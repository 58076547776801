import React, { ReactNode } from 'react';
import _ from 'lodash';
import moment from 'moment';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Button,
  InputGroup,
  Dropdown,
  DropdownButton,
  Form,
  Badge,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Switch } from "antd";
import InputMask from 'react-input-mask';
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, findStatus, isEdit, checkStatus } from '../slice.tsx';
import { getFirstCustomerSite } from '../../manage/jobs/jobTypes/slice.tsx';
import driverOptionsSlice from './driverOptions/slice.tsx';

// enums
import { warning, dateFormat, timeMeridianFormat, dateTimeMeridianFormat, apiDateFormat, getDateObjectTimeMeridian, addDate, isValidDate, getMaxPageSize, timeRegex, concatDateTime, isNumeric } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
import SmartSelect from '../../../../v5/components/select/SmartSelect.tsx';
import SmartAutocomplete from '../../../../v5/components/autocomplete/SmartAutocomplete.tsx';

import MultipleTrips from './MultipleTrips.tsx';
import CollectBin from './CollectBin.tsx';

import { ReactComponent as MagicIcon } from "../../../../v5/assets/icons/magic.svg";
import { ReactComponent as CalendarIcon } from "../../../../v5/assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "../../../../v5/assets/icons/clock.svg";
// import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus.svg";

// ----------------------------------------------------------------------

const StyledStatusRow: React.FC<React.ComponentProps<typeof Row>> = styled(Row)`
  padding: 8px 12px 8px 12px;
  border-radius: 6px;
  background-color: var(--bs-gray-50);
`;

const StyledDivider: React.FC<React.ComponentProps<typeof Divider>> = styled(Divider)`
  margin: 0px 0px 0px 7px !important;
  background-color: white;
`;


const DispatchOrder = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isLoadingAutoSuggestDriver } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const getElements = () => {
    return <Row className='g-16'>
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label>{fields.jobDate.label}</Form.Label>
          <InputGroup>
            <Form.Control
              as = {InputMask}
              mask={'99-99-9999'}
              maskChar={null}
              disabled={checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed'])}
              placeholder={dateFormat()}
              value={values.jobDate}
              onChange={(e) => {
                setFieldValue('jobDate', e.target.value);
                
                try {
                  if(isValidDate(e.target.value)){
                    let newDate = addDate(values.followUpDays, moment(e.target.value, dateFormat()));
                    setFieldValue('followUpDate', newDate.toDate());
                  }
                }catch(e){}
              }}
              isInvalid={!!(errors && errors.jobDate)}
            />
            <InputGroup.Text>
              <DatePicker
                portal={true}
                onOpenPickNewDate={false}
                shadow={false}
                disabled={checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed'])}
                calendarPosition={'bottom-right'} 
                format={dateFormat()}
                value={values.jobDate}
                onChange={(value: DateObject) => {
                  setFieldValue('jobDate', value.format(dateFormat()));

                  try {
                    let newDate = addDate(values.followUpDays, moment(value.format(dateFormat()), dateFormat()));
                    setFieldValue('followUpDate', newDate.toDate());
                  }catch(e){}
                }}
                render={(value, openCalendar) => {
                  return <Button variant={'custom-none-primary'} onClick={openCalendar}><CalendarIcon /></Button>
                }}
              />
            </InputGroup.Text>
          </InputGroup>
          <Form.Control.Feedback type="invalid">{errors && errors.jobDate as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label>{fields.jobTimeSpecific.label}</Form.Label>
          <InputGroup>
            <Form.Control
              as={SmartAutocomplete}
              value={values.accountJobWorkingTimeName}
              placeholder={fields.jobTimeSpecific.placeholder}
              addText={'Add new working time'}
              apiPath={'AccountJobWorkingTime'}
              customParams={{
                currentPage: 1,
                pageSize: getMaxPageSize(),
                searchQuery: '',
                sortColumn: 'order',
                sortDir: 'asc',
                jobDate: (values.jobDate && values.jobDate != '') ? moment(values.jobDate, dateFormat()).format(apiDateFormat()) : null,
                customerSiteId: null,
              }}
              fieldNames={{ value: 'accountJobWorkingTimeId', label: 'workingTimeName' }}
              selected={{ accountJobWorkingTimeId: values.accountJobWorkingTimeId, workingTimeName: values.accountJobWorkingTimeName }}
              getPopupContainer={(trigger: any) => trigger.parentNode}
              hasOptionRender={false}
              hasFooterRender={false}
              searchable={false}
              allowClear={true}
              selectedIcon={false}
              disabled={checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed'])}
              regex={timeRegex()}
              onChangeValue={(value: any, option: any, ref: any) => {
                if(option && option.accountJobWorkingTimeId){
                  setFieldValue('accountJobWorkingTimeId', option?.accountJobWorkingTimeId);
                  setFieldValue('accountJobWorkingTimeName', option?.workingTimeName);

                  let jobTime = (option && option.startTime) ? moment(option.startTime).format(timeMeridianFormat()) : null;
                  if(isValidDate(moment().format(dateFormat()) + ' ' + jobTime, dateTimeMeridianFormat())){
                    let jobTimeSpecific = concatDateTime(values.jobDate, jobTime)
                    setFieldValue('jobTimeSpecific', jobTimeSpecific);
                  } else {
                    setFieldValue('jobTimeSpecific', null);
                  }

                } else {
                  setFieldValue('accountJobWorkingTimeId', null);
                  setFieldValue('accountJobWorkingTimeName', value);
                  
                  if(isValidDate(moment().format(dateFormat()) + ' ' + value, dateTimeMeridianFormat())){
                    let jobTimeSpecific = concatDateTime(values.jobDate, value)
                    setFieldValue('jobTimeSpecific', jobTimeSpecific);
                  } else {
                    setFieldValue('jobTimeSpecific', null);
                  }
                }
              }}
              isInvalid={!!(errors && errors.accountJobWorkingTimeId)}
            />
            <InputGroup.Text>
              <DatePicker
                portal={true}
                onOpenPickNewDate={false}
                shadow={false}
                disabled={checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed'])}
                calendarPosition={'bottom-right'}
                format={timeMeridianFormat()}
                value={getDateObjectTimeMeridian(values.accountJobWorkingTimeName)}
                onChange={(value: DateObject) => {
                  let timeName = value.format(timeMeridianFormat())
                  let jobTimeSpecific = concatDateTime(values.jobDate, timeName)
                  setFieldValue('jobTimeSpecific', jobTimeSpecific);
                  
                  setFieldValue('accountJobWorkingTimeId', null);
                  setFieldValue('accountJobWorkingTimeName', timeName);
                }}
                disableDayPicker
                render={(value, openCalendar) => {
                  return <Button variant={'custom-none-primary'} onClick={openCalendar}><ClockIcon /></Button>
                }}
                plugins={[
                  <TimePicker hideSeconds />
                ]}
              />
            </InputGroup.Text>
          </InputGroup>
          <Form.Control.Feedback type="invalid">{errors && errors.accountJobWorkingTimeId as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label className='w-100'>
            <Row>
              <Col xs={true}>{fields.driverId.label}</Col>
              {!checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed']) && <Col xs={'auto'}>
                <a href='/'
                  className={'fs-12 gray-600'}
                  onClick={(e: any) => {
                    e.preventDefault();
                    e.stopPropagation();

                    dispatch(driverOptionsSlice.setShow({ show: true, details: values }));
                  }}
                >Driver Options</a>
              </Col>}
            </Row>
          </Form.Label>
          <Form.Control
            as={SmartSelect}
            isInvalid={!!(errors && errors.driverId)}
            value={values.driverId}
            placeholder={fields.driverId.placeholder}

            addText={'Add new driver'}
            apiPath={'driver'}
            customParams={{
              recentJob: true,
            }}
            fieldNames={{ value: 'driverId', label: 'driverName' }}
            selected={{ driverId: values.driverId, driverName: values.driverName }}
            getPopupContainer={(trigger: any) => trigger.parentNode}

            hasOptionRender={false}
            hasFooterRender={false}
            searchable={true}
            allowClear={true}
            selectedIcon={false}
            disabled={isLoadingAutoSuggestDriver || checkStatus(values?.currentStatusName, ['started', 'in progress', 'completed', 'cancelled', 'failed'])}
            virtual={true}

            onChangeValue={async (value: any, option: any, ref: any) => {
              if(option){
                await setFieldValue('driverId', value);
                await setFieldValue('driverName', option.driverName);

                if(values.vehicleId === null || values.vehicleId <= 0){
                  if(option && option.defaultVehicle){
                      let vehicleItem = option.defaultVehicle;
                      let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                      let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';

                      await setFieldValue('vehicleId', vehicleId);
                      await setFieldValue('vehicleName', vehicleName);
                  }
                }
                
                let selectedStatus = findStatus(values?.jobTemplate?.templateStatuses, 'unassigned');
                if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus?.jobStatusId) {
                  let newStatus = findStatus(values?.jobTemplate?.templateStatuses, 'assigned');
                  let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                  let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';

                  await setFieldValue('statusId', newStatusId);
                  await setFieldValue('statusName', newStatusName);
                }
              } else {
                await setFieldValue('driverId', null);
                await setFieldValue('driverName', '');
                
                let selectedStatus = findStatus(values?.jobTemplate?.templateStatuses, 'assigned');
                let dispatchedStatus = findStatus(values?.jobTemplate?.templateStatuses, 'dispatched');
                if (values.statusId === selectedStatus?.jobStatusId) {
                  let newStatus = findStatus(values?.jobTemplate?.templateStatuses, 'unassigned');
                  let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                  let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';

                  await setFieldValue('statusId', newStatusId);
                  await setFieldValue('statusName', newStatusName);
                } else if (values.statusId === dispatchedStatus?.value) {
                  let newStatus = findStatus(values?.jobTemplate?.templateStatuses, 'unassigned');
                  let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                  let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';
                  
                  await setFieldValue('statusId', newStatusId);
                  await setFieldValue('statusName', newStatusName);
                }
              }
            }}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.driverId as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <Form.Group>
          <Form.Label>{fields.vehicleId.label}</Form.Label>
          <Form.Control
            as={SmartSelect}
            isInvalid={!!(errors && errors.vehicleId)}
            value={values.vehicleId}
            placeholder={fields.driverId.placeholder}

            addText={'Add new vehicle'}
            apiPath={'vehicle'}
            customParams={{
              recentJob: true,
            }}
            fieldNames={{ value: 'vehicleId', label: 'vehicleName' }}
            selected={{ vehicleId: values.vehicleId, vehicleName: values.vehicleName }}
            getPopupContainer={(trigger: any) => trigger.parentNode}

            hasOptionRender={false}
            hasFooterRender={false}
            searchable={true}
            allowClear={true}
            selectedIcon={false}
            disabled={isLoadingAutoSuggestDriver || checkStatus(values?.currentStatusName, ['cancelled', 'failed'])}
            virtual={true}

            onChangeValue={async (value: any, option: any, ref: any) => {
              if(option){
                await setFieldValue('vehicleId', value);
                await setFieldValue('vehicleName', option.vehicleName);

                if(values.driverId === null || values.driverId <= 0){
                  if(option && option.defaultDriver){
                      let driverItem = option.defaultDriver;
                      let driverId = (driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
                      let driverName = (driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
                      
                      await setFieldValue('driverId', driverId);
                      await setFieldValue('driverName', driverName);
                      
                      let selectedStatus = findStatus(values?.jobTemplate?.templateStatuses, 'unassigned');
                      if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus?.jobStatusId) {
                        let newStatus = findStatus(values?.jobTemplate?.templateStatuses, 'assigned');
                        let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                        let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';
                        
                        await setFieldValue('statusId', newStatusId);
                        await setFieldValue('statusName', newStatusName);
                      }
                  }
                }
              } else {
                await setFieldValue('vehicleId', null);
                await setFieldValue('vehicleName', '');
              }
            }}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.vehicleId as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12} md={6}>
        <StyledStatusRow className='align-items-center g-0'>
          <Col xs={true} className='medium fs-12'>{fields.jobStatus.label}</Col>
          <Col xs={'auto'}><Badge bg={'custom-defalut'}>{values.statusName}</Badge></Col>
        </StyledStatusRow>
      </Col>
      
      <Col xs={12}>
        <Divider />
      </Col>

      {!isEdit() && <Col xs={12} md={6}>
        <MultipleTrips />
      </Col>}
      
      <Col xs={12} md={6}>
        <CollectBin />
      </Col>
    </Row>
  }


  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
      <Col xs={12}>
        <Row className='g-16 align-items-center'>
          <Col xs={true}>
            <span className="large semibold gray-900">5. Dispatch Order</span>
          </Col>
          {checkStatus(values?.currentStatusName, ['', 'unassigned', 'assigned', 'dispatched']) && <Col xs={'auto'}>
            <Button
              variant={'custom-success'}
              size={'sm'}
              disabled={!(values.jobDate != '') || isLoadingAutoSuggestDriver}
              onClick={() => {
                let customerSite = getFirstCustomerSite(values);

                let params = {
                  jobTimeSpecific: values.jobTimeSpecific ? values.jobTimeSpecific : values.jobDate,
                  customerSiteId: customerSite?.customerSiteId,
                };

                dispatch(slice.callAutoSuggestDriverApi(params, async (state: boolean, data: any) => {
                  if(state && data){
                    let driverId = (data && data.driverId && data.driverId > 0) ? data.driverId : null;
                    let driverName = (data && data.driverName && data.driverName !== '') ? data.driverName : '';

                    await setFieldValue('driverId', driverId);
                    await setFieldValue('driverName', driverName);
                    

                    if(data && data.defaultVehicle){
                      let vehicleItem = data.defaultVehicle;
                      let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                      let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';

                      await setFieldValue('vehicleId', vehicleId);
                      await setFieldValue('vehicleName', vehicleName);
                    }


                    let selectedStatus = findStatus(values?.jobTemplate?.templateStatuses, 'unassigned');
                    if(values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus?.jobStatusId) {
                      let newStatus = findStatus(values?.jobTemplate?.templateStatuses, 'assigned');
                      let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                      let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';
                      
                      await setFieldValue('statusId', newStatusId);
                      await setFieldValue('statusName', newStatusName);
                    }
                  }
                }));
              }}
            >
              <MagicIcon /><span className='ps-s4'>Auto-Suggest</span>
            </Button>

            {/* <DropdownButton
              variant={'custom-success'}
              size={'sm'}
              disabled={!(values.jobDate != '') || isLoadingAutoSuggestDriver}
              title={<><MagicIcon /><span className='ps-s4'>Auto-Suggest</span> <StyledDivider type='vertical' /></>}
            >
              <Dropdown.Item
                active={false}
                disabled={false}
                onClick={() => {
                  
                }}
              >Load Previous</Dropdown.Item>
              <Dropdown.Item
                active={true}
                disabled={false}
                onClick={() => {
                  let customerSite = getFirstCustomerSite(values);

                  let params = {
                    jobTimeSpecific: values.jobTimeSpecific ? values.jobTimeSpecific : values.jobDate,
                    customerSiteId: customerSite?.customerSiteId,
                  };

                  dispatch(slice.callAutoSuggestDriverApi(params, async (state: boolean, data: any) => {
                    if(state && data){
                      let driverId = (data && data.driverId && data.driverId > 0) ? data.driverId : null;
                      let driverName = (data && data.driverName && data.driverName !== '') ? data.driverName : '';

                      await setFieldValue('driverId', driverId);
                      await setFieldValue('driverName', driverName);
                      

                      if(data && data.defaultVehicle){
                        let vehicleItem = data.defaultVehicle;
                        let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                        let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';

                        await setFieldValue('vehicleId', vehicleId);
                        await setFieldValue('vehicleName', vehicleName);
                      }


                      let selectedStatus = findStatus(values?.jobTemplate?.templateStatuses, 'unassigned');
                      if(values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus?.jobStatusId) {
                        let newStatus = findStatus(values?.jobTemplate?.templateStatuses, 'assigned');
                        let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                        let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';
                        
                        await setFieldValue('statusId', newStatusId);
                        await setFieldValue('statusName', newStatusName);
                      }
                    }
                  }));
                }}
              >Load Default</Dropdown.Item>
            </DropdownButton> */}
          </Col>}
        </Row>
      </Col>

      <Col xs={12}>{getElements()}</Col>

      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
}

export default DispatchOrder;
