import React from "react";
import moment from "moment";
import styled from 'styled-components';

import { 
  Button,
  Row,
  Col,
} from "react-bootstrap-v5";

import Utils from "../../../utils/utils";

import JobListTopSection from "../Partial/JobListTopSection.jsx";

import RSuiteDateRangePickerCostume from "../../../components/OLD/Datepicker/RSuiteDateRangePickerCostume";
import WrappedButton from '../../../components/Buttons/WrappedButton';

import { connect } from 'react-redux'
import dateSection from "../../../../setup/redux/slices/dateSection";


const StyledArrowButton = styled(Button)`
  border: 1px solid #ECECEC !important;
  border-radius: 2px !important;
  
  i {
    padding-left: 12px !important;
    padding-right: 12px !important;
    color: #7B7B7B !important;
  }

  &:hover {
    border: 1px solid #484848 !important;
    color: #484848 !important;
    background-color: #f7f7fa !important;
  }
`;

const StyledDatePicker = styled.div`
  border: 1px solid #ECECEC !important;
  border-radius: 2px !important;

  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .rs-picker-toggle-value {
    color: #7A7A7A !important;
  }

  .material-icons {
    color: #7A7A7A !important;
  }

  .rs-picker-toggle:hover {
    border: 0px;
    border-radius: 2px !important;
  }

  &:hover {
    border: 1px solid #484848 !important;
    color: #484848 !important;
    background-color: #f7f7fa !important;
  }
`;

const StyledButtonToday = styled(Button)`
  border: 1px solid #185cff !important;
  border-radius: 2px !important;
  
  span {
    padding-left: 12px !important;
    padding-right: 12px !important;
    color: #185cff !important;
  }

  &:hover {
    border: 1px solid #185cff !important;
    color: #185cff !important;
    background-color: #f7f7fa !important;
  }
`;


class ViewTopSection extends React.Component {
  
  constructor(props) {
    super(props);

    this.pageName = "jobs-page";

    this.state = {
      isDisabledBatchAction: false,
      search: '',
    }
  }


  async componentDidMount(){
    await this.props.action.dateSection.dateGetFilters({
      auth: this.props.auth,
      settingsType: this.props.settingsType,
      pageName: this.pageName,
    });
  }

  /* UNCHECKED */
  setButtonArrow = () => {
    return (
      <div>
        <StyledArrowButton
          className={'me-2'}
          variant="link"
          size={'sm'}
          onClick={async () => {
            await this.props.action.dateSection.datePrev();
            await this.props.action.dateSection.dateSetFilters({
              auth: this.props.auth,
              settingsType: this.props.settingsType,
              pageName: this.pageName,
            });

            if(this.props.onDateChange){
              this.props.onDateChange();
            }
          }}
        >
          <i color="blue" className="fas fa-arrow-left"></i>
        </StyledArrowButton>
        <StyledArrowButton
          variant="link"
          size={'sm'}
          onClick={async () => {
            await this.props.action.dateSection.dateNext();
            await this.props.action.dateSection.dateSetFilters({
              auth: this.props.auth,
              settingsType: this.props.settingsType,
              pageName: this.pageName,
            });

            if(this.props.onDateChange){
              this.props.onDateChange();
            }
          }}
        >
          <i className="fas fa-arrow-right"></i>
        </StyledArrowButton>
      </div>
    );
  }

  setDatePicker = () => {
    return <StyledDatePicker className={'date-range-picker'}>
      <RSuiteDateRangePickerCostume
        className={''}
        placeholder={"Select date"}
        ranges={Utils.getDateRanges(['Last 6 Months'])}
        value={this.props.dateSection.dateRange}
        cleanable={false}
        renderValue={(value, format) => {
          if( moment(value[0]).format(Utils.getDefaultDateRangeFormat())  === moment(value[1]).format(Utils.getDefaultDateRangeFormat())  ){
            let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat()) 
          
            return (
              <div pl={1}>
                <span className="dateTicker">{date}</span>
              </div>
            );
          } else {
            let date = moment(value[0]).format(Utils.getDefaultDateRangeFormat()) + " to " + moment(value[1]).format(Utils.getDefaultDateRangeFormat());

            return (
              <div pl={1}>
                <span className="dateTicker">{date}</span>
              </div>
            );
          }
        }}
        onChange={async (value) => {
          await this.props.action.dateSection.dateChange(value);
          await this.props.action.dateSection.dateSetFilters({
            auth: this.props.auth,
            settingsType: this.props.settingsType,
            pageName: this.pageName,
          });

          if(this.props.onDateChange){
            this.props.onDateChange();
          }
        }}
      />
    </StyledDatePicker>
  }

  setTodayButton = () => {
    return <StyledButtonToday
      variant={"link"}
      size={'sm'}
      onClick={async () => {
        await this.props.action.dateSection.dateToday();
        await this.props.action.dateSection.dateSetFilters({
          auth: this.props.auth,
          settingsType: this.props.settingsType,
          pageName: this.pageName,
        });

        if(this.props.onDateChange){
          this.props.onDateChange();
        }
      }}
    >
      <span>TODAY</span>
    </StyledButtonToday>
  }

  setNewButton = () => {
    return <Button
      size={"sm"}
      onClick={() => {
        this.props.history.push('/v5/job-form-beta');
      }}
    >+ New</Button>
  }
  /* END UNCHECKED */
  
  
  /* CHECKED */
  setCloseButton = () => {
    return <WrappedButton
      className={'px-3 pe-1'}
      variant={"light"}
      size={'sm'}
      style={{ backgroundColor: 'transparent' }}
      onClick={() => {
        this.setState({
          isDisabledBatchAction: false,
        }, () => {
          if(this.props.onChangeBatchAction){
            this.props.onChangeBatchAction(false);
          }
        });
      }}
    >
      <i className={'material-icons'}>close</i>
    </WrappedButton>
  }
  /* END CHECKED */


  
  topSection = () => {
		return <JobListTopSection
      hasChecked={(this.props.isDisabledBatchAction)}
      unchecked={this.topSectionUnchecked()}
      checked={this.topSectionChecked()}
    />
	}
	topSectionUnchecked = () => {
    return <Row className={"view-top-section-layout align-items-center"}>
      <Col xs={12} md={'auto'}>{this.props.setActiveDropdown()}</Col>
      <Col xs={12} sm={true} xl={3} className={'pb-3 pb-xl-0'}>{this.setDatePicker()}</Col>
      <Col xs={'auto'} className={'pb-3 pb-xl-0'}>{this.setButtonArrow()}</Col>
      <Col xs={'auto'} className={'pb-3 pb-xl-0'}>{this.setTodayButton()}</Col>
      <Col xs={12} xl={'auto'}></Col>
      <Col xs={'auto'} className={'pb-3 pb-xl-0'}>{this.props.setAdvancedSearch()}</Col>
      <Col xs={'auto'} className={'pb-3 pb-xl-0'}>{this.props.setSearch()}</Col>
      <Col xs={12} sm={true}></Col>
      <Col xs={'auto'} className={'text-center'}>{this.setNewButton()}</Col>
      <Col xs={'auto'} className={'text-center'}>{this.props.setSettingsDropdown(this.props.realTimeUpdate.selected)}</Col>
      <Col xs={'auto'} className={'text-center'}>{this.props.setColomnDropdown()}</Col>
    </Row>
  }
	topSectionChecked = () => {
    return <Row className={"align-items-center"}>
      <Col xs={12} md={'auto'}>{this.props.batchAction()}</Col>
      <Col xs={12} md={'auto'}>{this.props.bulkUpdate()}</Col>
      <Col xs={12} md={true}>{this.props.setSelectedItemsCount()}</Col>
      <Col xs={true}></Col>
      <Col xs={'auto'} className={'text-center'}>{this.setCloseButton()}</Col>
    </Row>
  }



  render() {
    return this.topSection()
  }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  action: {
    dateSection: {
      dateGetFilters: (payload) => dispatch(dateSection.dateGetFilters(payload)),
      dateSetFilters: (payload) => dispatch(dateSection.dateSetFilters(payload)),
      dateNext: (payload) => dispatch(dateSection.dateNext(payload)),
      datePrev: (payload) => dispatch(dateSection.datePrev(payload)),
      dateToday: (payload) => dispatch(dateSection.dateToday(payload)),
      dateChange: (payload) => dispatch(dateSection.dateChange(payload)),
    },
  }
});
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ViewTopSection);