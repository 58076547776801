/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

// import { axios } from '../../../../../v5/utils/axios';
// import { ReadAPIParams, success, successAPI, errorAPI } from '../../../../../v5/utils/utils';
import Yup from '../../../../../v5/utils/yup';
// import {  } from '../../../../../v5/utils/enums';

import { prepareDriverPermisons } from '../../../manage/jobs/jobTypes/slice.tsx';


export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    steps: Yup.array().of(Yup.object()),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const initialValues: any = {
  steps: []
};


export const prepareForm = (values: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(values);
  
  let isRequireBinNumber = true;
  let canDriverChangeBinType = true;
  let canDriverChangeWasteType = true;
  let isEditableBinNumber = true;

  if(form && form.steps && form.steps.length > 0){
    isRequireBinNumber = form.steps[0].isRequireBinNumberToStart;
    canDriverChangeBinType = form.steps[0].canDriverChangeBinType;
    canDriverChangeWasteType = form.steps[0].canDriverChangeWasteType;
    isEditableBinNumber = form.steps[0].isEditableBinNumber;
  }
  
  data['isRequireBinNumber'] = isRequireBinNumber;
  data['canDriverChangeBinType'] = canDriverChangeBinType;
  data['canDriverChangeWasteType'] = canDriverChangeWasteType;
  data['isEditableBinNumber'] = isEditableBinNumber;

  return data;
};
export const prepareData = (values: any = null) => {
  let data = _.cloneDeep(values);
  let form = _.cloneDeep(values);

  let steps: Array<any> = [];
  if(form && form.steps && form.steps.length > 0){
    let driverPermisons = prepareDriverPermisons(form, 'steps');
    let isRequireBinNumber = driverPermisons.isRequireBinNumber;
    let canDriverChangeBinType = driverPermisons.canDriverChangeBinType;
    let canDriverChangeWasteType = driverPermisons.canDriverChangeWasteType;
    let isEditableBinNumber = driverPermisons.isEditableBinNumber;

    steps = form.steps.map((step: any, i: number) => {
      return {
        ...step,
        isRequireBinNumberToStart: isRequireBinNumber,
        isRequireBinNumberToEnd: isRequireBinNumber,
        canDriverChangeBinType: canDriverChangeBinType,
        canDriverChangeWasteType: canDriverChangeWasteType,
        isEditableBinNumber: isEditableBinNumber,
      }
    });
  }

  data['steps'] = steps;

  return data;
};


interface InitState {
  show: boolean,
  details: any,
}

function NewReducer() {
  const name = 'jobFormDriverOptionsSlice';


  const initialState: InitState = {
    show: false,
    details: initialValues,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, details: any }>) => {
      state.show = action.payload.show;
      state.details = action.payload.details;
    },
  };


  const apis = {
    
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();