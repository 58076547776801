import React, { ReactNode } from 'react';
// import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Spin } from 'antd';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice from './slice.tsx';
// import {  } from '../../slice.tsx';
// import {  } from '../../../manage/jobs/jobTypes/slice.tsx';

// import SmartSelect from '../../../../../v5/components/select/SmartSelect.tsx';

// import { warning, dateTimeFormat, getMaxPageSize, getCurrency, isNumeric, getDefaultUOMv4, getBillingUOMv4 } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

// import { ReactComponent as RefreshIcon } from "../../../../../v5/assets/icons/refresh-ccw.svg";

import HistoryDetails from "./HistoryDetails.jsx";

// ----------------------------------------------------------------------


const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, details, isLoading, currentVersion, previousVersion } = useTypedSelector((state: RootState) => state.jobFormAuditTrailSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useEffect(() => {
    if(show){
      let params: any = {
        jobId: details?.jobId,
        jobHistoryId: details?.jobHistoryId,
      };
      dispatch(slice.callCompareHistoryApi(params, (state: boolean, data: any) => {}));
    }
  }, [show, details])


  const onCancel = () => {
    dispatch(slice.setShow({ show: false, details: null }));
  }


  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        <Button
          variant={'custom-outlined'}
          size={'sm'}
          className='me-12'
          disabled={isLoading}
          onClick={(e) => {
            onCancel();
          }}
        >Cancel</Button>
      </Col>
    </Row>
  }

  const formElements = () => {
    return <Row className='g-32 align-items-center'>
      <Col xs={12}>
        {
          (currentVersion)
          ?
          <HistoryDetails
            index={0}
            last={0}
            previousVersion={previousVersion}
            currentVersion={currentVersion}
          />
          :
          <div style={{ fontWeight: "bold" }}>Job audit trails is not recorded for old jobs</div>
        }
      </Col>
    </Row>
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '40%' }}
  >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>
        <div>Audit Trail</div>
        <div className='regular fs-14 gray-600'>The difference between the current and previous item in the history list</div>
      </Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='px-24 py-16 scrollable-container'>
      <Spin spinning={isLoading} wrapperClassName={'no-height'}>
        {formElements()}
      </Spin>
    </Offcanvas.Body>
    <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
  </Offcanvas>
}

export default PageComponent;