/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, isNumeric, removeEmptyRowsWithArrays, removeEmptyRows } from '../../utils/utils';
import Yup from '../../utils/yup';

export const fields = {
  reminderDate: {
    id: 'reminderDate',
    label: 'Reminder Date',
  },
  customer: {
    id: 'customerNameDisplay',
    label: 'Customer',
  },
  customerSite: {
    id: 'siteNameDisplay',
    label: 'Site',
  },
  reminderDescripton: {
    id: 'reminderDescripton',
    label: 'Description',
    placeholder: placeholderRows(5, 'Enter remarks', ''),
    rows: 5,
  },
  updatedBy: {
    id: 'updatedBy',
    label: 'Last update by',
  },
  status: {
    id: 'statusDisplay',
    label: 'Current Status',
  },
};

export const formSchema = (id: number | null = null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
  })
}

let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
  formikContext = useContext(FormikContext);
  if (!formikContext) {
    throw new Error('useFormikContext must be used within a FormikProvider');
  }
  return formikContext;
};

export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);

  if (data && form) {
    let isActive = ((form.isActive == false) || (form.isActive == true)) ? form.isActive : false;

    let customer = (form && form.customer) ? form.customer : null;
    let customerId = (customer && customer.customerId && customer.customerId !== '') ? customer.customerId : null;
    let customerName = (customer && customer.customerName && customer.customerName !== '') ? customer.customerName : '';

    let customerSite = (form && form.customerSite) ? form.customerSite : null;
    let customerSiteId = (customerSite && customerSite.customerSiteId && customerSite.customerSiteId !== '') ? customerSite.customerSiteId : null;
    let customerSiteName = (customerSite && customerSite.siteName && customerSite.siteName !== '') ? customerSite.siteName : '';

    let reminderDescripton = (form.reminderDescripton && form.reminderDescripton !== '') ? form.remarks : '';
    let reminderDate = (form.reminderDate && form.reminderDate !== '') ? form.reminderDate : null;

    let status = (form.status && form.status !== '') ? form.status : null;

    data['isActive'] = isActive;

    data['customerId'] = customerId;
    data['customerName'] = customerName;
    data['customerSiteId'] = customerSiteId;
    data['customerSiteName'] = customerSiteName;
    data['reminderDescripton'] = reminderDescripton;
    data['reminderDate'] = reminderDate;
    data['status'] = status;
  }

  return data;
};

export const prepareData = (values: any = null, id: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if (form) {
    if (id) {
      data['accountReminderId'] = id;
    }

    data['isActive'] = form.isActive;

    data['customerId'] = form.customerId;
    data['customerSiteId'] = form.customerSiteId;
    data['reminderDescripton'] = form.billingAddress;
    data['reminderDate'] = form.contactNumber;
    data['status'] = form.status;

    let customer = null;
    if (form.customerId) {
      customer = {
        customerName: form.customerName,
      }
    }
    data['customer'] = customer;

    let customerSite = null;
    if (form.customerSiteId) {
      customerSite = {
        siteName: form.siteName,
      }
    }
    data['customerSite'] = customerSite;
  }

  return data;
};

export interface initialValuesStruct {
  isActive: boolean,

  customerId: any,
  customerName: string,

  customerSiteId: any,
  customerSiteName: string,

  reminderDescripton: string,
  reminderDate: any,

  status: any,
};
export const initialValues: initialValuesStruct = {
  isActive: true,

  customerId: null,
  customerName: '',

  customerSiteId: null,
  customerSiteName: '',

  reminderDescripton: '',
  reminderDate: false,
  status: 0,
};

interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any | null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'remindersSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number | null }>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.alerts = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.alerts = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };

  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      await axios.get('accountreminder', { params: params }).then(result => {
        let data = result.data;

        successAPI(data);

        callback(true, data);
        dispatch(actions.finishRead(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishRead(null));
      });
    },

    callDetailsApi: (id: number | null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      await axios.get('accountreminder/' + id).then(result => {
        let data = result.data;

        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetails(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCreate());

      await axios.post('accountreminder', params).then(result => {
        let data = result.data;

        // successAPI(data);
        // success('Saved successfully', 'The data you entered has been successfully saved.');

        callback(true, data);
        dispatch(actions.finishCreate(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishCreate(null));
      });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await axios.put('accountreminder', params).then(result => {
        let data = result.data;

        // successAPI(data);
        // success('Updated successfully', 'Your data has been successfully updated.');

        callback(true, data);
        dispatch(actions.finishUpdate(data));
      }).catch(error => {
        errorAPI(error);

        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();