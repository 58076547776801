import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Modal,
  Offcanvas,
} from 'react-bootstrap';
// import styled from 'styled-components';

import { Spin, Checkbox, Switch, Tooltip, Alert } from 'antd';

import InputMask from 'react-input-mask';
import DatePicker, { DateObject } from "react-multi-date-picker";

import GoogleSearchAddress from '../../../../components/googleMap/GoogleSearchAddress.tsx';
import SmartSelect from '../../../../components/select/SmartSelect.tsx';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../../setup'
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData } from './slice.tsx';

import Map from './Map.tsx';

// enums
import { getFullAddressPlace, validateCoordinates, convertCoordToLatLng, convertLatLngToCoord, limitRows, getMaxPageSize, dateFormat } from '../../../../../v5/utils/utils.tsx';
import { CustomerSiteAvailability } from '../../../../../v5/utils/enums.tsx';

import { ReactComponent as SearchIcon } from "../../../../../v5/assets/icons/search.svg";
import { ReactComponent as InfoIcon } from "../../../../../v5/assets/icons/alert-info.svg";
import { ReactComponent as CalendarIcon } from "../../../../../v5/assets/icons/calendar.svg";
import { ReactComponent as CheckboxUncheckedIcon } from "../../../../../v5/assets/icons/checkbox-unchecked.svg";
import { ReactComponent as CheckboxCheckedIcon } from "../../../../../v5/assets/icons/checkbox-checked.svg";

// ----------------------------------------------------------------------


const FormComponent = (props: any) => {
    const dispatch = useDispatch();
    const { isLoading, show, id, customerId, fieldType, details, isLoadingDriver, driverItems, weekdayIsLoading, weekdayIsSelectAll, weekdayItems, weekendIsLoading, weekendIsSelectAll, weekendItems } = useSelector((state: RootState) => state.jobFormCustomerSiteMiniSliceSlice);
    
    const [showInfo, setShowInfo] = React.useState(false);
    const [more, setMore] = React.useState(false);


    React.useEffect(() => {
        if(show){
            setMore(false);
            setShowInfo(false);
            let form = prepareForm(null, initialValues);
            setValues(form);
        }
    }, [show]);

    React.useEffect(() => {
        if(id && id > 0){
            dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
        }
    }, [id]);
        
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        let form = prepareForm(details, initialValues);
        setValues(form);
        dispatch(slice.setLoading(false));
    }, [details]);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(null),
        onSubmit: values => {
            onSubmit(values, (data: any) => {
                if(props && props.onSave){
                    props.onSave(data, fieldType);
                }
                
                onCancel();
            });
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


    const onSubmit = (values: any, callback: (data: any) => void) => {
        let data = prepareData(values, customerId);
        if(id > 0){
            dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        } else {
            dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        }
    }
    const onCancel = () => {
        let form = prepareForm(null, initialValues);
        setValues(form);
        dispatch(slice.setValues(form));
        dispatch(slice.setShow({ show: false, id: null, customerId: null, fieldType: null }));
        dispatch(slice.resetSlice());
    }


    const setFooter = () => {
        return <Row className='w-100 gx-12'>
            <Col xs={true} className='text-end'>
                <Button
                    className='me-5'
                    variant={'custom-text'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={(e) => {
                        onCancel();
                    }}
                >Cancel</Button>
                <Button
                    variant={'custom-primary'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={() => {
                        handleSubmit()
                        // dispatch(slice.setLoading(true));
                    }}
                >Save</Button>
            </Col>
        </Row>
    }
    const setForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}>
                <Form.Group>
                    <InputGroup>
                        <InputGroup.Text className='p-search'>
                            <SearchIcon />
                        </InputGroup.Text>
                        <Form.Control
                            as={GoogleSearchAddress}
                            className={'search-address'}
                            autoComplete='off'
                            placeholder={'Search'}
                            value={values.customSiteName}
                            onPlaceSelected={(place: any, r: any) => {
                                if(r?.value != place?.name){
                                    let obj = getFullAddressPlace(place);
                                    
                                    if(obj?.customSiteName != ''){
                                        setFieldValue('customSiteName', obj?.customSiteName);
                                    }

                                    setFieldValue('siteName', obj?.siteName);
                                    setFieldValue('street', obj?.street);
                                    setFieldValue('blockNo', obj?.blockNo);
                                    setFieldValue('postalCode', obj?.postalCode);
                                    setFieldValue('latitude', obj?.lat);
                                    setFieldValue('longitude', obj?.lng);
                                    setFieldValue('coordinates', obj?.coord);
                                }
                            }}
                            onChange={(e: any) => {
                                if(e.target.value != ''){
                                if(validateCoordinates(e.target.value)){
                                        setFieldValue('customSiteName', e.target.value);
                                        setFieldValue('siteName', e.target.value);
                        
                                        let coord = convertCoordToLatLng(e.target.value);
                                    if(coord){
                                        let coordinates = convertLatLngToCoord(coord?.lat, coord?.lng);
                    
                                        setFieldValue('latitude', coord.lat);
                                        setFieldValue('longitude', coord.lng);
                                        setFieldValue('coordinates', coordinates);
                                    } else {
                                        setFieldValue('latitude', null);
                                        setFieldValue('longitude', null);
                                        setFieldValue('coordinates', '');
                                    }
                
                                } else {
                                    if(values.coordinates != ''){
                                        setFieldValue('customSiteName', e.target.value);
                                    } else {
                                        setFieldValue('customSiteName', e.target.value);
                                        setFieldValue('siteName', e.target.value);

                                        setFieldValue('latitude', null);
                                        setFieldValue('longitude', null);
                                        setFieldValue('coordinates', '');
                                    }
                                }

                                } else {
                                    setFieldValue('latitude', null);
                                    setFieldValue('longitude', null);
                                    setFieldValue('coordinates', '');
                                }
                            }}
                            onKeyDown={(e: any) => {
                                if ((e.charCode || e.keyCode) === 13) {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }
                            }}
                            isInvalid={!!(errors && errors.siteName)}
                        />
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{errors && errors.siteName as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            
            {(values.latitude != null && values.latitude != '') && <>
                <Col xs={12}>
                    <Alert
                        message=""
                        description="You can adjust the position of the marker by dragging it. The coordinates will change but the rest of the fields will stay the same. If you wish to search a new location, go to the address bar to search again."
                        type="info"
                        showIcon
                        icon={<InfoIcon />}
                    />
                </Col>
                <Col xs={12} style={{ minHeight: '280px' }}>
                    <Map 
                        coordinates={values.coordinates}
                        canMoveMarker={true}
                        onDragEnd={(position: any) => {
                        let coordinates = convertLatLngToCoord(position?.lat, position?.lng);

                        setFieldValue('latitude', position?.lat);
                        setFieldValue('longitude', position?.lng);
                        setFieldValue('coordinates', coordinates);
                        }}
                    />
                </Col>
            </>}
            
            {(values.latitude != null && values.latitude != '') && <>
                <Col xs={12} className='mt-5'>
                    <Form.Group>
                        <Form.Label>{fields.siteName.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.siteName.placeholder}
                            disabled={false}
                            value={values.siteName}
                            onChange={(e) => {
                                setFieldValue('siteName', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.siteName)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.siteName as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.street.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.street.placeholder}
                            disabled={false}
                            value={values.street}
                            onChange={(e) => {
                                setFieldValue('street', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.street)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.street as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.blockNo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.blockNo.placeholder}
                            disabled={false}
                            value={values.blockNo}
                            onChange={(e) => {
                                setFieldValue('blockNo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.blockNo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.blockNo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.unitNo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.unitNo.placeholder}
                            disabled={false}
                            value={values.unitNo}
                            onChange={(e) => {
                                setFieldValue('unitNo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.unitNo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.unitNo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.postalCode.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.postalCode.placeholder}
                            disabled={false}
                            value={values.postalCode}
                            onChange={(e) => {
                                setFieldValue('postalCode', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.postalCode)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.postalCode as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.coordinates.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.coordinates.placeholder}
                            disabled={true}
                            value={(values.coordinates != '') ? values.coordinates : '-'}
                            onChange={(e) => {
                                setFieldValue('coordinates', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.coordinates)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.coordinates as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonName.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonName.placeholder}
                            disabled={false}
                            value={values.contactPersonName}
                            onChange={(e) => {
                                setFieldValue('contactPersonName', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonName)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonName as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonPhoneNumber.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonPhoneNumber.placeholder}
                            disabled={false}
                            value={values.contactPersonPhoneNumber}
                            onChange={(e) => {
                                setFieldValue('contactPersonPhoneNumber', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonPhoneNumber)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonPhoneNumber as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonNameTwo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonNameTwo.placeholder}
                            disabled={false}
                            value={values.contactPersonNameTwo}
                            onChange={(e) => {
                                setFieldValue('contactPersonNameTwo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonNameTwo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonNameTwo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                
                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>{fields.contactPersonPhoneNumberTwo.label}</Form.Label>
                        <Form.Control
                            type={'text'}
                            autoComplete='off'
                            placeholder={fields.contactPersonPhoneNumberTwo.placeholder}
                            disabled={false}
                            value={values.contactPersonPhoneNumberTwo}
                            onChange={(e) => {
                                setFieldValue('contactPersonPhoneNumberTwo', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.contactPersonPhoneNumberTwo)}
                        />
                        <Form.Control.Feedback type="invalid">{errors && errors.contactPersonPhoneNumberTwo as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={12}>
                    <Form.Group>
                        <Form.Label>
                            <span className='me-2'>{fields.expiryDate.label}</span>
                            <Tooltip placement={'top'} title={fields.expiryDate.info}>
                                <InfoIcon />
                            </Tooltip>
                        </Form.Label>
                        <InputGroup>
                            <Form.Control
                                as = {InputMask}
                                mask={'99-99-9999'}
                                maskChar={null}
                                placeholder={dateFormat()}
                                value={values.expiryDate}
                                onChange={(e) => {
                                    setFieldValue('expiryDate', e.target.value);
                                }}
                                isInvalid={!!(errors && errors.expiryDate)}
                            />
                            <InputGroup.Text>
                                <DatePicker
                                    portal={true}
                                    onOpenPickNewDate={false}
                                    shadow={false}
                                    disabled={false}
                                    calendarPosition={'bottom-right'} 
                                    format={dateFormat()}
                                    value={values.expiryDate}
                                    onChange={(value: DateObject) => {
                                        setFieldValue('expiryDate', value.format(dateFormat()));
                                    }}
                                    render={(value, openCalendar) => {
                                        return <Button variant={'custom-none-primary'} onClick={openCalendar}><CalendarIcon /></Button>
                                    }}
                                />
                            </InputGroup.Text>
                        </InputGroup>
                        <Form.Control.Feedback type="invalid">{errors && errors.expiryDate as ReactNode}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </>}

            {(values.latitude != null && values.latitude != '') && <Col xs={12} className='text-end mt-5'>
                <a href={'/'}
                    className='medium hover blue-500'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        setMore(!more);
                    }}
                >{more ? 'Show less' : 'Show more'}</a>
            </Col>}
        </Row>
    }
    const setOtherForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}>
                <Row className={'g-2 pt-5 pb-5'}>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.zoneId.label}</Form.Label>
                            <Form.Control
                                as={SmartSelect}
                                isInvalid={!!(errors && errors.zoneId)}
                                value={values.zoneId}
                                placeholder={fields.zoneId.placeholder}
                                addText={'Add new zone'}
                                apiPath={'zone'}
                                fieldNames={{ value: 'zoneId', label: 'zoneName' }}
                                createResponseFunc={(options: any) => {
                                    return (options) ? options : null;
                                }}
                                onCreateFunc={(option: any) => {
                                    if(option){
                                        setFieldValue('zoneId', option.zoneId);
                                        setFieldValue('zoneName', option.zoneName);
                                    }
                                }}
                                updateResponseFunc={(options: any) => {
                                    return (options) ? options : null;
                                }}
                                onUpdateFunc={(option: any) => {
                                    if(option && values.zoneId === option.zoneId){
                                        setFieldValue('zoneId', option.zoneId);
                                        setFieldValue('zoneName', option.zoneName);
                                    }
                                }}
                                selected={{ zoneId: values.zoneId, zoneName: values.zoneName }}
                                hasOptionRender={true}
                                hasFooterRender={false}
                                searchable={true}
                                allowClear={true}
                                selectedIcon={false}
                                disabled={false}
                                virtual={true}
                                onChangeValue={async (value: any, option: any, ref: any) => {
                                    if(option){
                                        await setFieldValue('zoneId', value);
                                        await setFieldValue('zoneName', option.zoneName);
                                    } else {
                                        await setFieldValue('zoneId', null);
                                        await setFieldValue('zoneName', '');
                                    }
                                }}
                                optionRender={(option: any, ref: any) => {
                                    return <Row className={'align-items-center gx-2'}>
                                        <Col xs={'auto'}>
                                            <span 
                                                className="position-relative d-inline-block border border-light rounded-circle"
                                                style={{
                                                    top: '-1px',
                                                    width: '6px',
                                                    height: '6px',
                                                    backgroundColor: (option && option.data && option.data.zoneColor) ? option.data.zoneColor : '',
                                                }}
                                            >
                                            </span>
                                        </Col>
                                        <Col xs={'auto'}>{option?.label}</Col>
                                    </Row>
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.zoneId as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.remarks.label} <small>{fields.remarks.labelDetails}</small></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                id={fields.remarks.id}
                                placeholder={fields.remarks.placeholder}
                                value={values.remarks}
                                onChange={async (e) => {
                                    limitRows(e.target.value, 5, async (value: any) => {
                                        await setFieldValue('remarks', value);
                                    });
                                }}
                                isInvalid={!!errors.remarks}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.remarks as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={12} className='mb-2'>
                        <Checkbox
                            checked={values.copySiteRemarksToJob}
                            onChange={(e) => {
                                setFieldValue('copySiteRemarksToJob', e.target.checked);
                            }}
                            >
                            {fields.copySiteRemarksToJob.label}
                        </Checkbox>
                    </Col>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.driverId.label}</Form.Label>
                            <Form.Control
                                as={SmartSelect}
                                mode="multiple"
                                maxTagCount={'responsive'}
                                isInvalid={!!(errors && errors.driverId)}
                                value={values.driverId}
                                placeholder={fields.driverId.placeholder}
                                addText={'Add new driver'}
                                apiPath={'driver'}
                                fieldNames={{ value: 'driverId', label: 'driverName' }}
                                selected={{ driverId: values.driverId, driverName: values.driverName }}
                                hasOptionRender={true}
                                hasFooterRender={true}
                                searchable={true}
                                allowClear={true}
                                selectedIcon={false}
                                disabled={false}
                                virtual={true}
                                onChangeValue={async (value: any, option: any, ref: any) => {
                                    if(option){
                                        await setFieldValue('driverId', option?.map((x: any) => x.driverId));
                                        await setFieldValue('driverName', option?.map((x: any) => x.driverName));
                                    } else {
                                        await setFieldValue('driverId', []);
                                        await setFieldValue('driverName', []);
                                    }
                                }}
                                optionRender={(option: any, ref: any) => {
                                    return <Row className={'align-items-center gx-2'}>
                                        <Col xs={'auto'}>
                                            {values.driverId?.includes(option?.value) ? <CheckboxCheckedIcon className='position-relative' style={{ top: '-2' }} /> : <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} />}
                                        </Col>
                                        <Col xs={'auto'}>{option?.label}</Col>
                                    </Row>
                                }}
                                footerRender={(isLoading: boolean, ref: any) => {
                                    return <Button
                                        variant={'custom-text'}
                                        size={'sm'}
                                        disabled={isLoading}
                                        onClick={async (e) => { 
                                            e.preventDefault();
                                            e.stopPropagation();

                                            if(ref){
                                                if(ref?.getAllData().every((x: any) => values.driverId?.includes(x.driverId))){
                                                    await setFieldValue('driverId', []);
                                                    await setFieldValue('driverName', []);
                                                } else {
                                                    await setFieldValue('driverId', ref?.getAllData()?.map((x: any) => x.driverId));
                                                    await setFieldValue('driverName', ref?.getAllData()?.map((x: any) => x.driverName));
                                                }
                                            }
                                        }}
                                    >
                                        {isLoading ? <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} /> : ref && ref?.getAllData()?.every((x: any) => values.driverId?.includes(x.driverId)) ? <CheckboxCheckedIcon className='position-relative' style={{ top: '-2' }} /> : <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} />}
                                        <span className='ps-2'>Select All</span>
                                    </Button>
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.driverId as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>

                    <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.instructions.label} <small>{fields.instructions.labelDetails}</small></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={5}
                                id={fields.instructions.id}
                                placeholder={fields.instructions.placeholder}
                                value={values.instructions}
                                onChange={async (e) => {
                                    limitRows(e.target.value, 5, async (value: any) => {
                                        await setFieldValue('instructions', value);
                                    });
                                }}
                                isInvalid={!!errors.instructions}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.instructions as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    
                    <Col xs={12}>
                        <Row className='align-items-center'>
                            <Col xs={12}><Form.Label>{fields.weekdayId.label}</Form.Label></Col>
                            <Col xs={'auto'}>
                                <Switch
                                    size='small'
                                    checked={values.weekdaysAvailability === CustomerSiteAvailability.All}
                                    onChange={(checked) => {
                                        setFieldValue('weekdaysAvailability', CustomerSiteAvailability.All);
                                    }}
                                />
                                <span className='ps-2 position-relative' style={{ top: '1px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setFieldValue('weekdaysAvailability', CustomerSiteAvailability.All);
                                    }}
                                >All</span>
                            </Col>
                            <Col xs={'auto'}>
                                <Switch
                                    size='small'
                                    checked={values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours}
                                    onChange={(checked) => {
                                        setFieldValue('weekdaysAvailability', CustomerSiteAvailability.SelectedHours);
                                    }}
                                />
                                <span className='ps-2 position-relative' style={{ top: '1px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setFieldValue('weekdaysAvailability', CustomerSiteAvailability.SelectedHours);
                                    }}
                                >Selected hours</span>
                            </Col>
                            <Col xs={'auto'}>
                                <Switch
                                    size='small'
                                    checked={values.weekdaysAvailability === CustomerSiteAvailability.NotAvailable}
                                    onChange={(checked) => {
                                        setFieldValue('weekdaysAvailability', CustomerSiteAvailability.NotAvailable);
                                    }}
                                />
                                <span className='ps-2 position-relative' style={{ top: '1px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setFieldValue('weekdaysAvailability', CustomerSiteAvailability.NotAvailable);
                                    }}
                                >Not available</span>
                            </Col>
                        </Row>
                    </Col>
                    {(values.weekdaysAvailability === CustomerSiteAvailability.SelectedHours) && <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.weekdayId.label}</Form.Label>
                            <Form.Control
                                as={SmartSelect}
                                mode="multiple"
                                maxTagCount={'responsive'}
                                isInvalid={!!(errors && errors.weekdayId)}
                                value={values.weekdayId}
                                placeholder={fields.weekdayId.placeholder}
                                addText={'Add new time'}
                                apiPath={'AccountJobWorkingTime'}
                                customParams={{
                                    currentPage: 1,
                                    pageSize: getMaxPageSize(),
                                    sortColumn: 'order',
                                    sortDir: 'asc',
                                }}
                                fieldNames={{ value: 'accountJobWorkingTimeId', label: 'workingTimeName' }}
                                selected={{ accountJobWorkingTimeId: values.weekdayId, workingTimeName: values.weekdayName }}
                                hasOptionRender={true}
                                hasFooterRender={true}
                                searchable={true}
                                allowClear={true}
                                selectedIcon={false}
                                disabled={false}
                                virtual={true}
                                onChangeValue={async (value: any, option: any, ref: any) => {
                                    if(option){
                                        await setFieldValue('weekdayId', option?.map((x: any) => x.accountJobWorkingTimeId));
                                        await setFieldValue('weekdayName', option?.map((x: any) => x.workingTimeName));
                                    } else {
                                        await setFieldValue('weekdayId', []);
                                        await setFieldValue('weekdayName', []);
                                    }
                                }}
                                optionRender={(option: any, ref: any) => {
                                    return <Row className={'align-items-center gx-2'}>
                                        <Col xs={'auto'}>
                                            {values.weekdayId?.includes(option?.value) ? <CheckboxCheckedIcon className='position-relative' style={{ top: '-2' }} /> : <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} />}
                                        </Col>
                                        <Col xs={'auto'}>{option?.label}</Col>
                                    </Row>
                                }}
                                footerRender={(isLoading: boolean, ref: any) => {
                                    return <Button
                                        variant={'custom-text'}
                                        size={'sm'}
                                        disabled={isLoading}
                                        onClick={async (e) => { 
                                            e.preventDefault();
                                            e.stopPropagation();

                                            if(ref){
                                                if(ref?.getAllData().every((x: any) => values.weekdayId?.includes(x.accountJobWorkingTimeId))){
                                                    await setFieldValue('weekdayId', []);
                                                    await setFieldValue('weekdayName', []);
                                                } else {
                                                    await setFieldValue('weekdayId', ref?.getAllData()?.map((x: any) => x.accountJobWorkingTimeId));
                                                    await setFieldValue('weekdayName', ref?.getAllData()?.map((x: any) => x.workingTimeName));
                                                }
                                            }
                                        }}
                                    >
                                        {isLoading ? <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} /> : ref && ref?.getAllData()?.every((x: any) => values.weekdayId?.includes(x.accountJobWorkingTimeId)) ? <CheckboxCheckedIcon className='position-relative' style={{ top: '-2' }} /> : <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} />}
                                        <span className='ps-2'>Select All</span>
                                    </Button>
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.weekdayId as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}

                    <Col xs={12}>
                        <Row className='align-items-center'>
                            <Col xs={12}><Form.Label>{fields.weekendId.label}</Form.Label></Col>
                            <Col xs={'auto'}>
                                <Switch
                                    size='small'
                                    checked={values.weekendAvailability === CustomerSiteAvailability.All}
                                    onChange={(checked) => {
                                        setFieldValue('weekendAvailability', CustomerSiteAvailability.All);
                                    }}
                                />
                                <span className='ps-2 position-relative' style={{ top: '1px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setFieldValue('weekendAvailability', CustomerSiteAvailability.All);
                                    }}
                                >All</span>
                            </Col>
                            <Col xs={'auto'}>
                                <Switch
                                    size='small'
                                    checked={values.weekendAvailability === CustomerSiteAvailability.SelectedHours}
                                    onChange={(checked) => {
                                        setFieldValue('weekendAvailability', CustomerSiteAvailability.SelectedHours);
                                    }}
                                />
                                <span className='ps-2 position-relative' style={{ top: '1px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setFieldValue('weekendAvailability', CustomerSiteAvailability.SelectedHours);
                                    }}
                                >Selected hours</span>
                            </Col>
                            <Col xs={'auto'}>
                                <Switch
                                    size='small'
                                    checked={values.weekendAvailability === CustomerSiteAvailability.NotAvailable}
                                    onChange={(checked) => {
                                        setFieldValue('weekendAvailability', CustomerSiteAvailability.NotAvailable);
                                    }}
                                />
                                <span className='ps-2 position-relative' style={{ top: '1px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setFieldValue('weekendAvailability', CustomerSiteAvailability.NotAvailable);
                                    }}
                                >Not available</span>
                            </Col>
                        </Row>
                    </Col>
                    {(values.weekendAvailability === CustomerSiteAvailability.SelectedHours) && <Col xs={12}>
                        <Form.Group>
                            <Form.Label>{fields.weekendId.label}</Form.Label>
                            <Form.Control
                                as={SmartSelect}
                                mode="multiple"
                                maxTagCount={'responsive'}
                                isInvalid={!!(errors && errors.weekendId)}
                                value={values.weekendId}
                                placeholder={fields.weekendId.placeholder}
                                addText={'Add new time'}
                                apiPath={'AccountJobWorkingTime'}
                                customParams={{
                                    currentPage: 1,
                                    pageSize: getMaxPageSize(),
                                    sortColumn: 'order',
                                    sortDir: 'asc',
                                }}
                                fieldNames={{ value: 'accountJobWorkingTimeId', label: 'workingTimeName' }}
                                selected={{ accountJobWorkingTimeId: values.weekendId, workingTimeName: values.weekendName }}
                                hasOptionRender={true}
                                hasFooterRender={true}
                                searchable={true}
                                allowClear={true}
                                selectedIcon={false}
                                disabled={false}
                                virtual={true}
                                onChangeValue={async (value: any, option: any, ref: any) => {
                                    if(option){
                                        await setFieldValue('weekendId', option?.map((x: any) => x.accountJobWorkingTimeId));
                                        await setFieldValue('weekendName', option?.map((x: any) => x.workingTimeName));
                                    } else {
                                        await setFieldValue('weekendId', []);
                                        await setFieldValue('weekendName', []);
                                    }
                                }}
                                optionRender={(option: any, ref: any) => {
                                    return <Row className={'align-items-center gx-2'}>
                                        <Col xs={'auto'}>
                                            {values.weekendId?.includes(option?.value) ? <CheckboxCheckedIcon className='position-relative' style={{ top: '-2' }} /> : <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} />}
                                        </Col>
                                        <Col xs={'auto'}>{option?.label}</Col>
                                    </Row>
                                }}
                                footerRender={(isLoading: boolean, ref: any) => {
                                    return <Button
                                        variant={'custom-text'}
                                        size={'sm'}
                                        disabled={isLoading}
                                        onClick={async (e) => { 
                                            e.preventDefault();
                                            e.stopPropagation();

                                            if(ref){
                                                if(ref?.getAllData().every((x: any) => values.weekendId?.includes(x.accountJobWorkingTimeId))){
                                                    await setFieldValue('weekendId', []);
                                                    await setFieldValue('weekendName', []);
                                                } else {
                                                    await setFieldValue('weekendId', ref?.getAllData()?.map((x: any) => x.accountJobWorkingTimeId));
                                                    await setFieldValue('weekendName', ref?.getAllData()?.map((x: any) => x.workingTimeName));
                                                }
                                            }
                                        }}
                                    >
                                        {isLoading ? <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} /> : ref && ref?.getAllData()?.every((x: any) => values.weekendId?.includes(x.accountJobWorkingTimeId)) ? <CheckboxCheckedIcon className='position-relative' style={{ top: '-2' }} /> : <CheckboxUncheckedIcon className='position-relative' style={{ top: '-2' }} />}
                                        <span className='ps-2'>Select All</span>
                                    </Button>
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors && errors.weekendId as ReactNode}</Form.Control.Feedback>
                        </Form.Group>
                    </Col>}

                </Row>
            </Col>
        </Row>
    }


    return <Offcanvas
        backdrop={'static'}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '600px' }}
    >
        <FormikContext.Provider value={formik}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{(id && id > 0) ? 'Edit' : 'Create'} Customer Site</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Spin spinning={isLoading}>
                    <Row className={'g-2'}>
                        <Col xs={12}>{setForm()}</Col>
                        {(more && (values.latitude != null && values.latitude != '')) && <Col xs={12}>{setOtherForm()}</Col>}
                    </Row>
                </Spin>
            </Offcanvas.Body>
            <Modal.Footer className='border-top'>{setFooter()}</Modal.Footer>
        </FormikContext.Provider>
    </Offcanvas>
}

export default FormComponent;
