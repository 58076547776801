import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Tooltip, Pagination, Radio, Checkbox } from 'antd';

// Auth
// import { useAuthContext } from '../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { fields, clientSideRows, getPaginationCount, itemRenderPagination, StyledInfoIcon, StyledTable, isRadioOrCheckbox, RadioOrCheckbox, isDisabledCB } from './slice.tsx';

// enums
import { isNumeric, hasPermission, getCurrency, formatNumber } from '../../../../utils/utils.tsx';
import { Permissions } from '../../../../utils/enums.tsx';

// import MaterialDataTable from '../../../../components/table/withSlice/MaterialDataTable.jsx';

// import { ReactComponent as InfoIcon } from "../../../../assets/icons/i.svg";
import { ReactComponent as PrevIcon } from "../../../../assets/icons/arrow-left.svg";
import { ReactComponent as NextIcon } from "../../../../assets/icons/arrow-right.svg";

// ----------------------------------------------------------------------


const ListComponent = React.forwardRef((props: any, ref: any) => {
  // const router = useRouter();

  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;
  
  const { includeLocalService, selected, rows, clientSideCurrentPageLocal, clientSideItemsPerPage, values, group, block } = useTypedSelector((state: RootState) => state.jobFormBinDetailsServicesSlice);
  const dispatch = useDispatch<AppDispatch>();
  

  
	// React.useImperativeHandle(
  //   ref,
  //   () => ({
  //     get() {
        
  //     },
  //   }),
  // );
  

  // React.useEffect(() => {
    
  // }, []);


  const tableSection = () => {
    let localServices = rows.filter((x: any) => isNumeric(x.customerId));
    let localServiceRows = clientSideRows(localServices, clientSideCurrentPageLocal, clientSideItemsPerPage);
    let total = getPaginationCount(localServices.length, clientSideItemsPerPage);

    let colWidthLocal = includeLocalService ? '20%' : '25%';
    if(hasPermission(Permissions.JobBilling)){
      colWidthLocal = includeLocalService ? '20%' : '25%';
    } else {
      colWidthLocal = includeLocalService ? '25%' : '30%';
    }

    let isRorCB: any = isRadioOrCheckbox(values, group, block);

    if(localServiceRows && localServiceRows.length > 0){
      return <>
        <StyledTable>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>&nbsp;</th>
              {includeLocalService && <th style={{ width: '20%' }}>{fields.customerName.label}</th>}
              <th style={{ width: colWidthLocal }}>{fields.serviceTag.label}</th>
              <th style={{ width: colWidthLocal }}>{fields.binTypeName.label}</th>
              <th style={{ width: colWidthLocal }}>{fields.wasteTypeName.label}</th>
              {(hasPermission(Permissions.JobBilling)) && <th style={{ width: colWidthLocal }}>{fields.price.label}</th>}
            </tr>
          </thead>
          <tbody>
            {localServiceRows.map((row: any, i: number) => {
              return <tr key={'jobform_services_local_' + i}
                className={(selected.findIndex((x: any) => x?.serviceItemId === row?.serviceItemId) > -1) ? 'active' : ''}
                onClick={(e) => {
                  if(isRorCB === RadioOrCheckbox.RADIO){
                    let selArr = _.cloneDeep(selected);
                    selArr = [row];
                    dispatch(slice.setSelected(selArr));
                    
                  } else {
                    if(!isDisabledCB(row, selected)){
                      let selArr = _.cloneDeep(selected);
                      let selectedIndex = selArr.findIndex((x: any) => x?.serviceItemId === row?.serviceItemId);
                      if(selectedIndex > -1){
                        selArr.splice(selectedIndex, 1);
                      } else {
                        selArr.push(row);
                      }
                      dispatch(slice.setSelected(selArr));
                    }
                  }
                }}
              >
                <td className='text-center'>
                  {
                    (isRorCB === RadioOrCheckbox.RADIO)
                    ?
                    <Radio checked={(selected.findIndex((x: any) => x?.serviceItemId === row?.serviceItemId) > -1)} />
                    :
                      (isRorCB === RadioOrCheckbox.CHECKBOX)
                      ?
                        (!isDisabledCB(row, selected))
                        ?
                        <Checkbox checked={(selected.findIndex((x: any) => x?.serviceItemId === row?.serviceItemId) > -1)} />
                        :
                        null
                      :
                      null
                  }
                </td>
                {includeLocalService && <td>{(row && row.customerName) ? row.customerName : ''}</td>}
                <td>{row.serviceTag}</td>
                <td>{row.binTypeName}</td>
                <td>{row.wasteTypeName}</td>
                {(hasPermission(Permissions.JobBilling)) && <td>{getCurrency() + formatNumber(row?.price)}</td>}
              </tr>
            })}
          </tbody>
        </StyledTable>

        {(localServiceRows && localServiceRows.length > 0) && <div className={'pt-12 pe-24 pb-16 ps-24 w-100 align-items-center justify-content-center'}>
          <Row className='align-items-center g-8'>
            <Col xs={{ span: 'auto', order: 1 }} md={{ span: 'auto', order: 1 }}>
              
            </Col>
            <Col xs={{ span: 12, order: 5 }} md={{ span: true, order: 2 }} className='text-center'>
              <Row className='align-items-center justify-content-center'>
                <Col xs={'auto'}>
                  <Pagination
                    showTitle={false}
                    showSizeChanger={false}
                    current={clientSideCurrentPageLocal}
                    pageSize={clientSideItemsPerPage}
                    pageSizeOptions={[clientSideItemsPerPage]}
                    total={localServices.length}
                    onChange={(page, pageSize) => {
                      dispatch(slice.setClientSideCurrentPageLocal((page)));
                    }}
                    itemRender={itemRenderPagination}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: true, order: 2 }} md={{ span: true, order: 3 }} className='d-flex d-md-none'></Col>
            <Col xs={{ span: 'auto', order: 3 }} md={{ span: 'auto', order: 4 }}>
              <Button
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  dispatch(slice.setClientSideCurrentPageLocal((clientSideCurrentPageLocal-1)));
                }}
                disabled={(clientSideCurrentPageLocal <= 1)}
              >
                <PrevIcon />
              </Button>
            </Col>
            <Col xs={{ span: 'auto', order: 4 }} md={{ span: 'auto', order: 5 }}>
              <Button
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  dispatch(slice.setClientSideCurrentPageLocal((clientSideCurrentPageLocal+1)));
                }}
                disabled={(clientSideCurrentPageLocal >= total)}
              >
                <NextIcon />
              </Button>
            </Col>
          </Row>
        </div>}
      </>
    } else {
      return <div className='text-center px-16 py-12'>No local services</div>
    }
  }

  
  return <>
    <Card className='card-list'>
      <Card.Header>
        <Row className='g-s4 align-items-center'>
          <Col xs={'auto'} className='semibold gray-800'>Local Service</Col>
          <Col xs={'auto'} className='semibold gray-800'>
            <Tooltip title={'A Local Service is unique and applies to single customer.'}><StyledInfoIcon /></Tooltip>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className='p-0'>
        {tableSection()}
      </Card.Body>
    </Card>
  </>
});

export default ListComponent;
