import React, { ReactNode } from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Steps } from "antd";

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice from './slice.tsx';

import Map from './Map.tsx';

import { mToKm } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

// import { ReactComponent as XIcon } from "../../../../../v5/assets/icons/x.svg";

import { LocationEnum, getStepDescription, getStepInfo } from '../../../manage/jobs/jobTypes/slice.tsx';

// ----------------------------------------------------------------------

const StyledTotalDistanceCard = styled.div`
  border: 1px solid var(--bs-gray-300);
  border-radius: 6px;
  padding: 12px 16px;

  .text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--bs-gray-600);
  }

  .numb {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--bs-gray-900);
  }
`;

const StyledListCard = styled.div`
  border: 1px solid var(--bs-gray-300);
  border-radius: 6px;
  padding: 12px 16px;

  ${up('lg')} {
    height: calc(-190px + 100vh);
  }

  ${down('lg')} {
    max-height: 100%;
  }
`;

const StyledList: React.FC<React.ComponentProps<typeof Col>> = styled(Col)`
  overflow: auto;
  min-height: 70px;
  max-height: 100%;

  .ant-steps {
    .ant-steps-item {
      .ant-steps-item-container {
        display: flex;
        align-items: center;

        .ant-steps-item-tail {
          top: 40%;
          padding: unset;

          &::after {
            background-color: transparent;
            background: transparent;
            border-left: 1px dashed var(--bs-gray-400);
            border-radius: 0px;
            left: -2px;
            top: 2px;
            position: relative;
          }
        }

        .ant-steps-item-icon {
          position: relative;
          margin-inline-end: 6px;

          .ant-steps-icon {
            position: relative;
            display: flex;
            width: 20px;
            height: 20px;

            span {
              position: absolute;
              width: 20px;
              height: 20px;
              border-radius: 50%;
              background-color: var(--bs-gray-600);
              color: white;
              font-size: 12px;
              font-weight: 600;
              line-height: 18px;
              text-align: center;
            }
          }
        }
        
        .ant-steps-item-content {
          width: 100%;
          padding: 6px 12px 6px 12px;
          margin-bottom: 8px;
          border-radius: 5px;
          background-color: var(--bs-blue-focus1);

          .ant-steps-item-title {
            color: var(--bs-gray-700);;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;

            .location {
              color: var(--bs-gray-800);;
              font-size: 14px;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
`;


const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, values } = useTypedSelector((state: RootState) => state.jobFormJobSummarySlice);
  const { defaultLocations, distance } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const onCancel = () => {
    dispatch(slice.setShow({ show: false, values: null }));
  }
  

  const mapElements = () => {
    return <Row className='g-24 align-items-center'>
      <Col xs={12}>
        <Map />
      </Col>
    </Row>
  }
  
  const listElements = () => {
    return <Row className='g-8 align-items-center'>
      <Col xs={12}>
        <StyledTotalDistanceCard>
          <Row className='g-8 align-items-center'>
            <Col xs={true} className={'text'}>Total distance</Col>
            <Col xs={'auto'} className={'numb'}>{mToKm(distance)}</Col>
          </Row>
        </StyledTotalDistanceCard>
      </Col>
      <Col xs={12}>
        <StyledListCard>
          <StyledList xs={12}>
            <Steps
              current={0}
              status={'wait'}
              direction="vertical"
              size='small'
              items={getStepInfo(values, defaultLocations, false, (stepObj: any) => {})}
            />
          </StyledList>
        </StyledListCard>
      </Col>
    </Row>
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '70%' }}
  >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Job Summary</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body className='p-0'>
      <Row className='g-0'>
        <Col xs={12} lg={7} xl={8}>
          <Modal.Body className='pe-lg-12'>
            {mapElements()}
          </Modal.Body>
        </Col>
        <Col xs={12} lg={5} xl={4}>
          <Modal.Body className='ps-lg-12'>
            {listElements()}
          </Modal.Body>
        </Col>
      </Row>
    </Offcanvas.Body>
  </Offcanvas>
}

export default PageComponent;
