import React, { Component } from 'react';
import moment from 'moment';
import _ from 'lodash';
import { diff } from "nested-object-diff";

import { fields, isStandardService } from '../../slice.tsx';
import { fields as billingFields } from '../../form/billingJob/slice.tsx';
import { fields as weightChitFields, toFixedDefaultUom } from '../../form/weightChit/slice.tsx';
import { getFirstBins, getFirstCustomerSites, isExchange1Type, isDisposalType, isShiftType, canWeightChit, getGroupsFromSteps, getBinIn, getBinOut, isEdit } from '../../../manage/jobs/jobTypes/slice.tsx';

import { isNumeric, dateFormat, dateTimeMeridianFormat, timeMeridianFormat, getCurrency, formatNumber } from '../../../../utils/utils';
import { ServiceType } from '../../../../utils/enums';

import {
    Row,
    Col,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Tooltip } from 'antd';


// ----------------------------------------------------------------------

const StyledWrapper = styled.div`
    border-radius: 6px;
    background-color: var(--bs-blue-focus1);

    .ant-divider {
        margin: 10px 0px 10px 0px;
        border-color: var(--bs-gray-300);
    }

    .v5-highlight {
        background-color: #fff2a8;
        color: black;
        padding-left: 5px;
        padding-right: 5px;
        cursor: help;
    }
    .v5-highlight-green {
        background-color: #a8ffaf;
        color: black;
        padding-left: 5px;
        padding-right: 5px;
    }
    .v5-highlight-red {
        background-color: #ffa8a8;
        color: black;
        padding-left: 5px;
        padding-right: 5px;
    }

    .v5-highlight-row {
        background-color: #fff2a8;
        color: black;
        cursor: help;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .v5-highlight-row-green {
        background-color: #a8ffaf;
        color: black;
        padding-top: 3px;
        padding-bottom: 3px;
    }
    .v5-highlight-row-red {
        background-color: #ffa8a8;
        color: black;
        padding-top: 3px;
        padding-bottom: 3px;
    }
`;

const StyledTable = styled.table`
  width: 100%;
  
  thead {
    tr {
      th {
        background-color: var(--bs-white);
        padding: 6px 16px;
            
        font-size: 11px;
        font-weight: 800;
        line-height: 16px;
        color: var(--bs-gray-800);
      }
    }
  }

  tbody {
    tr {
      td {
        background-color: var(--bs-white);

        vertical-align: top;
        padding: 6px 16px;
            
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        color: var(--bs-gray-900);
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }
  }
`;

// ----------------------------------------------------------------------


class HistoryDetails extends Component {
  
    constructor(props){
        super(props);

        let index = (props.index) ? props.index : 0;
        let last = (props.last) ? props.last : 0;

        let currentVersion = (props.currentVersion) ? props.currentVersion : null;
        let previousVersion = (props.previousVersion) ? props.previousVersion : null;

        currentVersion = this.prepareData(currentVersion);
        previousVersion = this.prepareData(previousVersion);

        let item = { ...previousVersion, ...currentVersion };
        var differences = diff(previousVersion, currentVersion);

        this.state = {
            index: index,
            last: last,
            previousVersion: previousVersion,
            item: item,
            diff: differences,
        }
    }


    findDiff = (field = null, arr = null) => {
        let obj = null;
        let diff = (arr) ? arr : this.state.diff;
        
        if(field && diff && diff.length > 0){
            let item = diff.filter(x => x.path === field);
            obj = (item && item.length > 0) ? item[0] : null;
        }

        return obj;
    }
    classNameDiff = (item = null, prefix = '') => {
        let cls = '';

        if(item){
            if(item.type === 'A'){
                cls = 'v5-highlight' + prefix + '-green';
            } else if(item.type === 'E'){
                cls = 'v5-highlight' + prefix;
            } else if(item.type === 'D'){
                cls = 'v5-highlight' + prefix + '-red';
            } else if(item.type === 'M'){
                cls = 'v5-highlight' + prefix;
            }
        }

        return cls;
    }
    classNameDiffRow = (item = null) => {
        return this.classNameDiff(item, '-row');
    }
    getDiffValue = (field = null, obj = null, callback = null) => {
        let value = '';

        if(field && obj){
            try {
                let fieldValue = field.split('.').reduce((o, i) => o[i], obj);
                value = (callback) ? callback(fieldValue) : fieldValue;
            } catch(e){}
        }

        return value;
    }
    getPreviousValue = (item = null, callback = null) => {
        let value = '';

        let fieldValue = (item && item.lhs && item.lhs !== '') ? item.lhs : null;
        value = (callback) ? callback(fieldValue) : fieldValue;

        return {
            title: (value && value !== '') ? 'Previous value: ' : 'No previous value!',
            value: (value && value !== '') ? value : '',
        }
    }
    getHighlightedItem = (item = null, field = null, callback = null, obj = null) => {
        let items = (obj) ? obj : this.state.item;
        
        let cls = this.classNameDiff(item);
        let value = this.getDiffValue(field, items, callback);
        let previousValue = this.getPreviousValue(item, callback);

        if(item){
            return <Tooltip placement="top" title={<>
                <div><small>{previousValue?.title}</small></div>
                <div><b>{previousValue?.value}</b></div>
            </>}>
                <span className={cls}>{value}</span>
            </Tooltip>
        } else {
            return <>{value}</>
        }
    }
    highlight = (field = null, callback = null, obj = null) => {
        let item = this.findDiff(field, obj);
        return this.getHighlightedItem(item, field, callback, obj);
    }
    highlightRow = (field = null, obj = null) => {
        let item = this.findDiff(field, obj);
        let cls = this.classNameDiffRow(item);
        return cls;
    }
    
    
    prepareData = (data = null) => {
        let item = _.cloneDeep(data);

        item = this.createStepGroups(item);
        item = this.getCustomerSite(item);
        item = this.getPhotos(item);
        item = this.getSignature(item);
        item = this.geBinInOut(item);
        item = this.getWeightChit(item);
        item = this.getStandardService(item);
        item = this.getAdditionalService(item);

        return item;
    }

    createStepGroups = (item = null) => {
        if(item){
            let steps = (item.steps && item.steps.length > 0) ? item.steps : [];
            
            let stepGroups = getGroupsFromSteps(steps, item?.jobBinTypes, isEdit());
            item['stepGroups'] = (stepGroups && stepGroups.length > 0) ? stepGroups : [];
        }

        return item;
    }
    getCustomerSite = (item = null) => {
        
        let customerSites = getFirstCustomerSites(item);

        if(item && customerSites && customerSites.length > 0){
            item['customerSite'] = (customerSites[0]) ? customerSites[0] : null;
            item['customerSite2'] = (customerSites[1]) ? customerSites[1] : null;
        }

        return item;
    }
    getPhotos = (item = null) => {
        if(item && item.attempts && item.attempts.length > 0){
            let attempt = item.attempts[0];

            item['jobPhotos'] = (attempt && attempt.jobPhotos && attempt.jobPhotos.length > 0) ? attempt.jobPhotos : [];
        }

        return item;
    }
    getSignature = (item = null) => {
        if(item && item.attempts && item.attempts.length > 0){
            let attempt = item.attempts[0];

            item['jobSignatures'] = (attempt && attempt.jobSignatures && attempt.jobSignatures.length > 0) ? attempt.jobSignatures : [];
        }

        return item;
    }
    geBinInOut = (item = null) => {
        let binOut = getBinOut(item);
        let binIn = getBinIn(item);
        
        item['binOut'] = (binOut && binOut.length > 0) ? binOut : null;
        item['binOutBinType'] = (binOut && binOut.length > 0) ? binOut.map(x => x?.binType?.binTypeName) : [];
        item['binOutWasteType'] = (binOut && binOut.length > 0) ? binOut.map(x => x?.wasteType?.wasteTypeName) : [];
        item['binOutBinNumber'] = (binOut && binOut.length > 0) ? binOut.map(x => x?.binNumber) : [];

        item['binIn'] = (binIn && binIn.length > 0) ? binIn : null;
        item['binInBinType'] = (binIn && binIn.length > 0) ? binIn.map(x => x?.binType?.binTypeName) : [];
        item['binInWasteType'] = (binIn && binIn.length > 0) ? binIn.map(x => x?.wasteType?.wasteTypeName) : [];
        item['binInBinNumber'] = (binIn && binIn.length > 0) ? binIn.map(x => x?.binNumber) : [];

        return item;
    }
    getWeightChit = (item = null) => {
        let isExchange1 = isExchange1Type(item?.stepGroups);
        let binsTypes = getFirstBins(item);
        let binType1 = (binsTypes && binsTypes.binType1) ? binsTypes.binType1 : null;
        let binType2 = (binsTypes && binsTypes.binType2) ? binsTypes.binType2 : null;
        let bin = isExchange1 ? binType2 : binType1;
        
        if(bin){
            item['weightChit'] = bin;
            item['weighChitCustomFields'] = bin?.weighChitCustomFields;
        }

        return item;
    }
    getStandardService = (item = null) => {
        if(item && item.pricings && item.pricings.length > 0){
            item['standardService'] = item.pricings.filter(x => isStandardService(x));
        }

        return item;
    }
    getAdditionalService = (item = null) => {
        if(item && item.pricings && item.pricings.length > 0){
            item['additionalService'] = item.pricings.filter(x => x.serviceType === ServiceType.AdditionalService);
        }

        return item;
    }


    setForm = () => {
        return <Row className="g-6 align-items-center">
            <>
                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.customerId.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('customerName')}</Col>
                    </Row>
                </Col>

                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.customerSiteId.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('customerSite.fullAddress')}</Col>
                    </Row>
                </Col>
                {isShiftType(this.state.item?.steps) && <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>Second {fields.customerSiteId.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('customerSite2.fullAddress')}</Col>
                    </Row>
                </Col>}

                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.jobDate.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('jobDate', (value) => (value) ? moment(value).format(dateFormat()) : '' )}</Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.jobTimeSpecific.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('jobTimeSpecific', (value) => (value) ? moment(value).format(timeMeridianFormat()) : '' )}</Col>
                    </Row>
                </Col>

                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.jobStatus.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('status.jobStatusName')}</Col>
                    </Row>
                </Col>
                {((this.state.item.status.jobStatusName == 'Completed')) && <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>Completed Date</Col>
                        <Col xs={12} md={7}>{this.highlight('jobAttemptCompletedDate', (value) => (value) ? moment(value).format(dateFormat()) : '' )}</Col>
                    </Row>
                </Col>}
                {((this.state.item.status.jobStatusName == 'Completed')) && <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>Billing Status</Col>
                        <Col xs={12} md={7}>{this.highlight('billingStatus')}</Col>
                    </Row>
                </Col>}
                {((this.state.item.billingStatus == 'Unbilled') && this.state.item.invoiceNumber) && <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{billingFields.invoiceNumber.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('invoiceNumber')}</Col>
                    </Row>
                </Col>}
                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.remarks.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('remarks')}</Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{billingFields.jobNumber.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('jobNumber')}</Col>
                    </Row>
                </Col>

                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.driverId.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('driverName')}</Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.vehicleId.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('vehicleName')}</Col>
                    </Row>
                </Col>

                {(this.state.item.binOut && this.state.item.binOut.length > 0) && <>
                    {(this.state.item.binOutBinType && this.state.item.binOutBinType.length > 0) && <>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>Bin Out</Col>
                                <Col xs={12} md={7}>
                                    {this.state.item.binOutBinType.map((item, i) => {
                                        return <span key={'binOutBinType_item_' + i}>
                                            {this.highlight('binOutBinType.' + i)}
                                            {(i < this.state.item.binOutBinType.length - 1) && <span>, </span>}
                                        </span>
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    </>}

                    {(this.state.item.binOutWasteType && this.state.item.binOutWasteType.length > 0) && <>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>Waste Out</Col>
                                <Col xs={12} md={7}>
                                    {this.state.item.binOutWasteType.map((item, i) => {
                                        return <span key={'binOutWasteType_item_' + i}>
                                            {this.highlight('binOutWasteType.' + i)}
                                            {(i < this.state.item.binOutWasteType.length - 1) && <span>, </span>}
                                        </span>
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    </>}

                    {(this.state.item.binOutBinNumber && this.state.item.binOutBinNumber.length > 0) && <>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>Bin Out ID</Col>
                                <Col xs={12} md={7}>
                                    {this.state.item.binOutBinNumber.map((item, i) => {
                                        return <span key={'binOutBinNumber_item_' + i}>
                                            {this.highlight('binOutBinNumber.' + i)}
                                            {(i < this.state.item.binOutBinNumber.length - 1) && <span>, </span>}
                                        </span>
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    </>}
                </>}

                {(this.state.item.binIn && this.state.item.binIn.length > 0) && <>
                    {(this.state.item.binInBinType && this.state.item.binInBinType.length > 0) && <>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>Bin In</Col>
                                <Col xs={12} md={7}>
                                    {this.state.item.binInBinType.map((item, i) => {
                                        return <span key={'binInBinType_item_' + i}>
                                            {this.highlight('binInBinType.' + i)}
                                            {(i < this.state.item.binInBinType.length - 1) && <span>, </span>}
                                        </span>
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    </>}

                    {(this.state.item.binInWasteType && this.state.item.binInWasteType.length > 0) && <>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>Waste In</Col>
                                <Col xs={12} md={7}>
                                    {this.state.item.binInWasteType.map((item, i) => {
                                        return <span key={'binInWasteType_item_' + i}>
                                            {this.highlight('binInWasteType.' + i)}
                                            {(i < this.state.item.binInWasteType.length - 1) && <span>, </span>}
                                        </span>
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    </>}

                    {(this.state.item.binInBinNumber && this.state.item.binInBinNumber.length > 0) && <>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>Bin In ID</Col>
                                <Col xs={12} md={7}>
                                    {this.state.item.binInBinNumber.map((item, i) => {
                                        return <span key={'binInBinNumber_item_' + i}>
                                            {this.highlight('binInBinNumber.' + i)}
                                            {(i < this.state.item.binInBinNumber.length - 1) && <span>, </span>}
                                        </span>
                                    })}
                                </Col>
                            </Row>
                        </Col>
                    </>}
                </>}

                {canWeightChit(this.state.item?.stepGroups) && <>
                    <Col xs={12}>
                        <Row className="align-items-center">
                            <Col xs={12} md={5} className='medium'>Bin Weight</Col>
                            <Col xs={12} md={7}>{this.highlight('weightChit.binWeight')}</Col>
                        </Row>
                    </Col>
                    {isDisposalType(this.state.item?.stepGroups) && <Col xs={12}>
                        <Row className="align-items-center">
                            <Col xs={12} md={5} className='medium'>Bin Disposed</Col>
                            <Col xs={12} md={7}>{this.highlight('weightChit.binOutWeight')}</Col>
                        </Row>
                    </Col>}
                </>}

                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.amountToCollect.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('customerSite.amountToCollect', (value) => {
                            return getCurrency() + formatNumber(value);
                        })}</Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.collectedAmount.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('customerSite.collectedAmount', (value) => {
                            return getCurrency() + formatNumber(value);
                        })}</Col>
                    </Row>
                </Col>
                <Col xs={12}>
                    <Row className="align-items-center">
                        <Col xs={12} md={5} className='medium'>{fields.jobPaymentType.label}</Col>
                        <Col xs={12} md={7}>{this.highlight('customerSite.jobPaymentType', (value) => {
                            if(value === 3){
                                return "Others";
                            } else if(value === 2){
                                return "Transfer";
                            } else if(value === 1){
                                return "Cheque";
                            } else {
                                return "Cash";
                            }
                        })}</Col>
                    </Row>
                </Col>
            </>

            <Col xs={12}>
                <Row className="g-6 align-items-center mt-24">
                    <Col xs={12} className='mb-6'><b>PHOTOS</b></Col>
                    <Col xs={12}>
                        {
                            (this.state.item.jobPhotos && this.state.item.jobPhotos.length > 0)
                            ?
                            this.state.item.jobPhotos.map((itm, i) => {
                                return <div key={'photoUrl_' + i} className={'d-inline-block ps-2 pe-2'}>
                                    <img src={itm.photoUrl} alt={'Job'} style={{ objectFit: 'cover', width: '150px', height: '150px' }} className={'img-thumbnail'}></img>
                                </div>
                            })
                            :
                            <span>No photos</span>
                        }
                    </Col>
                </Row>
            </Col>

            {(this.state.item.jobSignatures && this.state.item.jobSignatures.length > 0) && <Col xs={12}>
                <Row className="g-6 align-items-center mt-24">
                    <Col xs={12} className='mb-6'><b>SIGNATURE</b></Col>
                    <Col xs={12}>
                        {this.state.item.jobSignatures.map((itm, i) => {
                            return <div key={'jobSignatures_' + i} className={'d-inline-block ps-2 pe-2'}>
                                <img src={itm.jobSignatureUrl} alt={'Job'} style={{ objectFit: 'contain', width: '150px', height: '150px' }} className={'img-thumbnail'}></img>
                            </div>
                        })}
                    </Col>
                    <Col xs={12}>
                        <Row className="align-items-center">
                            <Col xs={12} md={5} className='medium'>{fields.jobSignedUserName.label}</Col>
                            <Col xs={12} md={7}>{this.highlight('jobSignatures.0.jobSignedUserName')}</Col>
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row className="align-items-center">
                            <Col xs={12} md={5} className='medium'>{fields.jobSignedUserContact.label}</Col>
                            <Col xs={12} md={7}>{this.highlight('jobSignatures.0.jobSignedUserContact')}</Col>
                        </Row>
                    </Col>
                </Row>
            </Col>}

            {canWeightChit(this.state.item?.stepGroups) && <>
                <Col xs={12}>
                    <Row className="g-6 align-items-center mt-24">
                        <Col xs={12} className='mb-6'><b>WEIGH CHIT</b></Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.weightChitTicketNumber.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.weightChitTicketNumber')}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.binWeight.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.binWeight', (value) => {
                                    return toFixedDefaultUom(value);
                                })}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.tareBinWeight.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.tareBinWeight', (value) => {
                                    return toFixedDefaultUom(value);
                                })}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.netBinWeight.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.netBinWeight', (value) => {
                                    return toFixedDefaultUom(value);
                                })}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.nonBillableBinWeight.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.nonBillableBinWeight', (value) => {
                                    return toFixedDefaultUom(value);
                                })}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.billableBinWeight.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.billableBinWeight', (value) => {
                                    return toFixedDefaultUom(value);
                                })}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.weightChitRemarks.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.weightChitRemarks')}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.binWeighBy.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.binWeighBy')}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.weightChitDateTime.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.weightChitDateTime', (value) => {
                                    return (value) ? moment(value).format(dateTimeMeridianFormat()) : '';
                                })}</Col>
                            </Row>
                        </Col>
                        <Col xs={12}>
                            <Row className="align-items-center">
                                <Col xs={12} md={5} className='medium'>{weightChitFields.weightChitOutDateTime.label}</Col>
                                <Col xs={12} md={7}>{this.highlight('weightChit.weightChitOutDateTime', (value) => {
                                    return (value) ? moment(value).format(dateTimeMeridianFormat()) : '';
                                })}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>

                {(this.state.item.weighChitCustomFields && this.state.item.weighChitCustomFields.length > 0) && <Col xs={12}>
                    <Row className="g-6 align-items-center mt-24">
                        <Col xs={12} className='mb-6'><b>CUSTOM FIELDS</b></Col>

                        {this.state.item.weighChitCustomFields.map((item: any, i: number) => {
                            return <Col xs={12} key={'audittrail_weightchit_customField_item_' + i}>
                                <Row className="align-items-center">
                                    <Col xs={12} md={5} className='medium'>{this.highlight('weighChitCustomFields.' + i + '.accountCustomField.label')}</Col>
                                    <Col xs={12} md={7}>{this.highlight('weighChitCustomFields.' + i + '.value')}</Col>
                                </Row>
                            </Col>
                        })}

                    </Row>
                </Col>}
            </>}

            {(this.state.item.standardService && this.state.item.standardService.length > 0) && <Col xs={12}>
                <Row className="g-6 align-items-center mt-24">
                    <Col xs={12} className='mb-6'><b>STANDARD SERVICE</b></Col>
                    <Col xs={12}>
                        <StyledTable>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>{billingFields.serviceTag.label}</th>
                                    <th>{fields.binTypeId.label}</th>
                                    <th>{fields.wasteTypeId.label}</th>
                                    <th>{billingFields.chargeCategoryId.label}</th>
                                    <th>{billingFields.quantity.label}</th>
                                    <th>{billingFields.rate.label}</th>
                                    <th>{billingFields.price.label}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.item.standardService.map((item: any, i: number) => {
                                    return <tr key={'audittrail_body_row_standard_' + i}>
                                        <td>{(i+1)}</td>
                                        <td>{this.highlight('standardService.' + i + '.serviceTag')}</td>
                                        <td>{this.highlight('standardService.' + i + '.binType.binTypeName')}</td>
                                        <td>{this.highlight('standardService.' + i + '.wasteType.wasteTypeName')}</td>
                                        <td>{this.highlight('standardService.' + i + '.chargeCategory.chargeCategoryName')}</td>
                                        <td>
                                            {this.highlight('standardService.' + i + '.quantity', (val) => {
                                                return formatNumber(val, '0,0.000')
                                            })}
                                        </td>
                                        <td>
                                            {this.highlight('standardService.' + i + '.price', (val) => {
                                                return formatNumber(val)
                                            })}
                                        </td>
                                        <td>
                                            {this.highlight('standardService.' + i + '.price', (val) => {
                                                return formatNumber(parseFloat(item.quantity) * parseFloat(item.price))
                                            })}
                                        </td>
                                    </tr>
                                })}
                                
                            </tbody>
                        </StyledTable>
                    </Col>
                </Row>
            </Col>}

            {(this.state.item.additionalService && this.state.item.additionalService.length > 0) && <Col xs={12}>
                <Row className="g-6 align-items-center mt-24">
                    <Col xs={12} className='mb-6'><b>ADDITIONAL SERVICE</b></Col>
                    <Col xs={12}>
                        <StyledTable>
                            <thead>
                                <tr>
                                    <th>No</th>
                                    <th>{billingFields.additionalServices.label}</th>
                                    <th>{billingFields.chargeCategoryId.label}</th>
                                    <th>{billingFields.quantity.label}</th>
                                    <th>{billingFields.rate.label}</th>
                                    <th>{billingFields.price.label}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.item.additionalService.map((item: any, i: number) => {
                                    return <tr key={'audittrail_body_row_additional_' + i}>
                                        <td>{(i+1)}</td>
                                        <td>{this.highlight('additionalService.' + i + '.serviceTag')}</td>
                                        <td>{this.highlight('additionalService.' + i + '.chargeCategory.chargeCategoryName')}</td>
                                        <td>
                                            {this.highlight('additionalService.' + i + '.quantity', (val) => {
                                                return formatNumber(val, '0,0.000')
                                            })}
                                        </td>
                                        <td>
                                            {this.highlight('additionalService.' + i + '.price', (val) => {
                                                return formatNumber(val)
                                            })}
                                        </td>
                                        <td>
                                            {this.highlight('additionalService.' + i + '.price', (val) => {
                                                return formatNumber(parseFloat(item.quantity) * parseFloat(item.price))
                                            })}
                                        </td>
                                    </tr>
                                })}
                                
                            </tbody>
                        </StyledTable>
                    </Col>
                </Row>
            </Col>}

        </Row>
    }


    render() {
        return <StyledWrapper className={'px-8 py-12'}>
            {this.setForm()}
        </StyledWrapper>
    }
}

export default HistoryDetails;
