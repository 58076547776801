/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
// import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import styled from 'styled-components';
import type { PaginationProps } from 'antd';

import { findStatus } from '../../slice';
import { LocalJobType, ForWith } from '../../../manage/jobs/jobTypes/slice';

import { axios } from '../../../../../v5/utils/axios';
import { success, successAPI, errorAPI, isNumeric } from '../../../../../v5/utils/utils';
// import Yup from '../../../../../v5/utils/yup';
// import {  } from '../../../../../v5/utils/enums';

import { ReactComponent as InfoIcon } from "../../../../assets/icons/i.svg";


export const RadioOrCheckbox = {
  NONE: 0,
  RADIO: 1,
  CHECKBOX: 2,
}

export const fields = {
  binOutDate: {
    id: 'binOutDate',
    label: 'Complete date',
    placeholder: '',
  },
  binTypeName: {
    id: 'binTypeName',
    label: 'Bin type',
    placeholder: '',
  },
  wasteTypeName: {
    id: 'wasteTypeName',
    label: 'For Waste Type',
    placeholder: '',
  },
  binNumber: {
    id: 'binNumber',
    label: 'Bin ID',
    placeholder: '',
  },
  jobNumber: {
    id: 'jobNumber',
    label: 'DO number',
    placeholder: '',
  },
  driverName: {
    id: 'driverName',
    label: 'Driver',
    placeholder: '',
  },
  vehicleName: {
    id: 'vehicleName',
    label: 'Vehicle',
    placeholder: '',
  },
  customerName: {
    id: 'customerName',
    label: 'Customer Name',
    placeholder: '',
  },
  serviceTag: {
    id: 'serviceTag',
    label: 'Name',
    placeholder: '',
  },
  price: {
    id: 'price',
    label: 'Price',
    placeholder: '',
  },
};

export const  StyledInfoIcon = styled(InfoIcon)`
  position: relative;
  top: 0px;
  width: 18px;
  height: 19px;
  margin-left: 5px;
  cursor: pointer;

  path {
    stroke: var(--bs-gray-400);
  }
`;
export const  StyledTable = styled.table`
  width: 100%;

  tr:first-child th:first-child {
    border-top-left-radius: 0px;
  }

  tr:first-child th:last-child {
    border-top-right-radius: 0px;
  }

  thead {
    tr {
      th {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
      }
    }
  }

  tbody {
    tr {
      td {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;

        cursor: pointer;
      }

      &:hover, &.active {
        background-color: var(--bs-blue-focus1);
      }
    }
  }
`;

export const itemRenderPagination: PaginationProps['itemRender'] = (_: any, type: any, originalElement: any) => {
  if (type === 'prev') {
    return null;
  }
  if (type === 'next') {
    return null;
  }
  return originalElement;
}
export const getPaginationCount = (totalRows: any, rowsPerPageCount: any) => {
  let mod: any = totalRows % rowsPerPageCount;
  let pages: any = totalRows / rowsPerPageCount;
  return (mod > 0) ? (parseInt(pages) + 1) : pages;
}
export const clientSideRows = (rows: any = [], currentPage: any = 1, clientSideItemsPerPage = 5) => {
  let data = (rows && rows.length > 0) ? rows : [];

  const indexOfLastItem = currentPage * clientSideItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - clientSideItemsPerPage;
  const currentData = data.slice(indexOfFirstItem, indexOfLastItem);

  return (currentData && currentData.length > 0) ? currentData : [];
}
export const changePaginationToSelectedItem = (rows: any, serviceItem: any, clientSideItemsPerPage: any, callback: (pageNumber: any, tab: any) => void) => {
  if(rows && rows.length > 0){
    let localServices = rows.filter((x: any) => isNumeric(x.customerId));
    let globalServices = rows.filter((x: any) => (x.customerId === null));
    
    if(serviceItem){
      if(localServices && localServices.length > 0){
        let pageNumber = getPageNumber(localServices, serviceItem?.serviceItemId, clientSideItemsPerPage);

        if(pageNumber != -1){
          callback(pageNumber, 'tab1');
        }
      }
      
      if(globalServices && globalServices.length > 0){
        let pageNumber = getPageNumber(globalServices, serviceItem?.serviceItemId, clientSideItemsPerPage);

        if(pageNumber != -1){
          callback(pageNumber, 'tab2');
        }
      }
    }
  }
}
export const getPageNumber = (data: any, serviceItemId: any, clientSideItemsPerPage: any) => {
  if(data && data.length > 0){
    const index = data.findIndex((item: any) => item.serviceItemId == serviceItemId);
    if (index === -1) {
      return -1;
    }
    return Math.floor(index / clientSideItemsPerPage) + 1;
  } else {
    return -1;
  }
}

export const isRadioOrCheckbox = (values: any, group: any, block: any) => {
  let status = findStatus(values?.jobTemplate?.templateStatuses, 'completed');
  if (status && values?.statusId === status?.jobStatusId) {
    if(group?.type === LocalJobType.EXCHANGE1 && block?.isForWith === ForWith.WITH){
      return RadioOrCheckbox.CHECKBOX;
    } else {
      return RadioOrCheckbox.RADIO;
    }
  } else {
    return RadioOrCheckbox.RADIO;
  }
}
export const isDisabledCB = (row: any, selectedItems: Array<any>) => {
  if (selectedItems.length === 0) {
    return false; // If nothing is selected, all rows remain enabled
  }

  const firstSelected = selectedItems[0]; // The first selected row determines the binTypeId
  const selectedWasteTypes = new Set(selectedItems.map(item => item.wasteTypeId));

  // If the current row is already selected, it should remain enabled
  if (selectedItems.some(item => 
    item.binTypeId === row.binTypeId && 
    item.wasteTypeId === row.wasteTypeId && 
    item.serviceItemId === row.serviceItemId
  )) {
    return false;
  }

  // If the current row has a different binTypeId than the first selected row, disable it
  if (row.binTypeId !== firstSelected.binTypeId) {
    return true;
  }

  // If the current row has the same wasteTypeId as an already selected row, disable it
  if (selectedWasteTypes.has(row.wasteTypeId)) {
    return true;
  }

  return false; // If none of the conditions apply, the row remains enabled
}


interface InitState {
  show: boolean,
  tab: string,
  values: any,
  customerSiteId: number|null,

  groupIndex: number|null,
  group: any,
  
  blockIndex: number|null,
  block: any,

  index: number|null,
  serviceItem: any,
  wasteTypes: Array<any>

  selected: Array<any>,
  selectedHistory: any,

  includeLocalService: boolean,
  search: string,

  clientSideCurrentPageLocal: number,
  clientSideCurrentPageGlobal: number,
  clientSideCurrentPageHistory: number,
  clientSideItemsPerPage: number,
  clientSideItemsPerPageHistory: number,
  
  isLoadingLocalGlobal: boolean,
  listLocalGlobal: Array<any>,
  rows: Array<any>,

  isLoadingHistory: boolean,
  listHistory: Array<any>,
  rowsHistory: Array<any>,

  isLoadingService: boolean,
  service: any,

  isLoadingServicesItems: boolean,
  serviceItems: Array<any>,
}

function NewReducer() {
  const name = 'jobFormBinDetailsServicesSlice';


  const initialState: InitState = {
    show: false,
    tab: 'tab1',
    values: 'tab1',
    customerSiteId: null,
    
    groupIndex: null,
    group: null,
    
    blockIndex: null,
    block: null,

    index: null,
    serviceItem: null,
    wasteTypes: [],

    selected: [],
    selectedHistory: null,

    includeLocalService: false,
    search: '',

    clientSideCurrentPageLocal: 1,
    clientSideCurrentPageGlobal: 1,
    clientSideCurrentPageHistory: 1,
    clientSideItemsPerPage: 5,
    clientSideItemsPerPageHistory: 7,

    isLoadingLocalGlobal: false,
    listLocalGlobal: [],
    rows: [],

    isLoadingHistory: false,
    listHistory: [],
    rowsHistory: [],

    isLoadingService: false,
    service: null,

    isLoadingServicesItems: false,
    serviceItems: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, values: any, customerSiteId: number|null, groupIndex: number| null, group: any, blockIndex: number| null, block: any, index: number| null, serviceItem: any, wasteTypes: Array<any> }>) => {
      state.show = action.payload.show;
      state.values = action.payload.values;
      state.customerSiteId = action.payload.customerSiteId;
      
      state.groupIndex = action.payload.groupIndex;
      state.group = action.payload.group;
      
      state.blockIndex = action.payload.blockIndex;
      state.block = action.payload.block;

      state.index = action.payload.index;
      state.serviceItem = action.payload.serviceItem;
      state.wasteTypes = action.payload.wasteTypes;
    },
    setTab: (state: InitState, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    setIncludeLocalService: (state: InitState, action: PayloadAction<boolean>) => {
      state.includeLocalService = action.payload;
    },
    setSearch: (state: InitState, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSelected: (state: InitState, action: PayloadAction<Array<any>>) => {
      state.selected = action.payload;
    },
    setSelectedHistory: (state: InitState, action: PayloadAction<any>) => {
      state.selectedHistory = action.payload;
    },
    
    setIsLoadingLocalGlobal: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoadingLocalGlobal = action.payload;
    },
    setClientSideCurrentPageLocal: (state: InitState, action: PayloadAction<number>) => {
      state.clientSideCurrentPageLocal = action.payload;
    },
    setClientSideCurrentPageGlobal: (state: InitState, action: PayloadAction<number>) => {
      state.clientSideCurrentPageGlobal = action.payload;
    },
    setClientSideCurrentPageHistory: (state: InitState, action: PayloadAction<number>) => {
      state.clientSideCurrentPageHistory = action.payload;
    },
    setClientSideItemsPerPage: (state: InitState, action: PayloadAction<number>) => {
      state.clientSideItemsPerPage = action.payload;
    },
    setLocalGlobal: (state: InitState, action: PayloadAction<{ rows: Array<any>, rowsHistory: Array<any>, clientSideCurrentPageLocal: number, clientSideCurrentPageGlobal: number, clientSideCurrentPageHistory: number, isLoadingLocalGlobal: boolean }>) => {
      state.rows = action.payload.rows;
      state.rowsHistory = action.payload.rowsHistory;
      state.clientSideCurrentPageLocal = action.payload.clientSideCurrentPageLocal;
      state.clientSideCurrentPageGlobal = action.payload.clientSideCurrentPageGlobal;
      state.clientSideCurrentPageHistory = action.payload.clientSideCurrentPageHistory;
      state.isLoadingLocalGlobal = action.payload.isLoadingLocalGlobal;
    },

    startReadLocalGlobal: (state: InitState) => {
      state.isLoadingLocalGlobal = true;
      state.listLocalGlobal = [];
      state.rows = [];
    },
    finishReadLocalGlobal: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.listLocalGlobal = data;
      state.rows = data;
      state.isLoadingLocalGlobal = false;
    },

    startReadHistory: (state: InitState) => {
      state.isLoadingHistory = true;
      state.listHistory = [];
      state.rowsHistory = [];
    },
    finishReadHistory: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.listHistory = data;
      state.rowsHistory = data;
      state.isLoadingHistory = false;
    },

    startDetailsService: (state: InitState) => {
      state.isLoadingService = true;
    },
    finishDetailsService: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingService = false;
      state.service = action.payload;
    },

    startDetailsServicesItems: (state: InitState) => {
      state.isLoadingServicesItems = true;
    },
    finishDetailsServicesItems: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingServicesItems = false;
      state.serviceItems = action.payload;
    },
  };


  const apis = {
    callReadLocalGlobalApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadLocalGlobal());

      await axios.get('service-item/compact', { params: params }).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        if(callback){
          callback(true, data);
        }
        dispatch(actions.finishReadLocalGlobal(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        if(callback){
          callback(false, null);
        }
        dispatch(actions.finishReadLocalGlobal(null));
      });
    },
    callReadHistoryApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadHistory());

      await axios.get('report/bin-out-detail', { params: params }).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishReadHistory(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishReadHistory(null));
      });
    },
    
    callDetailsServiceApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsService());

      await axios.get('service-item/' + id).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishDetailsService(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDetailsService(null));
      });
    },
    callDetailsServicesItemsApi: (ids: Array<any>, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsServicesItems());
    
      try {
        const results = await Promise.all(ids.map(id => 
          axios.get(`service-item/${id}`).then((result: any) => result.data)
        ));
    
        callback(true, results);
        dispatch(actions.finishDetailsServicesItems(results));
      } catch (error) {
        errorAPI(error);
    
        callback(false, null);
        dispatch(actions.finishDetailsServicesItems(null));
      }
    }
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();