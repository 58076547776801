import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  Card,
  ListGroup,
  Form,
  InputGroup,
} from 'react-bootstrap';
// import styled from 'styled-components';

import { Radio, Divider } from 'antd';
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { FormikContext, initialValues, formSchema, prepareForm, prepareData } from './slice.tsx';

import { getDriverAppOptionsStepInfo, getStepDescription, DriverAppOptionsLabelEnum, CustomOptions, BinActivityEnum } from '../../../manage/jobs/jobTypes/slice.tsx';
import { StyledRow, StyledStep, StyledInputGroup } from '../../../manage/jobs/jobTypes/form/Tab3.tsx';

import { maxInputNumber12, maxInputNumber012 } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

import { ReactComponent as PlusIcon } from "../../../../../v5/assets/icons/plus-2.svg";
import { ReactComponent as MinusIcon } from "../../../../../v5/assets/icons/minus.svg";

// ----------------------------------------------------------------------

const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, details } = useTypedSelector((state: RootState) => state.jobFormDriverOptionsSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useEffect(() => {
    if(show){
      let form = prepareForm(details);
      setValues(form);
    }
  }, [show, details])

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema(null),
    onSubmit: values => {
      if(props.onSave){
        let form = prepareData(values);
        props.onSave(form?.steps);
      }

      onCancel();
    },
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


  const onCancel = () => {
    dispatch(slice.setShow({ show: false, details: null }));
    dispatch(slice.resetSlice());
  }
  

  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        <Button
          variant={'custom-text'}
          size={'sm'}
          className='me-12'
          onClick={(e) => {
            onCancel();
          }}
        >Cancel</Button>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          onClick={(e) => {
            handleSubmit();
          }}
        >Save Changes</Button>
      </Col>
    </Row>
  }

  const setFormDriverPermissons = () => {
    return <Row className='g-16'>
      <StyledStep xs={12}>
        <Row className='g-12'>
          <Col xs={12}>
            <Row className='align-items-center'>
              <Col xs={12} lg={6} className='semibold gray-900 pb-1 pb-lg-0'>Driver’s action</Col>
              <Col xs={true} lg={2} className='semibold gray-900 text-center'>Yes</Col>
              <Col xs={true} lg={2} className='semibold gray-900 text-center'>No</Col>
              <Col xs={true} lg={2} className='semibold gray-900 text-center'>Optional</Col>
            </Row>
          </Col>
          
          {!(values.steps && values.steps.length > 0 && values.steps[0].binActivity === BinActivityEnum.LoadWaste) && <>
            <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.RequiredBinID}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isRequireBinNumber === true)}
                    onChange={(e) => {
                      setFieldValue('isRequireBinNumber', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isRequireBinNumber === false)}
                    onChange={(e) => {
                      setFieldValue('isRequireBinNumber', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
              </StyledRow>
            </Col>
          </>}

          {!(values.steps && values.steps.length > 0 && values.steps[0].binActivity === BinActivityEnum.LoadWaste) && <>
            <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ChangeBin}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.canDriverChangeBinType === true)}
                    onChange={(e) => {
                      setFieldValue('canDriverChangeBinType', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.canDriverChangeBinType === false)}
                    onChange={(e) => {
                      setFieldValue('canDriverChangeBinType', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
              </StyledRow>
            </Col>
          </>}

          <Col xs={12}>
            <StyledRow className='align-items-center'>
              <Col xs={12}><Divider /></Col>
              <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ChangeWaste}</Col>
              <Col xs={true} lg={2} className='text-center'>
                <Radio
                  disabled={false}
                  checked={(values.canDriverChangeWasteType === true)}
                  onChange={(e) => {
                    setFieldValue('canDriverChangeWasteType', true);
                  }}
                />
              </Col>
              <Col xs={true} lg={2} className='text-center'>
                <Radio
                  disabled={false}
                  checked={(values.canDriverChangeWasteType === false)}
                  onChange={(e) => {
                    setFieldValue('canDriverChangeWasteType', false);
                  }}
                />
              </Col>
              <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
            </StyledRow>
          </Col>

          {!(values.steps && values.steps.length > 0 && values.steps[0].binActivity === BinActivityEnum.LoadWaste) && <>
            <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.NewBinID}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isEditableBinNumber === true)}
                    onChange={(e) => {
                      setFieldValue('isEditableBinNumber', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isEditableBinNumber === false)}
                    onChange={(e) => {
                      setFieldValue('isEditableBinNumber', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
              </StyledRow>
            </Col>
          </>}
        </Row>
      </StyledStep>
    </Row>
  }

  const setFormDriverActivity = () => {
    return <Row className='g-16'>

      {
      (values.steps && values.steps.length > 0)
      ?
      values.steps.map((item: any, i: number) => {
        let stepName: any = getStepDescription(i, item);
        let stepInfo: any = getDriverAppOptionsStepInfo(i, item, values.steps);
        
        return <StyledStep key={'step_tab3_' + i} xs={12}>
          <Row className='g-12'>
            <Col xs={12}>
              <Row className='align-items-center'>
                <Col xs={12} lg={6} className='semibold gray-900 pb-1 pb-lg-0'>Step {i+1}: {stepName}</Col>
                <Col xs={true} lg={2} className='semibold gray-900 text-center'>Yes</Col>
                <Col xs={true} lg={2} className='semibold gray-900 text-center'>No</Col>
                <Col xs={true} lg={2} className='semibold gray-900 text-center'>Optional</Col>
              </Row>
            </Col>

            {stepInfo?.TakePhoto && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>
                  <Row className='align-items-center'>
                    <Col xs={'auto'} style={{ userSelect: 'none' }}>{DriverAppOptionsLabelEnum.TakePhoto}</Col>
                    <Col xs={'auto'}>
                      <Form.Group>
                        <StyledInputGroup>
                          <InputGroup.Text
                            onClick={() => {
                              if(item.mustTakePhoto !== CustomOptions.No){
                                const minNumb = ((item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) ? 1 : 0;
                              
                                if(item.numberofPhotosRequired > minNumb){
                                  setFieldValue('steps.' + i + '.numberofPhotosRequired', (item.numberofPhotosRequired-1));
                                }
                              }
                            }}
                          >
                            <MinusIcon />
                          </InputGroup.Text>
                          <Form.Control
                            className='custom'
                            as={CurrencyInput}
                            allowDecimals={false}
                            allowNegativeValue={false}
                            decimalsLimit={0}
                            maxLength={2}

                            disabled={(item.mustTakePhoto === CustomOptions.No) ? true : false}
                            autoComplete='off'
                            placeholder={''}
                            value={item.numberofPhotosRequired}
                            onValueChange={(value: any) => {
                              const minNumb = ((item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) ? 1 : 0;
                              
                              if(value > minNumb){
                                setFieldValue('steps.' + i + '.numberofPhotosRequired', value);
                              }
                            }}
                            isInvalid={false}
                          />
                          <InputGroup.Text
                            onClick={() => {
                              if(item.mustTakePhoto !== CustomOptions.No){
                                setFieldValue('steps.' + i + '.numberofPhotosRequired', (item.numberofPhotosRequired+1));
                              }
                            }}
                          >
                            <PlusIcon />
                          </InputGroup.Text>
                        </StyledInputGroup>
                      </Form.Group>
                    </Col>
                    <Col xs={'auto'} style={{ userSelect: 'none' }}>{DriverAppOptionsLabelEnum.TakePhotoSuffix}</Col>
                  </Row>
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakePhoto === CustomOptions.Yes)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.mustTakePhoto', CustomOptions.Yes);
                      setFieldValue('steps.' + i + '.numberofPhotosRequired', 1);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakePhoto === CustomOptions.No)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.mustTakePhoto', CustomOptions.No);
                      setFieldValue('steps.' + i + '.numberofPhotosRequired', 0);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakePhoto === CustomOptions.Optional)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.mustTakePhoto', CustomOptions.Optional);
                      setFieldValue('steps.' + i + '.numberofPhotosRequired', 1);
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {stepInfo?.Signature && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.Signature}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakeSignature === CustomOptions.Yes)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.mustTakeSignature', CustomOptions.Yes);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakeSignature === CustomOptions.No)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.mustTakeSignature', CustomOptions.No);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakeSignature === CustomOptions.Optional)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.mustTakeSignature', CustomOptions.Optional);
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {stepInfo?.InputBinWeight && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.InputBinWeight}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireBinWeight === true)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.isRequireBinWeight', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireBinWeight === false)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.isRequireBinWeight', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={true}
                    checked={false}
                    onChange={(e) => {
                      
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {stepInfo?.ReviewWasteType && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ReviewWasteType}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireReviewWasteType === true)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.isRequireReviewWasteType', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireReviewWasteType === false)}
                    onChange={(e) => {
                      setFieldValue('steps.' + i + '.isRequireReviewWasteType', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={true}
                    checked={false}
                    onChange={(e) => {
                      
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {!(
              stepInfo?.TakePhoto ||
              stepInfo?.Signature ||
              stepInfo?.InputBinWeight ||
              stepInfo?.ReviewWasteType
            ) &&  <Col xs={12}>No settings</Col>}
          </Row>
        </StyledStep>
      })
      :
      <Col xs={12}>No settings</Col>
      }
    </Row>
  }
  

  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '60%' }}
  >
    <FormikContext.Provider value={formik}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>
          <div>Driver Options</div>
          <div className='regular fs-14 gray-600'>Job-based rules and permission on Driver’s mobile app</div>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Row className='g-24'>
          {((values.steps && values.steps.length > 0) && !(values.steps.every((x: any) => x.binActivity === BinActivityEnum.NoActivity))) && <>
            <Col xs={12}>
              <Row className='g-8'>
                <Col xs={12}>
                  <span className="large medium gray-900">Driver’s permission</span>
                </Col>
                <Col xs={12}>
                  <span className="gray-800">Determine what driver can do or cannot do on his mobile app.</span>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              {setFormDriverPermissons()}
            </Col>
          </>}

          <Col xs={12}>
            <Row className='g-8'>
              <Col xs={12}>
                <span className="large medium gray-900">Driver’s activities</span>
              </Col>
              <Col xs={12}>
                <span className="gray-800">Determine what driver must accomplish for each job step.</span>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            {setFormDriverActivity()}
          </Col>
        </Row>
      </Offcanvas.Body>
      <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
    </FormikContext.Provider>
  </Offcanvas>
}

export default PageComponent;
