import React, { Suspense } from 'react'
import { Route, Redirect, Switch, useLocation } from 'react-router-dom'

import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';

import FallbackView from '../../_metronic/partials/content/fallback-view/FallbackView'

import Utils from '../utils/utils';
import { PagesPermissions, UserCookieSettingType, BillingTypes } from "../utils/enums";

import SignalRDownload from "../components/signalR/SignalRDownload";

import RouteLayout from "./RouteLayout";
import RedirectLayout from "./RedirectLayout";

import PageLayout from "../layout/PageLayout.jsx";
import PagePanelLayout from "../layout/PagePanelLayout.jsx";

import {Maintenance} from "../views/errors/layout/Maintenance";

import Dashboard from "../views/dashboard/Dashboard";
import Map from "../views/map/Map";
import JobsView from "../views/jobs/JobsView";
import GranttChartView from "../views/jobs/GranttChartView";
import GranttChartPage from "../views/jobs/GanttChart/Page";
import JobsForm from "../views/jobs/JobsForm";
import JobIframe from "../views/jobs/JobIframe";
import CustomerList from "../views/Customers/CustomerList";
import CustomerForm from "../views/Customers/partial/CustomerForm";
import CustomerOverview from "../views/Customers/partial/CustomerOverview";
import LocalServiceApplicableSites from "../views/Customers/partial/LocalServiceApplicableSites";
import CustomerLocalServices from "../views/Customers/partial/CustomerLocalServices";
import LocalServiceOverview from "../views/Customers/partial/LocalServiceOverview";
import LocalServiceHistory from "../views/Customers/partial/LocalServiceHistory";
import CustomerSites from "../views/Customers/partial/CustomerSites";
import CustomerSitesOverview from "../views/Customers/partial/CustomerSitesOverview";
import CustomerSitesApplicableServices from "../views/Customers/partial/CustomerSitesApplicableServices";
import CustomerSiteHistory from "../views/Customers/partial/CustomerSiteHistory";
import CustomerEmail from "../views/Customers/partial/CustomerEmail";

import BillingInvoiceListByDate from "../views/billings/BillingInvoiceListByDate";
import BillingInvoiceListByCustomer from "../views/billings/BillingInvoiceListByCustomer";
import DedicatedBillingForm from "../views/billings/DedicatedBillingForm";

import BillingCategoryList from "../views/billingCategory/BillingCategoryList";
import AlertList from "../views/alert/AlertList";
import ReminderList from "../views/reminder/ReminderList";
import AuditTrailList from "../views/auditTrail/AuditTrailList";
import GlobalServiceList from "../views/globalService/GlobalServiceList";

import DriversDetailsForm from "../views/Manage/Drivers/DriversDetailsForm";
import DriversView from "../views/Manage/Drivers/DriversView";
import DriversNotesView from "../views/Manage/Drivers/DriversNotesView";
import VehiclesView from "../views/Manage/Vehicles/VehiclesView";
import WasteTypesView from "../views/Manage/WasteTypes/WasteTypesView";
import BinTypesView from "../views/Manage/BinTypes/BinTypesView";
import BinNumbersView from "../views/Manage/BinTypes/BinNumbersView";
import JobTypeForm from "../views/Manage/JobTypes/JobTypeForm";
import JobTypesView from "../views/Manage/JobTypes/JobTypesView";
import RecurringJobForm from "../views/Manage/JobTypes/RecurringJobForm/JobsForm";
import RecurringJobsView from "../views/Manage/JobTypes/RecurringJobsView";
// import AdditionalServicesView from "../views/Manage/Services/AdditionalServicesView";
// import StandardServicesView from "../views/Manage/Services/StandardServicesView";
import TimeView from "../views/Manage/Time/TimeView";
import PayrollView from "../views/Manage/Payroll/PayrollView";
import PayrollBetaForm from "../views/Manage/Payroll/PayrollBetaForm";
import GeofenceView from "../views/Manage/geofence/GeofenceView";
import HolidayView from "../views/Manage/Holiday/HolidayView";
import WorkHoursView from "../views/Manage/WorkHours/WorkHoursView";
import GeneralView from "../views/Manage/General/GeneralView";
import UsersView from "../views/Manage/Users/UsersView";
import RolesView from "../views/Manage/Users/RolesView";
import RolesForm from "../views/Manage/Users/RolesForm";
import CompanyView from "../views/Manage/Company/CompanyView";
import QBIntegrationForm from "../views/Manage/Company/QBIntegrationForm";
import XeroIntegrationForm from "../views/Manage/Company/XeroIntegrationForm";
import TwilioIntegrationForm from "../views/Manage/Company/TwilioIntegrationForm";
import TwilioDeintegrationForm from "../views/Manage/Company/TwilioDeintegrationForm";
import SubscriptionView from "../views/Manage/Subscription/SubscriptionView";

import ReportIframe from "../components/OLD/Pages/Reports/ReportIframe";
import BinsOnSiteView from "../views/Reports/Partial/BinsOnSiteView";
import BinActivitiesView from "../views/Reports/Partial/BinActivitiesView";
import WasteCollectionsSummaryView from "../views/Reports/Partial/WasteCollectionsSummaryView";
import WasteCollectionsDetailedView from "../views/Reports/Partial/WasteCollectionsDetailedView";
import WastePurchaseDetailedView from "../views/Reports/Partial/WastePurchaseDetailedView";
import DriverTripPay from "../views/Reports/Partial/DriverTripPay";
import ReportsCustomerServices from "../views/Reports/Partial/ReportsCustomerServices";

import BinCenterList from "../views/binCenter/BinCenterList";





import { axiosInstance, axiosAuthInstance } from '../v5/utils/axios';

import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { ThemeProvider as BootstrapThemeProvider } from 'react-bootstrap';

import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import PrivateLayout from "../v5/layouts/PrivateLayout";
import DesignSystem from "../v5/pages/designSystem/DesignSystem";

import AlertListV5 from "../v5/pages/alerts/Page";
import RemindersListV5 from "../v5/pages/reminders/Page";
import AuditTrailsListV5 from "../v5/pages/auditTrails/Page";

import CustomerV5 from "../v5/pages/customers/customer/Page";
import CustomerOverviewV5 from "../v5/pages/customers/customer/overview/Page";

import JobFormV5 from "../v5/pages/jobs/Form";

import JobTypesV5 from "../v5/pages/manage/jobs/Page";
import JobTypesFormV5 from "../v5/pages/manage/jobs/jobTypes/Page";


const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
  xxxl: '1920px',
  tablet: '768px',
  desktop: '992px',
  lgDesktop: '1200px',
};


const theme = createTheme({
  palette: {
    primary: {
      light: '#3f9ad2',
      main: '#185cff',
      dark: '#0a5a8b',
      contrastText: '#fff',
    },
    secondary: {
      light: '#3f9ad2',
      main: '#185cff',
      dark: '#0a5a8b',
      contrastText: '#fff',
    }
  },
  typography: {
    fontFamily: [
      'Poppins',
      'Roboto',
      '"Lucida Sans"',
      '"Lucida Sans Regular"',
      '"Lucida Grande"',
      '"Lucida Sans Unicode"',
      'Geneva',
      'Verdana',
      'sans-serif',
    ].join(','),
  },
});


const VersionWrapper = ({children}: any) => {
  const location = useLocation();
  
  let isV5Route: any = location.pathname.startsWith('/v5/');

  React.useEffect(() => {
    let accessToken = localStorage.getItem("token");
    axiosInstance.defaults.headers.common.Authorization = `${accessToken}`;
    axiosAuthInstance.defaults.headers.common.Authorization = `${accessToken}`;

    let isV5: any = location.pathname.startsWith('/v5/');
    
    if (isV5) {
      document.documentElement.classList.remove('wp-v4');
      document.documentElement.classList.add('wp-v5');
    } else {
      document.documentElement.classList.remove('wp-v5');
      document.documentElement.classList.add('wp-v4');
    }
  }, [location.pathname]);


  if(isV5Route === true){
    return <BootstrapThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
      minBreakpoint={'xs'}
    >
      <StyledThemeProvider theme={{ breakpoints: breakpoints }}>
        <PrivateLayout>{children}</PrivateLayout>
      </StyledThemeProvider>
    </BootstrapThemeProvider>
  } else if(isV5Route === false){
    return <MuiThemeProvider theme={theme}>
      <MasterLayout>{children}</MasterLayout>
    </MuiThemeProvider>
  } else {
    return <></>
  }
}


export function PrivateRoutes() {
  const location = useLocation();
  
  let isV5Route: any = location.pathname.startsWith('/v5/');

  
  return (
    <Suspense fallback={<FallbackView />}>
      <VersionWrapper>
        <Switch>
          <Redirect from='/sign-in' to='/Dashboard' />
          <Redirect from='/login' to='/Dashboard' />
          <Redirect exact from='/' to='/Dashboard' />

          <RouteLayout path="/Dashboard" title="WastePorter - Dashboard" className={'dashboard'} Component={Dashboard} read={PagesPermissions.Dashboard.read} write={PagesPermissions.Dashboard.write} />
          
          <RouteLayout path="/Map" title="WastePorter - Map" className={'map'} Component={Map} read={PagesPermissions.Map.read} write={PagesPermissions.Map.write} />

          <RouteLayout path="/Customers" title="WastePorter - Customers" className={'customers'} Component={CustomerList} read={PagesPermissions.Customers.read} write={PagesPermissions.Customers.write} settingsType={UserCookieSettingType.Customer} />
          <RouteLayout path="/Customer-form" title="WastePorter - Customer Form" className={'customer-form'} Component={CustomerForm} Layout={PageLayout} read={PagesPermissions.Customers.read} write={PagesPermissions.Customers.write} settingsType={UserCookieSettingType.Customer} />
          <RouteLayout path="/Customer-overview" title="WastePorter - Customer Overview" className={'customer-overview'} Component={CustomerOverview} Layout={PagePanelLayout} read={PagesPermissions.Customers.read} write={PagesPermissions.Customers.write} settingsType={UserCookieSettingType.Customer} />

          <RouteLayout path="/Customer-overview-local-services" title="WastePorter - Customer Local Services" className={'customer-local-services'} Component={CustomerLocalServices} Layout={PagePanelLayout} read={PagesPermissions.Customers.read} write={PagesPermissions.Customers.write} settingsType={UserCookieSettingType.CustomerServices} />
          <RouteLayout path="/Customer-local-service-overview" title="WastePorter - Customer Local Service Overview" className={'customer-local-services-overview'} Component={LocalServiceOverview} Layout={PagePanelLayout} read={PagesPermissions.CustomerServices.read} write={PagesPermissions.CustomerServices.write} settingsType={UserCookieSettingType.CustomerServices} />
          <RouteLayout path="/Customer-local-service-applicable-sites" title="WastePorter - Customer Local Service Applicable Sites" className={'customer-local-services-applicable-sites'} Component={LocalServiceApplicableSites} Layout={PagePanelLayout} read={PagesPermissions.CustomerServices.read} write={PagesPermissions.CustomerServices.write} settingsType={UserCookieSettingType.CustomerServices} />
          <RouteLayout path="/Customer-local-service-overview-history" title="WastePorter - Local Service History" className={'local-service-history'} Component={LocalServiceHistory} Layout={PagePanelLayout} read={PagesPermissions.CustomerServices.read} write={PagesPermissions.CustomerServices.write} settingsType={UserCookieSettingType.CustomerServices} />

          <RouteLayout path="/Customer-overview-sites" title="WastePorter - Customer Sites" className={'customer-sites'} Component={CustomerSites} Layout={PagePanelLayout} read={PagesPermissions.CustomerSites.read} write={PagesPermissions.CustomerSites.write} settingsType={UserCookieSettingType.CustomerSite} />
          <RouteLayout path="/Customer-site-overview" title="WastePorter - Customer Site Overview" className={'customer-Site-overview'} Component={CustomerSitesOverview} Layout={PagePanelLayout} read={PagesPermissions.CustomerSites.read} write={PagesPermissions.CustomerSites.write} settingsType={UserCookieSettingType.CustomerSite} />
          <RouteLayout path="/Customer-site-applicable-services" title="WastePorter - Customer Site Applicable Services" className={'customer-Site-Applicable-Services'} Component={CustomerSitesApplicableServices} Layout={PagePanelLayout} read={PagesPermissions.CustomerSites.read} write={PagesPermissions.CustomerSites.write} settingsType={UserCookieSettingType.CustomerSite} />
          <RouteLayout path="/Customer-site-overview-history" title="WastePorter - Customer Site History" className={'customer-Site-History'} Component={CustomerSiteHistory} Layout={PagePanelLayout} read={PagesPermissions.CustomerSites.read} write={PagesPermissions.CustomerSites.write} settingsType={UserCookieSettingType.CustomerSite} />

          <RouteLayout path="/Customer-overview-email" title="WastePorter - Customer Email" className={'customer-email'} Component={CustomerEmail} Layout={PagePanelLayout} read={PagesPermissions.CustomersEmail.read} write={PagesPermissions.CustomersEmail.write} settingsType={UserCookieSettingType.CustomersEmail} />


          <RouteLayout path="/services" title="WastePorter - Services" className={'global-service'} Component={GlobalServiceList} read={PagesPermissions.GlobalService.read} write={PagesPermissions.GlobalService.write} settingsType={UserCookieSettingType.GlobalService} />

          <RouteLayout path="/Jobs" title="WastePorter - Jobs" className={'jobs'} Component={JobsView} read={PagesPermissions.Jobs.read} write={PagesPermissions.Jobs.write} settingsType={UserCookieSettingType.Job} />
          <RouteLayout path="/Grantt-chart-old" title="WastePorter - Gantt chart" className={'jobs'} Component={GranttChartView} read={PagesPermissions.Jobs.read} write={PagesPermissions.Jobs.write} settingsType={UserCookieSettingType.Job} />
          <RouteLayout path="/Gantt-chart" title="WastePorter - Gantt chart" className={'gantt-chart'} Component={GranttChartPage} read={PagesPermissions.Jobs.read} write={PagesPermissions.Jobs.write} settingsType={UserCookieSettingType.Job} />
          <RouteLayout path="/Jobs-form" title="WastePorter - Jobs form" className={'jobs'} Component={JobsForm} read={PagesPermissions.JobDetails.read} write={PagesPermissions.JobDetails.write} settingsType={UserCookieSettingType.Job} />
          <Route path="/Job-Iframe" component={JobIframe} />

          <RouteLayout path="/billing/invoice-table/list-by-dates" title="WastePorter - Invoice list by dates" Component={BillingInvoiceListByDate} read={PagesPermissions.BillingInvoice.read} write={PagesPermissions.BillingInvoice.write} settingsType={UserCookieSettingType.BillingByJobDates} billingType={BillingTypes.Invoice} />
          <RouteLayout path="/billing/invoice-table/group-by-customer" title="WastePorter - Invoice group by customer" Component={BillingInvoiceListByCustomer} read={PagesPermissions.BillingInvoice.read} write={PagesPermissions.BillingInvoice.write} settingsType={UserCookieSettingType.BillingByCustomer} billingType={BillingTypes.Invoice} />
          <RouteLayout path="/billing/purchase-table/list-by-dates" title="WastePorter - Purchase list by dates" Component={BillingInvoiceListByDate} read={PagesPermissions.BillingPurchase.read} write={PagesPermissions.BillingPurchase.write} settingsType={UserCookieSettingType.BillingByJobDates} billingType={BillingTypes.Purchase} />
          <RouteLayout path="/billing/purchase-table/group-by-customer" title="WastePorter - Purchase group by customer" Component={BillingInvoiceListByCustomer} read={PagesPermissions.BillingPurchase.read} write={PagesPermissions.BillingPurchase.write} settingsType={UserCookieSettingType.BillingByCustomer} billingType={BillingTypes.Purchase} />
          <RouteLayout path="/billing/expense-table/list-by-dates" title="WastePorter - Expense list by dates" Component={BillingInvoiceListByDate} read={PagesPermissions.BillingExpense.read} write={PagesPermissions.BillingExpense.write} settingsType={UserCookieSettingType.BillingByJobDates} billingType={BillingTypes.Expense} />
          <RouteLayout path="/billing/expense-table/group-by-customer" title="WastePorter - Expense group by customer" Component={BillingInvoiceListByCustomer} read={PagesPermissions.BillingExpense.read} write={PagesPermissions.BillingExpense.write} settingsType={UserCookieSettingType.BillingByCustomer} billingType={BillingTypes.Expense} />
          <RouteLayout path="/billing/billing-form" title="WastePorter - Billing form" Component={DedicatedBillingForm} read={PagesPermissions.BillingInvoice.read} write={PagesPermissions.BillingInvoice.write} />
          <RouteLayout path="/billing/category" title="WastePorter - Billing Category" Component={BillingCategoryList} read={PagesPermissions.BillingCategory.read} write={PagesPermissions.BillingCategory.write} settingsType={UserCookieSettingType.BillingCategory} />

          <RouteLayout path="/alert" title="WastePorter - Alert" className={'alert'} Component={AlertList} read={PagesPermissions.Alert.read} write={PagesPermissions.Alert.write} settingsType={UserCookieSettingType.Alert} />
          <RouteLayout path="/reminders" title="WastePorter - Reminders" className={'reminders'} Component={ReminderList} read={PagesPermissions.Reminders.read} write={PagesPermissions.Reminders.write} settingsType={UserCookieSettingType.Reminders} />
          <RouteLayout path="/audit-trail" title="WastePorter - Audit Trail" className={'audit-trail'} Component={AuditTrailList} read={PagesPermissions.AuditTrail.read} write={PagesPermissions.AuditTrail.write} settingsType={UserCookieSettingType.AuditTrail} />

          <RedirectLayout path="/manage/resources" checkPermission={Utils.hasManageResourcesPermission} />
          <RouteLayout path="/manage/resource/drivers-details" title="WastePorter - Manage drivers details" Component={DriversDetailsForm} read={PagesPermissions.ManageDrivers.read} write={PagesPermissions.ManageDrivers.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/resource/drivers" title="WastePorter - Manage drivers" Component={DriversView} read={PagesPermissions.ManageDrivers.read} write={PagesPermissions.ManageDrivers.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/resource/driver-notes" title="WastePorter - Manage driver notes" Component={DriversNotesView} read={PagesPermissions.ManageDrivers.read} write={PagesPermissions.ManageDrivers.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/resource/vehicles" title="WastePorter - Manage vehicles" Component={VehiclesView} read={PagesPermissions.ManageVehicles.read} write={PagesPermissions.ManageVehicles.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/resource/waste-types" title="WastePorter - Manage waste types" Component={WasteTypesView} read={PagesPermissions.ManageWaste.read} write={PagesPermissions.ManageWaste.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/resource/bin-types" title="WastePorter - Manage bin types" Component={BinTypesView} read={PagesPermissions.ManageBins.read} write={PagesPermissions.ManageBins.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/resource/bin-numbers" title="WastePorter - Manage bin numbers " Component={BinNumbersView} read={PagesPermissions.ManageBins.read} write={PagesPermissions.ManageBins.write} settingsType={UserCookieSettingType.Manage} />
          
          <RedirectLayout path="/manage/jobs" checkPermission={Utils.hasManageJobsPermission} />
          <RouteLayout path="/manage/job/job-types" title="WastePorter - Manage job types" Component={JobTypesView} read={PagesPermissions.ManageJobtemplate.read} write={PagesPermissions.ManageJobtemplate.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/job/job-type-form" title="WastePorter - Manage job type form" Component={JobTypeForm} read={PagesPermissions.ManageJobtemplate.read} write={PagesPermissions.ManageJobtemplate.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/job/job-recurring" title="WastePorter - Manage job recurring" Component={RecurringJobsView} read={PagesPermissions.ManageJobtemplate.read} write={PagesPermissions.ManageJobtemplate.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/job/recurring-job-form" title="WastePorter - Manage recurring job form" component={RecurringJobForm} read={PagesPermissions.ManageJobtemplate.read} write={PagesPermissions.ManageJobtemplate.write} settingsType={UserCookieSettingType.Manage} />
          {/* <RouteLayout path="/manage/job/additional-services" title="WastePorter - Manage additional services" Component={AdditionalServicesView} read={PagesPermissions.ManageService.read} write={PagesPermissions.ManageService.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/job/standard-services" title="WastePorter - Manage standard services " Component={StandardServicesView} read={PagesPermissions.ManageService.read} write={PagesPermissions.ManageService.write} settingsType={UserCookieSettingType.Manage} /> */}
          <RouteLayout path="/manage/job/time" title="WastePorter - Manage time" Component={TimeView} read={PagesPermissions.ManageTime.read} write={PagesPermissions.ManageTime.write} />
          
          <RedirectLayout path="/manage/payrolls" checkPermission={Utils.hasManagePayrollsPermission} />
          <RouteLayout path="/manage/payroll/payroll" title="WastePorter - Manage payroll" Component={PayrollView} read={PagesPermissions.ManagePayroll.read} write={PagesPermissions.ManagePayroll.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/payroll/form" title="WastePorter - Payroll form" className={'payroll-beta-form'} Component={PayrollBetaForm} read={PagesPermissions.ManagePayroll.read} write={PagesPermissions.ManagePayroll.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/payroll/hours" title="WastePorter - Manage hours" Component={WorkHoursView} read={PagesPermissions.ManageWorkHours.read} write={PagesPermissions.ManageWorkHours.write} settingsType={UserCookieSettingType.Manage} />
          
          <RedirectLayout path="/manage/users" checkPermission={Utils.hasManageUsersPermission} />
          <RouteLayout path="/manage/user/users" title="WastePorter - Manage users" Component={UsersView} read={PagesPermissions.ManageUsers.read} write={PagesPermissions.ManageUsers.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/user/roles" title="WastePorter - Manage roles" Component={RolesView} read={PagesPermissions.ManageUsers.read} write={PagesPermissions.ManageUsers.write} settingsType={UserCookieSettingType.Manage} />          
          <RouteLayout path="/manage/user/roles-form" title="WastePorter - Manage roles form" Component={RolesForm} read={PagesPermissions.ManageUsers.read} write={PagesPermissions.ManageUsers.write} settingsType={UserCookieSettingType.Manage} />
          
          <RedirectLayout path="/manage/profiles" checkPermission={Utils.hasManageProfilesPermission} />
          <RouteLayout path="/manage/profile/company" title="WastePorter - Manage company" Component={CompanyView} read={PagesPermissions.ManageCompany.read} write={PagesPermissions.ManageCompany.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/Manage-integrations-connect" title="WastePorter - Manage integrationsn connect" component={QBIntegrationForm} read={PagesPermissions.ManageCompany.read} write={PagesPermissions.ManageCompany.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage-xero-integrations-connect" title="WastePorter - Manage xero integrations connect" component={XeroIntegrationForm} read={PagesPermissions.ManageCompany.read} write={PagesPermissions.ManageCompany.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage-twilio-integrations-connect" title="WastePorter - Manage twilio integrations connect" component={TwilioIntegrationForm} read={PagesPermissions.ManageCompany.read} write={PagesPermissions.ManageCompany.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage-twilio-integrations-disconnect" title="WastePorter - Manage twilio integrations disconnect" component={TwilioDeintegrationForm} read={PagesPermissions.ManageCompany.read} write={PagesPermissions.ManageCompany.write} settingsType={UserCookieSettingType.Manage} />
          
          <RouteLayout path="/manage/geofence" title="WastePorter - Manage geofence" Component={GeofenceView} read={PagesPermissions.ManageGeofence.read} write={PagesPermissions.ManageGeofence.write} settingsType={UserCookieSettingType.Manage} />
          
          <RedirectLayout path="/manage/settings" checkPermission={Utils.hasManageSettingsPermission} />
          <RouteLayout path="/manage/setting/general" title="WastePorter - Manage general" Component={GeneralView} read={PagesPermissions.ManageGeneral.read} write={PagesPermissions.ManageGeneral.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/setting/subscription" title="WastePorter - Manage subscription" Component={SubscriptionView} read={PagesPermissions.ManageSubscription.read} write={PagesPermissions.ManageSubscription.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/manage/setting/holiday" title="WastePorter - Manage holiday" Component={HolidayView} read={PagesPermissions.ManageHoliday.read} write={PagesPermissions.ManageHoliday.write} settingsType={UserCookieSettingType.Manage} />
          
          <RouteLayout path="/bin-center" title="WastePorter - Bin center" className={'bin-center'} Component={BinCenterList} read={PagesPermissions.BinCenter.read} write={PagesPermissions.BinCenter.write} settingsType={UserCookieSettingType.BinCenter} />

          <RedirectLayout path="/Reports" checkPermission={Utils.hasReportsPermission} />
          <RouteLayout path="/Reports-bins-on-site" title="WastePorter - Reports bins on site" Component={BinsOnSiteView} read={PagesPermissions.ReportsBins.read} write={PagesPermissions.ReportsBins.write} />
          <RouteLayout path="/Reports-bin-activities" title="WastePorter - Reports bin activities" Component={BinActivitiesView} read={PagesPermissions.ReportsBinsActivities.read} write={PagesPermissions.ReportsBinsActivities.write} />
          <RouteLayout path="/Reports-waste-collections-summary" title="WastePorter - Reports waste collections summary" Component={WasteCollectionsSummaryView} read={PagesPermissions.ReportsCollectionSummary.read} write={PagesPermissions.ReportsCollectionSummary.write} />
          <RouteLayout path="/Reports-waste-collections-detailed" title="WastePorter - Reports waste collections detailed" Component={WasteCollectionsDetailedView} read={PagesPermissions.ReportsCollectionDetailed.read} write={PagesPermissions.ReportsCollectionDetailed.write} />
          <RouteLayout path="/Reports-waste-purchase-detailed" title="WastePorter - Reports waste purchase detailed" Component={WastePurchaseDetailedView} read={PagesPermissions.ReportsPurchaseDetailed.read} write={PagesPermissions.ReportsPurchaseDetailed.write} />
          <RouteLayout path="/Reports-driver-trip-pay" title="WastePorter - Reports driver trip pay" Component={DriverTripPay} read={PagesPermissions.ReportsDriverTripPay.read} write={PagesPermissions.ReportsDriverTripPay.write} />
          <RouteLayout path="/Reports-customer-services" title="WastePorter - Reports customer services" Component={ReportsCustomerServices} read={PagesPermissions.ReportsCustomerServices.read} write={PagesPermissions.ReportsCustomerServices.write} />
          <Route path="/Report-Iframe" component={ReportIframe} />





          <RouteLayout path="/v5/design-system" title="WastePorter - Alert" className={'alert'} Component={DesignSystem} read={null} write={null} settingsType={null} />
          {/* <RouteLayout path="/v5/alert" title="WastePorter - Alert" className={'alert'} Component={AlertListV5} read={PagesPermissions.Alert.read} write={PagesPermissions.Alert.write} settingsType={UserCookieSettingType.Alert} />
          <RouteLayout path="/v5/reminders" title="WastePorter - Reminders" className={'reminders'} Component={RemindersListV5} read={PagesPermissions.Reminders.read} write={PagesPermissions.Reminders.write} settingsType={UserCookieSettingType.Reminders} />
          <RouteLayout path="/v5/audit-trail" title="WastePorter - Audit Trails" className={'audit-trail'} Component={AuditTrailsListV5} read={PagesPermissions.AuditTrail.read} write={PagesPermissions.AuditTrail.write} settingsType={UserCookieSettingType.AuditTrail} />

          <RouteLayout path="/v5/Customers" title="WastePorter - Customers" className={'customers'} Component={CustomerV5} read={PagesPermissions.Customers.read} write={PagesPermissions.Customers.write} settingsType={UserCookieSettingType.Customer} />
          <RouteLayout path="/v5/Customer/overview" title="WastePorter - Customer Overview" className={'customer-overview'} Component={CustomerOverviewV5} read={PagesPermissions.Customers.read} write={PagesPermissions.Customers.write} settingsType={UserCookieSettingType.Customer} /> */}
          
          <RouteLayout path="/v5/job-form-beta" title="WastePorter - Job form" className={'job-form-v5'} Component={JobFormV5} read={PagesPermissions.JobDetails.read} write={PagesPermissions.JobDetails.write} settingsType={UserCookieSettingType.Job} />

          <RouteLayout path="/v5/manage/job/job-types" title="WastePorter - Manage job types" className={'manage-jobtypes-v5'} Component={JobTypesV5} read={PagesPermissions.ManageJobtemplate.read} write={PagesPermissions.ManageJobtemplate.write} settingsType={UserCookieSettingType.Manage} />
          <RouteLayout path="/v5/manage/job/job-types-form" title="WastePorter - Manage job types form" className={'manage-jobtypes-form-v5'} Component={JobTypesFormV5} read={PagesPermissions.ManageJobtemplate.read} write={PagesPermissions.ManageJobtemplate.write} settingsType={UserCookieSettingType.Manage} />





          <Route path="/Maintenance" component={Maintenance} />

          <Redirect to='error/404' />
        </Switch>
        
        {!isV5Route && <SignalRDownload />}
      </VersionWrapper>
    </Suspense>
  )
}
