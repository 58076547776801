import React, { ReactNode } from 'react';
import _ from 'lodash';
import moment from 'moment';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, findStatus, isEdit, isCopy } from '../slice.tsx';
// import jobTypeHistorySlice from './jobTypeHistory/slice.tsx';

import { transformJobStepBinType, getGroupsFromSteps } from '../../manage/jobs/jobTypes/slice.tsx';

// enums
import { dateFormat } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

// import { ReactComponent as RefreshNewIcon } from "../../../../v5/assets/icons/refresh-new.svg";

// ----------------------------------------------------------------------

const JobType = React.forwardRef((props: any, ref: any) => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { jobTemplates } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useImperativeHandle(
    ref,
    () => ({
      onSelectTemplate(item: any) {
        onSelectTemplate(item);
      },
    }),
  );


  React.useEffect(() => {
    dispatch(slice.callReadJobTemplateApi(null, (state: boolean, data: any) => {}))
  }, [])

  
  const onSelectTemplate = async (item: any) => {
    if(values.jobTemplateId === item.jobTemplateId){
      await setFieldValue('jobTemplateId', null);
      await setFieldValue('jobTemplate', null);
      await setFieldValue('isBillable', false);
      await setFieldValue('statusId', null);
      await setFieldValue('statusName', '');
      await setFieldValue('steps', []);
      await setFieldValue('jobBinTypes', []);
      await setFieldValue('stepGroups', []);

      dispatch(slice.clearJob());
    } else {
      await setFieldValue('jobTemplateId', item.jobTemplateId);
      await setFieldValue('jobTemplate', item);
      await setFieldValue('isBillable', item.isBillable);

      dispatch(slice.callJobTemplateBoilerplateApi({ customerId: values.customerId, jobTemplateId: item.jobTemplateId }, async (state: boolean, data: any) => {
        if(state){
          await setFieldValue('jobTemplate', data.jobTemplate);

          let steps = (data && data.steps && data.steps.length > 0) ? data.steps : [];
          await setFieldValue('steps', steps);
          
          let transformed = transformJobStepBinType(steps, data);
          await setFieldValue('jobBinTypes', transformed?.jobBinTypes);

          let stepGroups = getGroupsFromSteps(steps, transformed?.jobBinTypes, isEdit());
          await setFieldValue('stepGroups', []);
          setTimeout(async () => {
            await setFieldValue('stepGroups', stepGroups);

            if(!isEdit() && values?.jobDate == ''){
              await setFieldValue('jobDate', moment().format(dateFormat()));
            }
          }, 200);

          if(!isEdit() && (values.statusId === undefined || values.statusId === null || values.statusId === 0)){
            let statusUnassigned = findStatus(data?.jobTemplate?.templateStatuses, 'unassigned');
            if(statusUnassigned && statusUnassigned.jobStatusId) {
              let statusId = (statusUnassigned.jobStatusId) ? statusUnassigned.jobStatusId : null;
              let statusName = (statusUnassigned.jobStatusName) ? statusUnassigned.jobStatusName : '';
              
              setFieldValue('statusId', statusId);
              setFieldValue('statusName', statusName);
            }
          }
        }
      }));
    }
  }

  const getElements = () => {
    return <Row className='g-8 align-items-center'>
      {jobTemplates.map((item: any, i: number) => {
        return <Col key={'job_form_job_template_item_' + i} xs={4} md={3}>
          <Button
            className={'w-100 text-truncate'}
            size={'sm'}
            active={(values.jobTemplateId === item.jobTemplateId)}
            disabled={(values.jobTemplateId === item.jobTemplateId) ? false : (isEdit() && !isCopy())}
            variant={'custom-label-outlined'}
            onClick={async () => {
              if(!(isEdit() && !isCopy())){
                onSelectTemplate(item);
              }
            }}
            title={item.jobTemplateName}
          >
            {item.jobTemplateName}
          </Button>
        </Col>
      })}
      
    </Row>
  }


  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
      <Col xs={12}>
        <Row className='g-16 align-items-center'>
          <Col xs={true}>
            <span className="large semibold gray-900">2. Job Type</span>
          </Col>
          {/* <Col xs={'auto'}>
            <Button 
              variant={'custom-secondary'}
              size={'sm'}
              onClick={() => {
                dispatch(jobTypeHistorySlice.setShow({ show: true, id: values.jobTemplateId }));
              }}
            >
              <RefreshNewIcon /><span className='ps-s4'>Load History</span>
            </Button>
          </Col> */}
        </Row>
      </Col>

      <Col xs={12}>{getElements()}</Col>

      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
});

export default JobType;
