/* eslint-disable no-loop-func */
import React, { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../v5/utils/axios';
import { success, successAPI, errorAPI, getMaxPageSize, isNumeric, getCurrency, formatNumber } from '../../../v5/utils/utils';
// import {  } from '../../../v5/utils/enums';
import { BillingTypes } from '../../../v5/pages/jobs/slice';


interface InitState {
  isLoading: boolean,

  accountBillingProfileId: number|null,
  invoiceTaxRate: number|null,
  purchaseTaxRate: number|null,

  category: Array<any>,
}


function NewReducer() {
  const name = 'taxRateSlice';


  const initialState: InitState = {
    isLoading: false,

    accountBillingProfileId: null,
    invoiceTaxRate: null,
    purchaseTaxRate: null,
    
    category: [],
  };


  const reducers = {
    setMainTaxRate: (state: InitState, action: PayloadAction<{ invoiceTaxRate: number|null, purchaseTaxRate: number|null }>) => {
      let invoiceTaxRate = (isNumeric(action.payload.invoiceTaxRate)) ? action.payload.invoiceTaxRate : null;
      let purchaseTaxRate = (isNumeric(action.payload.purchaseTaxRate)) ? action.payload.purchaseTaxRate : null;

      state.invoiceTaxRate = invoiceTaxRate;
      state.purchaseTaxRate = purchaseTaxRate;
    },
    setTaxRate: (state: InitState, action: PayloadAction<{ index: number|null, invoiceTaxRate: number|null, purchaseTaxRate: number|null }>) => {
      if(action.payload.index){
        let invoiceTaxRate = (isNumeric(action.payload.invoiceTaxRate)) ? action.payload.invoiceTaxRate : null;
        let purchaseTaxRate = (isNumeric(action.payload.purchaseTaxRate)) ? action.payload.purchaseTaxRate : null;

        let item: any = {
          index: action.payload.index,
          invoiceTaxRate: invoiceTaxRate,
          purchaseTaxRate: purchaseTaxRate,
        };
  
        let category = state.category;
        let index = category.findIndex((x) => x['index'] == action.payload.index);
        if (index === -1) {
          category.push(item);
        } else {
          category.splice(index, 1);
          category.push(item);
        }

        state.category = category;
      }
    },

    startReadDefaultProfile: (state: InitState) => {
      state.category = [];

      state.accountBillingProfileId = null;
      state.invoiceTaxRate = null;
      state.purchaseTaxRate = null;

      state.isLoading = true;
    },
    finishReadDefaultProfile: (state: InitState, action: PayloadAction<{ data: any }>) => {
      let item = (action.payload && action.payload.data && action.payload.data.data && action.payload.data.data.length > 0) ? action.payload.data.data[0] : null;
      
      let accountBillingProfileId = (item && isNumeric(item.accountBillingProfileId)) ? item.accountBillingProfileId : null;
      let invoiceTaxRate = (item && isNumeric(item.invoiceTaxRate)) ? item.invoiceTaxRate : null;
      let purchaseTaxRate = (item && isNumeric(item.purchaseTaxRate)) ? item.purchaseTaxRate : null;
      
      state.accountBillingProfileId = accountBillingProfileId;
      state.invoiceTaxRate = invoiceTaxRate;
      state.purchaseTaxRate = purchaseTaxRate;

      state.isLoading = false;
    },
    
    startReadCustomerProfile: (state: InitState) => {
      state.category = [];

      state.accountBillingProfileId = null;
      state.invoiceTaxRate = null;
      state.purchaseTaxRate = null;
      
      state.isLoading = true;
    },
    finishReadCustomerProfile: (state: InitState, action: PayloadAction<{ data: any }>) => {
      let item = (action.payload && action.payload.data && action.payload.data.billingProfile) ? action.payload.data.billingProfile : null;
      
      let accountBillingProfileId = (item && isNumeric(item.accountBillingProfileId)) ? item.accountBillingProfileId : null;
      let invoiceTaxRate = (item && isNumeric(item.invoiceTaxRate)) ? item.invoiceTaxRate : null;
      let purchaseTaxRate = (item && isNumeric(item.purchaseTaxRate)) ? item.purchaseTaxRate : null;
      
      state.accountBillingProfileId = accountBillingProfileId;
      state.invoiceTaxRate = invoiceTaxRate;
      state.purchaseTaxRate = purchaseTaxRate;

      state.isLoading = false;
    },
  };


  const func = {
    getTax: (isTaxInclusive: boolean, price: number, percent: number|null) => {
      let tax: number = 0;

      if(isTaxInclusive === true){
        if(percent != null && isNumeric(percent)){
          try {
            tax = (100 / (percent + 100)) * price
          } catch (e) {
            tax = price;
          }
        } else {
          tax = price;
        }
      } else {
        tax = price;
      }

      return tax;
    },
    getWithoutTax: (isTaxInclusive: boolean, price: number, percent: number|null) => {
      let plusTax = func.getTax(isTaxInclusive, price, percent);
      return getCurrency() + formatNumber(plusTax) + ' without tax';
    },
    getTaxPercentage: (billingType: number|null, invoiceTaxRate: number|null, purchaseTaxRate: number|null): number|null => {
      return (billingType === BillingTypes.Purchase) ? purchaseTaxRate : invoiceTaxRate;
    },
    getTaxRate: (isTaxInclusive: boolean, item: any|null, taxRate: { invoiceTaxRate: number|null, purchaseTaxRate: number|null, category: Array<any> }) => {
      let invoiceTaxRate = (taxRate && isNumeric(taxRate.invoiceTaxRate)) ? taxRate.invoiceTaxRate : null;
      let purchaseTaxRate = (taxRate && isNumeric(taxRate.purchaseTaxRate)) ? taxRate.purchaseTaxRate : null;

      let chargeCategoryId = (item && item.chargeCategoryId) ? item.chargeCategoryId : null;
      if(chargeCategoryId && taxRate.category && taxRate.category.length > 0){
        let catItems = taxRate.category.filter((x: any) => x.index == chargeCategoryId);
        if(catItems && catItems.length > 0){
          let invoiceTaxRateC = (catItems && catItems[0] && isNumeric(catItems[0].invoiceTaxRate)) ? catItems[0].invoiceTaxRate : null;
          let purchaseTaxRatec = (catItems && catItems[0] && isNumeric(catItems[0].purchaseTaxRate)) ? catItems[0].purchaseTaxRate : null;

          invoiceTaxRate = invoiceTaxRateC;
          purchaseTaxRate = purchaseTaxRatec;
        }
      }

      let billingType = (item) ? item.billingType : null;
      let price = (item) ? item.price : null;

      let getTaxPercentage: number|null = func.getTaxPercentage(billingType, invoiceTaxRate, purchaseTaxRate);
      return func.getWithoutTax(isTaxInclusive, price, getTaxPercentage);
    },
    getTaxRateColumn: (item: any|null) => {
      if(item){
        let iTaxRate = (item && item.invoiceTaxRate) ? item.invoiceTaxRate : null;
        let pTaxRate = (item && item.purchaseTaxRate) ? item.purchaseTaxRate : null;
        let invoiceTaxRate = (item && item.chargeCategory && item.chargeCategory.invoiceTaxRate) ? item.chargeCategory.invoiceTaxRate : iTaxRate;
        let purchaseTaxRate = (item && item.chargeCategory && item.chargeCategory.purchaseTaxRate) ? item.chargeCategory.purchaseTaxRate : pTaxRate;

        return <>
          <div>{getCurrency() + formatNumber(item.price)}</div>
          {(item.isTaxInclusive && isNumeric(item.price)) && <i className={'fw-light text-muted fs-7'}>{func.getWithoutTax(item.isTaxInclusive, item.price, func.getTaxPercentage(item.billingType, invoiceTaxRate, purchaseTaxRate))}</i>}
        </>
      }
    },
    getTaxRateField: (item: any|null, iTaxRate: number|null, pTaxRate: number|null) => {
      if(item){
        let invoiceTaxRate = (item && item.chargeCategory && item.chargeCategory.invoiceTaxRate) ? item.chargeCategory.invoiceTaxRate : iTaxRate;
        let purchaseTaxRate = (item && item.chargeCategory && item.chargeCategory.purchaseTaxRate) ? item.chargeCategory.purchaseTaxRate : pTaxRate;

        return <>
          <div>{getCurrency() + formatNumber(item.price)}</div>
          {(item.isTaxInclusive && isNumeric(item.price)) && <i className={'fw-light text-muted fs-7'}>{func.getWithoutTax(item.isTaxInclusive, item.price, func.getTaxPercentage(item.billingType, invoiceTaxRate, purchaseTaxRate))}</i>}
        </>
      }
    },
  }


  const apis = {
    getDefaultProfileApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadDefaultProfile());

      let params = {
        isActive: true,
        isDefault: true,
      };

      await axios.get('AccountBillingProfile', { params: params }).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishReadDefaultProfile({ data: data }));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishReadDefaultProfile({ data: null }));
      });
    },

    getCustomerProfileApi: (customerId: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadCustomerProfile());

      if(customerId){
        await axios.get('customer/' + customerId).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadCustomerProfile({ data: data }));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadCustomerProfile({ data: null }));
        });
      } else {
        dispatch(actions.finishReadCustomerProfile({ data: null }));
      }
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
    ...func,
  };
}


export default NewReducer();