/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import moment from 'moment';
import type { UploadFile } from 'antd';

import { axios } from '../../../v5/utils/axios';
import { ReadAPIParams, success, warning, successAPI, errorAPI, placeholderRows, getMaxPageSize, isNumeric, dateTimeMeridianFormat, getBoolProps, prepareDate, saveDate, prepareDateTime, saveDateTime, dateFormat, getIntProps } from '../../../v5/utils/utils';
import Yup from '../../../v5/utils/yup';
import { CustomerSiteAvailability, ServiceType } from '../../../v5/utils/enums';

import { LocationEnum, getBinInOut, getFirstCustomerSite, getFirstBinType, getGroupsFromSteps, getFirstCustomerSiteStep, isTaskType, isExchange1Type, isDisposalType, getFirstBins } from '../manage/jobs/jobTypes/slice.tsx';

import { getBearing } from '../../../v5/components/googleMap/GoogleMap.jsx';


export const BillingTypes = {
  Invoice: 0,
  Purchase: 1,
  Expense: 2
}


export const fields = {
  customerId: {
    id: 'customerId',
    label: 'Company name',
    placeholder: 'Select',
  },
  remarks: {
    id: 'remarks',
    label: 'Job Note',
    placeholder: placeholderRows(5, 'Write a note here', ''),
    rows: 5,
  },
  customerNote: {
    id: 'customerNote',
    label: 'Customer Note',
    placeholder: 'Default',
  },
  accountBillingProfileId: {
    id: 'accountBillingProfileId',
    label: 'Billing by',
    placeholder: 'Default',
  },
  paymentTermsId: {
    id: 'paymentTermsId',
    label: 'Payment terms',
    placeholder: 'Default',
  },
  xeroPaymentTerm: {
    id: 'xeroPaymentTerm',
    label: 'Payment terms',
    placeholder: 'Default',
  },
  
  jobTemplateId: {
    id: 'jobTemplateId',
    label: 'Tamplate name',
    placeholder: '',
  },
  
  customerSiteId: {
    id: 'customerSiteId',
    label: 'Customer site',
    placeholder: 'Select',
  },
  instructions: {
    id: 'instructions',
    label: 'Site instruction',
    placeholder: placeholderRows(1, 'Write a note here', ''),
    rows: 1,
  },
  contactPersonName: {
    id: 'contactPersonName',
    label: 'Contact person 1',
    placeholder: 'Name',
  },
  contactPersonPhoneNumber: {
    id: 'contactPersonPhoneNumber',
    label: 'Contact number 1',
    placeholder: 'Contact number',
  },
  contactPersonNameTwo: {
    id: 'contactPersonNameTwo',
    label: 'Contact person 2',
    placeholder: 'Name',
  },
  contactPersonPhoneNumberTwo: {
    id: 'contactPersonPhoneNumberTwo',
    label: 'Contact number 2',
    placeholder: 'Contact number',
  },

  sameAsBinOut: {
    id: 'sameAsBinOut',
    label: 'Same as BIN OUT',
    placeholder: '',
  },
  isNoninventory: {
    id: 'isNoninventory',
    label: 'Non-inventory bin',
    placeholder: '',
  },
  isBillable: {
    id: 'isBillable',
    label: 'Bill this Bin',
    placeholder: '',
  },
  binTypeId: {
    id: 'binTypeId',
    label: 'Bin type',
    placeholder: 'Select',
  },
  wasteTypeId: {
    id: 'binTypeId',
    label: 'Waste type',
    labelFor: 'For waste type',
    labelWith: 'With waste type',
    placeholder: 'Select',
  },
  binNumberId: {
    id: 'binNumberId',
    label: 'Bin ID',
    placeholder: 'Enter',
  },
  serviceTag: {
    id: 'serviceTag',
    label: 'Service name',
    placeholder: 'Filled automatically',
  },
  quantity: {
    id: 'quantity',
    label: 'Quantity',
    placeholder: '',
  },
  binOutWeight: {
    id: 'binOutWeight',
    label: 'Bin weight (Gross)',
    placeholder: '',
  },
  binWeight: {
    id: 'binWeight',
    label: 'Bin weight (Gross)',
    placeholder: '',
  },
  additionalWaste: {
    id: 'additionalWaste',
    label: 'Additional waste',
    placeholder: '',
  },
  
  jobDate: {
    id: 'jobDate',
    label: 'Job date',
    placeholder: 'Enter',
  },
  jobTimeSpecific: {
    id: 'jobTimeSpecific',
    label: 'Job time',
    placeholder: 'Enter',
  },
  driverId: {
    id: 'driverId',
    label: 'Driver',
    placeholder: 'Select',
  },
  vehicleId: {
    id: 'vehicleId',
    label: 'Vehicle',
    placeholder: 'Select',
  },
  jobStatus: {
    id: 'jobStatus',
    label: 'Job status',
    placeholder: '',
  },
  
  multipleTrips: {
    id: 'multipleTrips',
    label: 'Multiple Trips',
    placeholder: ' ',
  },
  numberOfTrip: {
    id: 'numberOfTrip',
    label: 'Number of trips',
    placeholder: ' ',
    info: 'Create multiple-trips per job instead of creating same job multiple times.',
  },
  assignToSameDriverVehicle: {
    id: 'assignToSameDriverVehicle',
    label: 'Assign to the same driver & vehicle',
    placeholder: '',
  },

  collectBin: {
    id: 'collectBin',
    label: 'Collect Bin',
    placeholder: ' ',
  },
  followUpDays: {
    id: 'followUpDays',
    label: 'Collect in (days)',
    placeholder: ' ',
    info: 'Follow up with a Collect Bin job with scheduled date and time.',
  },
  followUpDate: {
    id: 'followUpDate',
    label: 'Collection date',
    placeholder: '',
  },
  followUpTimeSpecific: {
    id: 'followUpTimeSpecific',
    label: 'Collection time',
    placeholder: '',
  },
  followUpAssignToSameDriverVehicle: {
    id: 'followUpAssignToSameDriverVehicle',
    label: 'Assign to the same driver & vehicle',
    placeholder: '',
  },

  amountToCollect: {
    id: 'amountToCollect',
    label: 'Amount to collect',
    placeholder: '',
  },
  collectedAmount: {
    id: 'collectedAmount',
    label: 'Collected by driver',
    placeholder: '',
  },
  jobPaymentType: {
    id: 'jobPaymentType',
    label: 'Payment mode',
    placeholder: '',
  },

  jobSignedUserName: {
    id: 'jobSignedUserName',
    label: 'Signed username',
    placeholder: ' ',
  },
  jobSignedUserContact: {
    id: 'jobSignedUserContact',
    label: 'Signed contact',
    placeholder: ' ',
  },
  
  defaultLocationId: {
    id: 'defaultLocationId',
    label: 'Default location',
    placeholder: 'Select',
  },
  binActivity: {
    id: 'binActivity',
    label: 'Bin activity',
    placeholder: 'Select',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),

    customerId: Yup.number().nullable().required().label(fields.customerId.label),
    remarks: Yup.string().label(fields.remarks.label),

    jobTemplateId: Yup.number().nullable().required().label(fields.jobTemplateId.label),

    // customerSiteId: Yup.number().nullable().required().label(fields.customerSiteId.label),
    // instructions: Yup.string().label(fields.instructions.label),
    // contactPersonName: Yup.string().label(fields.contactPersonName.label),
    // contactPersonPhoneNumber: Yup.string().label(fields.contactPersonPhoneNumber.label),
    // contactPersonNameTwo: Yup.string().label(fields.contactPersonNameTwo.label),
    // contactPersonPhoneNumberTwo: Yup.string().label(fields.contactPersonPhoneNumberTwo.label),
    
    statusId: Yup.number().nullable().required().label(fields.jobStatus.label),
    jobDate: Yup.string().nullable().label(fields.jobDate.label),
    jobTimeSpecific: Yup.string().nullable().label(fields.jobTimeSpecific.label),
    driverId: Yup.number().nullable().label(fields.driverId.label),
    vehicleId: Yup.number().nullable().label(fields.vehicleId.label),
    
    jobSignedUserName: Yup.string().label(fields.jobSignedUserName.label),
    jobSignedUserContact: Yup.string().label(fields.jobSignedUserContact.label),
    
    amountToCollect: Yup.number().nullable().min(0).label(fields.amountToCollect.label),
    collectedAmount: Yup.number().nullable().min(0).label(fields.collectedAmount.label),
    jobPaymentType: Yup.number().nullable().label(fields.jobPaymentType.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const JobAction = {
  Save: 0,
  Draft: 1,
  Unassigned: 2,
  Assigned: 3,
  Dispatched: 4,
  Completed: 5,
  Cancelled: 6,
  SaveAndClose: 7
}
export const ParentJobRelation = {
  MultipleTrip: 0,
  FollowUp: 1
}


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  let isCopyJob = isCopy();
  let isEditJob = isEdit();

  if(data && form){
    data['version'] = 1;
    data['action'] = JobAction.Save;
    data['isActive'] = form.isActive;
    data['isBillable'] = form.isBillable;

    data['customerId'] = form.customerId;
    data['customer'] = form.customer;
    data['customerName'] = form.customer?.customerName;
    data['remarks'] = form.remarks;

    data['jobTemplateId'] = form.jobTemplateId;
    data['jobTemplate'] = form.jobTemplate;
    
    let stepsArr = [];
    if(form.steps && form.steps.length > 0){
      for(let i = 0; i < form.steps.length; i++){
        stepsArr = form.steps.map((step: any, s: number) => {
          return {
            ...step,
            customerSiteName: step?.site?.siteNameDisplay,
          }
        });
      }
    }
    data['steps'] = stepsArr;
    data['jobBinTypes'] = form.jobBinTypes;

    let stepGroups: any = getGroupsFromSteps(stepsArr, form?.jobBinTypes, isEditJob);
    data['stepGroups'] = stepGroups;
    

    let jobDate = isEditJob ? prepareDate(form.jobDate) : moment().format(dateFormat());
    data['jobDate'] = (!isCopyJob) ? jobDate : '';

    let jobTimeSpecific = prepareDateTime(form.jobTimeSpecific);
    data['jobTimeSpecific'] = (!isCopyJob) ? jobTimeSpecific : '';

    let accountJobWorkingTimeId = null;
    let jobTimeSpecificNameByTime = jobTimeSpecific;
    let accountJobWorkingTimeName = (form.workingTimeName && form.workingTimeName !== '') ? form.workingTimeName : (jobTimeSpecificNameByTime ? jobTimeSpecificNameByTime : '');

    data['jobTimeFrom'] = (!isCopyJob) ? form.jobTimeFrom : null;
    data['jobTimeTo'] = (!isCopyJob) ? form.jobTimeTo : null;
    data['accountJobWorkingTimeId'] = (!isCopyJob) ? accountJobWorkingTimeId : null;
    data['accountJobWorkingTimeName'] = (!isCopyJob) ? accountJobWorkingTimeName : '';
    data['jobDurationHours'] = form.jobDurationHours;
    data['jobDurationMinutes'] = form.jobDurationMinutes;
    data['dispatchLaterTime'] = form.dispatchLaterTime;
    data['jobAttemptCompletedDate'] = form.jobAttemptCompletedDate;

    data['driverId'] = form.driverId;
    data['driverName'] = form.driverName;
    data['vehicleId'] = form.vehicleId;
    data['vehicleName'] = form.vehicleName;
    data['statusId'] = form.jobStatusId;
    data['statusName'] = form.statusName;
    data['currentStatusId'] = form.jobStatusId;
    data['currentStatusName'] = form.statusName;

    if(isCopyJob){
      let newStatus = findStatus(values?.jobTemplate?.templateStatuses, ((form.driverId) ? 'assigned' : 'unassigned'));
      let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
      let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';

      data['statusId'] = newStatusId;
      data['statusName'] = newStatusName;
      data['currentStatusId'] = newStatusId;
      data['currentStatusName'] = newStatusName;
    }

    let multipleTrip = (form.multipleTrip === true || form.multipleTrip == false) ? form.multipleTrip : false;
    data['multipleTrip'] = multipleTrip;
    data['numberOfTrip'] = isNumeric(form.numberOfTrip) ? form.numberOfTrip : 0;
    let assignToSameDriverVehicle = (form.assignToSameDriverVehicle === true || form.assignToSameDriverVehicle == false) ? form.assignToSameDriverVehicle : false;
    data['assignToSameDriverVehicle'] = assignToSameDriverVehicle;

    data['isCollectJob'] = (!isCopyJob) ? isCollectJob() : false;
    data['haveFollowUp'] = (!isCopyJob) ? form.haveFollowUp : false;
    let followUpDate = prepareDate(form.followUpDate);
    data['followUpDate'] = (!isCopyJob) ? followUpDate : null;
    let followUpTimeSpecific = prepareDateTime(form.followUpTimeSpecific);
    data['followUpTimeSpecific'] = (!isCopyJob) ? followUpTimeSpecific : null;
    data['followUpDays'] = (!isCopyJob) ? form.followUpDays : 0;
    data['followUpAssignToSameDriverVehicle'] = (!isCopyJob) ? form.followUpAssignToSameDriverVehicle : false;
    data['parentJobRelation'] = ParentJobRelation.FollowUp;

    let paymentsObj = preparePaymentsForm(stepGroups, isCopyJob);
    data['isRequirePaymentCollection'] = paymentsObj?.isRequirePaymentCollection;
    data['defaultAmountToCollect'] = paymentsObj?.defaultAmountToCollect;
    data['amountToCollect'] = paymentsObj?.amountToCollect;
    data['collectedAmount'] = paymentsObj?.collectedAmount;
    data['jobPaymentType'] = paymentsObj?.jobPaymentType;

    
    let attempt = (form.attempts && form.attempts.length > 0) ? form.attempts[0] : null;
    let proofOfDeliveryRemarks = (attempt && attempt.proofOfDeliveryRemarks != '') ? attempt.proofOfDeliveryRemarks : '';

    let jobPhotos = (attempt && attempt.jobPhotos && attempt.jobPhotos.length > 0) ? attempt.jobPhotos : [];

    let photoAttachmentsUrl: any = [];
    if(!isCopyJob){
      if(jobPhotos && jobPhotos.length > 0){
        jobPhotos.forEach((item: any, i: number) => {
          photoAttachmentsUrl.push({
            uid: 'jobphotos_' + item.jobPhotoId,
            name: 'jobphotos_' + item.jobPhotoId,
            status: 'done',
            url: item.photoUrl,
            thumbUrl: item.photoUrl,
            preview: item.photoUrl,
            data: item,
          });
        });
      }
    }

    let jobSignatures = (attempt && attempt.jobSignatures && attempt.jobSignatures.length > 0) ? attempt.jobSignatures : [];
    let jobSignature = (jobSignatures && jobSignatures.length > 0) ? jobSignatures[0] : null;
    
    let jobSignatureUrl: any = [];
    if(!isCopyJob){
      if((jobSignature && jobSignature.jobSignatureUrl)){
        jobSignatureUrl.push({
          uid: 'jobSignature_' + jobSignature.jobSignatureId,
          name: 'jobSignature_' + jobSignature.jobSignatureId,
          status: 'done',
          url: jobSignature.jobSignatureUrl,
          thumbUrl: jobSignature.jobSignatureUrl,
          preview: jobSignature.jobSignatureUrl,
          data: jobSignature,
        });
      }
    }
    let jobSignedUserName = (!isCopyJob) ? (jobSignature && jobSignature.jobSignedUserName) ? jobSignature.jobSignedUserName : '' : '';
    let jobSignedUserContact = (!isCopyJob) ? (jobSignature && jobSignature.jobSignedUserContact) ? jobSignature.jobSignedUserContact : '' : '';

    
    data['photoAttachmentsUrl'] = photoAttachmentsUrl;

    data['jobSignatureUrl'] = jobSignatureUrl;
    data['jobSignedUserName'] = jobSignedUserName;
    data['jobSignedUserContact'] = jobSignedUserContact;

    data['pricings'] = form.pricings;

    data['jobNumber'] = (!isCopyJob) ? form.jobNumber : '';
    data['invoiceDate'] = form.invoiceDate;
    data['billingNote'] = form.billingNote;
    data['invoiceNumber'] = form.invoiceNumber;
    data['billedDate'] = form.billedDate;

    data['proofOfDeliveryRemarks'] = (!isCopyJob) ? proofOfDeliveryRemarks : '';
    data['cancellationRemarks'] = (!isCopyJob) ? form.cancellationRemarks || '' : '';

    let isSendEmail = (form.isSendEmail === true || form.isSendEmail == false) ? form.isSendEmail : false;
    data['isSendEmail'] = isSendEmail;

    data['histories'] = form.histories || [];


    if(isEditJob && !isCopyJob){
      data['updated'] = form.updated;
    }
  }
  
  return data;
};
export const preparePaymentsForm = (stepGroups: any = null, isCopyJob: boolean = false) => {
  let data: any = {
    isRequirePaymentCollection: false,
    defaultAmountToCollect: 0,
    amountToCollect: '0',
    collectedAmount: '0',
    jobPaymentType: null,
  };

  let step = getFirstCustomerSiteStep({ stepGroups: stepGroups });
  let isRequirePaymentCollection = (step && ((step.isRequirePaymentCollection == true) || (step.isRequirePaymentCollection == false))) ? step.isRequirePaymentCollection : false;
  data['isRequirePaymentCollection'] = isRequirePaymentCollection;
  data['defaultAmountToCollect'] = (isRequirePaymentCollection === false) ? 0 : ((step?.amountToCollect !== '') ? step?.amountToCollect : 0);
  data['amountToCollect'] = (isRequirePaymentCollection === false) ? '0' : step?.amountToCollect;
  data['collectedAmount'] = (!isCopyJob) ? (isRequirePaymentCollection === false) ? '0' : step?.collectedAmount : '';
  data['jobPaymentType'] = step?.jobPaymentType;

  return data;
}

export const prepareData = (values: any = null, eventKey: any, cancellationRemarks: any = null) => {
  let data: any = {};
  let form = _.cloneDeep(values);
  
  let id = getId();
  let isCopyJob = isCopy();
  let isEditJob = isEdit();

  if(form){
    data['version'] = 1;
    data['action'] = eventKey;
    data['isActive'] = form.isActive;

    data['customerId'] = form.customerId;
    data['remarks'] = form.remarks;
    data['jobTemplateId'] = form.jobTemplateId;
    data['jobTypeName'] = 'Custom';
    data['driverId'] = form.driverId;
    data['vehicleId'] = form.vehicleId;
    data['jobStatusId'] = form.statusId;

    data['multipleTrip'] = form.multipleTrip;
    data['numberOfTrip'] = isNumeric(form.numberOfTrip) ? form.numberOfTrip : 0;
    data['assignToSameDriverVehicle'] = form.assignToSameDriverVehicle;

    data['haveFollowUp'] = form.haveFollowUp;
    let followUpDate = saveDate(form.followUpDate);
    data['followUpDate'] = followUpDate;
    let followUpTimeSpecific = saveDateTime(form.followUpTimeSpecific);
    data['followUpTimeSpecific'] = followUpTimeSpecific;
    data['followUpDays'] = form.followUpDays;
    data['followUpAssignToSameDriverVehicle'] = form.followUpAssignToSameDriverVehicle;
    data['parentJobRelation'] = ParentJobRelation.FollowUp;

    let jobDate = saveDate(form.jobDate);
    data['jobDate'] = jobDate;
    let jobTimeSpecific = saveDateTime(form.jobTimeSpecific);
    data['jobTimeSpecific'] = jobTimeSpecific;
    data['jobTimeFrom'] = form.jobTimeFrom;
    data['jobTimeTo'] = form.jobTimeTo;
    data['workingTimeName'] = form.accountJobWorkingTimeName;
    data['jobDurationHours'] = form.jobDurationHours;
    data['jobDurationMinutes'] = form.jobDurationMinutes;
    data['dispatchLaterTime'] = form.dispatchLaterTime;

    let setpsBins = prepareStepsData(form, id, isCopyJob);
    data['steps'] = setpsBins?.steps;
    data['jobBinTypes'] = setpsBins?.jobBinTypes;

    data['attempts'] = prepareAttemptsData(form, id, isCopyJob);
    data['pricings'] = form.isBillable ? preparePricingsData(form, id, isCopyJob) : null;
    data['isBillable'] = form.isBillable;

    data['totalDistance'] = form.totalDistance || 0;
    let isSendEmail = (form.isSendEmail === true || form.isSendEmail == false) ? form.isSendEmail : false;
    data['isSendEmail'] = isSendEmail;

    data['cancellationRemarks'] = (cancellationRemarks && cancellationRemarks != '') ? cancellationRemarks : form.cancellationRemarks || '';
    data['billingNote'] = form?.billingNote || '';


    if(isEditJob && !isCopyJob){
      data['jobId'] = id;
      data['updated'] = form.updated;
    }

    if(isCopyJob){
      delete data['jobId'];
      data['isCopy'] = true;
      data['copiedJobId'] = id;
      data['histories'] = null;
    }
  }

  return data;
};
export const prepareStepsData = (values: any = null, id: any = null, isCopy: boolean = false) => {
  let steps = _.cloneDeep(values.steps);

  let jobBinTypesArr: Array<any> = [];
  let stepsArr: Array<any> = [];
  let binOrder = 1;

  /* GROUP */
  if(values && values.stepGroups && values.stepGroups.length > 0){
    values.stepGroups.forEach((group: any, g: number) => {
      let customerSite1: any = null;
      let customerSite2: any = null;
      if(group && group.customerSites && group.customerSites.length > 0){
        if(group.customerSites.length === 1){
          customerSite1 = group.customerSites[0];
        } else if (group.customerSites.length === 2){
          customerSite1 = group.customerSites[0];
          customerSite2 = group.customerSites[1];
        }
      }

      let isShift = (customerSite1 && customerSite2) ? true : false;

      /* BLOCKS */
      if(group && group.blocks && group.blocks.length > 0){
        group.blocks.forEach((block: any, b: number) => {
          let groupedJobStepBinTypes: Array<any> = [];
          let jobStepBinTypes: Array<any> = [];
          
          /* BINS */
          if(block && block.bins && block.bins.length > 0){
            let stepOrderFirst = 1;
            let isBillable = false;
            let isInVehicle = false;
            block.bins.forEach((binItem: any, binIndex: number) => {
              if(binIndex === 0){
                stepOrderFirst = binItem.stepOrder
                isBillable = binItem.isBillable;
                isInVehicle = binItem.isInVehicle;
              }

              groupedJobStepBinTypes.push({
                binTypeId: binItem?.binTypeId,
                binActivity: binItem?.binActivity,
                quantity: 1,
                jobStepId: 0,
                order: 0,
                stepOrder: 0,
              });

              jobStepBinTypes.push({
                jobBinTypeId: binItem?.jobBinTypeId,
                binActivity: binItem?.binActivity,
                binTypeOrder: binOrder,
                jobStepId: 0,
                order: 0,
                stepOrder: 0,
              });

              binItem.stepOrder = stepOrderFirst;
              binItem.order = binOrder;
              binItem.isBillable = isBillable;
              binItem.isInVehicle = isInVehicle;
              jobBinTypesArr.push(binItem);

              binOrder = binOrder + 1;
            })
          }
          /* END BINS */


          /* STEPS */
          if(block && block.steps && block.steps.length > 0){
            block.steps.forEach((stepItem: any, stepIndex: number) => {
              let stepObj: any = steps.find((x: any) => x.stepOrder === stepItem.stepOrder);

              if(stepObj.locationType === LocationEnum.CustomerSite){
                let customerSiteObj = null;
                if(isShift){
                  customerSiteObj = (stepIndex == 0) ? customerSite1 : customerSite2;
                } else {
                  customerSiteObj = customerSite1;
                }
                
                stepObj['customerSiteId'] = customerSiteObj?.customerSiteId;
                stepObj['address'] = customerSiteObj?.address;
                stepObj['latitude'] = customerSiteObj?.latitude;
                stepObj['longitude'] = customerSiteObj?.longitude;
                stepObj['contactPersonOne'] = customerSiteObj?.contactPersonName;
                stepObj['contactNumberOne'] = customerSiteObj?.contactPersonPhoneNumber;
                stepObj['contactPersonTwo'] = customerSiteObj?.contactPersonNameTwo;
                stepObj['contactNumberTwo'] = customerSiteObj?.contactPersonPhoneNumberTwo;
                stepObj['siteRemarks'] = customerSiteObj?.instructions;

                stepObj['siteName'] = customerSiteObj?.customerSite?.siteName;
                stepObj['blockNo'] = customerSiteObj?.customerSite?.blockNo;
                stepObj['street'] = customerSiteObj?.customerSite?.street;
                stepObj['unitNo'] = customerSiteObj?.customerSite?.unitNo;
                stepObj['postalCode'] = customerSiteObj?.customerSite?.postalCode;

                stepObj['isRequirePaymentCollection'] = values.isRequirePaymentCollection;
                stepObj['defaultAmountToCollect'] = (values.isRequirePaymentCollection === false) ? 0 : ((values.amountToCollect !== '') ? values.amountToCollect : 0);
                stepObj['amountToCollect'] = (values.isRequirePaymentCollection === false) ? '0' : values.amountToCollect;
                stepObj['collectedAmount'] = (values.isRequirePaymentCollection === false) ? '0' : values.collectedAmount;
                stepObj['jobPaymentType'] = values.jobPaymentType;
                
              } else {
                stepObj['customerSiteId'] = null;
                stepObj['address'] = stepObj?.address;
                stepObj['latitude'] = stepObj?.latitude;
                stepObj['longitude'] = stepObj?.longitude;
                stepObj['contactPersonOne'] = '';
                stepObj['contactNumberOne'] = '';
                stepObj['contactPersonTwo'] = '';
                stepObj['contactNumberTwo'] = '';
                stepObj['siteRemarks'] = '';

                stepObj['siteName'] = stepObj?.siteName;
                stepObj['blockNo'] = '';
                stepObj['street'] = '';
                stepObj['unitNo'] = '';
                stepObj['postalCode'] = '';

                stepObj['isRequirePaymentCollection'] = false;
                stepObj['defaultAmountToCollect'] = 0;
                stepObj['amountToCollect'] = '';
                stepObj['collectedAmount'] = '';
                stepObj['jobPaymentType'] = null;
              }


              let binActivity = getBinInOut(stepObj);

              let groupedJobStepBinTypesArr = _.cloneDeep(groupedJobStepBinTypes);
              if(groupedJobStepBinTypesArr && groupedJobStepBinTypesArr.length > 0){
                for(let i = 0; i < groupedJobStepBinTypesArr.length; i++){
                  groupedJobStepBinTypesArr[i].binActivity = binActivity;
                  groupedJobStepBinTypesArr[i].jobStepId = stepObj?.jobStepId;
                  groupedJobStepBinTypesArr[i].stepOrder = stepObj?.stepOrder;
                  groupedJobStepBinTypesArr[i].order = (i+1);
                }
              }
              stepObj['groupedJobStepBinTypes'] = groupedJobStepBinTypesArr;

              let jobStepBinTypesArr = _.cloneDeep(jobStepBinTypes);
              if(jobStepBinTypesArr && jobStepBinTypesArr.length > 0){
                for(let i = 0; i < jobStepBinTypesArr.length; i++){
                  jobStepBinTypesArr[i].binActivity = binActivity;
                  jobStepBinTypesArr[i].jobStepId = stepObj?.jobStepId;
                  jobStepBinTypesArr[i].stepOrder = stepObj?.stepOrder;
                  jobStepBinTypesArr[i].order = (i+1);
                }
              }
              stepObj['jobStepBinTypes'] = jobStepBinTypesArr;


              stepsArr.push(stepObj);
            })
          }
          /* END STEPS */

        })
      }
      /* END BLOCKS */

    })
  }
  /* END GROUP */

  
  if(stepsArr && stepsArr.length > 0){
    for(let i = 0; i < stepsArr.length; i++){
      delete stepsArr[i].templateStep;
      delete stepsArr[i].defaultLocation;
      delete stepsArr[i].customerSite;
      delete stepsArr[i].site;
      delete stepsArr[i].zone;
      delete stepsArr[i].customerSiteAddress;
      delete stepsArr[i].binType;
      delete stepsArr[i].binTypeName;
      delete stepsArr[i].wasteType;
      delete stepsArr[i].wasteTypeName;
      delete stepsArr[i].wasteTypes;
      delete stepsArr[i].customerSiteName;
      delete stepsArr[i].serviceItemName;

      if(stepsArr[i].groupedJobStepBinTypes && stepsArr[i].groupedJobStepBinTypes.length > 0){
        // NOP
      } else {
        if(i > 0){
          let groupedJobStepBinTypesItems = _.cloneDeep(stepsArr[i-1].groupedJobStepBinTypes);
          if(groupedJobStepBinTypesItems && groupedJobStepBinTypesItems.length > 0){
            groupedJobStepBinTypesItems = groupedJobStepBinTypesItems.map((item: any, j: number) => {
              return {
                ...item,
                binActivity: 0,
                jobStepId: stepsArr[i]?.jobStepId,
                stepOrder: stepsArr[i]?.stepOrder,
              }
            })
          }
          stepsArr[i].groupedJobStepBinTypes = groupedJobStepBinTypesItems;
        }
      }
      
      if(stepsArr[i].jobStepBinTypes && stepsArr[i].jobStepBinTypes.length > 0){
        // NOP
      } else {
        if(i > 0){
          let jobStepBinTypesItems = _.cloneDeep(stepsArr[i-1].jobStepBinTypes);
          if(jobStepBinTypesItems && jobStepBinTypesItems.length > 0){
            jobStepBinTypesItems = jobStepBinTypesItems.map((item: any, j: number) => {
              return {
                ...item,
                binActivity: 0,
                jobBinTypeId: 0,
                jobStepId: stepsArr[i]?.jobStepId,
                stepOrder: stepsArr[i]?.stepOrder,
              }
            })
          }
          stepsArr[i].jobStepBinTypes = jobStepBinTypesItems;
        }
      }
    }
  } else {
    stepsArr = steps
  }

  if(jobBinTypesArr && jobBinTypesArr.length > 0){
    for(let i = 0; i < jobBinTypesArr.length; i++){
      delete jobBinTypesArr[i].binType;
      delete jobBinTypesArr[i].wasteType;
      delete jobBinTypesArr[i].serviceItem;
      delete jobBinTypesArr[i].jobStepBinTypes;
      delete jobBinTypesArr[i].stepOrder;

      let wasteTypes: Array<any> = [];
      if(jobBinTypesArr[i].wasteTypes && jobBinTypesArr[i].wasteTypes.length > 0){
        jobBinTypesArr[i].wasteTypes.forEach((wasteTypeItem: any, j: number) => {
          wasteTypes.push({
            wasteTypeId: wasteTypeItem?.wasteTypeId,
            serviceItemId: wasteTypeItem?.serviceItemId,
            order: (j+1),
          });
        });
        jobBinTypesArr[i].wasteTypes = wasteTypes;
      }
    }
  }

  return {
    steps: stepsArr,
    jobBinTypes: jobBinTypesArr
  };
}
export const prepareAttemptsData = (values: any = null, id: any = null, isCopy: boolean = false) => {
  let data = [];

  let jobPhotos: Array<any> = [];
  if (values && values.photoAttachmentsUrl && values.photoAttachmentsUrl.length > 0) {
    values.photoAttachmentsUrl.forEach((x: any) => {
      if(x.response && x.response != ''){
        jobPhotos.push({
          photoUrl: x.response
        });
      } else if(x.url && x.url != ''){
        jobPhotos.push({
          photoUrl: x.url
        });
      }
    });
  }

  let jobSignatures: Array<any> = [];
  if (values && values.jobSignatureUrl && values.jobSignatureUrl.length > 0) {
    values.jobSignatureUrl.forEach((x: any) => {
      if(x.response && x.response != ''){
        jobSignatures.push({
          jobSignatureUrl: x.response,
          jobSignedUserName: values.jobSignedUserName,
          jobSignedUserContact: values.jobSignedUserContact,
        });
      } else if(x.url && x.url != ''){
        jobSignatures.push({
          jobSignatureUrl: x.url,
          jobSignedUserName: values.jobSignedUserName,
          jobSignedUserContact: values.jobSignedUserContact,
        });
      } else {
        jobSignatures.push({
          jobSignatureUrl: '',
          jobSignedUserName: values.jobSignedUserName,
          jobSignedUserContact: values.jobSignedUserContact,
        });
      }
    });
  }

  let attempt1: any = {
    customerName: values.customerName,

    driverName: values.driverName,
    vehicleName: values.vehicleName,

    remarks: values.proofOfDeliveryRemarks || '',
    jobSignatures: jobSignatures,
    jobPhotos: jobPhotos,
  };

  if (isCopy === false) {
    if (checkStatus(values?.statusName, ['completed']) &&
      (values.jobAttemptCompletedDate === null || values.jobAttemptCompletedDate === '')
    ) {
      attempt1["jobAttemptCompletedDate"] = new Date();
    } else {
      attempt1["jobAttemptCompletedDate"] = values.jobAttemptCompletedDate;
    }
  }

  data.push(attempt1);

  return data;
}
export const preparePricingsData = (values: any = null, id: any = null, isCopy: boolean = false) => {
  let data: any = [];

  let jobStepId: any = null;

  if (values && values.pricings && values.pricings.length > 0) {
    let pricings = values.pricings.sort((a: any, b: any) => a.serviceType - b.serviceType);

    data = pricings.map((item: any, i: number) => {
      let quantity = (item.quantity && item.quantity != '') ? parseFloat(item.quantity) : 0;

      let dataItem: any = {
        sortOrder: i,
        serviceType: item.serviceType,
        binTypeId: (isStandardService(item)) ? item.binTypeId : null,
        wasteTypeId: (isStandardService(item)) ? item.wasteTypeId : null,
        serviceAdditionalChargeTemplateId: (item.serviceType == ServiceType.AdditionalService) ? item.serviceAdditionalChargeTemplateId : null,
        serviceTag: item.serviceTag,
        bundleServiceTag: item.bundleServiceTag,
        description: item.serviceTag,
        price: (item.price && item.price != '') ? item.price : 0,
        quantity: quantity,
        billingType: item.billingType,
        chargeCategoryId: item.chargeCategoryId,
        jobStepId: null,
        serviceItemId: item.serviceItemId,
        isCopyServiceItem: item.isCopyServiceItem,
        isBinWeightQuantity: item.useBinWeightQuantity,
        isTaxInclusive: (item) ? item.isTaxInclusive : false,
        invoiceTaxRate: (item) ? item.invoiceTaxRate : null,
        purchaseTaxRate: (item) ? item.purchaseTaxRate : null,
        useBinWeightQuantity: false,
      }

      if (item.jobPricingId) {
        dataItem['jobPricingId'] = item.jobPricingId;
      }

      if (isStandardService(item)) {
        dataItem['jobStepId'] = (jobStepId > 0) ? jobStepId : null;
      }

      return dataItem;
    });
  }


  let mainBundleServiceTag = '';
  if (data && data.length > 0) {
    let standardServicesData = data.filter((x: any) => isStandardService(x));
    if (standardServicesData && standardServicesData.length > 0) {
      for (let i = 0; i < standardServicesData.length; i++) {
        let standardItem = standardServicesData[i];

        if (i === 0) {
          mainBundleServiceTag = standardItem.bundleServiceTag;
        }

        for (let j = 0; j < data.length; j++) {
          if (data[j].bundleServiceTag == standardItem.bundleServiceTag) {
            data[j].jobStepId = (jobStepId > 0) ? jobStepId : null;
          }
        }
      }
    }
  }

  if (data && data.length > 0) {
    let mainIndex = data.findIndex((x: any) => ((x.bundleServiceTag == mainBundleServiceTag) && (x.isBinWeightQuantity == true)));
    if (mainIndex > -1) {
      data[mainIndex].useBinWeightQuantity = true;
    }
  }

  return data;
}

export const clearForm = (values: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(initialValues);
  
  let obj = {
    ...data,
    ...form,
  }
  
  return obj;
};

export const weekDayNumb = (jobDate: any) => {
  return (jobDate) ? moment(jobDate).isoWeekday() : 7;
};
export const isWeekend = (weekDayNumb: any) => {
  return ((weekDayNumb === 6) || (weekDayNumb === 7)) ? true : false;
};
export const getTimeAvailability = (values: any) => {
  let customerSite: any = getFirstCustomerSite(values);

  let timeAvailability = CustomerSiteAvailability.All;
  let isWeek = isWeekend(weekDayNumb(values?.jobDate));
  if(isWeek){
    timeAvailability = customerSite?.weekendAvailability;
  } else {
    timeAvailability = customerSite?.weekdaysAvailability;
  }

  return {
    timeAvailability: timeAvailability,
    isWeekend: isWeek,
  };
}
export const getJobFormActionButtons = (values: any, id: any = null) => {
  if (values) {
    let {
      statusName,
      customerId,
      jobTemplateId,
      driverId,
      vehicleId,
      steps,
      stepGroups,
    } = values;

      statusName = statusName.toString().toLowerCase().trim();
      
      let isTask = isTaskType(steps);
      let isExchange1 = isExchange1Type(stepGroups);
      let isDisposal = isDisposalType(stepGroups);

      let customerSite: any = getFirstCustomerSite(values);
      let customerSiteId = (customerSite && customerSite?.customerSiteId > 0) ? customerSite?.customerSiteId : null;

      // let binType: any = getFirstBinType(values);
      let binsType: any = getFirstBins(values);
      let binType1Id = (binsType && binsType.binType1 && binsType.binType1?.binTypeId > 0) ? binsType.binType1?.binTypeId : null;
      let binType2Id = (binsType && binsType.binType2 && binsType.binType2?.binTypeId > 0) ? binsType.binType2?.binTypeId : null;
      let isSelectedBinTypeId = isExchange1 ? (binType1Id && binType2Id) : binType1Id;

      if (!isEdit() || isCopy()) {
          if (!customerId) {
              return null;
          } else {
              if (!jobTemplateId) {
                  return {
                      main: JobAction.Draft,
                      dropdown: null,
                  };
              } else if (!customerSiteId && !isDisposal) {
                  return {
                      main: JobAction.Draft,
                      dropdown: null,
                  };
              } else {
                  if (!isSelectedBinTypeId && !isTask) {
                      return {
                          main: JobAction.Draft,
                          dropdown: null,
                      };
                  } else {
                      if (!driverId) {
                          return {
                              main: JobAction.Unassigned,
                              dropdown: [JobAction.Draft],
                          };
                      } else if (driverId && !vehicleId) {
                          return {
                              main: JobAction.Assigned,
                              dropdown: [JobAction.Draft],
                          };

                      } else {
                          return {
                              main: JobAction.Dispatched,
                              dropdown: [JobAction.Draft, JobAction.Assigned, JobAction.Completed],
                          };
                      }
                  }
              }
          }

      } else {
          if (['draft', 'unassigned'].includes(statusName)) {
              if (!customerId) {
                  return null;
              } else {
                  if (!jobTemplateId) {
                      return {
                          main: JobAction.Cancelled,
                          dropdown: [JobAction.Draft],
                      };
                  } else if (!customerSiteId && !isDisposal) {
                      return {
                          main: JobAction.Cancelled,
                          dropdown: [JobAction.Draft],
                      };
                  } else {
                      if (!isSelectedBinTypeId && !isTask) {
                          return {
                              main: JobAction.Cancelled,
                              dropdown: [JobAction.Draft],
                          };
                      } else {
                          if (!driverId || !vehicleId) {
                              return {
                                  main: JobAction.Unassigned,
                                  dropdown: [JobAction.Draft, JobAction.Cancelled],
                              };
                          } else {
                              return {
                                  main: JobAction.Dispatched,
                                  dropdown: [JobAction.Draft, JobAction.Assigned, JobAction.Completed, JobAction.Cancelled],
                              };
                          }
                      }
                  }
              }

          } else if (['assigned'].includes(statusName)) {
              if (!customerId) {
                  return null;
              } else {
                  if (!jobTemplateId) {
                      return {
                          main: JobAction.Cancelled,
                          dropdown: [JobAction.Draft],
                      };
                  } else if (!customerSiteId && !isDisposal) {
                      return {
                          main: JobAction.Cancelled,
                          dropdown: [JobAction.Draft],
                      };
                  } else {
                      if (!isSelectedBinTypeId && !isTask) {
                          return {
                              main: JobAction.Cancelled,
                              dropdown: [JobAction.Draft],
                          };
                      } else {
                          if (!driverId) {
                              return {
                                  main: JobAction.Unassigned,
                                  dropdown: [JobAction.Draft, JobAction.Cancelled],
                              };
                          } else if (driverId && !vehicleId) {
                              return {
                                  main: JobAction.Assigned,
                                  dropdown: [JobAction.Draft, JobAction.Cancelled],
                              };
                          } else {
                              return {
                                  main: JobAction.Dispatched,
                                  dropdown: [JobAction.Draft, JobAction.Assigned, JobAction.Completed, JobAction.Cancelled],
                              };
                          }
                      }
                  }
              }

          } else if (['dispatched', 'acknowledged'].includes(statusName)) {
              if (!customerId) {
                  return null;
              } else {
                  if (!jobTemplateId) {
                      return {
                          main: JobAction.Cancelled,
                          dropdown: [JobAction.Draft],
                      };
                  } else if (!customerSiteId && !isDisposal) {
                      return {
                          main: JobAction.Cancelled,
                          dropdown: [JobAction.Draft],
                      };
                  } else {
                      if (!isSelectedBinTypeId && !isTask) {
                          return {
                              main: JobAction.Cancelled,
                              dropdown: [JobAction.Draft],
                          };
                      } else {
                          if (!driverId || !vehicleId) {
                              return {
                                  main: JobAction.Unassigned,
                                  dropdown: [JobAction.Draft, JobAction.Cancelled],
                              };
                          } else {
                              return {
                                  main: JobAction.Dispatched,
                                  dropdown: [JobAction.Draft, JobAction.Completed, JobAction.Cancelled],
                              };
                          }
                      }
                  }
              }

          } else if (['started', 'in progress'].includes(statusName)) {
              if (!customerId) {
                  return null;
              } else {
                  if (!jobTemplateId) {
                      return {
                          main: JobAction.Save,
                          dropdown: [JobAction.SaveAndClose],
                      };
                  } else if (!customerSiteId && !isDisposal) {
                      return {
                        main: JobAction.Save,
                        dropdown: [JobAction.SaveAndClose],
                      };
                  } else {
                      if (!isSelectedBinTypeId && !isTask) {
                          return {
                              main: JobAction.Save,
                              dropdown: [JobAction.SaveAndClose],
                          };
                      } else {
                          if (!driverId || !vehicleId) {
                              return {
                                  main: JobAction.Save,
                                  dropdown: [JobAction.Cancelled, JobAction.SaveAndClose],
                              };
                          } else {
                              return {
                                  main: JobAction.Save,
                                  dropdown: [JobAction.Completed, JobAction.Cancelled, JobAction.SaveAndClose],
                              };
                          }
                      }
                  }
              }

          } else if (statusName === 'completed') {
              if (!customerId) {
                  return null;
              } else {
                  if (!jobTemplateId) {
                      return {
                          main: JobAction.Save,
                          dropdown: [JobAction.SaveAndClose],
                      };
                  } else if (!customerSiteId && !isDisposal) {
                      return {
                        main: JobAction.Save,
                        dropdown: [JobAction.SaveAndClose],
                      };
                  } else {
                      if (!isSelectedBinTypeId && !isTask) {
                          return {
                              main: JobAction.Save,
                              dropdown: [JobAction.SaveAndClose],
                          };
                      } else {
                          if (!driverId || !vehicleId) {
                              return {
                                  main: JobAction.Save,
                                  dropdown: [JobAction.SaveAndClose],
                              };
                          } else {
                              return {
                                  main: JobAction.Save,
                                  dropdown: [JobAction.Cancelled, JobAction.SaveAndClose],
                              };
                          }
                      }
                  }
              }

          } else {
              if (!customerId) {
                  return null;
              } else {
                  if (!jobTemplateId) {
                      return {
                          main: JobAction.Save,
                          dropdown: [JobAction.SaveAndClose],
                      };
                  } else if (!customerSiteId && !isDisposal) {
                      return {
                        main: JobAction.Save,
                        dropdown: [JobAction.SaveAndClose],
                      };
                  } else {
                      if (!isSelectedBinTypeId && !isTask) {
                          return {
                              main: JobAction.Save,
                              dropdown: [JobAction.SaveAndClose],
                          };
                      } else {
                          if (!driverId || !vehicleId) {
                              return {
                                  main: JobAction.Save,
                                  dropdown: [JobAction.SaveAndClose],
                              };
                          } else {
                              return {
                                  main: JobAction.Save,
                                  dropdown: [JobAction.SaveAndClose],
                              };
                          }
                      }
                  }
              }
          }
      }
  } else {
      return null;
  }
}
export const getJobFormActionButtonName = (jobAction = null) => {
  if (jobAction == JobAction.Draft) {
      return 'Mark as Draft';
  } else if (jobAction == JobAction.Unassigned) {
      return 'Mark as Unassigned';
  } else if (jobAction == JobAction.Assigned) {
      return 'Mark as Assigned';
  } else if (jobAction == JobAction.Dispatched) {
      return 'Dispatch Now';
  } else if (jobAction == JobAction.Completed) {
      return 'Mark as Completed';
  } else if (jobAction == JobAction.Cancelled) {
      return 'Mark as Cancelled';
  } else if (jobAction == JobAction.SaveAndClose) {
      return 'Save and Close';
  } else {
      return 'Save';
  }
}

export const getId = () => {
  let id = getIntProps();
  return (isNumeric(id) && id > 0) ? id : null;
}
export const isEdit = () => {
  let id = getIntProps();
  return (isNumeric(id) && id > 0) ? true : false;
}
export const isCopy = () => {
  return getBoolProps();
}
export const isCollectJob = (form: any = null) => {
  let parentJobId = (form && form.parentJobId) ? form.parentJobId : null;
  let parentJobRelation = (form && form.parentJobRelation) ? form.parentJobRelation : null;

  if ((parentJobId > 0) && (parentJobRelation == ParentJobRelation.FollowUp)) {
    return true;
  } else {
    return false;
  }
}
export const saveAction = (eventKey = null, id: any = null, router: any) => {
  if (eventKey == JobAction.Dispatched) {
    router.push('/jobs');
  } else if (eventKey == JobAction.Draft) {
    router.push('/jobs');
  } else if (eventKey == JobAction.Completed) {
    router.push('/jobs');
  } else if (eventKey == JobAction.Cancelled) {
    router.push('/jobs');
  } else if (eventKey == JobAction.Assigned) {
    router.push('/jobs');
  } else if (eventKey == JobAction.Unassigned) {
    router.push('/jobs');
  } else if (eventKey == JobAction.SaveAndClose) {
    router.push('/jobs');
  } else {
    reloadAction(id, router);
  }
}
export const reloadAction = (id: any = null, router: any) => {
  if (id) {
    window.location.href = '/v5/job-form-beta?id=' + id;
  } else {
    router.push('/jobs');
  }
}
export const saveJobForm = (eventKey: any, id: any, form: any = null, jobTemplate: any, onCreate: any, onUpdate: any, onCopy: any, onCachCollected: any, onCanceled: any) => {
    let {
      values,
    } = form;
    
    let timeAvailability = CustomerSiteAvailability.All;
    let timeAvailabilityObj = getTimeAvailability(values);

    if (timeAvailabilityObj?.timeAvailability !== CustomerSiteAvailability.NotAvailable) {
      if (id > 0) {
        if (isCopy()) {
          onCopy()
        } else {
          let isCashCollectedLess = false;
          let isStatus = false;

          let status = findStatus(jobTemplate?.templateStatuses, 'in progress');
          isStatus = (status && status.jobStatusId && (values.currentStatusId === status.value)) ? true : false;

          let collectedAmount = (values.collectedAmount != '') ? parseFloat(values.collectedAmount) : 0;
          let amountToCollect = (values.amountToCollect != '') ? parseFloat(values.amountToCollect) : 0;
          if (collectedAmount < amountToCollect) {
            isCashCollectedLess = true;
          }

          if (eventKey == JobAction.Cancelled) {
            onCanceled()
          } else {
            if (isCashCollectedLess && !isStatus) {
              onCachCollected()
            } else {
              onUpdate()
            }
          }
        }
      } else {
        onCreate()
      }
    } else {
      warning('Warning', (timeAvailabilityObj?.isWeekend ? 'Weekend' : 'Weekdays') + ' time is not available');
    }
}
export const setQtyByUseBinWeightQuantity = (item: any = null) => {
  let useBinWeightQuantity = ((item && item.useBinWeightQuantity === true) || (item.useBinWeightQuantity === false)) ? item.useBinWeightQuantity : false;
  let quantity = (item && item.quantity) ? item.quantity : useBinWeightQuantity ? 0 : 1;

  return { 
    quantity: quantity, 
    useBinWeightQuantity: useBinWeightQuantity
  };
}
export const setPricingItem = (pricingItem: any = null, binTypeOrder: any) => {
  let main: any = null;
  let bundle: Array<any> = [];
  
  if(pricingItem) {
    let bundleServices = (pricingItem.bundleServices && pricingItem.bundleServices.length > 0) ? pricingItem?.bundleServices : [];

    let qtyObj = setQtyByUseBinWeightQuantity(pricingItem);
    let useBinWeightQuantity = qtyObj.useBinWeightQuantity;
    let quantity = qtyObj.quantity;
    let bundleServiceTag = pricingItem?.serviceTag + '_' + binTypeOrder || '';

    main = {
      serviceType: ServiceType.StandardService,
      binTypeId: pricingItem?.binTypeId,
      wasteTypeId: pricingItem?.wasteTypeId,
      serviceTag: pricingItem?.serviceTag || '',
      bundleServiceTag: bundleServiceTag,
      description: bundleServiceTag,
      price: pricingItem?.price,
      quantity: quantity,
      billingType: pricingItem?.billingType,
      chargeCategoryId: pricingItem?.chargeCategoryId,
      chargeCategoryName: pricingItem?.chargeCategory?.chargeCategoryName || '',
      chargeCategory: pricingItem?.chargeCategory,
      jobStepId: pricingItem?.jobStepId,
      serviceItemId: pricingItem?.serviceItemId,
      useBinWeightQuantity: useBinWeightQuantity,
      isTaxInclusive: pricingItem?.isTaxInclusive || false,
      invoiceTaxRate: pricingItem?.invoiceTaxRate || null,
      purchaseTaxRate: pricingItem?.purchaseTaxRate || null,
      sortOrder: 1,
    };

    if(bundleServices && bundleServices.length > 0){
      bundleServices.forEach((bundleItem: any, i: number) => {
        let bundleQtyObj = setQtyByUseBinWeightQuantity(bundleItem);
        let bundleUseBinWeightQuantity = bundleQtyObj.useBinWeightQuantity;
        let bundleQuantity = bundleQtyObj.quantity;

        let bundleServiceItem = {
          serviceType: ServiceType.AdditionalService,
          binTypeId: null,
          wasteTypeId: null,
          serviceTag: bundleItem?.serviceTag || '',
          bundleServiceTag: bundleServiceTag,
          description: bundleItem?.serviceTag || '',
          price: bundleItem?.price,
          quantity: bundleQuantity,
          billingType: bundleItem?.billingType,
          chargeCategoryId: bundleItem?.chargeCategoryId,
          chargeCategoryName: bundleItem?.chargeCategory?.chargeCategoryName || '',
          chargeCategory: bundleItem?.chargeCategory,
          jobStepId: bundleItem?.jobStepId,
          serviceItemId: bundleItem?.serviceItemId,
          useBinWeightQuantity: bundleUseBinWeightQuantity,
          isTaxInclusive: bundleItem?.isTaxInclusive || false,
          invoiceTaxRate: bundleItem?.invoiceTaxRate || null,
          purchaseTaxRate: bundleItem?.purchaseTaxRate || null,
          sortOrder: (i+2),
        };

        bundle.push(bundleServiceItem);
      })
    }
  }

  return {
    main: main,
    bundle: bundle,
  };
}
export const setPricingsData = (mainItem: any = null, additionalItems: Array<any>, binTypeOrder: any) => {
  let mainPricings: Array<any> = [];
  let additionalPricings: Array<any> = [];
  let pricings: Array<any> = [];

  if(mainItem) {
    let mainPricingObject = setPricingItem(mainItem, binTypeOrder);
    if(mainPricingObject && mainPricingObject.main){
      mainPricings.push(mainPricingObject?.main);

      if(mainPricingObject?.bundle && mainPricingObject?.bundle.length > 0){
        mainPricingObject?.bundle.forEach((bundleItem: any) => {
          additionalPricings.push(bundleItem);
        })
      }
    }
  }

  if(additionalItems && additionalItems.length > 0) {
    additionalItems.forEach((additionalItm: any) => {
      let additionalMainPricingObject = setPricingItem(additionalItm, binTypeOrder);
      if(additionalMainPricingObject && additionalMainPricingObject.main){
        mainPricings.push(additionalMainPricingObject?.main);
  
        if(additionalMainPricingObject?.bundle && additionalMainPricingObject?.bundle.length > 0){
          additionalMainPricingObject?.bundle.forEach((additionalBundleItem: any) => {
            additionalPricings.push(additionalBundleItem);
          })
        }
      }
    });
  }
  
  if(mainPricings && mainPricings.length > 0){
    mainPricings.forEach((mItem: any) => {
      pricings.push(mItem);
    });
  }

  if(additionalPricings && additionalPricings.length > 0){
    additionalPricings.forEach((aItem: any) => {
      pricings.push(aItem);
    });
  }

  if(pricings && pricings.length > 0){
    pricings = pricings.map((pItem: any, i: number) => {
      return {
        ...pItem,
        sortOrder: (i+1),
      }
    });
  }

  return pricings;
}
export const margePricingsData = (oldPricings: Array<any> = [], onewPricings: Array<any> = []) => {
  let pricings: Array<any> = [];

  if(oldPricings && oldPricings.length > 0){
    oldPricings.forEach((item: any) => {
      pricings.push(item);
    })
  }

  if(onewPricings && onewPricings.length > 0){
    onewPricings.forEach((item: any) => {
      pricings.push(item);
    })
  }
  
  if(pricings && pricings.length > 0){
    pricings = pricings.sort((a: any, b: any) => a.serviceType - b.serviceType).map((pItem: any, i: number) => {
      return {
        ...pItem,
        sortOrder: (i+1),
      }
    });
  }

  return pricings;
}
export const separateServices = (pricings: Array<any>) => {
  let standardServices = pricings.filter((x: any) => x.serviceType === ServiceType.StandardService);
  let additionalServices = pricings.filter((x: any) => x.serviceType === ServiceType.AdditionalService);
  let withoutBundle = pricings.filter((x: any) => !x.bundleServiceTag);

  return { 
    standardServices: standardServices,
    additionalServices: additionalServices,
    withoutBundle: withoutBundle
  };
};
export const updateBillingPricings = (values: any, services: Array<any>) => {
  let pricingsArr: Array<any> = [];
  let arr: Array<any> = [];

  let { standardServices, additionalServices, withoutBundle } = separateServices(values?.pricings);

  if(standardServices && standardServices.length > 0){
    standardServices.forEach((item: any, i: number) => {
      let standardServiceItem = services.find((x: any) => x?.serviceItemId === item?.serviceItemId);
      if(standardServiceItem){
        let bundleServices: Array<any> = (standardServiceItem.bundleServices && standardServiceItem.bundleServices.length > 0) ? standardServiceItem?.bundleServices : [];
        let binTypeOrder = (i+1);

        let mainPricingObject = setPricingItem(standardServiceItem, binTypeOrder);
        if(mainPricingObject && mainPricingObject.main){
          let mainQtyObj = setQtyByUseBinWeightQuantity(item);
          let mainUseBinWeightQuantity = mainQtyObj.useBinWeightQuantity;
          let mainQuantity = mainQtyObj.quantity;

          mainPricingObject.main['useBinWeightQuantity'] = mainUseBinWeightQuantity;
          mainPricingObject.main['quantity'] = mainQuantity;
          arr.push(mainPricingObject?.main);

          if(mainPricingObject?.bundle && mainPricingObject?.bundle.length > 0){
            mainPricingObject?.bundle.forEach((bundleItem: any) => {
              let bundleItemService = bundleServices.find((x: any) => x?.serviceItemId === bundleItem?.serviceItemId);
              let prevItem = additionalServices.find((x: any) => x?.serviceItemId === bundleItem?.serviceItemId);
              if(prevItem){
                bundleItemService['useBinWeightQuantity'] = prevItem?.useBinWeightQuantity;
                bundleItemService['quantity'] = prevItem?.quantity;
              }

              let prevBundleQtyObj = setQtyByUseBinWeightQuantity(bundleItemService);
              let prevBundleUseBinWeightQuantity = prevBundleQtyObj.useBinWeightQuantity;
              let prevBundleQuantity = prevBundleQtyObj.quantity;

              bundleItem['useBinWeightQuantity'] = prevBundleUseBinWeightQuantity;
              bundleItem['quantity'] = prevBundleQuantity;
              arr.push(bundleItem);
            });
          }
        }
      }
    });
  }

  if(withoutBundle && withoutBundle.length > 0){
    withoutBundle.forEach((aitem: any) => {
      arr.push(aitem);
    });
  }

  if(arr && arr.length > 0){
    arr.sort((a: any, b: any) => a.serviceType - b.serviceType).forEach((pItem: any, i: number) => {
      pricingsArr.push({
        ...pItem,
        sortOrder: (i+1),
      });
    });
  }
  
  return pricingsArr;
}

export const checkStatus = (statusName: string = '', arr: Array<string> = []) => {
  let status = false;

  if(arr && arr.length > 0){
    status = arr.includes(statusName.toLowerCase().trim())
  }

  return status;
};
export const findStatus = (arr: Array<any> = [], statusName: string = '', field: string = 'jobStatusName') => {
  let status = null;

  if(arr && arr.length > 0){
    let statusItem = arr.filter(x => x?.templateStatus[field].toLowerCase().trim() === statusName.toLowerCase().trim());

    if(statusItem && statusItem.length > 0){
      status = statusItem[0]?.templateStatus;
    }
  }

  return status;
};
export const isVisibleAutoSuggestDriver = (status: string = '') => {
  if (status.toString().toLowerCase().trim() === '') {
    return true;
  } else if (status.toString().toLowerCase().trim() === 'unassigned') {
    return true;
  } else if (status.toString().toLowerCase().trim() === 'assigned') {
    return true;
  } else if (status.toString().toLowerCase().trim() === 'dispatched') {
    return true;
  } else {
    return false;
  }
}
export const formatCustomerSiteAddress = (item: any = null) => {
  let address = '';

  if (item) {
    let siteName = (item.siteName && item.siteName !== '') ? item.siteName : '';
    let siteNameDisplay = (item.siteNameDisplay && item.siteNameDisplay !== '') ? item.siteNameDisplay : '';
    let siteN = (siteNameDisplay !== '') ? siteNameDisplay : siteName;

    let blockNo = (item.blockNo && item.blockNo !== '') ? item.blockNo : '';
    let street = (item.street && item.street !== '') ? item.street : '';
    let unitNo = (item.unitNo && item.unitNo !== '') ? item.unitNo : '';
    let postalCode = (item.postalCode && item.postalCode !== '') ? item.postalCode : '';

    let addressParts = [];
    if (blockNo !== '') {
      addressParts.push('Block ' + blockNo);
    }
    if (street !== '') {
      addressParts.push(street);
    }
    if (unitNo !== '') {
      addressParts.push('Unit No. ' + unitNo);
    }
    if (postalCode !== '') {
      addressParts.push('Post Code ' + postalCode);
    }

    address = siteN;
    if (addressParts && addressParts.length > 0) {
      address += ' (' + addressParts.join(', ') + ')';
    }
  }

  return address;
}
export const concatStringRemarks = (mainText = '', newText = '', prevText = '', shouldChange = true) => {
  mainText = mainText.replace(prevText, '');
  if (shouldChange) {
    mainText = newText + '\n' + mainText;
  }
  mainText = mainText.replace(/^\s*[\r\n]/gm, '');
  return mainText;
}
export const isStandardService = (item: any) => {
  return ((item.serviceType == ServiceType.StandardService) || (item.serviceType == ServiceType.BundleService)) ? true : false;
}
export const isMainPriceUseBinWeightQuantity = (pricings: Array<any> = [], item: any = null) => {
  let isMain = false;

  if (pricings && pricings.length > 0) {
    if (item) {
      let standardServiceIndex = pricings.findIndex(x => isStandardService(x));
      if (standardServiceIndex > -1) {
        if (pricings[standardServiceIndex] && pricings[standardServiceIndex].bundleServiceTag) {
          if ((item.useBinWeightQuantity == true) && (item.bundleServiceTag == pricings[standardServiceIndex].bundleServiceTag)) {
            isMain = true;
          }
        }
      }
    }
  }

  return isMain;
}
export const setQtyAtMainPricings = (pricings: Array<any> = [], billableWeight: any) =>{
  if (pricings && pricings.length > 0) {
      let standardServiceIndex = pricings.findIndex(x => isStandardService(x));
      if (standardServiceIndex > -1) {
          if (pricings[standardServiceIndex] && pricings[standardServiceIndex].bundleServiceTag) {
              let mainAdditionalIndex = pricings.findIndex(x => (x.useBinWeightQuantity == true) && (x.bundleServiceTag == pricings[standardServiceIndex].bundleServiceTag));
              if (mainAdditionalIndex > -1) {
                  pricings[mainAdditionalIndex].quantity = billableWeight;
                  pricings[mainAdditionalIndex].isMain = true;
              }
          }
      }

      return pricings;
  } else {
      return []
  }
}

export const markerLabelColor = (hexColor  = '#43936C') => {
  try {
    const dynamicClassName = `marker-label-${hexColor.replace("#", "")}`;
    const styleElement = document.createElement("style");
    styleElement.textContent = `
      .${dynamicClassName} {
        background-color: ${hexColor};
        color: white !important;
        font-weight: bold !important;
        font-size: 11px !important;
        padding: 3px 9px !important;
        border-radius: 20px !important;
      }
    `;
    document.head.appendChild(styleElement);
  }catch(e){}
}
export const defaultMarkerIcon = (text = '', color = '#7F818D', cls = '') => {
  try {
    let icon = encodeURIComponent(`<svg class="${cls}" width="44" height="54" viewBox="0 0 44 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22" cy="22" r="22" fill="white"/>
      <rect x="3" y="3" width="38" height="38" rx="19" fill="white"/>
      <rect x="3" y="3" width="38" height="38" rx="19" stroke="${color}" stroke-width="2"/>
      <rect x="6" y="6" width="32" height="32" rx="16" fill="${color}"/>
      <rect x="21" y="40" width="3" height="14" rx="1.5" fill="${color}"/>
    </svg>`)

    return {
      icon: {
        url: 'data:image/svg+xml;utf-8,' + icon,
        scaledSize: new window.google.maps.Size(44, 44),
        labelOrigin: new window.google.maps.Point(22, 18),
      },
      label: {
        text: text,
        color: "white",
        fontSize: "14px",
        fontWeight: "500"
      }
    }
  } catch(e){
    return {}
  }
}
export const driverMarkerIcon = (text = '', col = '', cls = '') => {
  try {
    let color = (col && col != '') ? col : '#43936C';

    let icon = encodeURIComponent(`<svg class="${cls}"  width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5 14.0909C27.5 24.2727 14.5 33 14.5 33C14.5 33 1.5 24.2727 1.5 14.0909C1.5 6.861 7.3203 1 14.5 1C21.6797 1 27.5 6.861 27.5 14.0909Z" fill="${color}" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <rect x="5.5" y="5" width="18" height="18" rx="9" fill="white"/>
      <g clip-path="url(#clip0_1160_3948)">
        <path d="M16.1667 15.6667V10.25H9.91675V15.6667H16.1667ZM16.1667 15.6667H19.0834V13.5833L17.8334 12.3333H16.1667V15.6667ZM12.8334 16.7083C12.8334 17.2836 12.367 17.75 11.7917 17.75C11.2165 17.75 10.7501 17.2836 10.7501 16.7083C10.7501 16.133 11.2165 15.6667 11.7917 15.6667C12.367 15.6667 12.8334 16.133 12.8334 16.7083ZM18.2501 16.7083C18.2501 17.2836 17.7837 17.75 17.2084 17.75C16.6331 17.75 16.1667 17.2836 16.1667 16.7083C16.1667 16.133 16.6331 15.6667 17.2084 15.6667C17.7837 15.6667 18.2501 16.133 18.2501 16.7083Z" stroke="${color}" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_1160_3948">
          <rect width="10" height="10" fill="white" transform="translate(9.5 9)"/>
        </clipPath>
      </defs>
    </svg>`)

    markerLabelColor(color);

    return {
      icon: {
        url: 'data:image/svg+xml;utf-8,' + icon,
        scaledSize: new window.google.maps.Size(26, 32),
        labelOrigin: new window.google.maps.Point(13, 41),
      },
      label: {
        text: text,
        color: "white",
        className: "marker-label-" + color.replace("#", ""),
      }
    }
  } catch(e){
    return {}
  }
}
export const arrowMarkerIcon = (data: any, item: any, i: number) => {
  try {
    let toPosition = data[i + 1] ? data[i + 1]?.position : null;

    return {
      icon: {
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 2.5,
        fillColor: "#FFFFFF",
        fillOpacity: 1,
        strokeWeight: 1,
        strokeColor: "#000000",
        rotation: i < data.length - 1 ? getBearing(item.position, toPosition) : 0,
      }
    }
  } catch(e){
    return {}
  }
}


export const BinActivity = {
  In: 0,
  Out: 1,
  NoActivity: 2,
}


export interface customerSiteTypesStruct {
  customerSiteId: number|null,
  customerSiteName: string,
  site: any,
  
  instructions: string,
  contactPersonName: string,
  contactPersonPhoneNumber: string,
  contactPersonNameTwo: string,
  contactPersonPhoneNumberTwo: string,

  address: string,
  fullAddress: string,
  siteName: string,
  latitude: string,
  longitude: string,
}
export const customerSiteInit: customerSiteTypesStruct = {
  customerSiteId: null,
  customerSiteName: '',
  site: null,
  
  instructions: '',
  contactPersonName: '',
  contactPersonPhoneNumber: '',
  contactPersonNameTwo: '',
  contactPersonPhoneNumberTwo: '',

  address: '',
  fullAddress: '',
  siteName: '',
  latitude: '',
  longitude: '',
}

export interface jobBinTypesStruct {
  jobBinTypeId: number|null,
  binTypeId: number|null,
  binType: any,
  wasteTypeId: number|null,
  wasteType: any,
  wasteTypes: any,
  serviceItemId: number|null,
  serviceItem: any,
  binNumberId: any,
  binNumber: string,
  binOutWeight: any,
  binWeight: any,
  tareBinWeight: any,
  netBinWeight: any,
  nonBillableBinWeight: any,
  billableBinWeight: any,
  weightChitTicketNumber: any,
  weightChitRemarks: any,
  binWeighBy: any,
  weightChitDateTime: any,
  weightChitOutDateTime: any,
  order: number,
  binActivity: number,
  isNoninventory: boolean,
  isBillable: boolean,
  isInVehicle: boolean,
  stepOrder: number,
  quantity: number|null,
}
export const jobBinTypesInit: jobBinTypesStruct = {
  jobBinTypeId: 0,
  binTypeId: null,
  binType: null,
  wasteTypeId: 0,
  wasteType: null,
  wasteTypes: [],
  serviceItemId: null,
  serviceItem: null,
  binNumberId: null,
  binNumber: '',
  binOutWeight: null,
  binWeight: null,
  tareBinWeight: null,
  netBinWeight: null,
  nonBillableBinWeight: null,
  billableBinWeight: null,
  weightChitTicketNumber: null,
  weightChitRemarks: null,
  binWeighBy: null,
  weightChitDateTime: null,
  weightChitOutDateTime: null,
  order: 1,
  binActivity: 1,
  isNoninventory: false,
  isBillable: false,
  isInVehicle: false,
  stepOrder: 1,
  quantity: 1,
}

export interface jobStepBinTypesStruct {
  order: number,
  binActivity: number,
  binTypeOrder: number,
  stepOrder: number,
  jobBinTypeId: number|null,
  jobBinType: any,
}
export const jobStepBinTypesInit: jobStepBinTypesStruct = {
  order: 1,
  binActivity: 0,
  binTypeOrder: 1,
  stepOrder: 1,
  jobBinTypeId: null,
  jobBinType: null,
}

export interface groupedJobStepBinTypesStruct {
  order: number,
  binActivity: number,
  stepOrder: number,
  binTypeId: number|null,
  quantity: number|null,
}
export const groupedJobStepBinTypesInit: groupedJobStepBinTypesStruct = {
  order: 1,
  binActivity: 1,
  stepOrder: 1,
  binTypeId: null,
  quantity: 1
}


export interface initialValuesStruct {
  isActive: boolean,
  isBillable: boolean,
  action: number|null,

  customerId: number|null,
  customerName: string,
  customer: any,
  remarks: string,

  jobTemplateId: number|null,
  steps: Array<any>,
  jobBinTypes: Array<jobBinTypesStruct>,
  stepGroups: Array<any>,

  jobDate: string,
  jobTimeSpecific: string,
  accountJobWorkingTimeId: number|null,
  accountJobWorkingTimeName: string,
  driverId: number|null,
  driverName: string,
  vehicleId: number|null,
  vehicleName: string,

  statusId: number|null,
  statusName: string,
  currentStatusId: number|null,
  currentStatusName: string,

  multipleTrip: boolean,
  numberOfTrip: number|null,
  assignToSameDriverVehicle: boolean,
  
  haveFollowUp: boolean,
  followUpDate: string,
  followUpTimeSpecific: string,
  followUpDays: number|null,
  followUpAssignToSameDriverVehicle: boolean,
  
  isRequirePaymentCollection: boolean,
  amountToCollect: number|null,
  collectedAmount: number|null,
  jobPaymentType: number|null,

  photoAttachmentsUrl: UploadFile[],

  jobSignatureUrl: UploadFile[],
  jobSignedUserName: string,
  jobSignedUserContact: string,
};
export const initialValues: initialValuesStruct = {
  isActive: true,
  isBillable: false,
  action: JobAction.Save,

  customerId: null,
  customerName: '',
  customer: null,
  remarks: '',

  jobTemplateId: null,
  steps: [],
  jobBinTypes: [],
  stepGroups: [],

  jobDate: '',
  jobTimeSpecific: '',
  accountJobWorkingTimeId: null,
  accountJobWorkingTimeName: '',
  driverId: null,
  driverName: '',
  vehicleId: null,
  vehicleName: '',

  statusId: null,
  statusName: '',
  currentStatusId: null,
  currentStatusName: '',

  multipleTrip: false,
  numberOfTrip: null,
  assignToSameDriverVehicle: false,
  
  haveFollowUp: false,
  followUpDate: '',
  followUpTimeSpecific: '',
  followUpDays: 0,
  followUpAssignToSameDriverVehicle: false,
  
  isRequirePaymentCollection: false,
  amountToCollect: null,
  collectedAmount: null,
  jobPaymentType: null,

  photoAttachmentsUrl: [],

  jobSignatureUrl: [],
  jobSignedUserName: '',
  jobSignedUserContact: '',
};


interface InitState {
  isLoading: boolean,
  details: any,

  isLoadingCreateUpdateDelete: boolean,

  isLoadingCreditLimit: boolean,
  isCreditLimit: boolean,
  msgCreditLimit: string,

  isLoadingJobTemplates: boolean,
  jobTemplates: Array<any>,
  jobTemplate: any,
  job: any,

  isLoadingCheckAllowPullJob: boolean,
  checkAllowPullJob: boolean,

  isLoadingAutoSuggestDriver: boolean,
  autoSuggestDriver: any,

  isLoadingDefaultLocations: boolean,
  defaultLocations: Array<any>,
  
  isLoadingLatestLocationDrivers: boolean,
  latestLocationDrivers: Array<any>,

  isLoadingDistance: boolean,
  distance: number,

  isLoadingDownloadInternalNote: boolean,
  isLoadingRefreshJobNote: boolean,
}

function NewReducer() {
  const name = 'jobFormSlice';


  const initialState: InitState = {
    isLoading: false,
    details: null,

    isLoadingCreateUpdateDelete: false,

    isLoadingCreditLimit: false,
    isCreditLimit: false,
    msgCreditLimit: '',
    
    isLoadingJobTemplates: false,
    jobTemplates: [],
    jobTemplate: null,
    job: null,
    
    isLoadingCheckAllowPullJob: false,
    checkAllowPullJob: false,

    isLoadingAutoSuggestDriver: false,
    autoSuggestDriver: null,

    isLoadingDefaultLocations: false,
    defaultLocations: [],

    isLoadingLatestLocationDrivers: false,
    latestLocationDrivers: [],
    
    isLoadingDistance: true,
    distance: 0,

    isLoadingDownloadInternalNote: false,
    isLoadingRefreshJobNote: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startReadCheckCreditLimit: (state: InitState) => {
      state.isLoadingCreditLimit = true;
      state.isCreditLimit = false;
      state.msgCreditLimit = '';
    },
    finishReadCheckCreditLimit: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload != '') ? action.payload : null;
      
      if (data) {
        state.isCreditLimit = true;
        state.msgCreditLimit = data;
      } else {
        state.isCreditLimit = false;
        state.msgCreditLimit = '';
      }

      state.isLoadingCreditLimit = false;
    },
    resetCheckCreditLimit: (state: InitState) => {
      state.isLoadingCreditLimit = false;
      state.isCreditLimit = false;
      state.msgCreditLimit = '';
    },

    startReadJobTemplate: (state: InitState) => {
      state.isLoadingJobTemplates = true;
      state.jobTemplates = [];
    },
    finishReadJobTemplate: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.jobTemplates = data;
      state.isLoadingJobTemplates = false;
    },

    startDetailsJobTemplate: (state: InitState) => {
      state.isLoadingJobTemplates = true;
      state.jobTemplate = null;
    },
    finishDetailsJobTemplate: (state: InitState, action: PayloadAction<any>) => {
      state.jobTemplate = action.payload;
      state.isLoadingJobTemplates = false;
    },
    clearJobTemplate: (state: InitState) => {
      state.jobTemplate = null;
    },

    startCheckAllowPullJob: (state: InitState) => {
      state.isLoadingCheckAllowPullJob = true;
      state.checkAllowPullJob = false;
    },
    finishCheckAllowPullJob: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : false;
      state.checkAllowPullJob = data;
      state.isLoadingCheckAllowPullJob = false;
    },

    startAutoSuggestDriver: (state: InitState) => {
      state.isLoadingAutoSuggestDriver = true;
      state.autoSuggestDriver = null;
    },
    finishAutoSuggestDriver: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data) ? action.payload.data : null;
      state.autoSuggestDriver = data;
      state.isLoadingAutoSuggestDriver = false;
    },

    startReadDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishReadDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let defaultLocations: any = [];
      if(data && data.length > 0){
        defaultLocations = data.map((item: any, i: number) => {
          item['addressField'] = item.address;
          return item;
        });
      }

      state.defaultLocations = defaultLocations;
      state.isLoadingDefaultLocations = false;
    },

    startLatestLocationDrivers: (state: InitState) => {
      state.isLoadingLatestLocationDrivers = true;
      state.latestLocationDrivers = [];
    },
    finishLatestLocationDrivers: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.length > 0) ? action.payload : [];
      
      let arr: any = [];
      if(data && data.length > 0){
        arr = data.map((item: any, i: number) => {
          let coordinate = {
            lat: item.latitude,
            lng: item.longitude,
          };

          let driver = (item && item.driver) ? item.driver : null;
          let driverName = (driver && driver.driverName) ? driver.driverName : '';
      
          return {
            driverId: item.driverId,
            driverName: driverName,
            title: 'Updated: ' + moment(item.updated).format(dateTimeMeridianFormat()),
            item: item,
            position: coordinate,
          }
        });
      }
      
      state.latestLocationDrivers = arr;
      state.isLoadingLatestLocationDrivers = false;
    },

    startGetDistance: (state: InitState) => {
      state.isLoadingDistance = true;
      state.distance = 0;
    },
    finishGetDistance: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload) ? action.payload : 0;
      
      state.distance = data;
      state.isLoadingDistance = false;
    },
    cancelGetDistance: (state: InitState) => {
      state.isLoadingDistance = false;
      state.distance = 0;
    },

    startJobTemplateBoilerplate: (state: InitState) => {
      state.isLoadingJobTemplates = true;
      state.job = null;
    },
    finishJobTemplateBoilerplate: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload) ? action.payload : null;
      state.job = data;
      state.isLoadingJobTemplates = false;
    },
    clearJob: (state: InitState) => {
      state.job = null;
    },

    startDownloadInternalNote: (state: InitState) => {
      state.isLoadingDownloadInternalNote = true;
    },
    finishDownloadInternalNote: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDownloadInternalNote = false;
    },
    
    startRefreshJobNote: (state: InitState) => {
      state.isLoadingRefreshJobNote = true;
    },
    finishRefreshJobNote: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingRefreshJobNote = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('job', { params: params }).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('job/' + id).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('job', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            // success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('job', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            // success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('job', { data: params }).then((result: any) => {
            let data = result.data;
                
            // successAPI(data);
            success('Deleted successfully', 'Job Type has been successfully deleted.');

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callActivateApi: (isActive: boolean, ids: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startActivate());

        let params = {
          isActive: isActive,
          ids: ids,
        };

        await axios.put('job/deactivate', params).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishActivate(obj));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishActivate(null));
        });
    },

    callReadCheckCreditLimitApi: (customerId: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      if(customerId && isNumeric(customerId) && customerId > 0){
        dispatch(actions.startReadCheckCreditLimit());

        await axios.get('Customer/' + customerId + '/check-credit-limit').then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadCheckCreditLimit(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadCheckCreditLimit(null));
        });
      }
    },

    callReadJobTemplateApi: (params: ReadAPIParams|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadJobTemplate());

      let param = (params) ? params : {
        currentPage: 1,
        pageSize: getMaxPageSize(),
        searchQuery: '',
        sortColumn: 'common',
        sortDir: 'desc',
      };
      
      await axios.get('jobtemplate', { params: param }).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishReadJobTemplate(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishReadJobTemplate(null));
      });
    },

    callDetailsJobTemplateApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetailsJobTemplate());
  
        await axios.get('jobtemplate/' + id).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetailsJobTemplate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetailsJobTemplate(null));
        });
    },

    callCheckAllowPullJobApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCheckAllowPullJob());

      await axios.get('job/check-allow-pull-job', { params: null }).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishCheckAllowPullJob(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishCheckAllowPullJob(null));
      });
    },

    callAutoSuggestDriverApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startAutoSuggestDriver());

      await axios.post('job/auto-suggest-driver', params).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishAutoSuggestDriver(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishAutoSuggestDriver(null));
      });
    },

    callReadDefaultLocationApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadDefaultLocation());

      await axios.get('default-location', { params: params }).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishReadDefaultLocation(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishReadDefaultLocation(null));
      });
    },
    
    callLatestLocationDriversApi: (ids: Array<any>, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startLatestLocationDrivers());

      await axios.post('driver/latest-location', { driverIds: ids }).then((result: any) => {
          let data = result.data;
          
          // successAPI(data);

          callback(true, data);
          dispatch(actions.finishLatestLocationDrivers(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishLatestLocationDrivers(null));
      });
    },
    
    callGetDistanceApi: (coordinates: Array<any>, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      if(coordinates && coordinates.length > 0){
        dispatch(actions.startGetDistance());

        await axios.post('route/get-distance', coordinates).then((result: any) => {
          let data = result.data;
          
          // successAPI(data);

          callback(true, data);
          dispatch(actions.finishGetDistance(data));
        }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishGetDistance(null));
        });
      } else {
        dispatch(actions.cancelGetDistance());
      }
    },

    callJobTemplateBoilerplateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startJobTemplateBoilerplate());
  
        await axios.post('job/job-template-boilerplate', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);

            callback(true, data);
            dispatch(actions.finishJobTemplateBoilerplate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishJobTemplateBoilerplate(null));
        });
    },

    callDownloadInternalNoteApi: (jobId: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDownloadInternalNote());

      await axios.get('job/get-job-internal-note/' + jobId).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishDownloadInternalNote(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishDownloadInternalNote(null));
      });
    },
    callRefreshJobNoteApi: (jobId: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRefreshJobNote());

      await axios.get('job/' + jobId).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishRefreshJobNote(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishRefreshJobNote(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();