import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Modal,
  Form,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Spin, Divider, Radio } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../v5/utils/store.tsx';
import slice, { fields, FormikContext, initialValues, formSchema, prepareForm, initialDefaultLocation } from './slice.tsx';
import confirmSlice from '../../../../components/modal/confirmSlice.tsx';

// enums
import { getAddressPlace, warning, autoFoucus, getMaxPageSize } from '../../../../../v5/utils/utils.tsx';
// import {  } from '../../../../../v5/utils/enums.tsx';

import GoogleSearchAddress from '../../../../../v5/components/googleMap/GoogleSearchAddress.tsx';

import { ReactComponent as TrashIcon } from "../../../../../v5/assets/icons/trash-2.svg";

// ----------------------------------------------------------------------

const StyledRow = styled(Row)`
  .ant-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    border-color: var(--bs-gray-100);
  }
`;


const FormElement = ({ onSave }: any) => {
  const { isLoading, list, show, groupIndex, blockIndex, stepIndex, step } = useTypedSelector((state: RootState) => state.jobFormDefaultLocationFormSlice);
  const dispatch = useDispatch<AppDispatch>();
  
  const formikRef = React.useRef<any>(null);


  React.useEffect(() => {
    if(show){
      let params = {
        currentPage: 1,
        pageSize: getMaxPageSize(),
        searchQuery: '',
        sortColumn: 'created',
        sortDir: 'asc',
      };
      dispatch(slice.callReadApi(params, (state: boolean, data: any) => {}));
    }
  }, [show]);

  React.useEffect(() => {
    dispatch(slice.setLoading(true));
    let form = prepareForm(list, initialValues);
    setValues(form);
    dispatch(slice.setLoading(false));
  }, [list]);


  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: formSchema(null),
    onSubmit: values => {},
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;
  formikRef.current = formik;


  const onSubmit = () => {
    if(step?.defaultLocationId && step?.defaultLocationId > 0){
      validateForm().then((err: any) => {
        if(!!err?.defaultLocation){
          warning('Warning', 'You must fill in the required fields');
        } else {
          if(onSave){
            onSave(groupIndex, blockIndex, stepIndex, step)
          }
        }
      })

      onCancel();
    }
  }
  const onCancel = () => {
    let form = prepareForm(null, initialValues);
    setValues(form);
    dispatch(slice.setShow({ show: false, group: null, groupIndex: null, block: null, blockIndex: null, step: null, stepIndex: null }));
    dispatch(slice.resetSlice());
  }


  const setFooter = () => {
    return <Row className='gx-12 w-100'>
      <Col xs={true}></Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-text'}
          size={'sm'}
          disabled={isLoading}
          onClick={() => {
            onCancel();
          }}
        >Cancel</Button>
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          disabled={isLoading || !(step?.defaultLocationId && step?.defaultLocationId > 0)}
          onClick={() => {
            onSubmit();
          }}
        >Save</Button>
      </Col>
    </Row>
  }

  const setForm = () => {
    return <StyledRow className={'g-12'}>
      <Col xs={12}>
        <Row className='g-8 align-items-center'>
          <Col xs={'auto'} md={3} lg={2}><small className="semibold gray-600">{fields.isDefault.label}</small></Col>
          <Col xs={'auto'} md={3} lg={4} xl={3} xxl={2}><small className="semibold gray-600">{fields.locationName.label}</small></Col>
          <Col xs={true} md={true}><small className="semibold gray-600">{fields.address.label}</small></Col>
          <Col xs={'auto'} md={'auto'}><small className="semibold gray-600">&nbsp;</small></Col>
        </Row>
      </Col>

      <Col xs={12}><Divider /></Col>

      {
        (values && values.defaultLocation && values.defaultLocation.length > 0)
        ?
        <>
          {values.defaultLocation.map((item: any, i: number) => {
            return <Col xs={12} key={'edit_default_location_item_' + i}>
              <Row className='align-items-center g-8 pb-24 pb-lg-0'>
                <Col xs={{ span: 'auto', order: 1 }} md={{ span: 'auto', order: 2 }} lg={{ span: 2, order: 1 }} className='text-center'>
                  {(item?.defaultLocationId && item?.defaultLocationId > 0) && <Radio
                    disabled={false}
                    checked={(item.defaultLocationId === step.defaultLocationId)}
                    onChange={async (e) => {
                      let stepObj: any = _.cloneDeep(step);
                      stepObj.defaultLocationId = item?.defaultLocationId;
                      stepObj.defaultLocation = item;
                      dispatch(slice.setStep(stepObj));
                    }}
                  />}
                </Col>

                <Col xs={{ span: 12, order: 3 }} md={{ span: 12, order: 1 }} lg={{ span: 4, order: 2 }}>
                  <Form.Group>
                    <Form.Control
                      className={'location-name-' + i}
                      type={'text'}
                      autoComplete='off'
                      placeholder={'Enter site name'}
                      disabled={false}
                      value={item.locationName}
                      onChange={async (e) => {
                        await setFieldValue('defaultLocation.' + i + '.locationName', e.target.value);
                      }}
                      onBlur={async (e) => {
                        await validateForm().then((err: any) => {
                          const errorsData = err?.defaultLocation?.[i];

                          if (errorsData && Object.entries(errorsData).length > 0) {
                            
                          } else {
                            let params: any = {
                              isDefault: false,
                              locationName: item.locationName,
                              addressField: item.address,
                              address: item.address,
                              latitude: item.latitude,
                              longitude: item.longitude,
                            };
                            if(item.defaultLocationId && item.defaultLocationId > 0){
                              params['defaultLocationId'] = item.defaultLocationId;
                              dispatch(slice.callUpdateApi(params, async (state: boolean, data: any) => {
                                if(state){
                                  await setFieldValue('defaultLocation.' + i, data);
                                }
                              }));
                            } else {
                              dispatch(slice.callCreateApi(params, async (state: boolean, data: any) => {
                                if(state){
                                  await setFieldValue('defaultLocation.' + i, data);
                                }
                              }));
                            }
                          }
                        })
                      }}
                      isInvalid={!!(errors && errors.defaultLocation && errors.defaultLocation.length > 0 && errors.defaultLocation[i] && typeof errors.defaultLocation[i] === 'object' && errors.defaultLocation[i].locationName)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={{ span: 12, order: 4 }} md={{ span: true, order: 3 }} lg={{ span: true, order: 3 }}>
                  <Form.Group>
                    <Form.Control
                      as={GoogleSearchAddress}
                      autoComplete='off'
                      placeholder={'Insert address or coordinate'}
                      isInvalid={!!(errors && errors.defaultLocation && errors.defaultLocation.length > 0 && errors.defaultLocation[i] && typeof errors.defaultLocation[i] === 'object' && errors.defaultLocation[i].address)}
                      // disabled={!(item.locationName != '')}
                      value={item.addressField}
                      onPlaceSelected={async (place: any) => {
                        let obj = getAddressPlace(place);

                        await setFieldValue('defaultLocation.' + i + '.addressField', obj?.address);
                        await setFieldValue('defaultLocation.' + i + '.address', obj?.address);
                        await setFieldValue('defaultLocation.' + i + '.latitude', obj?.lat);
                        await setFieldValue('defaultLocation.' + i + '.longitude', obj?.lng);

                        await validateForm().then((err: any) => {
                          const errorsData = err?.defaultLocation?.[i];

                          if (errorsData && Object.entries(errorsData).length > 0) {
                            
                          } else {
                            const currentItem = formikRef.current?.values?.defaultLocation[i];

                            let params: any = {
                              isDefault: false,
                              locationName: currentItem.locationName,
                              addressField: obj?.address,
                              address: obj?.address,
                              latitude: obj?.lat,
                              longitude: obj?.lng,
                            };
                            if(item.defaultLocationId && item.defaultLocationId > 0){
                              params['defaultLocationId'] = item.defaultLocationId;
                              dispatch(slice.callUpdateApi(params, async (state: boolean, data: any) => {
                                if(state){
                                  await setFieldValue('defaultLocation.' + i, data);
                                }
                              }));
                            } else {
                              dispatch(slice.callCreateApi(params, async (state: boolean, data: any) => {
                                if(state){
                                  await setFieldValue('defaultLocation.' + i, data);
                                }
                              }));
                            }
                          }
                        })
                      }}
                      onChange={async (e) => {
                        await setFieldValue('defaultLocation.' + i + '.addressField', e.target.value);
                      }}
                      onBlur={async (e) => {
                        if(e.target.value != item.address){
                          await setFieldValue('defaultLocation.' + i + '.addressField', '');
                          await setFieldValue('defaultLocation.' + i + '.address', '');
                          await setFieldValue('defaultLocation.' + i + '.latitude', null);
                          await setFieldValue('defaultLocation.' + i + '.longitude', null);
                        }
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xs={{ span: 'auto', order: 2 }} md={{ span: 'auto', order: 4 }} lg={{ span: 'auto', order: 4 }} className='text-center'>
                  <Button
                    className={'px-10 py-8'}
                    variant={'custom-secondary-danger'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();

                      if(item.defaultLocationId){
                        dispatch(confirmSlice.show(() => {
                          let items = _.cloneDeep(values.defaultLocation);
                          items.splice(i, 1);
                          setFieldValue('defaultLocation', items);
                          
                          dispatch(slice.callDeleteApi({ defaultLocationId : item.defaultLocationId }, (state: boolean, data: any) => {
                            if(step?.defaultLocationId === item.defaultLocationId){
                              let stepObj: any = _.cloneDeep(step);
                              stepObj.defaultLocationId = null;
                              stepObj.defaultLocation = null;
                              dispatch(slice.setStep(stepObj));
                            }

                            dispatch(confirmSlice.setHide());
                          }));
                        }, {
                          title: 'Delete Location',
                          body: 'Are you sure to delete this location? This cannot be undone',
                          size: 'sm',
                          cancelText: 'Cancel',
                          cancelVariant: 'custom-outline',
                          confirmText: 'Delete',
                          confirmVariant: 'custom-danger',
                        }));
                      } else {
                        let items = _.cloneDeep(values.defaultLocation);
                        items.splice(i, 1);
                        setFieldValue('defaultLocation', items);
                      }
                    }}
                  >
                    <TrashIcon />
                  </Button>
                </Col>

              </Row>
            </Col>
          })}
          
          {errors?.defaultLocation && typeof errors.defaultLocation === "string" && <Col xs={12}>
            <Form.Control.Feedback className={'d-block'} type="invalid">{errors.defaultLocation}</Form.Control.Feedback>
          </Col>}
          {!(step?.defaultLocationId && step?.defaultLocationId > 0) && <Col xs={12}>
            <Form.Control.Feedback className={'d-block'} type="invalid">You must choose a default location</Form.Control.Feedback>
          </Col>}
        </>
        :
        <Col xs={12}>No locations</Col>
      }

      <Col xs={12}><Divider /></Col>

      <Col xs={12}>
        <Button
          className={'px-0'}
          size={'sm'}
          variant={'custom-text-primary'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            validateForm().then((err: any) => {
              if(!!err?.defaultLocation){
                warning('Warning', 'You must fill in the required fields');
              } else {
                let items = _.cloneDeep(values.defaultLocation);
                let newItem = _.cloneDeep(initialDefaultLocation);
                items.push(newItem);
                setFieldValue('defaultLocation', items);
                autoFoucus('.location-name-' + (items.length-1));
              }
            })
          }}
        >
          Add More Location
        </Button>
      </Col>
    </StyledRow>
  }


  return <Modal
    show={show}
    onHide={() => {
      dispatch(slice.setShow({ show: false, group: null, groupIndex: null, block: null, blockIndex: null, step: null, stepIndex: null }));
    }}
    backdrop={'static'}
    keyboard={false}
    enforceFocus={false}
    centered={true}
    size={'lg'}
  >
    <FormikContext.Provider value={formik}>
      <Modal.Header closeButton>
        <h6 className='fw-medium'>Edit Default Location</h6>
      </Modal.Header>

      <Spin spinning={isLoading} wrapperClassName={'no-height'}>
        <Modal.Body className='pt-24'>{setForm()}</Modal.Body>
      </Spin>

      <Modal.Footer>{setFooter()}</Modal.Footer>
    </FormikContext.Provider>
  </Modal>
}

export default FormElement;
