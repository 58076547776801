import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Modal,
  Form,
} from 'react-bootstrap';

import { Spin } from 'antd';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../../utils/store.tsx';
import slice, { fields, FormikContext, initialValues, formSchema, prepareForm, prepareData } from './slice.tsx';

// enums
import { getAddressPlace } from '../../../../../../utils/utils.tsx';
// import {  } from '../../../../../../utils/enums.tsx';

import GoogleSearchAddress from '../../../../../../components/googleMap/GoogleSearchAddress.tsx';

// ----------------------------------------------------------------------

const FormElement = ({ onCreate, onUpdate, onDelete }: any) => {
  const { isLoading, show, index, id, details } = useTypedSelector((state: RootState) => state.defaultLocationFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useEffect(() => {
    if(show && (id && id > 0)){
      dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
    } else {
      onCancel();
    }
  }, [id]);

  React.useEffect(() => {
    dispatch(slice.setLoading(true));
    let form = prepareForm(details, initialValues);
    setValues(form);
    dispatch(slice.setLoading(false));
  }, [details]);


  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: formSchema(null),
    onSubmit: values => {
      onSubmit(values, (data: any) => {
        onCancel();
      });
    },
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


  const onSubmit = (values: any, callback: (data: any) => void) => {
    let data = prepareData(values, id);
    if(id && id > 0){
      dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
        if(state){
          if(onUpdate){
            onUpdate(index, data);
          }
          
          callback(data);
        }
      }));
    } else {
      dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
        if(state){
          if(onCreate){
            onCreate(index, data);
          }

          callback(data);
        }
      }));
    }
  }
  const onCancel = () => {
    let form = prepareForm(null, initialValues);
    setValues(form);
    dispatch(slice.setValues(form));
    dispatch(slice.setShow({ show: false, index: null, id: null }));
    dispatch(slice.resetSlice());
  }


  const setFooter = () => {
    return <Row className='gx-12 w-100'>
      <Col xs={true}>
        {(id && id > 0) && <Button
          variant={'custom-danger'}
          size={'sm'}
          disabled={isLoading}
          onClick={() => {
            dispatch(slice.callDeleteApi({ defaultLocationId: id }, (state: boolean, data: any) => {
              if(state){
                if(onDelete){
                  onDelete(index, id);
                }

                onCancel();
              }
            }));
          }}
        >Delete</Button>}
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-text'}
          size={'sm'}
          disabled={isLoading}
          onClick={() => {
            onCancel();
          }}
        >Cancel</Button>
      </Col>
      <Col xs={'auto'}>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          disabled={isLoading}
          onClick={() => {
            handleSubmit();
          }}
        >Save</Button>
      </Col>
    </Row>
  }

  const setForm = () => {
    return <Row className={'g-16'}>
      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.locationName.label}</Form.Label>
          <Form.Control
            type={'text'}
            autoComplete='off'
            placeholder={fields.locationName.placeholder}
            disabled={false}
            value={values.locationName}
            onChange={(e: any) => {
              setFieldValue('locationName', e.target.value);
            }}
            isInvalid={!!(errors && errors.locationName)}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.locationName as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.address.label}</Form.Label>
          <Form.Control
            as={GoogleSearchAddress}
            autoComplete='off'
            placeholder={'Insert address or coordinate'}
            isInvalid={!!(errors && errors.address)}
            value={values.addressField}
            onPlaceSelected={async (place: any) => {
              let obj = getAddressPlace(place);

              await setFieldValue('addressField', obj?.address);
              await setFieldValue('address', obj?.address);
              await setFieldValue('latitude', obj?.lat);
              await setFieldValue('longitude', obj?.lng);
            }}
            onChange={async (e) => {
              await setFieldValue('addressField', e.target.value);
            }}
            onBlur={async (e) => {
              if(e.target.value != values.address){
                await setFieldValue('addressField', '');
                await setFieldValue('address', '');
                await setFieldValue('latitude', null);
                await setFieldValue('longitude', null);
              }
            }}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.address as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
  }


  return <Modal
    show={show}
    onHide={() => {
      dispatch(slice.setShow({ show: false, index: null, id: null }));
    }}
    backdrop={'static'}
    keyboard={false}
    enforceFocus={false}
    centered={true}
    size={'sm'}
  >
    <FormikContext.Provider value={formik}>
      <Modal.Header closeButton>
        <h6 className='fw-medium'>{((id && id > 0) ? 'Edit' : 'Add')} Default Location</h6>
      </Modal.Header>

      <Spin spinning={isLoading} wrapperClassName={'no-height'}>
        <Modal.Body>{setForm()}</Modal.Body>
      </Spin>

      <Modal.Footer>{setFooter()}</Modal.Footer>
    </FormikContext.Provider>
  </Modal>
}

export default FormElement;
