import React, { ReactNode, useContext  } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Card,
  Accordion,
  InputGroup,
} from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Radio, Timeline, Divider } from "antd";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, fields, getStepDescription, getDriverAppOptionsStepInfo, DriverAppOptionsLabelEnum, CustomOptions, BinActivityEnum, getBinActivityName, getLocationTypeName, isForWithItem, ForWith, isShiftType } from '../slice.tsx';

// enums
import { getDefaultUOMv4 } from '../../../../../utils/utils.tsx';
import { ReportUOM } from '../../../../../utils/enums.tsx';

// components
// import MainContainer from '../../../../../components/container/MainContainer.jsx';
import Select from '../../../../../components/select/Select.tsx';

import { ReactComponent as PlusIcon } from "../../../../../../v5/assets/icons/plus-2.svg";
import { ReactComponent as MinusIcon } from "../../../../../../v5/assets/icons/minus.svg";
import { ReactComponent as InfoIcon } from "../../../../../../v5/assets/icons/i.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../../../v5/assets/icons/arrow-left.svg";
import { ReactComponent as ChevronUpIcon } from "../../../../../../v5/assets/icons/chevron-up.svg";
import { ReactComponent as ChevronDownIcon } from "../../../../../../v5/assets/icons/chevron-down.svg";
import { ReactComponent as CircleCheckIcon } from "../../../../../../v5/assets/icons/circle-check-2.svg";
import { ReactComponent as QRScanIcon } from "../../../../../../v5/assets/icons/qr-scan.svg";
import { ReactComponent as PhotoIcon } from "../../../../../../v5/assets/icons/photo.svg";
import { ReactComponent as EditIcon } from "../../../../../../v5/assets/icons/edit-2.svg";
import { ReactComponent as CircleIcon } from "../../../../../../v5/assets/icons/circle.svg";

// ----------------------------------------------------------------------

export const StyledStep = styled(Col)`
  border-radius: 6px;
  border: 1px solid var(--bs-gray-100);
  padding: 16px 24px;
  
  .ant-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    border-color: var(--bs-gray-100);
  }
`;
export const StyledInputGroup = styled(InputGroup)`
  .input-group-text {
    padding: 5px 8px !important;
    cursor: pointer;

    svg {
      position: relative;
      top: -2px;
    }

    &:hover {
      svg {
        path {
          stroke: var(--bs-gray-900) !important;
        }
      }
    }

    &:first-child ~ .form-control.custom {
      padding-left: 30px !important;
    }
  }

  .form-control {
    width: 80px;
    padding-right: 30px !important;
    text-align: center;
    padding-top: 4px !important;
    padding-bottom: 2px !important;
    min-height: 32px !important;

    &.custom {
      padding-right: 30px !important;
    }
  }
`;
export const StyledRow = styled(Row)`
  .ant-divider {
    margin-top: 4px;
    margin-bottom: 4px;
    padding-bottom: 12px;
    border-color: var(--bs-gray-100);
  }

  .ant-radio-wrapper {
    margin-inline-end: 0px;
  }
`;

const StyledMobilePreview = styled.div`
  border-radius: 6px;
  border: 1px solid var(--bs-gray-300);
  background-color: var(--bs-gray-50);
  padding: 16px;
  max-width: 392px;
  margin: 0 auto;

  position: sticky;
  top: 0px;

  .title {
    margin-bottom: 20px;
    text-align: center;

    svg {
      position: relative;
      top: -2px;
      margin-right: 4px;
      width: 16px;
      height: 16px;

      path {
        stroke: var(--bs-gray-600);
      }
    }
  }

  .mobile-wrapper {
    border-radius: 25px;
    border: 4px solid var(--bs-gray-800);
    background-color: var(--bs-gray-200);
    padding: 0px;
    overflow: hidden;

    .header {
      padding: 18px 16px 10px 16px;
      background-color: white;
      border-bottom: 1px solid var(--bs-gray-300);

      svg {
        position: relative;
        top: -1px;
        width: 20px;
        height: 20px;
  
        path {
          stroke: var(--bs-gray-900);
        }
      }
    }

    .caption {
      padding: 16px 8px;
      background-color: white;

      .ant-timeline {
        margin-top: 8px;

        .ant-timeline-item {
          padding-bottom: 6px;

          &.ant-timeline-item-last {
            padding-bottom: 0px !important;

            .ant-timeline-item-content {
              min-height: unset !important;
            }
          }

          .ant-timeline-item-head {
            svg {
              position: relative;
              top: -1px;
              width: 5px;
              height: 5px;
        
              circle {
                fill: var(--bs-gray-900) !important;
              }
            }
          }

          .ant-timeline-item-tail {
            z-index: 1;
            border-inline-start: 2px dotted var(--bs-gray-500) !important;
          }
          
          .ant-timeline-item-content {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: var(--bs-gray-900);
            inset-block-start: -5px;
            margin-inline-start: 18px;
          }
        }
      }
    }

    .content {
      padding: 16px 8px;
      overflow-y: scroll;
      height: calc(100vh - var(--manage-layout-top-height, 66px) - var(--manage-layout-tab-height, 44px) - var(--manage-jobtype-caption-height, 139px) - var(--manage-jobtype-header-height, 53px) - 290px);
      min-height: 450px;

      .accordion {
        .card {
          border: 1px solid var(--bs-blue-200);
          background-color: var(--bs-blue-focus1);
          border-radius: 2px;
          overflow: hidden;
          margin-bottom: 12px;
          gap: 8px;
  
          &:last-child {
            margin-bottom: 8px;
          }

          .card-header {
            padding: 0px !important;
            background-color: unset !important;
            border: unset !important;
          }

          .custom-button {
            background-color: white;
            border: 1px solid var(--bs-gray-300);
            border-radius: 2px;
            padding: 4px 7px;
            box-shadow: unset;

            svg {
              position: relative;
              top: -2px;
              width: 16px;
              height: 16px;
        
              path {
                stroke: var(--bs-gray-600);
              }
            }
          }

          .accordion-collapse {
            .card-body {
              .ant-divider {
                margin: 0px 0px 8px 0px;
              }

              .form-label {
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 18px !important;
                color: var(--bs-gray-800);
                margin-bottom: 6px;
              }
              .form-control {
                border-radius: 0px !important;
                border: 1px solid var(--bs-gray-300) !important;
                height: 40px !important;
                box-shadow: unset !important;
              }
              .input-group-text {
                top: 4px;
                font-size: 12px !important;
                font-weight: 400 !important;
                line-height: 18px !important;
                color: var(--bs-gray-600);

                svg {
                  path {
                    stroke: var(--bs-gray-900) !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const StyledPhoto = styled.div`
  height: 40px;
  background-color: white;
  border: 1px solid var(--bs-gray-300);
  text-align: center;
  align-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--bs-gray-800);

  svg {
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;

    path {
      stroke: var(--bs-blue-500);
    }
  }
`;
const StyledSignature = styled.div`
  height: 40px;
  background-color: white;
  border: 1px solid var(--bs-gray-300);
  text-align: center;
  align-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: var(--bs-gray-800);

  svg {
    position: relative;
    top: -1px;
    width: 16px;
    height: 16px;

    path {
      stroke: var(--bs-blue-500);
      opacity: 0.6;
    }
  }
`;


const CustomToggle = ({ eventKey, callback }: any) => {
  const { activeEventKey } = useContext(AccordionContext);

  const onClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  const isCurrentEventKey = activeEventKey === eventKey;

  return (
    <button
      type="button"
      className='custom-button'
      onClick={onClick}
    >
      {isCurrentEventKey ? <ChevronUpIcon /> : <ChevronDownIcon />}
    </button>
  );
}


const Tab3 = ({ formikRef }: any) => {
  // const location = useLocation();
  // const router = useRouter();
  
  const topHeightHeight = document.getElementById('manage_layout_top_ID')?.clientHeight || 66;
  const tabHeight = document.getElementById('manage_layout_tab_ID')?.clientHeight || 44;
  const captionHeight = document.getElementById('manage_jobtype_caption_ID')?.clientHeight || 139;
  const headerHeight = document.getElementById('manage_jobtype_header_ID')?.clientHeight || 53;
  document.documentElement.style.setProperty('--manage-layout-top-height', `${(topHeightHeight)}px`);
  document.documentElement.style.setProperty('--manage-layout-tab-height', `${(tabHeight)}px`);
  document.documentElement.style.setProperty('--manage-jobtype-caption-height', `${(captionHeight)}px`);
  document.documentElement.style.setProperty('--manage-jobtype-header-height', `${(headerHeight)}px`);

  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  // const dispatch = useDispatch<AppDispatch>();


  const setFormDriverPermissons = () => {
    return <Row className='g-16'>
      <StyledStep xs={12}>
        <Row className='g-12'>
          <Col xs={12}>
            <Row className='align-items-center'>
              <Col xs={12} lg={6} className='semibold gray-900 pb-1 pb-lg-0'>Driver’s action</Col>
              <Col xs={true} lg={2} className='semibold gray-900 text-center'>Yes</Col>
              <Col xs={true} lg={2} className='semibold gray-900 text-center'>No</Col>
              <Col xs={true} lg={2} className='semibold gray-900 text-center'>Optional</Col>
            </Row>
          </Col>
          
          {!(values.templateSteps && values.templateSteps.length > 0 && values.templateSteps[0].binActivity === BinActivityEnum.LoadWaste) && <>
            <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.RequiredBinID}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isRequireBinNumber === true)}
                    onChange={(e) => {
                      setFieldValue('isRequireBinNumber', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isRequireBinNumber === false)}
                    onChange={(e) => {
                      setFieldValue('isRequireBinNumber', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
              </StyledRow>
            </Col>
          </>}

          {!(values.templateSteps && values.templateSteps.length > 0 && values.templateSteps[0].binActivity === BinActivityEnum.LoadWaste) && <>
            <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ChangeBin}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.canDriverChangeBinType === true)}
                    onChange={(e) => {
                      setFieldValue('canDriverChangeBinType', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.canDriverChangeBinType === false)}
                    onChange={(e) => {
                      setFieldValue('canDriverChangeBinType', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
              </StyledRow>
            </Col>
          </>}

          <Col xs={12}>
            <StyledRow className='align-items-center'>
              <Col xs={12}><Divider /></Col>
              <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ChangeWaste}</Col>
              <Col xs={true} lg={2} className='text-center'>
                <Radio
                  disabled={false}
                  checked={(values.canDriverChangeWasteType === true)}
                  onChange={(e) => {
                    setFieldValue('canDriverChangeWasteType', true);
                  }}
                />
              </Col>
              <Col xs={true} lg={2} className='text-center'>
                <Radio
                  disabled={false}
                  checked={(values.canDriverChangeWasteType === false)}
                  onChange={(e) => {
                    setFieldValue('canDriverChangeWasteType', false);
                  }}
                />
              </Col>
              <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
            </StyledRow>
          </Col>

          {!(values.templateSteps && values.templateSteps.length > 0 && values.templateSteps[0].binActivity === BinActivityEnum.LoadWaste) && <>
            <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.NewBinID}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isEditableBinNumber === true)}
                    onChange={(e) => {
                      setFieldValue('isEditableBinNumber', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(values.isEditableBinNumber === false)}
                    onChange={(e) => {
                      setFieldValue('isEditableBinNumber', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>&nbsp;</Col>
              </StyledRow>
            </Col>
          </>}
        </Row>
      </StyledStep>
    </Row>
  }

  const setFormDriverActivity = () => {
    return <Row className='g-16'>

      {
      (values.templateSteps && values.templateSteps.length > 0)
      ?
      values.templateSteps.map((item: any, i: number) => {
        let stepName: any = getStepDescription(i, item);
        let stepInfo: any = getDriverAppOptionsStepInfo(i, item, values.templateSteps);
        
        return <StyledStep key={'step_tab3_' + i} xs={12}>
          <Row className='g-12'>
            <Col xs={12}>
              <Row className='align-items-center'>
                <Col xs={12} lg={6} className='semibold gray-900 pb-1 pb-lg-0'>Step {i+1}: {stepName}</Col>
                <Col xs={true} lg={2} className='semibold gray-900 text-center'>Yes</Col>
                <Col xs={true} lg={2} className='semibold gray-900 text-center'>No</Col>
                <Col xs={true} lg={2} className='semibold gray-900 text-center'>Optional</Col>
              </Row>
            </Col>

            {stepInfo?.TakePhoto && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>
                  <Row className='align-items-center'>
                    <Col xs={'auto'} style={{ userSelect: 'none' }}>{DriverAppOptionsLabelEnum.TakePhoto}</Col>
                    <Col xs={'auto'}>
                      <Form.Group>
                        <StyledInputGroup>
                          <InputGroup.Text
                            onClick={() => {
                              if(item.mustTakePhoto !== CustomOptions.No){
                                const minNumb = ((item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) ? 1 : 0;
                              
                                if(item.numberofPhotosRequired > minNumb){
                                  setFieldValue('templateSteps.' + i + '.numberofPhotosRequired', (item.numberofPhotosRequired-1));
                                }
                              }
                            }}
                          >
                            <MinusIcon />
                          </InputGroup.Text>
                          <Form.Control
                            className='custom'
                            as={CurrencyInput}
                            allowDecimals={false}
                            allowNegativeValue={false}
                            decimalsLimit={0}
                            maxLength={2}

                            disabled={(item.mustTakePhoto === CustomOptions.No) ? true : false}
                            autoComplete='off'
                            placeholder={''}
                            value={item.numberofPhotosRequired}
                            onValueChange={(value: any) => {
                              const minNumb = ((item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) ? 1 : 0;
                              
                              if(value > minNumb){
                                setFieldValue('templateSteps.' + i + '.numberofPhotosRequired', value);
                              }
                            }}
                            isInvalid={false}
                          />
                          <InputGroup.Text
                            onClick={() => {
                              if(item.mustTakePhoto !== CustomOptions.No){
                                setFieldValue('templateSteps.' + i + '.numberofPhotosRequired', (item.numberofPhotosRequired+1));
                              }
                            }}
                          >
                            <PlusIcon />
                          </InputGroup.Text>
                        </StyledInputGroup>
                      </Form.Group>
                    </Col>
                    <Col xs={'auto'} style={{ userSelect: 'none' }}>{DriverAppOptionsLabelEnum.TakePhotoSuffix}</Col>
                  </Row>
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakePhoto === CustomOptions.Yes)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.mustTakePhoto', CustomOptions.Yes);

                      if(item.numberofPhotosRequired < 1){
                        setFieldValue('templateSteps.' + i + '.numberofPhotosRequired', 1);
                      }
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakePhoto === CustomOptions.No)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.mustTakePhoto', CustomOptions.No);
                      setFieldValue('templateSteps.' + i + '.numberofPhotosRequired', 0);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakePhoto === CustomOptions.Optional)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.mustTakePhoto', CustomOptions.Optional);
                      
                      if(item.numberofPhotosRequired < 1){
                        setFieldValue('templateSteps.' + i + '.numberofPhotosRequired', 1);
                      }
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {stepInfo?.Signature && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.Signature}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakeSignature === CustomOptions.Yes)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.mustTakeSignature', CustomOptions.Yes);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakeSignature === CustomOptions.No)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.mustTakeSignature', CustomOptions.No);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.mustTakeSignature === CustomOptions.Optional)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.mustTakeSignature', CustomOptions.Optional);
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {stepInfo?.InputBinWeight && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.InputBinWeight}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireBinWeight === true)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.isRequireBinWeight', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireBinWeight === false)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.isRequireBinWeight', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={true}
                    checked={false}
                    onChange={(e) => {
                      
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {stepInfo?.ReviewWasteType && <Col xs={12}>
              <StyledRow className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={12} lg={6} className='gray-800 pb-1 pb-lg-0'>{DriverAppOptionsLabelEnum.ReviewWasteType}</Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireReviewWasteType === true)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.isRequireReviewWasteType', true);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={false}
                    checked={(item.isRequireReviewWasteType === false)}
                    onChange={(e) => {
                      setFieldValue('templateSteps.' + i + '.isRequireReviewWasteType', false);
                    }}
                  />
                </Col>
                <Col xs={true} lg={2} className='text-center'>
                  <Radio
                    disabled={true}
                    checked={false}
                    onChange={(e) => {
                      
                    }}
                  />
                </Col>
              </StyledRow>
            </Col>}

            {!(
              stepInfo?.TakePhoto ||
              stepInfo?.Signature ||
              stepInfo?.InputBinWeight ||
              stepInfo?.ReviewWasteType
            ) &&  <Col xs={12}>No settings</Col>}
          </Row>
        </StyledStep>
      })
      :
      <Col xs={12}>No settings</Col>
      }
    </Row>
  }

  const setPreviewMobile = () => {
    return <StyledMobilePreview >
      <div className='title'><InfoIcon /> <span className='fs-16 medium gray-600'>Preview on mobile app</span></div>
      <div className='mobile-wrapper'>
        <div className='header'>
          <Row className='g-0 align-items-center'>
            <Col xs={'auto'}><ArrowLeftIcon /></Col>
            <Col xs={true} className='fs-16 medium gray-900 text-center text-uppercase text-truncate'>{values.jobTemplateName || 'JOB TYPE'}</Col>
          </Row>
        </div>
        
        <div className='caption'>
          <Row className='g-s4'>
            <Col xs={12} className='fs-16 semibold gray-900'>Customer name</Col>
            {
              isShiftType(values.templateSteps)
              ?
              <Col xs={12}>
                <Timeline
                  items={[
                    {
                      children: 'Customer Site will be here',
                      dot: <CircleIcon />
                    },
                    {
                      children: 'Customer Site will be here',
                      dot: <CircleIcon />
                    },
                  ]}
                />
              </Col>
              :
              <Col xs={12} className='gray-900'>Customer Site will be here</Col>
            }
          </Row>
        </div>

        <div className='content'>
          <Accordion defaultActiveKey={values.templateSteps.map((item: any, i: number) => 'mobile_item_' + i)} alwaysOpen>

            {values.templateSteps.map((item: any, i: number) => {
              let binActivityName = getBinActivityName(i, item);
              let locationTypeName = getLocationTypeName(i, item);
              let stepInfo: any = getDriverAppOptionsStepInfo(i, item, values.templateSteps);
              let isForWith: any = isForWithItem(item);

              let canShow = (
                stepInfo?.RequiredBinID ||
                stepInfo?.ChangeBin ||
                stepInfo?.ChangeWaste ||
                stepInfo?.InputBinWeight ||
                stepInfo?.ReviewWasteType ||
                (stepInfo?.TakePhoto && (item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) ||
                (stepInfo?.Signature && (item.mustTakeSignature === CustomOptions.Yes) || (item.mustTakeSignature === CustomOptions.Optional))
              )

              let stepName = binActivityName
              if(item?.binActivity === BinActivityEnum.NoActivity){
                stepName = (item?.stepDescription != '') ? item?.stepDescription : binActivityName;
              }

              return <Card key={'mobile_step_item_' + i}>
                <Card.Header>
                  <Row className='g-16 align-items-center'>
                    <Col xs={true}>
                      <Row className='g-8 align-items-center'>
                        <Col xs={'auto'}>
                          <CircleCheckIcon />
                        </Col>
                        <Col xs={true}>
                          <div className='semibold gray-900'>{stepName}</div>
                          <div className='fs-12 gray-900'>{locationTypeName}</div>
                        </Col>
                      </Row>
                    </Col>
                    {canShow && <Col xs={'auto'}>
                      <CustomToggle eventKey={'mobile_item_' + i} />
                    </Col>}
                  </Row>
                </Card.Header>

                {canShow && <Accordion.Collapse eventKey={'mobile_item_' + i}>
                  <Card.Body>
                    <Divider />
                    <Row className='g-12 align-items-center'>
                      {stepInfo?.RequiredBinID && <Col xs={12}>
                        <Form.Group>
                          <Form.Label>{fields.binNumberId.label}{values.isRequireBinNumber && <span className='text-danger'>*</span>}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={'text'}
                              placeholder={fields.binNumberId.placeholder}
                              readOnly={true}
                              isInvalid={false}
                            />
                            <InputGroup.Text>
                              <QRScanIcon />
                            </InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>}
                      {stepInfo?.ChangeBin && <Col xs={12}>
                        <Form.Group>
                          <Form.Label>{fields.binType.label}</Form.Label>
                          {
                            values.canDriverChangeBinType
                            ?
                            <Form.Control
                              as={Select}
                              value={''}
                              placeholder={fields.binType.placeholder}
                              notFoundContent={<div className='text-center'>{fields.binType.dropdown}</div>}

                              disabled={false}
                              searchable={false}
                              allowClear={false}
                              selectedIcon={false}
                              loading={false}
                              getPopupContainer={(trigger: any) => trigger.parentNode}
                              onChangeValue={async (value: any, option: any, ref: any) => {}}
                              options={[]}
                              isInvalid={false}
                            />
                            :
                            <Form.Control
                              type={'text'}
                              autoComplete='off'
                              placeholder={fields.binType.placeholder}
                              readOnly={true}
                              value={''}
                              onChange={async (e) => {}}
                              isInvalid={false}
                            />
                          }
                        </Form.Group>
                      </Col>}
                      {stepInfo?.ChangeWaste && <Col xs={12}>
                        <Form.Group>
                          <Form.Label>{(isForWith === ForWith.FOR) ? fields.wasteType.labelFor : fields.wasteType.labelWith}</Form.Label>
                          {
                            values.canDriverChangeWasteType
                            ?
                            <Form.Control
                              as={Select}
                              value={''}
                              placeholder={fields.wasteType.placeholder}
                              notFoundContent={<div className='text-center'>{fields.wasteType.dropdown}</div>}

                              disabled={false}
                              searchable={false}
                              allowClear={false}
                              selectedIcon={false}
                              loading={false}
                              getPopupContainer={(trigger: any) => trigger.parentNode}
                              onChangeValue={async (value: any, option: any, ref: any) => {}}
                              options={[]}
                              isInvalid={false}
                            />
                            :
                            <Form.Control
                              type={'text'}
                              autoComplete='off'
                              placeholder={fields.wasteType.placeholder}
                              readOnly={true}
                              value={''}
                              onChange={async (e) => {}}
                              isInvalid={false}
                            />
                          }
                        </Form.Group>
                      </Col>}
                      {stepInfo?.InputBinWeight && <Col xs={12}>
                        <Form.Group>
                          <Form.Label>{fields.inputWeight.label}{item.isRequireBinWeight && <span className='text-danger'>*</span>}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={'text'}
                              placeholder={fields.inputWeight.placeholder}
                              readOnly={true}
                              isInvalid={false}
                            />
                            <InputGroup.Text>{(getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>}
                      {stepInfo?.ReviewWasteType && <Col xs={12}>
                        <Form.Group>
                          <Form.Label>{fields.reviewWasteType.label}{item.isRequireReviewWasteType && <span className='text-danger'>*</span>}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type={'text'}
                              placeholder={fields.reviewWasteType.placeholder}
                              readOnly={true}
                              isInvalid={false}
                            />
                            <InputGroup.Text>{(getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'}</InputGroup.Text>
                          </InputGroup>
                        </Form.Group>
                      </Col>}
                      {(stepInfo?.TakePhoto && (item.mustTakePhoto === CustomOptions.Yes) || (item.mustTakePhoto === CustomOptions.Optional)) && <Col xs={6}>
                        <StyledPhoto>
                          <PhotoIcon />
                          <span className='ms-s3'>{fields.photo.label}</span>
                          {(item.mustTakePhoto === CustomOptions.Yes) && <span className='text-danger'>*</span>}
                        </StyledPhoto>
                      </Col>}
                      {(stepInfo?.Signature && (item.mustTakeSignature === CustomOptions.Yes) || (item.mustTakeSignature === CustomOptions.Optional)) && <Col xs={6}>
                        <StyledSignature>
                          <EditIcon />
                          <span className='ms-s3'>{fields.signature.label}</span>
                          {(item.mustTakeSignature === CustomOptions.Yes) && <span className='text-danger'>*</span>}
                        </StyledSignature>
                      </Col>}
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>}
              </Card>
            })}
            
          </Accordion>
        </div>
      </div>
    </StyledMobilePreview>
  }


  return <Row className='g-24 mb-100'>
    <Col xs={12} xl={8}>
      <Row className='g-24'>
        {((values.templateSteps && values.templateSteps.length > 0) && !(values.templateSteps.every((x: any) => x.binActivity === BinActivityEnum.NoActivity))) && <>
          <Col xs={12}>
            <Row className='g-8'>
              <Col xs={12}>
                <span className="large medium gray-900">Driver’s permission</span>
              </Col>
              <Col xs={12}>
                <span className="gray-800">Determine what driver can do or cannot do on his mobile app.</span>
              </Col>
            </Row>
          </Col>
          <Col xs={12}>
            {setFormDriverPermissons()}
          </Col>
        </>}

        <Col xs={12}>
          <Row className='g-8'>
            <Col xs={12}>
              <span className="large medium gray-900">Driver’s activities</span>
            </Col>
            <Col xs={12}>
              <span className="gray-800">Determine what driver must accomplish for each job step.</span>
            </Col>
          </Row>
        </Col>
        <Col xs={12}>
          {setFormDriverActivity()}
        </Col>
      </Row>
    </Col>

    <Col xs={12} xl={4}>
      {setPreviewMobile()}
    </Col>
  </Row>
}

export default Tab3;
