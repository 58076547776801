/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, isNumeric, removeEmptyRowsWithArrays, removeEmptyRows, convertLatLngToCoord } from '../../../utils/utils';
import Yup from '../../../utils/yup';
import { CustomerSiteAvailability } from '../../../utils/enums';


export const fields = {
  siteName: {
    id: 'siteName',
    label: 'Site Name',
    placeholder: 'Enter site name',
  },
  street: {
    id: 'street',
    label: 'Street Name',
    placeholder: 'Enter street name',
  },
  blockNo: {
    id: 'blockNo',
    label: 'Block Number',
    placeholder: 'Enter block number',
  },
  unitNo: {
    id: 'unitNo',
    label: 'Unit Number',
    placeholder: 'Enter unit number',
  },
  postalCode: {
    id: 'postalCode',
    label: 'Post Code',
    placeholder: 'Enter post code',
  },
  coordinates: {
    id: 'coordinates',
    label: 'Coordinates',
    placeholder: '-',
  },
  isActive: {
    id: 'isActive',
    label: 'Status',
    placeholder: 'Status',
  },
  zoneId: {
    id: 'zoneId',
    label: 'Group As',
    placeholder: 'Choose group',
  },
  contactPersonName: {
    id: 'contactPersonName',
    label: 'Contact 1',
    placeholder: 'Person 1',
  },
  contactPersonPhoneNumber: {
    id: 'contactPersonPhoneNumber',
    label: 'Phone 1',
    placeholder: 'Phone 1',
  },
  contactPersonNameTwo: {
    id: 'contactPersonNameTwo',
    label: 'Contact 2',
    placeholder: 'Person 2',
  },
  contactPersonPhoneNumberTwo: {
    id: 'contactPersonPhoneNumberTwo',
    label: 'Phone 2',
    placeholder: 'Phone 2',
  },
  isContactPersonSendNotification: {
    id: 'isContactPersonSendNotification',
    label: 'Notification',
    placeholder: ' ',
  },
  isContactPersonTwoSendNotification: {
    id: 'isContactPersonTwoSendNotification',
    label: 'Notification',
    placeholder: ' ',
  },
  driverId: {
    id: 'driverId',
    label: 'Default Driver(s)',
    placeholder: 'Choose driver(s)',
  },
  weekdayId: {
    id: 'weekdayId',
    label: 'Weekdays Availability',
    placeholder: 'Select option',
  },
  weekendId: {
    id: 'weekendId',
    label: 'Weekends Availability',
    placeholder: 'Select option',
  },
  remarks: {
    id: 'remarks',
    label: 'Site Remarks (Internal Note)',
    placeholder: placeholderRows(5, 'Enter customer note', ''),
    rows: 5,
  },
  instructions: {
    id: 'instructions',
    label: 'Driver Remarks (Appear On Driver App)',
    placeholder: placeholderRows(5, 'Enter customer note', ''),
    rows: 5,
  },
  copySiteRemarksToJob: {
    id: 'copySiteRemarksToJob',
    label: 'Copy Site Remarks To Job Form',
    placeholder: ' ',
  },
  defaultDriverName: {
    id: 'defaultDriverName',
    label: 'Default Driver Name',
    placeholder: ' ',
  },
  isDeactivateOrDelete: {
    id: 'isDeactivateOrDelete',
    label: 'is Deactivate Or Delete',
    placeholder: ' ',
  },
  zone: {
    id: 'zone',
    label: 'Zone',
    placeholder: ' ',
  },
  weekdaysAvailability: {
    id: 'weekdaysAvailability',
    label: 'weekdaysAvailability',
    placeholder: ' ',
  },
  weekendAvailability: {
    id: 'weekendAvailability',
    label: 'weekendAvailability',
    placeholder: ' ',
  },
  autoSendDocDisplay: {
    id: 'autoSendDocDisplay',
    label: 'autoSendDocDisplay',
    placeholder: ' ',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
    siteName: Yup.string().required().label(fields.siteName.label),
    street: Yup.string().label(fields.street.label),
    blockNo: Yup.string().label(fields.blockNo.label),
    unitNo: Yup.string().label(fields.unitNo.label),
    postalCode: Yup.string().label(fields.postalCode.label),
    coordinates: Yup.string().required().label(fields.coordinates.label),
    zoneId: Yup.number().nullable().label(fields.zoneId.label),
    remarks: Yup.string().label(fields.remarks.label),
    instructions: Yup.string().label(fields.instructions.label),
    contactPersonName: Yup.string().label(fields.contactPersonName.label),
    contactPersonPhoneNumber: Yup.string().nullable().label(fields.contactPersonPhoneNumber.label),
    contactPersonNameTwo: Yup.string().label(fields.contactPersonNameTwo.label),
    contactPersonPhoneNumberTwo: Yup.string().nullable().label(fields.contactPersonPhoneNumberTwo.label),
    isContactPersonSendNotification: Yup.bool().oneOf([true, false]),
    isContactPersonTwoSendNotification: Yup.bool().oneOf([true, false]),
    weekdayId: Yup.array().nullable().label(fields.weekdayId.label),
    weekendId: Yup.array().nullable().label(fields.weekendId.label),
    // vehicleId: Yup.array().nullable().label(fields.vehicleId.label),
    driverId: Yup.array().nullable().label(fields.driverId.label),
    copySiteRemarksToJob: Yup.bool().oneOf([true, false]).label(fields.copySiteRemarksToJob.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null, customerDetails: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let isActive = ((form.isDeactivateOrDelete == false) || (form.isDeactivateOrDelete == true)) ? form.isDeactivateOrDelete : false;
    let copySiteRemarksToJob = ((form.copySiteRemarksToJob == false) || (form.copySiteRemarksToJob == true)) ? form.copySiteRemarksToJob : false;
    let isContactPersonSendNotification = ((form.isContactPersonSendNotification === false) || (form.isContactPersonSendNotification === true)) ? form.isContactPersonSendNotification : false;
    let isContactPersonTwoSendNotification = ((form.isContactPersonTwoSendNotification === false) || (form.isContactPersonTwoSendNotification === true)) ? form.isContactPersonTwoSendNotification : false;

    let latitude = (form && form.latitude && form.latitude !== '') ? form.latitude : '';
    let longitude = (form && form.longitude && form.longitude !== '') ? form.longitude : '';
    let coordinates = convertLatLngToCoord(latitude, longitude);

    let zone = (form && form.zone) ? form.zone : null;
    let zoneId = (zone && zone.zoneId) ? zone.zoneId : null;
    let zoneName = (zone && zone.zoneName) ? zone.zoneName : '';

    let driverId: any = [];
    let driverName: any = [];
    let defaultDrivers = (form && form.defaultDrivers) ? form.defaultDrivers : [];
    if(defaultDrivers && defaultDrivers.length > 0){
      defaultDrivers.forEach((item: any, i: number) => {
        let defaultVehicle = (item && item.defaultVehicle) ? item.defaultVehicle : null;
        let vehicleName = (defaultVehicle && defaultVehicle.vehicleName) ? defaultVehicle.vehicleName : null;
        let defDriverName = [item.driverName, vehicleName].join(' / ');

        driverId.push(item.driverId);
        driverName.push(defDriverName);
      });
    }

    let weekdayId: any = [];
    let weekdayName: any = [];
    let customerSiteWeekdayJobWorkingTimes = (form && form.customerSiteWeekdayJobWorkingTimes) ? form.customerSiteWeekdayJobWorkingTimes : [];
    if(customerSiteWeekdayJobWorkingTimes && customerSiteWeekdayJobWorkingTimes.length > 0){
      customerSiteWeekdayJobWorkingTimes.forEach((item: any, i: number) => {
        let accountJobWorkingTime = (item && item.accountJobWorkingTime) ? item.accountJobWorkingTime : null;
        let accountJobWorkingTimeId = (accountJobWorkingTime && accountJobWorkingTime.accountJobWorkingTimeId) ? accountJobWorkingTime.accountJobWorkingTimeId : null;
        let workingTimeName = (accountJobWorkingTime && accountJobWorkingTime.workingTimeName) ? accountJobWorkingTime.workingTimeName : '';
        
        weekdayId.push(accountJobWorkingTimeId);
        weekdayName.push(workingTimeName);
      });
    }
    let weekdaysAvailability = (form && form.weekdaysAvailability) ? form.weekdaysAvailability : CustomerSiteAvailability.All;

    let weekendId: any = [];
    let weekendName: any = [];
    let customerSiteWeekendJobWorkingTimes = (form && form.customerSiteWeekendJobWorkingTimes) ? form.customerSiteWeekendJobWorkingTimes : [];
    if(customerSiteWeekendJobWorkingTimes && customerSiteWeekendJobWorkingTimes.length > 0){
      customerSiteWeekendJobWorkingTimes.forEach((item: any, i: number) => {
        let accountJobWorkingTime = (item && item.accountJobWorkingTime) ? item.accountJobWorkingTime : null;
        let accountJobWorkingTimeId = (accountJobWorkingTime && accountJobWorkingTime.accountJobWorkingTimeId) ? accountJobWorkingTime.accountJobWorkingTimeId : null;
        let workingTimeName = (accountJobWorkingTime && accountJobWorkingTime.workingTimeName) ? accountJobWorkingTime.workingTimeName : '';
        
        weekendId.push(accountJobWorkingTimeId);
        weekendName.push(workingTimeName);
      });
    }
    let weekendAvailability = (form && form.weekendAvailability) ? form.weekendAvailability : CustomerSiteAvailability.All;

    let customSiteName = (form.customSiteName && form.customSiteName !== '') ? form.customSiteName : '';
    let siteName = (form.siteName && form.siteName !== '') ? form.siteName : '';
    let street = (form.street && form.street !== '') ? form.street : '';
    let blockNo = (form.blockNo && form.blockNo !== '') ? form.blockNo : '';
    let unitNo = (form.unitNo && form.unitNo !== '') ? form.unitNo : '';
    let postalCode = (form.postalCode && form.postalCode !== '') ? form.postalCode : '';
    let remarks = (form.remarks && form.remarks !== '') ? form.remarks : '';
    let instructions = (form.instructions && form.instructions !== '') ? form.instructions : '';
    let contactPersonName = (form.contactPersonName && form.contactPersonName !== '') ? form.contactPersonName : '';
    let contactPersonPhoneNumber = (form.contactPersonPhoneNumber && form.contactPersonPhoneNumber !== '') ? form.contactPersonPhoneNumber : '';
    let contactPersonNameTwo = (form.contactPersonNameTwo && form.contactPersonNameTwo !== '') ? form.contactPersonNameTwo : '';
    let contactPersonPhoneNumberTwo = (form.contactPersonPhoneNumberTwo && form.contactPersonPhoneNumberTwo !== '') ? form.contactPersonPhoneNumberTwo : '';


    data['customerId'] = customerDetails?.customerId;
    data['customerName'] = customerDetails?.customerName;

    data['isActive'] = isActive;
    data['copySiteRemarksToJob'] = copySiteRemarksToJob;
    data['isContactPersonSendNotification'] = isContactPersonSendNotification;
    data['isContactPersonTwoSendNotification'] = isContactPersonTwoSendNotification;

    data['latitude'] = latitude;
    data['longitude'] = longitude;
    data['coordinates'] = coordinates;
    data['zoneId'] = zoneId;
    data['zoneName'] = zoneName;
    data['driverId'] = driverId;
    data['driverName'] = driverName;
    data['weekdayId'] = weekdayId;
    data['weekdayName'] = weekdayName;
    data['weekendId'] = weekendId;
    data['weekendName'] = weekendName;
    data['weekdaysAvailability'] = weekdaysAvailability;
    data['weekendAvailability'] = weekendAvailability;

    data['customSiteName'] = customSiteName;
    data['siteName'] = siteName;
    data['street'] = street;
    data['blockNo'] = blockNo;
    data['unitNo'] = unitNo;
    data['postalCode'] = postalCode;
    data['remarks'] = remarks;
    data['instructions'] = instructions;
    data['contactPersonName'] = contactPersonName;
    data['contactPersonPhoneNumber'] = contactPersonPhoneNumber;
    data['contactPersonNameTwo'] = contactPersonNameTwo;
    data['contactPersonPhoneNumberTwo'] = contactPersonPhoneNumberTwo;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null, customerDetails: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(id){
      data['customerSiteId'] = id;
    }
    data['customerId'] = customerDetails?.customerId;
    
    let driverIds = (form && form.driverId && form.driverId.length > 0) ? form.driverId.join(',') : '';
    let accountWeekdayJobWorkingTimeIds = (form && form.weekdayId && form.weekdayId.length > 0) ? form.weekdayId.join(',') : '';
    let accountWeekendJobWorkingTimeIds = (form && form.weekendId && form.weekendId.length > 0) ? form.weekendId.join(',') : '';

    let weekdaysAvailability = (form && form.weekdaysAvailability) ? form.weekdaysAvailability : CustomerSiteAvailability.All;
    let weekendAvailability = (form && form.weekendAvailability) ? form.weekendAvailability : CustomerSiteAvailability.All;

    let latitude = form.latitude;
    let longitude = form.longitude;
    let coordinates = (form && form.coordinates && form.coordinates != '') ? form.coordinates.split(',') : [];
    latitude = (coordinates && coordinates.length > 0 && coordinates[0]) ? coordinates[0] : form.latitude;
    longitude = (coordinates && coordinates.length > 0 && coordinates[1]) ? coordinates[1] : form.longitude;


    data['isDeactivateOrDelete'] = form.isActive;
    data['copySiteRemarksToJob'] = form.copySiteRemarksToJob;
    data['isContactPersonSendNotification'] = form.isContactPersonSendNotification;
    data['isContactPersonTwoSendNotification'] = form.isContactPersonTwoSendNotification;

    data['latitude'] = latitude;
    data['longitude'] = longitude;

    data['customSiteName'] = form.customSiteName;
    data['siteName'] = form.siteName;
    data['street'] = form.street;
    data['blockNo'] = form.blockNo;
    data['unitNo'] = form.unitNo;
    data['postalCode'] = form.postalCode;
    data['remarks'] = form.remarks;
    data['instructions'] = form.instructions;
    data['contactPersonName'] = form.contactPersonName;
    data['contactPersonPhoneNumber'] = form.contactPersonPhoneNumber;
    data['contactPersonNameTwo'] = form.contactPersonNameTwo;
    data['contactPersonPhoneNumberTwo'] = form.contactPersonPhoneNumberTwo;

    data['driverIds'] = driverIds;
    data['accountWeekdayJobWorkingTimeIds'] = (weekdaysAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekdayJobWorkingTimeIds : (weekdaysAvailability === CustomerSiteAvailability.All) ? '' : null;
    data['accountWeekendJobWorkingTimeIds'] = (weekendAvailability === CustomerSiteAvailability.SelectedHours) ? accountWeekendJobWorkingTimeIds : (weekendAvailability === CustomerSiteAvailability.All) ? '' : null;
    data['weekdaysAvailability'] = weekdaysAvailability;
    data['weekendAvailability'] = weekendAvailability;

    if(form.zoneId !== 0){
      data['zoneId'] = form.zoneId;
    }
  }

  return data;
};


export interface initialValuesStruct {
  isActive: boolean,
  isContactPersonSendNotification: boolean,
  isContactPersonTwoSendNotification: boolean,
  copySiteRemarksToJob: boolean,

  customSiteName: string,
  siteName: string,
  street: string,
  blockNo: string,
  unitNo: string,
  postalCode: string,
  latitude: string,
  longitude: string,
  coordinates: string,
  remarks: string,
  instructions: string,
  contactPersonName: string,
  contactPersonPhoneNumber: string,
  contactPersonNameTwo: string,
  contactPersonPhoneNumberTwo: string,
  
  zoneId: any,
  zoneName: string,

  driverId: Array<any>,
  driverName: Array<any>,
  
  weekdayId: Array<any>,
  weekdayName: Array<any>,

  weekendId: Array<any>,
  weekendName: Array<any>,

  weekdaysAvailability: any,
  weekendAvailability: any,
};
export const initialValues: initialValuesStruct = {
  isActive: true, 
  isContactPersonSendNotification: false, 
  isContactPersonTwoSendNotification: false, 
  copySiteRemarksToJob: false, 

  customSiteName: '',
  siteName: '',
  street: '',
  blockNo: '',
  unitNo: '',
  postalCode: '',
  latitude: '',
  longitude: '',
  coordinates: '',
  remarks: '',
  instructions: '',
  contactPersonName: '',
  contactPersonPhoneNumber: '',
  contactPersonNameTwo: '',
  contactPersonPhoneNumberTwo: '',
  
  zoneId: null,
  zoneName: '',

  driverId: [],
  driverName: [],
  
  weekdayId: [],
  weekdayName: [],

  weekendId: [],
  weekendName: [],

  weekdaysAvailability: CustomerSiteAvailability.All,
  weekendAvailability: CustomerSiteAvailability.All,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  id: any|null,
  details: any,

  isLoadingCreateUpdateDelete: boolean,
}

function NewReducer() {
  const name = 'customersSiteSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    id: null,
    details: initialValues,

    isLoadingCreateUpdateDelete: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setId: (state: InitState, action: PayloadAction<any>) => {
      state.id = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, id: number|null}>) => {
      state.id = action.payload.id;
      state.show = action.payload.show;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.customerSite = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.customerSite = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('customerSite', { params: params }).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('customerSite/' + id).then(result => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('customerSite', params).then(result => {
            let data = result.data;
            
            // successAPI(data);
            // success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('customerSite', params).then(result => {
            let data = result.data;
            
            // successAPI(data);
            // success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('customerSite', { data: params }).then(result => {
            let data = result.data;
                
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callActivateApi: (isActive: boolean, ids: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startActivate());

        let params = {
          isActive: isActive,
          ids: ids,
        };

        await axios.put('customerSite/deactivate', params).then(result => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishActivate(obj));
        }).catch(error => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishActivate(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();