import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Checkbox, Divider, Alert } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, checkStatus, getId, isEdit, isCopy } from '../slice.tsx';
import { canWeightChit, isDisabledWeightChit } from '../../manage/jobs/jobTypes/slice.tsx';
import confirmSlice from '../../../components/modal/confirmSlice.tsx';
import printPreviewSlice from './printPreview/slice.tsx';
import recurringJobSlice from './recurringJob/slice.tsx';
import billingJobSlice from './billingJob/slice.tsx';
import weightChitSlice from './weightChit/slice.tsx';

// enums
import { hasPermission } from '../../../../v5/utils/utils.tsx';
import { Permissions } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

import FooterActionButtons from './FooterActionButtons.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../../v5/assets/icons/alert-triangle.svg";

// ----------------------------------------------------------------------

const StyledDivider: React.FC<React.ComponentProps<typeof Divider>> = styled(Divider)`
  margin: 0px 0px 0px 7px;
  background-color: var(--bs-gray-800);
`;


const Footer = (props: any) => {
  // const location = useLocation();
  const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isCreditLimit } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const confirmSliceState = useTypedSelector((state: RootState) => state.confirmSlice);
  const dispatch = useDispatch<AppDispatch>();

  const inputRemarksRef = React.useRef<any>(null);
  // const inputRef = React.useRef<any>(null);


  return <>
    <Row className='align-items-center justify-content-center g-8'>
      <Col xs={true} className='d-none d-md-block'></Col>
      {checkStatus(values?.statusName, ['completed']) && <Col xs={12} md={'auto'} className='text-center text-sm-end'>
        <Checkbox
          checked={values?.isSendEmail}
          onChange={async (e: any) => {
            await setFieldValue('isSendEmail', e.target.checked);
          }}
        >Do you want to resend DO to customer?</Checkbox>
      </Col>}

      <Col xs={true} className='d-none d-sm-block d-md-none'></Col>
      <Col xs={'auto'}>
        <DropdownButton
          variant={'custom-outlined'}
          size={'sm'}
          title={<>Options <StyledDivider type='vertical' /></>}
          disabled={!(values.customerId && values.jobTemplateId)}
        >
          {(hasPermission(Permissions.JobProofOfDelivery)) && <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(printPreviewSlice.setShow({ show: true, form: values }));
            }}
          >Print Preview</Dropdown.Item>}

          {/* <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(recurringJobSlice.setShow(true));
            }}
          >Make Recurring</Dropdown.Item> */}

          {(hasPermission(Permissions.JobBilling)) && <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(billingJobSlice.setShow({ show: true, form: values }));
            }}
          >Bill Job</Dropdown.Item>}
          
          {(canWeightChit(values?.stepGroups) && !isDisabledWeightChit(values?.stepGroups)) && <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(weightChitSlice.setShow({ show: true, details: values }));
            }}
          >Weight Chit</Dropdown.Item>}

          {(isEdit() && !isCopy()) && <Dropdown.Item
            active={false}
            disabled={false}
            onClick={() => {
              window.location.href = '/v5/job-form-beta?id=' + getId() + '&isCopy=true';
            }}
          >Copy Job</Dropdown.Item>}

          {(isEdit() && !isCopy()) && <Dropdown.Item
            className={'text-danger'}
            active={false}
            disabled={false}
            onClick={() => {
              dispatch(confirmSlice.show(null, {
                title: 'Are you sure you want to remove this job?',
                body: <>
                  {(checkStatus(values?.statusName, ['completed'])) && <>
                    <Alert
                      className='mb-3'
                      message={'Once you choose to delete a completed job, the details of the job will be removed from the maps and reports.'}
                      description=""
                      type="warning"
                      showIcon
                      icon={<AlertTriangleIcon />}
                    />
                  </>}

                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      className='textarea-noresize'
                      ref={inputRemarksRef}
                      autoFocus
                      rows={5}
                      autoComplete='off'
                      placeholder={'Enter remarks'}
                      disabled={false}
                    />
                    <Form.Control.Feedback type="invalid">You must enter remarks</Form.Control.Feedback>
                  </Form.Group>
                </>,
                size: 'md',
                cancelText: 'Cancel',
                cancelVariant: 'custom-outlined',
                cancelCallback: () => {},
                confirmText: 'Yes, please remove',
                confirmVariant: 'custom-danger',
                confirmCallback: async () => {
                  if(inputRemarksRef && inputRemarksRef.current){
                    if(inputRemarksRef.current.value !== ''){
                      inputRemarksRef.current.classList.remove("is-invalid");
                      let val = inputRemarksRef.current.value;

                      let params: any = {
                        jobId: getId(),
                        remarks: val,
                      };

                      dispatch(slice.callDeleteApi(params, (state: boolean, data: any) => {
                        if(state){
                          dispatch(confirmSlice.setHide());
                          router.push('/jobs');
                        }
                      }));
                    } else {
                      inputRemarksRef.current.classList.add("is-invalid");
                    }
                  }
                },
              }));
            }}
          >Delete Job</Dropdown.Item>}
        </DropdownButton>
      </Col>

      {!isCreditLimit && <Col xs={'auto'}>
        <FooterActionButtons 
          onSave={(eventKey: any) => {
            // dispatch(confirmSlice.show(() => {
            //   dispatch(confirmSlice.setHide());

            //   dispatch(confirmSlice.show(null, {
            //     title: 'Save as New Template',
            //     body: <>
            //       <Form.Group>
            //         <Form.Control
            //           ref={inputRef}
            //           type={'text'}
            //           autoComplete='off'
            //           autoFocus
            //           placeholder={'Enter the template name'}
            //           disabled={false}
            //         />
            //         <Form.Control.Feedback type="invalid">You must enter a template name</Form.Control.Feedback>
            //       </Form.Group>
            //     </>,
            //     size: 'md',
            //     cancelText: 'Cancel',
            //     cancelVariant: 'custom-outlined',
            //     cancelCallback: () => {
            //       // ("Cancel BUT OK");
            //     },
            //     confirmText: 'Confirm Save',
            //     confirmVariant: 'custom-primary',
            //     confirmCallback: () => {
            //       if(inputRef && inputRef.current){
            //         if(inputRef.current.value !== ''){
            //           inputRef.current.classList.remove("is-invalid");
            //           // ("Confirm BUT OK", inputRef.current.value);
            //           dispatch(confirmSlice.setHide());
            //         } else {
            //           inputRef.current.classList.add("is-invalid");
            //         }
            //       }
            //     },
            //   }));
            // }, {
            //   title: 'Custom Job Detected',
            //   body: 'Do you want save it as a new Template?',
            //   size: 'md',
            //   cancelText: 'No, thanks',
            //   cancelVariant: 'custom-outlined',
            //   cancelCallback: () => {
            //     // ("Cancel BUT OK");
            //   },
            //   confirmText: 'Save Template',
            //   confirmVariant: 'custom-primary',
            // }));

            if(props.onSave){
              props.onSave(eventKey);
            }
          }}
          onSaveCopyDraft={(eventKey: any) => {
            if(props.onSaveCopyDraft){
              props.onSaveCopyDraft(eventKey);
            }
          }}
        />
      </Col>}
    </Row>
  </>
}

export default Footer;
