import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Radio, Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../utils/store.tsx';
import slice, { useFormikContext, fields, JobTemplateType, LocationEnum, BinActivityEnum, getVehicleBinStatus, getPrevStep, getNextStep, isMultiStep, canRenderStep, StepRender, disableBinActivityes, initStep, getBinActivityDropdown, getLocationTypeDropdown, PickDropEmptyTitle, PickDropLoadedTitle, DropWasteDropEmptyTitle, PickWasteDropWasteTitle, getLocationName, getLocationValue, DefaultLocationEnum, isShiftType } from '../slice.tsx';
import confirmSlice from '../../../../../components/modal/confirmSlice.tsx';
import defaultLocationFormSlice from './defaultLocation/slice.tsx';

// enums
import { success, warning, scrollTo, limitRows, getMaxPageSize } from '../../../../../utils/utils.tsx';
// import {  } from '../../../../../utils/enums.tsx';

// components
// import MainContainer from '../../../../../components/container/MainContainer.jsx';
// import SmartAutocomplete from '../../../../../components/autocomplete/SmartAutocomplete.tsx';
import Select from '../../../../../components/select/Select.tsx';
import SmartSelect from '../../../../../components/select/SmartSelect.tsx';

import { ReactComponent as PlusIcon } from "../../../../../../v5/assets/icons/plus.svg";
import { ReactComponent as TrashIcon } from "../../../../../../v5/assets/icons/trash-2.svg";
import { ReactComponent as InfoIcon } from "../../../../../../v5/assets/icons/i.svg";

// ----------------------------------------------------------------------

const StyledStep = styled(Col)`
  border-radius: 6px;
  background-color: var(--bs-blue-focus1);
  padding-top: 8px;
  padding-bottom: 8px;

  > div {
    padding: 16px 24px;

    > div {
      .ant-divider {
        margin-top: 4px;
        margin-bottom: 4px;
        border-color: var(--bs-gray-100);
      }
    }
  }
`;
export const StyledStepStatus = styled(Col)`
  border-radius: 6px;
  padding: 16px 24px;
  background-color: var(--bs-green-focus2);

  
  .ant-divider {
    margin-left: 0px;
    margin-right: 0px;
    border-color: var(--bs-gray-100);
  }
`;
export const StyledInfoIcon = styled(InfoIcon)`
  position: relative;
  top: -1px;
  width: 18px;
  height: 19px;
  margin-right: 5px;

  path {
    stroke: var(--bs-gray-600);
  }
`;
export const StyledVehicleBinStatus = styled.span`
  color: var(--bs-gray-600);

  &.Loaded {
    color: var(--bs-yellow-500);
  }
  
  &.Empty {
    color: var(--bs-green-500);
  }
`;


const StepElement = ({ i, item, isMulti, showTitle, isMain, orderBlok, isOldJobTemplate }: any) => {
  const { isLoadingDefaultLocations, defaultLocations } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  const dispatch = useDispatch<AppDispatch>();

  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  let disableBinActivity: any = disableBinActivityes(i, item, values.templateSteps);
  let prevStep: any = getPrevStep(i, item, values.templateSteps);

  let showSelected = ((isMulti && isMain) || (!isMulti && isMain));
  let showDelete = ((i > 0) && (item.binActivity != null) && ((isMulti && !isMain) || (!isMulti && isMain)));
  let isDisabled = (isMulti && isMain);
  let showFields = ((item.binActivity != null) && ((isMulti && isMain) || (!isMulti && isMain)));
  
  let isCheckedEmpty = (isMulti && (item?.binActivity === BinActivityEnum.PickEmptyBin || item?.binActivity === BinActivityEnum.DropEmptyBin));
  let isCheckedLoaded = (isMulti && (item?.binActivity === BinActivityEnum.PickLoadedBin || item?.binActivity === BinActivityEnum.DropLoadedBin));
  let isCheckedDisposal = (isMulti && (item?.binActivity === BinActivityEnum.WasteDisposal));
  let isCheckedTipper = (isMulti && (item?.binActivity === BinActivityEnum.LoadWaste));
  
  let isDisabledEmpty = ((i > 0) && (values.templateSteps[0]?.binActivity === BinActivityEnum.LoadWaste)) ? true : false;
  let isDisabledLoaded = ((i > 0) && (values.templateSteps[0]?.binActivity === BinActivityEnum.LoadWaste)) ? true : false;
  let isDisabledDisposal = ((i > 0) && (values.templateSteps[0]?.binActivity === BinActivityEnum.LoadWaste)) ? true : false;
  let isDisabledTipper = ((i > 0) && (values.templateSteps[0]?.binActivity === BinActivityEnum.LoadWaste)) ? true : false;

  let isNotDisabledPickDropEmpty = (!(prevStep?.step?.binActivity === BinActivityEnum.PickEmptyBin) && !(prevStep?.step?.binActivity === BinActivityEnum.PickLoadedBin) && !(prevStep?.step?.binActivity === BinActivityEnum.LoadWaste));
  let isNotDisabledPickDropLoaded = (!(prevStep?.step?.binActivity === BinActivityEnum.PickLoadedBin) && !(prevStep?.step?.binActivity === BinActivityEnum.PickEmptyBin) && !(prevStep?.step?.binActivity === BinActivityEnum.LoadWaste));

  let selectedSelectField = showSelected ? item.binActivity : isCheckedEmpty ? PickDropEmptyTitle : isCheckedLoaded ? PickDropLoadedTitle : isCheckedDisposal ? DropWasteDropEmptyTitle : isCheckedTipper ? PickWasteDropWasteTitle : null;


  React.useEffect(() => {
    if (isMain) {
      setFieldValue('templateSteps.' + i + '.orderBlok', orderBlok);
    }
  }, [isMain, orderBlok]);


  return <>
    <Row className={'g-16 py-8'}>
      <Col xs={{ span: true, order: 1 }} md={{ span: 2, order: 1 }} lg={{ span: 1, order: 1 }} className='semibold blue-500'>{showTitle && <>Step {(i+1)}</>}</Col>

      <Col xs={{ span: 12, order: 3 }} md={{ span: 4, order: 2 }}>
        <Form.Group>
          <Form.Label>{fields.binActivity.label}</Form.Label>
          <Form.Control
            as={Select}
            value={selectedSelectField}
            placeholder={fields.binActivity.placeholder}
            size='sm'

            disabled={isDisabled || isOldJobTemplate}
            searchable={false}
            allowClear={false}
            selectedIcon={false}
            loading={false}
            getPopupContainer={(trigger: any) => trigger.parentNode}
            onChangeValue={async (value: any, option: any, ref: any) => {
              let items = _.cloneDeep(values.templateSteps);
              items.forEach((itm: any, ind: number) => {
                if(ind > i){
                  itm.binActivity = null;
                } else if (ind === i){
                  itm.binActivity = (option) ? value : null;
                }
              });

              // if((value == BinActivityEnum.PickEmptyBin) || (value == BinActivityEnum.PickLoadedBin)){
              //   let newItem = _.cloneDeep(initStep);
              //   newItem.templateStepSites = defaultLocations;
              //   newItem.binActivity = BinActivityEnum.NoActivity;
              //   items.push(newItem);
              // }
              
              await setFieldValue('templateSteps', items.filter((x: any) => x.binActivity != null));
            }}
            options={getBinActivityDropdown((obj: any) => {
              return disableBinActivity?.[obj?.name];
            }, isDisabled, isOldJobTemplate)}
            customHeader={(isLoading: any, ref: any) => {
              if((i > 0) && isShiftType(values.templateSteps)){
                return null
              } else {
              return <>
                {((i === 0) || isNotDisabledPickDropEmpty) && <div className='ant-select-item'
                  onClick={() => {
                    if(!isCheckedEmpty && !isDisabledEmpty){
                      let items = _.cloneDeep(values.templateSteps);

                      items.forEach((itm: any, ind: number) => {
                        if(ind > i){
                          itm.binActivity = null;
                        }
                      });

                      items[i].binActivity = BinActivityEnum.PickEmptyBin;
                      items[i].locationType = LocationEnum.Yard;

                      let newItem = _.cloneDeep(initStep);
                      newItem.templateStepSites = defaultLocations;
                      newItem.binActivity = BinActivityEnum.DropEmptyBin;
                      newItem.locationType = LocationEnum.CustomerSite;
                      items.push(newItem);

                      setFieldValue('templateSteps', items.filter((x: any) => x.binActivity != null));
                      scrollTo('.step-' + (items.length-1));
                    }
                  }}
                >
                  <Radio
                    disabled={isDisabledEmpty}
                    checked={isCheckedEmpty}
                  >{PickDropEmptyTitle}</Radio>
                </div>}
                {((i === 0) || isNotDisabledPickDropLoaded) && <div className='ant-select-item'
                  onClick={() => {
                    if(!isCheckedLoaded && !isDisabledLoaded){
                      let items = _.cloneDeep(values.templateSteps);

                      items.forEach((itm: any, ind: number) => {
                        if(ind > i){
                          itm.binActivity = null;
                        }
                      });

                      items[i].binActivity = BinActivityEnum.PickLoadedBin;
                      items[i].locationType = LocationEnum.CustomerSite;

                      let newItem = _.cloneDeep(initStep);
                      newItem.templateStepSites = defaultLocations;
                      newItem.binActivity = BinActivityEnum.DropLoadedBin;
                      newItem.locationType = LocationEnum.Yard;
                      items.push(newItem);

                      setFieldValue('templateSteps', items.filter((x: any) => x.binActivity != null));
                      scrollTo('.step-' + (items.length-1));
                    }
                  }}
                >
                  <Radio
                    disabled={isDisabledLoaded}
                    checked={isCheckedLoaded}
                  >{PickDropLoadedTitle}</Radio>
                </div>}
                {(prevStep?.step?.binActivity === BinActivityEnum.PickLoadedBin) && <div className='ant-select-item'
                  onClick={() => {
                    if(!isCheckedDisposal && !isDisabledDisposal){
                      let items = _.cloneDeep(values.templateSteps);

                      items.forEach((itm: any, ind: number) => {
                        if(ind > i){
                          itm.binActivity = null;
                        }
                      });

                      items[i].binActivity = BinActivityEnum.WasteDisposal;
                      items[i].locationType = LocationEnum.CustomerSite;

                      let newItem = _.cloneDeep(initStep);
                      newItem.templateStepSites = defaultLocations;
                      newItem.binActivity = BinActivityEnum.DropEmptyBin;
                      newItem.locationType = LocationEnum.Yard;
                      items.push(newItem);

                      setFieldValue('templateSteps', items.filter((x: any) => x.binActivity != null));
                      scrollTo('.step-' + (items.length-1));
                    }
                  }}
                >
                  <Radio
                    disabled={isDisabledDisposal}
                    checked={isCheckedDisposal}
                  >{DropWasteDropEmptyTitle}</Radio>
                </div>}
                {(i === 0) && <div className='ant-select-item'
                  onClick={() => {
                    if(!isCheckedTipper && !isDisabledTipper){
                      let items = _.cloneDeep(values.templateSteps);

                      items.forEach((itm: any, ind: number) => {
                        if(ind > i){
                          itm.binActivity = null;
                        }
                      });

                      items[i].binActivity = BinActivityEnum.LoadWaste;
                      items[i].locationType = LocationEnum.CustomerSite;

                      let newItem = _.cloneDeep(initStep);
                      newItem.templateStepSites = defaultLocations;
                      newItem.binActivity = BinActivityEnum.WasteDisposal;
                      newItem.locationType = LocationEnum.Yard;
                      items.push(newItem);

                      setFieldValue('templateSteps', items.filter((x: any) => x.binActivity != null));
                      scrollTo('.step-' + (items.length-1));
                    }
                  }}
                >
                  <Radio
                    disabled={isDisabledTipper}
                    checked={isCheckedTipper}
                  >{PickWasteDropWasteTitle}</Radio>
                </div>}
              </>
              }
            }}
            optionRender={(option: any, ref: any) => {
              return <Radio
                disabled={false}
                checked={showSelected ? option.value === item?.binActivity : false}
              >{option.label}</Radio>
            }}
            isInvalid={!!(errors && errors.templateSteps && errors.templateSteps.length > 0 && errors.templateSteps[i] && typeof errors.templateSteps[i] === 'object' && errors.templateSteps[i].binActivity)}
          />
          <Form.Control.Feedback type="invalid">{(errors && errors.templateSteps && errors.templateSteps[i] && errors.templateSteps[i].binActivity) && errors.templateSteps[i].binActivity}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      
      {showFields && <Col xs={{ span: 12, order: 4 }} md={{ span: true, order: 3 }}>
        <Form.Group>
          <Form.Label>{fields.defaultLocationId.label}</Form.Label>
          <Row className='g-0'>
            <Col xs={12} sm={4}>
              <Form.Control
                as={Select}
                className='rounded-0 rounded-start'
                size='sm'
                value={getLocationValue(item.locationType, item.hasDefaultSite)}
                placeholder={fields.defaultLocationId.placeholder}
                searchable={false}
                allowClear={false}
                selectedIcon={false}
                loading={false}
                disabled={isOldJobTemplate}
                onChangeValue={(value: any, option: any, ref: any) => {
                  if(value == DefaultLocationEnum.CustomerSiteLocation){
                    setFieldValue('templateSteps.' + i + '.locationType', LocationEnum.CustomerSite);
                    setFieldValue('templateSteps.' + i + '.hasDefaultSite', false);

                  } else if(value == DefaultLocationEnum.DefaultLocation){
                    setFieldValue('templateSteps.' + i + '.locationType', LocationEnum.Yard);
                    setFieldValue('templateSteps.' + i + '.hasDefaultSite', true);

                  } else if(value == DefaultLocationEnum.NoDefaultLocation){
                    setFieldValue('templateSteps.' + i + '.locationType', LocationEnum.Yard);
                    setFieldValue('templateSteps.' + i + '.hasDefaultSite', false);
                  }
                }}
                options={getLocationTypeDropdown(i, item, values.templateSteps, isOldJobTemplate)}

                optionRender={(option: any, ref: any) => {
                  return <Radio
                    disabled={false}
                    checked={(option.value === getLocationValue(item.locationType, item.hasDefaultSite))}
                  >{option.label}</Radio>
                }}
                isInvalid={false}
              />
            </Col>
            <Col xs={12} sm={8}>
              {
                (
                  (getLocationValue(item.locationType, item.hasDefaultSite) === DefaultLocationEnum.CustomerSiteLocation) ||
                  (getLocationValue(item.locationType, item.hasDefaultSite) === DefaultLocationEnum.NoDefaultLocation)
                )
                ?
                <Form.Control
                  className='rounded-0 rounded-end'
                  size='sm'
                  autoComplete='off'
                  placeholder={'Specific' + (getLocationValue(item.locationType, item.hasDefaultSite) === DefaultLocationEnum.CustomerSiteLocation ? ' customer ' : ' ') + 'location will be entered when creating a job.'}
                  readOnly={true}
                  disabled={isOldJobTemplate}
                />
                :
                <>
                  <Form.Control
                    as={SmartSelect}
                    isInvalid={!!(errors && errors.templateSteps && errors.templateSteps.length > 0 && errors.templateSteps[i] && typeof errors.templateSteps[i] === 'object' && errors.templateSteps[i].defaultLocationId)}
                    value={item.defaultLocationId}
                    placeholder={fields.defaultLocationId.placeholder}

                    className='rounded-0 rounded-end'
                    size='sm'
                    getPopupContainer={(trigger: any) => trigger.parentNode}

                    addText={'Add new location'}
                    apiPath={'default-location'}
                    customParams={{
                      currentPage: 1,
                      pageSize: getMaxPageSize(),
                      sortColumn: 'locationName',
                      sortDir: 'asc',
                    }}
                    fieldNames={{ value: 'defaultLocationId', label: 'locationName' }}
                    selected={{ defaultLocationId: item.defaultLocationId, locationName: item?.defaultLocation?.locationName }}
                    footerRender={(isLoading: boolean, ref: any) => {
                      return <Button
                        variant={'custom-text'}
                        size={'sm'}
                        disabled={isLoading}
                        onClick={(e) => { 
                          e.preventDefault();
                          e.stopPropagation();

                          dispatch(defaultLocationFormSlice.setShow({ show: true, index: i, id: null }));
                        }}
                      >Add More Location</Button>
                    }}
                    optionRender={(option: any, ref: any) => {
                      return <Row className={'g-0'}>
                        <Col xs={'auto'}>
                          <Radio
                            disabled={false}
                            checked={(option.value === item.defaultLocationId)}
                          />
                        </Col>
                        <Col xs={true}>{option.label}</Col>
                        <Col xs={'auto'}>
                          <a href={'/'} 
                            className='medium hover blue-500 px-10 py-s3'
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              
                              dispatch(defaultLocationFormSlice.setShow({ show: true, index: i, id: option.value }));
                            }}
                          >Edit</a>
                        </Col>
                      </Row>
                    }}

                    hasOptionRender={true}
                    hasFooterRender={true}
                    searchable={false}
                    allowClear={false}
                    selectedIcon={false}
                    disabled={false}
                    virtual={true}

                    onChangeValue={async (value: any, option: any, ref: any) => {
                      if(option){
                        await setFieldValue('templateSteps.' + i + '.defaultLocationId', value);
                        await setFieldValue('templateSteps.' + i + '.defaultLocation', option);
                      } else {
                        await setFieldValue('templateSteps.' + i + '.defaultLocationId', null);
                        await setFieldValue('templateSteps.' + i + '.defaultLocation', null);
                      }
                    }}
                  />
                  <Form.Control.Feedback type="invalid">{(errors && errors.templateSteps && errors.templateSteps[i] && errors.templateSteps[i].defaultLocationId) && errors.templateSteps[i].defaultLocationId}</Form.Control.Feedback>
                </>
              }
            </Col>
          </Row>
        </Form.Group>
      </Col>}

      {showDelete && <Col xs={{ span: 'auto', order: 2 }} md={{ span: 'auto', order: 4 }}>
        <Form.Group>
          <Form.Label className='d-none d-md-block'>&nbsp;</Form.Label>
          <Button
            className={'px-s4'}
            size={'sm'}
            variant={'custom-secondary-danger'}
            disabled={isOldJobTemplate}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              dispatch(confirmSlice.show(() => {
                let items = _.cloneDeep(values.templateSteps);
                items.forEach((itm: any, ind: number) => {
                  if(ind > i){
                    itm.binActivity = null;
                  }
                });
                items.splice(i, 1);
                setFieldValue('templateSteps', items.filter((x: any) => x.binActivity != null));
                
                dispatch(confirmSlice.setHide());
                // success('Deleted successfully', 'Step has been successfully deleted.');
              }, {
                title: 'Delete Step',
                body: 'Are you sure to delete this step? This cannot be undone',
                size: 'sm',
                cancelText: 'Cancel',
                cancelVariant: 'custom-outline',
                confirmText: 'Delete',
                confirmVariant: 'custom-danger',
              }));
            }}
          >
            <TrashIcon />
          </Button>
        </Form.Group>
      </Col>}
    </Row>

    {(item?.binActivity === BinActivityEnum.NoActivity) && <Row className={'g-16 py-8'}>
      <Col xs={12} md={2} lg={1} className='semibold blue-500 d-none d-md-block'>&nbsp;</Col>

      <Col xs={12} md={true}>
        <Form.Group>
          <Form.Label>
            {fields.stepDescription.label}
            <span className='text-danger'>*</span>
            <i className='ms-8 fs-12 regular gray-600'>(max {fields.stepDescription.maxLength} characters)</i>
          </Form.Label>
          <Form.Control
            type="text"
            autoComplete='off'
            size='sm'
            placeholder={fields.stepDescription.placeholder}
            maxLength={fields.stepDescription.maxLength}
            disabled={false}
            value={item.stepDescription}
            onChange={async (e) => {
              await setFieldValue('templateSteps.' + i + '.stepDescription', e.target.value);
            }}
            isInvalid={!!(errors && errors.templateSteps && errors.templateSteps.length > 0 && errors.templateSteps[i] && typeof errors.templateSteps[i] === 'object' && errors.templateSteps[i].stepDescription)}
          />
          <Form.Control.Feedback type="invalid">{(errors && errors.templateSteps && errors.templateSteps[i] && errors.templateSteps[i].stepDescription) && errors.templateSteps[i].stepDescription}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>}
  </>
}


const Tab2 = ({ formikRef }: any) => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { defaultLocations } = useTypedSelector((state: RootState) => state.manageJobTypesSlice);
  // const dispatch = useDispatch<AppDispatch>();

  
  let isOldJobTemplate = ((values.jobTemplateType === JobTemplateType.Default) || (values.templateSteps.some((x: any) => x.stepType != 8)));


  const setFormElements = () => {
    let orderBlok = 0;

    return <Row className='g-16'>

      <Col xs={12}>
        <Row className='g-16'>
          {values.templateSteps.map((item: any, i: number) => {
            let vehicleBinStatus: any = getVehicleBinStatus(i, item, values.templateSteps);
            let prevStep: any = getPrevStep(i, item, values.templateSteps);
            let nextStep: any = getNextStep(i, item, values.templateSteps);
            let { isMulti, canRender }: any = isMultiStep(prevStep, { index: 1, step: item }, nextStep);
            let renderStep: any = canRenderStep(isMulti, canRender);

            let stepItemEl = [];
            if(renderStep === StepRender.None){
              return null;

            } else if(renderStep === StepRender.Multi){
              orderBlok = orderBlok+1;

              stepItemEl.push(<React.Fragment key={'step_tab2_item_multi_' + i}>
                <StepElement i={prevStep?.index} item={prevStep?.step} isMulti={isMulti} showTitle={true} isMain={false} orderBlok={null} isOldJobTemplate={isOldJobTemplate} />
                <StepElement i={prevStep?.index} item={prevStep?.step} isMulti={isMulti} showTitle={false} isMain={true} orderBlok={orderBlok} isOldJobTemplate={isOldJobTemplate} />
                <Row className='pt-8 pb-s4'>
                  <Col xs={12}><Divider /></Col>
                </Row>
                <StepElement i={i} item={item} isMulti={isMulti} showTitle={true} isMain={true} orderBlok={orderBlok} isOldJobTemplate={isOldJobTemplate} />
              </React.Fragment>);

            } else if(renderStep === StepRender.Single){
              orderBlok = orderBlok+1;

              stepItemEl.push(<React.Fragment key={'step_tab2_item_single_' + i}>
                <StepElement i={i} item={item} isMulti={isMulti} showTitle={true} isMain={true} orderBlok={orderBlok} isOldJobTemplate={isOldJobTemplate} />
              </React.Fragment>);
            }

            return <React.Fragment key={'step_tab2_' + i}>
              <StyledStep xs={12} className={'step-' + i}>{stepItemEl}</StyledStep>
              {vehicleBinStatus && <StyledStepStatus xs={12}>
                <Row className={'g-16 px-8'}>
                  <Col xs={true} md={2} lg={1} className='d-none d-md-block'>&nbsp;</Col>
                  <Col xs={true}>
                    <Row className='g-16'>
                      {vehicleBinStatus?.vehicleStatus && <Col xs={true} md={'auto'} className='gray-900'>Vehicle status: <StyledVehicleBinStatus className={vehicleBinStatus?.vehicleStatus || ''}>{vehicleBinStatus?.vehicleStatus}</StyledVehicleBinStatus></Col>}
                      {(vehicleBinStatus?.vehicleStatus && vehicleBinStatus?.binStatus) && <Col xs={'auto'} md={'auto'}><Divider type="vertical" /></Col>}
                      {vehicleBinStatus?.binStatus && <Col xs={true} md={'auto'} className='gray-900 text-end text-md-start'>Bin status: <StyledVehicleBinStatus className={vehicleBinStatus?.binStatus || ''}>{vehicleBinStatus?.binStatus}</StyledVehicleBinStatus></Col>}
                      {vehicleBinStatus?.info && <Col xs={12} md={true} className='gray-600 text-center text-md-end'><StyledInfoIcon /><span>{vehicleBinStatus?.info}</span></Col>}
                    </Row>
                  </Col>
                </Row>
              </StyledStepStatus>}
            </React.Fragment>
          })}

          <Form.Control.Feedback type="invalid" className={'d-block'}>{(errors && errors.templateSteps && typeof errors.templateSteps === 'string') && errors.templateSteps as ReactNode}</Form.Control.Feedback>
        </Row>
      </Col>

      <Col xs={12} className='mb-150'>
        <Button
          className={'add-step'}
          size={'sm'}
          disabled={values.templateSteps.some((x: any) => x.binActivity === null) || isOldJobTemplate}
          variant={'custom-outlined'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();

            validateForm().then((err: any) => {
              // if(!!err.templateSteps){
              //   warning('Warning', 'You must fill in the required fields');
              // } else {
                let items = _.cloneDeep(values.templateSteps);
                let newItem = _.cloneDeep(initStep);
                newItem.templateStepSites = defaultLocations;
                newItem.binActivity = BinActivityEnum.NoActivity;
                items.push(newItem);
                
                setFieldValue('templateSteps', items);
                scrollTo('.step-' + (items.length-1));
              // }
            })
          }}
        >
          <PlusIcon /> Add Step
        </Button>
      </Col>

    </Row>
  }


  return <Row className='g-24 mb-100'>
    <Col xs={12}>
      <Row className='g-8'>
        <Col xs={12}>
          <span className="large medium gray-900">Job Steps</span>
        </Col>
        <Col xs={12}>
          <span className="gray-800">Determine how many steps for this job type, specific location on each step, and what the driver do in each location.</span>
        </Col>
      </Row>
    </Col>

    <Col xs={12} md={12} lg={10}>
      {setFormElements()}
    </Col>
  </Row>
}

export default Tab2;
