import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  Form,
  InputGroup,
  Card,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Divider, Radio, Spin, Tooltip } from 'antd';
import InputMask from 'react-input-mask';
import DatePicker, { DateObject } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { fields, FormikContext, initialValues, formSchema, prepareForm, prepareData, getKgTons, toFixedUom, convertUom } from './slice.tsx';
import { checkStatus } from '../../slice.tsx';

// import SmartSelect from '../../../../../v5/components/select/SmartSelect.tsx';

import { warning, dateTimeFormat, getMaxPageSize, getCurrency, downloadPDF, isNumeric, getDefaultUOMv4, getBillingUOMv4 } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

import { ReactComponent as RefreshIcon } from "../../../../../v5/assets/icons/refresh-ccw.svg";
import { ReactComponent as CalendarIcon } from "../../../../../v5/assets/icons/calendar.svg";
import { LocalJobType } from '../../../manage/jobs/jobTypes/slice.tsx';

// ----------------------------------------------------------------------

const StyledSign = styled.div`
  background-color: var(--bs-blue-focus1);
  border: 1px solid var(--bs-gray-300);
  border-radius: 6px;
  padding: 12px 16px;
  text-align: center;
  align-content: center;
  height: calc(100% - 26px);

  ${down('md')} {
    height: auto;
  }
`;


const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, details, isLoadingRefresh, isLoadingDownload, isLoadingCustomFields } = useTypedSelector((state: RootState) => state.jobFormWeightChitSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useEffect(() => {
    if(show){
      dispatch(slice.callReadCustomFieldsCApi((state: boolean, customFields: any) => {
        initialRefresh(details).then((updatedStepGroups) => {
          let form = prepareForm(details, customFields, initialValues, getDefaultUOMv4());
          form['stepGroups'] = updatedStepGroups;
          setValues(form);
        });
      }));
    }
  }, [show, details])

  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema(null),
    onSubmit: values => {
      let data = prepareData(values);

      if(props.onSave){
        props.onSave(data);
      }

      onCancel();
    },
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


  const onCancel = () => {
    dispatch(slice.setShow({ show: false, details: null }));
    dispatch(slice.resetSlice());
  }

  const initialRefresh = async (details: any) => {
    let stepGroupsObj = _.cloneDeep(details?.stepGroups);
    let apiCalls: Promise<void>[] = [];

    let uomDefault = getDefaultUOMv4();
    let uomBilling = getBillingUOMv4();

    if(stepGroupsObj && stepGroupsObj.length > 0){
      stepGroupsObj.forEach((group: any, g: number) => {

        if(group && group.blocks && group.blocks.length > 0){
          group.blocks.forEach((block: any, b: number) => {
            let canShow = (group?.type === LocalJobType.EXCHANGE1) ? (b === 0) ? false : true : true;
            
            if(canShow){
              if(block && block.bins && block.bins.length > 0){
                block.bins.forEach((item: any, i: number) => {
                  
                  let vehicleId = (details && isNumeric(details.vehicleId)) ? details.vehicleId : null;
                  let binTypeId = (item && isNumeric(item.binTypeId)) ? item.binTypeId : null;
                  let serviceItemId = (item && isNumeric(item.serviceItemId)) ? item.serviceItemId : null;
              
                  let apiCall = new Promise<void>((resolve) => {
                    dispatch(slice.callRefreshWeightApi({ vehicleId, binTypeId, serviceItemId }, (state: boolean, data: any) => {
                      if(state){
                        let unladenBinWeightVehicle = isNumeric(data?.vehicleUnladenWeight) ? parseFloat(data?.vehicleUnladenWeight) : 0;
                        let unladenBinWeightBinType = isNumeric(data?.binUnladenWeight) ? parseFloat(data?.binUnladenWeight) : 0;
                        let nonBillableWeightVal = isNumeric(data?.nonBillableWeight) ? parseFloat(data?.nonBillableWeight) : 0;

                        let weighChitTareWeight = toFixedUom((unladenBinWeightBinType + unladenBinWeightVehicle), uomDefault);
                        let nonBillableBinWeight = parseFloat(uomBilling == uomDefault ? nonBillableWeightVal : convertUom(nonBillableWeightVal, uomBilling, uomDefault));

                        let isDisposal = (group?.type === LocalJobType.DISPOSAL);
                        let grossWaight = parseFloat(item.binWeight);
                        let binOutWeight = parseFloat(item.binOutWeight);
                        let binWeight = isDisposal ? binOutWeight : grossWaight;

                        let netBinWeight = binWeight - weighChitTareWeight;
                        let billableBinWeight = netBinWeight - nonBillableBinWeight;
                        billableBinWeight = (billableBinWeight < 0) ? 0 : billableBinWeight;
                        let netBinWeightToFixedUom = toFixedUom(netBinWeight, uomDefault);
                        let billableBinWeightToFixedUom = toFixedUom(billableBinWeight, uomDefault);

                        item['vehicleUnladenWeight'] = unladenBinWeightVehicle;
                        item['binUnladenWeight'] = unladenBinWeightBinType;
                        item['nonBillableWeight'] = nonBillableBinWeight;
                        item['tareBinWeight'] = weighChitTareWeight;
                        item['nonBillableBinWeight'] = nonBillableBinWeight;
                        item['netBinWeight'] = netBinWeightToFixedUom;
                        item['billableBinWeight'] = billableBinWeightToFixedUom;
                      }
                      resolve();
                    }));
                  });
      
                  apiCalls.push(apiCall);
                })
              }
            }
          })
        }

      })
    }

    await Promise.all(apiCalls);
    return stepGroupsObj;
  }
  const refreshTareWeight = async (item: any, callback: (data: any) => void) => {
    let vehicleId = (details && isNumeric(details.vehicleId)) ? details.vehicleId : null;
    let binTypeId = (item && isNumeric(item.binTypeId)) ? item.binTypeId : null;

    dispatch(slice.callRefreshWeightApi({ vehicleId, binTypeId }, (state: boolean, data: any) => {
      if(state && callback){
        callback(data);
      }
    }));
  }
  const refresNonBillablehWeight = async (item: any, callback: (data: any) => void) => {
    let serviceItemId = (item && isNumeric(item.serviceItemId)) ? item.serviceItemId : null;

    dispatch(slice.callRefreshWeightApi({ serviceItemId }, (state: boolean, data: any) => {
      if(state && callback){
        callback(data);
      }
    }));
  }
  const isDisabled = (group: any, item: any): boolean => {
    let isDisposal = (group?.type === LocalJobType.DISPOSAL);
    if(isDisposal){
      return (item.binOutWeight && item.binOutWeight !== '') ? false : true
    } else {
      return (item.binWeight && item.binWeight !== '') ? false : true
    }
  }


  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        {checkStatus(details?.statusName, ['completed']) && <Button
          variant={'custom-outlined'}
          size={'sm'}
          className='me-12'
          disabled={isLoadingDownload}
          onClick={(e) => {
            dispatch(slice.callDownloadApi(details?.jobId, (state: boolean, data: any) => {
              if(state){
                let fileName = "download" + details?.jobNumber + ".pdf"
                downloadPDF(fileName, data);
              }
            }));
          }}
        >Download</Button>}
        <Button
          variant={'custom-primary'}
          size={'sm'}
          onClick={(e) => {
            handleSubmit();
          }}
        >Save</Button>
      </Col>
    </Row>
  }

  const formElements = () => {
    return <Row className='g-32 align-items-center'>
      <Col xs={12}>
        <Row className='gx-40 gy-16 align-items-center'>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label disabled={false}>{fields.weightChitTicketNumber.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                size={'sm'}
                placeholder={fields.weightChitTicketNumber.placeholder}
                disabled={false}
                value={values?.weightChitTicketNumber || ''}
                onChange={async (e) => {
                  await setFieldValue('weightChitTicketNumber', e.target.value);
                }}
                isInvalid={!!errors.weightChitTicketNumber}
              />
              <Form.Control.Feedback type="invalid">{errors.weightChitTicketNumber}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>{fields.weightChitDateTime.label}</Form.Label>
              <InputGroup>
                <Form.Control
                  as = {InputMask}
                  mask={'99-99-9999 99:99'}
                  maskChar={null}
                  placeholder={dateTimeFormat()}
                  value={values?.weightChitDateTime}
                  onChange={(e) => {
                    setFieldValue('weightChitDateTime', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.weightChitDateTime)}
                />
                <InputGroup.Text>
                  <DatePicker
                    portal={true}
                    onOpenPickNewDate={false}
                    shadow={false}
                    disabled={false}
                    calendarPosition={'bottom-right'} 
                    format={dateTimeFormat()}
                    value={values?.date}
                    onChange={(value: DateObject) => {
                      setFieldValue('weightChitDateTime', value.format(dateTimeFormat()));
                    }}
                    render={(value, openCalendar) => {
                      return <Button variant={'custom-none-primary'} onClick={openCalendar}><CalendarIcon /></Button>
                    }}
                    plugins={[
                      <TimePicker hideSeconds position={'bottom'} />
                    ]}
                  />
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors && errors.weightChitDateTime as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label disabled={false}>{fields.binWeighBy.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                size={'sm'}
                placeholder={fields.binWeighBy.placeholder}
                disabled={false}
                value={values?.binWeighBy || ''}
                onChange={async (e) => {
                  await setFieldValue('binWeighBy', e.target.value);
                }}
                isInvalid={!!errors.binWeighBy}
              />
              <Form.Control.Feedback type="invalid">{errors.binWeighBy}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label>{fields.weightChitOutDateTime.label}</Form.Label>
              <InputGroup>
                <Form.Control
                  as = {InputMask}
                  mask={'99-99-9999 99:99'}
                  maskChar={null}
                  placeholder={dateTimeFormat()}
                  value={values?.weightChitOutDateTime}
                  onChange={(e) => {
                    setFieldValue('weightChitOutDateTime', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.weightChitOutDateTime)}
                />
                <InputGroup.Text>
                  <DatePicker
                    portal={true}
                    onOpenPickNewDate={false}
                    shadow={false}
                    disabled={false}
                    calendarPosition={'bottom-right'} 
                    format={dateTimeFormat()}
                    value={values?.weightChitOutDateTime}
                    onChange={(value: DateObject) => {
                      setFieldValue('weightChitOutDateTime', value.format(dateTimeFormat()));
                    }}
                    render={(value, openCalendar) => {
                      return <Button variant={'custom-none-primary'} onClick={openCalendar}><CalendarIcon /></Button>
                    }}
                    plugins={[
                      <TimePicker hideSeconds position={'bottom'} />
                    ]}
                  />
                </InputGroup.Text>
              </InputGroup>
              <Form.Control.Feedback type="invalid">{errors && errors.weightChitOutDateTime as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label disabled={false}>{fields.weightChitRemarks.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                size={'sm'}
                placeholder={fields.weightChitRemarks.placeholder}
                disabled={false}
                value={values?.weightChitRemarks || ''}
                onChange={async (e) => {
                  await setFieldValue('weightChitRemarks', e.target.value);
                }}
                isInvalid={!!errors.weightChitRemarks}
              />
              <Form.Control.Feedback type="invalid">{errors.weightChitRemarks}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>

      {(values.customFields && values.customFields.length > 0) && <Col xs={12}>
        <Row className='gx-40 gy-16 align-items-center'>
          <Col xs={12}>
            <div className='semibold gray-900 fs-18'>{fields.customFields.label}</div>
            <Divider className='m-0' />
          </Col>
          
          {values.customFields.map((item: any, i: number) => {
            return <Col xs={12} md={6} key={'jobform_weightchit_customfield_' + i}>
              <Form.Group>
                <Form.Label disabled={false}>{item?.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  size={'sm'}
                  placeholder={''}
                  disabled={false}
                  value={item?.value || ''}
                  onChange={async (e) => {
                    await setFieldValue('customFields.' + i + '.value', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.customFields && errors.customFields[i] && errors.customFields[i].value)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.customFields && errors.customFields[i] && errors.customFields[i].value) && errors.customFields[i].value}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          })}

        </Row>
      </Col>}

      <Col xs={12}><Divider className='m-0' /></Col>

      <Col xs={12}>
        <Row className='g-8 align-items-center'>

          {
            (values.stepGroups && values.stepGroups.length > 0)
            ?
            values.stepGroups.map((group: any, g: number) => {
              return <Col xs={12} key={'jobform_weightchit_group_' + g}>

                {
                  (group && group.blocks && group.blocks.length > 0)
                  ?
                  group.blocks.map((block: any, b: number) => {
                    let canShow = (group?.type === LocalJobType.EXCHANGE1) ? (b === 0) ? false : true : true;
                    
                    if(canShow){
                      return <div key={'jobform_weightchit_group_' + g + '_block_' + b}>
                        <Row className='g-12 align-items-center'>
                          {(block && block.bins && block.bins.length > 0) && block.bins.map((item: any, i: number) => {
                            return <Col xs={12} key={'jobform_weightchit_group_' + g + '_block_' + b + '_bin_' + i}>
                              <Row className='g-12 align-items-start'>
                                <Col xs={12} md={true}>
                                  {getRow({
                                    group: group, g: g,
                                    block: block, b: b,
                                    item: item, i: i, 
                                  })}
                                </Col>
                              </Row>
                            </Col>
                          })}
                        </Row>
                      </div>
                    }
                  })
                  :
                  <span className='gray-700'>No bin involved</span>
                }

              </Col>
            })
            :
            <Col xs={12}>No bins!</Col>
          }

        </Row>
      </Col>

    </Row>
  }

  const getRow = ({
    group, g,
    block, b,
    item, i, 
  }: any) => {
    return <Col xs={12} key={'jobform_weightchit_item_' + i}>
      <Row className='g-8 align-items-center'>
        <Col xs={12} className='semibold gray-900'>{(item && item.binNumber != '') ? 'Bin ID: ' + item?.binNumber : 'Bin'}</Col>
        <Col xs={12}>
          <Row className='g-12'>
            <Col xs={12} lg={true}>
              <Card className='card-list no-shadow'>
                <Card.Header className='semibold gray-800 fs-12'>{(group?.type === LocalJobType.DISPOSAL) ? fields.binOutWeight.label : fields.binWeight.label} {getKgTons(values?.uom)}</Card.Header>
                <Card.Body>
                  {(group?.type === LocalJobType.DISPOSAL)
                    ?
                    <Form.Group>
                      <Form.Control
                        as={CurrencyInput}
                        allowDecimals={true}
                        allowNegativeValue={false}
                        decimalsLimit={2}
      
                        autoComplete='off'
                        disabled={false}
                        placeholder={fields.binOutWeight.placeholder}
                        value={item?.binOutWeight || ''}
                        onValueChange={async (value: any) => {
                          if (value != '') {
                            let binOutWeight = parseFloat(value);
                            let tareBinWeight = parseFloat(item.tareBinWeight);
                            let nonBillableBinWeight = parseFloat(item.nonBillableBinWeight);
                            let netBinWeight = binOutWeight - tareBinWeight;
                            let billableBinWeight = netBinWeight - nonBillableBinWeight;
                            billableBinWeight = (billableBinWeight < 0) ? 0 : billableBinWeight;

                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binOutWeight', value);
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.netBinWeight', toFixedUom(netBinWeight, values.uom));
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.billableBinWeight', toFixedUom(billableBinWeight, values.uom));
                          } else {
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binOutWeight', value);
                          }
                        }}
                      
                        isInvalid={!!(
                          errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                          errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                          errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                          typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].binOutWeight
                        )}
                      />
                      <Form.Control.Feedback type="invalid">{(
                        errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                        errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                        errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                        errors.stepGroups[g].blocks[b].bins[i].binOutWeight
                      ) && errors.stepGroups[g].blocks[b].bins[i].binOutWeight}</Form.Control.Feedback>
                    </Form.Group>
                    :
                    <Form.Group>
                      <Form.Control
                        as={CurrencyInput}
                        allowDecimals={true}
                        allowNegativeValue={false}
                        decimalsLimit={2}
      
                        autoComplete='off'
                        disabled={false}
                        placeholder={fields.binWeight.placeholder}
                        value={item?.binWeight || ''}
                        onValueChange={async (value: any) => {
                          if (value != '') {
                            let binWeight = parseFloat(value);
                            let tareBinWeight = parseFloat(item.tareBinWeight);
                            let nonBillableBinWeight = parseFloat(item.nonBillableBinWeight);
                            let netBinWeight = binWeight - tareBinWeight;
                            let billableBinWeight = netBinWeight - nonBillableBinWeight;
                            billableBinWeight = (billableBinWeight < 0) ? 0 : billableBinWeight;

                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binWeight', value);
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.netBinWeight', toFixedUom(netBinWeight, values.uom));
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.billableBinWeight', toFixedUom(billableBinWeight, values.uom));
                          } else {
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binWeight', value);
                          }
                        }}
                      
                        isInvalid={!!(
                          errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                          errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                          errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                          typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].binWeight
                        )}
                      />
                      <Form.Control.Feedback type="invalid">{(
                        errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                        errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                        errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                        errors.stepGroups[g].blocks[b].bins[i].binWeight
                      ) && errors.stepGroups[g].blocks[b].bins[i].binWeight}</Form.Control.Feedback>
                    </Form.Group>
                  }
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} lg={'auto'}>
              <StyledSign>-</StyledSign>
            </Col>

            <Col xs={12} lg={true}>
              <Row className='g-6 align-items-center'>
                <Col xs={12}>
                  <Card className='card-list no-shadow'>
                    <Card.Header className='semibold gray-800 fs-12'>{fields.tareBinWeight.label} {getKgTons(values?.uom)}</Card.Header>
                    <Card.Body>
                      <Row className='g-10 align-items-center'>
                        <Col xs={true}>
                          <Form.Group>
                            <Form.Control
                              as={CurrencyInput}
                              allowDecimals={true}
                              allowNegativeValue={false}
                              decimalsLimit={2}
            
                              autoComplete='off'
                              disabled={isDisabled(group, item)}
                              placeholder={fields.tareBinWeight.placeholder}
                              value={item?.tareBinWeight || ''}
                              onValueChange={async (value: any) => {
                                let isDisposal = (group?.type === LocalJobType.DISPOSAL);
                                let grossWaight = parseFloat(item.binWeight);
                                let binOutWeight = parseFloat(item.binOutWeight);
                                let binWeight = isDisposal ? binOutWeight : grossWaight;

                                let tareBinWeight = parseFloat(value);
                                let nonBillableBinWeight = parseFloat(item.nonBillableBinWeight);
                                let netBinWeight = binWeight - tareBinWeight;
                                let billableBinWeight = netBinWeight - nonBillableBinWeight;
                                billableBinWeight = (billableBinWeight < 0) ? 0 : billableBinWeight;

                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.tareBinWeight', value);
                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.netBinWeight', toFixedUom(netBinWeight, values.uom));
                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.billableBinWeight', toFixedUom(billableBinWeight, values.uom));
                              }}
                            
                              isInvalid={!!(
                                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                                typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].tareBinWeight
                              )}
                            />
                            <Form.Control.Feedback type="invalid">{(
                              errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                              errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                              errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                              errors.stepGroups[g].blocks[b].bins[i].tareBinWeight
                            ) && errors.stepGroups[g].blocks[b].bins[i].tareBinWeight}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={'auto'}>
                          <Tooltip title={'Refresh tare weight'}>
                            <Button
                              variant={'custom-secondary px-6'}
                              size={'sm'}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                refreshTareWeight(item, async (data: any) => {
                                  if(data){
                                    let unladenBinWeightBinType = isNumeric(data?.binUnladenWeight) ? parseFloat(data?.binUnladenWeight) : 0;
                                    let unladenBinWeightVehicle = isNumeric(data?.vehicleUnladenWeight) ? parseFloat(data?.vehicleUnladenWeight) : 0;
                                    let weighChitTareWeight = toFixedUom((unladenBinWeightBinType + unladenBinWeightVehicle), values.uom);
                                    
                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.vehicleUnladenWeight', unladenBinWeightVehicle);
                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binUnladenWeight', unladenBinWeightBinType);

                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.tareBinWeight', weighChitTareWeight);
                                    
                                    let isDisposal = (group?.type === LocalJobType.DISPOSAL);
                                    let grossWaight = parseFloat(item.binWeight);
                                    let binOutWeight = parseFloat(item.binOutWeight);
                                    let binWeight = isDisposal ? binOutWeight : grossWaight;

                                    let nonBillableBinWeight = parseFloat(item.nonBillableBinWeight);
                                    let netBinWeight = binWeight - weighChitTareWeight;
                                    let billableBinWeight = netBinWeight - nonBillableBinWeight;
                                    billableBinWeight = (billableBinWeight < 0) ? 0 : billableBinWeight;
                                    let netBinWeightToFixedUom = toFixedUom(netBinWeight, values.uom);
                                    let billableBinWeightToFixedUom = toFixedUom(billableBinWeight, values.uom);

                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.netBinWeight', netBinWeightToFixedUom);
                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.billableBinWeight', billableBinWeightToFixedUom);
                                  }
                                });
                              }}
                            >
                              <RefreshIcon />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Row className='g-8 align-items-center'>
                    <Col xs={'auto'}>
                      <span className={'fs-12 gray-700 me-s4'}>{fields.vehicle.label}</span>
                      <span className={'fs-12 gray-900'}>{item?.vehicleUnladenWeight} {getKgTons(values?.uom, '', '')}</span>
                    </Col>
                    <Col xs={'auto'}><Divider type='vertical' /></Col>
                    <Col xs={'auto'}>
                      <span className={'fs-12 gray-700 me-s4'}>{fields.bin.label}</span>
                      <span className={'fs-12 gray-900'}>{item?.binUnladenWeight} {getKgTons(values?.uom, '', '')}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={12} lg={'auto'}>
              <StyledSign>=</StyledSign>
            </Col>

            <Col xs={12} lg={true}>
              <Card className='card-list no-shadow'>
                <Card.Header className='semibold gray-800 fs-12'>{fields.netBinWeight.label} {getKgTons(values?.uom)}</Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Control
                      as={CurrencyInput}
                      allowDecimals={true}
                      allowNegativeValue={false}
                      decimalsLimit={2}
    
                      autoComplete='off'
                      disabled={true}
                      placeholder={fields.netBinWeight.placeholder}
                      value={item?.netBinWeight || ''}
                      onValueChange={async (value: any) => {
                        await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.netBinWeight', value);
                      }}
                    
                      isInvalid={!!(
                        errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                        errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                        errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                        typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].netBinWeight
                      )}
                    />
                    <Form.Control.Feedback type="invalid">{(
                      errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                      errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                      errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                      errors.stepGroups[g].blocks[b].bins[i].netBinWeight
                    ) && errors.stepGroups[g].blocks[b].bins[i].netBinWeight}</Form.Control.Feedback>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} lg={'auto'}>
              <StyledSign>-</StyledSign>
            </Col>

            <Col xs={12} lg={true}>
              <Row className='g-6 align-items-center'>
                <Col xs={12}>
                  <Card className='card-list no-shadow'>
                    <Card.Header className='semibold gray-800 fs-12'>{fields.nonBillableBinWeight.label} {getKgTons(values?.uom)}</Card.Header>
                    <Card.Body>
                      <Row className='g-10 align-items-center'>
                        <Col xs={true}>
                          <Form.Group>
                            <Form.Control
                              as={CurrencyInput}
                              allowDecimals={true}
                              allowNegativeValue={false}
                              decimalsLimit={2}
            
                              autoComplete='off'
                              disabled={isDisabled(group, item)}
                              placeholder={fields.nonBillableBinWeight.placeholder}
                              value={item?.nonBillableBinWeight || ''}
                              onValueChange={async (value: any) => {
                                let isDisposal = (group?.type === LocalJobType.DISPOSAL);
                                let grossWaight = parseFloat(item.binWeight);
                                let binOutWeight = parseFloat(item.binOutWeight);
                                let binWeight = isDisposal ? binOutWeight : grossWaight;

                                let tareBinWeight = parseFloat(item.tareBinWeight);
                                let nonBillableBinWeight = parseFloat(value);
                                let netBinWeight = binWeight - tareBinWeight;
                                let billableBinWeight = netBinWeight - nonBillableBinWeight;
                                billableBinWeight = (billableBinWeight < 0) ? 0 : billableBinWeight;

                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.nonBillableBinWeight', value);
                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.tareBinWeight', tareBinWeight);
                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.netBinWeight', toFixedUom(netBinWeight, values.uom));
                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.billableBinWeight', toFixedUom(billableBinWeight, values.uom));
                              }}
                            
                              isInvalid={!!(
                                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                                typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].nonBillableBinWeight
                              )}
                            />
                            <Form.Control.Feedback type="invalid">{(
                              errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                              errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                              errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                              errors.stepGroups[g].blocks[b].bins[i].nonBillableBinWeight
                            ) && errors.stepGroups[g].blocks[b].bins[i].nonBillableBinWeight}</Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                        <Col xs={'auto'}>
                          <Tooltip title={'Refresh non-billable weight'}>
                            <Button
                              variant={'custom-secondary px-6'}
                              size={'sm'}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                refresNonBillablehWeight(item, async (data: any) => {
                                  if(data){
                                    // Convert from billing uom to default uom
                                    let nonBillableWeightVal = isNumeric(data?.nonBillableWeight) ? parseFloat(data?.nonBillableWeight) : 0;
                                    let nonBillableBinWeight = parseFloat(getBillingUOMv4() == values.uom ? nonBillableWeightVal : convertUom(nonBillableWeightVal, getBillingUOMv4(), values.uom));
                                    
                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.nonBillableWeight', nonBillableBinWeight);

                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.nonBillableBinWeight', nonBillableBinWeight);
                                    
                                    let isDisposal = (group?.type === LocalJobType.DISPOSAL);
                                    let grossWaight = parseFloat(item.binWeight);
                                    let binOutWeight = parseFloat(item.binOutWeight);
                                    let binWeight = isDisposal ? binOutWeight : grossWaight;

                                    let netBinWeight = binWeight - parseFloat(item.tareBinWeight);
                                    let billableBinWeight = netBinWeight - nonBillableBinWeight;
                                    billableBinWeight = (billableBinWeight < 0) ? 0 : billableBinWeight;
                                    let netBinWeightToFixedUom = toFixedUom(netBinWeight, values.uom);
                                    let billableBinWeightToFixedUom = toFixedUom(billableBinWeight, values.uom);
                                    
                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.netBinWeight', netBinWeightToFixedUom);
                                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.billableBinWeight', billableBinWeightToFixedUom);
                                  }
                                });
                              }}
                            >
                              <RefreshIcon />
                            </Button>
                          </Tooltip>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                <Col xs={12}>
                  <Row className='g-8 align-items-center'>
                    <Col xs={'auto'}>
                      <span className={'fs-12 gray-700 me-s4'}>{fields.nonBillable.label}</span>
                      <span className={'fs-12 gray-900'}>{item?.nonBillableWeight} {getKgTons(values?.uom, '', '')}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col xs={12} lg={'auto'}>
              <StyledSign>=</StyledSign>
            </Col>

            <Col xs={12} lg={true}>
              <Card className='card-list no-shadow'>
                <Card.Header className='semibold gray-800 fs-12'>{fields.billableBinWeight.label} {getKgTons(values?.uom)}</Card.Header>
                <Card.Body>
                  <Form.Group>
                    <Form.Control
                      as={CurrencyInput}
                      allowDecimals={true}
                      allowNegativeValue={false}
                      decimalsLimit={2}
    
                      autoComplete='off'
                      disabled={isDisabled(group, item)}
                      placeholder={fields.billableBinWeight.placeholder}
                      value={item?.billableBinWeight || ''}
                      onValueChange={async (value: any) => {
                        await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.billableBinWeight', value);
                      }}
                    
                      isInvalid={!!(
                        errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                        errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                        errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                        typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].billableBinWeight
                      )}
                    />
                    <Form.Control.Feedback type="invalid">{(
                      errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                      errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                      errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                      errors.stepGroups[g].blocks[b].bins[i].billableBinWeight
                    ) && errors.stepGroups[g].blocks[b].bins[i].billableBinWeight}</Form.Control.Feedback>
                  </Form.Group>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xs={12}><Divider className='my-8' /></Col>
      </Row>
    </Col>
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '90%' }}
  >
    <FormikContext.Provider value={formik}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Weight Chit</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='px-24 py-16 scrollable-container'>
        <Spin spinning={isLoadingRefresh || isLoadingCustomFields} wrapperClassName={'no-height'}>
          {formElements()}
        </Spin>
      </Offcanvas.Body>
      <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
    </FormikContext.Provider>
  </Offcanvas>
}

export default PageComponent;