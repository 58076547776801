import React from 'react';
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Pagination, Radio } from 'antd';

// Auth
// import { useAuthContext } from '../../../../auth/use-auth-context.js';
// import { useRouter } from '../../../../auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { fields, clientSideRows, getPaginationCount, itemRenderPagination, StyledTable } from './slice.tsx';

// enums
import { dateFormat, isNumeric } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

// import MaterialDataTable from '../../../../components/table/withSlice/MaterialDataTable.jsx';

// import { ReactComponent as InfoIcon } from "../../../../assets/icons/i.svg";
import { ReactComponent as PrevIcon } from "../../../../assets/icons/arrow-left.svg";
import { ReactComponent as NextIcon } from "../../../../assets/icons/arrow-right.svg";

// ----------------------------------------------------------------------


const ListComponent = React.forwardRef((props: any, ref: any) => {
  // const router = useRouter();

  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;
  
  const { selectedHistory, rowsHistory, clientSideCurrentPageHistory, clientSideItemsPerPageHistory } = useTypedSelector((state: RootState) => state.jobFormBinDetailsServicesSlice);
  const dispatch = useDispatch<AppDispatch>();
  

  
	// React.useImperativeHandle(
  //   ref,
  //   () => ({
  //     get() {
        
  //     },
  //   }),
  // );
  

  // React.useEffect(() => {
    
  // }, []);


  const tableSection = () => {
    let historyServices = rowsHistory.filter((x: any) => isNumeric(x.customerId));
    let historyServiceRows = clientSideRows(historyServices, clientSideCurrentPageHistory, clientSideItemsPerPageHistory);
    let total = getPaginationCount(historyServices.length, clientSideItemsPerPageHistory);

    if(historyServiceRows && historyServiceRows.length > 0){
      return <>
        <StyledTable>
          <thead>
            <tr>
              <th style={{ width: '5%' }}>&nbsp;</th>
              <th style={{ width: '10%' }}>{fields.binOutDate.label}</th>
              <th style={{ width: '10%' }}>{fields.binTypeName.label}</th>
              <th style={{ width: '10%' }}>{fields.wasteTypeName.label}</th>
              <th style={{ width: '10%' }}>{fields.binNumber.label}</th>
              <th style={{ width: '10%' }}>{fields.jobNumber.label}</th>
              <th style={{ width: '10%' }}>{fields.driverName.label}</th>
              <th style={{ width: '10%' }}>{fields.vehicleName.label}</th>
            </tr>
          </thead>
          <tbody>
            {historyServiceRows.map((row: any, i: number) => {
              return <tr key={'jobform_services_history_' + i}
                className={(selectedHistory?.customerSiteBinHistoryId === row?.customerSiteBinHistoryId) ? 'active' : ''}
                onClick={(e) => {
                  dispatch(slice.setSelectedHistory((selectedHistory?.customerSiteBinHistoryId === row?.customerSiteBinHistoryId) ? null : row));
                }}
              >
                <td className='text-center'>
                  <Radio checked={(selectedHistory?.customerSiteBinHistoryId === row?.customerSiteBinHistoryId)} />
                </td>
                <td>{(row.binOutDate) ? moment(row.binOutDate).format(dateFormat()) : ''}</td>
                <td>{row.binTypeName}</td>
                <td>{row.wasteTypeName}</td>
                <td>{row.binNumber}</td>
                <td>{row.jobNumber}</td>
                <td>{row.driverName}</td>
                <td>{row.vehicleName}</td>
              </tr>
            })}
          </tbody>
        </StyledTable>

        {(historyServiceRows && historyServiceRows.length > 0) && <div className={'pt-12 pe-24 pb-16 ps-24 w-100 align-items-center justify-content-center'}>
          <Row className='align-items-center g-8'>
            <Col xs={{ span: 'auto', order: 1 }} md={{ span: 'auto', order: 1 }}>
              
            </Col>
            <Col xs={{ span: 12, order: 5 }} md={{ span: true, order: 2 }} className='text-center'>
              <Row className='align-items-center justify-content-center'>
                <Col xs={'auto'}>
                  <Pagination
                    showTitle={false}
                    showSizeChanger={false}
                    current={clientSideCurrentPageHistory}
                    pageSize={clientSideItemsPerPageHistory}
                    pageSizeOptions={[clientSideItemsPerPageHistory]}
                    total={historyServices.length}
                    onChange={(page, pageSize) => {
                      dispatch(slice.setClientSideCurrentPageHistory((page)));
                    }}
                    itemRender={itemRenderPagination}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={{ span: true, order: 2 }} md={{ span: true, order: 3 }} className='d-flex d-md-none'></Col>
            <Col xs={{ span: 'auto', order: 3 }} md={{ span: 'auto', order: 4 }}>
              <Button
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  dispatch(slice.setClientSideCurrentPageHistory((clientSideCurrentPageHistory-1)));
                }}
                disabled={(clientSideCurrentPageHistory <= 1)}
              >
                <PrevIcon />
              </Button>
            </Col>
            <Col xs={{ span: 'auto', order: 4 }} md={{ span: 'auto', order: 5 }}>
              <Button
                variant={'custom-outlined'}
                size='sm'
                onClick={() => {
                  dispatch(slice.setClientSideCurrentPageHistory((clientSideCurrentPageHistory+1)));
                }}
                disabled={(clientSideCurrentPageHistory >= total)}
              >
                <NextIcon />
              </Button>
            </Col>
          </Row>
        </div>}
      </>
    } else {
      return <div className='text-center px-16 py-12'>No service history</div>
    }
  }

  
  return <>
    {tableSection()}
  </>
});

export default ListComponent;
