import React from "react";
import moment from 'moment';
import queryString from 'query-string';
import * as yup from 'yup';
import axios from 'axios';

import Utils from '../../../utils/utils'
import { PageCookieSettingType, ParentJobRelation, CancelToken, ReportUOM, JobStepType } from "../../../utils/enums";
import { StyledBilllingStatus, StyledRealTimeItem } from "../../../utils/styles";

import { updateApi } from '../../../api/CRUD/DispatchCRUD'

import { connect } from 'react-redux'
import { actions } from "../../../../setup/redux/auth/saga";
import { clear } from "../../../../setup/redux/actions";
import { dispatchApiCallGet, dispatchApiCallPost, dispatchApiCallPut, dispatchApiCallDelete } from '../../../../setup/redux/dispatch/actions'
import realTimeUpdate from "../../../../setup/redux/slices/realTimeUpdate";
import dateSection from "../../../../setup/redux/slices/dateSection";
import importWeighChit from "../redux/importWeighChit";
import weighChitCustomFields from "../../Manage/Company/redux/weighChitCustomFields";
import refreshWeighChit from "../redux/refreshWeighChit";
import signalRslice from "../../../components/signalR/signalRslice";
import bulkUpdateJobForm from "../redux/bulkUpdateJobForm";

import { Spinner, Modal, Badge, Row, Col, Button, Dropdown } from "react-bootstrap-v5";

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';

import Tooltip from "../../../components/Tooltip/Tooltip";
import CustomPopover from '../../../components/Tooltip/Popover';
import IconButtonTable from "../../../components/Buttons/IconButtonTable";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import CustomCheckInput from "../../../components/OLD/input/CustomCheckInput";
import CustomDropdownMultiSelect from '../../../components/OLD/Autocomplete/CustomDropdownMultiSelect';
import MenuSubItems from '../../../components/OLD/Dropdown/MenuSubItems';
import IconButtonSearch from '../../../components/input/IconButtonSearch';
import DropdownToggle from '../../../components/dropdown/DropdownToggle';
import DropdownIconButtonMenu from '../../../components/OLD/Dropdown/DropdownIconButtonMenu';
import AdvancedFilter from '../../../components/OLD/Filter/AdvancedFilter';
import makeExpanding from '../../../components/OLD/Expanding/makeExpanding';
import makeDropdownList from "../../../components/dropdown/makeDropdownList";
import Popup from '../../../components/popup/Popup';
import LoadingButton from '../../../components/Buttons/LoadingButton';
import IconButtonTooltip from '../../../components/Buttons/IconButtonTooltip';

import DataTable from "../../../components/OLD/DataTable/DataTable";
import DataTableActionMenu from '../../../components/OLD/DataTable/DataTableActionMenu';
import DataTableAjaxPagination from '../../../components/OLD/DataTable/Pagination/DataTableAjaxPaginationV5';
import DataTableScrollSync from '../../../components/OLD/DataTable/DataTableScrollSync.jsx';

import CancelDialog from '../Dialogs/CancelDialog';
import FailedDialog from '../Dialogs/FailedDialog.jsx';
import PopupWeighChit from '../Dialogs/PopupWeighChit.jsx';
import ImportWeighChitPopup from '../Dialogs/importWeighChit/ImportWeighChitPopup';
import BulkUpdateJobForm from "../Dialogs/BulkUpdateJobForm";

import { DrawerLayout } from '../../../components/drawer/DrawerLayout'
import JobViewAssignToForm from "./drawer/JobViewAssignToForm";
import JobFormWeighChitDrawer from "./drawer/JobFormWeighChitDrawer";

import SignalR from '../../../components/signalR/SignalR.jsx';
import { ReceiveMessage } from '../../../components/signalR/Enums.jsx';

import { ReactComponent as RepeatIcon } from '../../../../_metronic/assets/img/icons/repeat.svg';
import { ReactComponent as NotifIcon } from '../../../../_metronic/assets/img/icons/ic_priority_high_24px.svg';
import { ReactComponent as FilterIcon } from "../../../../_metronic/assets/img/icons/manage-filter.svg";
import { ReactComponent as PrintIcon } from "../../../../_metronic/assets/img/icons/report-print.svg";
import { ReactComponent as DownloadIcon } from "../../../../_metronic/assets/img/icons/report-download.svg";
import { ReactComponent as SettingsIcon } from "../../../../_metronic/assets/img/icons/manage-settings.svg";  
import { ReactComponent as WeighChitIcon } from "../../../../_metronic/assets/img/icons/weight_chit.svg";  
import { ReactComponent as ScanTextIcon } from "../../../../_metronic/assets/img/bin_center/scan_text.svg";

const { saveUserCookieSettings } = actions;
const ExpandingSearchBox = makeExpanding(IconButtonSearch);
const DropdownMenu = makeDropdownList(Button);


const formFieldsAssignTo = {
  driversList: {
    id: 'driversList',
    label: 'Drivers',
    placeholder: ' ',
    required: 'You must select a driver',
  },
}
const formSchemaAssignTo = yup.object().shape({
  driversList: yup.array().of(
    yup.object().shape({
      isChecked: yup.bool().oneOf([true, false]),
    })
  ).min(1, formFieldsAssignTo.driversList.required),
});



const formFieldsWeighChit = {
  ticketNumber: {
    id: 'ticketNumber',
    label: 'Ticket number',
    placeholder: ' ',
  },
  grossWeight: {
    id: 'grossWeight',
    label: 'Gross weight',
    placeholder: ' ',
    info: 'You can enter a maximum of 5 numbers with max 3 decimal places',
  },
  tareWeight: {
    id: 'tareWeight',
    label: 'Tare weight',
    placeholder: ' ',
  },
  nettWeight: {
    id: 'nettWeight',
    label: 'Nett weight',
    placeholder: ' ',
  },
  billableWeight: {
    id: 'billableWeight',
    label: 'Billable weight',
    placeholder: ' ',
  },
  nonBillableBinWeight: {
    id: 'nonBillableBinWeight',
    label: 'Non-billable weight',
    placeholder: ' ',
  },
  remarks: {
    id: 'remarks',
    label: 'Remarks',
    placeholder: ' ',
  },
  weighBy: {
    id: 'weighBy',
    label: 'Weigh by',
    placeholder: ' ',
  },
  date: {
    id: 'date',
    label: 'Weigh bridge check in',
    placeholder: ' ',
  },
  weightChitOutDateTime: {
    id: 'weightChitOutDateTime',
    label: 'Weigh bridge check out',
    placeholder: ' ',
  },
  // time: {
  //   id: 'time',
  //   label: 'Time',
  //   placeholder: ' ',
  // },
}
const formSchemaWeighChit = yup.object().shape({
  isEdit: yup.bool().oneOf([true, false]),
  
  ticketNumber: yup.string().nullable().label(formFieldsWeighChit.ticketNumber.label),
  // grossWeight: yup.number().nullable().min(0).label(formFieldsWeighChit.grossWeight.label),
  // grossWeight: yup.string().nullable().matches(Utils.decimalValidationInputText(), formFieldsWeighChit.grossWeight.info).label(formFieldsWeighChit.grossWeight.label),

  uom: yup.number().oneOf([ReportUOM.tons, ReportUOM.kg]),
  grossWeight: yup.number().when(['uom'], (uom, schema) => {
    if (uom === ReportUOM.kg ) {
      return yup.number()
        .integer()
        .test('noDecimal', 'Decimal values are not allowed', val => {
            if (val !== undefined && val !== null) {
                const strVal = val.toString();
                return strVal.indexOf('.') === -1;
            }
            return true;
        })
        .max(99999)
        .nullable()
        .min(0)
        .label(formFieldsWeighChit.grossWeight.label);

    } else {
      return yup.number()
        .typeError('Invalid ' + formFieldsWeighChit.grossWeight.label)
        .transform((value, originalValue) => {
          if (originalValue === undefined || originalValue === null || originalValue === '') {
            return undefined;
          }
          return parseFloat(originalValue);
        })
        .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
          if (val === null || val === undefined) return true;
            const strVal = val.toString();
            const parts = strVal.split('.');
          if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
              return true;
          }
          return false;
        })
        .max(99.999)
        .nullable()
        .min(0)
        .label(formFieldsWeighChit.grossWeight.label);
    }
  }),

  tareWeight: yup.number().when(['uom', 'grossWeight'], (uom, grossWeight, schema) => {
    if (uom === ReportUOM.kg ) {
      return yup.number()
        .integer()
        .test('noDecimal', 'Decimal values are not allowed', val => {
            if (val !== undefined && val !== null) {
                const strVal = val.toString();
                return strVal.indexOf('.') === -1;
            }
            return true;
        })
        .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99999)
        .nullable()
        .min(0)
        .label(formFieldsWeighChit.tareWeight.label);
        
    } else {
      return yup.number()
        .typeError('Invalid ' + formFieldsWeighChit.tareWeight.label)
        .transform((value, originalValue) => {
          if (originalValue === undefined || originalValue === null || originalValue === '') {
            return undefined;
          }
          return parseFloat(originalValue);
        })
        .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
          if (val === null || val === undefined) return true;
            const strVal = val.toString();
            const parts = strVal.split('.');
          if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
              return true;
          }
          return false;
        })
        .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99.999)
        .nullable()
        .min(0)
        .label(formFieldsWeighChit.tareWeight.label);
    }
  }),

  nettWeight: yup.number().nullable().min(0).label(formFieldsWeighChit.nettWeight.label),

  nonBillableBinWeight: yup.number().when(['uom'], (uom, grossWeight, schema) => {
    if (uom === ReportUOM.kg ) {
      return yup.number()
        .integer()
        .test('noDecimal', 'Decimal values are not allowed', val => {
            if (val !== undefined && val !== null) {
                const strVal = val.toString();
                return strVal.indexOf('.') === -1;
            }
            return true;
        })
        .min(0)
        .max(99999)
        .nullable()
        .label(formFieldsWeighChit.nonBillableBinWeight.label);
        
    } else {
      return yup.number()
        .typeError('Invalid ' + formFieldsWeighChit.nonBillableBinWeight.label)
        .transform((value, originalValue) => {
          if (originalValue === undefined || originalValue === null || originalValue === '') {
            return undefined;
          }
          return parseFloat(originalValue);
        })
        .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
          if (val === null || val === undefined) return true;
            const strVal = val.toString();
            const parts = strVal.split('.');
          if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
              return true;
          }
          return false;
        })
        .min(0)
        .max(99.999)
        .nullable()
        .label(formFieldsWeighChit.nonBillableBinWeight.label);
    }
  }),

  billableWeight: yup.number().when(['uom', 'grossWeight'], (uom, grossWeight, schema) => {
    if (uom === ReportUOM.kg ) {
      return yup.number()
        .integer()
        .test('noDecimal', 'Decimal values are not allowed', val => {
            if (val !== undefined && val !== null) {
                const strVal = val.toString();
                return strVal.indexOf('.') === -1;
            }
            return true;
        })
        .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99999)
        .nullable()
        .min(0)
        .label(formFieldsWeighChit.billableWeight.label);
        
    } else {
      return yup.number()
        .typeError('Invalid ' + formFieldsWeighChit.billableWeight.label)
        .transform((value, originalValue) => {
          if (originalValue === undefined || originalValue === null || originalValue === '') {
            return undefined;
          }
          return parseFloat(originalValue);
        })
        .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
          if (val === null || val === undefined) return true;
            const strVal = val.toString();
            const parts = strVal.split('.');
          if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
              return true;
          }
          return false;
        })
        .max((Utils.parseFloat(grossWeight) > 0) ? grossWeight : 99.999)
        .nullable()
        .min(0)
        .label(formFieldsWeighChit.billableWeight.label);
    }
  }),

  remarks: yup.string().nullable().label(formFieldsWeighChit.remarks.label),
  weighBy: yup.string().nullable().label(formFieldsWeighChit.weighBy.label),
  date: yup.date().nullable().label(formFieldsWeighChit.date.label),
  weightChitOutDateTime: yup.date().nullable().label(formFieldsWeighChit.weightChitOutDateTime.label),
  // time: yup.date().nullable().label(formFieldsWeighChit.time.label),
});


const { 
  REACT_APP_REAL_TIME_URL
} = process.env;


class JobViewTableSection extends React.Component {
    constructor(props) {
        super(props);
        
        props.layoutRef.current = this;

        this.pageName = "jobs-page";
        
        this.tableRef = React.createRef();
        this.scrollListRef = React.createRef();
        this.refDropdownAdvacedSearch = React.createRef();
        this.refDropdownActionMenu = React.createRef();
        this.refFormDrawerAssignTo = React.createRef();
        this.refFormDrawerWeighChit = React.createRef();
        this.refDataTableScrollSync = React.createRef();
        this.refPopupWeighChit = React.createRef();
        this.refSignalR = React.createRef();

        this.formDrawerAssignTo = null;
        this.formDrawerWeighChit = null;

        let userInfo = props.auth.user;
        let groupAccountEmail = (userInfo && userInfo.userAccount && userInfo.userAccount.accountEmail) ? userInfo.userAccount.accountEmail : '';
        let userEmail = (userInfo && userInfo.email && userInfo.email !== '') ? userInfo.email : '';
        
		    this.dispatchUser = (props.auth && props.auth.dispatchUser) ? props.auth.dispatchUser : null;
        
        let q = queryString.parse(window.location.search);
        this.status_filter = (q.status_filter) ? q.status_filter : '';
        this.weigh_chit = (q.weigh_chit) ? q.weigh_chit : '';

        this.intervalId = null;

        this.actionTemplate = (id, row, col, obj, index) => {
            let isCompleted = (row.statusName.toLowerCase() === 'completed') ? true : false;
            let isUnassigned = (row.statusName.toLowerCase() === 'unassigned') ? true : false;
            let isDispatched = (row.statusName.toLowerCase() === 'dispatched') ? true : false;

            return <div>
              {(row.isActive && (row.customer && row.customer.isActive)) && <IconButtonTable
                title={'Copy'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
      
                  // this.setState({
                  //   isCreateNewDialogCopy: true,
                  //   newDialogItemCopy: row
                  // });
      
                  this.props.history.push((row?.version == 1) ? '/v5/job-form-beta?id=' + row.jobId + '&isCopy=true' : '/jobs-form?id=' + row.jobId + '&isCopy=true');
                }}
              >
                <i className={'material-icons'}>content_copy</i>
              </IconButtonTable>}
              {
                isCompleted && row.isActive
                ?
                  (!row.downloadIsLoading)
                  ?
                  <IconButtonTable
                    title={'Download DO'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      
                      row.downloadIsLoading = true;
                      obj.refrsh();
      
                      this.callDownloadFileApi(row.jobId, (response) => {
                        if(response){
                          let byteArray = Utils.getUint8Array(response);
        
                          const url = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', row.jobNumber + ((row.customer && row.customer.billingName && row.customer.billingName !== '') ? ' ' + row.customer.billingName : '') + '.pdf');
                          document.body.appendChild(link);
                          link.click();
                          link.remove();
                        }
        
                        row.downloadIsLoading = false;
                        obj.refrsh();
                      });
                    }}
                  >
                    <i className={'far fa-file-pdf'}></i>
                  </IconButtonTable>
                  :
                  <span style={{ paddingRight: '11px' }}><Spinner animation="border" /></span>
                :
                null
              }
              {isUnassigned && row.isActive && <IconButtonTable
                title={'Remove'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  obj.remove(row);
                }}
              
                >
                <i className={'material-icons'}>delete</i>
              </IconButtonTable>}
      
              {isDispatched && row.isActive && <IconButtonTable
                title={'Send a reminder notification'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
      
                  this.callSendNotificationToDriverApi(row.jobId);
                }}
              >
                <i className={'material-icons'}>notifications</i>
              </IconButtonTable>}
              
              {(
                (row.jobTypeName == 'Exchange' ||
                row.jobTypeName == 'On The Spot' ||
                (row.jobTypeName == 'Out' && row.statusName.toLowerCase() == "completed") ||
                row.jobTypeName == 'Pull') &&
                (row.statusName.toLowerCase() != "deleted" && 
                row.statusName.toLowerCase() != "cancelled" &&
                row.statusName.toLowerCase() != "failed" &&
                row.statusName.toLowerCase() != "rejected")
              ) && <IconButtonTable
                title={'Weigh chit'}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  
                  let jobId = (row && row.jobId) ? row.jobId : null;

                  this.callDetailsApi(jobId, row, (res) => {
                    let jobStepId = null;
                    let jobStep = null;
                    let steps = (res && res.steps && res.steps.length > 0) ? res.steps : [];
                    for(let i = 0; i < steps.length; i++){
                      let item = steps[i];
                      
                      if(row.jobTypeName == 'Out'){
                        if(i === 0){
                          jobStepId = item.jobStepId;
                          jobStep = item;
                          break;
                        }
                      } else {
                        if(item.customerSiteId){
                          jobStepId = item.jobStepId;
                          jobStep = item;
                          break;
                        }
                      }
                    }
  
                    let statusName = (res && res.statusName) ? res.statusName : '';
                    let jobNumber = (res && res.jobNumber) ? res.jobNumber : '';
                    
                    let weighChitTicketNumber = (jobStep && jobStep.weightChitTicketNumber) ? jobStep.weightChitTicketNumber : '';
                    let weighChitGrossWeight = (jobStep && jobStep.binWeight) ? jobStep.binWeight : '';
                    let weighChitTareWeight = (jobStep && jobStep.tareBinWeight) ? jobStep.tareBinWeight : '0';
                    let weighChitNettWeight = (jobStep && jobStep.netBinWeight) ? jobStep.netBinWeight : '0';
                    let weighChitNonBillableBinWeight = (jobStep && jobStep.nonBillableBinWeight) ? jobStep.nonBillableBinWeight : '0';
                    let weighChitBillableWeight = (jobStep && jobStep.billableBinWeight) ? jobStep.billableBinWeight : '0';
                    let weighChitRemarks = (jobStep && jobStep.weightChitRemarks) ? jobStep.weightChitRemarks : '';
                    let weighChitWeighBy = (jobStep && jobStep.binWeighBy) ? jobStep.binWeighBy : '';
                    let weightChitDateTime = (jobStep && jobStep.weightChitDateTime) ? jobStep.weightChitDateTime : null;
                    let weightChitOutDateTime = (jobStep && jobStep.weightChitOutDateTime) ? jobStep.weightChitOutDateTime : null;
                    // let weighChitTime = (jobStep && jobStep.weightChitDateTime) ? jobStep.weightChitDateTime : new Date();
                    let jobStepWeighChitCustomFields = (jobStep && jobStep.jobStepWeighChitCustomFields && jobStep.jobStepWeighChitCustomFields.length > 0) ? jobStep.jobStepWeighChitCustomFields : [];
  
                    let jobTemplateType = (steps && steps.length > 0) ? steps[0].stepType : null;
                    let vehicleId = (res && res.vehicleId) ? res.vehicleId : null;
                    let serviceItemId = null;
                    let binTypeId = null;
                    
                    let step1 = steps.filter(x => x.stepOrder === 1);
                    if (step1 && step1.length > 0) {
                      if (jobTemplateType === JobStepType.OnTheSpot) {
                        serviceItemId = step1[0].serviceItemId;
                        binTypeId = step1[0].binTypeId;
                      } else if (jobTemplateType === JobStepType.Pull) {
                        serviceItemId = step1[0].serviceItemId;
                        binTypeId = step1[0].binTypeId;
                      } else if (jobTemplateType === JobStepType.Out) {
                        serviceItemId = step1[0].serviceItemId;
                        binTypeId = step1[0].binTypeId;
                      }
                    }
                    let step2 = steps.filter(x => x.stepOrder === 2);
                    if (step2 && step2.length > 0) {
                      if (jobTemplateType === JobStepType.Exchange) {
                        serviceItemId = step2[0].serviceItemId;
                        binTypeId = step2[0].binTypeId;
                      }
                    }

                    let dataParamsWeighChit = {
                      id: 0,
                      vehicleId: vehicleId,
                      serviceItemId: serviceItemId,
                      binTypeId: binTypeId,
                      row: {
                        jobId: jobId,
                        jobStepId: jobStepId,
                        statusName: statusName,
                        jobNumber: jobNumber,
  
                        weighChitTicketNumber: weighChitTicketNumber,
                        weighChitGrossWeight: weighChitGrossWeight,
                        weighChitTareWeight: weighChitTareWeight,
                        weighChitNettWeight: weighChitNettWeight,
                        weighChitNonBillableBinWeight: weighChitNonBillableBinWeight,
                        weighChitBillableWeight: weighChitBillableWeight,
                        weighChitRemarks: weighChitRemarks,
                        weighChitWeighBy: weighChitWeighBy,
                        weightChitDateTime: weightChitDateTime,
                        weightChitOutDateTime: weightChitOutDateTime,
                        // weighChitTime: weighChitTime,
                        jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
                        uom: this.dispatchUser.defaultUoM,
                      },
                    };
                    Utils.setDrawerData(this.formDrawerWeighChit, dataParamsWeighChit);
                    Utils.showDrawer(this.formDrawerWeighChit);
                  });
                }}
              >
                <WeighChitIcon
                  style={{
                    width: '18px',
                  }}
                />
              </IconButtonTable>}

              {
                row.isActive
                ?
                  (!row.downloadInternalNoteIsLoading)
                  ?
                  <IconButtonTable
                    title={'Internal Note'}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      
                      row.downloadInternalNoteIsLoading = true;
                      obj.refrsh();
      
                      this.callDownloadInternalNoteIsLoadingApi(row.jobId, (response) => {
                        if(response){
                          let byteArray = Utils.getUint8Array(response);
        
                          const url = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/pdf'}));
                          const link = document.createElement('a');
                          link.href = url;
                          link.setAttribute('download', 'Internal note - ' + row.jobNumber + ((row.customer && row.customer.billingName && row.customer.billingName !== '') ? ' ' + row.customer.billingName : '') + '.pdf');
                          document.body.appendChild(link);
                          link.click();
                          link.remove();
                        }
        
                        row.downloadInternalNoteIsLoading = false;
                        obj.refrsh();
                      });
                    }}
                  >
                    <i className={'fas fa-print ps-1'}></i>
                  </IconButtonTable>
                  :
                  <span style={{ paddingLeft: '11px' }}><Spinner animation="border" /></span>
                :
                null
              }

            </div>
        }
      
        this.customerNameTemplate = (id, row, col, obj, index) => {
    
        return <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        }}>
            <div className={'custom-cell-line-3'}>
        
            {(row.customerNameDisplay && row.customerNameDisplay !== '') ? row.customerNameDisplay : '-'}
            </div>
            <div>
            {(row.error) && <Tooltip title={row.error}>
            <NotifIcon style={{
                width: '18px',
                marginLeft: '5px',
                fill:'#EF476F',
                opacity: '0.6',
            }} />
            </Tooltip>}
            </div>
            <div>
      

            {(row.recurringJobId) && <Tooltip title={'Recurring Job'}>
            <RepeatIcon style={{
                width: '18px',
                marginLeft: '5px',
                fill: '#484848',
                opacity: '0.6',
            }} />
            </Tooltip>}
         
            </div>
          
        </div>
        };
        this.customerNameExportTemplate = (row) => {
        return (row.customerNameDisplay && row.customerNameDisplay !== '') ? row.customerNameDisplay : ''
        };
    
        this.billingNameTemplate = (id, row, col, obj, index) => {
    
        return (row.customer.billingName &&row.customer.billingName !== '') ? row.customer.billingName : '-'
        
        };
        this.billingNameExportTemplate = (row) => {
        return (row.customer.billingName && row.customer.billingName !== '') ? row.customer.billingName : ''
        };
        this.billingNameExportCSVTemplate = (row) => {
        return (row.customer.billingName && row.customer.billingName !== '') ? row.customer.billingName : ''
        };
    
        this.billingProfileNameTemplate = (id, row, col, obj, index) => {
    
        return (row.billingProfileName &&row.billingProfileName !== '') ? row.billingProfileName : '-'
        
        };
        this.billingProfileNameExportTemplate = (row) => {
        return (row.billingProfileName && row.billingProfileName !== '') ? row.billingProfileName : ''
        };
        
        this.jobTemplateNameTemplate = (id, row, col, obj, index) => {
          let jobTemplateName = (row.jobTemplateName && row.jobTemplateName !== '') ? row.jobTemplateName : '';
          let parentJobId = (row.parentJobId) ? row.parentJobId : null;
          let parentJobRelation = (row.parentJobRelation == ParentJobRelation.FollowUp) ? true : false;
          let parentJob = (row.parentJob) ? row.parentJob : null;
          let jobId = (parentJob && parentJob.jobId !== '') ? parentJob.jobId : '';
          let jobNumber = (parentJob && parentJob.jobNumber !== '') ? parentJob.jobNumber : '';

          return <div>
            {
            (parentJobId && parentJobRelation)
            ?
            <CustomPopover title={<div className={'p-2'}>
              <a target={'_blank'} rel="noreferrer" href={(row?.version == 1) ? '/v5/job-form-beta?id=' + jobId : '/jobs-form?id=' + jobId}
                className={'link-primary-underline'}
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();
                }}
              >{jobNumber}</a>
            </div>}>
              <div className={'d-flex align-items-center'}>
                <i className={'material-icons'}>assignment_return</i>
                <span className={'ps-2'}>{jobTemplateName}</span>
              </div>
            </CustomPopover>
            :
            jobTemplateName
            }
          </div>
        };
        this.jobTemplateNameExportTemplate = (row) => {
          return (row.jobTemplateName && row.jobTemplateName !== '') ? row.jobTemplateName : ''
        };
    
        this.customerTermsTemplate = (id, row, col, obj, index) => {   
        
        return (row.customerPaymentTerms) ? row.customerPaymentTerms : '';
        };
        this.customerTermsExportTemplate = (row , index) => {
        return (row.customerPaymentTerms) ? row.customerPaymentTerms: '';
        };

        this.customerCategoryName = (id, row, col, obj, index) => {   
         
        return (row.customer.customerCategoryName) ? row.customer.customerCategoryName : '';
        };
        this.customerCategoryNameExportTemplate = (row , index) => {
        return (row.customer.customerCategoryName) ? row.customer.customerCategoryName: '';
        };

        this.contactPersonOneTemplate = (id, row, col, obj, index) => {
          let arr = [];

          let contactPersonOne = (row.contactPersonOne && row.contactPersonOne !== '') ? row.contactPersonOne : '';
          let contactNumberOne = (row.contactNumberOne && row.contactNumberOne !== '') ? row.contactNumberOne : '';

          if (contactPersonOne !== '') arr.push(contactPersonOne);
          if (contactNumberOne !== '') arr.push('(' + contactNumberOne +  ')');
        

          return arr.join(' : ');
        };
        this.contactPersonOneExportTemplate = (row) => {
          let arr = [];

          let contactPersonOne = (row.contactPersonOne && row.contactPersonOne !== '') ? row.contactPersonOne : '';
          let contactNumberOne = (row.contactNumberOne && row.contactNumberOne !== '') ? row.contactNumberOne : '';

          if (contactPersonOne !== '') arr.push(contactPersonOne);
          if (contactNumberOne !== '') arr.push('(' + contactNumberOne +  ')');

          return arr.join(' : ');
        };
        this.contactPersonOneExportCSVTemplate = (row) => {
          let arr = [];

          let contactPersonOne = (row.contactPersonOne && row.contactPersonOne !== '') ? row.contactPersonOne : '';
          let contactNumberOne = (row.contactNumberOne && row.contactNumberOne !== '') ? row.contactNumberOne : '';

          if (contactPersonOne !== '') arr.push(contactPersonOne);
          if (contactNumberOne !== '') arr.push('(' + contactNumberOne +  ')');

          return arr.join(' : ');
        };

        this.contactPersonTwoTemplate = (id, row, col, obj, index) => {
          let arr = [];

          let contactPersonTwo = (row.contactPersonTwo && row.contactPersonTwo !== '') ? row.contactPersonTwo : '';
          let contactNumberTwo = (row.contactNumberTwo && row.contactNumberTwo !== '') ? row.contactNumberTwo : '';

          if (contactPersonTwo !== '') arr.push(contactPersonTwo);
          if (contactNumberTwo !== '') arr.push('(' + contactNumberTwo +  ')');

          return arr.join(' : ');
        };
        this.contactPersonExportTwoTemplate = (row) => {
          let arr = [];

          let contactPersonTwo = (row.contactPersonTwo && row.contactPersonTwo !== '') ? row.contactPersonTwo : '';
          let contactNumberTwo = (row.contactNumberTwo && row.contactNumberTwo !== '') ? row.contactNumberTwo : '';

          if (contactPersonTwo !== '') arr.push(contactPersonTwo);
          if (contactNumberTwo !== '') arr.push('(' + contactNumberTwo +  ')');

          return arr.join(' : ');
        }
        this.contactPersonExportTwoCSVTemplate = (row) => {
          let arr = [];

          let contactPersonTwo = (row.contactPersonTwo && row.contactPersonTwo !== '') ? row.contactPersonTwo : '';
          let contactNumberTwo = (row.contactNumberTwo && row.contactNumberTwo !== '') ? row.contactNumberTwo : '';

          if (contactPersonTwo !== '') arr.push(contactPersonTwo);
          if (contactNumberTwo !== '') arr.push('(' + contactNumberTwo +  ')');

          return arr.join(' : ');
        }

        this.jobTimeSpecificTemplate = (id, row, col, obj, index) => {
        return row.jobTimeSpecificDisplay;
        };
        this.jobTimeSpecificExportTemplate = (row) => {
          return row.jobTimeSpecificDisplay;
        };
        this.jobTimeSpecificExportCSVTemplate = (row) => {
          return row.jobTimeSpecificDisplay;
        };
    
        this.jobDateStartTemplate = (id, row, col, obj, index) => {
        return (row.actualJobTimeSpecificDisplay) ? Utils.toUcase(row.actualJobTimeSpecificDisplay) : '';
        };
        this.jobDateStartExportTemplate = (row) => {
        return (row.actualJobTimeSpecificDisplay) ? Utils.toUcase(row.actualJobTimeSpecificDisplay) : '';
        };
        this.jobDateStartExportCSVTemplate = (row) => {
        return (row.actualJobTimeSpecificDisplay) ? Utils.toUcase(row.actualJobTimeSpecificDisplay) : '';
        };
    
        this.jobDateInProgressTemplate = (id, row, col, obj, index) => {
        return (row.inProgressTimeDisplay) ? Utils.toUcase(row.inProgressTimeDisplay) : '';
        };
        this.jobDateInProgressExportTemplate = (row) => {
        return (row.inProgressTimeDisplay) ? Utils.toUcase(row.inProgressTimeDisplay) : '';
        };
        this.jobDateInProgressExportCSVTemplate = (row) => {
        return (row.inProgressTimeDisplay) ? Utils.toUcase(row.inProgressTimeDisplay) : '';
        };
    
        this.jobDateCompleteTemplate = (id, row, col, obj, index) => {
        return (row.jobAttemptCompletedDateDisplay) ? Utils.splitToDateTime(Utils.toUcase(row.jobAttemptCompletedDateDisplay)).date : '';
        };
        this.jobDateCompleteExportTemplate = (row) => {
        return (row.jobAttemptCompletedDateDisplay) ? Utils.splitToDateTime(Utils.toUcase(row.jobAttemptCompletedDateDisplay)).date : '';
        };
        this.jobDateCompleteExportCSVTemplate = (row) => {
        return (row.jobAttemptCompletedDateDisplay) ? Utils.splitToDateTime(Utils.toUcase(row.jobAttemptCompletedDateDisplay)).date : '';
        };

        this.jobTimeTemplate = (id, row, col, obj, index) => {
          return (row.jobAttemptCompletedDateDisplay) ? Utils.splitToDateTime(Utils.toUcase(row.jobAttemptCompletedDateDisplay)).time : '';
   
        };
        this.jobTimeExportTemplate = (row) => {
          return (row.jobAttemptCompletedDateDisplay) ? Utils.splitToDateTime(Utils.toUcase(row.jobAttemptCompletedDateDisplay)).time : '';
        };
        this.jobTimeExportCSVTemplate = (row) => {
          return (row.jobAttemptCompletedDateDisplay) ? Utils.splitToDateTime(Utils.toUcase(row.jobAttemptCompletedDateDisplay)).time : '';
        };
        
        this.weightChitDateTimeTemplate = (id, row, col, obj, index) => {
          return (row && row.weightChitDateTime) ? moment(row.weightChitDateTime).format(Utils.getDefaultDateTimeFormat()) : ''
   
        };
        this.weightChitDateTimeExportTemplate = (row) => {
          return ((row && row.weightChitDateTime) ? moment(row.weightChitDateTime).format(Utils.getDefaultDateTimeFormat()) : '')
        };
        this.weightChitDateTimeExportCSVTemplate = (row) => {
          return (row && row.weightChitDateTime) ? moment(row.weightChitDateTime).format(Utils.getDefaultDateTimeFormat()) : ''
        };
        
        this.weightChitOutDateTimeTemplate = (id, row, col, obj, index) => {
          return (row && row.weightChitOutDateTime) ? moment(row.weightChitOutDateTime).format(Utils.getDefaultDateTimeFormat()) : ''
   
        };
        this.weightChitOutDateTimeExportTemplate = (row) => {
          return ((row && row.weightChitOutDateTime) ? moment(row.weightChitOutDateTime).format(Utils.getDefaultDateTimeFormat()) : '')
        };
        this.weightChitOutDateTimeExportCSVTemplate = (row) => {
          return (row && row.weightChitOutDateTime) ? moment(row.weightChitOutDateTime).format(Utils.getDefaultDateTimeFormat()) : ''
        };


        this.billingStatusTemplate = (id, row, col, obj, index) => {
          return <StyledBilllingStatus status={row.billingStatus}>{row.billingStatus}</StyledBilllingStatus>
        };
        this.billingStatusExportTemplate = (row) => {
          return row.billingStatus;
        }
        this.billingStatusExportCSVTemplate = (row) => {
          return row.billingStatus;
        }

        this.isBillableTemplate = (id, row, col, obj, index) => {
          return (row.isBillable == true) ? 'Billable' : 'Non Billable';
   
        };
        this.isBillableExportTemplate = (row) => {
          return (row.isBillable == true) ? 'Billable' : 'Non Billable';
        };
        this.isBillableExportCSVTemplate = (row) => {
          return (row.isBillable == true) ? 'Billable' : 'Non Billable';
        };


        this.WasteInItemTemplate = (id, row, col, obj, index) => {
            let options = [];
            if(row && row.wasteTypesIn && row.wasteTypesIn.length > 0){
                options = row.wasteTypesIn.map((item, i) => {
                    return {
                        value: i,
                        label: item.wasteTypeName,
                        item: item,
                    }
                });
            }

            return <CustomDropdownMultiSelect
                className={'job-form-step-in'}
                trnasparantBg={true}
                valueField={'value'}
                labelField={'label'}
                values={options}
                options={options}
                disabled={true}
                optionRenderer={(obj) => {
                    return <span className={"react-dropdown-select-option custom-waste-type-list-item"}>
                        <span className={"react-dropdown-select-option-label"}>{obj.item.label}</span>
                    </span>
                }}
            />
        };
        this.WasteInItemExportTemplate = (row) => {
            if(row && row.wasteTypesIn && row.wasteTypesIn.length > 0){
            return row.wasteTypesIn.map(x => x.wasteTypeName).join(", ");
            }
        }
        
        this.binNumberOutTemplate = (id, row, col, obj, index) => {
          return <>
            <span className={'pe-2'}>{row.binNumberOut}</span>
            {row.binNumberOutIsUsingQr && <Tooltip title={'QR code is scanned'}><i style={{ fontSize: '16px' }} class="fa fa-qrcode"></i></Tooltip>}
            {row.binNumberOutIsUsingTextDetection && <Tooltip title={'QR code is scanned with text recognition'}><ScanTextIcon style={{ position: 'relative', top: '-2px', width: '18px', height: '18px' }} /></Tooltip>}
          </>
        };
        this.binNumberOutExportTemplate = (row) => {
            return row.binNumberOut + ' ' + (row.binNumberOutIsUsingQr ? '[QR]' : row.binNumberOutIsUsingTextDetection ? '[TR]' : '');
        }

        this.binNumberInTemplate = (id, row, col, obj, index) => {
          return <>
            <span className={'pe-2'}>{row.binNumberIn}</span>
            {row.binNumberInIsUsingQr && <Tooltip title={'QR code is scanned'}><i style={{ fontSize: '16px' }} class="fa fa-qrcode"></i></Tooltip>}
            {row.binNumberInIsUsingTextDetection && <Tooltip title={'QR code is scanned with text recognition'}><ScanTextIcon style={{ position: 'relative', top: '-2px', width: '18px', height: '18px' }} /></Tooltip>}
          </>
        };
        this.binNumberInExportTemplate = (row) => {
          return row.binNumberIn + ' ' + (row.binNumberInIsUsingQr ? '[QR]' : row.binNumberInIsUsingTextDetection ? '[TR]' : '');
        }
      
        this.siteRemarksTemplate = (id, row, col, obj, index) => {
        let siteRemarks = row.siteRemarks;
        
        let jobStepId = null;
        if(row.steps && row.steps.length > 0){
            for(let i = 0; i< row.steps.length; i++){
            let item = row.steps[i];
    
              if(row.jobTypeName == 'Out'){
                if(i === 0){
                  jobStepId = item.jobStepId;
                  break;
                }
              } else {
                if(item.customerSiteId){
                  jobStepId = item.jobStepId;
                  break;
                }
              }
            }
            
            if(jobStepId){
            return <div>
                <CustomCheckInput
                value={(siteRemarks !== '') ? siteRemarks : ''}
                placeholder={"Site Remarks"}
                disabled={!row.isActive || this.state.isLoadingSiteRemarks}
                multiline={true}
                rowsMax={100}
                onMouseDown={(e) => {
                    this.setState({
                    isMenuSubItemsOpen: true,
                    });
                }}
                onCheck={(value) => {
                    row['siteRemarks'] = value;
    
                    this.setState({
                    isMenuSubItemsOpen: false,
                    }, () => {
                    this.callUpdateSiteRemarksApi({
                        jobId: row.jobId,
                        jobStepId: jobStepId,
                        siteRemarks: row['siteRemarks'],
                    }, () => {
                      this.setState({
                        isLoadingSiteRemarks: false,
                      });
                    });
                    });
                }}
                onPressEnter={(value) => {
                    row['siteRemarks'] = value;
    
                    this.setState({
                    isMenuSubItemsOpen: false,
                    }, () => {
                    this.callUpdateSiteRemarksApi({
                        jobId: row.jobId,
                        jobStepId: jobStepId,
                        siteRemarks: row['siteRemarks'],
                    }, () => {
                      this.setState({
                        isLoadingSiteRemarks: false,
                      });
                    });
                    });
                }}
                />
            </div>
            } else {
            return '';
            }
        }
        };
        this.siteRemarksExportTemplate = (row) => {
        let siteRemarks = row.siteRemarks;
        
        let jobStepId = null;
        if(row.steps && row.steps.length > 0){
            for(let i = 0; i< row.steps.length; i++){
            let item = row.steps[i];
    
              if(row.jobTypeName == 'Out'){
                if(i === 0){
                  jobStepId = item.jobStepId;
                  break;
                }
              } else {
                if(item.customerSiteId){
                  jobStepId = item.jobStepId;
                  break;
                }
              }
            }
            
            if(jobStepId){
            return siteRemarks;
            }
        }
        }
    
        this.remarksTemplate = (id, row, col, obj, index) => {
        return <div>
            <CustomCheckInput
            value={(row.remarks !== '') ? row.remarks : ''}
            placeholder={"Remarks"}
            disabled={!row.isActive || this.state.isLoadingRemarks}
            multiline={true}
            rowsMax={100}
            onMouseDown={(e) => {
                this.setState({
                isMenuSubItemsOpen: true,
                });
            }}
            onCheck={(value) => {
                row['remarks'] = value;
                obj.refrsh();
    
                this.setState({
                isMenuSubItemsOpen: false,
                }, () => {
                this.callUpdateRemarksApi({
                    jobId: row.jobId,
                    remarks: row['remarks'],
                }, () => {
                  this.setState({
                    isLoadingRemarks: false,
                  });
                });
                });
            }}
            onPressEnter={(value) => {
                row['remarks'] = value;
                obj.refrsh();
    
                this.setState({
                isMenuSubItemsOpen: false,
                }, () => {
                this.callUpdateRemarksApi({
                    jobId: row.jobId,
                    remarks: row['remarks'],
                }, () => {
                  this.setState({
                    isLoadingRemarks: false,
                  });
                });
                });
            }}
            />
        </div>
        };
        this.remarksExportTemplate = (row) => {
        return row.remarks;
        }
    
        this.priorityTemplate = (id, row, col, obj, index) => {
          return <div>
            <CustomCheckInput
              // type={'number'}
              inputProps={{
                pattern: Utils.number2Decimal()
              }}
              value={(Utils.isNumeric(row.priority)) ? row.priority : ''}
              placeholder={"Priority"}
              disabled={!row.isActive || this.state.isLoadingPriority}
              multiline={false}
              rowsMax={100}
              onMouseDown={(e) => {
                this.setState({
                  isMenuSubItemsOpen: true,
                });
              }}
              onCheck={(value) => {
                row['priority'] = value;

                this.setState({
                  isMenuSubItemsOpen: false,
                }, () => {
                  this.callUpdatePriorityApi({
                    jobId: row.jobId,
                    priority: row['priority'],
                  }, () => {
                    this.setState({
                      isLoadingPriority: false,
                    });
                  });
                });
              }}
              onPressEnter={(value) => {
                row['priority'] = value;

                this.setState({
                  isMenuSubItemsOpen: false,
                }, () => {
                  this.callUpdatePriorityApi({
                    jobId: row.jobId,
                    priority: row['priority'],
                  }, () => {
                    this.setState({
                      isLoadingPriority: false,
                    });
                  });
                });
              }}
            />
          </div>
        };
        this.priorityExportTemplate = (row) => {
          return row.priority;
        }
        
        this.vehicleTemplate = (id, row, col, obj, index) => {
        row.initialVehicleNameList = this.state.initialVehicleNameList;
        
        if(row.statusName === null || row.statusName === 'undefined'){
            row.statusName = '';
        }
        let status = (row.statusName && row.statusName !== '') ? row.statusName.toLowerCase() : '';
        let disabled = false;
        if(status === 'completed'){
            // disabled = true;
        } else if(status === 'cancelled'){
            disabled = true;
        } else if(status === 'failed'){
            disabled = true;
        }
    
        return <div>
            {
            (row.statusVehicleDispatchLoading)
            ?
            <div style={{ textAlign: 'center' }}>
              <Spinner animation="border" />
            </div>
            :
            <MenuSubItems
                disabled={!row.isActive || disabled}
                text={row.vehicleName}
                items={row.initialVehicleNameList}
                isSearchFieldItem={true}
                callApi={this.callSearchVehiclesApi}
                callApiResult={(result) => {
                  this.setState({
                      initialVehicleNameList: result
                  });
                }}
                isOpen={(isOpen) => {
                  this.setState({
                    isMenuSubItemsOpen: isOpen,
                  }, () => {
                    obj.setDisableRowClick(isOpen);
                  });
                }}
                onClick={(item, subItem) => {
                let newRows = this.state.rows;
                row.statusVehicleDispatchLoading = true;
                newRows[index] = row;
                this.setState({
                    rows: newRows
                }, () => {
                    this.callDispatchApi({
                    jobId: row.jobId,
                    jobStatusId: row.jobStatusId,
                    vehicleId: item.item.vehicleId,
                    driverId: (item.item.defaultDriver && item.item.defaultDriver.driverId) ? item.item.defaultDriver.driverId : null,
                    jobAttemptCompletedDate: (row.jobAttemptCompletedDate) ? row.jobAttemptCompletedDate : null,
                    }, row, (data, isError) => {
                      if(isError){
                        let currentRow = this.state.rows.filter(x => x.jobId === row.jobId);
                        if(currentRow && currentRow.length > 0){
                          currentRow[0]['statusDriverDispatchLoading'] = false;
                          currentRow[0]['statusVehicleDispatchLoading'] = false;
                          currentRow[0]['statusDispatchLoading'] = false;
                  
                          this.tableRef.current.update(currentRow[0], (updatedRow, newArray) => {
                            this.setState({
                              rows: newArray
                            });
                          });
                        }
                      }

                    row.statusVehicleDispatchLoading = false;
                    obj.refrsh();
                    this.callStatApi(false);
                    });
                });
                }}
            />
            }
        </div>;
        };
        this.vehicleExportTemplate = (row) => {
        return row.vehicleName;
        }
    
        this.driverTemplate = (id, row, col, obj, index) => {
        row.initialDriverNameList = this.state.initialDriverNameList;
        
        let unassignedStatusItem = this.findStatus('unassigned');
        let assignedStatusItem = this.findStatus('assigned');
        if(row.jobStatusId === unassignedStatusItem.jobStatusId){
            row.jobStatusId = assignedStatusItem.jobStatusId;
        }
        
        if(row.statusName === null || row.statusName === 'undefined'){
            row.statusName = '';
        }
        let status = (row.statusName && row.statusName !== '') ? row.statusName.toLowerCase() : '';
        
        let disabled = false;
        if(status === 'completed'){
            disabled = true;
        } else if(status === 'cancelled'){
            disabled = true;
        } else if(status === 'failed'){
            disabled = true;
        } else if(status === 'started'){
          disabled = true;
        } else if(status === 'in progress'){
          disabled = true;
        }
    
        return <div>
            {
            (row.statusDriverDispatchLoading)
            ?
            <div style={{ textAlign: 'center' }}>
              <Spinner animation="border" />
            </div>
            :
            <MenuSubItems
                disabled={!row.isActive || disabled}
                text={row.driverName}
                items={row.initialDriverNameList}
                isSearchFieldItem={true}
                callApi={this.callSearchDriversApi}
                callApiResult={(result) => {
                  this.setState({
                    initialDriverNameList: result
                  });
                }}
                isOpen={(isOpen) => {
                  this.setState({
                    isMenuSubItemsOpen: isOpen,
                  }, () => {
                    obj.setDisableRowClick(isOpen);
                  });
                }}
                onClick={(item, subItem) => {
                  let newRows = this.state.rows;
                  row.statusDriverDispatchLoading = true;
                  newRows[index] = row;
                  this.setState({
                    rows: newRows
                  }, () => {
                      this.callDispatchApi({
                      jobId: row.jobId,
                      jobStatusId: row.jobStatusId,
                      driverId: item.item.driverId,
                      vehicleId: (item.item.defaultVehicle && item.item.defaultVehicle.vehicleId) ? item.item.defaultVehicle.vehicleId : null,
                      jobAttemptCompletedDate: (row.jobAttemptCompletedDate) ? row.jobAttemptCompletedDate : null,
                      }, row, (data, isError) => {
                        if(isError){
                          let currentRow = this.state.rows.filter(x => x.jobId === row.jobId);
                          if(currentRow && currentRow.length > 0){
                            currentRow[0]['statusDriverDispatchLoading'] = false;
                            currentRow[0]['statusVehicleDispatchLoading'] = false;
                            currentRow[0]['statusDispatchLoading'] = false;
                    
                            this.tableRef.current.update(currentRow[0], (updatedRow, newArray) => {
                              this.setState({
                                rows: newArray
                              });
                            });
                          }
                        }

                      row.statusDriverDispatchLoading = false;
                      obj.refrsh();
                      this.callStatApi(false);
                      });
                  });
                }}
            />
            }
        </div>;
        };
        this.driverExportTemplate = (row) => {
        return row.driverName;
        }
        
        this.totalDistanceTemplate = (id, row, col, obj, index) => {
        return row.totalDistance ? Utils.mToKm(row.totalDistance) : '';
        }
        this.totalDistanceExportTemplate = (row) => {
          return row.totalDistance ? Utils.mToKm(row.totalDistance) : '';
        }
    
        this.statusTemplate = (id, row, col, obj, index) => {
        row.driverList = this.state.initialDriverList;
    
        let jobTemplateType = Utils.getJobTypeInfoByText(row.jobTypeName);
        let canInProgress = Utils.canInProgressStatus(jobTemplateType);
        
        if(row.statusName === null || row.statusName === 'undefined'){
            row.statusName = '';
        }

        let status = (row.statusName && row.statusName !== '') ? row.statusName.toLowerCase() : '';
        let disabled = false;
        if(status === 'completed'){
            // disabled = true;
        } else if(status === 'cancelled'){
            disabled = true;
        } else if(status === 'failed'){
            disabled = true;
        }

        let badgeClassName = '';
        if(status === 'unassigned'){
          badgeClassName = 'status-unassigned-background'
        } else if(status === 'assigned'){
          badgeClassName = 'status-assigned-background'
        } else if(status === 'dispatched'){
          badgeClassName = 'status-dispatched-background'
        } else if(status === 'acknowledged'){
          badgeClassName = 'status-acknowledged-background'
        } else if(status === 'started'){
          badgeClassName = 'status-started-background'
        } else if(status === 'in progress'){
          badgeClassName = 'status-inprogress-background'
        } else if(status === 'completed'){
          badgeClassName = 'status-completed-background'
        } else if(status === 'cancelled'){
          badgeClassName = 'status-unsuccessful-background'
        } else if(status === 'failed'){
          badgeClassName = 'status-unsuccessful-background'
        } else if(status === 'rejected'){
          badgeClassName = 'status-unsuccessful-background'
        } else if(status === 'deleted'){
          badgeClassName = 'status-unassigned-color'
        } else if(status === 'draft'){
          badgeClassName = 'status-draft-background'
        }
    
        row.statusList = [
            {
            text: 'Unassigned',
            value: 'unassigned',
            disabled: () => {
                if(status === 'unassigned'){
                return true;
                } else if(status === 'started'){
                return true;
                } else if(status === 'in progress'){
                return true;
                } else if(status === 'completed'){
                return true;
                } else if(status === 'cancelled'){
                return true;
                } else if(status === 'failed'){
                return true;
                } else {
                return false;
                }
            },
            },
            {
            text: 'Assigned',
            value: 'assigned',
            items: row.driverList,
            disabled: () => {
                if(status === 'started'){
                return true;
                } else if(status === 'in progress'){
                return true;
                } else if(status === 'completed'){
                return true;
                } else if(status === 'cancelled'){
                return true;
                } else if(status === 'failed'){
                return true;
                } else {
                return false;
                }
            },
            },
            {
            text: 'Dispatched',
            value: 'dispatched',
            disabled: () => {
                if(row.driverId === null || row.driverId === 0){
                return true;
                } else {
                if(status === 'unassigned'){
                    return true;
                } else if(status === 'dispatched'){
                    return true;
                } else if(status === 'started'){
                    return true;
                } else if(status === 'in progress'){
                    return true;
                } else if(status === 'completed'){
                    return true;
                } else if(status === 'cancelled'){
                    return true;
                } else if(status === 'failed'){
                    return true;
                } else {
                    return false;
                }
                }
            },
            },
            {
            text: 'Acknowledged',
            value: 'acknowledged',
            disabled: () => {
                if(status === 'unassigned'){
                return true;
                } else if(status === 'acknowledged'){
                return true;
                } else if(status === 'started'){
                return true;
                } else if(status === 'in progress'){
                return true;
                } else if(status === 'completed'){
                return true;
                } else if(status === 'cancelled'){
                return true;
                } else if(status === 'failed'){
                return true;
                } else if(status === 'draft'){
                return true;
                } else {
                return false;
                }
            },
            },
            {
            text: 'Started',
            value: 'started',
            disabled: () => {
                if(status === 'unassigned'){
                return true;
                } else if(status === 'started'){
                return true;
                } else if(status === 'in progress'){
                return true;
                } else if(status === 'completed'){
                return true;
                } else if(status === 'cancelled'){
                return true;
                } else if(status === 'failed'){
                return true;
                } else if(status === 'draft'){
                return true;
                } else {
                return false;
                }
            },
            },
            {
            text: 'In Progress',
            value: 'in progress',
            disabled: () => {
                if(status === 'unassigned'){
                return true;
                } else if(status === 'in progress'){
                return true;
                } else if(status === 'completed'){
                return true;
                } else if(status === 'cancelled'){
                return true;
                } else if(status === 'failed'){
                return true;
                } else if(status === 'draft'){
                return true;
                } else {
                return false;
                }
            },
            },
            {
            text: 'Completed',
            value: 'completed',
            disabled: () => {
                if(status === 'unassigned'){
                return true;
                } else if(status === 'assigned'){
                return true;
                // } else if(status === 'dispatched'){
                // return true;
                // } else if(status === 'acknowledged'){
                // return true;
                } else if(status === 'completed'){
                return true;
                } else if(status === 'cancelled'){
                return true;
                } else if(status === 'failed'){
                return true;
                } else if(status === 'draft'){
                return true;
                } else {
                return false;
                }
            },
            },
            {
            text: <span className={'text-red'}>Cancelled</span>,
            value: 'cancelled',
            disabled: () => {
                if(status === 'unassigned'){
                return true;
                } else if(status === 'cancelled'){
                return true;
                // } else if(status === 'completed'){
                //   return true;
                } else if(status === 'failed'){
                return true;
                } else {
                return false;
                }
            },
            },
            {
            text: <span className={'text-red'}>Failed</span>,
            value: 'failed',
            disabled: () => {
                if(status === 'unassigned'){
                return true;
                } else if(status === 'failed'){
                return true;
                // } else if(status === 'completed'){
                // return true;
                } else if(status === 'cancelled'){
                return true;
                } else {
                return false;
                }
            },
            },
        ];
        
        if(!canInProgress){
          row.statusList = row.statusList.map(x => {
            if(x.value == 'in progress'){
              x.disabled = true;
              return x;
            } else {
              return x;
            }
          });
        }


        return <div>
            {
            (row.statusDispatchLoading)
            ?
            <div style={{ textAlign: 'center' }}>
              <Spinner animation="border" />
            </div>
            :
            <MenuSubItems
                disabled={!row.isActive || disabled}
                text={<Badge pill bg="light" className={'as-billing-statuses ' + badgeClassName}>{row.statusName}</Badge>}
                items={row.statusList}
                isSearchFieldSubItem={true}
                callApi={this.callSearchDriversApi}
                callApiResult={(result) => {
                this.setState({
                    initialDriverList: result
                });
                }}
                isOpen={(isOpen) => {
                  this.setState({
                    isMenuSubItemsOpen: isOpen,
                  }, () => {
                    obj.setDisableRowClick(isOpen);
                  });
                }}
                onClick={(item, subItem) => {
                let newRows = this.state.rows;
                let statusItem = this.findStatus(item.value);
    
                if(subItem){
                    if(statusItem){
                    row.statusDispatchLoading = true;
        
                    newRows[index] = row;
                    this.setState({
                        rows: newRows
                    }, () => {
                        this.callDispatchApi({
                        jobId: row.jobId,
                        jobStatusId: statusItem.jobStatusId,
                        driverId: subItem.item.driverId,
                        vehicleId: (subItem.item.defaultVehicle && subItem.item.defaultVehicle.vehicleId) ? subItem.item.defaultVehicle.vehicleId : null,
                        jobAttemptCompletedDate: (row.jobAttemptCompletedDate) ? row.jobAttemptCompletedDate : null,
                        }, row, (newRow, isError) => {
                          if(isError){
                            let currentRow = this.state.rows.filter(x => x.jobId === row.jobId);
                            if(currentRow && currentRow.length > 0){
                              currentRow[0]['statusDriverDispatchLoading'] = false;
                              currentRow[0]['statusVehicleDispatchLoading'] = false;
                              currentRow[0]['statusDispatchLoading'] = false;
                      
                              this.tableRef.current.update(currentRow[0], (updatedRow, newArray) => {
                                this.setState({
                                  rows: newArray
                                });
                              });
                            }
                          }

                          if(newRow){
                            newRows[index].driverId = newRow.driverId;
                            newRows[index].driverName = newRow.driverName;
                            newRows[index].vehicleId = newRow.vehicleId;
                            newRows[index].vehicleName = newRow.vehicleName;
                            newRows[index].jobStatusId = newRow.jobStatusId;
                            newRows[index].statusName = newRow.statusName;
                            newRows[index].jobNumber = newRow.jobNumber;
                            newRows[index].statusDispatchLoading = false;
                          }

                        this.setState({
                            rows: newRows
                        }, () => {
                            obj.refrsh();
                            this.callStatApi(false);
                        });


                        });
                    });
                    }
                } else {
                    if(statusItem){
                    let dispatchData = {
                        jobId: row.jobId,
                        jobStatusId: statusItem.jobStatusId,
                    };
    
                    if (item.value === 'failed') {
                      this.setState({
                        statusDispatchLoading: true,
                        isFailedDialog: true,
                        failedRow: dispatchData,
                        failedMessage: 'Are you sure?',
                      }, () => {
                        this.setState({
                          statusDispatchLoading: false,
                        });
                      });
                    } else if (item.value === 'cancelled') {
                        this.setState({
                          statusDispatchLoading: true,
                          isCancelDialog: true,
                          cancelRow: dispatchData,
                          cancelMessage: 'Are you sure you want to cancel this job?',
                        }, () => {
                          this.setState({
                            statusDispatchLoading: false,
                          });
                        });
                    } else {
                        if(item.value === 'completed'){
                        dispatchData['jobAttemptCompletedDate'] = new Date();
                        }
                        
                        row.statusDispatchLoading = true;
                        newRows[index] = row;
                        this.setState({
                        rows: newRows
                        }, () => {
                        this.callDispatchApi(dispatchData, row, (newRow, isError) => {
                          if(isError){
                            let currentRow = this.state.rows.filter(x => x.jobId === row.jobId);
                            if(currentRow && currentRow.length > 0){
                              currentRow[0]['statusDriverDispatchLoading'] = false;
                              currentRow[0]['statusVehicleDispatchLoading'] = false;
                              currentRow[0]['statusDispatchLoading'] = false;
                      
                              this.tableRef.current.update(currentRow[0], (updatedRow, newArray) => {
                                this.setState({
                                  rows: newArray
                                });
                              });
                            }
                          }

                            if(newRow){
                            newRows[index].driverId = newRow.driverId;
                            newRows[index].driverName = newRow.driverName;
                            newRows[index].vehicleId = newRow.vehicleId;
                            newRows[index].vehicleName = newRow.vehicleName;
                            newRows[index].jobStatusId = newRow.jobStatusId;
                            newRows[index].statusName = newRow.statusName;
                            newRows[index].jobNumber = newRow.jobNumber;
                            newRows[index].statusDispatchLoading = false;
                            }
                            this.setState({
                            rows: newRows
                            }, () => {
                            obj.refrsh();
                            this.callStatApi(false);

                            try {
                              this.tableRef.current.update(newRow, (updatedRow, newArray) => {
                                this.setState({
                                    rows: newArray
                                }, () => {
                                  obj.refrsh();
                                });
                              });
                            } catch(e){}
                            });
                        });
                        });
                    }
                    }
                }
                }}
            />
            }
        </div>;
        };
        this.statusExportTemplate = (row) => {
        return row.statusName;
        }

        this.amountToCollectTemplate = (id, row, col, obj, index) => {
          return row.amountToCollect ? Utils.getCurrency() + Utils.formatNumber(row.amountToCollect) : '';
        }
        this.amountToCollectExportTemplate = (row) => {
          return row.amountToCollect ? Utils.getCurrency() + Utils.formatNumber(row.amountToCollect) : '';
        }

        this.collectedAmountTemplate = (id, row, col, obj, index) => {
          return row.collectedAmount ? Utils.getCurrency() + Utils.formatNumber(row.collectedAmount) : '';
        }
        this.collectedAmountExportTemplate = (row) => {
          return row.collectedAmount ? Utils.getCurrency() + Utils.formatNumber(row.collectedAmount) : '';
        }

        this.binWeightTemplate = (id, row, col, obj, index) => {
          // return Utils.formatNumber(row.binWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.binWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }
        this.binWeightExportTemplate = (row) => {
          // return Utils.formatNumber(row.binWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.binWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }
        
        this.binOutWeightTemplate = (id, row, col, obj, index) => {
          // return Utils.formatNumber(row.binOutWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.binOutWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }
        this.binOutWeightExportTemplate = (row) => {
          // return Utils.formatNumber(row.binOutWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.binOutWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }

        this.billableBinWeightTemplate = (id, row, col, obj, index) => {
          // return Utils.formatNumber(row.billableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.billableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }
        this.billableBinWeightExportTemplate = (row) => {
          // return Utils.formatNumber(row.billableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.billableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }

        this.netBinWeightTemplate = (id, row, col, obj, index) => {
          // return Utils.formatNumber(row.netBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.netBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }
        this.netBinWeightExportTemplate = (row) => {
          // return Utils.formatNumber(row.netBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.netBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }

        this.nonBillableBinWeightTemplate = (id, row, col, obj, index) => {
          // return Utils.formatNumber(row.nonBillableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.nonBillableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }
        this.nonBillableBinWeightExportTemplate = (row) => {
          // return Utils.formatNumber(row.nonBillableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.nonBillableBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }

        this.tareBinWeightTemplate = (id, row, col, obj, index) => {
          // return Utils.formatNumber(row.tareBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.tareBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }
        this.tareBinWeightExportTemplate = (row) => {
          // return Utils.formatNumber(row.tareBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000')) + ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? ' kg' : ' tons');
          return Utils.formatNumber(row.tareBinWeight, ((this.dispatchUser.defaultUoM == ReportUOM.kg) ? '0,0' : '0,0.000'));
        }


        let columns = [
            { 
                id: 'jobId', 
                filterKeyParam: 'jobId', 
                name: 'jobId', 
                label: 'ID',
                responsiveLabel: '',
                visible: false
            },
            { 
                id: 'customerName',
                filterKeyParam: 'customerName',
                name: 'customerName',
                nestedOrderBy: 'customer',  
                label: 'CUSTOMER NAME',
                width: '150px',
                responsiveLabel: 'CUSTOMER NAME',
                orderable: true,
                template: this.customerNameTemplate,
                exportTemplate: this.customerNameExportTemplate
            },
                      { 
                id: 'customer.customerCategoryName', 
                filterKeyParam: 'customer.customerCategoryName', 
                name: 'customer.customerCategoryName', 
                label: 'CUSTOMER CATEGORY',
                width: '185px',
                responsiveLabel: 'CUSTOMER CATEGORY',
                orderable: true,
                template: this.customerCategoryName,
                exportTemplate: this.customerCategoryNameExportTemplate,
            },
            { 
                id: 'customer.billingName', 
                filterKeyParam: 'customer.billingName', 
                name: 'customer.billingName',
                label: 'BILLING NAME',
                width: '150px',
                responsiveLabel: 'BILLING NAME',
                orderable: true,
                template: this.billingNameTemplate,
                exportTemplate: this.billingNameExportTemplate,
                exportCSVTemplate: this.billingNameExportCSVTemplate,
            },
            { 
                id: 'billingProfileName', 
                filterKeyParam: 'billingProfileName', 
                name: 'billingProfileName', 
                label: 'BILLING BY',
                width: '150px',
                responsiveLabel: 'BILLING BY',
                orderable: true,
                template: this.billingProfileNameTemplate,
                exportTemplate: this.billingProfileNameExportTemplate
            },
            { 
                id: 'jobTemplateName', 
                filterKeyParam: 'jobTemplateName', 
                name: 'jobTemplateName', 
                label: 'JOB TYPE',
                width: '150px',
                responsiveLabel: 'JOB TYPE',
                orderable: true,
                template: this.jobTemplateNameTemplate,
                exportTemplate: this.jobTemplateNameExportTemplate
            },
            { 
                id: 'jobTimeSpecific', 
                filterKeyParam: 'jobTimeSpecific', 
                name: 'jobTimeSpecific', 
                label: 'SCHEDULED TIME',
                width: '150px',
                responsiveLabel: 'SCHEDULED TIME',
                orderable: true,
                template: this.jobTimeSpecificTemplate,
                exportTemplate: this.jobTimeSpecificExportTemplate,
                exportCSVTemplate: this.jobTimeSpecificExportCSVTemplate,
            },
            { 
                id: 'actualJobTimeSpecific', 
                filterKeyParam: 'actualJobTimeSpecific', 
                name: 'actualJobTimeSpecific', 
                label: 'START TIME',
                width: '150px',
                responsiveLabel: 'START TIME',
                orderable: true,
                template: this.jobDateStartTemplate,
                exportTemplate: this.jobDateStartExportTemplate,
                exportCSVTemplate: this.jobDateStartExportCSVTemplate,
            },
            { 
                id: 'inProgressTime', 
                filterKeyParam: 'inProgressTime', 
                name: 'inProgressTime', 
                label: 'IN PROGRESS TIME',
                width: '150px',
                responsiveLabel: 'IN PROGRESS TIME',
                orderable: true,
                template: this.jobDateInProgressTemplate,
                exportTemplate: this.jobDateInProgressExportTemplate,
                exportCSVTemplate: this.jobDateInProgressExportCSVTemplate,
            },
            { 
              id: 'jobAttemptCompletedDate', 
              filterKeyParam: 'jobAttemptCompletedDate', 
              name: 'jobAttemptCompletedDate', 
              label: 'COMPLETE DATE',
              width: '150px',
              orderable: true,
              template: this.jobDateCompleteTemplate,
              exportTemplate: this.jobDateCompleteExportTemplate,
              exportCSVTemplate: this.jobDateCompleteExportCSVTemplate,
            },
            { 
              id: 'jobAttemptCompletedTime', 
              filterKeyParam: 'jobAttemptCompletedDate', 
              name: 'JobAttemptCompletedDate', 
              label: 'COMPLETE TIME',
              width: '150px',
              responsiveLabel: 'COMPLETE TIME',
              orderable: true,
    
              template: this.jobTimeTemplate,
              exportTemplate: this.jobTimeExportTemplate,
              exportCSVTemplate: this.jobTimeExportCSVTemplate,
            },
            { 
                id: 'location', 
                filterKeyParam: 'location', 
                name: 'location', 
                label: 'ADDRESSES',
                width: '150px',
                responsiveLabel: 'ADDRESSES',
                orderable: false,
            },
            { 
                id: 'jobNumber', 
                filterKeyParam: 'jobNumber', 
                name: 'jobNumber', 
                label: 'DO #',
                labelClass: 'uppercase-text',
                width: '150px',
                responsiveLabel: 'DO #',
                orderable: true,
            },
            { 
                id: 'vehicleName', 
                filterKeyParam: 'vehicleName', 
                name: 'vehicleName', 
                label: 'VEHICLE',
                width: '150px',
                responsiveLabel: 'VEHICLE',
                orderable: true,
                template: this.vehicleTemplate,
                exportTemplate: this.vehicleExportTemplate,
            },
            { 
                id: 'driverName', 
                filterKeyParam: 'driverName', 
                name: 'driverName', 
                label: 'DRIVER',
                width: '150px',
                responsiveLabel: 'DRIVER',
                orderable: true,
                template: this.driverTemplate,
                exportTemplate: this.driverExportTemplate,
            },
            { 
                id: 'statusName', 
                filterKeyParam: 'statusName', 
                name: 'statusName', 
                label: 'STATUS',
                width: '150px',
                responsiveLabel: 'STATUS',
                orderable: true,
                template: this.statusTemplate,
                exportTemplate: this.statusExportTemplate,
            },
            { 
              id: 'priority', 
              filterKeyParam: 'priority', 
              name: 'priority', 
              label: 'PRIORITY',
              width: '150px',
              responsiveLabel: 'PRIORITY',
              orderable: true,
              template: this.priorityTemplate,
              exportTemplate: this.priorityExportTemplate,
            },
            {
              id: "geofenceGroupName",
              name: "geofenceGroupName",
              label: "GEOFENCE GROUP",
              responsiveLabel: "GEOFENCE GROUP",
              width: "150px",
              orderable: false,
            },
            {
              id: "geofenceName",
              name: "geofenceName",
              label: "GEOFENCE",
              responsiveLabel: "GEOFENCE",
              width: "150px",
              orderable: false,
            },
            { 
                id: 'customerPaymentTerms', 
                filterKeyParam: 'customerPaymentTerms', 
                name: 'customerPaymentTerms', 
                label: 'PAYMENT TERMS',
                width: '150px',
                responsiveLabel: 'PAYMENT TERMS',
                orderable: true,
                template: this.customerTermsTemplate,
                exportTemplate: this.customerTermsExportTemplate,
            },
          
            { 
                id: 'contactPersonOne', 
                filterKeyParam: 'contactPersonOne', 
                name: 'contactPersonOne', 
                label: 'CONTACT PERSON ONE  ',
                width: '150px',
                responsiveLabel: 'CONTACT PERSON ONE ',
                orderable: true,
                template: this.contactPersonOneTemplate,
                exportTemplate: this.contactPersonOneExportTemplate,
                exportCSVTemplate: this.contactPersonOneExportCSVTemplate,
            },
            { 
                id: 'contactPersonTwo', 
                filterKeyParam: 'contactPersonTwo', 
                name: 'contactPersonTwo', 
                label: 'CONTACT PERSON TWO ',
                width: '150px',
                responsiveLabel: 'CONTACT PERSON TWO ',
                orderable: true,
                template: this.contactPersonTwoTemplate,
                exportTemplate: this.contactPersonExportTwoTemplate,
                exportCSVTemplate: this.contactPersonExportTwoCSVTemplate,
            },
            { 
                id: 'wasteTypeOut', 
                filterKeyParam: 'wasteTypeOut', 
                name: 'wasteTypeOut', 
                label: 'WASTE OUT',
                width: '150px',
                responsiveLabel: 'WASTE OUT',
                orderable: true,
            },
            { 
                id: 'binTypeOut', 
                filterKeyParam: 'binTypeOut', 
                name: 'binTypeOut', 
                label: 'BIN OUT',
                width: '150px',
                responsiveLabel: 'BIN OUT',
                orderable: true,
            },
            { 
                id: 'binNumberOut', 
                filterKeyParam: 'binNumberOut', 
                name: 'binNumberOut', 
                label: 'BIN NUMBER OUT',
                width: '150px',
                responsiveLabel: 'BIN NUMBER OUT',
                orderable: true,
                template: this.binNumberOutTemplate,
                exportTemplate: this.binNumberOutExportTemplate,
            },
            { 
                id: 'wasteTypeIn', 
                filterKeyParam: 'wasteTypeIn', 
                name: 'wasteTypeIn', 
                label: 'WASTE IN',
                width: '150px',
                responsiveLabel: 'WASTE IN',
                orderable: true,
                template: this.WasteInItemTemplate,
                exportTemplate: this.WasteInItemExportTemplate,
            },
            { 
                id: 'binTypeIn', 
                filterKeyParam: 'binTypeIn', 
                name: 'binTypeIn', 
                label: 'BIN IN',
                width: '150px',
                responsiveLabel: 'BIN IN',
                orderable: true,
            },
            { 
                id: 'binNumberIn', 
                filterKeyParam: 'binNumberIn', 
                name: 'binNumberIn', 
                label: 'BIN NUMBER IN',
                width: '150px',
                responsiveLabel: 'BIN NUMBER IN',
                orderable: true,
                template: this.binNumberInTemplate,
                exportTemplate: this.binNumberInExportTemplate,
            },
            { 
                id: 'amountToCollect', 
                filterKeyParam: 'amountToCollect', 
                name: 'amountToCollect', 
                label: 'AMOUNT TO COLLECT',
                width: '150px',
                responsiveLabel: 'AMOUNT TO COLLECT',
                orderable: true,
                template: this.amountToCollectTemplate,
                exportTemplate: this.amountToCollectExportTemplate,
            },
            { 
                id: 'collectedAmount', 
                filterKeyParam: 'collectedAmount', 
                name: 'collectedAmount', 
                label: 'COLLECTED AMOUNT',
                width: '150px',
                responsiveLabel: 'COLLECTED AMOUNT',
                orderable: true,
                template: this.collectedAmountTemplate,
                exportTemplate: this.collectedAmountExportTemplate,
            },
            { 
                id: 'jobPaymentTypeDisplay', 
                filterKeyParam: 'jobPaymentTypeDisplay', 
                name: 'jobPaymentTypeDisplay', 
                label: 'PAYMENT TYPE',
                width: '150px',
                responsiveLabel: 'PAYMENT TYPE',
                orderable: false,
            },
            { 
                id: 'instructionToDrivers', 
                filterKeyParam: 'instructionToDrivers', 
                name: 'instructionToDrivers', 
                label: 'INST. TO DRIVERS',
                width: '150px',
                responsiveLabel: 'INST. TO DRIVERS',
                orderable: true,
            },
            { 
                id: 'siteRemarks', 
                filterKeyParam: 'siteRemarks', 
                name: 'siteRemarks', 
                label: 'SITE INSTRUCTIONS',
                width: '150px',
                responsiveLabel: 'SITE INSTRUCTIONS',
                orderable: true,
                template: this.siteRemarksTemplate,
                exportTemplate: this.siteRemarksExportTemplate,
            },
            { 
                id: 'remarks', 
                filterKeyParam: 'remarks', 
                name: 'remarks', 
                label: 'INTERNAL NOTE',
                width: '150px',
                responsiveLabel: 'INTERNAL NOTE',
                orderable: true,
                template: this.remarksTemplate,
                exportTemplate: this.remarksExportTemplate,
            },
            { 
                id: 'cancellationRemarks', 
                filterKeyParam: 'cancellationRemarks', 
                name: 'cancellationRemarks', 
                label: 'CANCELLATION REMARKS',
                width: '150px',
                responsiveLabel: 'CANCELLATION REMARKS',
                orderable: true,
            },
            { 
                id: 'totalDistance', 
                filterKeyParam: 'totalDistance', 
                name: 'totalDistance', 
                label: 'DISTANCE',
                width: '150px',
                responsiveLabel: 'DISTANCE',
                orderable: true,
                template: this.totalDistanceTemplate,
                exportTemplate: this.totalDistanceExportTemplate,
            },

            { 
                id: 'weightChitTicketNumber', 
                filterKeyParam: 'weightChitTicketNumber', 
                name: 'weightChitTicketNumber', 
                label: 'TICKET NUMBER',
                width: '150px',
                responsiveLabel: 'TICKET NUMBER',
                orderable: true,
            },
            { 
                id: 'binOutWeight', 
                filterKeyParam: 'binOutWeight', 
                name: 'binOutWeight', 
                label: 'BIN OUT WEIGHT',
                width: '150px',
                responsiveLabel: 'BIN OUT WEIGHT',
                orderable: true,
                template: this.binOutWeightTemplate,
                exportTemplate: this.binOutWeightExportTemplate,
            },
            { 
                id: 'binWeight', 
                filterKeyParam: 'grossWeight', 
                name: 'binWeight', 
                label: 'GROSS WEIGHT',
                width: '150px',
                responsiveLabel: 'GROSS WEIGHT',
                orderable: true,
                template: this.binWeightTemplate,
                exportTemplate: this.binWeightExportTemplate,
            },
            { 
                id: 'billableBinWeight', 
                filterKeyParam: 'billableBinWeight', 
                name: 'billableBinWeight', 
                label: 'BILLABLE WEIGHT',
                width: '150px',
                responsiveLabel: 'BILLABLE WEIGHT',
                orderable: true,
                template: this.billableBinWeightTemplate,
                exportTemplate: this.billableBinWeightExportTemplate,
            },
            { 
                id: 'netBinWeight', 
                filterKeyParam: 'netBinWeight', 
                name: 'netBinWeight', 
                label: 'NETT WEIGHT',
                width: '150px',
                responsiveLabel: 'NETT WEIGHT',
                orderable: true,
                template: this.netBinWeightTemplate,
                exportTemplate: this.netBinWeightExportTemplate,
            },
            { 
                id: 'nonBillableBinWeight', 
                filterKeyParam: 'nonBillableBinWeight', 
                name: 'nonBillableBinWeight', 
                label: 'NON-BILLABLE WEIGHT',
                width: '150px',
                responsiveLabel: 'NON-BILLABLE WEIGHT',
                orderable: true,
                template: this.nonBillableBinWeightTemplate,
                exportTemplate: this.nonBillableBinWeightExportTemplate,
            },
            { 
                id: 'tareBinWeight', 
                filterKeyParam: 'tareBinWeight', 
                name: 'tareBinWeight', 
                label: 'TARE WEIGHT',
                width: '150px',
                responsiveLabel: 'TARE WEIGHT',
                orderable: true,
                template: this.tareBinWeightTemplate,
                exportTemplate: this.tareBinWeightExportTemplate,
            },
            { 
                id: 'weightChitRemarks', 
                filterKeyParam: 'weightChitRemarks', 
                name: 'weightChitRemarks', 
                label: 'WEIGHT REMARKS',
                width: '150px',
                responsiveLabel: 'WEIGHT REMARKS',
                orderable: true,
            },
            { 
                id: 'binWeighBy', 
                filterKeyParam: 'binWeighBy', 
                name: 'binWeighBy', 
                label: 'WEIGHT BY',
                width: '150px',
                responsiveLabel: 'WEIGHT BY',
                orderable: true,
            },
            { 
              id: 'weightChitDateTime', 
              filterKeyParam: 'weightChitDateTime', 
              name: 'weightChitDateTime', 
              label: 'TIME STAMP',
              width: '150px',
              responsiveLabel: 'TIME STAMP',
              orderable: true,
    
              template: this.weightChitDateTimeTemplate,
              exportTemplate: this.weightChitDateTimeExportTemplate,
              exportCSVTemplate: this.weightChitDateTimeExportCSVTemplate,
            },
            { 
              id: 'weightChitOutDateTime', 
              filterKeyParam: 'weightChitOutDateTime', 
              name: 'weightChitOutDateTime', 
              label: 'OUT TIME STAMP',
              width: '150px',
              responsiveLabel: 'OUT TIME STAMP',
              orderable: true,
    
              template: this.weightChitOutDateTimeTemplate,
              exportTemplate: this.weightChitOutDateTimeExportTemplate,
              exportCSVTemplate: this.weightChitOutDateTimeExportCSVTemplate,
            },
            
            { 
              id: 'billingStatus', 
              filterKeyParam: 'billingStatus', 
              name: 'billingStatus', 
              label: 'BILLING STATUS',
              width: '150px',
              responsiveLabel: 'BILLING STATUS',
              orderable: true,
              template: this.billingStatusTemplate,
              exportTemplate: this.billingStatusExportTemplate,
              exportCSVTemplate: this.billingStatusExportCSVTemplate,
            },
            // { 
            //   id: 'isBillable', 
            //   filterKeyParam: 'isBillable', 
            //   name: 'isBillable', 
            //   label: 'BILLING STATUS',
            //   width: '150px',
            //   responsiveLabel: 'BILLING STATUS',
            //   orderable: true,
            //   template: this.isBillableTemplate,
            //   exportTemplate: this.isBillableExportTemplate,
            //   exportCSVTemplate: this.isBillableExportCSVTemplate,
            // },

            { 
                id: 'action',
                filterKeyParam: 'action',
                name: 'action',
                label: '',
                width: '150px',
                responsiveLabel: '',
                orderable: false,
                exportVisible: false,
                visibleInDropdown: false,
                filterable: false,
                template: this.actionTemplate,
            }
        ];
        let initColumns = Array.from(columns);
        columns = Array.from(initColumns);
        
        
        let columnVisibility = [
          false,
          true,
          false,
          true,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
          true,
          true,
          true,
          true,
          true,
          true,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          false,
          true,
        ];
        let initColumnVisibility = Array.from(columnVisibility);
        columnVisibility = Array.from(initColumnVisibility);


        let isDisabledBatchAction = ((props.isDisabledBatchAction === false) || (props.isDisabledBatchAction === true)) ? props.isDisabledBatchAction : false;

        this.menuLeftPosition = 0;

		this.state = {
      data: null,
      err: null,
			isError: false,
			isLoading: false,
			isLoadingTable: false,

      popupShow: false,
      popupLoading: false,
      popupData: null,
      popupHasChecked: true,
      popupIsEdited: false,

      isLoadingBackdrop: false,
      isLoadingBatchAction: false,
      isDisabledBatchAction: isDisabledBatchAction,

      cancelDialogItemLoading: false,
      isCancelDialog: false,
      cancelRow: null,
      cancelMessage: '',
  
      failedDialogItemLoading: false,
      isFailedDialog: false,
      failedRow: null,
      failedMessage: '',
      
      assignDialogItemLoading: false,
      isAssignDialog: false,
      assignDialogItem: null,
      assignDialogIndex: 0,
  
      driver: null,
      driversItems: [],
      isLoadingDrivers: false,
  
      vehicle: null,
      vehiclesItems: [],
      isLoadingVehicles: false,
      
      newDialogItemLoading: false,
      isCreateNewDialog: false,
      newDialogItem: null,
      newDialogItemDefDate: null,
  
      newDialogItemLoadingCopy: false,
      isCreateNewDialogCopy: false,
      newDialogItemCopy: null,
  
      filtersIsClickAway: true,
      filterJobTypesValue: [],
  
      search: '',
      sortColumn: 'jobDate',
      sortDir: 'desc',
      sortColumnInit: 'jobDate',
      sortDirInit: 'desc',

      filterParams: [],
      searchOperator: [],
      searchColumnKey: 'name',

      isCheckbox: true,
      isDragable: false,
      isBorder: false,
      isPagination: false,
      selected: [],
      columnVisibility: columnVisibility,
      initColumnVisibility: initColumnVisibility,
      columns: columns,
      initColumns: initColumns,
      rows: [],
      currentPage: 1,
      totalRows: 0,
      rowsPerPageSelected: 50,
      rowsPerPageOptions: [50, 100, 300],
  
      downloadIsLoading: false,
      isLoadingSiteRemarks: false,
      isLoadingBinWeight: false,
  
      initialDriverList: [],
      initialDriverNameList: [],
      initialVehicleNameList: [],
  
      isMenuSubItemsOpen: false,

      groupAccountEmail: groupAccountEmail,
      userEmail: userEmail,

      jobTemplateFilter: null,
      jobStatusFilter: this.status_filter,
      
      status: [],
      isLoadingStatus: true,

      types: [],
      isLoadingTypes: false,

      isStatusOrTypes: 1,

      isIncludeInactive: false,
      isInactive: false,

      isCookies: false,

      isLoadingFormDrawerAssignTo: false,
      isVisibleFormDrawerAssignTo: false,
      isBlockingFormDrawerAssignTo: false,
      rowInitialValuesAssignTo: {
        jobIds: '',
        assignedStatusId: null,
        dispatchedStatusId: null,
        driverId: null,
        driversList: [],
      },
      rowValuesAssignTo: null,

      
      isLoadingFormDrawerWeighChit: false,
      isVisibleFormDrawerWeighChit: false,
      isBlockingFormDrawerWeighChit: false,
      rowInitialValuesWeighChit: {
        isEdit: false,

        ticketNumber: '',
        grossWeight: '',
        tareWeight: '0',
        nettWeight: '0',
        nonBillableBinWeight: '0',
        billableWeight: '0',
        remarks: '',
        weighBy: '',
        date: null,
        weightChitOutDateTime: null,
        // time: null,
        uom: this.dispatchUser.defaultUoM,

        jobStepWeighChitCustomFields: [],

        customFormParams: {
          
        }
      },
      rowValuesWeighChit: null,
    }
  }


	async componentDidMount() {
    await this.props.action.dateSection.dateGetFilters({
      auth: this.props.auth,
      settingsType: this.props.settingsType,
      pageName: this.pageName,
    });

    if(this.weigh_chit != ''){

      let jobId = this.weigh_chit;

      this.callDetailsApi(jobId, null, (res) => {
        let jobStepId = null;
        let jobStep = null;
        let steps = (res && res.steps && res.steps.length > 0) ? res.steps : [];
        for(let i = 0; i < steps.length; i++){
          let item = steps[i];
          
          if(res.jobTypeName == 'Out'){
            if(i === 0){
              jobStepId = item.jobStepId;
              jobStep = item;
              break;
            }
          } else {
            if(item.customerSiteId){
              jobStepId = item.jobStepId;
              jobStep = item;
              break;
            }
          }
        }

        let statusName = (res && res.statusName) ? res.statusName : '';
        let jobNumber = (res && res.jobNumber) ? res.jobNumber : '';
        
        let weighChitTicketNumber = (jobStep && jobStep.weightChitTicketNumber) ? jobStep.weightChitTicketNumber : '';
        let weighChitGrossWeight = (jobStep && jobStep.binWeight) ? jobStep.binWeight : '';
        let weighChitTareWeight = (jobStep && jobStep.tareBinWeight) ? jobStep.tareBinWeight : '0';
        let weighChitNettWeight = (jobStep && jobStep.netBinWeight) ? jobStep.netBinWeight : '0';
        let weighChitNonBillableBinWeight = (jobStep && jobStep.nonBillableBinWeight) ? jobStep.nonBillableBinWeight : '0';
        let weighChitBillableWeight = (jobStep && jobStep.billableBinWeight) ? jobStep.billableBinWeight : '0';
        let weighChitRemarks = (jobStep && jobStep.weightChitRemarks) ? jobStep.weightChitRemarks : '';
        let weighChitWeighBy = (jobStep && jobStep.binWeighBy) ? jobStep.binWeighBy : '';
        let weightChitDateTime = (jobStep && jobStep.weightChitDateTime) ? jobStep.weightChitDateTime : null;
        let weightChitOutDateTime = (jobStep && jobStep.weightChitOutDateTime) ? jobStep.weightChitOutDateTime : null;
        // let weighChitTime = (jobStep && jobStep.weightChitDateTime) ? jobStep.weightChitDateTime : new Date();
        let jobStepWeighChitCustomFields = (jobStep && jobStep.jobStepWeighChitCustomFields && jobStep.jobStepWeighChitCustomFields.length > 0) ? jobStep.jobStepWeighChitCustomFields : [];

        let jobTemplateType = (steps && steps.length > 0) ? steps[0].stepType : null;
        let vehicleId = (res && res.vehicleId) ? res.vehicleId : null;
        let serviceItemId = null;
        let binTypeId = null;
        
        let step1 = steps.filter(x => x.stepOrder === 1);
        if (step1 && step1.length > 0) {
          if (jobTemplateType === JobStepType.OnTheSpot) {
            serviceItemId = step1[0].serviceItemId;
            binTypeId = step1[0].binTypeId;
          } else if (jobTemplateType === JobStepType.Pull) {
            serviceItemId = step1[0].serviceItemId;
            binTypeId = step1[0].binTypeId;
          } else if (jobTemplateType === JobStepType.Out) {
            serviceItemId = step1[0].serviceItemId;
            binTypeId = step1[0].binTypeId;
          }
        }
        let step2 = steps.filter(x => x.stepOrder === 2);
        if (step2 && step2.length > 0) {
          if (jobTemplateType === JobStepType.Exchange) {
            serviceItemId = step2[0].serviceItemId;
            binTypeId = step2[0].binTypeId;
          }
        }

        let dataParamsWeighChit = {
          id: 0,
          vehicleId: vehicleId,
          serviceItemId: serviceItemId,
          binTypeId: binTypeId,
          row: {
            jobId: jobId,
            jobStepId: jobStepId,
            statusName: statusName,
            jobNumber: jobNumber,

            weighChitTicketNumber: weighChitTicketNumber,
            weighChitGrossWeight: weighChitGrossWeight,
            weighChitTareWeight: weighChitTareWeight,
            weighChitNettWeight: weighChitNettWeight,
            weighChitNonBillableBinWeight: weighChitNonBillableBinWeight,
            weighChitBillableWeight: weighChitBillableWeight,
            weighChitRemarks: weighChitRemarks,
            weighChitWeighBy: weighChitWeighBy,
            weightChitDateTime: weightChitDateTime,
            weightChitOutDateTime: weightChitOutDateTime,
            // weighChitTime: weighChitTime,
            jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
            uom: this.dispatchUser.defaultUoM,
          },
        };
        Utils.setDrawerData(this.formDrawerWeighChit, dataParamsWeighChit);
        Utils.showDrawer(this.formDrawerWeighChit);
      });
    }

    if(this.status_filter != ''){
      await this.props.action.dateSection.dateChange(Utils.getDateRangeValue("Today"));
      await this.props.action.dateSection.dateSetFilters({
        auth: this.props.auth,
        settingsType: this.props.settingsType,
        pageName: this.pageName,
      });
    }

    await this.props.action.realTimeUpdate.getFilters({
      auth: this.props.auth,
      settingsType: this.props.settingsType,
      pageName: this.pageName,
    });

    this.intervalId = setInterval(() => {
      if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
        this.menuLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
      }
      
      if(this.props.realTimeUpdate.value > 0){
        this.props.action.realTimeUpdate.updateTimeLeft();
      }
    }, 1000);

    this.initDrawers();

    this.getFilterInLocalStorage(() => {
      if(this.status_filter && this.status_filter != ''){
        this.setState({
          isLoadingTable: true,
          isStatusOrTypes: 1,
          jobStatusFilter: this.status_filter,
          jobTemplateFilter: null,
          currentPage: 1,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi();

          if(this.props.onLoadingTable){
            this.props.onLoadingTable(this.state.isLoadingTable);
          }
        });
      } else {
        this.callReadApi();
      }

      let qHash = queryString.parse(window.location.hash);
      if(qHash && qHash.createJob){
        this.setState({
          isCreateNewDialog: true,
          newDialogItem: null,
          newDialogItemDefDate: null,
        }, () => {
          window.location.hash = '';
        });
      }
    });
  }
  componentWillUnmount() {
    clearInterval(this.intervalId);

    if(this.refSignalR && this.refSignalR.current){
      this.refSignalR.current.disconnect()
    }
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.isDisabledBatchAction !== this.state.isDisabledBatchAction) {
      let isDisabledBatchAction = ((nextProps.isDisabledBatchAction === false) || (nextProps.isDisabledBatchAction === true)) ? nextProps.isDisabledBatchAction : false;
      this.setState({
        isDisabledBatchAction: isDisabledBatchAction,
      });
    }

    this.reduxProps(nextProps);
    this.setRefreshPage(nextProps);
	}

	shouldComponentUpdate(nextProps, nextState) {
    if(this.state.isMenuSubItemsOpen != nextState.isMenuSubItemsOpen) {
      if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
        this.menuLeftPosition = this.refDataTableScrollSync.current.scrollLeft;
      }
      return false
    }
    
    return true
  }

  reduxProps = (nextProps) => {
    Utils.reduxProps(nextProps,
      'job-table-section_job-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        let totalRows = (data && data.total) ? data.total : 0;
        
        this.setState({
          rows: rows,
          totalRows: totalRows,

          isLoadingTable: false,
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }

          if(this.props.onLoadingTable){
            this.props.onLoadingTable(this.state.isLoadingTable);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_job-list-by-page', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoadingTable: false,
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows);
          }

          if(this.props.onLoadingTable){
            this.props.onLoadingTable(this.state.isLoadingTable);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_job-list-read-all', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];
        
        this.setState({
          isLoadingTable: false,
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(rows, isError, err);
          }

          if(this.props.onLoadingTable){
            this.props.onLoadingTable(this.state.isLoadingTable);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_job-list-download-pdf', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let rows = (data && data.data && data.data.length > 0) ? data.data : [];

        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(rows, isError, err);
          }
          
          this.props.action.signalRslice.disconnect();
        });
      }
    );
    Utils.reduxProps(nextProps,
      'job-table-section_job-list-download-excel', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data, isError, err);
          }
          
          this.props.action.signalRslice.disconnect();
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_job-list-download-file', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_job-list-download-internal-note', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_dispatch-all', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(data && data.failedJobs && data.failedJobs.length){
          let m = 'Failed to dispatch';
  
          for(let i = 0; i < data.failedJobs.length; i++){
            let item = data.failedJobs[i];
            let title = (item.message && item.message !== '') ? item.message : m;
            let msg = item.quickbookError;
            let numb = item.jobNumber;
  
            Utils.toast(<div>
              <div className="fw-bolder pb-2">{title}</div>
              <div>{msg}</div>
              <div>{numb}</div>
            </div>, 'error');
            break;
          }
        }
  
        if(data && data.successJobs && data.successJobs.length > 0){
          let m = 'You have successfully dispatch';
  
          for(let i = 0; i < data.successJobs.length; i++){
            let item = data.successJobs[i];
            let title = (item.message && item.message !== '') ? item.message : m;
            let msg = item.quickbookError;
            let numb = item.jobNumber;
  
            Utils.toast(<div>
              <div className="fw-bolder pb-2">{title}</div>
              <div>{msg}</div>
              <div>{numb}</div>
            </div>, 'success');
            break;
          }
        }
        
        this.setState({
          isLoadingBatchAction: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback();
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_send-notification-to-driver', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(!this.state.isError){
            Utils.toast("Reminder notification sent successfully!", 'success'); 
          }

          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_merge-job-doc', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data, isError, err);
          }
          
          this.props.action.signalRslice.disconnect();
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_batch-step-bin-weight', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(callback){
            callback(data, isError, err);
          }
          
          this.props.action.signalRslice.disconnect();
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_dispatch', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(!this.state.isError){
            Utils.toast('Successfully updated!', 'success');

            this.tableRef.current.update(data, (updatedRow, newArray) => {
              this.setState({
                rows: newArray
              });
            });
          }
          
          if(callback){
            callback(data, this.state.isError);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_driver-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = [];

				if(data && data.data && data.data.length > 0){
					for(var i = 0; i < data.data.length; i++){
						let item = data.data[i];
						let id = item.driverId;
						let text = item.driverName;

						if(arr.findIndex(x => x.value === id) === -1){
							arr.push({
								value: id,
								text: text,
								item: item
							});
						}
          }
        }

        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(arr);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_driver-list-assignto', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = [];

				if(data && data.data && data.data.length > 0){
					for(var i = 0; i < data.data.length; i++){
						let item = data.data[i];
						let id = item.driverId;
						let text = item.driverName;

						if(arr.findIndex(x => x.value === id) === -1){
							arr.push({
								value: id,
								title: text,
								item: item,
                isChecked: false,
							});
						}
          }
        }

        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(arr);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_vehicle-list', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let arr = [];

				if(data && data.data && data.data.length > 0){
					for(var i = 0; i < data.data.length; i++){
						let item = data.data[i];
						let id = item.vehicleId;
						let text = item.vehicleName;

						if(arr.findIndex(x => x.value === id) === -1){
							arr.push({
								value: id,
								text: text,
								item: item
							});
						}
          }
        }

        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(arr);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_remarks', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_site-remarks', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_priority', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_bin-weight', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          if(isError){
            
          } else {
            if(callback){
              callback(data);
            }
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_get-stat', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let statuses = [];
        let totalRows = 0;

        if(data && data.data && data.data.length > 0){
          statuses = Utils.sortStatus((data && data.data) ? data.data : null);
          totalRows = (data && data.total) ? data.total : 0;
        }

        this.setState({
          totalRows: totalRows,
          status: statuses,
          isLoadingStatus: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();
          
          if(this.props.onStatusLoad){
            this.props.onStatusLoad(statuses, totalRows);
          }
          if(this.props.onStatusesLoading){
            this.props.onStatusesLoading(false);
          }

          if(callback){
            callback(data);
          }
        });
      }
    );

    Utils.reduxProps(nextProps,
      'job-table-section_get-type-stat', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        let types = [];

        if(data && data.data && data.data.length > 0){
          types = data.data;
        }

        this.setState({
          types: types,
          isLoadingTypes: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(this.props.onTypesLoad){
            this.props.onTypesLoad(this.state.types);
          }
          if(this.props.onTypesLoading){
            this.props.onTypesLoading(false);
          }

          if(callback){
            callback(data);
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_delete', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(!this.state.isError){
            Utils.toast('Successfully deleted!', 'success');

            if(callback){
              callback();
            }
          }
        });
      }
    );
    
    Utils.reduxProps(nextProps,
      'job-table-section_job-details', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        this.setState({
          isLoadingBackdrop: false,

          isLoading: isLoading,
          isError: isError,
          err: err,
        }, () => {
          this.props.clear();

          if(!this.state.isError){
            if(callback){
              callback(data);
            }
          } else {
            if(callback){
              callback(variables);
            }
          }
        });
      }
    );

  }


  setFilterInLocalStorage = (isCookies = true) => {
    if(this.props.settingsType){
      let { token, userCookieSettings } = this.props.auth;
      
      this.setState({
        isCookies: isCookies,
      });
      
      let data = {
        name: this.pageName,
        type: this.props.settingsType,
      };

      let valueLocalStorage = {
        [PageCookieSettingType.currentPage]: this.state.currentPage,
        // [PageCookieSettingType.fromDate]: this.state.fromDate,
        // [PageCookieSettingType.toDate]: this.state.toDate,
      };
      window.localStorage.setItem(this.pageName + '_' + this.props.settingsType, JSON.stringify(valueLocalStorage));

      let value = {
        [PageCookieSettingType.isCookies]: isCookies,
        [PageCookieSettingType.sortColumn]: this.state.sortColumn,
        [PageCookieSettingType.sortDir]: this.state.sortDir,
        // [PageCookieSettingType.currentPage]: this.state.currentPage,
        [PageCookieSettingType.rowsPerPageSelected]: this.state.rowsPerPageSelected,
        // [PageCookieSettingType.fromDate]: this.state.fromDate,
        // [PageCookieSettingType.toDate]: this.state.toDate,
        [PageCookieSettingType.columnVisibility]: this.state.columnVisibility,
        [PageCookieSettingType.columns]: this.state.columns,
        [PageCookieSettingType.isIncludeInactive]: this.state.isIncludeInactive,
        [PageCookieSettingType.isInactive]: this.state.isInactive,
        [PageCookieSettingType.search]: this.state.search,
        [PageCookieSettingType.jobStatusFilter]: this.state.jobStatusFilter,
        [PageCookieSettingType.jobTemplateFilter]: this.state.jobTemplateFilter,
        [PageCookieSettingType.isStatusOrTypes]: this.state.isStatusOrTypes,
        [PageCookieSettingType.realTimeUpdate]: this.props.realTimeUpdate.index,
      };
      data['value'] = JSON.stringify(value);

      Utils.saveUserCookieSettings(data, token, (settings) => {
        if(settings){
          let settingsArr = (userCookieSettings && userCookieSettings.length > 0) ? userCookieSettings : [];
          let settingsIndex = Utils.getUserCookieSettingsIndex(settingsArr, this.props.settingsType);
          if(settingsIndex !== -1 && settingsArr[settingsIndex]){
            let oldValue = JSON.parse(settingsArr[settingsIndex].value);
            let newValue = JSON.parse(settings.value);

            let value = Object.assign({}, oldValue);
            value[PageCookieSettingType.isCookies] = newValue[PageCookieSettingType.isCookies];
            value[PageCookieSettingType.sortColumn] = newValue[PageCookieSettingType.sortColumn];
            value[PageCookieSettingType.sortDir] = newValue[PageCookieSettingType.sortDir];
            value[PageCookieSettingType.rowsPerPageSelected] = newValue[PageCookieSettingType.rowsPerPageSelected];
            value[PageCookieSettingType.columnVisibility] = newValue[PageCookieSettingType.columnVisibility];
            value[PageCookieSettingType.columns] = newValue[PageCookieSettingType.columns];
            value[PageCookieSettingType.isIncludeInactive] = newValue[PageCookieSettingType.isIncludeInactive];
            value[PageCookieSettingType.isInactive] = newValue[PageCookieSettingType.isInactive];
            value[PageCookieSettingType.search] = newValue[PageCookieSettingType.search];
            value[PageCookieSettingType.jobStatusFilter] = newValue[PageCookieSettingType.jobStatusFilter];
            value[PageCookieSettingType.jobTemplateFilter] = newValue[PageCookieSettingType.jobTemplateFilter];
            value[PageCookieSettingType.isStatusOrTypes] = newValue[PageCookieSettingType.isStatusOrTypes];
            value[PageCookieSettingType.realTimeUpdate] = newValue[PageCookieSettingType.realTimeUpdate];
            
            settingsArr[settingsIndex].updated = settings.updated;
            settingsArr[settingsIndex].value = JSON.stringify(value);
          } else {
            settingsArr.push(settings);
          }

          this.props.saveUserCookieSettings(settingsArr)
        }
      });
    }
  }
  getFilterInLocalStorage = (callback = null) => {
    let settingsItem = null;

    if(this.props.settingsType){
      const { userCookieSettings } = this.props.auth;
      
      settingsItem = Utils.getUserCookieSettings(userCookieSettings, this.props.settingsType);
    }

    let valueLocalStorageStr = window.localStorage.getItem(this.pageName + '_' + this.props.settingsType);
    let valueLocalStorage = JSON.parse(valueLocalStorageStr);
    let currentPageValue = valueLocalStorage && valueLocalStorage[PageCookieSettingType.currentPage] && valueLocalStorage[PageCookieSettingType.currentPage] !== "" ? valueLocalStorage[PageCookieSettingType.currentPage] : this.state.currentPage;
    // let fromDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.fromDate] && valueLocalStorage[PageCookieSettingType.fromDate] !== '') ? valueLocalStorage[PageCookieSettingType.fromDate] : this.state.fromDate;
    // let toDateValue = (valueLocalStorage && valueLocalStorage[PageCookieSettingType.toDate] && valueLocalStorage[PageCookieSettingType.toDate] !== '') ? valueLocalStorage[PageCookieSettingType.toDate] : this.state.toDate;

    let isCookiesValue = settingsItem && settingsItem[PageCookieSettingType.isCookies] && settingsItem[PageCookieSettingType.isCookies] !== "" ? settingsItem[PageCookieSettingType.isCookies] : this.state.isCookies;
    let sortColumnValue = settingsItem && settingsItem[PageCookieSettingType.sortColumn] && settingsItem[PageCookieSettingType.sortColumn] !== "" ? settingsItem[PageCookieSettingType.sortColumn] : this.state.sortColumn;
    let sortDirValue = settingsItem && settingsItem[PageCookieSettingType.sortDir] && settingsItem[PageCookieSettingType.sortDir] !== "" ? settingsItem[PageCookieSettingType.sortDir] : this.state.sortDir;
    // let currentPageValue = settingsItem && settingsItem[PageCookieSettingType.currentPage] && settingsItem[PageCookieSettingType.currentPage] !== "" ? settingsItem[PageCookieSettingType.currentPage] : this.state.currentPage;
    let rowsPerPageSelectedValue = settingsItem && settingsItem[PageCookieSettingType.rowsPerPageSelected] && settingsItem[PageCookieSettingType.rowsPerPageSelected] !== "" ? settingsItem[PageCookieSettingType.rowsPerPageSelected] : this.state.rowsPerPageSelected;
    // let fromDateValue = (settingsItem && settingsItem[PageCookieSettingType.fromDate] && settingsItem[PageCookieSettingType.fromDate] !== '') ? settingsItem[PageCookieSettingType.fromDate] : this.state.fromDate;
    // let toDateValue = (settingsItem && settingsItem[PageCookieSettingType.toDate] && settingsItem[PageCookieSettingType.toDate] !== '') ? settingsItem[PageCookieSettingType.toDate] : this.state.toDate;
    let columnVisibilityValue = (settingsItem && settingsItem[PageCookieSettingType.columnVisibility] && settingsItem[PageCookieSettingType.columnVisibility] !== '') ? settingsItem[PageCookieSettingType.columnVisibility] : this.state.columnVisibility;
    let columnsValue = (settingsItem && settingsItem[PageCookieSettingType.columns] && settingsItem[PageCookieSettingType.columns] !== '') ? settingsItem[PageCookieSettingType.columns] : this.state.columns;
    let isIncludeInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isIncludeInactive] && settingsItem[PageCookieSettingType.isIncludeInactive] !== '') ? settingsItem[PageCookieSettingType.isIncludeInactive] : this.state.isIncludeInactive;
    let isInactiveValue = (settingsItem && settingsItem[PageCookieSettingType.isInactive] && settingsItem[PageCookieSettingType.isInactive] !== '') ? settingsItem[PageCookieSettingType.isInactive] : this.state.isInactive;
    let searchValue = (settingsItem && settingsItem[PageCookieSettingType.search] && settingsItem[PageCookieSettingType.search] !== '') ? settingsItem[PageCookieSettingType.search] : this.state.search;
    let jobStatusFilterValue = (settingsItem && settingsItem[PageCookieSettingType.jobStatusFilter] && settingsItem[PageCookieSettingType.jobStatusFilter] !== '') ? settingsItem[PageCookieSettingType.jobStatusFilter] : this.state.jobStatusFilter;
    let jobTemplateFilterValue = (settingsItem && settingsItem[PageCookieSettingType.jobTemplateFilter] && settingsItem[PageCookieSettingType.jobTemplateFilter] !== '') ? settingsItem[PageCookieSettingType.jobTemplateFilter] : this.state.jobTemplateFilter;
    let isStatusOrTypesValue = (settingsItem && settingsItem[PageCookieSettingType.isStatusOrTypes] && settingsItem[PageCookieSettingType.isStatusOrTypes] !== '') ? settingsItem[PageCookieSettingType.isStatusOrTypes] : this.state.isStatusOrTypes;
    
    let columns = Utils.setTemplateFunctionsToColumns(this.state.columns, columnsValue);
    // let dateRange = [
    //   moment(fromDateValue),
    //   moment(toDateValue),
    // ];

    this.setState({
      isCookies: isCookiesValue,
      sortColumn: sortColumnValue,
      sortDir: sortDirValue,
      currentPage: currentPageValue,
      rowsPerPageSelected: rowsPerPageSelectedValue,
      // fromDate: fromDateValue,
      // toDate: toDateValue,
      columnVisibility: columnVisibilityValue,
      isIncludeInactive: isIncludeInactiveValue,
      isInactive: isInactiveValue,
      search: searchValue,
      jobStatusFilter: jobStatusFilterValue,
      jobTemplateFilter: jobTemplateFilterValue,
      isStatusOrTypes: isStatusOrTypesValue,
      columns: columns,
      // dateRange: dateRange,
    }, () => {
      if (callback) {
        callback();
      }
    });
  }



  /* FUNCTIONS */
  onWindowResize = () => {
    this.getFilterInLocalStorage();
  }
  
  findStatus = (text) => {
		let status = null;

		if(this.state.status && this.state.status.length > 0){
			let statusItem = this.state.status.filter(x => x.jobStatusName.toLowerCase() === text.toLowerCase());

			if(statusItem && statusItem.length > 0){
				status = statusItem[0];
			}
		}

		return status;
  }

  setCheckedRows = (selected = [], callback = null) => {
    if(this.tableRef && this.tableRef.current){
      this.tableRef.current.setSelected(selected, callback);
    }
  }

  getCheckedRows = () => {
    let checkedRows = [];

    if(this.tableRef && this.tableRef.current){
      checkedRows = this.tableRef.current.getSelectedRows()
    }
    
    return checkedRows;
  }

  loadTable = () => {
    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    this.setState({
        isLoadingTable: true,
        fromDate: (fromDate) ? fromDate : moment().startOf('day'),
        toDate: (toDate) ? toDate : moment().endOf('day'),
        jobStatusFilter: '',
        jobTemplateFilter: null,
        currentPage: 1,
    }, () => {
      this.setFilterInLocalStorage();
      this.callReadApi();

      if(this.props.onLoadingTable){
        this.props.onLoadingTable(this.state.isLoadingTable);
      }
    });
  }

  loadTableShowAll = (isStatusOrTypes) => {
    if(!this.state.isLoadingTable)
    this.setState({
      jobStatusFilter: '',
      jobTemplateFilter: null,
      currentPage: 1,
      isStatusOrTypes: isStatusOrTypes,
    }, () => {
      this.setFilterInLocalStorage();
      this.callReadWithoutStatusApi();
    });
  }

  loadTableShowAllWithStatues = () => {
    this.setState({
      isLoadingTable: true,
      jobStatusFilter: '',
      jobTemplateFilter: null,
      currentPage: 1,
    }, () => {
      this.setFilterInLocalStorage();
      this.callReadApi();

      if(this.props.onLoadingTable){
        this.props.onLoadingTable(this.state.isLoadingTable);
      }
    });
  }

  loadTableByJobType = (jobTemplateFilter = null) => {
    if(!this.state.isLoadingTable)
    this.setState({
      isStatusOrTypes: 2,
      jobTemplateFilter: jobTemplateFilter,
      jobStatusFilter: '',
      currentPage: 1,
    }, () => {
      this.setFilterInLocalStorage();
      this.callReadWithoutStatusApi();
    });
  }

  loadTableByJobStatus = (jobStatusFilter = '') => {
    if(!this.state.isLoadingTable)
    this.setState({
      isStatusOrTypes: 1,
      jobStatusFilter: jobStatusFilter,
      jobTemplateFilter: null,
      currentPage: 1,
    }, () => {
      this.setFilterInLocalStorage();
      this.callReadWithoutStatusApi();
    });
  }

  // getBatchAction = () => {
  //   let unassignedState = false;
  //   let unassignedStatus = this.findStatus('unassigned');
  //   if(unassignedStatus){
  //     if(unassignedStatus.jobStatusId === this.state.jobStatusFilter){
  //       unassignedState = true;
  //     }
  //   }

  //   return <DropdownBtnMenu 
  //     className={"custom-job-split-btn-dropdown w-100 text-end text-md-center"} 
  //     text={unassignedState ? 'Assign To' : 'Dispatch all'}
  //     rightIcon={<Icon component={'i'}>arrow_drop_down</Icon>} 
  //     loading={this.state.isLoadingBatchAction}
  //     disabled={this.state.isDisabledBatchAction}
  //     menuItems={[
  //       {
  //         id: 5,
  //         text: 'Bulk Update',
  //       },
  //       {
  //         id: 1,
  //         text: 'Remind all',
  //       },
  //       {
  //         id: 2,
  //         text: 'Dispatch all',
  //       },
  //       {
  //         id: 3,
  //         text: 'Download all',
  //       },
  //       {
  //         id: 4,
  //         text: 'Assign To',
  //       }
  //     ]} 
  //     onClick1={(item) => {
  //       if(!unassignedState){
  //         this.setState({
  //           isLoadingBatchAction: true,
  //         }, () => {
  //           this.dispatchAll(() => {
  //             this.setState({
  //               isLoadingBatchAction: false,
  //             });
  //           });
  //         });

  //       } else {
  //         this.setState({
  //           isLoadingBatchAction: false,
  //         }, () => {
  //           this.assignAll();
  //         });
  //       }
  //     }}
  //     onClick2={(item) => {
  //       if(item.id === 1){
  //         this.setState({
  //           isLoadingBatchAction: true,
  //         }, () => {
  //           this.remindAll(() => {
  //             this.setState({
  //               isLoadingBatchAction: false,
  //             });
  //           });
  //         });

  //       } else if(item.id === 2){
  //         this.setState({
  //           isLoadingBatchAction: true,
  //         }, () => {
  //           this.dispatchAll(() => {
  //             this.setState({
  //               isLoadingBatchAction: false,
  //             });
  //           });
  //         });

  //       } else if(item.id === 3){
  //         this.setState({
  //           isLoadingBatchAction: true,
  //         }, () => {
  //           this.downloadAll(() => {
  //             this.setState({
  //               isLoadingBatchAction: false,
  //             });
  //           });
  //         });
  //       } else if(item.id === 4){
  //         this.setState({
  //           isLoadingBatchAction: false,
  //         }, () => {
  //           this.assignAll();
  //         });

  //       } else if(item.id === 5){
  //         let rows = this.getCheckedRows();
  //         let jobIds = (rows && rows.length > 0) ? rows.map(x => x.jobId).join(',') : '';
          
  //         this.setState({
  //           popupShow: true,
  //           popupLoading: false,
  //           popupData: jobIds,
  //           popupHasChecked: true,
  //           popupIsEdited: false,
  //         });
  //       }
  //     }}
  //   />
  // }

  setActiveDropdown = () => {
    return <Dropdown alignRight>
      <Dropdown.Toggle as={DropdownToggle}>
        {Utils.getActiveDeletedText(this.state.isInactive)} Jobs
        <ArrowDropDownIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          active={this.state.isInactive == false}
          onClick={() => {
            this.setState({
              isIncludeInactive: null,
              isInactive: false,
              jobStatusFilter: '',
              jobTemplateFilter: null,
              currentPage: 1,
            }, () => {
              if(this.props.onTypesReset){
                this.props.onTypesReset();
              }
              
              this.setFilterInLocalStorage();
              this.callReadApi();
            });
          }} 
        >Active</Dropdown.Item>
        <Dropdown.Item
          active={this.state.isInactive == true}
          onClick={() => {
            this.setState({
              isIncludeInactive: null,
              isInactive: true,
              jobStatusFilter: '',
              jobTemplateFilter: null,
              currentPage: 1,
            }, () => {
              if(this.props.onTypesReset){
                this.props.onTypesReset();
              }
              
              this.setFilterInLocalStorage();
              this.callReadApi();
            });
          }} 
        >Deleted</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  }

  setAdvancedSearch = () => {
    return <DropdownIconButtonMenu 
      ref={this.refDropdownAdvacedSearch}
      className={'job-list-icon-button-dropdown'}
      overflowVisible={true}
      selected={null}
      icon={<FilterIcon className={((Utils.filterParams(this.state.filterParams).length > 0) ? 'svg-fill-active' : 'advanced-filter-svg-inactive')}/>}
    >
      <AdvancedFilter
        columns={this.state.columns}
        columnVisibility={this.state.columnVisibility}

        filterParams={this.state.filterParams}
        searchOperator={this.state.searchOperator}

        searchColumnKey={this.state.searchColumnKey}

        onSearch={(filterParams, searchOperator) => {
          if(this.refDropdownAdvacedSearch && this.refDropdownAdvacedSearch.current){
            this.refDropdownAdvacedSearch.current.hide();
          }

          this.setState({
            isLoadingTable: true,
            filterParams: filterParams,
            searchOperator: searchOperator,
            jobStatusFilter: '',
            jobTemplateFilter: null,
            currentPage: 1,
          }, () => {
            if(this.props.onLoadingTable){
              this.props.onLoadingTable(this.state.isLoadingTable);
            }

            if(this.props.onTypesReset){
              this.props.onTypesReset();
            }

            this.setFilterInLocalStorage();
            this.callReadApi(() => {
              this.setState({
                totalRows: 0,
                rows: [],
                isLoadingTable: false,
                isLoading: false
              }, () => {
                if(this.props.onLoadingTable){
                  this.props.onLoadingTable(this.state.isLoadingTable);
                }
              });
            });
          });
        }}
        onCancel={() => {
          if(this.refDropdownAdvacedSearch && this.refDropdownAdvacedSearch.current){
            this.refDropdownAdvacedSearch.current.hide();
          }
        }}

        onClear={() => {
          this.setState({
            isLoadingTable: true,
            filterParams: [],
            searchOperator: [],
            jobStatusFilter: '',
            jobTemplateFilter: null,
            currentPage: 1,
          }, () => {
            if(this.props.onLoadingTable){
              this.props.onLoadingTable(this.state.isLoadingTable);
            }

            if(this.props.onTypesReset){
              this.props.onTypesReset();
            }

            if(this.refDropdownAdvacedSearch && this.refDropdownAdvacedSearch.current){
              this.refDropdownAdvacedSearch.current.hide();
            }

            this.setFilterInLocalStorage();
            this.callReadApi(() => {
              this.setState({
                totalRows: 0,
                rows: [],
                isLoadingTable: false,
                isLoading: false
              }, () => {
                if(this.props.onLoadingTable){
                  this.props.onLoadingTable(this.state.isLoadingTable);
                }
              });
            });
          });
        }}
      />
    </DropdownIconButtonMenu>
  }

  setSearch = () => {
    return <IconButtonSearch 
      value={this.state.search}
      isOpen={(this.state.search !== '') ? true : false}
      disabled={this.state.isLoadingTable}
      onPressEnter={(value) => {
        this.setState({
          isLoadingTable: true,
          search: value,
          jobStatusFilter: '',
          jobTemplateFilter: null,
          currentPage: 1,
        }, () => {
          if(this.props.onLoadingTable){
            this.props.onLoadingTable(this.state.isLoadingTable);
          }

          if(this.props.onTypesReset){
            this.props.onTypesReset();
          }

          this.setFilterInLocalStorage();
          this.callReadApi(() => {
            this.setState({
              totalRows: 0,
              rows: [],
              isLoadingTable: false,
              isLoading: false
            }, () => {
              if(this.props.onLoadingTable){
                this.props.onLoadingTable(this.state.isLoadingTable);
              }
            });
          });
        });
      }}
      onClear={() => {
        this.setState({
          isLoadingTable: true,
          search: '',
          jobStatusFilter: '',
          jobTemplateFilter: null,
          currentPage: 1,
        }, () => {
          if(this.props.onLoadingTable){
            this.props.onLoadingTable(this.state.isLoadingTable);
          }

          if(this.props.onTypesReset){
            this.props.onTypesReset();
          }

          this.setFilterInLocalStorage();
          this.callReadApi(() => {
            this.setState({
              totalRows: 0,
              rows: [],
              isLoadingTable: false,
              isLoading: false
            }, () => {
              if(this.props.onLoadingTable){
                this.props.onLoadingTable(this.state.isLoadingTable);
              }
            });
          });
        });
      }}
    />
  }

  setSettingsDropdown = (realTimeUpdateSelected) => {
    // this.forceUpdate();

    return <Dropdown alignRight={true}>
      <Dropdown.Toggle as={DropdownToggle} isButton className={'px-3'} variant={"light"} size={"sm"}>
      <SettingsOutlinedIcon />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item
          active={false}
          onClick={() => {
            this.callDownloadPDFAllApi((data, isError, err) => {
              if(!isError){
                this.tableRef.current.exportPDF(data);
              }
            });
          }} 
        >
          Download all in PDF format
        </Dropdown.Item>
        <Dropdown.Item
          active={false}
          onClick={() => {
            this.callDownloadExcelAllApi((data, isError, err) => {
              if(!isError){
                let type = 'application/vnd.ms-excel';
                Utils.saveByteArray('Jobs', data, type);
              }
            });
          }} 
        >
          Download all in Excel format
        </Dropdown.Item>

        <Dropdown.Divider />

        <Dropdown.Item
          active={false}
          onClick={() => {
            this.callReadByPageApi((data) => {
              this.tableRef.current.print(data);

              this.setState({
                isLoadingBackdrop: false,
              });
            });
          }} 
        >
          Print this page
        </Dropdown.Item>
        <Dropdown.Item
          active={false}
          onClick={() => {
            this.callReadAllApi((data) => {
              this.tableRef.current.print(data);

              this.setState({
                isLoadingBackdrop: false,
              });
            });
          }} 
        >
          Print all pages
        </Dropdown.Item>

        {/* <Dropdown.Divider />

        <Dropdown.Item
          active={true}
          onClick={() => {
            this.props.history.push("/Jobs");
          }} 
        >
          List View
        </Dropdown.Item>
        <Dropdown.Item
          active={false}
          onClick={() => {
            this.props.history.push("/Gantt-chart");
          }} 
        >
          Gantt Chart View
        </Dropdown.Item> */}
        
        <Dropdown.Divider />

        <Dropdown.Item
          active={false}
          onClick={async () => {
            this.props.action.importWeighChit.show();
            this.props.action.weighChitCustomFields.callReadApi();
          }} 
        >
          Import weigh chit
        </Dropdown.Item>

        <Dropdown.Divider />

        <Dropdown.ItemText>
          <StyledRealTimeItem>
            <div className={'title'}>{this.props.realTimeUpdate.title}</div>
            <div className={'item'}>
              <div className={'item-title'}>
                {realTimeUpdateSelected}
                <span>{(realTimeUpdateSelected != 'Real-time') ? Utils.formatTimeCountdown(this.props.realTimeUpdate.timeLeft) : ''}</span>
              </div>
              <div className={'item-icons'}>
                <div className={'item-icon-up'}>
                  <i 
                    className={'material-icons item-icon-up'}
                    onClick={async (e) => {
                      await this.props.action.realTimeUpdate.up({
                        auth: this.props.auth,
                        settingsType: this.props.settingsType,
                        pageName: this.pageName,
                      });
                    }} 
                  >arrow_drop_up</i>
                </div>
                <div className={'item-icon-down'}>
                  <i 
                    className={'material-icons  item-icon-down'}
                    onClick={async (e) => {
                      await this.props.action.realTimeUpdate.down({
                        auth: this.props.auth,
                        settingsType: this.props.settingsType,
                        pageName: this.pageName,
                      });
                    }} 
                  >arrow_drop_down</i>
                </div>
              </div>
            </div>
          </StyledRealTimeItem>
        </Dropdown.ItemText>
        
      </Dropdown.Menu>
    </Dropdown>
  }

  setColomnDropdown = () => {
    return <DropdownMenu
      className={'px-3'}
      variant={"light"}
      size={"sm"}
      text={<ViewHeadlineIcon />}
    >
      <DataTableActionMenu 
        pageName={this.pageName}
        columns={this.state.columns}
        selected={this.state.rowsPerPageSelected}
        selectItems={this.state.rowsPerPageOptions}
        columnVisibility={this.state.columnVisibility}
        canSelectOption={false}
        onChangeColumnVisibility={(index, value) => {
          let colVis = this.state.columnVisibility;
          colVis[index] = value;

          this.setState({
            columnVisibility: colVis
          });

          this.setFilterInLocalStorage();
        }}
        includeInactiveText={'Include Deleted'}
        canInactive={false}
        isCheckedIncludeInactive={this.state.isIncludeInactive === true || this.state.isIncludeInactive === false ? this.state.isIncludeInactive : false}
        onSelectChangeAjax={(val) => {
          this.setState({
            jobStatusFilter: '',
            jobTemplateFilter: null,
            currentPage: 1,
            rowsPerPageSelected: val
          }, () => {
            if(this.props.onTypesReset){
              this.props.onTypesReset();
            }

            this.setFilterInLocalStorage();
            this.callReadApi();
          });
        }}
        onChangeIncludeInactive={(val) => {
          this.setState({
            isIncludeInactive: val,
            jobStatusFilter: '',
            jobTemplateFilter: null,
            currentPage: 1,
          }, () => {
            if(this.props.onTypesReset){
              this.props.onTypesReset();
            }
            
            this.setFilterInLocalStorage();
            this.callReadApi();
          });
        }}
        isCookies={this.state.isCookies}
        onResetColumns={() => {
          this.setState({
            columns: Array.from(this.state.initColumns),
            columnVisibility: Array.from(this.state.initColumnVisibility),
            sortColumn: this.state.sortColumnInit,
            sortDir: this.state.sortDirInit,
          }, () => {
            this.setFilterInLocalStorage(false);
            this.callReadApi();
            if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
              this.refDropdownActionMenu.current.hide();
            }
          });
        }}
        onShowMore={(isShowedMore) => {
          this.setState({
            pageName: this.pageName,
          });
        }}
      />
    </DropdownMenu>
  }

  getBatchAction = () => {
    return <Dropdown alignRight>
      <Dropdown.Toggle>
        Batch Action
      </Dropdown.Toggle>
      <Dropdown.Menu>

        <Dropdown.Item
          active={false}
          onClick={() => {
            this.remindAll(() => {
              this.setState({
                isLoadingBatchAction: false,
              });
            });
          }} 
        >Remind all</Dropdown.Item>

        <Dropdown.Item
          active={false}
          onClick={() => {
            this.dispatchAll(() => {
              this.setState({
                isLoadingBatchAction: false,
              });
            });
          }} 
        >Dispatch all</Dropdown.Item>

        <Dropdown.Item
          active={false}
          onClick={() => {
            this.downloadAll(() => {
              // this.setState({
              //   isLoadingBatchAction: false,
              // });
            });
          }} 
        >Download all</Dropdown.Item>

        <Dropdown.Item
            active={false}
            onClick={() => {
              let rows = this.getCheckedRows();
              let jobIds = (rows && rows.length > 0) ? rows.map(x => x.jobId).join(',') : '';
              
              this.setState({
                popupShow: true,
                popupLoading: false,
                popupData: jobIds,
                popupHasChecked: true,
                popupIsEdited: false,
              });
            }} 
          >Update weigh chit</Dropdown.Item>

        {/* <Dropdown.Item
          active={false}
          onClick={() => {
            this.assignAll();
          }} 
        >Assign To</Dropdown.Item> */}

      </Dropdown.Menu>
    </Dropdown>
  }

  getBulkUpdate = () => {
    return <Button
      variant={'light'}
      onClick={() => {
        let rows = this.getCheckedRows();
        let jobIds = (rows && rows.length > 0) ? rows.map(x => x.jobId).join(',') : '';
        
        this.props.action.bulkUpdateJobForm.setShow(jobIds);
      }} 
    >Bulk Update</Button>

    // return <Dropdown alignRight>
    //   <Dropdown.Toggle variant={'light'}>
    //     Bulk Update
    //   </Dropdown.Toggle>
    //   <Dropdown.Menu>

    //       <Dropdown.Item
    //           active={false}
    //           onClick={() => {
    //             let rows = this.getCheckedRows();
    //             let jobIds = (rows && rows.length > 0) ? rows.map(x => x.jobId).join(',') : '';
                
    //             this.setState({
    //               popupShow: true,
    //               popupLoading: false,
    //               popupData: jobIds,
    //               popupHasChecked: true,
    //               popupIsEdited: false,
    //             });
    //           }} 
    //       >Weigh Chit</Dropdown.Item>

    //       {/* <Dropdown.Item
    //           active={false}
    //           onClick={() => {
    //               let selected = Utils.getSelected(this.tableRef);

    //               this.setState({
    //                   popupShowMailTemplate: true,
    //                   popupLoadingMailTemplate: false,
    //                   popupIdMailTemplate: selected,
    //               });
    //           }} 
    //       >Job Form</Dropdown.Item> */}

    //   </Dropdown.Menu>
    // </Dropdown>
  }
  setSelectedItemsCount = () => {
    return <span>{((this.tableRef && this.tableRef.current) ? this.tableRef.current.getSelected().length : '0')} item(s) selected</span>
  }
  /* END FUNCTIONS */


  /* API */
  callReadApi = (callback = null) => {
    if(this.cancelTokenList){
      this.cancelTokenList.cancel(CancelToken.CANCELLED)
    }
    this.cancelTokenList = axios.CancelToken.source();

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    this.setState({
      isLoading: true,
      isLoadingTable: true,
      rows: [],
    }, () => {
      if(this.props.onLoadingTable){
        this.props.onLoadingTable(this.state.isLoadingTable);
      }
    });

    let data = {
    currentPage: this.state.currentPage,
    pageSize: this.state.rowsPerPageSelected,
    searchQuery: this.state.search,
    fromDate: fromDate,
    toDate: toDate,
    jobTemplateFilter: this.state.jobTemplateFilter,
    jobStatusFilter: this.state.jobStatusFilter,
    sortColumn: this.state.sortColumn,
    sortDir: this.state.sortDir,
    isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
    };

    if(this.state.filterParams && this.state.filterParams.length > 0){
    data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
    data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }
        
    this.callStatApi();
    this.callTypesApi();

		this.props.dispatchApiCallPost(data, 'job-table-section_job-list', 'job/get-list', null, null, callback, this.cancelTokenList);
	}
  
  callReadWithoutStatusApi = (callback = null) => {
    if(this.cancelTokenList2){
      this.cancelTokenList2.cancel(CancelToken.CANCELLED)
    }
    this.cancelTokenList2 = axios.CancelToken.source();

    this.setState({
      isLoading: true,
      isLoadingTable: true,
      rows: [],
    }, () => {
      if(this.props.onLoadingTable){
        this.props.onLoadingTable(this.state.isLoadingTable);
      }
    });

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    let data = {
    currentPage: this.state.currentPage,
    pageSize: this.state.rowsPerPageSelected,
    searchQuery: this.state.search,
    fromDate: fromDate,
    toDate: toDate,
    jobTemplateFilter: this.state.jobTemplateFilter,
    jobStatusFilter: this.state.jobStatusFilter,
    sortColumn: this.state.sortColumn,
    sortDir: this.state.sortDir,
    isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
    isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
    };

    if(this.state.filterParams && this.state.filterParams.length > 0){
    data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
    data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }
        
		this.props.dispatchApiCallPost(data, 'job-table-section_job-list', 'job/get-list', null, null, callback, this.cancelTokenList2);
	}

  callDispatchAllApi = (data, callback = null) => {
    this.setState({
      isLoadingBatchAction: true,
    });
    
    this.props.dispatchApiCallPut(data, 'job-table-section_dispatch-all', 'job/' + data.jobId + '/batch-dispatch', null, callback, null);
  }

  callSendNotificationToDriverApi = (jobId = 0, callback = null) => {
    this.setState({
      isLoadingNotification: true,
    });

		this.props.dispatchApiCallGet(null, 'job-table-section_send-notification-to-driver', 'job/send-notification-to-driver/' + jobId, null, callback, null);
  }

  callMergeJobDocApi = async (jobIds = '', callback = null) => {
    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });


    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    let data = {
      jobIds: jobIds,
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      fromDate: fromDate,
      toDate: toDate,
      jobTemplateFilter: this.state.jobTemplateFilter,
      jobStatusFilter: this.state.jobStatusFilter,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
      hubId: this.props.signalRslice.hubId,
    };

    if(this.state.filterParams && this.state.filterParams.length > 0){
      data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
      data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }


		this.props.dispatchApiCallPost(data, 'job-table-section_merge-job-doc', 'job/merge-job-doc', null, callback, () => {});
  }

  callBatchStepBinWeightApi = async (data = null, callback = null) => {
    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });

    this.props.dispatchApiCallPut({ data: data, hubId: this.props.signalRslice.hubId }, 'job-table-section_batch-step-bin-weight', 'job/weight-chit/batch-step-bin-weight', null, callback, () => {});
  }

  callReadByPageApi = (callback = null) => {
    this.setState({
      isLoadingBackdrop: true,
      isLoadingTable: true,
      rows: [],
    }, () => {
      if(this.props.onLoadingTable){
        this.props.onLoadingTable(this.state.isLoadingTable);
      }
    });

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    let data = {
      currentPage: this.state.currentPage,
      pageSize: this.state.rowsPerPageSelected,
      searchQuery: this.state.search,
      fromDate: fromDate,
      toDate: toDate,
      jobTemplateFilter: this.state.jobTemplateFilter,
      jobStatusFilter: this.state.jobStatusFilter,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
    };

    if(this.state.filterParams && this.state.filterParams.length > 0){
      data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
      data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallPost(data, 'job-table-section_job-list-by-page', 'job/get-list', null, callback, null);
  }

  callReadAllApi = (callback = null) => {
    this.setState({
      isLoadingBackdrop: true,
      isLoadingTable: true,
      rows: [],
    }, () => {
      if(this.props.onLoadingTable){
        this.props.onLoadingTable(this.state.isLoadingTable);
      }
    });

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      fromDate: fromDate,
      toDate: toDate,
      jobTemplateFilter: this.state.jobTemplateFilter,
      jobStatusFilter: this.state.jobStatusFilter,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
    };

    if(this.state.filterParams && this.state.filterParams.length > 0){
      data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
      data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallPost(data, 'job-table-section_job-list-read-all', 'job/get-list', null, callback, null);
  }

  callDownloadPDFAllApi = async (callback = null) => {
    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      fromDate: fromDate,
      toDate: toDate,
      jobTemplateFilter: this.state.jobTemplateFilter,
      jobStatusFilter: this.state.jobStatusFilter,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
      hubId: this.props.signalRslice.hubId,
    };

    if(this.state.filterParams && this.state.filterParams.length > 0){
      data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
      data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallPost(data, 'job-table-section_job-list-download-pdf', 'job/get-list', null, callback, null);
  }

  callDownloadExcelAllApi = async (callback = null) => {
		// Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
    // this.setState({
    //   isLoadingBackdrop: true,
    // });

    await this.props.action.signalRslice.connect({
      callback: (percent) => {
        this.props.action.signalRslice.onPercentage(percent);
      },
      callbackInit: (hubId) => {}
    });

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    let data = {
      currentPage: 1,
      pageSize: Utils.getMaxPageSize(),
      searchQuery: this.state.search,
      fromDate: fromDate,
      toDate: toDate,
      jobTemplateFilter: this.state.jobTemplateFilter,
      jobStatusFilter: this.state.jobStatusFilter,
      sortColumn: this.state.sortColumn,
      sortDir: this.state.sortDir,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
      hubId: this.props.signalRslice.hubId,
    };

    if(this.state.filterParams && this.state.filterParams.length > 0){
      data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
      data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallGet(data, 'job-table-section_job-list-download-excel', 'job/download-excel', null, callback, null);
  }

  callDownloadFileApi = (jobId = 0, callback = null) => {
		this.setState({
			downloadIsLoading: true,
		});

		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
		this.props.dispatchApiCallGet(null, 'job-table-section_job-list-download-file', 'job/get-job-doc/' + jobId, null, callback, null);
  }
  
  callDownloadInternalNoteIsLoadingApi = (jobId = 0, callback = null) => {
		this.setState({
			downloadInternalNoteIsLoading: true,
		});

		Utils.toast(<div style={{ fontWeight: 'bold' }}>Please Wait ...</div>, 'info');
    
		this.props.dispatchApiCallGet(null, 'job-table-section_job-list-download-internal-note', 'job/get-job-internal-note/' + jobId, null, callback, null);
  }

  callDispatchApi = (row, data, callback = null) => {
    this.props.dispatchApiCallPut(row, 'job-table-section_dispatch', 'job/' + row.jobId + '/dispatch', null, callback, null);
  }

  callSearchDriversApi = (searchQuery = "", callback = null) => {
    let data = {
      currentPage: 1,
      pageSize: 30,
      searchQuery: searchQuery
    };

		this.props.dispatchApiCallGet(data, 'job-table-section_driver-list', 'driver', null, callback, null);
  }
  
  callSearchVehiclesApi = (searchQuery = "", callback = null) => {
    let data = {
      currentPage: 1,
      pageSize: 30,
      searchQuery: searchQuery
    };

		this.props.dispatchApiCallGet(data, 'job-table-section_vehicle-list', 'vehicle', null, callback, null);
  }

  callReadDriversApi = (searchQuery = "", callback = null) => {
    let data = {
      currentPage: 1,
      pageSize: 30,
      searchQuery: searchQuery
    };

		this.props.dispatchApiCallGet(data, 'job-table-section_driver-list-assignto', 'driver', null, callback, null);
  }

  callUpdateRemarksApi = (data, callback = null) => {
    this.setState({
      isLoadingRemarks: true,
    });
    
    this.props.dispatchApiCallPut(data, 'job-table-section_remarks', 'job/' + data.jobId + '/remarks', null, callback, null);
  }

  callUpdateSiteRemarksApi = (data, callback = null) => {
    this.setState({
      isLoadingSiteRemarks: true,
    });
    
    this.props.dispatchApiCallPut(data, 'job-table-section_site-remarks', 'job/' + data.jobId + '/step', null, callback, null);
  }

  callUpdatePriorityApi = (data, callback = null) => {
    this.setState({
      isLoadingPriority: true,
    });
    
    this.props.dispatchApiCallPut(data, 'job-table-section_priority', 'job/' + data.jobId + '/priority', null, callback, null);
  }

  callUpdateBinWeightApi = (data, callback = null) => {
    this.setState({
      isLoadingBinWeight: true,
    });
    
    this.props.dispatchApiCallPut(data, 'job-table-section_bin-weight', 'job/' + data.jobId + '/step-bin-weight', null, callback, null);
  }

  callStatApi = (isLoading = false) => {
    if(this.cancelTokenStat1){
      this.cancelTokenStat1.cancel(CancelToken.CANCELLED)
    }
    this.cancelTokenStat1 = axios.CancelToken.source();

    if(this.props.onStatusesLoading){
      this.props.onStatusesLoading(true);
    }

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    this.setState({
      isLoadingStatus: isLoading,
    });

    let data = {
      searchQuery: this.state.search,
      fromDate: fromDate,
      toDate: toDate,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
      // jobTemplateFilter: this.state.jobTemplateFilter,
      // jobStatusFilter: this.state.jobStatusFilter
    };
    
    if(this.state.filterParams && this.state.filterParams.length > 0){
      data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
      data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallPost(data, 'job-table-section_get-stat', 'job/status/stat', null, null, () => {}, this.cancelTokenStat1);
  }

  callTypesApi = (isLoading = false) => {
    if(this.cancelTokenStat2){
      this.cancelTokenStat2.cancel(CancelToken.CANCELLED)
    }
    this.cancelTokenStat2 = axios.CancelToken.source();

    if(this.props.onTypesLoading){
      this.props.onTypesLoading(true);
    }

    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

    this.setState({
      isLoadingTypes: isLoading,
    });

    let data = {
      searchQuery: this.state.search,
      fromDate: fromDate,
      toDate: toDate,
      isIncludeInactive: ((this.state.isIncludeInactive === true) || (this.state.isIncludeInactive === false)) ? this.state.isIncludeInactive : false,
      isInactive: ((this.state.isInactive === true) || (this.state.isInactive === false)) ? this.state.isInactive : false,
    };
    
    if(this.state.filterParams && this.state.filterParams.length > 0){
      data['filterParams'] = Utils.filterParams(this.state.filterParams);
    }
    if(this.state.searchOperator && this.state.searchOperator.length > 0){
      data['searchOperator'] = Utils.getSelectedOperator(this.state.searchOperator);
    }

		this.props.dispatchApiCallPost(data, 'job-table-section_get-type-stat', 'job/type/stat', null, null, () => {}, this.cancelTokenStat2);
  }

  callDeleteApi = (data, callback = null) => {
		this.props.dispatchApiCallDelete(data, 'job-table-section_delete', 'job', null, callback, null);
  }

  callDetailsApi = (id = null, row, callback = null) => {
    if (id) {
      this.setState({
        isLoadingBackdrop: true,
      });

      this.props.dispatchApiCallGet(null, 'job-table-section_job-details', 'job/' + id, row, callback, () => { });
    } else {
      if (callback) {
        callback(row)
      }
    }
  }
	/* END API */


  /* BATCH ACTION */
  dispatchAll = (callback = null) => {
    let rows = this.getCheckedRows();
                    
    let assignedStatus = this.findStatus('assigned');
    if(assignedStatus && assignedStatus.jobStatusName){
      let jobs = [];
      if(rows && rows.length > 0){
        for(let j = 0; j < rows.length; j++){
          let job = rows[j];
          
          if(job && job.statusName){
            if(job.statusName.toLowerCase() === assignedStatus.jobStatusName.toLowerCase()){
              jobs.push(job);
            }
          }
        }
      }
      
      if(jobs && jobs.length > 0){
        let jobIds = [];
        if(jobs && jobs.length > 0){
          for(let j = 0; j < jobs.length; j++){
            let job = jobs[j];
            
            if(job.driverId && job.driverId > 0){
              jobIds.push(job.jobId);
            }
          }
        }

        if(jobIds && jobIds.length > 0){
          let dispatchedStatus = this.findStatus('dispatched');
          if(dispatchedStatus && jobIds && jobIds.length > 0){
            let row = {
              jobIds: jobIds.join(','),
              jobStatusId: dispatchedStatus.jobStatusId,
            };
            this.callDispatchAllApi(row, () => {
              if(callback){
                callback();
              }
            });
          } else {
            Utils.toast('There are no assigned jobs!', 'error'); 
            
            if(callback){
              callback();
            }
          }
        } else {
          Utils.toast('You must assign a driver to the jobs you want to dispatch!', 'error'); 
          
          if(callback){
            callback();
          }
        }
      } else {
        Utils.toast('There are no assigned jobs!', 'error'); 
        
        if(callback){
          callback();
        }
      }
    } else {
      Utils.toast('An error has occurred!', 'error'); 

      if(callback){
        callback();
      }
    }
  }

  remindAll = (callback = null) => {
    let rows = this.getCheckedRows();

    let dispatchedStatus = this.findStatus('dispatched');
    if(dispatchedStatus){
      let jobIds = [];
      if(rows && rows.length > 0){
        for(let j = 0; j < rows.length; j++){
          let job = rows[j];
          
          if(job && job.statusName){
            if(job.statusName.toLowerCase() === dispatchedStatus.jobStatusName.toLowerCase()){
              jobIds.push(job.jobId);
            }
          }
        }
      }

      if(jobIds && jobIds.length > 0){
        for(let i = 0; i < jobIds.length; i++){
          this.callSendNotificationToDriverApi(jobIds[i]);
        }

        if(callback){
          callback();
        }
      } else {
        Utils.toast('No dispatched jobs!', 'error');
        
        if(callback){
          callback();
        }
      }
    } else {
      Utils.toast('An error has occurred!', 'error');
      
      if(callback){
        callback();
      }
    }
  }

  downloadAll = (callback = null) => {
    let rows = this.getCheckedRows();

    if(rows && rows.length > 0) {
      let jobIds = '';
      try {
        jobIds = rows.map(x => x.jobId).join(',')
      } catch(e){}

      this.callMergeJobDocApi(jobIds, (data) => {
        if(data){
          Utils.saveByteArray(data.fileName, data.fileBytes);

          if(callback){
            callback();
          }
        } else {
          if(callback){
            callback();
          }
        }
      });
    } else {
      Utils.toast('You must select a row!', 'error');
      
      if(callback){
        callback();
      }
    }
  }

  assignAll = () => {
    let unassignedStatus = this.findStatus('unassigned');
    if(unassignedStatus){
      let rows = this.getCheckedRows();
      
      let jobs = [];
      if(rows && rows.length > 0){
        for(let j = 0; j < rows.length; j++){
          let job = rows[j];
          
          if(job && job.statusName){
            if(job.statusName.toLowerCase() === unassignedStatus.jobStatusName.toLowerCase()){
              jobs.push(job);
            }
          }
        }
      }

      if(jobs && jobs.length > 0){
        let jobIds = [];
        for(let j = 0; j < jobs.length; j++){
          let job = jobs[j];
          if(job.driverId === null || job.driverId === 0){
            jobIds.push(job.jobId);
          }
        }

        let assignedStatus = this.findStatus('assigned');
        let dispatchedStatus = this.findStatus('dispatched');
        if(jobIds && jobIds.length > 0){
          let dataParamsAssignTo = {
            jobIds: jobIds.join(','),
            assignedStatusId: assignedStatus ? assignedStatus.jobStatusId : null,
            dispatchedStatusId: assignedStatus ? dispatchedStatus.jobStatusId : null,
          };
          Utils.setDrawerData(this.formDrawerAssignTo, dataParamsAssignTo);
          Utils.showDrawer(this.formDrawerAssignTo);
        } else {
          Utils.toast('There are no assigned status!', 'error');
        }
      } else {
        Utils.toast('There are no unassigned jobs!', 'error');
      }
    } else {
      Utils.toast('An error has occurred!', 'error');
    }
  }
  /* END BATCH ACTION */



	
	setDataTable = () => {
    return <DataTable 
      ref={this.tableRef}

      className={"table-style-v4"}
      classNameWrapper={"table-v4"}

      pageName={this.pageName}
      rowId={'jobId'}
      caption={'Jobs'}

      noMarginBottom={false}
      verticalAlign={'middle'}

      stickyHeader={false}
      isHeader={true}
      resizable={true}
      isDragableHeaderColumns={true}
      isDragable={this.state.isDragable}
      isCheckbox={this.state.isCheckbox}
      isBorder={this.state.isBorder}
      isPagination={this.state.isPagination}
      
      disabledResizeColumns={[0]}
      columnVisibility={this.state.columnVisibility}

      isLoading={this.state.isLoadingTable}
      rowsPerPageSelected={this.state.rowsPerPageSelected}
      rowsPerPageOptions={this.state.rowsPerPageOptions}

      search={this.state.search}
      rows={this.state.rows}
      columns={this.state.columns}
      
      // customHeader={this.setCustomHeader}

      disableRowClick={this.state.isMenuSubItemsOpen}
      onRowClick={(id, row) => {
        this.props.history.push((row?.version == 1) ? '/v5/job-form-beta?id=' + row.jobId : '/jobs-form?id=' + row.jobId);
      }}

      onRemove={(row) => {
        if(row){
          this.tableRef.current.flash(row);
          
          let rowData = {
            jobId: row.jobId
          };
        
          this.callDeleteApi(rowData, () => {
            this.callReadApi();
            this.callStatApi();
            this.callTypesApi();
          });
        }
      }}

      onCheckAll={(obj) => {
        let hasChecked = (this.getCheckedRows().length > 0) ? true : false;

        this.setState({
          isDisabledBatchAction: hasChecked,
        }, () => {
          if(this.props.onChangeBatchAction){
            this.props.onChangeBatchAction(hasChecked);
          }
        });
      }}
      onCheckRow={(obj, id) => {
        let hasChecked = (this.getCheckedRows().length > 0) ? true : false;

        this.setState({
          isDisabledBatchAction: hasChecked
        }, () => {
          if(this.props.onChangeBatchAction){
            this.props.onChangeBatchAction(hasChecked);
          }
        });
      }}

      onSortEnd={(columns, columnVisibility) => {
        this.setState({
          columns: columns,
          columnVisibility: columnVisibility,
        }, () => {
          this.setFilterInLocalStorage();
        });
      }}
      onOrder={(name, sort) => {
        if(this.tableRef && this.tableRef.current){
          this.tableRef.current.setSelected([], () => {
            this.setState({
              isDisabledBatchAction: false,
            }, () => {
              if(this.props.onChangeBatchAction){
                this.props.onChangeBatchAction(false);
              }
            });
          });
        }
        
        this.setState({
          sortColumn: name,
          sortDir: sort,
        }, () => {
          this.setFilterInLocalStorage();
          this.callReadApi(false);
        });
      }}

      onResize={(columns) => {
        if(columns){
          this.setState({
            columns: columns
          });
        }
        this.setFilterInLocalStorage();
        this.forceUpdate()
      }}
    />
  }
    setCustomToolbar = () => {
      // let unassignedState = false;
      // let unassignedStatus = this.findStatus('unassigned');
      // if(unassignedStatus){
      //   if(unassignedStatus.jobStatusId === this.state.jobStatusFilter){
      //     unassignedState = true;
      //   }
      // }

      return <div>
        <div className='row align-items-center d-block d-md-flex '>
            <div className='col-12 col-md-auto col-xl-auto'>
              
            </div>
            <div className='col-auto d-none d-md-flex align-items-center'>
              <DropdownIconButtonMenu 
                ref={this.refDropdownAdvacedSearch}
                className={'job-list-icon-button-dropdown'}
                overflowVisible={true}
                selected={null}
                icon={<FilterIcon className={((Utils.filterParams(this.state.filterParams).length > 0) ? 'svg-fill-active' : '')}/>}
              >
                <div>
                  <AdvancedFilter
                    columns={this.state.columns}
                    columnVisibility={this.state.columnVisibility}

                    filterParams={this.state.filterParams}
                    searchOperator={this.state.searchOperator}

                    searchColumnKey={this.state.searchColumnKey}

                    onSearch={(filterParams, searchOperator) => {
                      if(this.refDropdownAdvacedSearch && this.refDropdownAdvacedSearch.current){
                        this.refDropdownAdvacedSearch.current.hide();
                      }

                      this.setState({
                        filterParams: filterParams,
                        searchOperator: searchOperator,
                        jobStatusFilter: '',
                        jobTemplateFilter: null,
                        currentPage: 1,
                      }, () => {
                        if(this.props.onTypesReset){
                          this.props.onTypesReset();
                        }

                        this.setFilterInLocalStorage();
                        this.callReadApi(() => {
                          this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                          });
                        });
                      });
                    }}
                    onCancel={() => {
                      if(this.refDropdownAdvacedSearch && this.refDropdownAdvacedSearch.current){
                        this.refDropdownAdvacedSearch.current.hide();
                      }
                    }}
                  />
                </div>
                </DropdownIconButtonMenu>

                {(Utils.filterParams(this.state.filterParams).length > 0) && <a 
                  className={'link-danger'}
                  href={'/'}
                  onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      
                      this.setState({
                        filterParams: [],
                        searchOperator: [],
                        jobStatusFilter: '',
                        jobTemplateFilter: null,
                        currentPage: 1,
                      }, () => {
                        if(this.props.onTypesReset){
                          this.props.onTypesReset();
                        }

                        this.setFilterInLocalStorage();
                        this.callReadApi(() => {
                          this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                          });
                        });
                      });
                  }}
                >Clear Filters</a>}
            </div>
            <div className='col-12 d-block d-md-none'></div>
            <div className='col text-center text-md-end'>
              <div className='d-inline-flex align-items-center text-start'>
                <ExpandingSearchBox 
                  btnClassName={'manage-icon-btn'}
                  value={this.state.search}
                  isOpen={(this.state.search !== '') ? true : false}
                  onPressEnter={(value) => {
                    this.setState({
                      search: value,
                      jobStatusFilter: '',
                      jobTemplateFilter: null,
                      currentPage: 1,
                    }, () => {
                      if(this.props.onTypesReset){
                        this.props.onTypesReset();
                      }

                      this.setFilterInLocalStorage();
                      this.callReadApi(() => {
                        this.setState({
                          totalRows: 0,
                          rows: [],
                          isLoading: false
                        });
                      });
                    });
                  }}
                  onClear={() => {
                    this.setState({
                      search: '',
                      jobStatusFilter: '',
                      jobTemplateFilter: null,
                      currentPage: 1,
                    }, () => {
                      if(this.props.onTypesReset){
                        this.props.onTypesReset();
                      }

                      this.setFilterInLocalStorage();
                      this.callReadApi(() => {
                        this.setState({
                          totalRows: 0,
                          rows: [],
                          isLoading: false
                        });
                      });
                    });
                  }}
                />
              </div>
                {Utils.isMobileTablet() && <div className='col-12 d-block d-md-none'></div>}
                
            <div className='d-inline-flex d-md-none align-items-center text-start'>
              <DropdownIconButtonMenu 
                ref={this.refDropdownAdvacedSearch}
                className={'job-list-icon-button-dropdown'}
                overflowVisible={true}
                selected={null}
                icon={<FilterIcon className={((Utils.filterParams(this.state.filterParams).length > 0) ? 'svg-fill-active' : '')}/>}
              >
                <div>
                  <AdvancedFilter
                    columns={this.state.columns}
                    columnVisibility={this.state.columnVisibility}

                    filterParams={this.state.filterParams}
                    searchOperator={this.state.searchOperator}

                    searchColumnKey={this.state.searchColumnKey}

                    onSearch={(filterParams, searchOperator) => {
                      if(this.refDropdownAdvacedSearch && this.refDropdownAdvacedSearch.current){
                        this.refDropdownAdvacedSearch.current.hide();
                      }

                      this.setState({
                        filterParams: filterParams,
                        searchOperator: searchOperator,
                        jobStatusFilter: '',
                        jobTemplateFilter: null,
                        currentPage: 1,
                      }, () => {
                        if(this.props.onTypesReset){
                          this.props.onTypesReset();
                        }

                        this.setFilterInLocalStorage();
                        this.callReadApi(() => {
                          this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                          });
                        });
                      });
                    }}
                    onCancel={() => {
                      if(this.refDropdownAdvacedSearch && this.refDropdownAdvacedSearch.current){
                        this.refDropdownAdvacedSearch.current.hide();
                      }
                    }}
                  />
                </div>
                </DropdownIconButtonMenu>

                {(Utils.filterParams(this.state.filterParams).length > 0) && <a 
                  className={'link-danger'}
                  href={'/'}
                  onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      
                      this.setState({
                        filterParams: [],
                        searchOperator: [],
                        jobStatusFilter: '',
                        jobTemplateFilter: null,
                        currentPage: 1,
                      }, () => {
                        if(this.props.onTypesReset){
                          this.props.onTypesReset();
                        }

                        this.setFilterInLocalStorage();
                        this.callReadApi(() => {
                          this.setState({
                            totalRows: 0,
                            rows: [],
                            isLoading: false
                          });
                        });
                      });
                  }}
                >Clear Filters</a>}
            </div>
              <div className='d-inline-flex align-items-center text-start'>
                <DropdownIconButtonMenu 
                  className={'job-list-icon-button-dropdown'}
                  selected={null}
                  items={[
                    {
                      value: 1,
                      text: 'Print this page',
                    },
                    {
                      value: 2,
                      text: 'Print all pages',
                    }
                  ]} 
                  icon={<PrintIcon />} 
                  onClick={(item) => {
                    if(item.value === 1){
                      this.callReadByPageApi((data) => {
                        this.tableRef.current.print(data);

                        this.setState({
                          isLoadingBackdrop: false,
                        });
                      });
                    } else if(item.value === 2){
                      this.callReadAllApi((data) => {
                        this.tableRef.current.print(data);

                        this.setState({
                          isLoadingBackdrop: false,
                        });
                      });
                    }
                  }}
                />

                <DropdownIconButtonMenu 
                  className={'job-list-icon-button-dropdown'}
                  selected={null}
                  items={[
                    {
                      value: 1,
                      text: 'Download all in PDF format',
                    },
                    {
                      value: 2,
                      text: 'Download all in Excel format',
                    }
                  ]} 
                  icon={<DownloadIcon />} 
                  onClick={(item) => {
                      if(item.value === 1){
                        this.callDownloadPDFAllApi((data, isError, err) => {
                          if(!isError){
                            this.tableRef.current.exportPDF(data);
                          }
                        });

                      } else if(item.value === 2){
                        this.callDownloadExcelAllApi((data, isError, err) => {
                          if(!isError){
                            let type = 'application/vnd.ms-excel';
                            Utils.saveByteArray('Jobs', data, type);
                          }
                        });
                      }
                  }}
                />

                <DropdownIconButtonMenu 
                  ref={this.refDropdownActionMenu}
                  className={'job-list-icon-button-dropdown'}
                  selected={null}
                  icon={<SettingsIcon />}
                >
                  <DataTableActionMenu 
                    pageName={this.pageName}
                    columns={this.state.columns}
                    selected={this.state.rowsPerPageSelected}
                    selectItems={this.state.rowsPerPageOptions}
                    columnVisibility={this.state.columnVisibility}
                    onChangeColumnVisibility={(index, value) => {
                      let colVis = this.state.columnVisibility;
                      colVis[index] = value;

                      this.setState({
                        columnVisibility: colVis
                      });

                      this.setFilterInLocalStorage();
                    }}
                    includeInactiveText={'Include Deleted'}
                    canInactive={true}
                    isCheckedIncludeInactive={this.state.isIncludeInactive === true || this.state.isIncludeInactive === false ? this.state.isIncludeInactive : false}
                    onSelectChangeAjax={(val) => {
                      this.setState({
                        jobStatusFilter: '',
                        jobTemplateFilter: null,
                        currentPage: 1,
                        rowsPerPageSelected: val
                      }, () => {
                        if(this.props.onTypesReset){
                          this.props.onTypesReset();
                        }

                        this.setFilterInLocalStorage();
                        this.callReadApi();
                      });
                    }}
                    onChangeIncludeInactive={(val) => {
                      this.setState({
                        isIncludeInactive: val,
                        jobStatusFilter: '',
                        jobTemplateFilter: null,
                        currentPage: 1,
                      }, () => {
                        if(this.props.onTypesReset){
                          this.props.onTypesReset();
                        }
                        
                        this.setFilterInLocalStorage();
                        this.callReadApi();
                      });
                    }}
                    isCookies={this.state.isCookies}
                    onResetColumns={() => {
                      this.setState({
                        columns: Array.from(this.state.initColumns),
                        columnVisibility: Array.from(this.state.initColumnVisibility),
                        sortColumn: this.state.sortColumnInit,
                        sortDir: this.state.sortDirInit,
                      }, () => {
                        this.setFilterInLocalStorage(false);
                        this.callReadApi();
                        if(this.refDropdownActionMenu && this.refDropdownActionMenu.current){
                          this.refDropdownActionMenu.current.hide();
                        }
                      });
                    }}
                    onShowMore={(isShowedMore) => {
                      this.setState({
                        pageName: this.pageName,
                      });
                    }}
                  />
                </DropdownIconButtonMenu>
              </div>
            </div>
        </div>
      </div>
    }
    setCustomPagination = () => {
      return <DataTableAjaxPagination
        page={this.state.currentPage}
        selected={this.state.rowsPerPageSelected}
        count={this.state.totalRows}
        fixed={true}
        onPageChange={(page) => {
          this.setState({
            currentPage: page,
          }, () => {
            this.setFilterInLocalStorage();
            this.callReadWithoutStatusApi();
          });
        }}
        onOptionsChange={(option) => {
          this.setState({
            currentPage: 1,
            rowsPerPageSelected: option,
          }, () => {
            this.setFilterInLocalStorage();
            this.callReadWithoutStatusApi();
          });
        }}
      />
    }
	
    /* SIGNALR */
    setSignalR = () => {
      if(this.props.realTimeUpdate.value > 0){
        if(this.refSignalR && this.refSignalR.current){
          this.refSignalR.current.disconnect()
        }
      } else {
        return <SignalR 
          ref={this.refSignalR}
          url={REACT_APP_REAL_TIME_URL}
          group={this.state.groupAccountEmail}
          onConnect={() => {
              
          }}
          onFailed={(err) => {
              
          }}
          onReceiveMessage={(type, msg) => {
            if(type === ReceiveMessage.CreateJob){
              if(this.state.userEmail !== msg.updatedBy){
                  this.realTimeCreate(msg);
              }
            } else if(type === ReceiveMessage.UpdateJob){
              if(this.state.userEmail !== msg.updatedBy){
                  this.realTimeUpdate(msg);
              }
            } else if(type === ReceiveMessage.UpdateBatchJob){
              if(this.state.userEmail !== msg.updatedBy){
                if(msg && msg.successJobs && msg.successJobs.length > 0){
                    for(let i = 0; i < msg.successJobs.length; i++){
                      let jobInfo = msg.successJobs[i];
                      this.realTimeUpdate(jobInfo.job, (i == ( msg.successJobs.length-1)));
                    }
                }
              }
            }
          }}
        />
    }
  }
  setRefreshPage = (nextProps) => {
    if(nextProps.realTimeUpdate.value > 0){
      if(nextProps.realTimeUpdate.timeLeft == 0){
        nextProps.action.realTimeUpdate.resetTimeLeft();
        // window.location.reload();
          this.callReadApi();
      } else {
        if (this.props.realTimeUpdate.timeLeft == 15){
          Utils.toast('The page will be refreshed in 15 seconds', 'info', null, 2000);
        }
      }
    }
  }
  realTimeCreate = (jobInfo) => {
    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

      const start = moment(fromDate).startOf('day');
      const end = moment(toDate).startOf('day');
      const actual = moment(jobInfo.jobDate).startOf('day');
      const isBetween = moment(actual).isBetween(start, end);
      const isSame = start.isSame(actual);

      if(isSame || isBetween){
        if(jobInfo && jobInfo.jobId && (jobInfo.jobId > 0)){
            this.tableRef.current.create(jobInfo, (updatedRow, newArray) => {
              this.setState({
                rows: newArray
              }, () => {
                this.callStatApi();
                this.callTypesApi();
              });
            });
        }
      }
  }
  realTimeUpdate = (jobInfo, shouldCallApi = false) => {
    let fromDate = moment(this.props.dateSection.fromDate).format(Utils.getAPIDateFormat());
    let toDate = moment(this.props.dateSection.toDate).format(Utils.getAPIDateFormat());

      const start = moment(fromDate).startOf('day');
      const end = moment(toDate).startOf('day');
      const actual = moment(jobInfo.jobDate).startOf('day');
      const isBetween = moment(actual).isBetween(start, end);
      const isSame = start.isSame(actual) || end.isSame(actual);

      if(isSame || isBetween){
        if(jobInfo && jobInfo.jobId && (jobInfo.jobId > 0)){
            this.tableRef.current.update(jobInfo, (updatedRow, newArray) => {
              this.setState({
                rows: newArray
              }, () => {
                if(shouldCallApi){
                  this.callStatApi();
                  this.callTypesApi();
                }
              });
            });
        }
      }
  }
  /* END SIGNALR */

  
  /* DIALOG */
  setCancelDialog = () => {
    return <Modal
        show={this.state.isCancelDialog}
        onHide={() => {
          this.setState({
            isCancelDialog: false,
            cancelRow: null,
            cancelMessage: '',
          });
        }}
        centered
        backdrop="static"
        keyboard={false}
    >
      <CancelDialog
          isLoading={this.state.cancelDialogItemLoading}
          onHide={() => {
            this.setState({
              isCancelDialog: false,
              cancelRow: null,
              cancelMessage: '',
            });
          }}
          onOk={(form) => {
            let row = this.state.cancelRow;

            this.callDispatchApi({
              jobId: row.jobId,
              jobStatusId: row.jobStatusId,
              cancellationRemarks: (form.driverNoteText && form.driverNoteText !== '') ? form.driverNoteText : '',
              jobAttemptCompletedDate: (row.jobAttemptCompletedDate) ? row.jobAttemptCompletedDate : null,
            }, row, () => {
              this.callStatApi(false);
            });

            this.setState({
              isCancelDialog: false,
              cancelRow: null,
              cancelMessage: '',
            });
          }}
      />
    </Modal>
  }

  setFailedDialog = () => {
    return <Modal
        show={this.state.isFailedDialog}
        onHide={() => {
          this.setState({
            isFailedDialog: false,
            failedRow: null,
            failedMessage: '',
          });
        }}
        centered
        backdrop="static"
        keyboard={false}
    >
      <FailedDialog
          isLoading={this.state.failedDialogItemLoading}
          onHide={() => {
            this.setState({
              isFailedDialog: false,
              failedRow: null,
              failedMessage: '',
            });
          }}
          onOk={(form) => {
            let row = this.state.failedRow;
            if(row){
              this.callDispatchApi({
                jobId: row.jobId,
                jobStatusId: row.jobStatusId,
                cancellationRemarks: (form.driverNoteText && form.driverNoteText !== '') ? form.driverNoteText : '',
                jobAttemptCompletedDate: (row.jobAttemptCompletedDate) ? row.jobAttemptCompletedDate : null,
              }, row, () => {
                this.callStatApi(false);
              });
            } else {
              this.callStatApi(false);
            }

            this.setState({
              isFailedDialog: false,
              failedRow: null,
              failedMessage: '',
            });
          }}
      />
      </Modal>
  }

  setPopupWeighChit = () => {
    return <Popup 
      className={'no-bg'}
      auth={this.props.auth}
      settingsType={this.props.settingsType}

      show={this.state.popupShow}
      isLoading={this.state.popupLoading}
      items={this.state.popupData}

      isEdited={this.state.popupIsEdited}
      onChanged={() => {
        this.setState({
          popupIsEdited: true,
        });
      }}

      isIncludeInactive={this.state.isIncludeInactive}
      hasChecked={this.state.popupHasChecked}
      onChecked={(state) => {
        this.setState({
          popupHasChecked: !state,
        });
      }}

      downloadExcel={() => {
        if(this.refPopupWeighChit && this.refPopupWeighChit.current){
          this.refPopupWeighChit.current.downloadExcel();
        }
      }}
      downloadPDF={() => {
        if(this.refPopupWeighChit && this.refPopupWeighChit.current){
          this.refPopupWeighChit.current.downloadPDF();
        }
      }}
      columnSelection={() => {
        if(this.refPopupWeighChit && this.refPopupWeighChit.current){
          return this.refPopupWeighChit.current.columnSelection();
        }
      }}

      showHeader={true}
      header={({ isLoading, items, isEdited, onChanged, onHide, onSave, downloadExcel, downloadPDF, columnSelection }) => {
        return <Row className={'g-6 w-100 align-items-center'}>
          <Col xs={12} sm={true}>
            Bulk Update
          </Col>
          <Col xs={12} sm={'auto'}>
            <Row>

              {!isEdited && <>
                <Col xs={'auto'}>
                  <IconButtonTooltip 
                    title={'Download Weigh Chit Excel'}
                    placement={'bottom'}
                    disabled={isLoading}
                    onClick={downloadExcel}
                  >
                    <i className={'mif-file-excel'}></i>
                  </IconButtonTooltip>
                </Col>
                <Col xs={'auto'}>
                  <IconButtonTooltip 
                    title={'Download Weight Chit PDF'}
                    placement={'bottom'}
                    disabled={isLoading}
                    onClick={downloadPDF}
                  >
                    <i className={'mif-file-pdf'}></i>
                  </IconButtonTooltip>
                </Col>
              </>}

              <Col xs={'auto'}>
                {columnSelection()}
              </Col>
            </Row>
          </Col>
        </Row>
      }}
      showFooter={true}
      centerFooter={'start'}
      footer={({ isLoading, hasChecked, isEdited, onChanged, onHide, onSave }) => {
        return <Row>
          <Col xs={'auto'}>
            <LoadingButton
              disabled={hasChecked}
              isLoading={isLoading}
              onClick={onSave}
            >Save</LoadingButton>
          </Col>
          <Col xs={'auto'}>
            <Button
              variant={'light'}
              disabled={isLoading}
              onClick={onHide}
            >Cancel</Button>
          </Col>
        </Row>
      }}
      scrollable={true}
      centered={true}
      closeButton={true}
      fullscreen={true}
      noBodyPadding={true}
      onHide={() => {
        this.setState({
          popupShow: false,
          popupLoading: false,
          popupData: null,
          popupHasChecked: true,
          popupIsEdited: false,
        });
      }}
      onSave={() => {
        this.setState({
          popupLoading: true,
        }, () => {
          let rows = [];
          if(this.refPopupWeighChit && this.refPopupWeighChit.current && this.refPopupWeighChit.current.tableRef && this.refPopupWeighChit.current.tableRef.current){
            rows = this.refPopupWeighChit.current.tableRef.current.getSelectedRows();
          }

          let wcData = rows.map(row => {
            let jobStepId = null;
            let steps = (row && row.steps && row.steps.length > 0) ? row.steps : [];
            for(let i = 0; i < steps.length; i++){
              let item = steps[i];
      
              
              if(row.jobTypeName == 'Out'){
                if(i === 0){
                  jobStepId = item.jobStepId;
                  break;
                }
              } else {
                if(item.customerSiteId){
                  jobStepId = item.jobStepId;
                  break;
                }
              }
            }

            let jobId = (row && row.jobId) ? row.jobId : null;
            let statusName = (row && row.statusName) ? row.statusName : '';
            let jobNumber = (row && row.jobNumber) ? row.jobNumber : '';
            
            let weighChitTicketNumber = (row && row.weightChitTicketNumber) ? row.weightChitTicketNumber : '';
            let weighChitGrossWeight = (row && row.binWeight) ? row.binWeight : '';
            let weighChitTareWeight = (row && row.tareBinWeight) ? row.tareBinWeight : '0';
            let weighChitNettWeight = (row && row.netBinWeight) ? row.netBinWeight : '0';
            let weighChitNonBillableBinWeight = (row && row.nonBillableBinWeight) ? row.nonBillableBinWeight : '0';
            let weighChitBillableWeight = (row && row.billableBinWeight) ? row.billableBinWeight : '0';
            let weighChitRemarks = (row && row.weightChitRemarks) ? row.weightChitRemarks : '';
            let weighChitWeighBy = (row && row.binWeighBy) ? row.binWeighBy : '';
            let weighChitDateTime = (row && row.weightChitDateTime) ? row.weightChitDateTime : null;
            let weightChitOutDateTime = (row && row.weightChitOutDateTime) ? row.weightChitOutDateTime : null;

            return {
              jobId: jobId,
              jobStepId: jobStepId,
              statusName: statusName,
              jobNumber: jobNumber,

              weightChitTicketNumber: weighChitTicketNumber,
              binWeight: weighChitGrossWeight,
              tareBinWeight: weighChitTareWeight,
              netBinWeight: weighChitNettWeight,
              nonBillableBinWeight: weighChitNonBillableBinWeight,
              billableBinWeight: weighChitBillableWeight,
              weightChitRemarks: weighChitRemarks,
              binWeighBy: weighChitWeighBy,
              weightChitDateTime: weighChitDateTime,
              weightChitOutDateTime: weightChitOutDateTime,
            };
          });

          this.callBatchStepBinWeightApi(wcData, (data, isError, err) => {
            let newRows = this.state.rows;

            try {
              let fields = [
                'weightChitTicketNumber',
                'binWeight',
                'tareBinWeight',
                'netBinWeight',
                'nonBillableBinWeight',
                'billableBinWeight',
                'weightChitRemarks',
                'binWeighBy',
                'weightChitDateTime',
                'weightChitOutDateTime',
              ];
              newRows = Utils.changeJobs('jobId', data, this.state.rows, fields);
            }catch(e){}

            // try {
            //   if (this.tableRef && this.tableRef.current) {
            //       this.tableRef.current.setSelected();
            //   }
            // }catch(e){}

            // try {
            //   let jobIds = rows.map(x => x.jobId);
            //   Utils.colorRowsWarning(jobIds);
            // }catch(e){}

            if(isError){
              this.setState({
                popupLoading: false,
              });
            } else {
              this.setState({
                rows: newRows,
  
                popupShow: false,
                popupLoading: false,
                popupData: null,
                popupHasChecked: true,
                popupIsEdited: false,
              }, () => {
                try {
                  if (this.tableRef && this.tableRef.current) {
                      this.tableRef.current.refrsh();
                  }
                }catch(e){}
              });
            }
          });
        });
      }}
    >
      {(props) => {
        return <PopupWeighChit
          ref={this.refPopupWeighChit}
          {...props}
        />
      }}
    </Popup>
  }
  /* END DIALOG */

    setBackDrop = () => {
      return <LoadingOverlay active={this.state.isLoadingBackdrop || this.state.isLoadingBatchAction}>Please Wait ...</LoadingOverlay>
    }


    initDrawers = () => {
      Utils.initDrawers(() => {
        this.formDrawerAssignTo = Utils.getInstanceDrawer('formAssignTo');
        Utils.onShowDrawer(this.formDrawerAssignTo, null, () => {
          this.setState({
            isVisibleFormDrawerAssignTo: true,
          });
        });
        Utils.onHideDrawer(this.formDrawerAssignTo, this.refFormDrawerAssignTo, () => {
          this.setState({
            isVisibleFormDrawerAssignTo: false,
          });
        });

        
        this.formDrawerWeighChit = Utils.getInstanceDrawer('formWeighChit');
        Utils.onShowDrawer(this.formDrawerWeighChit, null, () => {
          this.setState({
            isVisibleFormDrawerWeighChit: true,
          });
        });
        Utils.onHideDrawer(this.formDrawerWeighChit, this.refFormDrawerWeighChit, () => {
          this.setState({
            isVisibleFormDrawerWeighChit: false,
          });
        });
      });
    }
    prepareFormAssignTo = (dataParams, driversList) => {
      if(driversList){
        let rowValues = {
          jobIds: dataParams.jobIds,
          assignedStatusId: dataParams.assignedStatusId,
          dispatchedStatusId: dataParams.dispatchedStatusId,
          driverId: null,
          driversList: (driversList && driversList.length > 0) ? driversList : [],
        };
    
        return rowValues;
      } else {
        return this.state.rowinitialValues;
      }
    }
    prepareDataAssignTo = (form, dataParams) => {
      let data = {
        jobIds: dataParams.jobIds,
        jobStatusId: dataParams.assignedStatusId,
        driverId: form.driverId,
      };
  
      return data;
    }
    prepareDataDispatchTo = (form, dataParams) => {
      let data = {
        jobIds: dataParams.jobIds,
        jobStatusId: dataParams.dispatchedStatusId,
        driverId: form.driverId,
      };
  
      return data;
    }
    formDrawerAssignToSection = () => {
      return <DrawerLayout
        name={'formAssignTo'}
        ref={this.refFormDrawerAssignTo}
        drawer={this.formDrawerAssignTo}
        validationSchema={formSchemaAssignTo}
        initialValues={this.state.rowInitialValuesAssignTo}
        values={this.state.rowValuesAssignTo}
        isVisible={this.state.isVisibleFormDrawerAssignTo}
        isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawerAssignTo, this.state.isBlockingFormDrawerAssignTo)}
        renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawerAssignTo, this.state.isBlockingFormDrawerAssignTo)}
  
        title={(data) => {
          if(data){
            return <div className={'d-flex align-items-center'}>
              <div className={'d-block'}>
                <div className={'pe-2'}>
                  <span className={'pe-2'}>Assign to</span>
                </div>
              </div>
            </div>;
          }
        }}
        toolbar={null}
        
        onOpenClose={(isOpen, dataParams) => {
          if(isOpen){
            this.props.action.realTimeUpdate.pause();

            this.callReadDriversApi('', (driversList) => {
              let rowValuesAssignTo = this.prepareFormAssignTo(dataParams, driversList);
    
              this.setState({
                rowValuesAssignTo: rowValuesAssignTo,
              });
            });
          } else {
            this.props.action.realTimeUpdate.play();
          }
        }}
        onSubmit={(form, dataParams) => {
          
        }}
      >
        {(formOptions, dataParams) => {
          return <JobViewAssignToForm
            drawer={this.formDrawerAssignTo}
            fields={formFieldsAssignTo}
            initialValues={this.state.rowInitialValuesAssignTo}
            values={this.state.rowValuesAssignTo}
            formOptions={formOptions}
            dataParams={dataParams}
            onAssign={(values) => {
              let data = this.prepareDataAssignTo(values, dataParams);
              this.callDispatchAllApi(data, () => {
                if(this.tableRef && this.tableRef.current){
                  this.tableRef.current.setSelected();
                }
                Utils.showDrawer(this.formDrawerAssignTo, false);

                this.callReadApi();
              });
            }}
            onDispatch={(values) => {
              let data = this.prepareDataDispatchTo(values, dataParams);
              this.callDispatchAllApi(data, () => {
                if(this.tableRef && this.tableRef.current){
                  this.tableRef.current.setSelected();
                }
                Utils.showDrawer(this.formDrawerAssignTo, false);

                this.callReadApi();
              });
            }}
          />
        }}
      </DrawerLayout>
    }


    formDrawerSectionWeighChit = () => {
		  return <DrawerLayout
        name={'formWeighChit'}
        ref={this.refFormDrawerWeighChit}
        drawer={this.formDrawerWeighChit}
        validationSchema={formSchemaWeighChit}
        initialValues={this.state.rowInitialValuesWeighChit}
        values={this.state.rowValuesWeighChit}
        isVisible={this.state.isVisibleFormDrawerWeighChit}
        validateOnMount={false}
        isBlocking={Utils.isBlockingDrawer(this.state.isLoadingFormDrawerWeighChit, this.state.isBlockingFormDrawerWeighChit)}
        renderChildren={Utils.shouldDrawerRenderChildren(this.state.isLoadingFormDrawerWeighChit, this.state.isBlockingFormDrawerWeighChit)}
        size={'100%'}

        title={(data) => {
          let jobNumber = (data && data.row && data.row.jobNumber) ? data.row.jobNumber : '';
          if(data){
            return <Row className={'align-items-center'}>
              <Col xs={'auto'}>
                <div className={'d-flex align-items-center'}>
                  <span className={'pe-2'}>Weigh chit</span>
                </div>
                <div className={'d-flex align-items-center'}>
                  <small>
                    <a href={(data?.row?.version == 1) ? '/v5/job-form-beta?id=' + data.row.jobId : '/jobs-form?id=' + data.row.jobId}
                      target={'_blank'}
                      rel="noreferrer"
                      className={'d-flex align-items-center'}
                      onClick={(e) => {
                        // e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      {jobNumber}
                      <i className={'material-icons text-primary ps-1'} style={{ fontSize: '19px' }}>launch</i>
                    </a>
                  </small>
                </div>
              </Col>
            </Row>
          }
        }}
        toolbar={null}
        
        onOpenClose={async (isOpen, dataParams) => {
          if(isOpen){
            await this.props.action.refreshWeighChit.clear();
            await this.props.action.refreshWeighChit.callReadVehicleWeightApi(dataParams.vehicleId);
            await this.props.action.refreshWeighChit.callReadBinWeightApi(dataParams.binTypeId);
            await this.props.action.refreshWeighChit.callReadNonBillableWeightApi(dataParams.serviceItemId);

            let rowValuesWeighChit = this.prepareFormWeighChit(dataParams.row);
            this.setState({
              rowValuesWeighChit: rowValuesWeighChit ? rowValuesWeighChit : this.state.rowInitialValuesWeighChit,
            });
          } else {
            this.setState({
              rowValuesWeighChit: this.state.rowInitialValuesWeighChit,
            });
          }
        }}
        onSubmit={(form, dataParams) => {
          let data = this.prepareDataWeighChit(dataParams, form);
          
          this.callUpdateBinWeightApi(data, () => {
            let rows = this.state.rows;
            if(rows && rows.length > 0){
              let jobIndex = rows.findIndex(x => x.jobId == data.jobId);
              if(jobIndex > -1){
                rows[jobIndex]['weightChitTicketNumber'] = data.weightChitTicketNumber;
                rows[jobIndex]['binWeight'] = data.binWeight;
                rows[jobIndex]['tareBinWeight'] = data.tareBinWeight;
                rows[jobIndex]['netBinWeight'] = data.netBinWeight;
                rows[jobIndex]['nonBillableBinWeight'] = data.nonBillableBinWeight;
                rows[jobIndex]['billableBinWeight'] = data.billableBinWeight;
                rows[jobIndex]['weightChitRemarks'] = data.weightChitRemarks;
                rows[jobIndex]['binWeighBy'] = data.binWeighBy;
                rows[jobIndex]['weightChitDateTime'] = data.weightChitDateTime;
                rows[jobIndex]['weightChitOutDateTime'] = data.weightChitOutDateTime;
                rows[jobIndex]['jobStepWeighChitCustomFields'] = data.jobStepWeighChitCustomFields;
              }
            }

            this.setState({
              rows: rows,
              isLoadingBinWeight: false,
            }, () => {
              Utils.showDrawer(this.formDrawerWeighChit, false);
            });
          });
        }}
      >
        {(formOptions, dataParams, isBlocking) => {
          return <JobFormWeighChitDrawer
            ref={this.refFormDrawerWeighChitDrawer}
            drawer={this.formDrawerWeighChit}
            fields={formFieldsWeighChit}
            initialValues={this.state.rowInitialValuesWeighChit}
            values={this.state.rowValuesWeighChit}
            formOptions={formOptions}
            dataParams={dataParams}
            isSave={true}
            isDownload={true}
            // weighChitCustomFields={this.props.weighChitCustomFields}
          />
        }}
      </DrawerLayout>
	  }
    prepareFormWeighChit = (data) => {
      if(data){
        let ticketNumber = data.weighChitTicketNumber ? data.weighChitTicketNumber : '';
        let grossWeight = data.weighChitGrossWeight ? data.weighChitGrossWeight : '';
        let tareWeight = data.weighChitTareWeight ? data.weighChitTareWeight : '0';
        let nettWeight = data.weighChitNettWeight ? data.weighChitNettWeight : '0';
        let nonBillableBinWeight = data.weighChitNonBillableBinWeight ? data.weighChitNonBillableBinWeight : '0';
        let billableWeight = data.weighChitBillableWeight ? data.weighChitBillableWeight : '0';
        let remarks = data.weighChitRemarks ? data.weighChitRemarks : '';
        let weighBy = data.weighChitWeighBy ? data.weighChitWeighBy : '';
        let date = data.weightChitDateTime ? new Date(data.weightChitDateTime) : null;
        let weightChitOutDateTime = data.weightChitOutDateTime ? new Date(data.weightChitOutDateTime) : null;
        // let time = data.weighChitTime ? new Date(data.weighChitTime) : null;
        let jobStepWeighChitCustomFields = (data && data.jobStepWeighChitCustomFields && data.jobStepWeighChitCustomFields.length > 0) ? data.jobStepWeighChitCustomFields : [];


        // let customFormParams = this.state.rowInitialValues.customFormParams;
    
    
        let rowValues = {
          isEdit: true,
          ticketNumber: ticketNumber,
          grossWeight: grossWeight,
          tareWeight: tareWeight,
          nettWeight: nettWeight,
          nonBillableBinWeight: nonBillableBinWeight,
          billableWeight: billableWeight,
          remarks: remarks,
          weighBy: weighBy,
          date: date,
          weightChitOutDateTime: weightChitOutDateTime,
          // time: time,
          uom: this.dispatchUser.defaultUoM,
          jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
        };
  
        return rowValues;
      } else {
        return this.state.rowInitialValuesWeighChit;
      }
    }
    prepareDataWeighChit = (dataParams, form) => {
      let row = (dataParams && dataParams.row) ? dataParams.row : null;
      let jobId = (row && row.jobId) ? row.jobId : null;
      let jobStepId = (row && row.jobStepId) ? row.jobStepId : null;
      
      let tareWeight = form.tareWeight;
      try { tareWeight = parseFloat(form.tareWeight); }catch(e){}
      
      let nettWeight = form.nettWeight;
      try { nettWeight = parseFloat(form.nettWeight); }catch(e){}
      
      let nonBillableBinWeight = form.nonBillableBinWeight;
      try { nonBillableBinWeight = parseFloat(form.nonBillableBinWeight); }catch(e){}

      let billableWeight = form.billableWeight;
      try { billableWeight = parseFloat(form.billableWeight); }catch(e){}
      
      let jobStepWeighChitCustomFields = (form && form.jobStepWeighChitCustomFields && form.jobStepWeighChitCustomFields.length > 0) ? form.jobStepWeighChitCustomFields : [];

      let weightChitDateTime = (form && form.date) ? form.date : null;
      if(weightChitDateTime != null){
        let weightChitDate = moment(weightChitDateTime).format(Utils.getAPIDateFormat());
        let weightChitTime = moment(weightChitDateTime).format(Utils.getDefaultTimeFormat());
        weightChitDateTime = Utils.getLocalIsoDateTime(moment(weightChitDate + ' ' + weightChitTime, Utils.getAPIDateTimeFormat()))
      }
  
      let weightChitOutDateTime = (form && form.weightChitOutDateTime) ? form.weightChitOutDateTime : null;
      if(weightChitOutDateTime != null){
        let weightChitOutDate = moment(weightChitOutDateTime).format(Utils.getAPIDateFormat());
        let weightChitOutTime = moment(weightChitOutDateTime).format(Utils.getDefaultTimeFormat());
        weightChitOutDateTime = Utils.getLocalIsoDateTime(moment(weightChitOutDate + ' ' + weightChitOutTime, Utils.getAPIDateTimeFormat()))
      }
      
      return {
        jobId: jobId,
        jobStepId: jobStepId,
        binWeight: form.grossWeight,

        weightChitTicketNumber: form.ticketNumber,
        tareBinWeight: tareWeight,
        netBinWeight: nettWeight,
        nonBillableBinWeight: nonBillableBinWeight,
        billableBinWeight: billableWeight,
        weightChitRemarks: form.remarks,
        binWeighBy: form.weighBy,
        weightChitDateTime: weightChitDateTime,
        weightChitOutDateTime: weightChitOutDateTime,
        jobStepWeighChitCustomFields: jobStepWeighChitCustomFields,
      }
    }


    render() {
        return <div className={'jobs-page'}>
            <div>
              <div>
                <DataTableScrollSync 
                  ref={this.refDataTableScrollSync}
                  onLoad={(r) => { 
                    if(this.refDataTableScrollSync && this.refDataTableScrollSync.current){
                        this.refDataTableScrollSync.current.scrollLeft = this.menuLeftPosition;
                    }
                  }}
                  className={"table-sync-v4"}>{this.setDataTable()}</DataTableScrollSync>
              </div>
              {this.setCustomPagination()}
            </div>

            <ImportWeighChitPopup />
            
            <BulkUpdateJobForm
              onUpdate={(data) => {
                if(this.tableRef && this.tableRef.current){
                  this.tableRef.current.setSelected([], () => {
                    let checked = false;
                    this.setState({
                      isDisabledBatchAction: checked,
                    }, () => {
                      if(this.props.onChangeBatchAction){
                        this.props.onChangeBatchAction(checked);
                      }
                    });
                  });
                }
      
                this.callReadApi();
              }}
            />
            
            {this.setCancelDialog()}
            {this.setFailedDialog()}
            {this.setSignalR()}
            {this.setBackDrop()}
            {this.formDrawerAssignToSection()}
            {this.formDrawerSectionWeighChit()}
            {this.setPopupWeighChit()}
        </div>
    }
}


const mapStateToProps = state => {
  return {
    ...state,
    ...Utils.mapStateToProps(state),
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallPost: (p1, p2, p3, p4, p5, p6, p7) => dispatch(dispatchApiCallPost(p1, p2, p3, p4, p5, p6, p7)),
  dispatchApiCallPut: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallPut(p1, p2, p3, p4, p5, p6)),
  dispatchApiCallDelete: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallDelete(p1, p2, p3, p4, p5, p6)),
  saveUserCookieSettings: (payload) => dispatch(saveUserCookieSettings(payload)),
  clear: (payload) => dispatch(clear(payload)),
  action: {
    dateSection: {
      dateGetFilters: (payload) => dispatch(dateSection.dateGetFilters(payload)),
      dateSetFilters: (payload) => dispatch(dateSection.dateSetFilters(payload)),
      dateChange: (payload) => dispatch(dateSection.dateChange(payload)),
    },
    realTimeUpdate: {
      getFilters: (payload) => dispatch(realTimeUpdate.getFilters(payload)),
      updateTimeLeft: (payload) => dispatch(realTimeUpdate.updateTimeLeft(payload)),
      resetTimeLeft: (payload) => dispatch(realTimeUpdate.resetTimeLeft(payload)),
      up: (payload) => dispatch(realTimeUpdate.up(payload)),
      down: (payload) => dispatch(realTimeUpdate.down(payload)),
      pause: (payload) => dispatch(realTimeUpdate.pause(payload)),
      play: (payload) => dispatch(realTimeUpdate.play(payload)),
    },
    importWeighChit: {
      show: (payload) => dispatch(importWeighChit.show(payload)),
    },
    weighChitCustomFields: {
      callReadApi: (payload) => dispatch(weighChitCustomFields.callReadApi(payload)),
    },
    refreshWeighChit: {
      clear: (payload) => dispatch(refreshWeighChit.clear(payload)),
      callReadVehicleWeightApi: (payload) => dispatch(refreshWeighChit.callReadVehicleWeightApi(payload)),
      callReadBinWeightApi: (payload) => dispatch(refreshWeighChit.callReadBinWeightApi(payload)),
      callReadNonBillableWeightApi: (payload) => dispatch(refreshWeighChit.callReadNonBillableWeightApi(payload)),
    },
    signalRslice: {
      connect: (payload) => dispatch(signalRslice.connect(payload)),
      disconnect: (payload) => dispatch(signalRslice.disconnect(payload)),
      onPercentage: (payload) => dispatch(signalRslice.onPercentage(payload)),
    },
    bulkUpdateJobForm: {
      setShow: (payload) => dispatch(bulkUpdateJobForm.setShow(payload)),
      setHide: (payload) => dispatch(bulkUpdateJobForm.setHide(payload)),
      callBulkUpdateApi: (payload) => dispatch(bulkUpdateJobForm.callBulkUpdateApi(payload)),
    },
  }
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(JobViewTableSection);
