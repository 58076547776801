/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { LocalJobType } from '../../../manage/jobs/jobTypes/slice';

import { axios } from '../../../../../v5/utils/axios';
import { success, successAPI, errorAPI, getMaxPageSize, isNumeric, prepareDateTime, saveDateTime, kgToTones, tonsToKg, getDefaultUOMv4, getBillingUOMv4 } from '../../../../../v5/utils/utils';
import Yup from '../../../../../v5/utils/yup';
import { ReportUOM } from '../../../../../v5/utils/enums';


export const fields = {
  weightChitTicketNumber: {
    id: 'weightChitTicketNumber',
    label: 'Ticket number',
    placeholder: 'Ticket number',
  },
  binWeighBy: {
    id: 'binWeighBy',
    label: 'Weight by',
    placeholder: 'Weight by',
  },
  weightChitRemarks: {
    id: 'weightChitRemarks',
    label: 'Remarks',
    placeholder: 'Remarks',
  },
  weightChitDateTime: {
    id: 'weightChitDateTime',
    label: 'Weight bridge check in',
    placeholder: '',
  },
  weightChitOutDateTime: {
    id: 'weightChitOutDateTime',
    label: 'Weight bridge check out',
    placeholder: '',
  },

  binWeight: {
    id: 'binWeight',
    label: 'Gross Weight',
    placeholder: '',
    info: 'You can enter a maximum of 5 numbers with max 3 decimal places',
  },
  binOutWeight: {
    id: 'binOutWeight',
    label: 'Gross Weight',
    placeholder: '',
    info: 'You can enter a maximum of 5 numbers with max 3 decimal places',
  },
  tareBinWeight: {
    id: 'tareBinWeight',
    label: 'Tare Weight',
    placeholder: '',
  },
  netBinWeight: {
    id: 'netBinWeight',
    label: 'Nett Weight',
    placeholder: '',
  },
  nonBillableBinWeight: {
    id: 'nonBillableBinWeight',
    label: 'Non-billable Weight',
    placeholder: '',
  },
  billableBinWeight: {
    id: 'billableBinWeight',
    label: 'Billable Weight',
    placeholder: '',
  },

  vehicle: {
    id: 'vehicle',
    label: 'Vehicle',
    placeholder: '',
  },
  bin: {
    id: 'bin',
    label: 'Bin',
    placeholder: '',
  },
  nonBillable: {
    id: 'nonBillable',
    label: 'Non-billable',
    placeholder: '',
  },
  
  customFields: {
    id: 'customFields',
    label: 'Custom fields',
    placeholder: '',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    uom: Yup.number().oneOf([ReportUOM.tons, ReportUOM.kg]),

    weightChitTicketNumber: Yup.string().nullable().label(fields.weightChitTicketNumber.label),
    binWeighBy: Yup.string().nullable().label(fields.binWeighBy.label),
    weightChitRemarks: Yup.string().nullable().label(fields.weightChitRemarks.label),
    weightChitDateTime: Yup.string().nullable().label(fields.weightChitDateTime.label),
    weightChitOutDateTime: Yup.string().nullable().label(fields.weightChitOutDateTime.label),

    stepGroups: Yup.array().of(Yup.object().shape({
      blocks: Yup.array().of(Yup.object().shape({
        bins: Yup.array().nullable().of(Yup.object().shape({
          binWeight: Yup.number().when(['$uom'], ([uom]: any) => {
            return (uom === ReportUOM.kg
              ? getYupForKG(fields.binWeight.label)
              : getYupForTONS(fields.binWeight.label)
            ).max(getYupForMAX(uom, null));
          }),
        
          tareBinWeight: Yup.number().when(['$uom', '$binWeight'], ([uom, binWeight]) => {
            return (uom === ReportUOM.kg
              ? getYupForKG(fields.tareBinWeight.label)
              : getYupForTONS(fields.tareBinWeight.label)
            ).max(getYupForMAX(uom, binWeight));
          }),
        
          netBinWeight: Yup.number().nullable().min(0).label(fields.netBinWeight.label),
        
          nonBillableBinWeight: Yup.number().when(['$uom', '$binWeight'], ([uom, binWeight]) => {
            return (uom === ReportUOM.kg
              ? getYupForKG(fields.nonBillableBinWeight.label)
              : getYupForTONS(fields.nonBillableBinWeight.label)
            ).max(getYupForMAX(uom, null));
          }),
        
          billableBinWeight: Yup.number().when(['$uom', '$binWeight'], ([uom, binWeight]) => {
            return (uom === ReportUOM.kg
              ? getYupForKG(fields.billableBinWeight.label)
              : getYupForTONS(fields.billableBinWeight.label)
            ).max(getYupForMAX(uom, binWeight));
          }),
        }))

      }))
    })),
  });
}
export const getYupForKG = (label: string) => {
  return Yup.number()
    .integer()
    .test('noDecimal', 'Decimal values are not allowed', val => {
        if (val !== undefined && val !== null) {
            const strVal = val.toString();
            return strVal.indexOf('.') === -1;
        }
        return true;
    })
    .min(0)
    .nullable()
    .label(label);
}
export const getYupForTONS = (label: string) => {
  return Yup.number()
    .typeError('Invalid ' + label)
    .transform((value, originalValue) => {
      if (originalValue === undefined || originalValue === null || originalValue === '') {
        return undefined;
      }
      return parseFloat(originalValue);
    })
    .test('maxDecimal', 'You can enter a maximum of 5 numbers with max 3 decimal places', val => {
      if (val === null || val === undefined) return true;
        const strVal = val.toString();
        const parts = strVal.split('.');
      if (parts.length === 1 || (parts.length === 2 && parts[1].length <= 3)) {
          return true;
      }
      return false;
    })
    .min(0)
    .nullable()
    .label(label);
}
export const getYupForMAX = (uom: any, binWeight: any = null) => {
  return isNumeric(binWeight) ? binWeight : (uom === ReportUOM.kg ? 99999 : 99.999);
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, customFields: Array<any> = [], defValues: any = null, uom: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let weightChitTicketNumber: string = '';
    let binWeighBy: string = '';
    let weightChitRemarks: string = '';
    let weightChitDateTime: string = '';
    let weightChitOutDateTime: string = '';
    let weighChitCustomFields: Array<any> = [];

    let stepGroups: any = _.cloneDeep(form?.stepGroups);

    /* GROUP */
    if(stepGroups && stepGroups.length > 0){
      stepGroups.forEach((group: any, g: number) => {
        // let isExchange1 = (group.type === LocalJobType.EXCHANGE1) ? true : false;

        /* BLOCKS */
        if(group && group.blocks && group.blocks.length > 0){
          group.blocks.forEach((block: any, b: number) => {
            
            /* BINS */
            if(block && block.bins && block.bins.length > 0){
              block.bins.forEach((binItem: any, binIndex: number) => {
                if(binIndex === 0){
                  weightChitTicketNumber = binItem?.weightChitTicketNumber || '';
                  binWeighBy = binItem?.binWeighBy || '';
                  weightChitRemarks = binItem?.weightChitRemarks || '';
                  weightChitDateTime = binItem?.weightChitDateTime || '';
                  weightChitOutDateTime = binItem?.weightChitOutDateTime || '';
                  weighChitCustomFields = (binItem && binItem.weighChitCustomFields && binItem.weighChitCustomFields.length > 0) ? binItem?.weighChitCustomFields : [];
                }
              })
            }
            /* END BINS */
  
          })
        }
        /* END BLOCKS */
  
      })
    }
    /* END GROUP */


    let customFieldsArr: Array<any> = [];
    if(customFields && customFields.length > 0){
      customFieldsArr = customFields.map((item: any, i: number) => {
        let itm = weighChitCustomFields.find((x: any) => x.accountCustomFieldId === item?.accountCustomFieldId);
        
        return {
          ...item,
          order: (i+1),
          value: (itm) ? itm?.value : null,
        }
      })
    }


    data['uom'] = uom;

    data['weightChitTicketNumber'] = weightChitTicketNumber;
    data['binWeighBy'] = binWeighBy;
    data['weightChitRemarks'] = weightChitRemarks;
    
    let weightChitDateTimeVal = prepareDateTime(weightChitDateTime);
    data['weightChitDateTime'] = weightChitDateTimeVal;

    let weightChitOutDateTimeVal = prepareDateTime(weightChitOutDateTime);
    data['weightChitOutDateTime'] = weightChitOutDateTimeVal;

    data['customFields'] = customFieldsArr;
    data['stepGroups'] = stepGroups;
  }

  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};
  
  let form = _.cloneDeep(values);

  if(form){
    let weightChitDateTimeVal = saveDateTime(form.weightChitDateTime);
    let weightChitOutDateTimeVal = saveDateTime(form.weightChitOutDateTime);
    
    let weighChitCustomFields: Array<any> = [];
    let customFields = (form && form.customFields && form.customFields.length > 0) ? form?.customFields : [];
    if(customFields && customFields.length > 0){
      customFields.forEach((item: any, i: number) => {
        weighChitCustomFields.push({
          accountCustomFieldId: item?.accountCustomFieldId,
          order: (i+1),
          value: item?.value,
        });
      })
    }

    /* GROUP */
    let stepGroups: any = _.cloneDeep(form?.stepGroups);

    if(stepGroups && stepGroups.length > 0){
      stepGroups.forEach((group: any, g: number) => {
        let isExchange1 = (group.type === LocalJobType.EXCHANGE1) ? true : false;
        
        /* BLOCKS */
        if(group && group.blocks && group.blocks.length > 0){
          group.blocks.forEach((block: any, b: number) => {
            
            /* BINS */
            if(block && block.bins && block.bins.length > 0){
              block.bins.forEach((binItem: any, binIndex: number) => {
                let canWrite = (isExchange1) ? (b === 0) ? false : true : true;

                if(canWrite){
                  binItem.weightChitTicketNumber = form?.weightChitTicketNumber || '';
                  binItem.binWeighBy = form?.binWeighBy || '';
                  binItem.weightChitRemarks = form?.weightChitRemarks || '';
                  binItem.weightChitDateTime = weightChitDateTimeVal;
                  binItem.weightChitOutDateTime = weightChitOutDateTimeVal;
                  binItem.weighChitCustomFields = weighChitCustomFields;
                }
              })
            }
            /* END BINS */
  
          })
        }
        /* END BLOCKS */
  
      })
    }
    /* END GROUP */

    data['stepGroups'] = stepGroups;
  }

  return data;
};

export const getKgTons = (uom: any, prefix = ' (', sufix = ')') => {
  let str = (uom === ReportUOM.kg) ? 'kg' : 'tons';
  let value = prefix + str + sufix;
  return value;
}
export const toFixedUom = (val: any, uom: any) => {
  let numb = (isNumeric(val))? val : parseFloat(val);
  return (isNumeric(numb)) ? numb.toFixed((uom === ReportUOM.kg) ? 0 : 3) : 0;
}
export const toFixedDefaultUom = (val: any) => {
  let uomDefault = getDefaultUOMv4();

  let numb = (isNumeric(val))? val : parseFloat(val);
  return (isNumeric(numb)) ? numb.toFixed((uomDefault === ReportUOM.kg) ? 0 : 3) : 0;
}
export const convertUom = (billableWeight: any, from: any, to: any) => {
  try {
    if(billableWeight > 0){
      if(from == ReportUOM.kg && to == ReportUOM.tons){
        return kgToTones(billableWeight);
      } else if (from == ReportUOM.tons && to == ReportUOM.kg){
        return tonsToKg(billableWeight);
      } else if(from == ReportUOM.kg && to == ReportUOM.kg){
        return billableWeight.toFixed(0);
      } else if(from == ReportUOM.tons && to == ReportUOM.tons){
        return billableWeight.toFixed(3);
      } else {
        return billableWeight;
      }
    } else {
      return billableWeight;
    }
  } catch (e) {
    return billableWeight;
  }
}
export const smartConversionKgTons = (billableWeight: any) => {
  let uomDefault = getDefaultUOMv4();
  let uomBilling = getBillingUOMv4();

  try {
    if(uomBilling === ReportUOM.kg){
      if(uomDefault === ReportUOM.kg){
        return billableWeight.toFixed(0);
      } else {
        return tonsToKg(billableWeight);
      }
    } else {
      if(uomDefault === ReportUOM.kg){
        return kgToTones(billableWeight)
      } else {
        return billableWeight.toFixed(3);
      }
    }
  } catch (e) {
    return billableWeight;
  }
}

export interface initialValuesStruct {
  weightChitTicketNumber: string,
  binWeighBy: string,
  weightChitRemarks: string,
  weightChitDateTime: string,
  weightChitOutDateTime: string,
  customFields: Array<any>,
  stepGroups: any,
}
export const initialValues: initialValuesStruct = {
  weightChitTicketNumber: '',
  binWeighBy: '',
  weightChitRemarks: '',
  weightChitDateTime: '',
  weightChitOutDateTime: '',
  customFields: [],
  stepGroups: null,
}


interface InitState {
  show: boolean,
  details: any,
  
  isLoadingRefresh: boolean,

  isLoadingDownload: boolean,
  downloadData: any,

  isLoadingCustomFields: boolean,
}

function NewReducer() {
  const name = 'jobFormWeightChitSlice';


  const initialState: InitState = {
    show: false,
    details: initialValues,
    
    isLoadingRefresh: false,
    
    isLoadingDownload: false,
    downloadData: null,

    isLoadingCustomFields: false,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, details: any }>) => {
      state.show = action.payload.show;
      state.details = action.payload.details;
    },


    startRefreshWeight: (state: InitState) => {
      state.isLoadingRefresh = true;
    },
    finishRefreshWeight: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingRefresh = false;
    },
    
    startDownload: (state: InitState) => {
      state.isLoadingDownload = true;
      state.downloadData = null;
    },
    finishDownload: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDownload = false;
      state.downloadData = action.payload;
    },

    startReadCustomFields: (state: InitState) => {
      state.isLoadingCustomFields = true;
    },
    finishReadCustomFields: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCustomFields = false;
    },
  };


  const apis = {
    callRefreshWeightApi: ({ vehicleId = null, binTypeId = null, serviceItemId = null }: { vehicleId?: number|null, binTypeId?: number|null, serviceItemId?: number|null }, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRefreshWeight());
      
      let path = [];
      if(isNumeric(vehicleId)){
        path.push({
          type: 'vehicle',
          path: 'vehicle/' + vehicleId,
        });
      }
      if(isNumeric(binTypeId)){
        path.push({
          type: 'bintype',
          path: 'bintype/' + binTypeId,
        });
      }
      if(isNumeric(serviceItemId)){
        path.push({
          type: 'service-item',
          path: 'service-item/' + serviceItemId,
        });
      }

      try {
        const results = await Promise.all(path.map((itm: any) => 
          axios.get(itm.path).then((result: any) => ({ type: itm.type, data: result.data }))
        ));
    
        let obj = {
          vehicleUnladenWeight: null,
          binUnladenWeight: null,
          nonBillableWeight: null,
        };

        if(results && results.length > 0){
          results.forEach((itm: any) => {
            if(itm.data){
              if(itm.type === 'vehicle'){
                obj.vehicleUnladenWeight = itm.data.unladednBinWeight;

              } else if(itm.type === 'bintype'){
                obj.binUnladenWeight = itm.data.unladednBinWeight;

              } else if(itm.type === 'service-item'){
                let nonBillableWeight = null;
                let bundleServices = (itm.data.bundleServices) ? itm.data.bundleServices : [];
                if(itm.data.useBinWeightQuantity){
                  nonBillableWeight = itm.data.nonBillableWeight;
                } else {
                  if (bundleServices && bundleServices.length > 0) {
                    let useBinWeightQuantityIndex = bundleServices.findIndex((x: any) => x.useBinWeightQuantity);
                    if (useBinWeightQuantityIndex > -1) {
                      nonBillableWeight = bundleServices[useBinWeightQuantityIndex].nonBillableWeight;
                    }
                  }
                }

                obj.nonBillableWeight = nonBillableWeight;
              }
            }
          })
        }

        callback(true, obj);
        dispatch(actions.finishRefreshWeight(obj));
      } catch (error) {
        errorAPI(error);
    
        callback(false, []);
        dispatch(actions.finishRefreshWeight(null));
      }
    },

    callDownloadApi: (jobId: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDownload());

      let params = {
        jobId: jobId,
        includeAttachment: false,
        includePdfAttachmentDeliveryOrder: false,
        includePdfAttachmentPhoto: false,
        includePdfAttachmentWeightChit: true,
      };

      await axios.post('job/get-job-doc', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDownload(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDownload(null));
      });
    },

    callReadCustomFieldsCApi: (callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startReadCustomFields());

      await axios.get('account/custom-field', { params: { types: 0 } }).then((result: any) => {
        let data = (result && result.data && result.data.length > 0) ? result.data : [];

        successAPI(data);

        callback(true, data);
        dispatch(actions.finishReadCustomFields(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, []);
        dispatch(actions.finishReadCustomFields([]));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();