import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
  InputGroup,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
  Offcanvas,
} from 'react-bootstrap';
// import styled from 'styled-components';

import { Spin, Switch, Tooltip } from 'antd';

import InputMask from 'react-input-mask';
import DatePicker, { DateObject } from "react-multi-date-picker";
import CurrencyInput from 'react-currency-input-field';

import SmartSelect from '../../../../components/select/SmartSelect.tsx';

// Redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from '../../../../../../setup'
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData, initContactPerson } from './slice.tsx';
import customerSlice, { getXeroPaymentTermTypeName } from '../../../customers/customer/slice.tsx';

// enums
import { danger, warning, dateFormat, getMaxPageSize, limitRows, hasEmptyRows, isEmptyRow } from '../../../../../v5/utils/utils.tsx';
import { XeroPaymentTermTypeItems } from '../../../../../v5/utils/enums.tsx';

import { ReactComponent as InfoIcon } from "../../../../../v5/assets/icons/alert-info.svg";
import { ReactComponent as CalendarIcon } from "../../../../../v5/assets/icons/calendar.svg";
import { ReactComponent as XIcon } from "../../../../../v5/assets/icons/x.svg";

// ----------------------------------------------------------------------


const FormComponent = (props: any) => {
    const dispatch = useDispatch();
    const { isLoading, show, id, details, defaultBillingProfile, defaultTermItem } = useSelector((state: RootState) => state.jobFormCustomerMiniSliceSlice);
    const { isLoadingQuickbookCreditTerms } = useSelector((state: RootState) => state.customersSlice);
    
    const [more, setMore] = React.useState(false);

    React.useEffect(() => {
        if(show){
            setMore(false);

            if(!(id && id > 0)){
                let params = {
                    currentPage: 1,
                    pageSize: getMaxPageSize(),
                    searchQuery: '',
                    isActive: true,
                };
                dispatch(slice.callReadAccountBillingProfileApi(params, (state: boolean, data: any) => {}));
            }
        }
    }, [show]);

    React.useEffect(() => {
        if(id && id > 0){
            dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
        }
    }, [id]);
        
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        let form = prepareForm(details, initialValues);
        setValues(form);
        dispatch(slice.setLoading(false));
    }, [details]);
    
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        if(defaultBillingProfile){
            let quickBookApp = (defaultBillingProfile && defaultBillingProfile.quickBookApp) ? defaultBillingProfile.quickBookApp : null;
            let isConnectQuickbook = (defaultBillingProfile && defaultBillingProfile.isConnectQuickbook) ? defaultBillingProfile.isConnectQuickbook : false;
            let isConnectXero = (defaultBillingProfile && defaultBillingProfile.isConnectXero) ? defaultBillingProfile.isConnectXero : false;
            let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;
            let quickBookDefaultTermId = (quickBookApp && quickBookApp.quickBookDefaultTermId) ? quickBookApp.quickBookDefaultTermId.toString() : '';
            
            setFieldValue('accountBillingProfileId', defaultBillingProfile.accountBillingProfileId);
            setFieldValue('accountBillingProfileName', defaultBillingProfile.billingProfileName);
            setFieldValue('quickBookAppId', quickBookAppId);
            setFieldValue('isConnectQuickbook', isConnectQuickbook);
            setFieldValue('isConnectXero', isConnectXero);

            if(isConnectXero){
                let xeroApp = (defaultBillingProfile && defaultBillingProfile.xeroApp) ? defaultBillingProfile.xeroApp : null;
                let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';
        
                setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
        
            } else if(isConnectQuickbook){
                
                let params = {
                    quickBookAppId: quickBookAppId,
                };
                dispatch(slice.callReadQuickbookCreditTermsApi(params, quickBookDefaultTermId, (state: boolean, data: any) => {}));
            }
        }
        dispatch(slice.setLoading(false));
    }, [defaultBillingProfile]);
    
    React.useEffect(() => {
        dispatch(slice.setLoading(true));
        if(defaultTermItem){
            let paymentTermsId = (defaultTermItem && defaultTermItem.id) ? defaultTermItem.id : null;
            let paymentTermsName = (defaultTermItem && defaultTermItem.name && defaultTermItem.name != '') ? defaultTermItem.name : '';
            
            setFieldValue('paymentTermsId', paymentTermsId);
            setFieldValue('paymentTermsName', paymentTermsName);
        }
        dispatch(slice.setLoading(false));
    }, [defaultTermItem]);


    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: formSchema(null),
        onSubmit: values => {
            onSubmit(values, (data: any) => {
                if(props && props.onSave){
                    props.onSave(data);
                }
                
                onCancel();
            });
        },
    });
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


    const onSubmit = (values: any, callback: (data: any) => void) => {
        let data = prepareData(values);
        if(id > 0){
            dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        } else {
            dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
                if(state){
                    callback(data);
                }
            }));
        }
    }
    const onCancel = () => {
        let form = prepareForm(null, initialValues);
        setValues(form);
        dispatch(slice.setValues(form));
        dispatch(slice.setShow({ show: false, id: null }));
        dispatch(slice.resetSlice());
    }

    
    const setFooter = () => {
        return <Row className='w-100 gx-12'>
            <Col xs={true} className='text-end'>
                <Button
                    className='me-5'
                    variant={'custom-text'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={(e) => {
                        onCancel();
                    }}
                >Cancel</Button>
                <Button
                    variant={'custom-primary'}
                    size={'sm'}
                    disabled={isLoading}
                    onClick={() => {
                        handleSubmit()
                    }}
                >Save</Button>
            </Col>
        </Row>
    }
    const setForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.customerName.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        autoComplete='off'
                        tabIndex={1}
                        placeholder={fields.customerName.placeholder}
                        disabled={false}
                        value={values.customerName}
                        onChange={(e) => {
                            setFieldValue('customerName', e.target.value);
                            setFieldValue('billingName', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.customerName)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.customerName as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.billingName.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={fields.billingName.placeholder}
                        disabled={false}
                        value={values.billingName}
                        onChange={(e) => {
                            setFieldValue('billingName', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.billingName)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.billingName as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.accountBillingProfileId.label}</Form.Label>
                    <Form.Control
                        as={SmartSelect}
                        isInvalid={!!(errors && errors.accountBillingProfileId)}
                        value={values.accountBillingProfileId}
                        placeholder={fields.accountBillingProfileId.placeholder}
                        addText={'Add new'}
                        apiPath={'AccountBillingProfile'}
                        fieldNames={{ value: 'accountBillingProfileId', label: 'billingProfileName' }}
                        selected={{ accountBillingProfileId: values.accountBillingProfileId, billingProfileName: values.billingProfileName }}
                        hasOptionRender={false}
                        hasFooterRender={false}
                        searchable={true}
                        allowClear={true}
                        selectedIcon={false}
                        disabled={false}
                        virtual={true}
                        onChangeValue={async (value: any, option: any, ref: any) => {
                            if(option){
                                await setFieldValue('accountBillingProfileId', value);
                                await setFieldValue('billingProfileName', option.billingProfileName);
                                await setFieldValue('paymentTermsId', null);
                                await setFieldValue('paymentTermsName', '');
                                await setFieldValue('xeroPaymentTermType', '0');
                                await setFieldValue('xeroPaymentTerm', '');

                                let quickBookApp = (option && option.quickBookApp) ? option.quickBookApp : null;
                                let isConnectQuickbook = (option && option.isConnectQuickbook) ? option.isConnectQuickbook : false;
                                let isConnectXero = (option && option.isConnectXero) ? option.isConnectXero : false;
                                let quickBookAppId = (quickBookApp && quickBookApp.quickBookAppId) ? quickBookApp.quickBookAppId : 0;

                                await setFieldValue('accountBillingProfileId', value);
                                await setFieldValue('billingProfileName', option.billingProfileName);
                                await setFieldValue('quickBookAppId', quickBookAppId);
                                await setFieldValue('isConnectQuickbook', isConnectQuickbook);
                                await setFieldValue('isConnectXero', isConnectXero);

                                if(isConnectXero){
                                    let xeroApp = (option && option.xeroApp) ? option.xeroApp : null;
                                    let xeroPaymentTermType = (xeroApp && xeroApp.xeroPaymentTermType) ? xeroApp.xeroPaymentTermType.toString() : '0';
                                    let xeroPaymentTerm = (xeroApp && xeroApp.xeroPaymentTerm) ? xeroApp.xeroPaymentTerm.toString() : '0';

                                    await setFieldValue('xeroPaymentTermType', xeroPaymentTermType);
                                    await setFieldValue('xeroPaymentTerm', xeroPaymentTerm);
                                }

                                if(isConnectQuickbook){
                                dispatch(customerSlice.callReadQuickbookCreditTermsApi(quickBookAppId, async (state: boolean, data: any) => {
                                    if(state){
                                        let paymentTermsName = '';
                                        let quickBookDefaultTermId = (quickBookApp && quickBookApp.quickBookDefaultTermId) ? quickBookApp.quickBookDefaultTermId.toString() : null;
                                        
                                        if(data && data.length > 0){
                                            let qbDefTermItem = data.find((x: any) => x.id == quickBookDefaultTermId);
                                            if(qbDefTermItem){
                                                paymentTermsName = qbDefTermItem.name;
                                            
                                                await setFieldValue('paymentTermsId', quickBookDefaultTermId);
                                                await setFieldValue('paymentTermsName', paymentTermsName);
                                            }
                                        }
                                    }
                                }));
                                }
                            } else {
                                await setFieldValue('accountBillingProfileId', null);
                                await setFieldValue('billingProfileName', '');
                                
                                await setFieldValue('accountBillingProfileId', null);
                                await setFieldValue('billingProfileName', '');
                                await setFieldValue('paymentTermsId', null);
                                await setFieldValue('paymentTermsName', '');
                                await setFieldValue('xeroPaymentTermType', '0');
                                await setFieldValue('xeroPaymentTerm', '0');
                                await setFieldValue('quickBookAppId', 0);
                                await setFieldValue('isConnectQuickbook', false);
                                await setFieldValue('isConnectXero', false);
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.accountBillingProfileId as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            {values.isConnectQuickbook && <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.paymentTermsId.label}</Form.Label>
                    <Form.Control
                        as={SmartSelect}
                        isInvalid={!!(errors && errors.paymentTermsId)}
                        value={values.paymentTermsId}
                        placeholder={fields.paymentTermsId.placeholder}
                        addText={'Add new term'}
                        apiPath={'quickbookapp/term'}
                        customParams={{
                        quickBookAppId: values.quickBookAppId,
                        }}
                        readResponseFunc={(options: any) => {
                        return (options && options.length > 0) ? options : [];
                        }}
                        fieldNames={{ value: 'id', label: 'name' }}
                        selected={{ id: values.paymentTermsId, name: values.paymentTermsName }}
                        hasOptionRender={false}
                        hasFooterRender={false}
                        searchable={false}
                        allowClear={true}
                        selectedIcon={false}
                        disabled={isLoadingQuickbookCreditTerms}
                        loading={isLoadingQuickbookCreditTerms}
                        virtual={true}
                        onChangeValue={async (value: any, option: any, ref: any) => {
                        if(option){
                            await setFieldValue('paymentTermsId', value);
                            await setFieldValue('paymentTermsName', option.paymentTermsName);
                            await setFieldValue('xeroPaymentTerm', '0');
                        } else {
                            await setFieldValue('paymentTermsId', null);
                            await setFieldValue('paymentTermsName', '');
                            await setFieldValue('xeroPaymentTerm', '0');
                        }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.paymentTermsId as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>}

            {values.isConnectXero && <Col xs={12} style={{ zIndex: '7' }}>
                <Form.Group>
                    <Form.Label>{fields.xeroPaymentTerm.label}</Form.Label>
                    <InputGroup>
                        <Form.Control
                            as={CurrencyInput}
                            allowDecimals={false}
                            allowNegativeValue={false}
                            disableGroupSeparators={true}
                            // decimalsLimit={2}

                            autoComplete='off'
                            placeholder={fields.xeroPaymentTerm.placeholder}
                            value={values.xeroPaymentTerm}
                            onValueChange={(value: any) => {
                                setFieldValue('xeroPaymentTerm', value);
                                setFieldValue('paymentTermsId', null);
                                setFieldValue('paymentTermsName', '');
                            }}
                        isInvalid={!!(errors && errors.xeroPaymentTerm)}
                        />
                        <InputGroup.Text>
                            <DropdownButton
                                variant={'custom-none-primary'}
                                size={'sm'}
                                title={getXeroPaymentTermTypeName(values.xeroPaymentTermType)}
                            >
                                {XeroPaymentTermTypeItems.map((item: any, i: number) => {
                                return <Dropdown.Item
                                    key={item.value + '_' + item.title + '_' + i}
                                    active={(item.value == values.xeroPaymentTermType)}
                                    onClick={async () => {
                                    await setFieldValue('xeroPaymentTermType', item.value);
                                    }}
                                >{item.title}</Dropdown.Item>
                                })}
                            </DropdownButton>
                        </InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{errors && errors.xeroPaymentTerm as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>}

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>
                        <span className='me-2'>{fields.expiryDate.label}</span>
                        <Tooltip placement={'top'} title={fields.expiryDate.info}>
                            <InfoIcon />
                        </Tooltip>
                    </Form.Label>
                    <InputGroup>
                        <Form.Control
                            as = {InputMask}
                            mask={'99-99-9999'}
                            maskChar={null}
                            placeholder={dateFormat()}
                            value={values.expiryDate}
                            onChange={(e) => {
                                setFieldValue('expiryDate', e.target.value);
                            }}
                            isInvalid={!!(errors && errors.expiryDate)}
                        />
                        <InputGroup.Text>
                            <DatePicker
                                portal={true}
                                onOpenPickNewDate={false}
                                shadow={false}
                                disabled={false}
                                calendarPosition={'bottom-right'} 
                                format={dateFormat()}
                                value={values.expiryDate}
                                onChange={(value: DateObject) => {
                                    setFieldValue('expiryDate', value.format(dateFormat()));
                                }}
                                render={(value, openCalendar) => {
                                    return <Button variant={'custom-none-primary'} onClick={openCalendar}><CalendarIcon /></Button>
                                }}
                            />
                        </InputGroup.Text>
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">{errors && errors.expiryDate as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12} className='text-end mt-5'>
                <a href={'/'}
                    className='medium hover blue-500'
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        
                        setMore(!more);
                    }}
                >{more ? 'Show less' : 'Show more'}</a>
            </Col>
        </Row>
    }
    const setOtherForm = () => {
        return <Row className={'g-2'}>
            <Col xs={12}><hr className={'custom-manage-hr mt-0 mb-1'} /></Col>
            
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.billingAddress.label}</Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={4}
                        id={fields.billingAddress.id}
                        placeholder={fields.billingAddress.placeholder}
                        value={values.billingAddress}
                        onChange={async (e) => {
                            limitRows(e.target.value, 4, async (value: any) => {
                                await setFieldValue('billingAddress', value);
                            });
                        }}
                        isInvalid={!!errors.billingAddress}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.billingAddress as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.contactNumber.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={fields.contactNumber.placeholder}
                        disabled={false}
                        value={values.contactNumber}
                        onChange={(e) => {
                            setFieldValue('contactNumber', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.contactNumber)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.contactNumber as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>
            
            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.note.label} <small>{fields.note.labelDetails}</small></Form.Label>
                    <Form.Control
                        as="textarea"
                        rows={5}
                        id={fields.note.id}
                        placeholder={fields.note.placeholder}
                        value={values.note}
                        onChange={async (e) => {
                            limitRows(e.target.value, 5, async (value: any) => {
                                await setFieldValue('note', value);
                            });
                        }}
                        isInvalid={!!errors.note}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.note as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>
                        <span className='me-2'>{fields.emailAddresses.label}</span>
                        <Tooltip placement={'top'} title={fields.emailAddresses.info}>
                            <InfoIcon />
                        </Tooltip>
                    </Form.Label>
                    <Form.Control
                        type="text"
                        id={fields.emailAddresses.id}
                        placeholder={fields.emailAddresses.placeholder}
                        value={values.emailAddresses.join(',')}
                        onChange={async (e) => {
                            await setFieldValue('emailAddresses', [e.target.value]);
                        }}
                        isInvalid={!!errors.emailAddresses}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.emailAddresses as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.customerCategoryId.label}</Form.Label>
                    <Form.Control
                        as={SmartSelect}
                        isInvalid={!!(errors && errors.customerCategoryId)}
                        value={values.customerCategoryId}
                        placeholder={fields.customerCategoryId.placeholder}
                        addText={'Add new category'}
                        apiPath={'customerCategory'}
                        fieldNames={{ value: 'customerCategoryId', label: 'customerCategoryName' }}
                        createResponseFunc={(options: any) => {
                            return (options) ? options : null;
                        }}
                        onCreateFunc={(option: any) => {
                            if(option){
                                setFieldValue('customerCategoryId', option.customerCategoryId);
                                setFieldValue('customerCategoryName', option.customerCategoryName);
                            }
                        }}
                        updateResponseFunc={(options: any) => {
                            return (options) ? options : null;
                        }}
                        onUpdateFunc={(option: any) => {
                            if(option && values.customerCategoryId === option.customerCategoryId){
                                setFieldValue('customerCategoryId', option.customerCategoryId);
                                setFieldValue('customerCategoryName', option.customerCategoryName);
                            }
                        }}
                        selected={{ customerCategoryId: values.customerCategoryId, customerCategoryName: values.customerCategoryName }}
                        hasOptionRender={true}
                        hasFooterRender={true}
                        searchable={true}
                        allowClear={true}
                        selectedIcon={false}
                        disabled={false}
                        virtual={true}
                        onChangeValue={async (value: any, option: any, ref: any) => {
                            if(option){
                                await setFieldValue('customerCategoryId', value);
                                await setFieldValue('customerCategoryName', option.customerCategoryName);
                            } else {
                                await setFieldValue('customerCategoryId', null);
                                await setFieldValue('customerCategoryName', '');
                            }
                        }}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.customerCategoryId as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.businessRegNo.label}</Form.Label>
                    <Form.Control
                        type={'text'}
                        placeholder={fields.businessRegNo.placeholder}
                        disabled={false}
                        value={values.businessRegNo}
                        onChange={(e) => {
                            setFieldValue('businessRegNo', e.target.value);
                        }}
                        isInvalid={!!(errors && errors.businessRegNo)}
                    />
                    <Form.Control.Feedback type="invalid">{errors && errors.businessRegNo as ReactNode}</Form.Control.Feedback>
                </Form.Group>
            </Col>

            <Col xs={12}>
                <Form.Group>
                    <Form.Label>{fields.isRequirePaymentCollection.label}</Form.Label>
                    <Form.Group className='checkbox-field'>
                        <Switch
                            checked={values.isRequirePaymentCollection}
                            onChange={(checked: any) => {
                                setFieldValue('isRequirePaymentCollection', checked);
                            }}
                        />
                        <span className='ps-2'>{values.isRequirePaymentCollection ? 'On' : 'Off'}</span>
                    </Form.Group>
                </Form.Group>
            </Col>

            <Col xs={12}><hr className={'custom-manage-hr mt-4 mb-4'} /></Col>

            <Col xs={12}>
                {
                    (values.contactPersons && values.contactPersons.length > 0)
                    ?
                    values.contactPersons.map((item: any, i: number) => {
                        return <Row key={i} className={'g-2'}>
                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.firstName.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.firstName.placeholder}
                                        disabled={false}
                                        value={item.firstName}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.firstName', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].firstName)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].firstName as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.emailAddress.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.emailAddress.placeholder}
                                        disabled={false}
                                        value={item.emailAddress}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.emailAddress', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].emailAddress)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].emailAddress as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.workPhone.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.workPhone.placeholder}
                                        disabled={false}
                                        value={item.workPhone}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.workPhone', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].workPhone)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].workPhone as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.mobilePhone.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.mobilePhone.placeholder}
                                        disabled={false}
                                        value={item.mobilePhone}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.mobilePhone', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].mobilePhone)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].mobilePhone as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={12}>
                                <Form.Group>
                                    <Form.Label>{fields.department.label}</Form.Label>
                                    <Form.Control
                                        type={'text'}
                                        placeholder={fields.department.placeholder}
                                        disabled={false}
                                        value={item.department}
                                        onChange={(e) => {
                                            setFieldValue('contactPersons.' + i + '.department', e.target.value);
                                        }}
                                        isInvalid={!!(errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].department)}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors && errors.contactPersons && errors.contactPersons[i] && errors.contactPersons[i].department as ReactNode}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {(values.contactPersons.length > 1) && <Col xs={12} className='text-center'>
                                <a href={'/'} 
                                    className='medium hover blue-500'
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        
                                        let contactPersons = _.cloneDeep(values.contactPersons);
                                        contactPersons.splice(i, 1);
                                        setFieldValue('contactPersons', contactPersons);
                                    }}
                                ><XIcon /></a>
                            </Col>}
                            
                            {(i < (values.contactPersons.length-1)) && <Col xs={12}><hr className={'custom-manage-hr mt-0 mb-5'} /></Col>}
                        </Row>
                    })
                    :
                    <div>No Contacts!</div>
                }

                <div className={'mt-2'}>
                    <a href={'/'} 
                        className='medium hover blue-500'
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            
                            if(values.contactPersons.length < 5){
                                let hasEmpty = hasEmptyRows(values.contactPersons, (item: any, i: any) => {
                                    return isEmptyRow(item, [ 'firstName', 'emailAddress', 'workPhone', 'mobilePhone', 'department' ]);
                                });
                                
                                if(!hasEmpty){
                                    let contactPersons = _.cloneDeep(values.contactPersons);
                                    contactPersons.push(initContactPerson);
                                    setFieldValue('contactPersons', contactPersons);
                                } else {
                                    danger('Error', 'You must fill in the required fields');
                                }
                            } else {
                                warning('warning', 'You can add a maximum of 5 contacts');
                            }
                        }}
                    >+ Add Contact Person</a>
                </div>
            </Col>
        </Row>
    }


    return <Offcanvas
        backdrop={'static'}
        enforceFocus={false}
        scroll={false}
        placement={'end'}
        show={show}
        onHide={() => {
            onCancel();
        }}
        style={{ width: '600px' }}
    >
        <FormikContext.Provider value={formik}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>{(id && id > 0) ? 'Edit' : 'Create'} Customer</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Spin spinning={isLoading}>
                    <Row className={'g-2'}>
                        <Col xs={12}>{setForm()}</Col>
                        {(!more && values.customerName != '') && <Col xs={12}>
                            <Form.Control.Feedback type="invalid" className='d-block'>{errors && errors.billingName as ReactNode}</Form.Control.Feedback>
                        </Col>}
                        {more && <Col xs={12}>{setOtherForm()}</Col>}
                    </Row>
                </Spin>
            </Offcanvas.Body>
            <Modal.Footer className='border-top'>{setFooter()}</Modal.Footer>
        </FormikContext.Provider>
    </Offcanvas>
}

export default FormComponent;
