import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  Form,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Divider, Radio, Spin } from 'antd';
import InputMask from 'react-input-mask';
import DatePicker, { DateObject } from "react-multi-date-picker";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { fields, FormikContext, initialValues, formSchema, ServiceType, getBillingTypeName, initPricingsItem } from './slice.tsx';
import { updateBillingPricings, isMainPriceUseBinWeightQuantity } from '../../../jobs/slice.tsx';
import { getFirstCustomerSite } from '../../../manage/jobs/jobTypes/slice.tsx';
import taxRateSlice from "../../../../../v5/utils/slices/taxRate.tsx";

import SmartSelect from '../../../../../v5/components/select/SmartSelect.tsx';

import { warning, dateFormat, scrollInElement, getMaxPageSize, getCurrency, formatNumber, isNumeric } from '../../../../utils/utils.tsx';
import { XeroPaymentTermTypeItems } from '../../../../utils/enums.tsx';

import { ReactComponent as PlusIcon } from "../../../../../v5/assets/icons/plus.svg";
import { ReactComponent as RefreshIcon } from "../../../../../v5/assets/icons/refresh-new.svg";
import { ReactComponent as TrashIcon } from "../../../../../v5/assets/icons/trash.svg";
import { ReactComponent as CalendarIcon } from "../../../../../v5/assets/icons/calendar.svg";

// ----------------------------------------------------------------------

export const  StyledTable = styled.table`
  width: 100%;
  
  thead {
    tr {
      th {
        background-color: var(--bs-blue-focus1);
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: top;
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }
  }
`;


const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, form, isLoadingUpdateBilling } = useTypedSelector((state: RootState) => state.jobFormBillingJobSlice);
  const { isLoading, invoiceTaxRate, purchaseTaxRate, category } = useTypedSelector((state: RootState) => state.taxRateSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useEffect(() => {
    if(show){
      setValues(form);
      
      let customerId = (form && form?.customer) ? form?.customer?.customerId : null;
      dispatch(taxRateSlice.getCustomerProfileApi(customerId, (state: boolean, data: any) => {}));
    }
  }, [show, form])

  
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema(null),
    onSubmit: values => {
      if(onSave){
        onSave(values);
      }

      onCancel();
    },
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;


  const onSave = (values: any) => {
    if(props.onSave){
      props.onSave(values);
    }

    dispatch(slice.setShow({ show: false, form: null }));
  }
  const onCancel = () => {
    dispatch(slice.setShow({ show: false, form: null }));
  }
  

  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        <Button
          variant={'custom-text'}
          size={'sm'}
          className='me-12'
          onClick={(e) => {
            onCancel();
          }}
        >Cancel</Button>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          onClick={(e) => {
            handleSubmit();
          }}
        >Ok</Button>
      </Col>
    </Row>
  }

  const formElements = () => {
    return <Row className='g-32 align-items-center'>
      <Col xs={12}>
        <Row className='gx-40 gy-16 align-items-center'>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.jobNumber.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                size={'sm'}
                placeholder={fields.jobNumber.placeholder}
                disabled={true}
                value={values?.jobNumber}
                onChange={(e) => {
                  setFieldValue('jobNumber', e.target.value);
                }}
                isInvalid={!!(errors && errors.jobNumber)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.jobNumber as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={6}>
            <Form.Group>
              <Form.Label disabled={true}>{fields.customerName.label}</Form.Label>
              <Form.Control
                type={'text'}
                autoComplete='off'
                size={'sm'}
                placeholder={fields.customerName.placeholder}
                disabled={true}
                value={values?.customerName}
                onChange={(e) => {
                  setFieldValue('customerName', e.target.value);
                }}
                isInvalid={!!(errors && errors.customerName)}
              />
              <Form.Control.Feedback type="invalid">{errors && errors.customerName as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12}>
            <Form.Group>
              <Form.Label>{fields.isBillable.label}</Form.Label>
              <div>
                <Radio
                  name={'jobform_billable'}
                  checked={values.isBillable}
                  onChange={(e) => {
                    setFieldValue('isBillable', true);
                  }}
                >{fields.isBillable.optionBillable}</Radio>
                <Radio
                  name={'jobform_billable'}
                  checked={!values.isBillable}
                  onChange={(e) => {
                    setFieldValue('isBillable', false);
                  }}
                >{fields.isBillable.optionNonBillable}</Radio>
              </div>
              <Form.Control.Feedback type="invalid">{errors && errors.isBillable as ReactNode}</Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Col>

      {values.isBillable && <>
        <Col xs={12}><Divider className='m-0' /></Col>

        <Col xs={12}>
          <Row className='gx-40 gy-16 align-items-center'>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.accountBillingProfileId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  size={'sm'}
                  placeholder={fields.accountBillingProfileId.placeholder}
                  disabled={true}
                  value={values.customer?.billingProfileName || ''}
                  onChange={async (e) => {
                    
                  }}
                  isInvalid={false}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.billingAddress.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  size={'sm'}
                  placeholder={fields.billingAddress.placeholder}
                  disabled={true}
                  value={values.customer?.billingAddress || ''}
                  onChange={(e) => {
                  }}
                  isInvalid={false}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>{fields.invoiceDate.label}</Form.Label>
                <InputGroup>
                  <Form.Control
                    as = {InputMask}
                    mask={'99-99-9999'}
                    maskChar={null}
                    placeholder={dateFormat()}
                    value={values?.invoiceDate}
                    onChange={(e) => {
                      setFieldValue('invoiceDate', e.target.value);
                    }}
                    isInvalid={!!(errors && errors.invoiceDate)}
                  />
                  <InputGroup.Text>
                    <DatePicker
                      portal={true}
                      onOpenPickNewDate={false}
                      shadow={false}
                      disabled={false}
                      calendarPosition={'bottom-right'} 
                      format={dateFormat()}
                      value={values?.invoiceDate}
                      onChange={(value: DateObject) => {
                        setFieldValue('invoiceDate', value.format(dateFormat()));
                      }}
                      render={(value, openCalendar) => {
                        return <Button variant={'custom-none-primary'} onClick={openCalendar}><CalendarIcon /></Button>
                      }}
                    />
                  </InputGroup.Text>
                </InputGroup>
                <Form.Control.Feedback type="invalid">{errors && errors.invoiceDate as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>

            {values.customer?.billingProfile?.isConnectQuickbook && <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.paymentTermsId.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  placeholder={fields.paymentTermsId.placeholder}
                  disabled={true}
                  value={values.customer?.paymentTerms || ''}
                  onChange={async (e) => {
                    
                  }}
                  isInvalid={false}
                />
              </Form.Group>
            </Col>}

            {values.customer?.billingProfile?.isConnectXero && <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.xeroPaymentTerm.label}</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={'text'}
                    autoComplete='off'
                    placeholder={fields.xeroPaymentTerm.placeholder}
                    disabled={true}
                    value={values.customer?.xeroPaymentTerm || ''}
                    onChange={async (e) => {
                      
                    }}
                    isInvalid={false}
                  />
                  <InputGroup.Text>{XeroPaymentTermTypeItems.find((x: any) => x.value == (values.customer?.xeroPaymentTermType || ''))?.title || ''}</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Col>}
          
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label>{fields.billingNote.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  size={'sm'}
                  placeholder={fields.billingNote.placeholder}
                  disabled={false}
                  value={values?.billingNote || ''}
                  onChange={(e) => {
                    setFieldValue('billingNote', e.target.value);
                  }}
                  isInvalid={!!(errors && errors.billingNote)}
                />
                <Form.Control.Feedback type="invalid">{errors && errors.billingNote as ReactNode}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={4}>
              <Form.Group>
                <Form.Label disabled={true}>{fields.invoiceNumber.label}</Form.Label>
                <Form.Control
                  type={'text'}
                  autoComplete='off'
                  size={'sm'}
                  placeholder={fields.invoiceNumber.placeholder}
                  disabled={true}
                  value={''}
                  onChange={(e) => {
                    
                  }}
                  isInvalid={false}
                />
              </Form.Group>
            </Col>
          </Row>
        </Col>
        
        <Col xs={12}><Divider className='m-0' /></Col>

        <Col xs={12}>
          <Row className='gx-40 gy-16 align-items-center'>
            <Col xs={12}>
              <StyledTable className='shadow1'>
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>{fields.serviceTag.label}</th>
                    <th style={{ width: '20%' }}>{fields.billingType.label}</th>
                    <th style={{ width: '20%' }}>{fields.chargeCategoryId.label}</th>
                    <th style={{ width: '10%' }}>{fields.quantity.label}</th>
                    <th style={{ width: '10%' }}>{fields.price.label}</th>
                    <th style={{ width: '10%' }}>{fields.total.label}</th>
                    <th style={{ width: '5%' }}>{fields.delete.label}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    (values.pricings && values.pricings.length > 0)
                    ?
                    values.pricings.map((item: any, i: number) => {
                      return <tr key={'jobform_billing_item_' + i} className={'jobform-billing-item-' + i}>
                        <td>
                          {
                            (item.serviceType === ServiceType.AdditionalService)
                            ?
                              <Form.Group>
                                <Form.Control
                                  as={SmartSelect}
                                  isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag)}
                                  value={item.serviceItemId}
                                  placeholder={fields.serviceTag.placeholder}

                                  addText={'Add new service item'}
                                  apiPath={'service-item'}
                                  customParams={{
                                    type: ServiceType.AdditionalService,
                                    customerId: values?.customerId,
                                    customerSiteId: values?.customerSiteId,
                                    includeGlobalService: true,
                                    IncludeTaxRate: true,
                                    sortColumn: 'serviceTag',
                                    sortDir: 'desc'
                                  }}
                                  fieldNames={{ value: 'serviceItemId', label: 'serviceTag' }}
                                  selected={{ serviceItemId: item.serviceItemId, serviceTag: item.serviceTag }}

                                  hasOptionRender={false}
                                  hasFooterRender={false}
                                  searchable={true}
                                  allowClear={true}
                                  selectedIcon={false}
                                  disabled={false}
                                  virtual={true}

                                  onChangeValue={async (value: any, option: any, ref: any) => {
                                    if(option){
                                      await setFieldValue('pricings.' + i + '.serviceItemId', value);
                                      await setFieldValue('pricings.' + i + '.serviceTag', option.serviceTag);
                                      await setFieldValue('pricings.' + i + '.serviceItem', option);

                                      let chargeCategory = (option && option.chargeCategory) ? option.chargeCategory : null;
                                      let chargeCategoryId = (chargeCategory && chargeCategory.chargeCategoryId) ? chargeCategory.chargeCategoryId : null;
                                      let chargeCategoryName = (chargeCategory && chargeCategory.chargeCategoryName) ? chargeCategory.chargeCategoryName : '';

                                      if (chargeCategory) {
                                        await setFieldValue('pricings.' + i + '.chargeCategoryId', chargeCategoryId);
                                        await setFieldValue('pricings.' + i + '.chargeCategoryName', chargeCategoryName);
                                        await setFieldValue('pricings.' + i + '.chargeCategory', chargeCategory);
                                      }


                                      let billingType = option ? option.billingType : null;
                                      if(billingType){
                                        await setFieldValue('pricings.' + i + '.billingType', billingType);
                                      }
                                      
                                      let price = option ? option.price : '0';
                                      await setFieldValue('pricings.' + i + '.price', price);
                                      await setFieldValue('pricings.' + i + '.quantity', 1);
                                      await setFieldValue('pricings.' + i + '.bundleServiceTag', '');
                                    } else {
                                      await setFieldValue('pricings.' + i + '.serviceItemId', null);
                                      await setFieldValue('pricings.' + i + '.serviceTag', '');
                                      await setFieldValue('pricings.' + i + '.serviceItem', null);

                                      await setFieldValue('pricings.' + i + '.bundleServiceTag', '');
                                      await setFieldValue('pricings.' + i + '.billingType', null);

                                      await setFieldValue('pricings.' + i + '.price', '');
                                      await setFieldValue('pricings.' + i + '.quantity', '');

                                      await setFieldValue('pricings.' + i + '.chargeCategoryId', null);
                                      await setFieldValue('pricings.' + i + '.chargeCategoryName', '');
                                      await setFieldValue('pricings.' + i + '.chargeCategory', null);
                                    }
                                  }}
                                />
                                <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag) && errors.pricings[i].serviceTag}</Form.Control.Feedback>
                              </Form.Group>
                            :
                              <Form.Group>
                                <Form.Control
                                  type={'text'}
                                  autoComplete='off'
                                  size={'sm'}
                                  placeholder={fields.serviceTag.placeholder}
                                  disabled={false}
                                  value={item.serviceTag}
                                  onChange={async (e) => {
                                    await setFieldValue('pricings.' + i + '.serviceTag', e.target.value);
                                    await setFieldValue('pricings.' + i + '.bundleServiceTag', e.target.value);
                                  }}
                                  isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag)}
                                />
                                <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].serviceTag) && errors.pricings[i].serviceTag}</Form.Control.Feedback>
                              </Form.Group>
                          }
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              type={'text'}
                              autoComplete='off'
                              size={'sm'}
                              placeholder={fields.billingType.placeholder}
                              disabled={true}
                              value={getBillingTypeName(item.billingType)}
                              onChange={(e) => {
                                setFieldValue('pricings.' + i + '.billingType', e.target.value);
                              }}
                              isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billingType)}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].billingType) && errors.pricings[i].billingType}</Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              as={SmartSelect}
                              isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].chargeCategoryId)}
                              value={item.chargeCategoryId}
                              placeholder={fields.chargeCategoryId.placeholder}

                              addText={'Add new category'}
                              apiPath={'chargecategory'}
                              customParams={{
                                currentPage: 1,
                                pageSize: getMaxPageSize(),
                                searchQuery: '',
                                isActive: true,
                              }}
                              fieldNames={{ value: 'chargeCategoryId', label: 'chargeCategoryName' }}
                              selected={{ chargeCategoryId: item.chargeCategoryId, chargeCategoryName: item?.chargeCategory?.chargeCategoryName }}

                              hasOptionRender={false}
                              hasFooterRender={false}
                              searchable={true}
                              allowClear={true}
                              selectedIcon={false}
                              disabled={true}
                              virtual={true}

                              onChangeValue={async (value: any, option: any, ref: any) => {
                                if(option){
                                  await setFieldValue('pricings.' + i + '.chargeCategoryId', value);
                                  await setFieldValue('pricings.' + i + '.chargeCategoryName', option.chargeCategoryName);
                                  await setFieldValue('pricings.' + i + '.chargeCategory', option);
                                                                    
                                  let invoiceTaxRate = option?.invoiceTaxRate;
                                  let purchaseTaxRate = option?.purchaseTaxRate;
                                  dispatch(taxRateSlice.setTaxRate({ index: value, invoiceTaxRate: invoiceTaxRate, purchaseTaxRate: purchaseTaxRate }));
                                } else {
                                  await setFieldValue('pricings.' + i + '.chargeCategoryId', null);
                                  await setFieldValue('pricings.' + i + '.chargeCategoryName', '');
                                  await setFieldValue('pricings.' + i + '.chargeCategory', null);
                                  
                                  dispatch(taxRateSlice.setTaxRate({ index: null, invoiceTaxRate: null, purchaseTaxRate: null }));
                                }
                              }}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].chargeCategoryId) && errors.pricings[i].chargeCategoryId}</Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              as={CurrencyInput}
                              allowDecimals={true}
                              allowNegativeValue={false}
                              decimalsLimit={2}
            
                              autoComplete='off'
                              disabled={isMainPriceUseBinWeightQuantity(values?.pricings, item)}
                              placeholder={fields.quantity.placeholder}
                              value={item.quantity}
                              onValueChange={async (value: any) => {
                                await setFieldValue('pricings.' + i + '.quantity', value);
                              }}
                            
                              isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].quantity)}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].quantity) && errors.pricings[i].quantity}</Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <Form.Group>
                            <Form.Control
                              as = {CurrencyInput}
                              isInvalid={!!(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].price)}
                              
                              allowDecimals={true}
                              allowNegativeValue={false}
                              disableGroupSeparators={true}
                              prefix={getCurrency()}

                              placeholder={fields.price.placeholder}
                              value={item.price}
                              onValueChange={async (value: any) => {
                                await setFieldValue('pricings.' + i + '.price', value);
                              }}
                            />
                            <Form.Control.Feedback type="invalid">{(errors && errors.pricings && errors.pricings[i] && errors.pricings[i].price) && errors.pricings[i].price}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid" className={(item.isTaxInclusive && isNumeric(item.price)) ? 'd-block' : ''}>{taxRateSlice.getTaxRate(item.isTaxInclusive, item, { invoiceTaxRate: invoiceTaxRate, purchaseTaxRate: purchaseTaxRate, category: category })}</Form.Control.Feedback>
                          </Form.Group>
                        </td>
                        <td>
                          <div className='mt-8'>{getCurrency()}{formatNumber(item.quantity * item.price)}</div>
                        </td>
                        <td>
                          {(item.serviceType === ServiceType.AdditionalService) && <Button
                            className='p-1'
                            variant={'custom-secondary-danger'}
                            size={'sm'}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              e.preventDefault();

                              let items = _.cloneDeep(values.pricings);
                              items.splice(i, 1);
                              setFieldValue('pricings', items);
                            }}
                          >
                            <TrashIcon style={{ width: '18px', height: '18px' }} />
                          </Button>}
                        </td>
                      </tr>
                    })
                    :
                    <tr>
                      <td colSpan={7} className='text-center'>No pricings</td>
                    </tr>
                  }
                  
                </tbody>
              </StyledTable>
            </Col>
            <Col xs={12}>
              <Button 
                variant={'custom-secondary'}
                size={'sm'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();

                  validateForm().then((err: any) => {
                    if (err && Object.entries(err).length > 0) {
                      warning('Warning', 'You must fill in the required fields');
                    } else {
                      let items = (values.pricings && values.pricings.length > 0) ? _.cloneDeep(values.pricings) : [];
                      let newItem = _.cloneDeep(initPricingsItem);
                      items.push(newItem);
                      
                      setFieldValue('pricings', items);

                      setTimeout(() => {
                        scrollInElement('.scrollable-container', '.jobform-billing-item-' + (items.length-1));
                      }, 100);
                    }
                  })
                }}
              >
                <PlusIcon />
                <span className='ps-s4'>Add Service Items</span>
              </Button>
            </Col>
          </Row>
        </Col>
      </>}

    </Row>
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '90%' }}
  >
    <FormikContext.Provider value={formik}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className='w-100'>
          <Row className='w-100 gx-24'>
            <Col xs={true}>Billing</Col>
            {values?.isBillable && <Col xs={'auto'} className='text-end'>
              <Button
                variant={'custom-text'}
                size={'sm'}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  
                  let customerSites = getFirstCustomerSite(values);
                  dispatch(slice.callUpdateBillingApi({ customerId: values?.customerId, customerSiteId: customerSites?.customerSiteId }, async (state: boolean, data: any) => {
                    if(state){
                      let arr = (data && data.data && data.data.length > 0) ? data.data : [];
                      let pricingsArr: Array<any> = updateBillingPricings(values, arr);
                      await setFieldValue('pricings', pricingsArr);
                    }
                  }));
                }}
              >
                <RefreshIcon />
                <span className='ms-s4'>Update Billing</span>
              </Button>
            </Col>}
          </Row>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className='px-24 py-16 scrollable-container'>
        <Spin spinning={isLoadingUpdateBilling || isLoading} wrapperClassName={'no-height'}>
          {formElements()}
        </Spin>
      </Offcanvas.Body>
      <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
    </FormikContext.Provider>
  </Offcanvas>
}

export default PageComponent;
