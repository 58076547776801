import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Card,
  Button,
  Form,
  InputGroup,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider, Spin, Checkbox } from "antd";
import CurrencyInput from 'react-currency-input-field';

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, BinActivity, jobBinTypesInit, findStatus, checkStatus } from '../slice.tsx';
import servicesSlice from './services/slice.tsx';
import weightChitSlice, { getKgTons } from './weightChit/slice.tsx';

import { getCustomerSiteIdFromSelectedBin, isAllCustomerSiteSelected, LocalJobType, LocalBinActivity, ForWith, LocationEnum } from '../../manage/jobs/jobTypes/slice.tsx';

// enums
import { warning, isNumeric, getMaxPageSize, getDefaultUOMv4 } from '../../../../v5/utils/utils.tsx';
// import { ServiceType } from '../../../../v5/utils/enums.tsx';

// components
import SmartSelect from '../../../../v5/components/select/SmartSelect.tsx';
import SmartAutocomplete from '../../../../v5/components/autocomplete/SmartAutocomplete.tsx';

import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "../../../../v5/assets/icons/minus.svg";
import { ReactComponent as TrashIcon } from "../../../../v5/assets/icons/trash.svg";

// ----------------------------------------------------------------------

const StyledCard: React.FC<React.ComponentProps<typeof Card>> = styled(Card)`
  padding: 8px 16px 16px 16px;
`;


const BinDetails = (props: any) => {
  // const location = useLocation();
  // const router = useRouter();

  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const getRow = ({
    group, g,
    block, b,
    item, i, 
    isDisabledFields
  }: any) => {
    let status = findStatus(values?.jobTemplate?.templateStatuses, 'completed');
    let isCompleted = (status && values?.statusId === status?.jobStatusId) ? true : false;

    let nextBin: any = null;
    if((b === 0) && group && group.blocks && group.blocks.length > 1 && group.blocks[1]){
      if(group.blocks[1].bins && group.blocks[1].bins.length > 0 && group.blocks[1].bins[i]){
        nextBin = group.blocks[1].bins[i];
      }
    }

    return <>
      <Row className='g-12 align-items-center'>
        {(group?.type != LocalJobType.TIPPER) && <Col xs={12} md={3}>
          <Form.Group>
            <Form.Label>{fields.binTypeId.label}</Form.Label>
            <Form.Control
              type={'text'}
              autoComplete='off'
              placeholder={fields.binTypeId.placeholder}
              disabled={isDisabledFields}
              readOnly={true}
              value={item?.binType?.binTypeName || ''}
              onChange={async (e) => {
                
              }}
              onClick={() => {
                if(values.steps.some((x: any) => x.locationType === LocationEnum.CustomerSite)){
                  let customerSites = getCustomerSiteIdFromSelectedBin(group);
                  if(isNumeric(customerSites?.customerSiteId1)){
                    dispatch(servicesSlice.setShow({ show: true, values: values, customerSiteId: customerSites?.customerSiteId1, groupIndex: g, group: group, blockIndex: b, block: block, index: i, serviceItem: item?.serviceItem, wasteTypes: item?.wasteTypes }));
                  } else {
                    warning('Warning', 'You must select a customer site');
                  }
                } else {
                  dispatch(servicesSlice.setShow({ show: true, values: values, customerSiteId: null, groupIndex: g, group: group, blockIndex: b, block: block, index: i, serviceItem: item?.serviceItem, wasteTypes: item?.wasteTypes }));
                }
              }}
              isInvalid={!!(
                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].binTypeId
              )}
            />
            <Form.Control.Feedback type="invalid">{(
              errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
              errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
              errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
              errors.stepGroups[g].blocks[b].bins[i].binTypeId
            ) && errors.stepGroups[g].blocks[b].bins[i].binTypeId}</Form.Control.Feedback>
          </Form.Group>
        </Col>}
        {!((group?.type === LocalJobType.DISPOSAL) && (block?.type === LocalBinActivity.DROP_EMPTY)) && <Col xs={12} md={3}>
          <Form.Group>
            {
              (block?.isForWith === ForWith.FOR)
              ?
              <Form.Label>{fields.wasteTypeId.labelFor}</Form.Label>
              :
                (block?.isForWith === ForWith.WITH)
                ?
                <Form.Label>{fields.wasteTypeId.labelWith}</Form.Label>
                :
                <Form.Label>{fields.wasteTypeId.label}</Form.Label>
            }
            <Form.Control
              type={'text'}
              autoComplete='off'
              placeholder={fields.wasteTypeId.placeholder}
              disabled={isDisabledFields}
              readOnly={true}
              value={item?.wasteType?.wasteTypeName || ''}
              onChange={async (e) => {
                
              }}
              onClick={() => {
                if(values.steps.some((x: any) => x.locationType === LocationEnum.CustomerSite)){
                  let customerSites = getCustomerSiteIdFromSelectedBin(group);
                  if(isNumeric(customerSites?.customerSiteId1)){
                    dispatch(servicesSlice.setShow({ show: true, values: values, customerSiteId: customerSites?.customerSiteId1, groupIndex: g, group: group, blockIndex: b, block: block, index: i, serviceItem: item?.serviceItem, wasteTypes: item?.wasteTypes }));
                  } else {
                    warning('Warning', 'You must select a customer site');
                  }
                } else {
                  dispatch(servicesSlice.setShow({ show: true, values: values, customerSiteId: null, groupIndex: g, group: group, blockIndex: b, block: block, index: i, serviceItem: item?.serviceItem, wasteTypes: item?.wasteTypes }));
                }
              }}
              isInvalid={!!(
                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].wasteTypeId
              )}
            />
            <Form.Control.Feedback type="invalid">{(
              errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
              errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
              errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
              errors.stepGroups[g].blocks[b].bins[i].wasteTypeId
            ) && errors.stepGroups[g].blocks[b].bins[i].wasteTypeId}</Form.Control.Feedback>
          </Form.Group>
        </Col>}
        <Col xs={12} md={3}>
          <Form.Group>
            <Form.Label disabled={true}>{fields.serviceTag.label}</Form.Label>
            <Form.Control
              type={'text'}
              autoComplete='off'
              placeholder={fields.serviceTag.placeholder}
              disabled={true}
              value={item?.serviceItem?.serviceTag || ''}
              onChange={async (e) => {
                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.serviceTag', e.target.value);
              }}
              isInvalid={!!(
                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].serviceTag
              )}
            />
            <Form.Control.Feedback type="invalid">{(
              errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
              errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
              errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
              errors.stepGroups[g].blocks[b].bins[i].serviceTag
            ) && errors.stepGroups[g].blocks[b].bins[i].serviceTag}</Form.Control.Feedback>
          </Form.Group>
        </Col>
        
        {(group?.type != LocalJobType.TIPPER) && <>
          <Col xs={12} md={3}>
            <Row className='g-12 align-items-center'>
              {(group?.type != LocalJobType.TIPPER) && <>
                {
                  (item?.binType?.isNoninventory === undefined || item?.binType?.isNoninventory === null)
                  ?
                  <Col xs={12} md={true}>&nbsp;</Col>
                  :
                  (item?.binType?.isNoninventory === false)
                  ?
                  <Col xs={12} md={true}>
                    <Form.Group>
                      <Form.Label>{fields.binNumberId.label}</Form.Label>
                      <Form.Control
                        as={SmartAutocomplete}
                        value={item?.binNumber}
                        placeholder={fields.binNumberId.placeholder}
                        getPopupContainer={(trigger: any) => trigger.parentNode}

                        addText={'Add new bin number'}
                        apiPath={'binNumber'}
                        customParams={{
                          currentPage: 1,
                          pageSize: getMaxPageSize(),
                          customerId: values.customerId,
                          customerSiteId: getCustomerSiteIdFromSelectedBin(group)?.customerSiteId1,
                          binTypeId: item?.binType?.binTypeId,
                          showRecentJob: true,
                        }}
                        fieldNames={{ value: 'binNumberId', label: 'binNumberName' }}
                        selected={{ binNumberId: item?.binNumberId, binNumberName: item?.binNumber }}

                        hasOptionRender={false}
                        hasFooterRender={false}
                        searchable={true}
                        allowClear={true}
                        selectedIcon={false}
                        disabled={isDisabledFields || !(item?.binType?.binTypeId)}
                        virtual={true}

                        onChangeValue={async (value: any, option: any, ref: any) => {
                          if(option){
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binNumberId', value);
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binNumber', option.binNumberName);
                          } else {
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binNumberId', null);
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binNumber', value);
                          }
                        }}
                        
                        isInvalid={!!(
                          errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                          errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                          errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                          typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].binNumberId
                        )}
                      />
                      <Form.Control.Feedback type="invalid">{(
                        errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                        errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                        errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                        errors.stepGroups[g].blocks[b].bins[i].binNumberId
                      ) && errors.stepGroups[g].blocks[b].bins[i].binNumberId}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  :
                  <Col xs={12} md={true}>
                    <Form.Group className='plus-minus-button-start'>
                      <Form.Label>{fields.quantity.label}</Form.Label>
                      <InputGroup>
                        <Form.Control
                          className='custom'
                          as={CurrencyInput}
                          allowDecimals={false}
                          allowNegativeValue={false}
                          decimalsLimit={0}
                          maxLength={2}

                          disabled={isDisabledFields}
                          autoComplete='off'
                          placeholder={fields.quantity.placeholder}
                          value={item?.quantity}
                          onValueChange={async (value: any) => {
                            await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.quantity', value);
                          }}
                          onBlur={async () => {
                            let numb = isNumeric(item?.quantity) ? parseInt(item?.quantity) : 0;

                            if(numb < 1){
                              await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.quantity', 1);
                            } else if(numb > 99){
                              await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.quantity', 99);
                            }
                          }}
                        
                          isInvalid={!!(
                            errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                            errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                            errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                            typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].quantity
                          )}
                        />
                        <InputGroup.Text>
                          <span
                            onClick={async () => {
                              const minNumb = 1;
                              let numb = isNumeric(item?.quantity) ? parseInt(item?.quantity) : 1;

                              if(numb > minNumb){
                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.quantity', (numb-1).toString());
                              }
                            }}
                          >
                            <MinusIcon />
                          </span>
                          <span
                            onClick={async () => {
                              const maxNumb = 99;
                              let numb = isNumeric(item?.quantity) ? parseInt(item?.quantity) : 1;

                              if(numb < maxNumb){
                                await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.quantity', (numb+1).toString());
                              }
                            }}
                          >
                            <PlusIcon />
                          </span>
                        </InputGroup.Text>
                      </InputGroup>
                      <Form.Control.Feedback type="invalid">{(
                        errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                        errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                        errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                        errors.stepGroups[g].blocks[b].bins[i].quantity
                      ) && errors.stepGroups[g].blocks[b].bins[i].quantity}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                }
              </>}

              {((i > 0) && (isDisabledFields == false)) && <Col xs={12} md={'auto'} className='text-center text-md-start'>
                <Form.Group>
                  <Form.Label className='d-block'>&nbsp;</Form.Label>
                  <Button
                    className='p-6'
                    variant={'custom-secondary-danger'}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      e.preventDefault();

                      let groupsArr = _.cloneDeep(values.stepGroups);
                      let binsArr = groupsArr[g]?.blocks[b]?.bins || [];
                      binsArr.splice(i, 1);

                      setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins', binsArr);

                      // if(group?.type === LocalJobType.EXCHANGE1){
                      //   if((block?.isForWith === ForWith.FOR)){
                      //     let binsArrFor = groupsArr[g]?.blocks[(b+1)]?.bins || [];
                      //     binsArrFor.splice(i, 1);

                      //     setFieldValue('stepGroups.' + g + '.blocks.' + (b+1) + '.bins', binsArrFor);
                      //   } else {
                      //     let binsArrWith = groupsArr[g]?.blocks[(b-1)]?.bins || [];
                      //     binsArrWith.splice(i, 1);

                      //     setFieldValue('stepGroups.' + g + '.blocks.' + (b-1) + '.bins', binsArrWith);
                      //   }
                      // }

                      if(props.onRemoveBin){
                        props.onRemoveBin(g, b, i, item);
                      }
                    }}
                  >
                    <TrashIcon style={{ width: '18px', height: '18px' }} />
                  </Button>
                </Form.Group>
              </Col>}
            </Row>
          </Col>
        </>}
        
        {(block?.isForWith === ForWith.WITH) && <>
          {(group?.type === LocalJobType.DISPOSAL)
          ?
          <Col xs={12} md={3}>
            <Form.Group>
              <Form.Label>{fields.binOutWeight.label}</Form.Label>
              <Form.Control
                  as={CurrencyInput}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  decimalsLimit={0}
                  suffix={' ' + getKgTons(getDefaultUOMv4(), '', '')}

                  autoComplete='off'
                  placeholder={fields.binOutWeight.placeholder}
                  readOnly={true}
                  disabled={!(item?.binTypeId > 0)}
                  value={item?.binOutWeight}
                  onValueChange={async (value: any) => {
                    // await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binOutWeight', value);
                  }}
                  isInvalid={!!(
                    errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                    errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                    errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                    typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].binOutWeight
                  )}

                  onClick={() => {
                    if((item?.binTypeId > 0)){
                      dispatch(weightChitSlice.setShow({ show: true, details: values }));
                    }
                  }}
              />
              <Form.Control.Feedback type="invalid">{(
                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                errors.stepGroups[g].blocks[b].bins[i].binOutWeight
              ) && errors.stepGroups[g].blocks[b].bins[i].binOutWeight}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          :
          <Col xs={12} md={3}>
            <Form.Group>
              <Form.Label>{fields.binWeight.label}</Form.Label>
              <Form.Control
                  as={CurrencyInput}
                  allowDecimals={false}
                  allowNegativeValue={false}
                  decimalsLimit={0}
                  suffix={' ' + getKgTons(getDefaultUOMv4(), '', '')}

                  autoComplete='off'
                  placeholder={fields.binWeight.placeholder}
                  readOnly={true}
                  disabled={!(item?.binTypeId > 0)}
                  value={item?.binWeight}
                  onValueChange={async (value: any) => {
                    // await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.binWeight', value);
                  }}
                  isInvalid={!!(
                    errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                    errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                    errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                    typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].binWeight
                  )}

                  onClick={() => {
                    if((item?.binTypeId > 0)){
                      dispatch(weightChitSlice.setShow({ show: true, details: values }));
                    }
                  }}
              />
              <Form.Control.Feedback type="invalid">{(
                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                errors.stepGroups[g].blocks[b].bins[i].binWeight
              ) && errors.stepGroups[g].blocks[b].bins[i].binWeight}</Form.Control.Feedback>
            </Form.Group>
          </Col>
          }
          {(isCompleted && (group?.type != LocalJobType.DISPOSAL) && (group?.type != LocalJobType.TIPPER)) && <Col xs={12} md={true}>
            <Form.Group>
              <Form.Label>{fields.additionalWaste.label}</Form.Label>
              <Form.Control
                as={SmartSelect}
                value={item?.wasteTypes?.map((x: any) => x?.wasteTypeId)}
                placeholder={fields.additionalWaste.placeholder}

                addText={'Add new waste type'}
                apiPath={'service-item'}
                customParams={{
                  customerId: values?.customerId,
                  currentPage: 1,
                  pageSize: getMaxPageSize(),
                  binTypeId: item?.binType?.binTypeId,
                  includeGlobalService: true,
                  IncludeTaxRate: true,
                }}
                readResponseFunc={(options: any) => {
                  let data = (options && options.data && options.data.length > 0) ? options.data : [];
                  return data.filter((x: any) => x?.wasteTypeId !== item?.wasteTypeId)
                }}
                
                fieldNames={{ value: 'wasteTypeId', label: 'wasteTypeName' }}
                selected={{
                  wasteTypeId: item?.wasteTypes?.map((x: any) => x?.wasteTypeId), 
                  wasteTypeName: item?.wasteTypes?.map((x: any) => x?.wasteTypeName)
                }}
                getPopupContainer={(trigger: any) => trigger.parentNode}

                mode="multiple"
                maxTagCount={'responsive'}
                hasOptionRender={true}
                hasFooterRender={false}
                searchable={true}
                allowClear={true}
                selectedIcon={false}
                disabled={!(item?.binTypeId > 0)}
                virtual={true}

                optionRender={(option: any, ref: any) => {
                  return <Row className={'align-items-center gx-2'}>
                    <Col xs={'auto'}><b>{option?.data?.wasteTypeName}</b></Col>
                    <Col xs={'auto'}><small>({option?.data?.serviceTag})</small></Col>
                  </Row>
                }}
                onChangeValue={async (value: any, option: any, ref: any) => {
                  if(option){
                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.wasteTypes', option);
                  } else {
                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.wasteTypes', []);
                  }
                }}

                isInvalid={!!(
                  errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                  errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                  errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                  typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].wasteTypes
                )}
              />
              <Form.Control.Feedback type="invalid">{(
                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                errors.stepGroups[g].blocks[b].bins[i].wasteTypes
              ) && errors.stepGroups[g].blocks[b].bins[i].wasteTypes}</Form.Control.Feedback>
            </Form.Group>

            {/* <Form.Group>
              <Form.Label>{fields.additionalWaste.label}</Form.Label>
              <Form.Control
                as={SmartSelect}
                value={item?.wasteTypes?.map((x: any) => x.wasteTypeId)}
                placeholder={fields.additionalWaste.placeholder}

                addText={'Add new waste type'}
                apiPath={'wasteType'}
                customParams={{
                  recentJob: true,
                }}
                readResponseFunc={(data: any) => {
                  let dataArr = (data && data.data && data.data.length > 0) ? data.data : [];

                  return dataArr.map((x: any) => {
                    return {
                      ...x,
                      wasteType: x,
                    }
                  });
                }}
                fieldNames={{ value: 'wasteTypeId', label: 'wasteTypeName' }}
                selected={{
                  wasteTypeId: item?.wasteTypes?.map((x: any) => x.wasteTypeId), 
                  wasteTypeName: item?.wasteTypes?.map((x: any) => x?.wasteType?.wasteTypeName)
                }}
                getPopupContainer={(trigger: any) => trigger.parentNode}

                mode="multiple"
                maxTagCount={'responsive'}
                hasOptionRender={false}
                hasFooterRender={false}
                searchable={true}
                allowClear={true}
                selectedIcon={false}
                disabled={!(item?.binTypeId > 0)}
                virtual={true}

                onChangeValue={async (value: any, option: any, ref: any) => {
                  if(option){
                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.wasteTypes', option);
                  } else {
                    await setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins.' + i + '.wasteTypes', []);
                  }
                }}

                isInvalid={!!(
                  errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                  errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                  errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                  typeof errors.stepGroups[g].blocks[b].bins[i] === 'object' && errors.stepGroups[g].blocks[b].bins[i].wasteTypes
                )}
              />
              <Form.Control.Feedback type="invalid">{(
                errors && errors.stepGroups && errors.stepGroups.length > 0 && errors.stepGroups[g] && 
                errors.stepGroups[g].blocks && errors.stepGroups[g].blocks.length > 0 && errors.stepGroups[g].blocks[b] &&
                errors.stepGroups[g].blocks[b].bins && errors.stepGroups[g].blocks[b].bins.length > 0 && errors.stepGroups[g].blocks[b].bins[i] &&
                errors.stepGroups[g].blocks[b].bins[i].wasteTypes
              ) && errors.stepGroups[g].blocks[b].bins[i].wasteTypes}</Form.Control.Feedback>
            </Form.Group> */}
          </Col>}
        </>}

        {((group?.type === LocalJobType.EXCHANGE1) && (block?.isForWith === ForWith.FOR)) && <>
          <Col xs={12}>
            <Checkbox
              disabled={!(item?.binType?.binTypeId)}
              checked={(item?.binType?.binTypeId && item?.serviceItemId === nextBin?.serviceItemId) ? true : false}
              onChange={async (e: any) => {
                if(e.target.checked && isNumeric(item.serviceItemId)){
                  dispatch(servicesSlice.callDetailsServicesItemsApi([item.serviceItemId], (state: boolean, selectedServices: any) => {
                    if(state){
                      let arr = selectedServices.map((y: any) => {
                        return {
                          ...y,
                          binTypeName: y?.binType?.binTypeName,
                          wasteTypeName: y?.wasteType?.wasteTypeName,
                        }
                      });
            
                      let mainData = arr.find((x: any) => x.serviceItemId == item?.serviceItemId);
                      let anotherData = arr.filter((x: any) => x.serviceItemId != mainData.serviceItemId);
            
                      if(props.onCopyToBelowSection){
                        mainData['binNumberId'] = item?.binNumberId;
                        mainData['binNumber'] = item?.binNumber;
                        mainData['quantity'] = item?.quantity;
                        mainData['isNoninventory'] = item?.isNoninventory;

                        props.onCopyToBelowSection(g, (b+1), i, mainData, anotherData, item);
                      }
                    }
                  }));
                } else {
                  props.onCopyToBelowSection(g, (b+1), i, null);
                }
              }}
            >Copy to below section</Checkbox>
          </Col>
        </>}

      </Row>
    </>
  }

  const getBinElements = () => {
    if(values.stepGroups && values.stepGroups.length > 0){
      return <Row className='g-24 align-items-center'>

        {values.stepGroups.map((group: any, g: number) => {
          return <Col xs={12} key={'jobform_bindetails_group_' + g}>

            {
            (group && group.blocks && group.blocks.length > 0)
            ?
            group.blocks.map((block: any, b: number) => {
              let isDisabledFields = (block && block.bins) ? false : true;
              let prevBlock = (b > 0) ? group.blocks[(b-1)] : [];

              let prevBins = (prevBlock && prevBlock.bins && prevBlock.bins.length > 0) ? prevBlock.bins : [];
              let currentBins = (block && block.bins && block.bins.length > 0) ? block.bins : [];

              let binsArray = isDisabledFields ? prevBins : currentBins;

              if(block.type != null){
                return <StyledCard className={'no-bg ' + ((b > 0) ? 'mt-12' : '')} key={'jobform_bindetails_group_' + g + '_block_' + b}>
                  <Row className='g-12 align-items-center'>
                    <Col xs={12} className='semibold gray-900'>{block?.title}</Col>
      
                    {(binsArray && binsArray.length > 0) && binsArray.map((item: any, i: number) => {
                      return <Col xs={12} key={'jobform_bindetails_group_' + g + '_block_' + b + '_bin_' + i}>
                        <Row className='g-12 align-items-start'>
                          <Col xs={12} md={true}>
                            {getRow({
                              group: group, g: g,
                              block: block, b: b,
                              item: item, i: i, 
                              isDisabledFields: isDisabledFields
                            })}
                          </Col>

                          {(group?.type === LocalJobType.DROP || group?.type === LocalJobType.PICK || group?.type === LocalJobType.SHIFT) && <Divider className='mt-16 mb-s4' />}
                          {((i < (binsArray.length-1)) && (group?.type === LocalJobType.EXCHANGE1 || group?.type === LocalJobType.EXCHANGE2 || group?.type === LocalJobType.DISPOSAL || group?.type === LocalJobType.ON_THE_SPOT)) && <Divider className='mt-16 mb-s4' />}
                        </Row>
                      </Col>
                    })}
      
                    {(group?.type === LocalJobType.DROP || group?.type === LocalJobType.PICK || group?.type === LocalJobType.SHIFT) && <Col xs={12} className=''>{addButton(() => {
                      let groupsArr = _.cloneDeep(values.stepGroups);
                      let binsArr = groupsArr[g]?.blocks[b]?.bins || [];
                      let itm = _.cloneDeep(jobBinTypesInit);
                      binsArr.push(itm);
                      setFieldValue('stepGroups.' + g + '.blocks.' + b + '.bins', binsArr);
                    })}</Col>}
                  </Row>
                </StyledCard>
              } else {
                return <span className='gray-700'>No bin involved</span>
              }
            })
            :
            <span className='gray-700'>No bin involved</span>
            }

            {(group?.type === LocalJobType.EXCHANGE1 || group?.type === LocalJobType.EXCHANGE2 || group?.type === LocalJobType.DISPOSAL || group?.type === LocalJobType.ON_THE_SPOT) && <Col xs={12} className='mt-8'>{addButton(() => {
              let groupsArr = _.cloneDeep(values.stepGroups);
              let blocksArr = groupsArr[g]?.blocks || [];

              let itm = _.cloneDeep(jobBinTypesInit);
              blocksArr.forEach((blck: any, bInd: number) => {
                if(blck.bins){
                  blck.bins.push(itm);
                }
              })

              setFieldValue('stepGroups.' + g + '.blocks', blocksArr);
            })}</Col>}
          </Col>
        })}

      </Row>
    }
  }

  const addButton = (callback: () => void) => {
    return <Button 
      variant={'custom-secondary'}
      size={'sm'}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        
        callback();
      }}
    >
      <PlusIcon /><span className='ps-s4'>Add Bin</span>
    </Button>
  }


  return <Col xs={12} xxl={10}>
    <Spin indicator={<></>} spinning={(values.steps.some((x: any) => x.locationType === LocationEnum.CustomerSite)) ? !isAllCustomerSiteSelected(values.stepGroups) : false}>
      <Row className='g-16 align-items-center'>
        <Col xs={12}>
          <span className="large semibold gray-900">4. Bin Detail</span>
        </Col>

        <Col xs={12}>{getBinElements()}</Col>

        <Col xs={12}>
          <Divider />
        </Col>
      </Row>
    </Spin>
  </Col>
}

export default BinDetails;
