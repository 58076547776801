import React from 'react';

// import {
//   Row,
//   Col,
// } from 'react-bootstrap';
// import styled from 'styled-components';

// import { useAuthContext } from '../../../../auth/use-auth-context.js';

// Redux
// import { useDispatch } from 'react-redux';
// import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
// import slice from './slice.tsx';

import { dinamicZoom, convertCoordToLatLng, getDefLatLngZoom } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

import GoogleMap, { addMarker, clearAllMarkers, routeMarkerSVGIcon } from '../../../../components/googleMap/GoogleMap.jsx';

// ----------------------------------------------------------------------

const MapComponent = ({ coordinates, canMoveMarker, onDragEnd }: any) => {
  const defaultLatLngZoom: any = getDefLatLngZoom();

  // const {  } = useTypedSelector((state: RootState) => state.customersSiteChangeLocationSlice);
  // const dispatch = useDispatch<AppDispatch>();

  const [map, setMap] = React.useState<any>(null);

  const markersRef = React.useRef<Array<google.maps.Marker>>([]);


  React.useEffect(() => {
    
  }, []);

  React.useEffect(() => {
    clearAllMarkers(markersRef);
    
    if(coordinates != ''){
      let position = convertCoordToLatLng(coordinates);
  
      addMarker(markersRef, {
        map: map,
        draggable: canMoveMarker,
        position: position,
        title: 'Test',
        // label: 'Desc',
        icon: {
          url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon('#CB3A31', ''),
          scaledSize: new google.maps.Size(26, 32),
          labelOrigin: new google.maps.Point(13, 41),
        },
        
        // label: {
        //   text: 'Test',
        //   className: 'marker-label'
        // },
      });
      
      if(markersRef && markersRef.current && markersRef.current.length > 0){
        let marker = markersRef.current[0];
  
        marker.addListener('dragend', (event: any) => {
          let pos = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          if(onDragEnd){
            onDragEnd(pos);
          }
        });

        if (marker && typeof marker.getPosition === 'function') {
          const position = marker.getPosition();
          if (position) {
            dinamicZoom(map, position.lat(), position.lng(), 0.0010);
          }
        } 
      }
    }
  }, [map]);

  React.useEffect(() => {
    clearAllMarkers(markersRef);
    
    if(coordinates != ''){
      let position = convertCoordToLatLng(coordinates);
  
      addMarker(markersRef, {
        map: map,
        draggable: canMoveMarker,
        position: position,
        title: 'Test',
        // label: 'Desc',
        icon: {
          url: 'data:image/svg+xml;utf-8,' + routeMarkerSVGIcon('#CB3A31', ''),
          scaledSize: new google.maps.Size(26, 32),
          labelOrigin: new google.maps.Point(13, 41),
        },
        
        // label: {
        //   text: 'Test',
        //   className: 'marker-label'
        // },
      });
      
      if(markersRef && markersRef.current && markersRef.current.length > 0){
        let marker = markersRef.current[0];
  
        marker.addListener('dragend', (event: any) => {
          let pos = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
          };

          if(onDragEnd){
            onDragEnd(pos);
          }
        });

        if (marker && typeof marker.getPosition === 'function') {
          const position = marker.getPosition();
          if (position) {
            dinamicZoom(map, position.lat(), position.lng(), 0.0010);
          }
        } 
      }
    }
  }, [coordinates]);


  return <div style={{ height: '250px' }}>
    <GoogleMap
      canConextMenu={false}
      options={{
        center: defaultLatLngZoom.coord,
        zoom: defaultLatLngZoom.zoom,
        mapTypeControl: false,
        streetViewControl: false,
        gestureHandling: "cooperative",
        zoomControl: false,
        cameraControl: false,
      }}
      onLoad={(map: any, ref: any) => {
        setMap(map);
      }}
    />
  </div>
}

export default MapComponent;
