import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, weekDayNumb, isWeekend } from '../slice.tsx';

// enums
import { getDispatchUser } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';
import Dropzone from '../../../../v5/components/dropzone/Dropzone.tsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const { 
  REACT_APP_DOC_GEN_API_URL
} = process.env;


const Attachments = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  let dispatchUser: any = getDispatchUser();
  let dispatchAccountId = (dispatchUser && dispatchUser.accountId) ? dispatchUser.accountId : null;

  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isLoading } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const getElements = () => {
    return <Row className='g-32 align-items-center'>
      <Col xs={'auto'}>
        <Dropzone
          accept={'image/jpeg,image/jpg,image/png'}
          acceptText={'.jpeg, .jpg, .png'}
          method={'post'}
          name={'file'}
          action={REACT_APP_DOC_GEN_API_URL + 'doc-generation-job/upload-file'}
          headers={{
            'UploadPath': 'dispatch/' + dispatchAccountId + '/jobs/attachment/',
            'Authorization': 'mWU89QAnk0ik'
          }}
          maxCount={null}
          maxSize={null}
          multiple={true}
          showDownload={true}
          canCrop={false}
          files={values.photoAttachmentsUrl}
          onChange={({ fileList: newFileList }: any) => {
            setFieldValue('photoAttachmentsUrl', newFileList);
          }}
          onRemoveFile={({ fileList: newFileList }: any) => {
            setFieldValue('photoAttachmentsUrl', newFileList);
          }}
          previewFileList={[
            ...(Array.isArray(values.photoAttachmentsUrl) ? values.photoAttachmentsUrl : []),
          ]}
        />
      </Col>
    </Row>
  }

  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
    <Col xs={12}>
        <span className="large semibold gray-900">7. Attachments</span>
      </Col>

      <Col xs={12}>{getElements()}</Col>
      
      <Col xs={12}>
        <Divider />
      </Col>
    </Row>
  </Col>
}

export default Attachments;
