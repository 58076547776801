/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';
import h2p from 'html2plaintext';
import parse from "html-react-parser";
// import moment from 'moment';

import { axios } from '../../../../../v5/utils/axios';
import { success, successAPI, errorAPI, createEditorState } from '../../../../../v5/utils/utils';
import Yup from '../../../../../v5/utils/yup';
import { WeighChitLayout, AccountDocSettingType, AccountDocSettingVariables } from '../../../../../v5/utils/enums';

import { getId } from '../../slice.tsx';
import { getFirstCustomerSite } from '../../../manage/jobs/jobTypes/slice';


export const fields = {
  fromName: {
    id: 'fromName',
    label: 'Company name',
    placeholder: 'Enter company name',
  },
  emailAddresses: {
    id: 'emailAddresses',
    label: 'To',
    placeholder: 'Multiple emails with commas',
    email: 'Must be a valid email',
  },
  subject: {
    id: 'subject',
    label: 'Subject',
    placeholder: 'Enter subject',
  },
  message: {
    id: 'message',
    label: 'Body',
    placeholder: '',
  },
  includeAttachment: {
    id: 'includeAttachment',
    label: 'PDF attachment',
    placeholder: '',
  },
  include: {
    id: 'include',
    label: 'Include',
    placeholder: '',
  },
  includePdfAttachmentDeliveryOrder: {
    id: 'includePdfAttachmentDeliveryOrder',
    label: 'Delivery Order',
    placeholder: '',
  },
  includePdfAttachmentPhoto: {
    id: 'includePdfAttachmentPhoto',
    label: 'Include Photos',
    placeholder: '',
  },
  includePdfAttachmentWeightChit: {
    id: 'includePdfAttachmentWeightChit',
    label: 'Include Weight Chit',
    placeholder: '',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    fromName: Yup.string().nullable().label(fields.fromName.label),
    emailAddresses: Yup.array().of(Yup.string().email(fields.emailAddresses.email)).label(fields.emailAddresses.label),
    subject: Yup.string().nullable().label(fields.subject.label),
    messagePlainText: Yup.string().nullable().label(fields.message.label),
    includeAttachment: Yup.bool().oneOf([true, false]).label(fields.includeAttachment.label),
    includePdfAttachmentDeliveryOrder: Yup.bool().oneOf([true, false]).label(fields.includePdfAttachmentDeliveryOrder.label),
    includePdfAttachmentPhoto: Yup.bool().oneOf([true, false]).label(fields.includePdfAttachmentPhoto.label),
    includePdfAttachmentWeightChit: Yup.bool().oneOf([true, false]).label(fields.includePdfAttachmentWeightChit.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null, accountBillingProfile: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let contactEmails = [];
    let isAutoSendDoDoc = false;
    let customerSiteDetails = getFirstCustomerSite(values);
    if(customerSiteDetails && customerSiteDetails.site && customerSiteDetails.site.contactEmails && customerSiteDetails.site.contactEmails.length > 0){
      contactEmails = customerSiteDetails?.site?.contactEmails;
      isAutoSendDoDoc = customerSiteDetails?.site?.isAutoSendDoDoc;
    }


    let fromName = '';
    let emailAddresses = [];
    let subject = '';
    let messagePlainText = h2p('');
    let messageHTML = '';
    let message = createEditorState('');
    let includeAttachment = true;
    let includePdfAttachmentDeliveryOrder = false;
    let includePdfAttachmentPhoto = false;
    let includePdfAttachmentWeightChit = false;
    let layout = WeighChitLayout.Default;


    if(accountBillingProfile && accountBillingProfile.accountEmailTemplates && accountBillingProfile.accountEmailTemplates.length > 0){
      let accountEmailTemplate = accountBillingProfile.accountEmailTemplates[0];
      if(accountEmailTemplate){
        let toArr = [];
        if(isAutoSendDoDoc){
            if(contactEmails.length > 0){
              for(let i = 0; i < contactEmails.length; i++){
                if(contactEmails[i].customerContactPersonId){
                  if(contactEmails[i].customerContactPerson.emailAddress){
                    toArr.push(contactEmails[i].customerContactPerson.emailAddress);
                  }
                } else {
                  if(contactEmails[i].emailAddress){
                    toArr.push(contactEmails[i].emailAddress);
                  }
                }
              }
            } else {
              if(values && values.customerItem && values.customerItem.emailAddresses){
                let emailAddresses = values.customerItem.emailAddresses.split(',');
                if(emailAddresses && emailAddresses.length > 0){
                  for(let i = 0; i < emailAddresses.length; i++){
                    if(emailAddresses[i]){
                      toArr.push(emailAddresses[i]);
                    }
                  }
                }
              }
            }
        } else {
          if(values && values.customerItem && values.customerItem.emailAddresses){
            let emailAddresses = values.customerItem.emailAddresses.split(',');
            if(emailAddresses && emailAddresses.length > 0){
              for(let i = 0; i < emailAddresses.length; i++){
                if(emailAddresses[i]){
                  toArr.push(emailAddresses[i]);
                }
              }
            }
          }
        }

        messagePlainText = h2p(accountEmailTemplate.message);
        messageHTML = accountEmailTemplate.message;
        message = createEditorState(messageHTML);

        fromName = accountEmailTemplate.fromName;
        emailAddresses = toArr;
        subject = accountEmailTemplate.subject;
        includeAttachment = true;
        includePdfAttachmentDeliveryOrder = accountEmailTemplate.includePdfAttachmentDeliveryOrder;
        includePdfAttachmentPhoto = accountEmailTemplate.includePdfAttachmentPhoto;
        includePdfAttachmentWeightChit = accountEmailTemplate.includePdfAttachmentWeightChit;
      }
    }


    if(accountBillingProfile && accountBillingProfile.accountDocSettings && accountBillingProfile.accountDocSettings.length > 0){
      let accountDocSettingsItems = accountBillingProfile.accountDocSettings.filter((x: any) => x.type == AccountDocSettingType.WeightChit);
      if(accountDocSettingsItems && accountDocSettingsItems.length > 0){
        let accountDocSettings = accountDocSettingsItems[0];
        layout = accountDocSettings.layout;
      }
    }


    data['fromName'] = fromName;
    data['emailAddresses'] = emailAddresses;
    data['contactEmails'] = contactEmails;
    data['subject'] = subject;
    data['messagePlainText'] = messagePlainText;
    data['messageHTML'] = messageHTML;
    data['message'] = message;
    data['includeAttachment'] = includeAttachment;
    data['includePdfAttachmentDeliveryOrder'] = includePdfAttachmentDeliveryOrder;
    data['includePdfAttachmentPhoto'] = includePdfAttachmentPhoto;
    data['includePdfAttachmentWeightChit'] = includePdfAttachmentWeightChit;
    data['layout'] = layout;
  }
  
  return data;
};
export const prepareData = (values: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    let emailAddresses = form.emailAddresses && form.emailAddresses.length > 0 ? form.emailAddresses.join(',') : '';

    data['jobId'] = getId();

    data['fromName'] = form.fromName;
    data['emailAddresses'] = emailAddresses;
    data['subject'] = form.subject;
    data['message'] = form.messageHTML;
    
    let includeAttachment = (form.includeAttachment === true || form.includeAttachment == false) ? form.includeAttachment : false;
    data['includeAttachment'] = includeAttachment;

    let includePdfAttachmentDeliveryOrder = (form.includePdfAttachmentDeliveryOrder === true || form.includePdfAttachmentDeliveryOrder == false) ? form.includePdfAttachmentDeliveryOrder : false;
    data['includePdfAttachmentDeliveryOrder'] = includePdfAttachmentDeliveryOrder;
    
    let includePdfAttachmentPhoto = (form.includePdfAttachmentPhoto === true || form.includePdfAttachmentPhoto == false) ? form.includePdfAttachmentPhoto : false;
    data['includePdfAttachmentPhoto'] = includePdfAttachmentPhoto;
    
    let includePdfAttachmentWeightChit = (form.includePdfAttachmentWeightChit === true || form.includePdfAttachmentWeightChit == false) ? form.includePdfAttachmentWeightChit : false;
    data['includePdfAttachmentWeightChit'] = includePdfAttachmentWeightChit;
  }

  return data;
};


export const stringToHtmlTrim = (value: any) => {
  try {
    let result = value && value !== "" ? value.toString().replace(/[ \t]+/g, ' ').trim() : value;
    return parse(result)
  } catch(e){
    return value
  }
};
export const strToBool = (status: any = '') => {
  if (status == 'True') {
      return true;
  } else if (status == 'False') {
      return false;
  } else {
      return false;
  }
};
export const findSettings = (settings: any = [], text: any = '', param: any = 'variableName') => {
  let status = null;

  if (settings && settings.length > 0) {
    let statusItem = settings.filter((x: any) => x[param].toLowerCase().trim() === text.toLowerCase().trim());

    if (statusItem && statusItem.length > 0) {
      status = statusItem[0];
    }
  }

  return status;
};
export const findSettingsItem = (settings: any = null, type: any = AccountDocSettingVariables.String, param: any = 'variableValue') => {
  let status = (type === AccountDocSettingVariables.Show) ? false : '';

  if (settings) {
    status = settings[param];

    if (type === AccountDocSettingVariables.Show) {
      status = strToBool(status);
    }
  }

  return status;
};
export const getAccountDocSettingVariables = (accountBillingProfile: any = null) => {
  let detailsDeliveryOrder = null;
  if (accountBillingProfile && accountBillingProfile.accountDocSettings && accountBillingProfile.accountDocSettings.length > 0) {
    let items = accountBillingProfile.accountDocSettings.filter((x: any) => x.type === AccountDocSettingType.DeliveryOrder);
    if (items && items.length > 0) {
      detailsDeliveryOrder = items[0];
    }
  }

  return detailsDeliveryOrder &&
    detailsDeliveryOrder.accountDocSettingVariables &&
    detailsDeliveryOrder.accountDocSettingVariables.length > 0
    ? detailsDeliveryOrder.accountDocSettingVariables
    : [];
};
export const getAccountDocSettingItem = (accountBillingProfile: any = null, variableName: any = "", type: any = AccountDocSettingVariables.String, defaultValue: any = "") => {
  let accountDocSettingVariables = getAccountDocSettingVariables(accountBillingProfile);
  let accountDocSettingItem = findSettings(accountDocSettingVariables, variableName);

  if (type === AccountDocSettingVariables.Show) {
    let defValue = strToBool(defaultValue);
    let value = findSettingsItem(accountDocSettingItem, type);
    return value === false || value === true ? value : defValue;
  } else {
    let value = findSettingsItem(accountDocSettingItem, type);
    return value && value !== "" ? value : defaultValue;
  }
};
export const getAccountDocSettingVariablesWeightChit = (accountBillingProfile: any = null) => {
  let detailsDeliveryOrder = null;
  if (accountBillingProfile && accountBillingProfile.accountDocSettings && accountBillingProfile.accountDocSettings.length > 0) {
    let items = accountBillingProfile.accountDocSettings.filter((x: any) => x.type === AccountDocSettingType.WeightChit);
    if (items && items.length > 0) {
      detailsDeliveryOrder = items[0];
    }
  }

  return detailsDeliveryOrder &&
    detailsDeliveryOrder.accountDocSettingVariables &&
    detailsDeliveryOrder.accountDocSettingVariables.length > 0
    ? detailsDeliveryOrder.accountDocSettingVariables
    : [];
};
export const getAccountDocSettingItemWeightChit = (accountBillingProfile: any = null, variableName: any = "", type: any = AccountDocSettingVariables.String, defaultValue: any = "") => {
  let accountDocSettingVariables = getAccountDocSettingVariablesWeightChit(accountBillingProfile);
  let accountDocSettingItem = findSettings(accountDocSettingVariables, variableName);

  if (type === AccountDocSettingVariables.Show) {
    let defValue = strToBool(defaultValue);
    let value = findSettingsItem(accountDocSettingItem, type);
    return value === false || value === true ? value : defValue;
  } else {
    let value = findSettingsItem(accountDocSettingItem, type);
    return value && value !== "" ? value : defaultValue;
  }
};
export const getSiteContact = (values: any) => {
  let arr = [];

  if(values){
    let arr1 = [];
    let contactPerson1 = values.contactPersonName && values.contactPersonName !== "" ? values.contactPersonName : "";
    let contactNumber1 = values.contactPersonPhoneNumber && values.contactPersonPhoneNumber !== "" ? values.contactPersonPhoneNumber : "";

    if (contactPerson1 !== "") arr1.push(contactPerson1);
    if (contactNumber1 !== "") arr1.push("(" + contactNumber1 + ")");

    let CPN1 = arr1.join(" : ");
    if (CPN1 !== "") arr.push(CPN1);


    let arr2 = [];
    let contactPerson2 = values.contactPersonNameTwo && values.contactPersonNameTwo !== "" ? values.contactPersonNameTwo : "";
    let contactNumber2 = values.contactPersonPhoneNumberTwo && values.contactPersonPhoneNumberTwo !== "" ? values.contactPersonPhoneNumberTwo : "";

    if (contactPerson2 !== "") arr2.push(contactPerson2);
    if (contactNumber2 !== "") arr2.push("(" + contactNumber2 + ")");

    let CPN2 = arr2.join(" : ");
    if (CPN2 !== "") arr.push(CPN2);
  }
  return arr.join("\n\r");
}


export interface initialValuesStruct {
  fromName: string,
  emailAddresses: Array<any>,
  contactEmails: Array<any>,
  subject: string,
  messagePlainText: string,
  messageHTML: string,
  message: any,
  includeAttachment: boolean,
  includePdfAttachmentDeliveryOrder: boolean,
  includePdfAttachmentPhoto: boolean,
  includePdfAttachmentWeightChit: boolean,
  layout: any,
};
export const initialValues: initialValuesStruct = {
  fromName: '',
  emailAddresses: [],
  contactEmails: [],
  subject: '',
  messagePlainText: '',
  messageHTML: '',
  message: '',
  includeAttachment: false,
  includePdfAttachmentDeliveryOrder: false,
  includePdfAttachmentPhoto: false,
  includePdfAttachmentWeightChit: false,
  layout: WeighChitLayout.Default,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  form: any,
  
  isLoadingAccountBillingProfile: boolean,
  accountBillingProfile: any,
}

function NewReducer() {
  const name = 'jobFormPrintPreviewSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    form: null,

    isLoadingAccountBillingProfile: false,
    accountBillingProfile: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, form: any }>) => {
      state.show = action.payload.show;
      state.form = action.payload.form;
    },
    
    startDetailsAccountBillingProfile: (state: InitState) => {
      state.isLoadingAccountBillingProfile = true;
      state.accountBillingProfile = null;
    },
    finishDetailsAccountBillingProfile: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload) ? action.payload : null;
      state.accountBillingProfile = data;
      state.isLoadingAccountBillingProfile = false;
    },

    startSendCompleteJobEmail: (state: InitState) => {
      state.isLoading = true;
    },
    finishSendCompleteJobEmail: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startGetJobDoc: (state: InitState) => {
      state.isLoading = true;
    },
    finishGetJobDoc: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callDetailsAccountBillingProfileApi: (id: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetailsAccountBillingProfile());

      await axios.get('AccountBillingProfile/' + id).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishDetailsAccountBillingProfile(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetailsAccountBillingProfile(null));
      });
    },

    callSendCompleteJobEmailApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startSendCompleteJobEmail());

      await axios.post('job/send-complete-job-email', params).then((result: any) => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishSendCompleteJobEmail(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishSendCompleteJobEmail(null));
      });
    },

    callGetJobDocApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startGetJobDoc());

      await axios.post('job/get-job-doc', params).then((result: any) => {
        let data = result.data;
        
        callback(true, data);
        dispatch(actions.finishGetJobDoc(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishGetJobDoc(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();