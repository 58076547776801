/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../../v5/utils/axios';
import { success, successAPI, errorAPI, getMaxPageSize } from '../../../../../v5/utils/utils';
import Yup from '../../../../../v5/utils/yup';
// import {  } from '../../../../../v5/utils/enums';


export const fields = {
  jobNumber: {
    id: 'jobNumber',
    label: 'DO#',
    placeholder: '',
  },
  customerName: {
    id: 'customerName',
    label: 'Customer name',
    placeholder: '',
  },
  isBillable: {
    id: 'isBillable',
    label: 'Status',
    placeholder: '',
    optionBillable: 'Billable',
    optionNonBillable: 'Non Billable',
  },

  accountBillingProfileId: {
    id: 'accountBillingProfileId',
    label: 'Billing by',
    placeholder: 'Billing by',
  },
  billingAddress: {
    id: 'billingAddress',
    label: 'Billing address',
    placeholder: 'Address',
  },
  invoiceDate: {
    id: 'invoiceDate',
    label: 'Invoice date',
    placeholder: 'Date',
  },
  billedDate: {
    id: 'billedDate',
    label: 'Billed date',
    placeholder: 'Date',
  },
  paymentTermsId: {
    id: 'paymentTermsId',
    label: 'Payment terms',
    placeholder: 'Payment terms',
  },
  xeroPaymentTerm: {
    id: 'xeroPaymentTerm',
    label: 'Payment terms',
    placeholder: 'Payment terms',
  },
  billingNote: {
    id: 'billingNote',
    label: 'Billing note',
    placeholder: 'Notes',
  },
  invoiceNumber: {
    id: 'invoiceNumber',
    label: 'Invoice number',
    placeholder: 'Invoice number',
  },

  serviceTag: {
    id: 'serviceTag',
    label: 'Service name',
    placeholder: '',
  },
  billingType: {
    id: 'billingType',
    label: 'Billing action',
    placeholder: '',
  },
  chargeCategoryId: {
    id: 'chargeCategoryId',
    label: 'Billing category',
    placeholder: '',
  },
  quantity: {
    id: 'quantity',
    label: 'Quantity',
    placeholder: '',
  },
  price: {
    id: 'price',
    label: 'Rate',
    placeholder: '',
  },
  total: {
    id: 'total',
    label: 'Total',
    placeholder: '',
  },
  delete: {
    id: 'delete',
    label: 'Delete',
    placeholder: '',
  },
  rate: {
    id: 'rate',
    label: 'Rate',
    placeholder: '',
  },
  additionalServices: {
    id: 'rate',
    label: 'Additional Services',
    placeholder: '',
  }
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isBillable: Yup.bool().oneOf([true, false]).label(fields.isBillable.label),
    billedDate: Yup.string().nullable().label(fields.billedDate.label),
    invoiceDate: Yup.string().nullable().label(fields.invoiceDate.label),
    invoiceNumber: Yup.string().nullable().label(fields.invoiceNumber.label),
    accountBillingProfileId: Yup.number().nullable().label(fields.accountBillingProfileId.label),
    paymentTermsId: Yup.number().nullable().label(fields.paymentTermsId.label),
    xeroPaymentTerm: Yup.number().nullable().positive().min(0).label(fields.xeroPaymentTerm.label),
    billingAddress: Yup.string().nullable().label(fields.billingAddress.label),
    billingNote: Yup.string().nullable().label(fields.billingNote.label),
    pricings: Yup.array().when(['isBillable'], ([isBillable]) => {
      if (isBillable) {
        return Yup.array().of(Yup.object().shape({
          serviceTag: Yup.string().required().label(fields.serviceTag.label),
          billingType: Yup.number().nullable().label(fields.billingType.label),
          chargeCategoryId: Yup.number().nullable().label(fields.chargeCategoryId.label),
          quantity: Yup.number().nullable().min(0).label(fields.quantity.label),
          price: Yup.number().nullable().min(0).label(fields.price.label),
        }));
      } else {
        return Yup.array().of(Yup.object().shape({
          serviceTag: Yup.string().label(fields.serviceTag.label),
          billingType: Yup.number().nullable().label(fields.billingType.label),
          chargeCategoryId: Yup.number().nullable().label(fields.chargeCategoryId.label),
          quantity: Yup.number().nullable().min(0).label(fields.quantity.label),
          price: Yup.number().nullable().min(0).label(fields.price.label),
        }));
      }
    }).hasEmpty('serviceTag', 'There are empty fields'),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const getBillingTypeName = (billingType: number|null) => {
  if(billingType === BillingTypes.Expense){
    return 'Expense';
  } else if(billingType === BillingTypes.Purchase){
    return 'Purchase';
  } else if(billingType === BillingTypes.Invoice){
    return 'Invoice';
  } else {
    return '';
  }
}


export const ServiceType = {
  StandardService: 0,
  BundleService: 1,
  AdditionalService: 2
}

export const BillingTypes = {
  Invoice: 0,
  Purchase: 1,
  Expense: 2
}


export interface pricingsItemStruct {
  serviceType: number|null,

  serviceItemId: number|null,
  serviceTag: string,
  serviceItem: number|null,

  billingType: number|null,
  
  chargeCategoryId: number|null,
  chargeCategoryName: string,
  chargeCategory: number|null,

  quantity: number|null,
  price: number|null,
}
export const initPricingsItem: pricingsItemStruct = {
  serviceType:  ServiceType.AdditionalService,

  serviceItemId: null,
  serviceTag: '',
  serviceItem: null,

  billingType: BillingTypes.Invoice,

  chargeCategoryId: null,
  chargeCategoryName: '',
  chargeCategory: null,

  quantity: null,
  price: null,
}


export const initialValues: any = {
  isBillable: false,
  pricings: [],
};


interface InitState {
  show: boolean,
  form: any,

  isLoadingUpdateBilling: boolean,
  listUpdateBilling: Array<any>,
}

function NewReducer() {
  const name = 'jobFormBillingJobSlice';


  const initialState: InitState = {
    show: false,
    form: null,

    isLoadingUpdateBilling: false,
    listUpdateBilling: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, form: any }>) => {
      state.show = action.payload.show;
      state.form = action.payload.form;
    },

    startUpdateBilling: (state: InitState) => {
      state.isLoadingUpdateBilling = true;
      state.listUpdateBilling = [];
    },
    finishUpdateBilling: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      state.listUpdateBilling = data;
      state.isLoadingUpdateBilling = false;
    },
  };


  const apis = {
    callUpdateBillingApi: ({ customerId = null, customerSiteId = null }: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdateBilling());

      let params = {
        currentPage: 1,
        pageSize: getMaxPageSize(),
        // searchQuery: '',
        // sortColumn: 'serviceTag',
        // sortDir: 'desc',
        includeGlobalService: true,
        includeTaxRate: true,
        customerId: customerId,
        customerSiteId: customerSiteId,
      };

      await axios.get('service-item', { params: params }).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishUpdateBilling(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishUpdateBilling(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();