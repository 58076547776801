import React, { ReactNode } from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  Tabs,
  Tab,
  Form,
  InputGroup,
} from 'react-bootstrap';
// import styled from 'styled-components';

import { Checkbox, Spin } from 'antd';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector, Tables } from '../../../../utils/store.tsx';
import slice, { changePaginationToSelectedItem } from './slice.tsx';
// import {  } from '../../slice.tsx';
// import {  } from '../../../manage/jobs/jobTypes/slice.tsx';

import List1 from './List1.tsx';
import List2 from './List2.tsx';
import List3 from './List3.tsx';

import { escapeChars, escapeCharsInStringOnPaste, getMaxPageSize, isNumeric } from '../../../../utils/utils.tsx';
import { ServiceType } from '../../../../utils/enums.tsx';

// import createGenericSlice from '../../../../components/table/withSlice/tableSlice.tsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/x.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/icons/search.svg";

// ----------------------------------------------------------------------

// const table = createGenericSlice<any>(Tables.JobLocalService);


const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  const { show, tab, rows, values, customerSiteId, groupIndex, group, blockIndex, block, index, serviceItem, wasteTypes, isLoadingLocalGlobal, isLoadingService, isLoadingServicesItems, includeLocalService, search, selected, selectedHistory, listLocalGlobal, listHistory, clientSideItemsPerPage, clientSideItemsPerPageHistory } = useTypedSelector((state: RootState) => state.jobFormBinDetailsServicesSlice);
  const dispatch = useDispatch<AppDispatch>();

  const refList1 = React.useRef<any>(null);
  const refList2 = React.useRef<any>(null);
  const refList3 = React.useRef<any>(null);


  React.useEffect(() => {
    if(show){
      dispatch(slice.setSelected(serviceItem ? [serviceItem] : []));
      dispatch(slice.setSelectedHistory(serviceItem));

      callServiceItemsReadApi((state: boolean, data: any) => {
        changePaginationToSelectedItem((data && data.data && data.data.length > 0) ? data.data : [], serviceItem, clientSideItemsPerPage, (page, t) => {
          if(t === 'tab1'){
            dispatch(slice.setClientSideCurrentPageLocal(page));
          } else {
            dispatch(slice.setClientSideCurrentPageGlobal(page));
          }
        });
      });
      callBinTypeHistoryReadApi((state: boolean, data: any) => {});
    }
  }, [show, serviceItem]);

  React.useEffect(() => {
    if(show){
      const filteredRows = rows.filter((row: any) => {
        const matchingRows = rows.filter((r: any) => 
          wasteTypes.some(waste => waste.serviceItemId === r.serviceItemId)
        );
      
        if (matchingRows.length === 0) return false;
      
        return wasteTypes.some((waste: any) => 
          waste.serviceItemId === row.serviceItemId &&
          matchingRows.some(mr => mr.binTypeId === row.binTypeId && mr.wasteTypeId === row.wasteTypeId)
        );
      });
      
      if(filteredRows && filteredRows.length > 0){
        if(serviceItem){
          filteredRows.unshift(serviceItem);
        }
        dispatch(slice.setSelected(filteredRows));
      }
    }
  }, [show, rows, wasteTypes]);

  React.useEffect(() => {
    if(includeLocalService) {
      callAllServiceItemsReadApi((state, data) => {
        clientSideSearch(false);
        changePaginationToSelectedItem((data && data.data && data.data.length > 0) ? data.data : [], serviceItem, clientSideItemsPerPage, (page, t) => {
          if(t === 'tab1'){
            dispatch(slice.setClientSideCurrentPageLocal(page));
          } else {
            dispatch(slice.setClientSideCurrentPageGlobal(page));
          }
        });
      });
    } else {
      callServiceItemsReadApi((state: boolean, data: any) => {});
    }
  }, [includeLocalService]);


  const clientSideSearch = (state: any = true) => {
    let allRows = (listLocalGlobal && listLocalGlobal.length > 0) ? _.cloneDeep(listLocalGlobal) : [];
    let allRowsHistory = (listHistory && listHistory.length > 0) ? _.cloneDeep(listHistory) : [];
    
    if(search != ''){
      let filteredRows = (listLocalGlobal && listLocalGlobal.length > 0) ? allRows.filter((x: any) => x.serviceTag.toLowerCase().trim().includes(search.toLowerCase().trim())) : [];
      let filteredRowsHistory = (listHistory && listHistory.length > 0) ? allRowsHistory.filter((x: any) => x.binTypeName.toLowerCase().trim().includes(search.toLowerCase().trim()) || x.wasteTypeName.toLowerCase().trim().includes(search.toLowerCase().trim())) : [];
      
      dispatch(slice.setLocalGlobal({
        rows: filteredRows,
        rowsHistory: filteredRowsHistory,
        clientSideCurrentPageLocal: 1,
        clientSideCurrentPageGlobal: 1,
        clientSideCurrentPageHistory: 1,
        isLoadingLocalGlobal: false,
      }));
    } else {
      dispatch(slice.setLocalGlobal({
        rows: allRows,
        rowsHistory: allRowsHistory,
        clientSideCurrentPageLocal: 1,
        clientSideCurrentPageGlobal: 1,
        clientSideCurrentPageHistory: 1,
        isLoadingLocalGlobal: false,
      }));
      
      if(tab === 'tab2'){
        callBinTypeHistoryReadApi((state: boolean, data: any) => {});
      } else {
        callServiceItemsReadApi((state: boolean, data: any) => {});
      }
    }
  }
  const callServiceItemsReadApi = (callback: (state: boolean, data: any) => void) => {
    let data: any = {
      currentPage: 1,
      pageSize: getMaxPageSize(),
      // searchQuery: search,
      types: ServiceType.StandardService.toString() + ',' + ServiceType.BundleService.toString(),
      includeSites: false,
      includeTaxRate: false,
      includeGlobalService: true,
      isIncludeInactive: null,
      isInactive: null,
    };

    let incLocalService = ((includeLocalService === true) || (includeLocalService === false)) ? includeLocalService : null;
    if (incLocalService) {
      data['includeLocalService'] = incLocalService;
      data['distinct'] = true;
    } else {
      data['distinct'] = null;
    }

    if (!incLocalService) {
      if (values?.customerId) {
        data['customerId'] = values?.customerId;
      }

      if (customerSiteId) {
        data['customerSiteId'] = customerSiteId;
      }
    }

    dispatch(slice.callReadLocalGlobalApi(data, callback));
  }
  const callAllServiceItemsReadApi = (callback: (state: boolean, data: any) => void) => {
    let data = {
      currentPage: 1,
      pageSize: getMaxPageSize(),
      includeGlobalService: true,
      includeLocalService: true,
      types: ServiceType.StandardService.toString() + ',' + ServiceType.BundleService.toString(),
      includeSites: false,
      includeTaxRate: false,
      distinct: true,
    };

    dispatch(slice.callReadLocalGlobalApi(data, callback));
  }
  const callBinTypeHistoryReadApi = (callback: (state: boolean, data: any) => void) => {
    let data: any = {
      currentPage: 1,
      pageSize: getMaxPageSize(),
      // searchQuery: search,
      sortColumn: 'created',
      sortDir: 'asc',
      customerSiteId: customerSiteId,
    };

    dispatch(slice.callReadHistoryApi(data, callback));
  }

  const onSave = () => {
    let selectedItem: Array<any> = [];
    if(tab === 'tab2'){
      if(selectedHistory && isNumeric(selectedHistory?.serviceItemId)){
        selectedItem = [selectedHistory];
      } else {
        if(listLocalGlobal && listLocalGlobal.length > 0){
          let findItem = listLocalGlobal.find((x: any) => (x.binTypeId == selectedHistory.binTypeId) && (x.wasteTypeId == selectedHistory.wasteTypeId));
          if(findItem){
            selectedItem = [findItem]
          }
        }
      }
    } else {
      selectedItem = selected;
    }
    
    if(selectedItem && selectedItem.length > 0 && selectedItem[0] && isNumeric(selectedItem[0]?.serviceItemId)){
      dispatch(slice.callDetailsServicesItemsApi(selectedItem.map((x: any) => x?.serviceItemId), (state: boolean, selectedServices: any) => {
        if(state){
          let arr = selectedServices.map((y: any) => {
            return {
              ...y,
              binTypeName: y?.binType?.binTypeName,
              wasteTypeName: y?.wasteType?.wasteTypeName,
            }
          });

          let mainData = arr.find((x: any) => x.serviceItemId == selectedItem[0]?.serviceItemId);
          let anotherData = arr.filter((x: any) => x.serviceItemId != mainData.serviceItemId);

          if(props.onSave){
            props.onSave(groupIndex, blockIndex, index, mainData, anotherData, tab);
          }
  
          onCancel();
        } else {
          onCancel();
        }
      }));
    } else {
      onCancel();
    }
  }
  const onCancel = () => {
    dispatch(slice.setSelected([]));
    dispatch(slice.setSelectedHistory([]));
    dispatch(slice.setSearch(''));
    dispatch(slice.setTab('tab1'));
    dispatch(slice.setShow({ show: false, values: null, customerSiteId: null, groupIndex: null, group: null, blockIndex: null, block: null, index: null, serviceItem: null, wasteTypes: [] }));
  }


  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        <Button
          variant={'custom-text'}
          size={'sm'}
          className='me-12'
          onClick={(e) => {
            onCancel();
          }}
        >Cancel</Button>
        <Button
          variant={'custom-primary'}
          size={'sm'}
          disabled={
            (tab === 'tab2')
            ?
              (selectedHistory == null)
            :
              !(selected && selected.length > 0)
          }
          onClick={(e) => {
            onSave();
          }}
        >Save Changes</Button>
      </Col>
    </Row>
  }

  const topSection = () => {
    return <div className=''>
      <Row className='align-items-center g-12'>
        <Col xs={12} md={4} lg={3} xl={2}>
          <InputGroup>
            <InputGroup.Text className='p-search'>
              <SearchIcon />
            </InputGroup.Text>
            <Form.Control
              type={'text'}
              autoComplete='off'
              placeholder={'Search'}
              size={'sm'}
              isInvalid={false}
              value={(search && search != '') ? search : ''}
              onChange={(e: any) => {
                escapeChars(e);
                dispatch(slice.setSearch(e.target.value));
              }}
              onKeyDown={(e: any) => {
                escapeChars(e);

                if (e.key === 'Enter') {
                  clientSideSearch();
                }
              }}
              onPaste={(e: any) => {
                const pastedText = e.clipboardData.getData('text');
                const cleanedText = escapeCharsInStringOnPaste(pastedText);
                dispatch(slice.setSearch(cleanedText));
                e.preventDefault();
              }}
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={'auto'}>
          {(tab === 'tab1') && <Checkbox
            checked={includeLocalService}
            onChange={(e) => {
              dispatch(slice.setIncludeLocalService(e.target.checked));
            }}
          >Search from all customers</Checkbox>}
        </Col>
        <Col xs={12} md={true} className='d-none d-md-block'></Col>
      </Row>
    </div>
  }

  const formElements = () => {
    return <Row className='g-24 align-items-center'>
      <Col xs={12}>
        <Tabs
          activeKey={tab}
          onSelect={(eventKey: any) => {
            dispatch(slice.setTab(eventKey));
          }}
        >
          <Tab eventKey={'tab1'} title={'Site Service'} />
          <Tab eventKey={'tab2'} title={'Site History'} />
        </Tabs>
      </Col>
      <Col xs={12}>
        <Row className='g-24 align-items-center'>
          <Col xs={12}>{topSection()}</Col>

          {(tab === 'tab1' && <>
            <Col xs={12}>
              <List1 ref={refList1} />
            </Col>
            <Col xs={12}>
              <List2 ref={refList2} />
            </Col>
          </>)}

          {(tab === 'tab2' && <>
            <Col xs={12}>
              <List3 ref={refList3} />
            </Col>
          </>)}
        </Row>
      </Col>
    </Row>
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: '90%' }}
  >
    <Offcanvas.Header closeButton>
      <Offcanvas.Title>Service</Offcanvas.Title>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <Spin
        spinning={(
          isLoadingLocalGlobal || 
          isLoadingService || 
          isLoadingServicesItems
        )}
        wrapperClassName={'no-height'}
      >
        {formElements()}
      </Spin>
    </Offcanvas.Body>
    <Modal.Footer className='border-top'>{footerElements()}</Modal.Footer>
  </Offcanvas>
}

export default PageComponent;
