import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Button,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Alert } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, getTimeAvailability, isCopy } from '../slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
import { CustomerSiteAvailability } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";
import { ReactComponent as AlertInfoIcon } from "../../../../v5/assets/icons/alert-info.svg";
import { ReactComponent as AlertCircleIcon } from "../../../../v5/assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../../v5/assets/icons/alert-triangle.svg";
import { ReactComponent as AlertCheckIcon } from "../../../../v5/assets/icons/alert-check.svg";

// ----------------------------------------------------------------------

const Alerts = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isLoading, isCreditLimit, msgCreditLimit } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const dispatch = useDispatch<AppDispatch>();

  let timeAvailabilityObj = getTimeAvailability(values);


  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>

      {isCreditLimit && <Col xs={12}>
        <Alert
          message={msgCreditLimit}
          description=""
          type="error"
          showIcon
          icon={<AlertCircleIcon />}
        />
      </Col>}

      {!isCreditLimit && <>
      
        {(timeAvailabilityObj?.timeAvailability == CustomerSiteAvailability.NotAvailable) && <Col xs={12}>
          <Alert
            message={(timeAvailabilityObj?.isWeekend ? 'Weekend' : 'Weekdays') + ' time is not available'}
            description=""
            type="warning"
            showIcon
            icon={<AlertTriangleIcon />}
          />
        </Col>}

        {/* <Col xs={12}>
          <Alert
            message="This price has changed."
            description={<Row className='align-items-center g-10'>
              <Col xs={12} md={true}>Do you wish to update to the new pricing?</Col>
              <Col xs={'auto'} md={'auto'}>
                <Button
                  variant={'custom-outlined'}
                  size={'sm'}
                >
                  Stay with Old Price
                </Button>
              </Col>
              <Col xs={'auto'} md={'auto'}>
                <Button
                  variant={'custom-primary'}
                  size={'sm'}
                >
                  Update to New Price
                </Button>
              </Col>
            </Row>}
            type="info"
            showIcon
            icon={<AlertInfoIcon />}
          />
        </Col> */}

        {isCopy() && <Col xs={12}>
          <Alert
            message="This is a copy of your current job form."
            description="Revise as needed and save this form as new"
            type="info"
            showIcon
            icon={<AlertInfoIcon />}
          />
        </Col>}
      </>}

    </Row>
  </Col>
}

export default Alerts;
