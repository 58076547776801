import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Button,
  Form,
} from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, findStatus, formatCustomerSiteAddress, concatStringRemarks, customerSiteInit, checkStatus } from '../slice.tsx';
import customerSiteMiniSlice from "./customerSite/slice.tsx";

// enums
import { limitRows, getMaxPageSize } from '../../../../v5/utils/utils.tsx';
import { CustomerSiteAvailability } from '../../../../v5/utils/enums.tsx';

import { LocationEnum, LocalJobType } from '../../manage/jobs/jobTypes/slice.tsx';

// components
import SmartSelect from '../../../../v5/components/select/SmartSelect.tsx';

import { ReactComponent as EditIcon } from "../../../../v5/assets/icons/edit-normal.svg";
import { ReactComponent as PlusIcon } from "../../../../v5/assets/icons/plus.svg";

// ----------------------------------------------------------------------

const Location = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { job } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();


  const getFields = ({
    group: group,
    g: g,
    item: item,
    i: i,
  }: any) => {
    return <Col key={'template_steps_' + i} xs={12}>
      <Row className='gx-32 align-items-center'>
        <Col xs={12} md={6}>
          <Row className='g-16 align-items-center'>
            <Col xs={12}>
              <Form.Group>
                <Form.Label disabled={!(values.customerId)}>{fields.customerSiteId.label}</Form.Label>
                <Form.Control
                  as={SmartSelect}
                  isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].customerSiteId)}
                  value={item.customerSiteId}
                  placeholder={fields.customerSiteId.placeholder}

                  addText={'Add new customer site'}
                  apiPath={'customerSite'}
                  customParams={{
                    currentPage: 1,
                    pageSize: getMaxPageSize(),
                    customerId: values?.customerId,
                    showRecentJob: true,
                  }}
                  fieldNames={{ value: 'customerSiteId', label: 'siteNameDisplay' }}
                  selected={{ customerSiteId: item.customerSiteId, siteNameDisplay: item.customerSiteName }}
                  getPopupContainer={(trigger: any) => trigger.parentNode}
                  footerRender={(isLoading: boolean, ref: any) => {
                    return <Button
                      variant={'custom-text'}
                      size={'sm'}
                      disabled={isLoading}
                      onClick={(e) => { 
                        e.preventDefault();
                        e.stopPropagation();

                        dispatch(customerSiteMiniSlice.setShow({ show: true, id: null, customerId: values.customerId, fieldType: 1 }));
                      }}
                    ><PlusIcon /> Add new customer site</Button>
                  }}

                  hasOptionRender={false}
                  hasFooterRender={true}
                  searchable={true}
                  allowClear={true}
                  selectedIcon={false}
                  disabled={!(values.customerId) || (checkStatus(values?.currentStatusName, ['in progress', 'completed', 'cancelled', 'failed'])) || values.isCollectJob}
                  virtual={true}

                  onChangeValue={async (value: any, option: any, ref: any) => {
                    if(option){
                      let customerSiteItem = _.cloneDeep(customerSiteInit);
                      customerSiteItem['customerSiteId'] = value;
                      customerSiteItem['customerSiteName'] = option.siteNameDisplay;
                      customerSiteItem['site'] = option;

                      await setFieldValue('stepGroups.' + g + '.customerSites.' + i, customerSiteItem);

                      if(group && group.blocks && group.blocks.length > 0){
                        group.blocks.forEach((blockItem: any, blockIndex: number) => {
                          
                          if(blockItem && blockItem.steps && blockItem.steps.length > 0){
                            let blockStepsArr: Array<any> = [];
                            blockItem.steps.forEach((stepItem: any) => {
                              if(stepItem?.locationType === LocationEnum.CustomerSite){
                                blockStepsArr.push({
                                  ...stepItem,
                                  address: customerSiteItem?.address || '',
                                  fullAddress: customerSiteItem?.fullAddress || '',
                                  latitude: customerSiteItem?.latitude || '',
                                  longitude: customerSiteItem?.longitude || '',
                                  customerSiteId: customerSiteItem?.customerSiteId || null,
                                  customerSiteName: customerSiteItem?.customerSiteName || '',
                                  site: customerSiteItem?.site || null,
                                  siteName: customerSiteItem?.siteName || '',
                                  siteRemarks: customerSiteItem?.instructions || '',
                                  contactNumberOne: customerSiteItem?.contactPersonName || '',
                                  contactNumberTwo: customerSiteItem?.contactPersonNameTwo || '',
                                  contactPersonOne: customerSiteItem?.contactPersonName || '',
                                  contactPersonTwo: customerSiteItem?.contactPersonNameTwo || '',
                                });
                              } else {
                                blockStepsArr.push(stepItem);
                              }
                            });
                            
                            setFieldValue('stepGroups.' + g + '.blocks.' + blockIndex + '.steps', blockStepsArr);
                          }
                        });
                      }

                      if(option){
                        let remarks = (option && option.remarks && option.remarks !== '') ? option.remarks : '';
                        let instructions = (option && option.instructions && option.instructions !== '') ? option.instructions : '';
                        let contactPersonName = (option && option.contactPersonName && option.contactPersonName !== '') ? option.contactPersonName : '';
                        let contactPersonPhoneNumber = (option && option.contactPersonPhoneNumber && option.contactPersonPhoneNumber !== '') ? option.contactPersonPhoneNumber : '';
                        let contactPersonNameTwo = (option && option.contactPersonNameTwo && option.contactPersonNameTwo !== '') ? option.contactPersonNameTwo : '';
                        let contactPersonPhoneNumberTwo = (option && option.contactPersonPhoneNumberTwo && option.contactPersonPhoneNumberTwo !== '') ? option.contactPersonPhoneNumberTwo : '';
                        
                        let isContactPersonSendNotification = ((option.isContactPersonSendNotification === false) || (option.isContactPersonSendNotification === true)) ? option.isContactPersonSendNotification : false;
                        let isContactPersonTwoSendNotification = ((option.isContactPersonTwoSendNotification === false) || (option.isContactPersonTwoSendNotification === true)) ? option.isContactPersonTwoSendNotification : false;
                
                        let copySiteRemarksToJob = (option && ((option.copySiteRemarksToJob == false) || (option.copySiteRemarksToJob == true))) ? option.copySiteRemarksToJob : false;
                        
                        let weekdaysAvailability = (option && option.weekdaysAvailability) ? option.weekdaysAvailability : CustomerSiteAvailability.All;
                        let weekendAvailability = (option && option.weekendAvailability) ? option.weekendAvailability : CustomerSiteAvailability.All;
                        
                        let defaultDrivers = (option.defaultDrivers && option.defaultDrivers.length > 0) ? option.defaultDrivers : [];
                        if(defaultDrivers && defaultDrivers.length > 0){
                          let defArr = defaultDrivers.filter((x: any) => x.defaultVehicle !== null);
                          if(defArr && defArr.length > 0){
                            let driverItem = defArr[0];
                            let driverId = (driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
                            let driverName = (driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
                            
                            if(values.driverId === null || values.driverId <= 0){
                              await setFieldValue('driverId', driverId);
                              await setFieldValue('driverName', driverName);
                            }

                            let vehicleItem = defArr[0].defaultVehicle;
                            let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                            let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';
                            
                            if(values.vehicleId === null || values.vehicleId <= 0){
                              await setFieldValue('vehicleId', vehicleId);
                              await setFieldValue('vehicleName', vehicleName);

                              let selectedStatus = findStatus(values?.jobTemplate?.templateStatuses, 'unassigned');
                              if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.jobStatusId) {
                                let newStatus = findStatus(values?.jobTemplate?.templateStatuses, 'assigned');
                                let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
                                let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';
                                
                                await setFieldValue('statusId', newStatusId);
                                await setFieldValue('statusName', newStatusName);
                              }
                            }
                          }
                        }

                        let prevRemarks = (values.customerSite && values.customerSite.remarks && values.customerSite.remarks != '') ? values.customerSite.remarks : '';
                        let newRemarks = concatStringRemarks(values.remarks, remarks, prevRemarks, copySiteRemarksToJob);
                        await setFieldValue('remarks', newRemarks);
                        
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.instructions', instructions);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonName', contactPersonName);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonPhoneNumber', contactPersonPhoneNumber);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonNameTwo', contactPersonNameTwo);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonPhoneNumberTwo', contactPersonPhoneNumberTwo);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.isContactPersonSendNotification', isContactPersonSendNotification);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.isContactPersonTwoSendNotification', isContactPersonTwoSendNotification);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.address', formatCustomerSiteAddress(option));
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.latitude', option?.latitude);
                        await setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.longitude', option?.longitude);
                      }
                    } else {
                      await setFieldValue('stepGroups.' + g + '.customerSites.' + i, customerSiteInit);

                      if(group && group.blocks && group.blocks.length > 0){
                        group.blocks.forEach((blockItem: any, blockIndex: number) => {
                          
                          if(blockItem && blockItem.steps && blockItem.steps.length > 0){
                            let blockStepsArr: Array<any> = [];
                            blockItem.steps.forEach((stepItem: any) => {
                              if(stepItem?.locationType === LocationEnum.CustomerSite){
                                blockStepsArr.push({
                                  ...stepItem,
                                  address: '',
                                  fullAddress: '',
                                  latitude: '',
                                  longitude: '',
                                  customerSiteId: null,
                                  customerSiteName: '',
                                  site: null,
                                  siteName: '',
                                  siteRemarks: '',
                                  contactNumberOne: '',
                                  contactNumberTwo: '',
                                  contactPersonOne: '',
                                  contactPersonTwo: '',
                                });
                              } else {
                                blockStepsArr.push(stepItem);
                              }
                            });
                            
                            setFieldValue('stepGroups.' + g + '.blocks.' + blockIndex + '.steps', blockStepsArr);
                          }
                        });
                      }
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].customerSiteId) && errors.steps[i].customerSiteId}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label disabled={false}>{fields.instructions.label}</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={fields.instructions.rows}
                  autoComplete='off'
                  placeholder={fields.instructions.placeholder}
                  disabled={checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed'])}
                  value={item.instructions}
                  onChange={(e) => {
                    limitRows(e.target.value, fields.instructions.rows, async (value: any) => {
                      setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.instructions', value);
                    });
                  }}
                  isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].instructions)}
                />
                <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].instructions) && errors.steps[i].instructions}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6}>
          <Row className='g-16 align-items-center'>
            <Col xs={12}>
              <Form.Label disabled={false}>{fields.contactPersonName.label}</Form.Label>
              <Row className='g-8 align-items-center'>
                <Col xs={6} sm={4} xl={6} xxl={4}>
                  <Form.Group>
                    <Form.Control
                      type={'text'}
                      autoComplete='off'
                      placeholder={fields.contactPersonName.placeholder}
                      disabled={checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed'])}
                      value={item.contactPersonName}
                      onChange={async (e) => {
                        setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonName', e.target.value);
                      }}
                      isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactPersonName)}
                    />
                    <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactPersonName) && errors.steps[i].contactPersonName}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={6} sm={8} xl={6} xxl={8}>
                  <Form.Group>
                    {/* <Form.Label disabled={false}>{fields.contactPersonPhoneNumber.label}</Form.Label> */}
                    <Form.Control
                      type={'text'}
                      autoComplete='off'
                      placeholder={fields.contactPersonPhoneNumber.placeholder}
                      disabled={false}
                      value={item.contactPersonPhoneNumber}
                      onChange={async (e) => {
                        setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonPhoneNumber', e.target.value);
                      }}
                      isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactPersonPhoneNumber)}
                    />
                    <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactPersonPhoneNumber) && errors.steps[i].contactPersonPhoneNumber}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <Form.Label disabled={false}>{fields.contactPersonNameTwo.label}</Form.Label>
              <Row className='g-8 align-items-center'>
                <Col xs={6} sm={4} xl={6} xxl={4}>
                  <Form.Group>
                    <Form.Control
                      type={'text'}
                      autoComplete='off'
                      placeholder={fields.contactPersonNameTwo.placeholder}
                      disabled={checkStatus(values?.currentStatusName, ['completed', 'cancelled', 'failed'])}
                      value={item.contactPersonNameTwo}
                      onChange={async (e) => {
                        setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonNameTwo', e.target.value);
                      }}
                      isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactPersonNameTwo)}
                    />
                    <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactPersonNameTwo) && errors.steps[i].contactPersonNameTwo}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={6} sm={8} xl={6} xxl={8}>
                  <Form.Group>
                    {/* <Form.Label disabled={false}>{fields.contactPersonPhoneNumberTwo.label}</Form.Label> */}
                    <Form.Control
                      type={'text'}
                      autoComplete='off'
                      placeholder={fields.contactPersonPhoneNumberTwo.placeholder}
                      disabled={false}
                      value={item.contactPersonPhoneNumberTwo}
                      onChange={async (e) => {
                        setFieldValue('stepGroups.' + g + '.customerSites.' + i + '.contactPersonPhoneNumberTwo', e.target.value);
                      }}
                      isInvalid={!!(errors && errors.steps && errors.steps.length > 0 && errors.steps[i] && typeof errors.steps[i] === 'object' && errors.steps[i].contactPersonPhoneNumberTwo)}
                    />
                    <Form.Control.Feedback type="invalid">{(errors && errors.steps && errors.steps[i] && errors.steps[i].contactPersonPhoneNumberTwo) && errors.steps[i].contactPersonPhoneNumberTwo}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  }

  const getElements = () => {
    if(values.stepGroups && values.stepGroups.length > 0){
      return <>
        {values.stepGroups.map((group: any, g: number) => {
          return <Row className='g-0 align-items-center' key={'jobform_location_group_' + g}>

            {(group && group.customerSites && group.customerSites.length > 0) && group.customerSites.map((item: any, i: number) => {
              return <React.Fragment key={'jobform_location_group_customersite_' + i}>
                {(i === 1) && <Col xs={12} className='semibold gray-600 mb-16'>Shift to</Col>}
                
                {getFields({
                  group: group,
                  g: g,
                  item: item,
                  i: i,
                })}

                <Col xs={12} className='mt-16 mb-16'>
                  <Divider />
                </Col>
              </React.Fragment>
            })}

          </Row>
        })}
      </>
    }
  }

  
  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
      <Col xs={12}>
        <span className="large semibold gray-900">3. Location</span>
      </Col>

      {
      (values.steps.some((x: any) => x.locationType === LocationEnum.CustomerSite))
      ?
      <Col xs={12}>{getElements()}</Col>
      :
      <Col xs={12}>No customer location</Col>
      }

      {/* <Col xs={12}>
        <Divider />
      </Col> */}
    </Row>
  </Col>
}

export default Location;
