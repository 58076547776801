/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import {
  Row,
  Col,
} from 'react-bootstrap';

import { axios } from '../../../../utils/axios';
import { ReadAPIParams, success, successAPI, errorAPI, placeholderRows, isNumeric, getIntProps, getBoolProps } from '../../../../utils/utils';
import Yup from '../../../../utils/yup';
// import {  } from '../../../../utils/enums';

import { BinActivity, jobBinTypesStruct, jobBinTypesInit, customerSiteInit} from '../../../jobs/slice';


export const JobTemplateType = {
  Default: 0,
  Custom: 1,
}
export const JobStepType = {
  Pull: 0,
  Put: 1,
  Exchange: 2,
  OnTheSpot: 3,
  Out: 4,
  Shift: 5,
  ThrowAtCustomer: 6,
  Task: 7,
  Custom: 8,
}
export const PickDropEmptyTitle = 'Pick & Drop Empty Bin';
export const PickDropLoadedTitle = 'Pick & Drop Loaded Bin';
export const DropWasteDropEmptyTitle = 'Drop Waste & Drop Empty Bin';
export const PickWasteDropWasteTitle = 'Pick Waste & Drop Waste';

export const CustomOptions = {
  No: 0,
  Yes: 1,
  Optional: 2,
}

export const LocalJobType = {
  DROP: 1,
  PICK: 2,
  SHIFT: 3,
  EXCHANGE1: 4,
  EXCHANGE2: 5,
  DISPOSAL: 6,
  ON_THE_SPOT: 7,
  TIPPER: 8,
  TASK: 9,
}
export const LocalBinActivity = {
  PICK_DROP_EMPTY: 1,
  PICK_DROP_LOADED: 2,
  PICK_EMPTY: 3,
  DROP_EMPTY: 4,
  PICK_WASTE: 5,
  DROP_WASTE: 6,
  PICK_WASTE_DROP_LOADED: 7,
  PICK_LOADED_DROP_WASTE: 8,
}
export const ForWith = {
  FOR: 1,
  WITH: 2,
}

export interface LocationStruct {
  CustomerSite: number,
  Yard: number,
  Landfill: number,
};
export interface LocationBoolStruct {
  CustomerSite: boolean,
  Yard: boolean,
  Landfill: boolean,
};
export const LocationEnum = {
  CustomerSite: 0,
  Yard: 1,
  Landfill: 2,
};

export const DefaultLocationEnum = {
  CustomerSiteLocation: 1,
  DefaultLocation: 2,
  NoDefaultLocation: 3,
};
export const StepRender = {
  None: 1,
  Single: 2,
  Multi: 3,
};

export interface BinActivityStruct {
  PickLoadedBin: number,
  DropLoadedBin: number,
  ExchangeBin: number,
  NoActivity: number,
  WasteDisposal: number,
  LoadWaste: number,
  PickEmptyBin: number,
  DropEmptyBin: number,
};
export interface BinActivityBoolStruct {
  PickLoadedBin: boolean,
  DropLoadedBin: boolean,
  ExchangeBin: boolean,
  NoActivity: boolean,
  WasteDisposal: boolean,
  LoadWaste: boolean,
  PickEmptyBin: boolean,
  DropEmptyBin: boolean,
};
export const BinActivityEnum: BinActivityStruct = {
  PickEmptyBin: 6,
  PickLoadedBin: 0,
  LoadWaste: 5,

  DropEmptyBin: 7,
  DropLoadedBin: 1,
  WasteDisposal: 4,

  NoActivity: 3,
  ExchangeBin: 2,
};

export interface DriverAppOptionsStruct {
  RequiredBinID: number,
  TakePhoto: number,
  Signature: number,
  InputBinWeight: number,
  ReviewWasteType: number,
  ChangeBin: number,
  ChangeWaste: number,
  NewBinID: number,
};
export interface DriverAppOptionsBoolStruct {
  RequiredBinID: boolean,
  TakePhoto: boolean,
  Signature: boolean,
  InputBinWeight: boolean,
  ReviewWasteType: boolean,
  ChangeBin: boolean,
  ChangeWaste: boolean,
  NewBinID: boolean,
};
export const DriverAppOptionsEnum = {
  RequiredBinID: 0,
  TakePhoto: 1,
  Signature: 2,
  InputBinWeight: 3,
  ReviewWasteType: 4,
  ChangeBin: 5,
  ChangeWaste: 6,
  NewBinID: 7,
};
export const DriverAppOptionsLabelEnum = {
  RequiredBinID: 'Input Bin ID',
  TakePhoto: 'Take',
  TakePhotoSuffix: 'photo',
  Signature: 'Take signature',
  InputBinWeight: 'Input weight',
  ReviewWasteType: 'Review waste type',
  ChangeBin: 'Edit bin type',
  ChangeWaste: 'Edit waste type',
  NewBinID: 'Add new bin on mobile',
};


export const fields = {
  isActive: {
    id: 'isActive',
    label: 'Status',
    labelTable: 'Status',
    placeholder: '',
  },

  jobTemplateType: {
    id: 'jobTemplateType',
    label: 'Types',
    labelTable: 'Types',
    placeholder: '',
  },
  binActivity: {
    id: 'binActivity',
    label: 'What the driver do?',
    labelTable: 'Bin activity',
    placeholder: 'Select',
  },
  defaultLocationId: {
    id: 'defaultLocationId',
    label: 'Location',
    labelTable: 'Default location',
    placeholder: 'Select',
  },
  stepDescription: {
    id: 'stepDescription',
    label: 'Task description',
    labelTable: 'Task description',
    placeholder: '',
    maxLength: 100,
  },

  jobTemplateName: {
    id: 'jobTemplateName',
    label: 'Job Type Name',
    labelTable: 'Names',
    placeholder: 'Enter job type name',
  },
  jobTemplateDescription: {
    id: 'jobTemplateDescription',
    label: 'Job Type Description',
    labelTable: 'Descriptions',
    placeholder: placeholderRows(5, 'Enter job type description', ''),
    rows: 5,
  },

  binNumberId: {
    id: 'binNumberId',
    label: 'Bin ID',
    placeholder: '',
  },
  binType: {
    id: 'binType',
    label: 'Bin Type',
    placeholder: '',
    dropdown: 'Can edit bin type',
  },
  wasteType: {
    id: 'wasteType',
    label: 'Waste Type',
    labelFor: 'For Waste Type',
    labelWith: 'With Waste Type',
    placeholder: '',
    dropdown: 'Can edit waste type',
  },
  inputWeight: {
    id: 'inputWeight',
    label: 'Weight',
    placeholder: '',
  },
  reviewWasteType: {
    id: 'reviewWasteType',
    label: 'Review Waste Type',
    placeholder: '',
  },
  photo: {
    id: 'photo',
    label: 'Photo',
    placeholder: '',
  },
  signature: {
    id: 'signature',
    label: 'Signature',
    placeholder: '',
  },
};

export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    isActive: Yup.bool().oneOf([true, false]),
    isBillable: Yup.bool().oneOf([true, false]),

    jobTemplateName: Yup.string().required().label(fields.jobTemplateName.label),
    jobTemplateDescription: Yup.string().label(fields.jobTemplateDescription.label),
    
    templateSteps: Yup.array().of(Yup.object().shape({
      stepName: Yup.string().nullable(),
      stepTitle: Yup.string().nullable(),
      
      stepDescription: Yup.number().when(['binActivity'], ([binActivity]) => {
        if(binActivity === BinActivityEnum.NoActivity){
          return Yup.string().nullable().required().label(fields.stepDescription.label)
        } else {
          return Yup.string().nullable().label(fields.stepDescription.label)
        }
      }),

      emptyBinCountAfterAction: Yup.number().nullable(),
      loadedBinCountAfterAction: Yup.number().nullable(),
      stepOrder: Yup.number().nullable(),

      locationType: Yup.number().nullable(),
      hasDefaultSite: Yup.bool().oneOf([true, false]),
      binActivity: Yup.number().min(0).nonNullable().required().label(fields.binActivity.label),
      
      defaultLocationId: Yup.number().when(['hasDefaultSite', 'locationType'], ([hasDefaultSite, locationType]) => {
        if(getLocationValidation(locationType, hasDefaultSite)){
          return Yup.number().min(0).nonNullable().required().label(fields.defaultLocationId.label)
        } else {
          return Yup.number().nullable().label(fields.defaultLocationId.label)
        }
      }),

      isEditableBinNumber: Yup.bool().oneOf([true, false]),
      isRequireBinNumber: Yup.bool().oneOf([true, false]),
      isRequireBinWeight: Yup.bool().oneOf([true, false]),
      isRequireReviewWasteType: Yup.bool().oneOf([true, false]),
      canDriverChangeBinType: Yup.bool().oneOf([true, false]),
      canDriverChangeWasteType: Yup.bool().oneOf([true, false]),
      mustTakePhoto: Yup.number().nullable(),
      numberofPhotosRequired: Yup.number().nullable(),
      mustTakeSignature: Yup.number().nullable(),
    })).customValidation(stepsValidation),
    
    isRequireBinNumber: Yup.bool().oneOf([true, false]),
    canDriverChangeBinType: Yup.bool().oneOf([true, false]),
    canDriverChangeWasteType: Yup.bool().oneOf([true, false]),
    isEditableBinNumber: Yup.bool().oneOf([true, false]),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null, defaultLocations: Array<any> = []) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    let isActive = ((form.isActive == false) || (form.isActive == true)) ? form.isActive : false;
    let isBillable = ((form.isBillable == false) || (form.isBillable == true)) ? form.isBillable : false;
    
    let jobTemplateName = (form.jobTemplateName && form.jobTemplateName !== '') ? form.jobTemplateName : '';
    let jobTemplateDescription = (form.jobTemplateDescription && form.jobTemplateDescription !== '') ? form.jobTemplateDescription : '';
    
    let jobTemplateType = (form && isNumeric(form.jobTemplateType)) ? form.jobTemplateType : JobTemplateType.Custom;
    let stepType = (form && isNumeric(form.stepType)) ? form.stepType : JobStepType.Custom;
    
    let defaultLocationsArr: Array<StepSiteStruct> = [];
    if(defaultLocations && defaultLocations.length > 0){
      defaultLocationsArr = defaultLocations.map((location: any, l: number) => {
        return {
          defaultLocationId: location.defaultLocationId,
          isDefault: location.isDefault,
          locationName: location.locationName,
          addressField: location.address,
          address: location.address,
          latitude: location.latitude,
          longitude: location.longitude,
        };
      });
    }

    let defaultLocationId = (form && isNumeric(form.defaultLocationId)) ? form.defaultLocationId : null;

    let steps: Array<StepStruct> = [];
    if(form.templateSteps && form.templateSteps.length > 0){
      steps = form.templateSteps.sort((a: any,b: any) => a.stepOrder - b.stepOrder).map((step: any, i: number) => {
        let defaultLocationsArrCloned = _.cloneDeep(defaultLocationsArr);

        if(defaultLocationsArrCloned && defaultLocationsArrCloned.length > 0){
          defaultLocationsArrCloned.forEach((location: any, l: number) => {
            let isDefault = (location.defaultLocationId === defaultLocationId) ? true : false;
            location.isDefault = isDefault;
          });
        }

        let locationType = step.locationType;
        if(jobTemplateType === JobTemplateType.Default){
          if(step.hasCustomerSite === false){
            locationType = LocationEnum.Yard
          }
        }
        
        let isRequireBinNumber = (step.isRequireBinNumberToStart != undefined) ? step.isRequireBinNumberToStart : step.isRequireBinNumber;

        return {
          stepName: step.stepName,
          stepTitle: step.stepTitle,
          stepDescription: step.stepDescription,

          emptyBinCountAfterAction: step.emptyBinCountAfterAction,
          loadedBinCountAfterAction: step.loadedBinCountAfterAction,
          stepOrder: step.stepOrder,
          stepType: stepType,

          locationType: locationType,
          hasDefaultSite: step.hasDefaultSite,
          binActivity: step.binActivity,
          defaultLocationId: step.defaultLocationId,
          defaultLocation: step.defaultLocation,
          
          isEditableBinNumber: step.isEditableBinNumber,
          isRequireBinNumber: isRequireBinNumber,
          isRequireBinWeight: step.isRequireBinWeight,
          isRequireReviewWasteType: step.isRequireReviewWasteType,
          canDriverChangeBinType: step.canDriverChangeBinType,
          canDriverChangeWasteType: step.canDriverChangeWasteType,
          mustTakePhoto: step.mustTakePhoto,
          numberofPhotosRequired: step.numberofPhotosRequired,
          mustTakeSignature: step.mustTakeSignature,

          templateStepSites: defaultLocationsArrCloned,
        }
      });
    }


    data['isActive'] = isActive;
    data['isBillable'] = isBillable;

    data['jobTemplateName'] = jobTemplateName;
    data['jobTemplateDescription'] = jobTemplateDescription;
    
    data['jobTemplateType'] = jobTemplateType;
    data['stepType'] = stepType;
    data['templateSteps'] = steps;
    
    let isRequireBinNumber = (steps && steps.length > 0 && steps[0]) ? steps[0]?.isRequireBinNumber : false;
    data['isRequireBinNumber'] = isRequireBinNumber;

    let canDriverChangeBinType = (steps && steps.length > 0 && steps[0]) ? steps[0]?.canDriverChangeBinType : false;
    data['canDriverChangeBinType'] = canDriverChangeBinType;

    let canDriverChangeWasteType = (steps && steps.length > 0 && steps[0]) ? steps[0]?.canDriverChangeWasteType : false;
    data['canDriverChangeWasteType'] = canDriverChangeWasteType;

    let isEditableBinNumber = (steps && steps.length > 0 && steps[0]) ? steps[0]?.isEditableBinNumber : false;
    data['isEditableBinNumber'] = isEditableBinNumber;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null, isCopy: boolean = false) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    if(id){
      data['jobTemplateId'] = id;
    }

    if(isCopy){
      delete data['jobTemplateId'];
    }
    
    let jobTemplateType = (form && isNumeric(form.jobTemplateType)) ? form.jobTemplateType : JobTemplateType.Custom;
    let stepType = (form && isNumeric(form.stepType)) ? form.stepType : JobStepType.Custom;

    let templateSteps: Array<any> = [];
    if(form.templateSteps && form.templateSteps.length > 0){
      let driverPermisons = prepareDriverPermisons(form, 'templateSteps');
      let isRequireBinNumber = driverPermisons.isRequireBinNumber;
      let canDriverChangeBinType = driverPermisons.canDriverChangeBinType;
      let canDriverChangeWasteType = driverPermisons.canDriverChangeWasteType;
      let isEditableBinNumber = driverPermisons.isEditableBinNumber;

      templateSteps = form.templateSteps.map((step: any, i: number) => {
        let stepName = getStepName(i, form.templateSteps);
        let stepTitle = getBinActivityName(i, step);
        let stepDescription = (step.binActivity === BinActivityEnum.NoActivity) ? step.stepDescription : getStepDescription(i, step);
        let stepOrder = (i+1);

        return {
          stepName: stepName,
          stepTitle: stepTitle,
          stepDescription: stepDescription,

          emptyBinCountAfterAction: 0,
          loadedBinCountAfterAction: 0,
          stepOrder: stepOrder,
          stepType: stepType,

          locationType: step.locationType,
          hasDefaultSite: step.hasDefaultSite,
          binActivity: step.binActivity,
          defaultLocationId: step.defaultLocationId,
          
          isRequireBinWeight: step.isRequireBinWeight,
          isRequireReviewWasteType: step.isRequireReviewWasteType,
          mustTakePhoto: step.mustTakePhoto,
          numberofPhotosRequired: step.numberofPhotosRequired,
          mustTakeSignature: step.mustTakeSignature,
          
          isRequireBinNumberToStart: isRequireBinNumber,
          isRequireBinNumberToEnd: isRequireBinNumber,
          canDriverChangeBinType: canDriverChangeBinType,
          canDriverChangeWasteType: canDriverChangeWasteType,
          isEditableBinNumber: isEditableBinNumber,
        }
      });
    }

    if(templateSteps && templateSteps.length > 0){
      templateSteps.forEach((step: any, i: number) => {
        let binCount = getBinCount(i, step, templateSteps);
        step.emptyBinCountAfterAction = binCount?.emptyBinCountAfterAction;
        step.loadedBinCountAfterAction = binCount?.loadedBinCountAfterAction;
      });
    }


    data['isActive'] = form.isActive;
    data['isBillable'] = form.isBillable;

    data['jobTemplateName'] = form.jobTemplateName;
    data['jobTemplateDescription'] = form.jobTemplateDescription;

    data['jobTemplateType'] = jobTemplateType;
    data['stepType'] = stepType;
    data['templateSteps'] = templateSteps;
  }

  return data;
};

export const prepareDefaultLocations = (values: any = null, defaultLocations: Array<StepSiteStruct> = []): any => {
  let form = _.cloneDeep(values);
  let locations = _.cloneDeep(defaultLocations);
  
  if(form && form.templateSteps && form.templateSteps.length > 0){
    for(let i = 0; i < form.templateSteps.length; i++){
      form.templateSteps[i].templateStepSites = locations;
    }
  }

  return form;
}
export const prepareDriverPermisons = (form: any, field = 'templateSteps'): any => {
  let data = {
    isRequireBinNumber: false,
    canDriverChangeBinType: false,
    canDriverChangeWasteType: false,
    isEditableBinNumber: false,
  }

  let isOthers = form[field].every((x: any) => x.binActivity === BinActivityEnum.NoActivity)
  if(isOthers){
    data.isRequireBinNumber = false;
    data.canDriverChangeBinType = false;
    data.canDriverChangeWasteType = false;
    data.isEditableBinNumber = false;

  } else {
    if(form[field][0].binActivity === BinActivityEnum.LoadWaste) {
      data.isRequireBinNumber = false;
      data.canDriverChangeBinType = false;
      data.canDriverChangeWasteType = form.canDriverChangeWasteType;
      data.isEditableBinNumber = false;

    } else {
      data.isRequireBinNumber = form.isRequireBinNumber;
      data.canDriverChangeBinType = form.canDriverChangeBinType;
      data.canDriverChangeWasteType = form.canDriverChangeWasteType;
      data.isEditableBinNumber = form.isEditableBinNumber;
    }
  }

  return data;
}

export const getId = () => {
  let id = getIntProps();
  return (isNumeric(id) && id > 0) ? id : null;
}
export const isEdit = () => {
  let id = getIntProps();
  return (isNumeric(id) && id > 0) ? true : false;
}

export const getVehicleBinStatus = (currentStepIndex: number, currentStep: any, steps: Array<any>): any => {
  if(currentStep){
    if (currentStep.binActivity === BinActivityEnum.PickEmptyBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Empty',
        info: null //'You can stack empty bins'
      };

    } else if (currentStep.binActivity === BinActivityEnum.PickLoadedBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };

    } else if (currentStep.binActivity === BinActivityEnum.LoadWaste) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.WasteDisposal) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Empty',
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.DropEmptyBin) {
      return {
        vehicleStatus: 'Empty',
        binStatus: null,
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.DropLoadedBin) {
      return {
        vehicleStatus: 'Empty',
        binStatus: null,
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.ExchangeBin) {
      return {
        vehicleStatus: 'Loaded',
        binStatus: 'Loaded',
        info: null
      };
      
    } else if (currentStep.binActivity === BinActivityEnum.NoActivity) {
      if(currentStepIndex === 0){
        return null;

      } else {
        let prevIndex = -1;
        let prevStep = null;
        
        for(let i = (currentStepIndex-1); i >= 0; i--){
          if(steps && steps.length > 0 && steps[i] && steps[i].binActivity !== BinActivityEnum.NoActivity){
            prevIndex = i;
            prevStep = steps[i];
            break;
          }
        }

        return getVehicleBinStatus(prevIndex, prevStep, steps);
      }
      
    } else {
      return null;
    }
  } else {
    return null;
  }
}
export const isLastStepHasEmptyVehicle = (steps: Array<any>) => {
  let isEmpty = true;
  
  if(steps && steps.length > 0 && steps[steps.length-1]){
    let i = (steps.length-1);
    let item = steps[steps.length-1];

    let vehicleBinStatus: any = getVehicleBinStatus(i, item, steps);
    isEmpty = (vehicleBinStatus && vehicleBinStatus.vehicleStatus && vehicleBinStatus.vehicleStatus === 'Loaded') ? false : true;
  
    if(!isEmpty){
      if(steps[0].binActivity === BinActivityEnum.LoadWaste){
        isEmpty = true;
      }
    }
  }

  return isEmpty;
}

export const canLoadWaste = (currentStepIndex: number, currentStep: any, steps: Array<any>): boolean => {
  let firstStep = (steps && steps.length > 0) ? steps[0] : null;
  let firstStepCanLoadWaste = (firstStep && firstStep.binActivity && firstStep.binActivity === BinActivityEnum.LoadWaste) ? true : false;

  return firstStepCanLoadWaste ? false : true;
}
export const getBinActivityCurrentStep1 = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  // let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if(currentStep){
    if(currentStep.locationType === LocationEnum.CustomerSite){
      return {
        PickEmptyBin: false,
        PickLoadedBin: false,
        LoadWaste: true,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.locationType === LocationEnum.Yard){
      return {
        PickEmptyBin: false,
        PickLoadedBin: false,
        LoadWaste: true,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.locationType === LocationEnum.Landfill){
      return {
        PickEmptyBin: true,
        PickLoadedBin: true,
        LoadWaste: true,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getBinActivityFirstStepIsLoadWaste = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  // let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if(currentStep){
    let canWasteDisposal = false;
    let isExistWasteDisposal = steps.some((x: any) => x.binActivity === BinActivityEnum.WasteDisposal);
    if(isExistWasteDisposal){
      let ind = steps.findIndex((x: any) => x.binActivity === BinActivityEnum.WasteDisposal);

      canWasteDisposal = (ind > -1 && ind != currentStepIndex)
    }

    if(currentStep.locationType === LocationEnum.CustomerSite){
      return {
        PickEmptyBin: true,
        PickLoadedBin: true,
        LoadWaste: true,
        WasteDisposal: canWasteDisposal,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.locationType === LocationEnum.Yard){
      return {
        PickEmptyBin: true,
        PickLoadedBin: true,
        LoadWaste: true,
        WasteDisposal: canWasteDisposal,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
      
    } else if(currentStep.locationType === LocationEnum.Landfill){
      return {
        PickEmptyBin: true,
        PickLoadedBin: true,
        LoadWaste: true,
        WasteDisposal: true,
        DropEmptyBin: true,
        DropLoadedBin: true,
        ExchangeBin: true,
        NoActivity: false,
      };
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getBinActivityStepIsShift = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getBinActivityPrevCustomerSite = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.binActivity === BinActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.WasteDisposal) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: false,
      WasteDisposal: true,
      DropEmptyBin: false,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: false,
      ExchangeBin: true,
      NoActivity: true,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.NoActivity) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getBinActivityPrevYard = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.binActivity === BinActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: false,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: canLoadWaste(currentStepIndex, currentStep, steps),
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: !canLoadWaste(currentStepIndex, currentStep, steps),
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.WasteDisposal) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: false,
      WasteDisposal: true,
      DropEmptyBin: false,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: false,
      PickLoadedBin: false,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: false,
      ExchangeBin: true,
      NoActivity: true,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.NoActivity) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: false,
          PickLoadedBin: false,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const getBinActivityPrevLandfill = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  if (prevStep.binActivity === BinActivityEnum.PickEmptyBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.PickLoadedBin) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }

  } else if (prevStep.binActivity === BinActivityEnum.LoadWaste) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: false,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: false,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.WasteDisposal) {
    if(currentStep){
      if(currentStep.locationType === LocationEnum.Yard){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.CustomerSite){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: false,
          WasteDisposal: true,
          DropEmptyBin: false,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };

      } else if(currentStep.locationType === LocationEnum.Landfill){
        return {
          PickEmptyBin: true,
          PickLoadedBin: true,
          LoadWaste: true,
          WasteDisposal: true,
          DropEmptyBin: true,
          DropLoadedBin: true,
          ExchangeBin: true,
          NoActivity: false,
        };
      }
    }
    
  } else if (prevStep.binActivity === BinActivityEnum.DropEmptyBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.DropLoadedBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.ExchangeBin) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
    
  } else if (prevStep.binActivity === BinActivityEnum.NoActivity) {
    return {
      PickEmptyBin: true,
      PickLoadedBin: true,
      LoadWaste: true,
      WasteDisposal: true,
      DropEmptyBin: true,
      DropLoadedBin: true,
      ExchangeBin: true,
      NoActivity: false,
    };
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const disableBinActivityes = (currentStepIndex: number, currentStep: any, steps: Array<any>): BinActivityBoolStruct => {
  let prevIndex = ((currentStepIndex-1) > -1) ? currentStepIndex-1 : -1;
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0 && steps[prevIndex]) ? steps[prevIndex] : null : null;

  if(isShiftType(steps)){
    return getBinActivityStepIsShift(currentStepIndex, currentStep, steps);
  }

  if(steps && steps.length > 0 && steps[0] && steps[0]?.binActivity === BinActivityEnum.LoadWaste){
    if(currentStepIndex === 0){
      return getBinActivityCurrentStep1(currentStepIndex, currentStep, steps);
    } else {
      return getBinActivityFirstStepIsLoadWaste(currentStepIndex, currentStep, steps);
    }
  }

  if(currentStepIndex === 0){
    return getBinActivityCurrentStep1(currentStepIndex, currentStep, steps);

  } else {
    if((prevIndex > 0) && prevStep.binActivity === BinActivityEnum.NoActivity){
      let prevPrevIndex = -1;
      let prevPrevStep = null;

      for(let i = prevIndex; i >= 0; i--){
        if(steps[i].binActivity !== BinActivityEnum.NoActivity){
          prevPrevIndex = i+1;
          prevPrevStep = steps[prevPrevIndex];
          break;
        }
      }

      if(prevPrevStep){
        if(prevPrevStep.locationType === LocationEnum.CustomerSite){
          return getBinActivityPrevCustomerSite(prevPrevIndex, prevPrevStep, steps);
  
        } else if(prevPrevStep.locationType === LocationEnum.Yard){
          return getBinActivityPrevYard(prevPrevIndex, prevPrevStep, steps);
          
        } else if(prevPrevStep.locationType === LocationEnum.Landfill){
          return getBinActivityPrevLandfill(prevPrevIndex, prevPrevStep, steps);
        }
      }
      
    } else {
      if(prevStep){
        if(prevStep.locationType === LocationEnum.CustomerSite){
          return getBinActivityPrevCustomerSite(currentStepIndex, currentStep, steps);
  
        } else if(prevStep.locationType === LocationEnum.Yard){
          return getBinActivityPrevYard(currentStepIndex, currentStep, steps);
          
        } else if(prevStep.locationType === LocationEnum.Landfill){
          return getBinActivityPrevLandfill(currentStepIndex, currentStep, steps);
        }
      }
    }
  }

  return {
    PickEmptyBin: true,
    PickLoadedBin: true,
    LoadWaste: true,
    WasteDisposal: true,
    DropEmptyBin: true,
    DropLoadedBin: true,
    ExchangeBin: true,
    NoActivity: false,
  };
}
export const stepsValidation = (values: any) => {
  let isValid = false;
  let error = '';

  if(values && values.length > 0){
    // check first step
    if(values.length === 1){
      if(values[0]?.binActivity === BinActivityEnum.NoActivity){
        isValid = true;

      } else if(values[0]?.binActivity === BinActivityEnum.LoadWaste){
        isValid = false;
        error = 'You must drop waste';

      } else {
        isValid = false;
        error = 'There must be more than 1 step';
      }

    } else {
      // Escape first step
      for(let i = 1; i < values.length; i++){
        // check if last step
        if(i === (values.length-1)){
          let vehicleBinStatus: any = getVehicleBinStatus(i, values[i], values);

          // if fist step is Pick Waste
          if(values[0]?.binActivity === BinActivityEnum.LoadWaste){
            let isExistWasteDisposal = values.some((x: any) => x.binActivity === BinActivityEnum.WasteDisposal);

            if(!isExistWasteDisposal){
              isValid = false;
              error = 'You must drop waste';
              break;
            }
          }

          // if vehicle status is Loaded in last step
          if(vehicleBinStatus?.vehicleStatus === 'Loaded'){
            if(values[0]?.binActivity != BinActivityEnum.LoadWaste){
              isValid = false;
              error = 'The vehicle must be empty';
              break;
            }
          }
        }

        isValid = true;
      }
    }

  } else {
    isValid = false;
    error = 'There is no steps';
  }

  return {
    isValid: isValid,
    error: error,
  };
}

export const getBinCount = (currentStepIndex: number, currentStep: any, steps: Array<any>): any => {
  let prevStep = (currentStepIndex > 0) ? (steps && steps.length > 0) ? steps[currentStepIndex-1] : null : null;

  let emptyBinCountAfterAction = (prevStep && isNumeric(prevStep.emptyBinCountAfterAction)) ? prevStep.emptyBinCountAfterAction : 0;
	let loadedBinCountAfterAction = (prevStep && isNumeric(prevStep.loadedBinCountAfterAction)) ? prevStep.loadedBinCountAfterAction : 0;

  if(currentStep){
    if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
      emptyBinCountAfterAction = emptyBinCountAfterAction + 1;

    } else if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
      loadedBinCountAfterAction = loadedBinCountAfterAction + 1;

    } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
      if(emptyBinCountAfterAction > 0){
        emptyBinCountAfterAction = emptyBinCountAfterAction - 1;
        loadedBinCountAfterAction = loadedBinCountAfterAction + 1;
      }

    } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
      if(loadedBinCountAfterAction > 0){
        loadedBinCountAfterAction = loadedBinCountAfterAction - 1;
        emptyBinCountAfterAction = emptyBinCountAfterAction + 1;
      }

    } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
      emptyBinCountAfterAction = emptyBinCountAfterAction - 1;

    } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
      loadedBinCountAfterAction = loadedBinCountAfterAction - 1;

    } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
      emptyBinCountAfterAction = emptyBinCountAfterAction - 1;
      loadedBinCountAfterAction = loadedBinCountAfterAction + 1;

    } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
      
    }
  }

  return {
    emptyBinCountAfterAction: emptyBinCountAfterAction,
    loadedBinCountAfterAction: loadedBinCountAfterAction,
  };
}
export const getStepName = (currentStepIndex: number, steps: Array<any>): any => {
  let stepName = '';
  
  if(currentStepIndex === 0){
    stepName = 'Start';
  } else if(currentStepIndex === (steps.length-1)){
    stepName = 'Complete';
  } else {
    stepName = 'In Progress';
  }

  return stepName;
}
export const getLocationTypeName = (currentStepIndex: number, currentStep: any): any => {
  let location: any = '';

  if(currentStep.locationType === LocationEnum.CustomerSite){
    location = 'Customer site'
  } else {
    location = 'Default location'
  }

  return location;
}
export const getBinActivityName = (currentStepIndex: number, currentStep: any): any => {
  let activity: any = '';

  if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
    activity = 'Pick Loaded Bin'
  } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
    activity = 'Drop Loaded Bin'
  } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
    activity = 'Exchange Bin'
  } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
    activity = 'Others'
  } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
    activity = 'Drop Waste'
  } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
    activity = 'Pick Waste'
  } else if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
    activity = 'Pick Empty Bin'
  } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
    activity = 'Drop Empty Bin'
  }

  return activity;
}
export const getBlockStepInfo = (jobSteps: any, jobBinTypes: Array<any>, isEdit: boolean = false): any => {
  let blocks: any = null;

  // DROP | SHIFT
  if(
    (jobSteps.length === 2) && 
    (jobSteps[0].binActivity === BinActivityEnum.PickEmptyBin) && 
    (jobSteps[1].binActivity === BinActivityEnum.DropEmptyBin)
  ){
    let type = (
      (jobSteps[0]?.locationType === LocationEnum.CustomerSite) && 
      (jobSteps[1]?.locationType === LocationEnum.CustomerSite)
    ) ? LocalJobType.SHIFT : LocalJobType.DROP;

    let hasCustomerSite = (
      jobSteps[0]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[1]?.locationType === LocationEnum.CustomerSite
    ) ? true : false;
    
    if(type === LocalJobType.DROP){
      // DROP
      
      let customerSiteObj: any = customerSiteInit;
      if(isEdit && hasCustomerSite){
        if(jobSteps[0]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[0] };
          customerSiteObj['address'] = jobSteps[0]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[0]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[0]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[0]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[0]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[0]?.siteRemarks;

        } else if(jobSteps[1]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[1] };
          customerSiteObj['address'] = jobSteps[1]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[1]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[1]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[1]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[1]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[1]?.siteRemarks;
        }
      }
  
      blocks = {
        type: LocalJobType.DROP,
        blocks: [
          {
            type: LocalBinActivity.PICK_DROP_EMPTY,
            isForWith: ForWith.FOR,
            title: PickDropEmptyTitle,
            steps: [jobSteps[0], jobSteps[1]],
            bins: getBinsFromBlock(jobBinTypes, [jobSteps[0], jobSteps[1]], LocalJobType.DROP),
          },
        ],
        customerSites: hasCustomerSite ? [customerSiteObj] : [],
      }

    } else {
      // SHIFT

      let customerSiteObj1: any = customerSiteInit;
      let customerSiteObj2: any = customerSiteInit;
      if(isEdit && hasCustomerSite){
        if(jobSteps[0]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj1 = { ...jobSteps[0] };
          customerSiteObj1['address'] = jobSteps[0]?.address;
          customerSiteObj1['contactPersonName'] = jobSteps[0]?.contactPersonOne;
          customerSiteObj1['contactPersonPhoneNumber'] = jobSteps[0]?.contactNumberOne;
          customerSiteObj1['contactPersonNameTwo'] = jobSteps[0]?.contactPersonTwo;
          customerSiteObj1['contactPersonPhoneNumberTwo'] = jobSteps[0]?.contactNumberTwo;
          customerSiteObj1['instructions'] = jobSteps[0]?.siteRemarks;
        }
        
        if(jobSteps[1]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj2 = { ...jobSteps[1] };
          customerSiteObj2['address'] = jobSteps[1]?.address;
          customerSiteObj2['contactPersonName'] = jobSteps[1]?.contactPersonOne;
          customerSiteObj2['contactPersonPhoneNumber'] = jobSteps[1]?.contactNumberOne;
          customerSiteObj2['contactPersonNameTwo'] = jobSteps[1]?.contactPersonTwo;
          customerSiteObj2['contactPersonPhoneNumberTwo'] = jobSteps[1]?.contactNumberTwo;
          customerSiteObj2['instructions'] = jobSteps[1]?.siteRemarks;
        }
      }

      blocks = {
        type: LocalJobType.SHIFT,
        blocks: [
          {
            type: LocalBinActivity.PICK_DROP_EMPTY,
            isForWith: ForWith.FOR,
            title: PickDropEmptyTitle,
            steps: [jobSteps[0], jobSteps[1]],
            bins: getBinsFromBlock(jobBinTypes, [jobSteps[0], jobSteps[1]], LocalJobType.SHIFT),
          },
        ],
        customerSites: hasCustomerSite ? [customerSiteObj1, customerSiteObj2] : [],
      }
    }
  }
  
  // PICK
  else if(
    (jobSteps.length === 2) && 
    (jobSteps[0].binActivity === BinActivityEnum.PickLoadedBin) && 
    (jobSteps[1].binActivity === BinActivityEnum.DropLoadedBin)
  ){
    let hasCustomerSite = (
      jobSteps[0]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[1]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[2]?.locationType === LocationEnum.CustomerSite
    ) ? true : false;

    let customerSiteObj: any = customerSiteInit;
    if(isEdit && hasCustomerSite){
      if(jobSteps[0]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[0] };
        customerSiteObj['address'] = jobSteps[0]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[0]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[0]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[0]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[0]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[0]?.siteRemarks;

      } else if(jobSteps[1]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[1] };
        customerSiteObj['address'] = jobSteps[1]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[1]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[1]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[1]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[1]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[1]?.siteRemarks;

      } else if(jobSteps[2]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[2] };
        customerSiteObj['address'] = jobSteps[2]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[2]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[2]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[2]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[2]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[2]?.siteRemarks;
      }
    }

    blocks = {
      type: LocalJobType.PICK,
      blocks: [
        {
          type: LocalBinActivity.PICK_DROP_LOADED,
          isForWith: ForWith.WITH,
          title: PickDropLoadedTitle,
          steps: [jobSteps[0], jobSteps[1]],
          bins: getBinsFromBlock(jobBinTypes, [jobSteps[0], jobSteps[1]], LocalJobType.PICK),
        },
      ],
      customerSites: hasCustomerSite ? [customerSiteObj] : [],
    }
  }
  
  // EXCHANGE 2 | DISPOSAL
  else if(
    (jobSteps.length === 3) && 
    (jobSteps[0].binActivity === BinActivityEnum.PickLoadedBin) && 
    (jobSteps[1].binActivity === BinActivityEnum.WasteDisposal) && 
    (jobSteps[2].binActivity === BinActivityEnum.DropEmptyBin)
  ){
    let hasCustomerSite = (
      jobSteps[0]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[1]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[2]?.locationType === LocationEnum.CustomerSite
    ) ? true : false;

    if(hasCustomerSite){
      // EXHANGE2

      let customerSiteObj: any = customerSiteInit;
      if(isEdit && hasCustomerSite){
        if(jobSteps[0]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[0] };
          customerSiteObj['address'] = jobSteps[0]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[0]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[0]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[0]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[0]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[0]?.siteRemarks;
  
        } else if(jobSteps[1]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[1] };
          customerSiteObj['address'] = jobSteps[1]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[1]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[1]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[1]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[1]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[1]?.siteRemarks;
  
        } else if(jobSteps[2]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[2] };
          customerSiteObj['address'] = jobSteps[2]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[2]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[2]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[2]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[2]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[2]?.siteRemarks;
        }
      }

      blocks = {
        type: LocalJobType.EXCHANGE2,
        blocks: [
          {
            type: LocalBinActivity.PICK_LOADED_DROP_WASTE,
            isForWith: ForWith.WITH,
            title: 'Pick Loaded Bin, Drop Waste',
            steps: [jobSteps[0], jobSteps[1]],
            bins: getBinsFromBlock(jobBinTypes, [jobSteps[0], jobSteps[1]], LocalJobType.EXCHANGE2),
          },
          {
            type: LocalBinActivity.DROP_EMPTY,
            isForWith: ForWith.FOR,
            title: 'Drop Empty Bin',
            steps: [jobSteps[2]],
            bins: null,
          },
        ],
        customerSites: hasCustomerSite ? [customerSiteObj] : [],
      }

    } else {
      // DISPOSAL

      let customerSiteObj: any = customerSiteInit;
      if(isEdit && hasCustomerSite){
        if(jobSteps[0]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[0] };
          customerSiteObj['address'] = jobSteps[0]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[0]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[0]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[0]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[0]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[0]?.siteRemarks;
  
        } else if(jobSteps[1]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[1] };
          customerSiteObj['address'] = jobSteps[1]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[1]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[1]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[1]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[1]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[1]?.siteRemarks;
  
        } else if(jobSteps[2]?.locationType === LocationEnum.CustomerSite){
          customerSiteObj = { ...jobSteps[2] };
          customerSiteObj['address'] = jobSteps[2]?.address;
          customerSiteObj['contactPersonName'] = jobSteps[2]?.contactPersonOne;
          customerSiteObj['contactPersonPhoneNumber'] = jobSteps[2]?.contactNumberOne;
          customerSiteObj['contactPersonNameTwo'] = jobSteps[2]?.contactPersonTwo;
          customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[2]?.contactNumberTwo;
          customerSiteObj['instructions'] = jobSteps[2]?.siteRemarks;
        }
      }

      blocks = {
        type: LocalJobType.DISPOSAL,
        blocks: [
          {
            type: LocalBinActivity.PICK_LOADED_DROP_WASTE,
            isForWith: ForWith.WITH,
            title: 'Pick Loaded Bin, Drop Waste',
            steps: [jobSteps[0], jobSteps[1]],
            bins: getBinsFromBlock(jobBinTypes, [jobSteps[0], jobSteps[1]], LocalJobType.DISPOSAL),
          },
          {
            type: LocalBinActivity.DROP_EMPTY,
            isForWith: ForWith.FOR,
            title: 'Drop Empty Bin',
            steps: [jobSteps[2]],
            bins: null,
          },
        ],
        customerSites: hasCustomerSite ? [customerSiteObj] : [],
      }
    }
  }

  // ON THE SPOT
  else if(
    (jobSteps.length === 3) && 
    (jobSteps[0].binActivity === BinActivityEnum.PickEmptyBin) && 
    (jobSteps[1].binActivity === BinActivityEnum.LoadWaste) && 
    (jobSteps[2].binActivity === BinActivityEnum.DropLoadedBin)
  ){
    let hasCustomerSite = (
      jobSteps[0]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[1]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[2]?.locationType === LocationEnum.CustomerSite
    ) ? true : false;

    let customerSiteObj: any = customerSiteInit;
    if(isEdit && hasCustomerSite){
      if(jobSteps[0]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[0] };
        customerSiteObj['address'] = jobSteps[0]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[0]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[0]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[0]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[0]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[0]?.siteRemarks;

      } else if(jobSteps[1]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[1] };
        customerSiteObj['address'] = jobSteps[1]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[1]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[1]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[1]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[1]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[1]?.siteRemarks;

      } else if(jobSteps[2]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[2] };
        customerSiteObj['address'] = jobSteps[2]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[2]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[2]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[2]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[2]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[2]?.siteRemarks;
      }
    }

    blocks = {
      type: LocalJobType.ON_THE_SPOT,
      blocks: [
        {
          type: LocalBinActivity.PICK_EMPTY,
          isForWith: ForWith.FOR,
          title: 'Pick Empty Bin',
          steps: [jobSteps[0]],
          bins: getBinsFromBlock(jobBinTypes, [jobSteps[0]], LocalJobType.ON_THE_SPOT),
        },
        {
          type: LocalBinActivity.PICK_WASTE_DROP_LOADED,
          isForWith: ForWith.WITH,
          title: 'Pick Waste, Drop Loaded Bin',
          steps: [jobSteps[1], jobSteps[2]],
          bins: null,
        },
      ],
      customerSites: hasCustomerSite ? [customerSiteObj] : [],
    }
  }

  // TIPPER
  else if(
    (jobSteps.length === 2) && 
    (jobSteps[0].binActivity === BinActivityEnum.LoadWaste) && 
    (jobSteps[1].binActivity === BinActivityEnum.WasteDisposal)
  ){
    let hasCustomerSite = (
      jobSteps[0]?.locationType === LocationEnum.CustomerSite || 
      jobSteps[1]?.locationType === LocationEnum.CustomerSite
    ) ? true : false;

    let customerSiteObj: any = customerSiteInit;
    if(isEdit && hasCustomerSite){
      if(jobSteps[0]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[0] };
        customerSiteObj['address'] = jobSteps[0]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[0]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[0]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[0]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[0]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[0]?.siteRemarks;

      } else if(jobSteps[1]?.locationType === LocationEnum.CustomerSite){
        customerSiteObj = { ...jobSteps[1] };
        customerSiteObj['address'] = jobSteps[1]?.address;
        customerSiteObj['contactPersonName'] = jobSteps[1]?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = jobSteps[1]?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = jobSteps[1]?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = jobSteps[1]?.contactNumberTwo;
        customerSiteObj['instructions'] = jobSteps[1]?.siteRemarks;
      }
    }

    blocks = {
      type: LocalJobType.TIPPER,
      blocks: [
        {
          type: LocalBinActivity.PICK_WASTE,
          isForWith: ForWith.FOR,
          title: 'Pick Waste',
          steps: [jobSteps[0]],
          bins: getBinsFromBlock(jobBinTypes, [jobSteps[0]], LocalJobType.TIPPER),
        },
        {
          type: LocalBinActivity.DROP_WASTE,
          isForWith: ForWith.WITH,
          title: 'Drop Waste',
          steps: [jobSteps[1]],
          bins: null,
        },
      ],
      customerSites: hasCustomerSite ? [customerSiteObj] : [],
    }
  }

  return blocks;
}
export const fixBlockStepInfoForTask = (groups: Array<any>, steps: Array<any>, isEdit: boolean = false): Array<any> => {
  if(groups && groups.length === 1 && (groups[0] === null || groups[0] === undefined)){
    let hasCustomerSite = steps.some((x: any) => x?.locationType === LocationEnum.CustomerSite);

    let customerSiteObj: any = customerSiteInit;
    if(isEdit && hasCustomerSite){
      let indexCustomerSite = steps.findIndex((x: any) => x?.locationType === LocationEnum.CustomerSite);
      if(indexCustomerSite > -1){
        let stepObj: any = steps[indexCustomerSite];

        customerSiteObj = { ...stepObj };
        customerSiteObj['address'] = stepObj?.address;
        customerSiteObj['contactPersonName'] = stepObj?.contactPersonOne;
        customerSiteObj['contactPersonPhoneNumber'] = stepObj?.contactNumberOne;
        customerSiteObj['contactPersonNameTwo'] = stepObj?.contactPersonTwo;
        customerSiteObj['contactPersonPhoneNumberTwo'] = stepObj?.contactNumberTwo;
        customerSiteObj['instructions'] = stepObj?.siteRemarks;
      }
    }

    groups[0] = {
      type: LocalJobType.TASK,
      blocks: [
        {
          type: null,
          isForWith: null,
          title: '',
          steps: steps,
          bins: [],
        }
      ],
      customerSites: [customerSiteObj],
    }
  }
  
  return groups
}
export const fixBlockStepInfoForExchange = (groups: Array<any>, steps: Array<any>, isEdit: boolean = false): Array<any> => {
  let newGroups: Array<any> = [];
  
  if(groups && groups.length > 0){
    for(let i = 0; i < groups.length; i++){
      let group = groups[i];
      
      if(group && group.blocks && group.blocks.length === 1 && group.blocks[0].type == LocalBinActivity.PICK_DROP_LOADED){
        let prevGroupIndex = (i-1);
        let prevGroup = (i > 0) ? groups[prevGroupIndex] : null;

        if(prevGroup && prevGroup.blocks && prevGroup.blocks.length === 1 && prevGroup.blocks[0].type == LocalBinActivity.PICK_DROP_EMPTY){
          let hasCustomerSite1 = (
            prevGroup.blocks[0].steps[0]?.locationType === LocationEnum.CustomerSite || 
            prevGroup.blocks[0].steps[1]?.locationType === LocationEnum.CustomerSite
          ) ? true : false;

          let hasCustomerSite2 = (
            group.blocks[0].steps[0]?.locationType === LocationEnum.CustomerSite || 
            group.blocks[0].steps[1]?.locationType === LocationEnum.CustomerSite
          ) ? true : false;

          let customerSiteObj: any = customerSiteInit;
          if(isEdit && (hasCustomerSite1 || hasCustomerSite2)){
            if(group.blocks[0].steps[0]?.locationType === LocationEnum.CustomerSite){
              let stepObj: any = steps.find((x: any) => x.stepOrder === group.blocks[0].steps[0]?.stepOrder);

              customerSiteObj = { ...stepObj };
              customerSiteObj['address'] = stepObj?.address;
              customerSiteObj['contactPersonName'] = stepObj?.contactPersonOne;
              customerSiteObj['contactPersonPhoneNumber'] = stepObj?.contactNumberOne;
              customerSiteObj['contactPersonNameTwo'] = stepObj?.contactPersonTwo;
              customerSiteObj['contactPersonPhoneNumberTwo'] = stepObj?.contactNumberTwo;
              customerSiteObj['instructions'] = stepObj?.siteRemarks;

            } else if(group.blocks[0].steps[1]?.locationType === LocationEnum.CustomerSite){
              let stepObj: any = steps.find((x: any) => x.stepOrder === group.blocks[0].steps[1]?.stepOrder);

              customerSiteObj = { ...stepObj };
              customerSiteObj['address'] = stepObj?.address;
              customerSiteObj['contactPersonName'] = stepObj?.contactPersonOne;
              customerSiteObj['contactPersonPhoneNumber'] = stepObj?.contactNumberOne;
              customerSiteObj['contactPersonNameTwo'] = stepObj?.contactPersonTwo;
              customerSiteObj['contactPersonPhoneNumberTwo'] = stepObj?.contactNumberTwo;
              customerSiteObj['instructions'] = stepObj?.siteRemarks;
            }
          }
          
          let newGroup = {
            type: LocalJobType.EXCHANGE1,
            blocks: [
              prevGroup.blocks[0],
              group.blocks[0],
            ],
            customerSites: (hasCustomerSite1 || hasCustomerSite2) ? [customerSiteObj] : [],
          }

          newGroups[(newGroups.length-1)] = newGroup
        } else {
          newGroups.push(group)
        }
      } else {
        newGroups.push(group)
      }
    }
  }

  return newGroups;
}
export const getBinsFromBlock = (jobBinTypes: Array<any>, blockSteps: Array<any>, localJobType: number): Array<any> => {
  let blockStep = (blockSteps && blockSteps.length > 0) ? blockSteps[0] : null;
  let jobStepBinTypes = (blockStep && blockStep.jobStepBinTypes && blockStep.jobStepBinTypes.length > 0) ? blockStep.jobStepBinTypes : [];
  let binTypeOrders = (jobStepBinTypes && jobStepBinTypes.length > 0) ? jobStepBinTypes.map((x: any) => x?.binTypeOrder) : [];

  if(localJobType === LocalJobType.TIPPER){
    return [jobBinTypesInit]
  } else {
    if(isCopyJob()){
      return (binTypeOrders && binTypeOrders.length > 0 && (jobBinTypes && jobBinTypes.length > 0)) ? jobBinTypes.filter((x: any) => binTypeOrders.includes(x.order)).map((y: any) => {
        return {
          ...y,
          binNumberId: null,
          binNumber: '',
          binOutWeight: null,
          binWeight: null,
          tareBinWeight: null,
          netBinWeight: null,
          nonBillableBinWeight: null,
          billableBinWeight: null,
          weightChitTicketNumber: null,
          weightChitRemarks: null,
          binWeighBy: null,
          weightChitDateTime: null,
          weightChitOutDateTime: null,
        }
      }) : [];
    } else {
      return (binTypeOrders && binTypeOrders.length > 0 && (jobBinTypes && jobBinTypes.length > 0)) ? jobBinTypes.filter((x: any) => binTypeOrders.includes(x.order)) : [];
    }
  }
}
export const isForWithItem = (item: any) => {
  if(
    (item?.binActivity === BinActivityEnum.PickEmptyBin) ||
    (item?.binActivity === BinActivityEnum.DropEmptyBin) ||
    (item?.binActivity === BinActivityEnum.LoadWaste)
  ){
    return ForWith.FOR;
  } else if(
    (item?.binActivity === BinActivityEnum.PickLoadedBin) ||
    (item?.binActivity === BinActivityEnum.DropLoadedBin) ||
    (item?.binActivity === BinActivityEnum.WasteDisposal)
  ){
    return ForWith.WITH;
  } else {
    return null
  }
}
export const isShiftType = (steps: Array<any>): boolean => {
  let isShift = false;

  if(steps && steps.length > 1){
    isShift = steps.some((el: any, i: any, arr: any) =>
      el.binActivity === BinActivityEnum.PickEmptyBin && el.locationType === LocationEnum.CustomerSite &&
      arr[i + 1] &&
      arr[i + 1].binActivity === BinActivityEnum.DropEmptyBin && arr[i + 1].locationType === LocationEnum.CustomerSite
    );
  }

  return isShift;
}
export const isTaskType = (steps: Array<any>): boolean => {
  return (steps && steps.length > 1 && steps[0] && steps[0]?.binActivity === BinActivityEnum.NoActivity) ? true : false;
}
export const isExchange1Type = (stepGroups: any = []): boolean => {
  let isExchange1 = false;

  if(stepGroups && stepGroups.length > 0){
    isExchange1 = stepGroups.some((el: any) => el.type === LocalJobType.EXCHANGE1);
  }

  return isExchange1;
}
export const isDisposalType = (stepGroups: any = []): boolean => {
  let isDisposal = false;

  if(stepGroups && stepGroups.length > 0){
    isDisposal = stepGroups.some((el: any) => el.type === LocalJobType.DISPOSAL);
  }

  return isDisposal;
}
export const isCopyJob = () => {
  return getBoolProps();
}

export const getStepDescription = (currentStepIndex: number, currentStep: any): any => {
  let location: any = getLocationTypeName(currentStepIndex, currentStep);
  let activity: any = getBinActivityName(currentStepIndex, currentStep);
  
  if(activity != '' && location != ''){
    return [activity, 'at', location].join(' ');
  } else if(activity != ''){
    return activity;
  } else if(location != ''){
    return location;
  } else {
    return '';
  }
}
export const getDriverAppOptionsStepInfo = (currentStepIndex: number, currentStep: any, steps: Array<any>): DriverAppOptionsBoolStruct => {
  let backData: DriverAppOptionsBoolStruct = {
    RequiredBinID: false,
    TakePhoto: false,
    Signature: false,
    InputBinWeight: false,
    ReviewWasteType: false,
    ChangeBin: false,
    ChangeWaste: false,
    NewBinID: false,
  }

  if(currentStep){
    if(currentStep.locationType === LocationEnum.Yard){
      if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
        let nextStepObj: any = getNextStep(currentStepIndex, currentStep, steps);

        let InputBinWeight = (nextStepObj && nextStepObj.step && (nextStepObj.step?.binActivity == BinActivityEnum.DropLoadedBin)) ? false : true;

        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.InputBinWeight = InputBinWeight;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
        let prevStepObj: any = getPrevStep(currentStepIndex, currentStep, steps);
        let nextStepObj: any = getNextStep(currentStepIndex, currentStep, steps);

        let prevStep = (prevStepObj && prevStepObj.step) ? prevStepObj.step : null; 
        let nextStep = (nextStepObj && nextStepObj.step) ? nextStepObj.step : null; 

        let ReviewWasteType = (
          (
            (prevStep?.binActivity == BinActivityEnum.PickLoadedBin) && 
            (prevStep?.locationType != LocationEnum.CustomerSite)
          ) && 
          (
            (nextStep?.binActivity == BinActivityEnum.DropEmptyBin) &&
            (nextStep?.locationType != LocationEnum.CustomerSite)
          )
        ) ? false : true;

        backData.InputBinWeight = true;
        backData.ReviewWasteType = ReviewWasteType;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
        backData.InputBinWeight = true;
        backData.ReviewWasteType = true;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;

      } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
        backData.TakePhoto = true;
      }

    } else if(currentStep.locationType === LocationEnum.CustomerSite){
      if(currentStep.binActivity === BinActivityEnum.PickEmptyBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.PickLoadedBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.LoadWaste){
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
        let nextStepObj: any = getNextStep(currentStepIndex, currentStep, steps);

        let ReviewWasteType = (nextStepObj && nextStepObj.step && (nextStepObj.step?.binActivity == BinActivityEnum.DropEmptyBin)) ? false : true;

        backData.InputBinWeight = true;
        backData.ReviewWasteType = ReviewWasteType;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropEmptyBin){
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.DropLoadedBin){
        backData.InputBinWeight = true;
        backData.ReviewWasteType = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.ExchangeBin){
        backData.ChangeBin = true;
        backData.ChangeWaste = true;
        backData.NewBinID = true;
        backData.RequiredBinID = true;
        backData.TakePhoto = true;
        backData.Signature = true;

      } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
        backData.TakePhoto = true;
        backData.Signature = true;
      }

    } else if(currentStep.locationType === LocationEnum.Landfill){
      if(currentStep.binActivity === BinActivityEnum.WasteDisposal){
        backData.InputBinWeight = true;
        backData.TakePhoto = true;

      } else if(currentStep.binActivity === BinActivityEnum.NoActivity){
        backData.TakePhoto = true;
      }

    }
  }

  return backData;
}
export const getStepInfo = (stepGroups: any = [], defaultLocations: Array<any> = [], canEdit: boolean = true, onClickEdit: (stepObj: any) => void): any => {
  let stepsArray: Array<any> = [];
  let stepCount = 0;

  let arr = getStepsFromStepGroups(stepGroups);
  if(arr && arr.length > 0){
    arr.forEach((stepObj: any, stepIndex: number) => {
      let location = '';
      if(stepObj.locationType === LocationEnum.CustomerSite){
        let customerSite = (stepObj.customerSite) ? stepObj.customerSite : null;
        let site = (stepObj.site) ? stepObj.site : null;

        let customerSiteName = (customerSite && customerSite.customerSiteName && customerSite.customerSiteName != '') ? customerSite.customerSiteName : 
        (site && site.customerSiteName && site.customerSiteName != '') ? site.customerSiteName : '';
        let siteName = (customerSite && customerSite.siteName && customerSite.siteName != '') ? customerSite.siteName : 
        (site && site.siteName && site.siteName != '') ? site.siteName : '';
        
        location = (customerSiteName != '') ? customerSiteName : siteName;
      } else {
        if(stepObj.hasDefaultSite){
          let defLocation = (defaultLocations && defaultLocations.length > 0) ? defaultLocations.find((x: any) => x.defaultLocationId == stepObj.defaultLocationId) : null;
          location = (defLocation && defLocation.locationName && defLocation.locationName != '') ? defLocation.locationName : '';
        } else {
          location = (stepObj.address && stepObj.address != '') ? stepObj.address : '';
        }
      }

      let activity: any = getBinActivityName(stepCount, stepObj);
      if(stepObj.isExchange1 && stepIndex === 1){
        activity = 'Drop Empty Bin, Pick Loaded Bin';
      }

      stepsArray.push({
        title: <Row>
          <Col xs={12} className={'location ' + ((location && location != '') ? '' : 'text-danger')}>{(location && location != '') ? location : 'Choose location'}</Col>
          <Col xs={12}>{activity}</Col>
          {(canEdit && (stepObj.locationType != LocationEnum.CustomerSite)) && <Col xs={12}>
            <a href={'/'}
              className='hover blue-500 fs-12'
              onClick={(e: any) => {
                e.stopPropagation();
                e.preventDefault();

                if(onClickEdit){
                  onClickEdit(stepObj?.dataObj);
                }
              }}
            >Edit</a>
          </Col>}
        </Row>,
        icon: <span>{(stepCount+1)}</span>
      });

      stepCount = stepCount + 1;
    });
  }

  return stepsArray;
}
export const getStepsFromStepGroups = (stepGroups: Array<any> = []) => {
  let mainArr: Array<any> = [];
  let stepCount = 0;

  if(stepGroups && stepGroups.length > 0){
    stepGroups.forEach((group: any, g: number) => {
      let customerSite1: any = null;
      let customerSite2: any = null;
      if(group && group.customerSites && group.customerSites.length > 0){
        if(group.customerSites.length === 1){
          customerSite1 = group.customerSites[0];
        } else if (group.customerSites.length === 2){
          customerSite1 = group.customerSites[0];
          customerSite2 = group.customerSites[1];
        }
      }

      let isShift = (customerSite1 && customerSite2) ? true : false;
      let isExchange1 = (group.type === LocalJobType.EXCHANGE1) ? true : false;
      
      let arr: Array<any> = [];

      /* BLOCKS */
      if(group && group.blocks && group.blocks.length > 0){

        if(isExchange1){
          let block1 = group.blocks[0];
          let block2 = group.blocks[1];

          let block1LastStep = null;
          if(block1 && block1.steps && block1.steps.length > 0){
            block1LastStep = block1.steps[block1.steps.length-1]
          }

          let block2LastStep = null;
          if(block2 && block2.steps && block2.steps.length > 0){
            block2LastStep = block2.steps[0]
          }

          // arr.push(block1?.steps[0]);
          arr.push({
            group: {
              index: g,
              data: group,
            },
            block: {
              index: 0,
              data: block1,
            },
            step: {
              index: 0,
              data: block1?.steps[0],
            }
          });
          if((block1LastStep?.locationType === LocationEnum.CustomerSite) && (block2LastStep?.locationType === LocationEnum.CustomerSite)){
            // arr.push(block1?.steps[1]);
            arr.push({
              group: {
                index: g,
                data: group,
              },
              block: {
                index: 0,
                data: block1,
              },
              step: {
                index: 1,
                data: block1?.steps[1],
              }
            });
          } else {
            // arr.push(block1?.steps[1]);
            arr.push({
              group: {
                index: g,
                data: group,
              },
              block: {
                index: 0,
                data: block1,
              },
              step: {
                index: 1,
                data: block1?.steps[1],
              }
            });

            // arr.push(block2?.steps[0]);
            arr.push({
              group: {
                index: g,
                data: group,
              },
              block: {
                index: 1,
                data: block2,
              },
              step: {
                index: 0,
                data: block2?.steps[0],
              }
            });
          }
          // arr.push(block2?.steps[1]);
          arr.push({
            group: {
              index: g,
              data: group,
            },
            block: {
              index: 1,
              data: block2,
            },
            step: {
              index: 1,
              data: block2?.steps[1],
            }
          });

        } else {
          group.blocks.forEach((block: any, b: number) => {
  
            /* STEPS */
            if(block && block.steps && block.steps.length > 0){
              block.steps.forEach((stepObj: any, stepIndex: number) => {
                // arr.push(stepObj);
                arr.push({
                  group: {
                    index: g,
                    data: group,
                  },
                  block: {
                    index: b,
                    data: block,
                  },
                  step: {
                    index: stepIndex,
                    data: stepObj,
                  }
                });
              })
              
            }
            /* END STEPS */
  
          })
        }

        if(arr && arr.length > 0){
          arr.forEach((obj: any, stepIndex: number) => {
            let stepObj = _.cloneDeep(obj?.step?.data);

            let customerSiteObj = null;
            if(isShift){
              customerSiteObj = (stepIndex == 0) ? customerSite1 : customerSite2;
            } else {
              customerSiteObj = customerSite1;
            }

            stepObj.stepCount = stepCount;
            stepObj.isShift = isShift;
            stepObj.isExchange1 = isExchange1;
            stepObj.customerSite = customerSiteObj;
            stepObj.dataObj = obj;

            stepCount = stepCount + 1;

            mainArr.push(stepObj);
          });
        }

      }
      /* END BLOCKS */

    });
  }

  return mainArr;
}
export const getBinActivityDropdown = (callback: any, isDisabled = false, isDefault = false): any => {
  const arr = Object.entries(BinActivityEnum).map(([name, val]) => {
    let lbl = getBinActivityName(-1, { binActivity: val });

    let itm = {
      value: val,
      label: lbl,
      name: name,
    };
    
    return {
      ...itm,
      disabled: callback ? callback(itm) : false,
    }
  });

  if(isDefault){
    return arr;
  } else {
    return !isDisabled ? arr.filter((x: any) => x.disabled === false).filter((x: any) => x.value != BinActivityEnum.ExchangeBin) : arr.filter((x: any) => x.value != BinActivityEnum.ExchangeBin);
  }
}
export const getLocationTypeDropdown = (currentStepIndex: number, currentStep: any, steps: Array<any>, isDefault = false): any => {
  let arr: Array<any> = [];

  let customerLocationItem = { 
    value: DefaultLocationEnum.CustomerSiteLocation,
    label: getLocationName(LocationEnum.CustomerSite, false),
    disabled: isDefault,
  };
  let defaultLocationItem = { 
    value: DefaultLocationEnum.DefaultLocation,
    label: getLocationName(LocationEnum.Yard, true),
    disabled: isDefault,
  };
  let noDefaultLocationItem = {
    value: DefaultLocationEnum.NoDefaultLocation,
    label: getLocationName(LocationEnum.Yard, false),
    disabled: isDefault,
  };

  arr.push(customerLocationItem);
  arr.push(defaultLocationItem);
  arr.push(noDefaultLocationItem);

  return arr;
}
export const getPrevStep = (currentStepIndex: number, currentStep: any, steps: Array<any>) => {
  let step = {
    index: -1,
    step: null,
  };

  let index = (currentStepIndex-1);
  let stepsLength = (steps && steps.length > 0) ? steps.length : 0;
  if((stepsLength > 0) && ((index > -1) && (index < stepsLength))){
    step.index = index;
    step.step = steps[index];
  }

  return step;
}
export const getNextStep = (currentStepIndex: number, currentStep: any, steps: Array<any>) => {
  let step = {
    index: -1,
    step: null,
  };

  let index = (currentStepIndex+1);
  let stepsLength = (steps && steps.length > 0) ? steps.length : 0;
  if((stepsLength > 0) && ((index > -1) && (index < stepsLength))){
    step.index = index;
    step.step = steps[index];
  }

  return step;
}
export const isMultiStep = (prevStep: any, currentStep: any, nextStep: any) => {
  let isMulti = false;
  let canRender = false;

  if(currentStep?.index > -1 && currentStep?.step != null){
    // CHECK BY PREV STEP
    if(prevStep?.index > -1 && prevStep?.step != null){
      if(
        (currentStep?.step?.binActivity === BinActivityEnum.DropEmptyBin) && 
        (prevStep?.step?.binActivity === BinActivityEnum.PickEmptyBin)
      ){
        // PICK & DROP EMPTY

        isMulti = true;
        canRender = true;
      } else if(
        (currentStep?.step?.binActivity === BinActivityEnum.DropLoadedBin) && 
        (prevStep?.step?.binActivity === BinActivityEnum.PickLoadedBin)
      ){
        // PICK & DROP LOADED

        isMulti = true;
        canRender = true;
      } else if(
        (currentStep?.step?.binActivity === BinActivityEnum.DropEmptyBin) && 
        (prevStep?.step?.binActivity === BinActivityEnum.WasteDisposal)
      ){
        // DROP WASTE & DROP EMPTY

        isMulti = true;
        canRender = true;
      } else if(
        (currentStep?.step?.binActivity === BinActivityEnum.WasteDisposal) && 
        (prevStep?.step?.binActivity === BinActivityEnum.LoadWaste)
      ){
        // PICK WASTE & DROP WASTE

        isMulti = true;
        canRender = true;
      }
    }

    // CHECK BY NEXT STEP
    if(nextStep?.index > -1 && nextStep?.step != null){
      if(
        (currentStep?.step?.binActivity === BinActivityEnum.PickEmptyBin) && 
        (nextStep?.step?.binActivity === BinActivityEnum.DropEmptyBin)
      ){
        // PICK & DROP EMPTY

        isMulti = true;
      } else if(
        (currentStep?.step?.binActivity === BinActivityEnum.PickLoadedBin) && 
        (nextStep?.step?.binActivity === BinActivityEnum.DropLoadedBin)
      ){
        // PICK & DROP LOADED

        isMulti = true;
      } else if(
        (currentStep?.step?.binActivity === BinActivityEnum.WasteDisposal) &&
        (nextStep?.step?.binActivity === BinActivityEnum.DropEmptyBin)
      ){
        // DROP WASTE & DROP EMPTY

        isMulti = true;
      } else if(
        (currentStep?.step?.binActivity === BinActivityEnum.LoadWaste) && 
        (nextStep?.step?.binActivity === BinActivityEnum.WasteDisposal)
      ){
        // PICK WASTE & DROP WASTE

        isMulti = true;
      }
    }
  }

  return {
    isMulti,
    canRender,
  };
}
export const canRenderStep = (isMulti: boolean, canRender: boolean) => {
  if(isMulti && canRender === false){
    return StepRender.None;
  } else if(isMulti && canRender === true){
    return StepRender.Multi;
  } else if(isMulti === false){
    return StepRender.Single;
  }

  return StepRender.None;
}

export const getLocationName = (locationType: any, hasDefaultSite: any) => {
  try {
    if(locationType === LocationEnum.CustomerSite){
      return 'Customer Location';
    } else {
      if(hasDefaultSite){
        return 'Default Location';
      } else {
        return 'No Default Location';
      }
    }
  } catch(e){
    return '';
  }
}
export const getLocationValue = (locationType: any, hasDefaultSite: any) => {
  try {
    if(locationType === LocationEnum.CustomerSite){
      return DefaultLocationEnum.CustomerSiteLocation;
    } else {
      if(hasDefaultSite){
        return DefaultLocationEnum.DefaultLocation;
      } else {
        return DefaultLocationEnum.NoDefaultLocation;
      }
    }
  } catch(e){
    return 0;
  }
}
export const getLocationValidation = (locationType: any, hasDefaultSite: any) => {
  try {
    if(locationType === LocationEnum.CustomerSite){
      return false;
    } else {
      if(hasDefaultSite){
        return true;
      } else {
        return false;
      }
    }
  } catch(e){
    return false;
  }
}

export const getGroupsFromSteps = (steps: Array<any>, jobBinTypes: Array<any>, isEdit: boolean = false): Array<any> => {
  let group: Array<any> = [];
  let job: Array<any> = [];

  let lastIndex = (steps && steps.length > 0) ? steps.length-1 : -1;

  if(steps && steps.length > 0){
    if(steps[0] && steps[0]?.binActivity === BinActivityEnum.LoadWaste){
      // TIPPER

      steps.forEach((step: any, currentStepIndex: number) => {
        if (currentStepIndex === 0) {
          // START JOB STEP
          job.push(step);

        } else if (step?.binActivity === BinActivityEnum.WasteDisposal) {
          // END JOB STEP
          job.push(step);
          
          let blockInfo = getBlockStepInfo(job, jobBinTypes, isEdit);
          group.push(blockInfo);
  
          job = [];
        }
      });

    } else {
      // OTHERS

      steps.forEach((step: any, currentStepIndex: number) => {
        if (step?.binActivity === BinActivityEnum.PickLoadedBin || step?.binActivity === BinActivityEnum.PickEmptyBin) {
          // START JOB STEP
          job.push(step);
          
        } else if (currentStepIndex === lastIndex || step?.binActivity === BinActivityEnum.DropLoadedBin || step?.binActivity === BinActivityEnum.DropEmptyBin) {
          // END JOB STEP
          job.push(step);
          
          let blockInfo = getBlockStepInfo(job, jobBinTypes, isEdit);
          group.push(blockInfo);
  
          job = [];
        } else {
          // STEPS BEETWEEN START/END JOB
          job.push(step);
        }
      }); 
    }

    group = fixBlockStepInfoForExchange(group, steps, isEdit);
    group = fixBlockStepInfoForTask(group, steps, isEdit);
  }

  return group;
}
export const getBinIn = (item: any): any => {
  let bins: any = null;

  if(item && item.jobBinTypes && item.jobBinTypes.length > 0){
    bins = item.jobBinTypes.filter((x: any) => x.binActivity === BinActivity.In);
  }
  
  return bins;
}
export const getBinOut = (item: any): any => {
  let bins: any = null;

  if(item && item.jobBinTypes && item.jobBinTypes.length > 0){
    bins = item.jobBinTypes.filter((x: any) => x.binActivity === BinActivity.Out);
  }
  
  return bins;
}
export const getBinAll = (item: any): any => {
  let bins: any = null;

  if(item && item.jobBinTypes && item.jobBinTypes.length > 0){
    bins = item.jobBinTypes;
  }
  
  return bins;
}
export const getBinInOut = (currentStep: any): any => {
  let bin: any = null;
  if (currentStep.binActivity === BinActivityEnum.PickEmptyBin) {
    bin = BinActivity.Out;

  } else if (currentStep.binActivity === BinActivityEnum.PickLoadedBin) {
    bin = BinActivity.Out;

  } else if (currentStep.binActivity === BinActivityEnum.DropEmptyBin) {
    bin = BinActivity.In;

  } else if (currentStep.binActivity === BinActivityEnum.DropLoadedBin) {
    bin = BinActivity.In;

  } else if (currentStep.binActivity === BinActivityEnum.LoadWaste) {
    bin = BinActivity.NoActivity;

  } else if (currentStep.binActivity === BinActivityEnum.WasteDisposal) {
    bin = BinActivity.NoActivity;

  } else if (currentStep.binActivity === BinActivityEnum.NoActivity) {
    bin = BinActivity.NoActivity;
  }

  return bin;
}
export const getBinInOutName = (binInOut: number): any => {
  let bin: any = '';

  if(isNumeric(binInOut)){
    if(binInOut === BinActivity.In){
      bin = 'IN'
    } else if(binInOut === BinActivity.Out){
      bin = 'OUT'
    } else if(binInOut === BinActivity.NoActivity){
      bin = 'No Activity'
    }
  }

  return bin;
}
export const transformJobStepBinType = (stepsArr: Array<any>, values: any) => {
  let steps = (stepsArr && stepsArr.length > 0) ? _.cloneDeep(stepsArr) : [];

  let jobBinTypes: Array<jobBinTypesStruct> = [];
  // steps?.sort((a: any, b: any) => a.stepOrder - b.stepOrder);
  steps.map((step: any, i: number) => ({
    ...steps,
    stepOrder: i+1
  }));

  let binTypeOrder = 1;

  for (const step of steps) {
      switch (step?.binActivity) {
          case BinActivityEnum.PickLoadedBin:
          case BinActivityEnum.PickEmptyBin:
            step.jobStepBinTypes = step?.jobStepBinTypes;
              for (const groupedBinType of step?.groupedJobStepBinTypes?.sort((a: any, b: any) => a.order - b.order)) {
                  for (let idx = 1; idx <= groupedBinType.quantity; idx++) {
                      step?.jobStepBinTypes.push({
                          order: idx,
                          stepOrder: step?.stepOrder,
                          binTypeOrder: binTypeOrder,
                          binActivity: BinActivity.Out
                      });

                      if (!jobBinTypes.some(x => x.order === binTypeOrder)) {
                        let jobBinTypesItem = _.cloneDeep(jobBinTypesInit);
                        jobBinTypesItem.binTypeId = groupedBinType.binTypeId
                        jobBinTypesItem.binType = groupedBinType.binType
                        jobBinTypesItem.order = binTypeOrder;
                        jobBinTypesItem.binActivity = step?.locationType === LocationEnum.CustomerSite
                          ? BinActivity.In
                          : step?.locationType === LocationEnum.Yard
                          ? BinActivity.Out : BinActivity.NoActivity;
                        jobBinTypesItem.isInVehicle = true;
                        jobBinTypesItem.stepOrder = step?.stepOrder;

                        jobBinTypes.push(jobBinTypesItem);
                        binTypeOrder++;
                      }
                  }
              }
              break;
          case BinActivityEnum.DropLoadedBin:
          case BinActivityEnum.DropEmptyBin:
            step.jobStepBinTypes = step?.jobStepBinTypes;
              for (const groupedBinType of step?.groupedJobStepBinTypes?.sort((a: any, b: any) => a.order - b.order)) {
                  if (groupedBinType.binTypeId) {
                      for (let idx = 1; idx <= groupedBinType.quantity; idx++) {
                          let jobBinType = jobBinTypes.sort((a, b) => a.order - b.order)
                              .find(x => x.binTypeId === groupedBinType.binTypeId && x.isInVehicle);
                          if (jobBinType) {
                              step?.jobStepBinTypes.push({
                                  order: idx,
                                  stepOrder: step?.stepOrder,
                                  binTypeOrder: binTypeOrder,
                                  binActivity: BinActivity.In
                              });
                              jobBinType.isInVehicle = false;
                          }
                      }
                  }
              }
              break;
          case BinActivityEnum.ExchangeBin:
            step.jobStepBinTypes = step?.jobStepBinTypes;
              for (const groupedBinType of step?.groupedJobStepBinTypes?.sort((a: any, b: any) => a.order - b.order)) {
                  if (groupedBinType.binActivity === BinActivity.In && groupedBinType.binTypeId) {
                      for (let idx = 1; idx <= groupedBinType.quantity; idx++) {
                          let jobBinType = jobBinTypes.sort((a, b) => a.order - b.order)
                              .find(x => x.binTypeId === groupedBinType.binTypeId && x.isInVehicle);
                          if (jobBinType) {
                              step?.jobStepBinTypes.push({
                                  order: idx,
                                  stepOrder: step?.stepOrder,
                                  binTypeOrder: binTypeOrder,
                                  binActivity: BinActivity.In
                              });
                              jobBinType.isInVehicle = false;
                          }
                      }
                  } else if (groupedBinType.binActivity === BinActivity.Out) {
                      for (let idx = 1; idx <= groupedBinType.quantity; idx++) {
                          step?.jobStepBinTypes.push({
                              order: idx,
                              stepOrder: step?.stepOrder,
                              binTypeOrder: binTypeOrder,
                              binActivity: BinActivity.Out
                          });

                          if (!jobBinTypes.some(x => x.order === binTypeOrder)) {
                              let jobBinTypesItem = _.cloneDeep(jobBinTypesInit);
                              jobBinTypesItem.binTypeId = groupedBinType.binTypeId
                              jobBinTypesItem.binType = groupedBinType.binType
                              jobBinTypesItem.order = binTypeOrder;
                              jobBinTypesItem.binActivity = step?.locationType === LocationEnum.CustomerSite
                                ? BinActivity.In
                                : step?.locationType === LocationEnum.Yard
                                ? BinActivity.Out : BinActivity.NoActivity;
                              jobBinTypesItem.isInVehicle = true;
                              jobBinTypesItem.stepOrder = step?.stepOrder;
                        
                              jobBinTypes.push(jobBinTypesItem);
                              binTypeOrder++;
                          }
                      }
                  }
              }
              break;
          case BinActivityEnum.WasteDisposal:
          case BinActivityEnum.LoadWaste:
            step.jobStepBinTypes = step?.jobStepBinTypes;
              for (const groupedBinType of step?.groupedJobStepBinTypes?.sort((a: any, b: any) => a.order - b.order)) {
                  if (groupedBinType.binTypeId) {
                      for (let idx = 1; idx <= groupedBinType.quantity; idx++) {
                          let jobBinType = jobBinTypes.sort((a, b) => a.order - b.order)
                              .find(x => x.binTypeId === groupedBinType.binTypeId && x.isInVehicle);
                          if (jobBinType) {
                              step?.jobStepBinTypes.push({
                                  order: idx,
                                  stepOrder: step?.stepOrder,
                                  binTypeOrder: binTypeOrder,
                                  binActivity: BinActivity.NoActivity
                              });
                              jobBinType.isInVehicle = true;
                          }
                      }
                  }
              }
              break;
          default:
              break;
      }
  }

  jobBinTypes.sort((a: any, b: any) => a.order - b.order).forEach((binType: any) => {
      const lastRelatedStep = steps
          .filter((step: any) => step.jobStepBinTypes.some((jobStepBinType: any) => jobStepBinType.binTypeOrder === binType.order))
          .sort((a: any, b: any) => b.stepOrder - a.stepOrder)
          .shift();

      if (lastRelatedStep) {
        if (lastRelatedStep.locationType === LocationEnum.Yard) {
          binType.binActivity = BinActivity.In;
        } else if (lastRelatedStep.locationType === LocationEnum.CustomerSite) {
          binType.binActivity = BinActivity.Out;
        }
      }
  });

  if (values.isBillable) {
    if(jobBinTypes && jobBinTypes.length > 0){
      for (let j = 0; j < jobBinTypes.length; j++) {
        if (jobBinTypes[j].binActivity === BinActivity.In) {
          jobBinTypes[j].isBillable = true;
        }
      }
    }
  }

  return { steps, jobBinTypes };
}
export const getCustomerSiteIdFromSelectedBin = (group: any) => {
  let customerSiteId1 = null;
  let customerSiteId2 = null;
  
  if(group && group.customerSites && group.customerSites.length > 0){
    if(group.customerSites.length === 1){
      customerSiteId1 = group.customerSites[0]?.customerSiteId;
    } else if (group.customerSites.length === 2){
      customerSiteId1 = group.customerSites[0]?.customerSiteId;
      customerSiteId2 = group.customerSites[1]?.customerSiteId;
    }
  }

  return {
    customerSiteId1: customerSiteId1,
    customerSiteId2: customerSiteId2,
  };
}
export const getFirstCustomerSite = (values: any) => {
  let customerSite: any = null;

  if(values && values.stepGroups && values.stepGroups.length > 0){
    for(let g = 0; g < values.stepGroups.length; g++){
      let group = values.stepGroups[g];

      if(group && group.customerSites && group.customerSites.length > 0){
        for(let c = 0; c < group.customerSites.length; c++){
          if(customerSite === null){
            customerSite = group.customerSites[c];
            break;
          }
        }
      }
    }
  }

  return customerSite;
}
export const getFirstCustomerSites = (values: any) => {
  let customerSites: any = null;

  if(values && values.stepGroups && values.stepGroups.length > 0){
    for(let g = 0; g < values.stepGroups.length; g++){
      let group = values.stepGroups[g];

      if(group && group.customerSites && group.customerSites.length > 0){
        if(customerSites === null){
          customerSites = group.customerSites;
          break;
        }
      }
    }
  }

  return customerSites;
}
export const getFirstCustomerSiteStep = (values: any) => {
  let step: any = null;

  if(values && values.stepGroups && values.stepGroups.length > 0){
    for(let g = 0; g < values.stepGroups.length; g++){
      let group = values.stepGroups[g];

      if(group && group.blocks && group.blocks.length > 0){
        for(let b = 0; b < group.blocks.length; b++){
          let block = group.blocks[b];

          if(block && block.steps && block.steps.length > 0){
            for(let i = 0; i < block.steps.length; i++){
              if(step === null){
                if(block.steps[i]?.locationType === LocationEnum.CustomerSite){
                  step = block.steps[i];
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  return step;
}
export const getFirstBinType = (values: any) => {
  let binType: any = null;

  if(values && values.stepGroups && values.stepGroups.length > 0){
    for(let g = 0; g < values.stepGroups.length; g++){
      let group = values.stepGroups[g];

      if(group && group.blocks && group.blocks.length > 0){
        for(let b = 0; b < group.blocks.length; b++){
          let block = group.blocks[b];

          if(block && block.bins && block.bins.length > 0){
            for(let i = 0; i < block.bins.length; i++){
              if(binType === null){
                binType = block.bins[i];
                break;
              }
            }
          }
        }
      }
    }
  }

  return binType;
}
export const getFirstBins = (values: any) => {
  let binType1: any = null;
  let binType2: any = null;

  if(values && values.stepGroups && values.stepGroups.length > 0){
    for(let g = 0; g < values.stepGroups.length; g++){
      let group = values.stepGroups[g];

      let isExchange1 = (group.type === LocalJobType.EXCHANGE1)

      if(group && group.blocks && group.blocks.length > 0){
        for(let b = 0; b < group.blocks.length; b++){
          let block = group.blocks[b];

          if(block && block.bins && block.bins.length > 0){
            for(let i = 0; i < block.bins.length; i++){
              if(isExchange1){
                if(b === 0){
                  if(binType1 === null){
                    binType1 = block.bins[i];
                    break;
                  }
                } else if(b === 1){
                  if(binType2 === null){
                    binType2 = block.bins[i];
                    break;
                  }
                }
              } else {
                if(binType1 === null){
                  binType1 = block.bins[i];
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  return {
    binType1: binType1,
    binType2: binType2,
  };
}
export const getFirstGroupBins = (values: any) => {
  let binType1: any = {
    bins: [],
    binActivity: null,
    isOk: null,
  };
  let binType2: any = {
    bins: [],
    binActivity: null,
    isOk: null,
  };

  if(values && values.stepGroups && values.stepGroups.length > 0){
    for(let g = 0; g < values.stepGroups.length; g++){
      let group = values.stepGroups[g];

      let isExchange1 = (group.type === LocalJobType.EXCHANGE1)

      if(group && group.blocks && group.blocks.length > 0){
        for(let b = 0; b < group.blocks.length; b++){
          let block = group.blocks[b];

          if(block && block.bins && block.bins.length > 0){
            for(let i = 0; i < block.bins.length; i++){
              if(isExchange1){
                if(b === 0){
                  if(binType1.isOk === null){
                    binType1.bins = block.bins;
                    binType1.isOk = true;
                    break;
                  }
                } else if(b === 1){
                  if(binType2.isOk === null){
                    binType2.bins = block.bins;
                    binType2.isOk = true;
                    break;
                  }
                }
              } else {
                if(binType1.isOk === null){
                  binType1.bins = block.bins;
                  binType1.isOk = true;
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  return {
    binType1: binType1,
    binType2: binType2,
  };
}
export const getFirstCustomFields = (values: any) => {
  let jobStepWeighChitCustomFields: any = null;

  if(values && values.steps && values.steps.length > 0){
    for(let s = 0; s < values.steps.length; s++){
      if((jobStepWeighChitCustomFields === null) && (values.steps[s] && values.steps[s].jobStepWeighChitCustomFields && values.steps[s].jobStepWeighChitCustomFields.length > 0)){
        jobStepWeighChitCustomFields = values.steps[s].jobStepWeighChitCustomFields;
        break;
      }
    }
  }

  return jobStepWeighChitCustomFields;
}
export const getBinTypeForPricings = (values: any) => {
  let binType: any = null;

  if(values && values.stepGroups && values.stepGroups.length > 0){
    for(let g = 0; g < values.stepGroups.length; g++){
      let group = values.stepGroups[g];

      if(group && group.blocks && group.blocks.length > 0){
        for(let b = 0; b < group.blocks.length; b++){
          let block = group.blocks[b];

          let shouldDoPricings = canDoPricings(group, block);
          if(shouldDoPricings){
            if(block && block.bins && block.bins.length > 0){
              for(let i = 0; i < block.bins.length; i++){
                if(binType === null){
                  binType = block.bins[i];
                  break;
                }
              }
            }
          }
        }
      }
    }
  }

  return binType;
}
export const isCustomerSiteSelected = (stepGroups: any) => {
  let isSelected = false;
  
  if(stepGroups && stepGroups.length > 0){
    for(let i = 0; i < stepGroups.length; i++){
      let customerSites = getCustomerSiteIdFromSelectedBin(stepGroups[i]);
      if(isNumeric(customerSites?.customerSiteId1) || isNumeric(customerSites?.customerSiteId2)){
        isSelected = true;
        break;
      }
    }
  }

  return isSelected;
}
export const isAllCustomerSiteSelected = (stepGroups: any) => {
  let isSelected = false;
  
  if(stepGroups && stepGroups.length > 0){
    for(let i = 0; i < stepGroups.length; i++){
      let customerSites = getCustomerSiteIdFromSelectedBin(stepGroups[i]);
      if(stepGroups[i]?.type === LocalJobType.SHIFT){
        if(isNumeric(customerSites?.customerSiteId1) && isNumeric(customerSites?.customerSiteId2)){
          isSelected = true;
          break;
        }
      } else {
        if(isNumeric(customerSites?.customerSiteId1) || isNumeric(customerSites?.customerSiteId2)){
          isSelected = true;
          break;
        }
      }
    }
  }

  return isSelected;
}
export const canDoPricings = (group: any, block: any) => {
  let shouldDoPricings = false;

  if(group){
    if(group?.type === LocalJobType.EXCHANGE1){
      if(block){
        shouldDoPricings = (block?.isForWith === ForWith.WITH) ? true : false;
      } else {
        shouldDoPricings = true;
      }
    } else {
      shouldDoPricings = true;
    }
  }

  return shouldDoPricings;
}
export const canWeightChit = (stepGroups: any) => {
  let canWC = false;

  if(stepGroups && stepGroups.length > 0){
    for(let g = 0; g < stepGroups.length; g++){
      let group = stepGroups[g];

      if(group && group.blocks && group.blocks.length > 0){
        for(let b = 0; b < group.blocks.length; b++){
          let block = group.blocks[b];

          if(block?.isForWith === ForWith.WITH){
            canWC = true;
            break;
          }
        }
      }
    }
  }

  return canWC;
}
export const isDisabledWeightChit = (stepGroups: any) => {
  let isDisabledWC = true;

  if(stepGroups && stepGroups.length > 0){
    for(let g = 0; g < stepGroups.length; g++){
      let group = stepGroups[g];

      if(group && group.blocks && group.blocks.length > 0){
        for(let b = 0; b < group.blocks.length; b++){
          let block = group.blocks[b];

          let isDisabledFields = (block && block.bins) ? false : true;
          let prevBlock = (b > 0) ? group.blocks[(b-1)] : [];

          let prevBins = (prevBlock && prevBlock.bins && prevBlock.bins.length > 0) ? prevBlock.bins : [];
          let currentBins = (block && block.bins && block.bins.length > 0) ? block.bins : [];

          let binsArray = isDisabledFields ? prevBins : currentBins;

          if(block?.isForWith === ForWith.WITH){
            if(group?.type === LocalJobType.DISPOSAL){
              if(binsArray && binsArray.length > 0){
                for(let i = 0; i < binsArray.length; i++){
                  let bin = binsArray[i];
                  isDisabledWC = !(bin?.binTypeId > 0);
                  break;
                }
              }

            } else {
              let customerSites = getCustomerSiteIdFromSelectedBin(group);
              if(isNumeric(customerSites?.customerSiteId1)){
  
                if(binsArray && binsArray.length > 0){
                  for(let i = 0; i < binsArray.length; i++){
                    let bin = binsArray[i];
                    isDisabledWC = !(bin?.binTypeId > 0);
                    break;
                  }
                }
  
              }
            }
          }
        }
      }
    }
  }

  return isDisabledWC;
}

export interface StepSiteStruct {
  defaultLocationId: number|null,
  isDefault: boolean,
  locationName: string,
  addressField: string,
  address: string,
  latitude: number|null,
  longitude: number|null,
};
export const initStepSite: StepSiteStruct = {
  defaultLocationId: null,
  isDefault: false,
  locationName: '',
  addressField: '',
  address: '',
  latitude: null,
  longitude: null,
};


export interface StepStruct {
  stepName: string,
  stepTitle: string,
  stepDescription: string,

  emptyBinCountAfterAction: number,
  loadedBinCountAfterAction: number,
  stepOrder: number,
  stepType: number,

  locationType: number,
  hasDefaultSite: boolean,
  binActivity: number|null,
  defaultLocationId: number|null,

  isEditableBinNumber: boolean,
  isRequireBinNumber: boolean,
  isRequireBinWeight: boolean,
  isRequireReviewWasteType: boolean,
  canDriverChangeBinType: boolean,
  canDriverChangeWasteType: boolean,
  mustTakePhoto: number|null,
  numberofPhotosRequired: number|null,
  mustTakeSignature: number|null,

  templateStepSites: Array<StepSiteStruct>,
  groupedJobStepBinTypes: Array<any>,
  jobStepBinTypes: Array<any>,
};
export const initStep: StepStruct = {
  stepName: '',
  stepTitle: '',
  stepDescription: '',

  emptyBinCountAfterAction: 0,
  loadedBinCountAfterAction: 0,
  stepOrder: 1,
  stepType: JobStepType.Custom,

  locationType: LocationEnum.CustomerSite,
  hasDefaultSite: true,
  binActivity: null,
  defaultLocationId: null,

  isEditableBinNumber: true,
  isRequireBinNumber: true,
  isRequireBinWeight: true,
  isRequireReviewWasteType: true,
  canDriverChangeBinType: true,
  canDriverChangeWasteType: true,
  mustTakePhoto: CustomOptions.Yes,
  numberofPhotosRequired: 1,
  mustTakeSignature: CustomOptions.Yes,

  templateStepSites: [],
  groupedJobStepBinTypes: [],
  jobStepBinTypes: [],
};


export interface initialValuesStruct {
  isActive: boolean,
  isBillable: boolean,

  jobTemplateName: string,
  jobTemplateDescription: string,

  jobTemplateType: number,
  stepType: number,

  templateSteps: Array<StepStruct>,

  isRequireBinNumber: boolean,
  canDriverChangeBinType: boolean,
  canDriverChangeWasteType: boolean,
  isEditableBinNumber: boolean,
};
export const initialValues: initialValuesStruct = {
  isActive: true,
  isBillable: true,

  jobTemplateName: '',
  jobTemplateDescription: '',

  jobTemplateType: JobTemplateType.Custom,
  stepType: JobStepType.Custom,

  templateSteps: [initStep],

  isRequireBinNumber: true,
  canDriverChangeBinType: true,
  canDriverChangeWasteType: true,
  isEditableBinNumber: true,
};


interface InitState {
  isLoading: boolean,
  details: any,
  tab: string,

  isLoadingCreateUpdateDelete: boolean,
  isLoadingChangeStatus: boolean,

  isLoadingDefaultLocations: boolean,
  defaultLocations: Array<any>,
}

function NewReducer() {
  const name = 'manageJobTypesSlice';


  const initialState: InitState = {
    isLoading: false,
    details: null,
    tab: 'tab1',

    isLoadingCreateUpdateDelete: false,
    isLoadingChangeStatus: false,

    isLoadingDefaultLocations: false,
    defaultLocations: [],
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setTab: (state: InitState, action: PayloadAction<string>) => {
      state.tab = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },

    startRead: (state: InitState) => {
      state.isLoading = true;
      // state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];
      // state.list = data;
    },

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.details = action.payload;
    },

    startCreate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },

    startDelete: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startActivate: (state: InitState) => {
      state.isLoadingCreateUpdateDelete = true;
    },
    finishActivate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingCreateUpdateDelete = false;
    },
    
    startChangeStatus: (state: InitState) => {
      state.isLoadingChangeStatus = true;
    },
    finishChangeStatus: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingChangeStatus = false;
    },

    startReadDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishReadDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let defaultLocations: any = [];
      if(data && data.length > 0){
        defaultLocations = data.map((item: any, i: number) => {
          item['addressField'] = item.address;
          return item;
        });
      }

      state.defaultLocations = defaultLocations;
      state.isLoadingDefaultLocations = false;
    },

    startCreateDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishCreateDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDefaultLocations = false;
    },

    startUpdateDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishUpdateDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDefaultLocations = false;
    },

    startDeleteDefaultLocation: (state: InitState) => {
      state.isLoadingDefaultLocations = true;
    },
    finishDeleteDefaultLocation: (state: InitState, action: PayloadAction<any>) => {
      state.isLoadingDefaultLocations = false;
    },

    addDefaultLocation: (state: InitState, action: PayloadAction<StepSiteStruct>) => {
      let item = action.payload;
      item['addressField'] = item.address;

      let items = state.defaultLocations;
      let index = items.findIndex((x: StepSiteStruct) => x.defaultLocationId === item.defaultLocationId);

      if(index === -1){
        items.push(item);
      }

      state.defaultLocations = items;
    },
    editDefaultLocation: (state: InitState, action: PayloadAction<StepSiteStruct>) => {
      let item = action.payload;
      item['addressField'] = item.address;

      let items = state.defaultLocations;
      let index = items.findIndex((x: StepSiteStruct) => x.defaultLocationId === item.defaultLocationId);

      if(index > -1){
        items[index] = { ...items[index], ...item };
      }

      state.defaultLocations = items;
    },
    removeDefaultLocation: (state: InitState, action: PayloadAction<StepSiteStruct>) => {
      let item = action.payload;
      let items = state.defaultLocations;
      let index = items.findIndex((x: StepSiteStruct) => x.defaultLocationId === item.defaultLocationId);

      if(index > -1){
        items.splice(index, 1);
      }

      state.defaultLocations = items;
    },
  };


  const apis = {
    callReadApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startRead());
  
        await axios.get('JobTemplate', { params: params }).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishRead(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishRead(null));
        });
    },

    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDetails());
  
        await axios.get('JobTemplate/' + id).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishDetails(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDetails(null));
        });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreate());
  
        await axios.post('JobTemplate', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            // success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreate(null));
        });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdate());
  
        await axios.put('JobTemplate', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            // success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdate(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdate(null));
        });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDelete());
  
        await axios.delete('JobTemplate', { data: params }).then((result: any) => {
            let data = result.data;
                
            // successAPI(data);
            success('Deleted successfully', 'Job Type has been successfully deleted.');

            callback(true, data);
            dispatch(actions.finishDelete(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDelete(null));
        });
    },

    callActivateApi: (isActive: boolean, ids: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startActivate());

        let params = {
          isActive: isActive,
          ids: ids,
        };

        await axios.put('JobTemplate/deactivate', params).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            let obj = (data && data.data) ? data.data : null;
            callback(true, obj);
            dispatch(actions.finishActivate(obj));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishActivate(null));
        });
    },

    callChangeStatusApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startChangeStatus());

      await axios.put('JobTemplate/activate', params).then((result: any) => {
          let data = result.data;
          
          successAPI(data);

          callback(true, data);
          dispatch(actions.finishChangeStatus(data));
      }).catch((error: any) => {
          errorAPI(error);
          
          callback(false, null);
          dispatch(actions.finishChangeStatus(null));
      });
    },

    callReadDefaultLocationApi: (params: ReadAPIParams, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startReadDefaultLocation());
  
        await axios.get('default-location', { params: params }).then((result: any) => {
            let data = result.data;
            
            successAPI(data);

            callback(true, data);
            dispatch(actions.finishReadDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishReadDefaultLocation(null));
        });
    },

    callCreateDefaultLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startCreateDefaultLocation());
  
        await axios.post('default-location', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Saved successfully', 'The data you entered has been successfully saved.');

            callback(true, data);
            dispatch(actions.finishCreateDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishCreateDefaultLocation(null));
        });
    },

    callUpdateDefaultLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startUpdateDefaultLocation());
  
        await axios.put('default-location', params).then((result: any) => {
            let data = result.data;
            
            // successAPI(data);
            success('Updated successfully', 'Your data has been successfully updated.');

            callback(true, data);
            dispatch(actions.finishUpdateDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishUpdateDefaultLocation(null));
        });
    },

    callDeleteDefaultLocationApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
        dispatch(actions.startDeleteDefaultLocation());
  
        await axios.delete('default-location', { data: params }).then((result: any) => {
            let data = result.data;
                
            // successAPI(data);
            success('Deleted successfully', 'Job Type has been successfully deleted.');

            callback(true, data);
            dispatch(actions.finishDeleteDefaultLocation(data));
        }).catch((error: any) => {
            errorAPI(error);
            
            callback(false, null);
            dispatch(actions.finishDeleteDefaultLocation(null));
        });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();