import _ from 'lodash';
import numeral from 'numeral';
import moment from "moment";
import { DateObject } from 'react-multi-date-picker';
import humanizeDuration from 'humanize-duration';

import { getTimeDiff, convertMinToHours, getCurrency } from '../utils';
import { AuditTrailObjectTypeText } from '../enums';

// Format ----------------------------------------------------------------------

export const dateTimeViewFormat = () => {
  return 'MMM DD, yyyy HH:mm';
}
export const dateViewFormat = () => {
  return 'MMM DD, yyyy';
}
export const dateTimeFormat = () => {
  return 'DD-MM-YYYY HH:mm';
}
export const dateTimeMeridianFormat = () => {
  return 'DD-MM-YYYY hh:mm A';
}
export const dateFormat = () => {
  return 'DD-MM-YYYY';
}
export const timeFormat = () => {
  return 'HH:mm';
}
export const timeMeridianFormat = () => {
  return 'hh:mm A';
}
export const apiDateFormat = () => {
  return 'YYYY-MM-DD';
}

export const getMinInteger = () => {
  return Number.MIN_SAFE_INTEGER;
};
export const getMaxInteger = () => {
  return Number.MAX_SAFE_INTEGER;
};

export const getDateObjectTime = (time: any) => {
  try {
    return new DateObject().set({ hour: moment(time, timeFormat()).hour(), minute: moment(time, timeFormat()).minute() })
  } catch (e) {
    return new DateObject();
  }
}
export const getDateObjectTimeMeridian = (time: any) => {
  try {
    return new DateObject().set({ hour: moment(time, timeMeridianFormat()).hour(), minute: moment(time, timeMeridianFormat()).minute() })
  } catch (e) {
    return new DateObject();
  }
}
export const formatBytes = (numb: number|null = 0, format = '0.0 b') => {
  try {
    return numeral(numb).format(format);
  } catch (e) {
    return numb;
  }
}
export const formatNumber = (numb: number|null = 0, format = '0,0.00', suffix = '', prefix = '') => {
  try {
    return prefix + numeral(numb).format(format) + suffix;
  } catch (e) {
    return numb;
  }
}
export const formatDistance = (numb: number|null = 0, format = '0,0.00', suffix = ' km', prefix = '') => {
  try {
    return formatNumber(numb, format, suffix, prefix);
  } catch (e) {
    return numb;
  }
}
export const formatCurrency = (numb: number|null = 0, format = '0,0.00', suffix = '') => {
  let prefix = getCurrency();
  try {
    return formatNumber(numb, format, suffix, prefix);
  } catch (e) {
    return numb;
  }
}
export const formatPhoneNumnber = () => {
  return "069/99-99-999";
}
export const formatMinToHours = (min = null) => {
  let numb = convertMinToHours(min);
  return formatNumber(numb, '0.[00]', ' h');
}
export const formatTime = (firstDate: any, secondDate: any) => {
  try {
    if(firstDate != null && secondDate != null){
      let timeMS = getTimeDiff(firstDate, secondDate);
      return humanizeDuration(timeMS);
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
}

export const round = (value: any, precision: number = 2) => {
  try {
    const numb = parseFloat(value);
    if (!Number.isNaN(numb)) {
      let res = Number(numb.toFixed(precision));
      return res;
    } else {
      return 0;
    }
  } catch(e){
    return 0;
  }
};


export const getAuditTrailLabel = (type: any) => {
  if (type != null) {
      return AuditTrailObjectTypeText[type];
  } else {
      return '';
  }
};

export const mToKm = (m: number) => {
  var km = m / 1000;
  return km.toFixed(1) + " km"
};

export const customConcat = (array: any = [], separator: any = '') => {
  return array.filter(x => (x !== null && x !== '')).join(separator);
}