import React, { ReactNode } from 'react';
import _ from 'lodash';
import parse from "html-react-parser";
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

// import { Divider } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, weekDayNumb, isWeekend } from '../slice.tsx';
import jobFormAuditTrailSlice from './auditTrail/slice.tsx';

// enums
// import {  } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledTable = styled.table`
  border: 0px !important;

  thead {
    tr {
      th {
        border: 0px !important;
        border-bottom: 1px solid var(--bs-gray-200) !important;
      }
    }
  }

  tbody {
    tr {
      td {
        border: 0px !important;
      }
    }
  }
`;


const AuditTrail = () => {
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  // const { details } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();
  

  const getElements = () => {
    let newValues = _.cloneDeep(values);

    return <Row className='g-32 align-items-center'>
      <Col xs={12}>
        <div className='overflow-auto'>
          <StyledTable>
            <thead>
              <tr>
                <th style={{ width: '10%' }}>Status</th>
                <th style={{ width: '20%' }}>User</th>
                <th style={{ width: '30%' }}>Description</th>
                <th style={{ width: '15%' }}>Timestamp</th>
                <th style={{ width: '10%' }}>Action</th>
              </tr>
            </thead>
            <tbody>

                {(newValues && newValues.histories && newValues.histories.length > 0) && newValues.histories.sort((a: any, b: any) => new Date(b.updated).getTime() - new Date(a.updated).getTime()).map((item: any, index: number) => {
                  return <tr key={'jobform_audittrail_list_item_' + index}>
                    <td>{item?.currentStatus || ''}</td>
                    <td>{item?.updatedBy || ''}</td>
                    <td>{parse(item?.description || '')}</td>
                    <td>{item?.updatedDisplay || ''}</td>
                    <td>
                      <a href="/"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          
                          dispatch(jobFormAuditTrailSlice.setShow({ show: true, details: item }));
                        }
                      }>View Changes</a> 
                    </td>
                  </tr>
                })}

            </tbody>
          </StyledTable>
        </div>
      </Col>
    </Row>
  }

  return <Col xs={12} xxl={10}>
    <Row className='gy-16 align-items-center'>
    <Col xs={12}>
        <span className="large semibold gray-900">9. Audit Trail</span>
      </Col>

      <Col xs={12}>{getElements()}</Col>

      {/* <Col xs={12}>
        <Divider />
      </Col> */}
    </Row>
  </Col>
}

export default AuditTrail;
