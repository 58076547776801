import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

// import {
//   Row,
//   Col,
//   Card,
// } from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, findStatus, driverMarkerIcon, arrowMarkerIcon, defaultMarkerIcon, isEdit } from '../slice.tsx';
import jobSummarySlice from './jobSummary/slice.tsx';

// enums
import { getDefLatLngZoomV4, info, mToKm, validateLatLng } from '../../../../v5/utils/utils.tsx';
// import {  } from '../../../../v5/utils/enums.tsx';

import { LocationEnum, LocalJobType, getStepsFromStepGroups } from '../../manage/jobs/jobTypes/slice.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';
import GoogleMap, { addMarker, clearAllMarkers, addPolyline, clearAllPolyline } from '../../../../v5/components/googleMap/GoogleMap.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------


const Map = () => {
  const defaultLatLngZoom: any = getDefLatLngZoomV4();
  // const location = useLocation();
  // const router = useRouter();
  
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

  const { isLoading, latestLocationDrivers, job, isLoadingLatestLocationDrivers, defaultLocations, isLoadingDistance, distance } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const dispatch = useDispatch<AppDispatch>();

  const [map, setMap] = React.useState<any>(null);
  const [customElements, setCustomElements] = React.useState<Array<any>>([
    {
      element: 'span',
      text: 'Total: 0km',
      className: 'badge bg-custom-light btn-lg',
      style: {
        position: 'absolute',
        bottom: '25px',
        left: '10px',
      },
      visible: true,
      disabled: false,
      callback: null,
    },
    {
      element: 'button',
      text: 'See larger view',
      className: 'btn btn-custom-outlined btn-sm',
      style: {
        position: 'absolute',
        bottom: '25px',
        right: '10px',
      },
      visible: true,
      disabled: !(values.steps && values.steps.length > 0),
      callback: (e: any) => {
        dispatch(jobSummarySlice.setShow({ show: true, values: values.stepGroups }));
      },
    }
  ]);


  const latestLocationDriversMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const driversPathMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const polylineRef = React.useRef<Array<google.maps.Polyline>>([]);
  const defaultLocationsMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const jobTemplateRef = React.useRef(values?.jobTemplate);
  const valuesRef = React.useRef(values.stepGroups);


  const removeCircularReferences = (obj: any, seen = new WeakSet()) => {
    if (typeof obj !== "object" || obj === null) return obj;
    if (seen.has(obj)) return undefined;
  
    seen.add(obj);
    const cleanObj: any = Array.isArray(obj) ? [] : {};
  
    for (const key in obj) {
      cleanObj[key] = removeCircularReferences(obj[key], seen);
    }
  
    return cleanObj;
  };


  React.useEffect(() => {
    jobTemplateRef.current = values?.jobTemplate;
    
    let coordinates: Array<any> = [];
    let arr = getStepsFromStepGroups(values.stepGroups);
    if(arr && arr.length > 0){
      arr.forEach((stepObj: any, stepIndex: number) => {
        if(stepObj.locationType == LocationEnum.CustomerSite){
          coordinates.push({
            customerSiteId: stepObj.customerSite?.customerSiteId,
            latitude: stepObj.customerSite?.latitude,
            longitude: stepObj.customerSite?.longitude,
          });
        } else {
          if(stepObj.hasDefaultSite){
            let locationItem = defaultLocations.find((x: any) => x.defaultLocationId === stepObj.defaultLocationId);
            if(locationItem){
              coordinates.push({
                customerSiteId: null,
                latitude: locationItem.latitude,
                longitude: locationItem.longitude,
              });
            }
          }
        }
      })
    }

    dispatch(slice.callGetDistanceApi(coordinates, (state: boolean, data: any) => {}));
  }, [values?.jobTemplate]);
  
  React.useEffect(() => {
    valuesRef.current = values.stepGroups;
  }, [values.stepGroups]);

  React.useEffect(() => {
    clearAllMarkers(latestLocationDriversMarkersRef);
    clearAllMarkers(driversPathMarkersRef);
    clearAllPolyline(polylineRef);

    if(!isEdit()){
      if(latestLocationDrivers && latestLocationDrivers.length > 0){
        if(values.driverId){
          let driver = latestLocationDrivers.find((x: any) => x.driverId === values.driverId);
          if(driver){
            addMarker(latestLocationDriversMarkersRef, {
              map: map,
              ...driver,
              ...driverMarkerIcon(driver.driverName, '#43936C'),
            }, (marker: any, markerItem: any) => {
              onClickDriver(markerItem);
            })
          }

        } else {
          latestLocationDrivers.forEach((item: any) => {
            addMarker(latestLocationDriversMarkersRef, {
              map: map,
              ...item,
              ...driverMarkerIcon(item.driverName, '#43936C'),
            }, (marker: any, markerItem: any) => {
              onClickDriver(markerItem);
            })
          });
        }

        latestLocationDrivers.forEach((item: any, i: number) => {
          addMarker(driversPathMarkersRef, {
            map: map,
            ...item,
            ...arrowMarkerIcon(latestLocationDrivers, item, i),
          })
        });
        
        addPolyline(polylineRef, latestLocationDrivers.map((x: any) => x.position), {
          map: map,
          strokeColor: "#185CFF",
          strokeOpacity: 0.5,
          strokeWeight: 4,
        });
      }
    }
  }, [latestLocationDrivers, values.driverId, window.google])

  React.useEffect(() => {
    let items = _.cloneDeep(customElements);
    items[1].disabled = !(values.steps && values.steps.length > 0);
    items[1].callback = (e: any) => {
      if(valuesRef && valuesRef.current){
        // const safeValues = removeCircularReferences(valuesRef.current);
        dispatch(jobSummarySlice.setShow({ show: true, values: valuesRef.current }));
      }
    };
    setCustomElements(items);

    clearAllMarkers(defaultLocationsMarkersRef);

    let coordinates: Array<any> = [];
    let arr = getStepsFromStepGroups(values.stepGroups);
    if(arr && arr.length > 0){
      arr.forEach((stepObj: any, stepIndex: number) => {
        if(stepObj.locationType == LocationEnum.CustomerSite){
          if(validateLatLng(stepObj?.customerSite?.latitude, stepObj?.customerSite?.longitude)){
            addMarker(defaultLocationsMarkersRef, {
              map: map,
              position: {
                lat: stepObj?.customerSite?.latitude,
                lng: stepObj?.customerSite?.longitude,
              },
              title: stepObj.customerSite.siteNameDisplay,
              ...defaultMarkerIcon((stepObj.stepCount+1).toString()),
            })
          }
        } else {
          if(stepObj.hasDefaultSite){
            let locationItem = defaultLocations.find((x: any) => x.defaultLocationId === stepObj.defaultLocationId);
            if(locationItem){
              if(validateLatLng(locationItem?.latitude, locationItem?.longitude)){
                addMarker(defaultLocationsMarkersRef, {
                  map: map,
                  position: {
                    lat: locationItem?.latitude,
                    lng: locationItem?.longitude,
                  },
                  title: locationItem.address,
                  ...defaultMarkerIcon((stepObj.stepCount+1).toString()),
                })
              }
            }
          }
        }
      })
    }
  }, [values.stepGroups]);

  React.useEffect(() => {
    let items = _.cloneDeep(customElements);
    items[0].text = isLoadingDistance ? 'Loading...' : 'Total: ' + mToKm(distance);
    setCustomElements(items);
  }, [isLoadingDistance, distance]);


  const onClickDriver = (markerItem: any) => {
    const currentJobTemplate = jobTemplateRef.current;
    if(currentJobTemplate){
      let item = (markerItem && markerItem.item) ? markerItem.item : null;

      let driver = (item && item.driver) ? item.driver : null;
      let driverId = (driver && driver.driverId) ? driver.driverId : null;
      let driverName = (driver && driver.driverName) ? driver.driverName : '';

      setFieldValue('driverId', driverId);
      setFieldValue('driverName', driverName);
      
      info(driverName, 'Driver has been assigned'); 

      if(values.vehicleId === null || values.vehicleId <= 0){
        if(driver && driver.defaultVehicle){
          let vehicleItem = driver.defaultVehicle;
          let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
          let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';

          setFieldValue('vehicleId', vehicleId);
          setFieldValue('vehicleName', vehicleName);
        }
      }
      
      let selectedStatus = findStatus(currentJobTemplate?.templateStatuses, 'unassigned');
      if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.jobStatusId) {
        let newStatus = findStatus(currentJobTemplate?.templateStatuses, 'assigned');
        let newStatusId = (newStatus.jobStatusId) ? newStatus.jobStatusId : null;
        let newStatusName = (newStatus.jobStatusName) ? newStatus.jobStatusName : '';

        setFieldValue('statusId', newStatusId);
        setFieldValue('statusName', newStatusName);
      }
    }
  }


  return <Spin spinning={isLoadingLatestLocationDrivers}>
    <div style={{ height: '250px' }}>
      <GoogleMap
        canConextMenu={false}
        options={{
          center: defaultLatLngZoom.coord,
          zoom: defaultLatLngZoom.zoom,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: false,
          cameraControl: false,
          fullscreenControl: false,
          gestureHandling: "cooperative",
        }}
        customElements={customElements}
        onLoad={(map: any, ref: any) => {
          setMap(map);
        }}
      />
    </div>
  </Spin>
}

export default Map;
