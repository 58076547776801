import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
  Button,
  ButtonGroup,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap';
import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Checkbox, Divider, Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../v5/utils/store.tsx';
import slice, { useFormikContext, fields, getJobFormActionButtons, getJobFormActionButtonName, getTimeAvailability, JobAction, isCopy } from '../slice.tsx';

// enums
import { danger } from '../../../../v5/utils/utils.tsx';
import { CustomerSiteAvailability } from '../../../../v5/utils/enums.tsx';

// components
// import MainContainer from '../../../../v5/components/container/MainContainer.jsx';

// import { ReactComponent as XIcon } from "../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------


const FooterActionButtons = (props) => {
    
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();

    const { isLoading, isLoadingCreateUpdateDelete, id } = useTypedSelector((state: RootState) => state.jobFormSlice);
    // const dispatch = useDispatch<AppDispatch>();

    const actionButtons = () => {
        let actions = getJobFormActionButtons(values, id);

        if(actions === null){
            return <Button 
                variant={'custom-primary'}
                size={'sm'}
                disabled={true}
            >
                Dispatch Now
                {(isLoading || isLoadingCreateUpdateDelete) ? <>&nbsp;<Spin className='ms-8' spinning={true} size='small' /></> : null}
            </Button>
        } else {
            return getDropdown(actions);
        }
    }

    const getDropdown = ({ main, dropdown }) => {
        let timeAvailabilityObj = getTimeAvailability(values);

        return <>
            {values.isActive && <Dropdown
                as={ButtonGroup}
                align="end"
                onSelect={(eventKey, e) => {
                    if(isCopy() && (eventKey == JobAction.Draft)){
                        if(props.onSaveCopyDraft){
                            props.onSaveCopyDraft(main);
                        }
                    } else {
                        validateForm().then((err) => {
                            if(_.isEmpty(err)){
                                if(props.onSave){
                                    props.onSave(eventKey);
                                }
                            } else {
                                let msg = 'You must fill in the required fields in form and in the billing side panel';
                                let keys = Object.keys(err);
                                if(keys && keys.length > 0){
                                    msg = err[keys[0]]
                                }
                                danger('Error', msg);
                            }
                        })
                    }
                }}
            >
                <Button 
                    variant={'custom-primary'}
                    size={'sm'}
                    disabled={(isLoading || isLoadingCreateUpdateDelete) || (timeAvailabilityObj?.timeAvailability == CustomerSiteAvailability.NotAvailable)}
                    onClick={() => {
                        validateForm().then((err) => {
                            if(_.isEmpty(err)){
                                if(props.onSave){
                                    props.onSave(main, values);
                                }
                            } else {
                                let msg = 'You must fill in the required fields in form and in the billing side panel';
                                let keys = Object.keys(err);
                                if(keys && keys.length > 0){
                                    msg = err[keys[0]]
                                }
                                danger('Error', msg);
                            }
                        })
                    }}
                >
                    {getJobFormActionButtonName(main)}
                    <Spin className='ms-8' spinning={(isLoading || isLoadingCreateUpdateDelete)} size='small' />
                </Button>

                {(dropdown && dropdown.length > 0) && <>
                    <Dropdown.Toggle 
                        variant={'custom-primary'}
                        size={'sm'}
                        split
                        disabled={(isLoading || isLoadingCreateUpdateDelete) || (timeAvailabilityObj?.timeAvailability == CustomerSiteAvailability.NotAvailable)}
                    />


                    <Dropdown.Menu variant={'primary'}>
                        {dropdown.map((act, i) => {
                            return <Dropdown.Item
                                key={i}
                                eventKey={act}
                                active={false}
                                disabled={false}
                            >{getJobFormActionButtonName(act)}</Dropdown.Item>
                        })}
                    </Dropdown.Menu>
                </>}
            </Dropdown>}
        </>
    }


    return actionButtons()
};

export default FooterActionButtons;