import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../../v5/utils/axios';
import { success, successAPI, errorAPI, getMaxPageSize } from '../../../../../v5/utils/utils';
import Yup from '../../../../../v5/utils/yup';
// import {  } from '../../../../../v5/utils/enums';


export const fields = {
  isDefault: {
    id: 'isDefault',
    label: 'Set as default',
    placeholder: '',
  },
  locationName: {
    id: 'locationName',
    label: 'Location Name',
    placeholder: 'Enter site name',
  },
  address: {
    id: 'address',
    label: 'Address',
    placeholder: 'Insert address or coordinate',
  },
};


export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    defaultLocation: Yup.array().of(Yup.object().shape({
      locationName: Yup.string().nullable().required().label(fields.locationName.label),
      address: Yup.string().nullable().required().label(fields.address.label),
    }))
    .unique('locationName', 'You need to remove duplicates'),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    data['defaultLocation'] = form;
  }
  
  return data;
};


export interface initialValuesStruct {
  defaultLocation: Array<any>,
};
export const initialValues: initialValuesStruct = {
  defaultLocation: [],
};


export interface initialDefaultLocationStruct {
  defaultLocationId: any,
  locationName: string,
  address: string,
  addressField: string,
  latitude: any,
  longitude: any,
  isActive: boolean,
  isDefault: boolean,
};
export const initialDefaultLocation: initialDefaultLocationStruct = {
  defaultLocationId: null,
  locationName: '',
  address: '',
  addressField: '',
  latitude: null,
  longitude: null,
  isActive: true,
  isDefault: false,
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  list: Array<any>,

  group: any,
  groupIndex: number|null,
  block: any,
  blockIndex: number|null,
  step: any,
  stepIndex: number|null,
}


function NewReducer() {
  const name = 'jobFormDefaultLocationFormSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    list: [],

    groupIndex: null,
    group: null,
    blockIndex: null,
    block: null,
    stepIndex: null,
    step: null,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setStep: (state: InitState, action: PayloadAction<any>) => {
      state.step = action.payload;
    },
    

    setShow: (state: InitState, action: PayloadAction<{ show: boolean, group: any, groupIndex: number|null, block: any, blockIndex: number|null, step: any, stepIndex: number|null }>) => {
      state.group = action.payload.group;
      state.groupIndex = action.payload.groupIndex;
      state.block = action.payload.block;
      state.blockIndex = action.payload.blockIndex;
      state.step = action.payload.step;
      state.stepIndex = action.payload.stepIndex;

      state.isLoading = false;
      state.list = [];
      state.show = action.payload.show;
    },
    

    startRead: (state: InitState) => {
      state.isLoading = true;
      state.list = [];
    },
    finishRead: (state: InitState, action: PayloadAction<any>) => {
      let data = (action.payload && action.payload.data && action.payload.data.length > 0) ? action.payload.data : [];

      let defaultLocations: any = [];
      if(data && data.length > 0){
        defaultLocations = data.map((item: any, i: number) => {
          item['addressField'] = item.address;
          return item;
        });
      }

      state.list = defaultLocations;
      state.isLoading = false;
    },

    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDelete: (state: InitState) => {
      state.isLoading = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callReadApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startRead());

      await axios.get('default-location', { params: params }).then((result: any) => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishRead(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishRead(null));
      });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCreate());

      await axios.post('default-location', params).then((result: any) => {
        let data = result.data;
        
        // successAPI(data);
        // success('Saved successfully', 'The data you entered has been successfully saved.');

        callback(true, data);
        dispatch(actions.finishCreate(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishCreate(null));
      });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await axios.put('default-location', params).then((result: any) => {
        let data = result.data;
        
        // successAPI(data);
        // success('Updated successfully', 'Your data has been successfully updated.');

        callback(true, data);
        dispatch(actions.finishUpdate(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDelete());

      await axios.delete('default-location', { data: params }).then((result: any) => {
        let data = result.data;
            
        // successAPI(data);
        success('Deleted successfully', 'Location has been successfully deleted.');

        callback(true, data);
        dispatch(actions.finishDelete(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDelete(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();