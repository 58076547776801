import React from 'react';
import {
  Row,
  Col,
  Form,
  Button,
} from 'react-bootstrap';

import type { PaginationProps } from 'antd';
import { Pagination } from 'antd';

import Select from '../../select/Select.tsx';

import { ReactComponent as PrevIcon } from "../../../assets/icons/arrow-left.svg";
import { ReactComponent as NextIcon } from "../../../assets/icons/arrow-right.svg";


const DataTablePagination = ({ dataTable, tableTotal, showPageSize, onPageSizeChange }: any) => {

  const [pageSizeOptions, setPageSizeOptions] = React.useState([
    { value: 10, label: '10 per page' },
    { value: 30, label: '30 per page' },
    { value: 50, label: '50 per page' },
    { value: 70, label: '70 per page' },
  ]);


  const itemRender: PaginationProps['itemRender'] = (_: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return null;
    }
    if (type === 'next') {
      return null;
    }
    return originalElement;
  };

    return <div className={'table-pagination'}>
      <Row className='align-items-center g-8'>
        <Col xs={{ span: 'auto', order: 1 }} md={{ span: 'auto', order: 1 }}>
          {showPageSize && <Form.Control
            as={Select}
            value={dataTable.getState().pagination.pageSize}
            placeholder={'Per page'}
            searchable={false}
            allowClear={false}
            selectedIcon={true}
            loading={false}
            onChangeValue={(value: any, option: any, ref: any) => {
              let pSize = Number(value);
              
              dataTable.setPageSize(pSize)

              if(onPageSizeChange){
                onPageSizeChange(pSize);
              }
            }}
            options={pageSizeOptions}
          />}
        </Col>
        <Col xs={{ span: 12, order: 5 }} md={{ span: true, order: 2 }} className='text-center'>
          <Row className='align-items-center justify-content-center'>
            <Col xs={'auto'}>
              <Pagination
                showTitle={false}
                showSizeChanger={false}
                current={(dataTable.getState().pagination.pageIndex+1)}
                pageSize={dataTable.getState().pagination.pageSize}
                pageSizeOptions={pageSizeOptions.map((x: any) => x.value)}
                total={tableTotal}

                itemRender={itemRender}
                onChange={(page, pageSize) => {
                  dataTable.setPageIndex((page-1))
                }}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: true, order: 2 }} md={{ span: true, order: 3 }} className='d-flex d-md-none'></Col>
        <Col xs={{ span: 'auto', order: 3 }} md={{ span: 'auto', order: 4 }}>
          <Button
            variant={'custom-outlined'}
            size='sm'
            onClick={() => dataTable.previousPage()}
            disabled={!dataTable.getCanPreviousPage()}
          >
            <PrevIcon />
          </Button>
        </Col>
        <Col xs={{ span: 'auto', order: 4 }} md={{ span: 'auto', order: 5 }}>
          <Button
            variant={'custom-outlined'}
            size='sm'
            onClick={() => dataTable.nextPage()}
            disabled={!dataTable.getCanNextPage()}
          >
            <NextIcon />
          </Button>
        </Col>
      </Row>
    </div>
  };


  export default DataTablePagination