import React, { ReactNode } from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';

import {
  Row,
  Col,
  Form,
  Button,
  Offcanvas,
  Modal,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Spin, Switch, Checkbox } from 'antd';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

import Select from '../../../../components/select/Select.tsx';
import RTextEditor from '../../../../components/richTextEditor/RTextEditor.jsx';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { fields, FormikContext, initialValues, formSchema, prepareForm, prepareData } from './slice.tsx';

import { warning, info, emailValidation, downloadPDF } from '../../../../utils/utils.tsx';
// import {  } from '../../../../utils/enums.tsx';

// import { ReactComponent as XIcon } from "../../../../../v5/assets/icons/x.svg";

import Preview from './Preview.tsx';

// ----------------------------------------------------------------------

const StyledPreview = styled.div`
  border-radius: 6px;
  border: 1px solid var(--bs-gray-200);
  background-color: var(--bs-gray-200);
  padding: 28px 19px;
  max-width: 800px;
  margin: 0 auto;

  position: sticky;
  top: 0px;
  overflow: auto;

  .ant-divider {
    margin: 10px 0px 10px 0px;
    border-color: var(--bs-gray-300);
  }
    
  ${up('md')} {
    height: calc(100vh - var(--printpreview-header-height) - var(--printpreview-footer-height) - 50px);
  }

  ${down('md')} {
    height: auto;
    margin-top: 32px;
  }

  .wrapper {
    gap: 24px;
    display: grid;
    min-width: 755px;
    
    .page {
      border-radius: 4px;
      background-color: white;
      padding: 24px;

      &.page-receipt {
        max-width: 500px;
        margin: 0 auto;
      }
    }
  }
    
  &.no-page {
    text-align: center;
    align-content: center;
    
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    color: var(--bs-gray-900);
  }

  .rich-text-parse-texts {
    white-space: pre-wrap;
    line-height: 10px;
    
    p {
      margin: 0 !important;
      display: block !important;
      line-height: 15px;
    }

    ul, ol {
      margin: 0 !important;

      li {
        margin: 0 !important;
        line-height: 15px;
      }
    }
  }
`;

// ----------------------------------------------------------------------

const PageComponent = (props: any) => {
  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const router = useRouter();

  
  const headerHeight = document.getElementById('printpreview_header_ID')?.clientHeight || 82;
  const footerHeight = document.getElementById('printpreview_footer_ID')?.clientHeight || 71;
  document.documentElement.style.setProperty('--printpreview-header-height', `${(headerHeight)}px`);
  document.documentElement.style.setProperty('--printpreview-footer-height', `${(footerHeight)}px`);


  const { isLoading, show, form, isLoadingAccountBillingProfile, accountBillingProfile } = useTypedSelector((state: RootState) => state.jobFormPrintPreviewSlice);
  const dispatch = useDispatch<AppDispatch>();


  React.useEffect(() => {
    if(show){
      setValues(form);
      
      let accountBillingProfileId = (form && form?.customer) ? form?.customer?.accountBillingProfileId : null;
      dispatch(slice.callDetailsAccountBillingProfileApi(accountBillingProfileId, (state: boolean, data: any) => {}));
    }
  }, [show, form])

  React.useEffect(() => {
    if(show){
      dispatch(slice.setLoading(true));
      let formData = prepareForm(form, initialValues, accountBillingProfile);
      setValues(formData);
      dispatch(slice.setLoading(false));
    }
  }, [accountBillingProfile])
  
    
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: formSchema(null),
    onSubmit: values => {},
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;
  

  const onCancel = () => {
    dispatch(slice.setShow({ show: false, form: null }));
  }
  const isInvalid = () => {
    return (errors && Object.entries(errors).length > 0)
  }
  const isDisabled = () => {
    let isDisabled = (!values.includePdfAttachmentDeliveryOrder && !values.includePdfAttachmentPhoto && !values.includePdfAttachmentWeightChit) ? true : false;
    return isDisabled
  }
  const isCompleted = () => {
    let currentStatusName = (form && form.currentStatusName && form.currentStatusName !== '') ? form.currentStatusName.toString().toLowerCase().trim() : '';
    let isCompletedStatus = (currentStatusName == 'completed') ? true : false;
    return isCompletedStatus
  }
  const isNoPage = () => {
    return (!values.includePdfAttachmentDeliveryOrder && !values.includePdfAttachmentPhoto && !values.includePdfAttachmentWeightChit);
  }

  const print = () => {
    let data = prepareData(values);

    localStorage.setItem("jobIframeData", JSON.stringify(data));

    const link = document.createElement("a");
    link.href = "/Job-Iframe";
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  const sendEmail = () => {
    info('Send email', 'You will receive an mail soon');

    let data = prepareData(values);
    dispatch(slice.callSendCompleteJobEmailApi(data, (state: boolean, data: any) => {}));
  }
  const download = () => {
    info('Download', 'Please Wait ...');

    let data = prepareData(values);
    dispatch(slice.callGetJobDocApi(data, (state: boolean, data: any) => {
      if(state){
        let fileName = form?.jobNumber + '-' +form?.customer?.billingName  + ".pdf"
        downloadPDF(fileName, data);
      }
    }));
  }


  const footerElements = () => {
    return <Row className='w-100 gx-12'>
      <Col xs={true}></Col>
      <Col xs={'auto'} className='text-end'>
        {isCompleted() && <Button
          variant={'custom-outlined'}
          size={'sm'}
          className='me-12'
          disabled={isDisabled() || isInvalid()}
          onClick={(e) => {
            sendEmail();
          }}
        >Send Email</Button>}

        {isCompleted() && <Button
          variant={'custom-outlined'}
          size={'sm'}
          className='me-12'
          disabled={isDisabled() || isInvalid()}
          onClick={(e) => {
            print();
          }}
        >Print</Button>}

        {isCompleted() && <Button
          variant={'custom-primary'}
          size={'sm'}
          disabled={isInvalid()}
          onClick={(e) => {
            download();
          }}
        >Download</Button>}
      </Col>
    </Row>
  }

  const formElements = () => {
    return <Row className='g-24 align-items-center'>
      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.fromName.label}</Form.Label>
          <Form.Control
            type={'text'}
            autoComplete='off'
            size={'sm'}
            placeholder={fields.fromName.placeholder}
            disabled={false}
            value={values?.fromName}
            onChange={(e) => {
              setFieldValue('fromName', e.target.value);
            }}
            isInvalid={!!(errors && errors.fromName)}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.fromName as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.emailAddresses.label}</Form.Label>
          <Form.Control
            as={Select}
            value={values?.emailAddresses}
            mode="tags"
            tokenSeparators={[',', ' ']}
            placeholder={fields.emailAddresses.placeholder}
            searchable={false}
            allowClear={true}
            selectedIcon={true}
            loading={false}
            onChangeValue={(value: any, option: any, ref: any) => {
              let pattern = emailValidation();
              let validEmails = value.filter((email: any) => email.match(pattern) !== null);

              if (value.length > validEmails.length) {
                warning('Warning', 'You must enter a valid email address');
              }

              setFieldValue('emailAddresses', validEmails);
            }}
            options={values?.contactEmails}
            isInvalid={!!(errors && errors.emailAddresses)}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.emailAddresses as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.subject.label}</Form.Label>
          <Form.Control
            type={'text'}
            autoComplete='off'
            size={'sm'}
            placeholder={fields.subject.placeholder}
            disabled={false}
            value={values?.subject}
            onChange={(e) => {
              setFieldValue('subject', e.target.value);
            }}
            isInvalid={!!(errors && errors.subject)}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.subject as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.message.label}</Form.Label>
          <Form.Control
            as = {RTextEditor}
            placeholder={fields.message.placeholder}
            editorState={values?.message}
            onChangeValue={(plainText: any, html: any, editorState: any) => {
              setFieldValue('messagePlainText', plainText);
              setFieldValue('messageHTML', html);
              setFieldValue('message', editorState);
            }}
            isInvalid={!!(errors && errors.message)}
          />
          <Form.Control.Feedback type="invalid">{errors && errors.message as ReactNode}</Form.Control.Feedback>
        </Form.Group>
      </Col>
      <Col xs={12}>
        <Form.Label className='ps-8 pt-6'>
          <Switch
            checked={values?.includeAttachment}
            onChange={(checked) => {
              setFieldValue('includeAttachment', checked);
              
              if(checked){
                if(!values.includePdfAttachmentDeliveryOrder && !values.includePdfAttachmentWeightChit){
                  setFieldValue('includePdfAttachmentDeliveryOrder', true);
                }
              }
            }}
          />
          <span className='regular ps-8' style={{ position: 'relative', top: '2px' }}>{fields.includeAttachment.label}</span>
        </Form.Label>
      </Col>
      <Col xs={12}>
        <Form.Group>
          <Form.Label>{fields.include.label}</Form.Label>
          <div>
            <Checkbox
              checked={values?.includePdfAttachmentDeliveryOrder}
              onChange={async (e: any) => {
                await setFieldValue('includePdfAttachmentDeliveryOrder', e.target.checked);
                if(!e.target.checked){
                  await setFieldValue('includePdfAttachmentPhoto', false);
                }
              }}
            >{fields.includePdfAttachmentDeliveryOrder.label}</Checkbox>

            <Checkbox
              checked={values?.includePdfAttachmentPhoto}
              disabled={!values.includePdfAttachmentDeliveryOrder}
              onChange={async (e: any) => {
                await setFieldValue('includePdfAttachmentPhoto', e.target.checked);
              }}
            >{fields.includePdfAttachmentPhoto.label}</Checkbox>

            <Checkbox
              checked={values?.includePdfAttachmentWeightChit}
              onChange={async (e: any) => {
                await setFieldValue('includePdfAttachmentWeightChit', e.target.checked);
              }}
            >{fields.includePdfAttachmentWeightChit.label}</Checkbox>
          </div>
        </Form.Group>
      </Col>
    </Row>
  }

  const noPageElements = () => {
    if(isNoPage()){
      return <div>There are no included pages</div>
    }
  }


  return <Offcanvas
    backdrop={'static'}
    scroll={false}
    placement={'end'}
    show={show}
    onHide={() => {
      onCancel();
    }}
    style={{ width: isCompleted() ? '90%' : '50%' }}
  >
    <FormikContext.Provider value={formik}>
      <Offcanvas.Header id={'printpreview_header_ID'} closeButton>
        <Offcanvas.Title>Print Preview</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Spin spinning={
          isLoading || 
          isLoadingAccountBillingProfile
        }>
          <Row className='g-24'>
            {isCompleted() && <Col xs={12} lg={6}>
              {formElements()}
            </Col>}
            <Col xs={12} lg={isCompleted() ? 6 : 12}>
              <StyledPreview className={isNoPage() ? 'no-page' : ''}>
                <Preview />
                {noPageElements()}
              </StyledPreview>
            </Col>
          </Row>
        </Spin>
      </Offcanvas.Body>
      <Modal.Footer id={'printpreview_footer_ID'} className='border-top'>{footerElements()}</Modal.Footer>
    </FormikContext.Provider>
  </Offcanvas>
}

export default PageComponent;
