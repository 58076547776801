import React, { ReactNode } from 'react';
import _ from 'lodash';
import moment from 'moment';

import {
  Row,
  Col,
} from 'react-bootstrap';
import styled from 'styled-components';

import { Image, List, Divider } from 'antd';

// Auth
// import { useAuthContext } from '../../../auth/use-auth-context.js';
// import { useRouter } from '../../../auth/auth-guard';

// import Select from '../../../../components/select/Select.tsx';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../utils/store.tsx';
import slice, { fields, useFormikContext, stringToHtmlTrim, findSettings, findSettingsItem, getAccountDocSettingVariables, getAccountDocSettingItem, getAccountDocSettingItemWeightChit, getAccountDocSettingVariablesWeightChit, getSiteContact } from './slice.tsx';
import { BinActivity, isStandardService } from '../../slice.tsx';
import { toFixedDefaultUom } from '../../form/weightChit/slice.tsx';
import { getFirstCustomerSite, getFirstCustomFields, getBinIn, getBinOut, getBinAll, isExchange1Type } from '../../../manage/jobs/jobTypes/slice.tsx';

import { getCurrency, formatNumber, dateFormat, dateTimeFormat, getDefaultUOMv4 } from '../../../../utils/utils.tsx';
import { WeighChitLayout, AccountDocSettingVariables, ServiceType, ReportUOM } from '../../../../utils/enums.tsx';

// import { ReactComponent as XIcon } from "../../../../../v5/assets/icons/x.svg";

import DefaultNoPhoto from "../../../../../v5/assets/img/default_no_photo.png";

// ----------------------------------------------------------------------

const StyledList = styled(List)`
    border: 1px solid var(--bs-gray-300);

    .ant-list-item {
        border-block-end: 1px solid var(--bs-gray-300);
    }
`;

const StyledListNoBorder = styled(List)`
    border: 0px;

    .ant-list-item {
        border-block-end: 0px;
        padding: 4px 4px;
    }
`;

const StyledTable = styled.table`
  width: 100%;
  
  thead {
    tr {
      th {
        background-color: var(--bs-blue-focus1);
        padding: 6px 16px;
            
        font-size: 11px;
        font-weight: 600;
        line-height: 16px;
        color: var(--bs-gray-800);
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: top;
        padding: 6px 16px;
            
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        color: var(--bs-gray-900);
      }
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 6px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 6px;
    }
  }
`;

// ----------------------------------------------------------------------

const PreviewComponent = (props: any) => {
    // const { user }: any = useAuthContext();
    // const { userAccount } = user ?? {};
    // const someField = userAccount?.someField;

    // const router = useRouter();

    const { isLoading, show, form, accountBillingProfile } = useTypedSelector((state: RootState) => state.jobFormPrintPreviewSlice);
    const dispatch = useDispatch<AppDispatch>();


    //   React.useEffect(() => {
    //     if(show){
        
    //     }
    //   }, [show, form])
  
    
    const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = useFormikContext();
  

    /* HEADER & FOOTER */
    const headerDefaultElements = () => {
        let logoSrc: any = getAccountDocSettingItem(
            accountBillingProfile,
            "StringCompanyLogo",
            AccountDocSettingVariables.String,
            DefaultNoPhoto
        ) || '';

        return <Row className='align-items-center'>
            <Col xs={'auto'}>
                <Image
                    preview={false}
                    width={200}
                    alt={'Company Logo'}
                    src={logoSrc}
                />
            </Col>
            <Col xs={true} className={'rich-text-parse-texts'}>
                {stringToHtmlTrim(
                    getAccountDocSettingItem(
                        accountBillingProfile,
                        "StringHeaderText",
                        AccountDocSettingVariables.String,
                        ""
                    )
                )}
            </Col>
        </Row>
    }
    const headerReceiptElements = () => {
        return <Row className='align-items-center'>
            <Col xs={true} className={'rich-text-parse-texts'}>
                {stringToHtmlTrim(
                    getAccountDocSettingItemWeightChit(
                        accountBillingProfile,
                        "StringHeaderText",
                        AccountDocSettingVariables.String,
                        ""
                    )
                )}
            </Col>
        </Row>
    }
    const footerElements = () => {
        let canShow = getAccountDocSettingItem(accountBillingProfile, "ShowDisclaimer", AccountDocSettingVariables.Show, false) ? true : false;

        if(canShow){
            return <Row className='align-items-center'>
                <Col xs={12}><Divider /></Col>
                <Col xs={true} className={'rich-text-parse-texts'}>
                    {stringToHtmlTrim(
                        getAccountDocSettingItem(
                            accountBillingProfile,
                            "StringDisclaimerText",
                            AccountDocSettingVariables.String,
                            ""
                        )
                    )}
                </Col>
            </Row>
        }
    }
    const footerWeightChitElements = () => {
        return <Row className='align-items-center'>
            <Col xs={12}><Divider /></Col>
            <Col xs={true} className={'rich-text-parse-texts'}>
                {stringToHtmlTrim(
                    getAccountDocSettingItemWeightChit(
                        accountBillingProfile,
                        "StringDisclaimerText",
                        AccountDocSettingVariables.String,
                        ""
                    )
                )}
            </Col>
        </Row>
    }
    /* END HEADER & FOOTER */


    /* PAGES */
    const page1 = () => {
        let customerSite = getFirstCustomerSite(form);
        let siteContact = getSiteContact(customerSite);

        let binOut: any = getBinOut(form);
        let binIn: any = getBinIn(form);

        let dataSourceList: any = [
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowCustomerName'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowCustomerName", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelCustomer_Name", AccountDocSettingVariables.Label, "Customer Name"),
                value: (form?.customer && form?.customer.customerName) ? form?.customer.customerName : '',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowCustomerAddress'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowCustomerAddress", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelCustomer_Address", AccountDocSettingVariables.Label, "Customer Address"),
                value: (form?.customer && form?.customer.billingAddress) ? form?.customer.billingAddress : '',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowProjectSite'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowProjectSite", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelProject_Site", AccountDocSettingVariables.Label, "Project Site"),
                value: (customerSite && customerSite?.address) ? customerSite?.address : '',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowJobType'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowJobType", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelJob_Type", AccountDocSettingVariables.Label, "Job Type"),
                value: (form?.jobTemplate && form?.jobTemplate.jobTemplateName) ? form?.jobTemplate.jobTemplateName : '',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowJobCompletion'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowJobCompletion", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelJob_Completion", AccountDocSettingVariables.Label, "Job Completion"),
                value: (form?.jobAttemptCompletedDate) ? moment.utc(form?.jobAttemptCompletedDate).format("DD-MMM-YYYY hh:mm A") : '',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowDriver'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowDriver", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelDriver", AccountDocSettingVariables.Label, "Driver"),
                value: form?.driverName || '',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowVehicle'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowVehicle", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelVehicle", AccountDocSettingVariables.Label, "Vehicle"),
                value: form?.vehicleName || '',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowSiteContact'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowSiteContact", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelSite_Contact", AccountDocSettingVariables.Label, "Site Contacts"),
                value: siteContact || '',
            },
        ];

        if(binOut && binOut.length > 0){
            dataSourceList.push({
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowBinNumberOUT'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowBinNumberOUT", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelBin_Number_OUT", AccountDocSettingVariables.Label, "Bin OUT"),
                value: binOut.map((item: any, i: number) => item?.binType?.binTypeName).join(', '),
                // value: <>
                //     {binOut.map((item: any, i: number) => {
                //         return <div key={'printpreview_page1_binout_item_' + i}>{item?.binType?.binTypeName}</div>
                //     })}
                // </>,
            });
        }
        if(binIn && binIn.length > 0){
            dataSourceList.push({
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowBinNumberIN'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowBinNumberIN", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelBin_Number_IN", AccountDocSettingVariables.Label, "Bin IN"),
                value: binIn.map((item: any, i: number) => item?.binType?.binTypeName).join(', '),
                // value: <>
                //     {binIn.map((item: any, i: number) => {
                //         return <div key={'printpreview_page1_binin_item_' + i}>{item?.binType?.binTypeName}</div>
                //     })}
                // </>,
            });
        }

        if(form?.customer?.billingProfile?.isConnectQuickbook){
            dataSourceList.push({
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowPaymentTerms'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowPaymentTerms", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelPayment_Terms", AccountDocSettingVariables.Label, "Payment Terms"),
                value: (form?.customer && form?.customer.paymentTerms) ? form?.customer.paymentTerms : '',
            });
        } else if(form?.customer?.billingProfile?.isConnectXero){
            dataSourceList.push({
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowPaymentTerms'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowPaymentTerms", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelPayment_Terms", AccountDocSettingVariables.Label, "Payment Terms"),
                value: (form?.customer && form?.customer.xeroPaymentTerm) ? form?.customer.xeroPaymentTerm : '',
            });
        } else {
            dataSourceList.push({
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowPaymentTerms'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowPaymentTerms", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelPayment_Terms", AccountDocSettingVariables.Label, "Payment Terms"),
                value: '',
            });
        }

        if(form?.isRequirePaymentCollection === true){
            dataSourceList.push({
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAmountCollected'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowAmountCollected", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItem(accountBillingProfile, "LabelCollections", AccountDocSettingVariables.Label, "Collected"),
                value: (form?.amountCollected) ? getCurrency() + formatNumber(form?.amountCollected) : '',
            });
        }

        dataSourceList = dataSourceList.sort((a: any, b: any) => a.order - b.order);



        /* STANDARD ITEMS */
        let dataSourceTableHeadStandard: any = [
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesBinType'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesBinType", AccountDocSettingVariables.Show, false),
                value: 'Bin Type',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesWasteType'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesWasteType", AccountDocSettingVariables.Show, false),
                value: 'Waste Type',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesQty'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesQty", AccountDocSettingVariables.Show, false),
                value: 'Qty',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesRate'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesRate", AccountDocSettingVariables.Show, false),
                value: 'Rate',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesPrice'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesPrice", AccountDocSettingVariables.Show, false),
                value: 'Price',
            },
        ];
        dataSourceTableHeadStandard = dataSourceTableHeadStandard.sort((a: any, b: any) => a.order - b.order);

        let dataSourceTableBodyStandard: any = [];
        let standardItems = form && form.pricings && (form.pricings.filter((x: any) => isStandardService(x)).length > 0) ? form.pricings.filter((x: any) => isStandardService(x)) : [];
        if(standardItems && standardItems.length > 0){
            standardItems.forEach((item: any) => {
                let dataSourceTableBodyRowStandard: any = [
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesBinType'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesBinType", AccountDocSettingVariables.Show, false),
                        value: item?.binType?.binTypeName,
                    },
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesWasteType'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesWasteType", AccountDocSettingVariables.Show, false),
                        value: item?.wasteType?.wasteTypeName,
                    },
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesQty'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesQty", AccountDocSettingVariables.Show, false),
                        value: item.quantity,
                    },
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesRate'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesRate", AccountDocSettingVariables.Show, false),
                        value: getCurrency() + formatNumber(item?.price),
                    },
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowStandardServicesPrice'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowStandardServicesPrice", AccountDocSettingVariables.Show, false),
                        value: getCurrency() + formatNumber((item?.quantity * item?.price)),
                    },
                ];
                dataSourceTableBodyRowStandard = dataSourceTableBodyRowStandard.sort((a: any, b: any) => a.order - b.order);
                dataSourceTableBodyStandard.push(dataSourceTableBodyRowStandard);
            });
        }
        /* END STANDARD ITEMS */



        /* ADDITIONAL ITEMS */
        let dataSourceTableHeadAdditional: any = [
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesDescription'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesDescription", AccountDocSettingVariables.Show, false),
                value: 'Additional Service',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesQty'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesQty", AccountDocSettingVariables.Show, false),
                value: 'Qty',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesRate'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesRate", AccountDocSettingVariables.Show, false),
                value: 'Rate',
            },
            {
                order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesPrice'), null, 'sortOrder'),
                show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesPrice", AccountDocSettingVariables.Show, false),
                value: 'Price',
            },
        ];
        dataSourceTableHeadAdditional = dataSourceTableHeadAdditional.sort((a: any, b: any) => a.order - b.order);

        let dataSourceTableBodyAdditional: any = [];
        let additionalItems = form && form.pricings && (form.pricings.filter((x: any) => x.serviceType === ServiceType.AdditionalService).length > 0) ? form.pricings.filter((x: any) => x.serviceType === ServiceType.AdditionalService) : [];
        if(additionalItems && additionalItems.length > 0){
            additionalItems.forEach((item: any) => {
                let dataSourceTableBodyRowAdditional: any = [
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesDescription'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesDescription", AccountDocSettingVariables.Show, false),
                        value: item?.serviceTag,
                    },
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesQty'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesQty", AccountDocSettingVariables.Show, false),
                        value: item?.quantity,
                    },
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesRate'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesRate", AccountDocSettingVariables.Show, false),
                        value: getCurrency() + formatNumber(item?.price),
                    },
                    {
                        order: findSettingsItem(findSettings(getAccountDocSettingVariables(accountBillingProfile), 'ShowAdditionalServicesPrice'), null, 'sortOrder'),
                        show: getAccountDocSettingItem(accountBillingProfile, "ShowAdditionalServicesPrice", AccountDocSettingVariables.Show, false),
                        value: getCurrency() + formatNumber((item?.quantity * item?.price)),
                    },
                ];
                dataSourceTableBodyRowAdditional = dataSourceTableBodyRowAdditional.sort((a: any, b: any) => a.order - b.order);
                dataSourceTableBodyAdditional.push(dataSourceTableBodyRowAdditional);
            });
        }
        /* END ADDITIONAL ITEMS */


        return <Row className='g-12 align-items-center'>
            <Col xs={12} className='px-16 py-8' style={{ borderRadius: '6px', backgroundColor: 'var(--bs-blue-focus1)' }}>
                <Row className='g-12 align-items-center'>
                    <Col xs={'auto'} className='fs-12 medium gray-700'>
                        {getAccountDocSettingItem(
                            accountBillingProfile,
                            "LabelDELIVERY_ORDER",
                            AccountDocSettingVariables.Label,
                            ""
                        )}
                    </Col>
                    <Col xs={true} className='fs-12 medium gray-900'>{form?.jobNumber}</Col>
                </Row>
            </Col>


            <Col xs={12}>
                <StyledList
                    size="small"
                    bordered
                    dataSource={dataSourceList}
                    renderItem={(item: any) => {
                        if(item.show){
                            return <List.Item>
                                <Row className='g-16 w-100'>
                                    <Col xs={3} className='fs-11 medium gray-700'>{item?.label}</Col>
                                    <Col xs={'auto'} className='fs-11 medium gray-700'>:</Col>
                                    <Col xs={true} className='fs-12 regular gray-900'>{item?.value}</Col>
                                </Row>
                            </List.Item>
                        }
                    }}
                />
            </Col>


            {(dataSourceTableBodyStandard && dataSourceTableBodyStandard.length > 0) && <>
                <Col xs={12} className='mt-24'>
                    <StyledTable>
                        <thead>
                            <tr>
                                <th>No</th>
                                {dataSourceTableHeadStandard.map((item: any, index: number) => {
                                    return <th key={'printpreview_standard_head_item_' + index}>{item.value}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dataSourceTableBodyStandard.map((row: any, rowIndex: number) => {
                                return <tr key={'printpreview_standard_body_row_' + rowIndex}>
                                    <td>{rowIndex+1}</td>
                                    {row.map((item: any, index: number) => {
                                        return <td key={'printpreview_standard_body_row'  + rowIndex + '_Item_' + index}>{item.value}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </StyledTable>
                </Col>
            </>}

            {(dataSourceTableBodyAdditional && dataSourceTableBodyAdditional.length > 0) && <>
                <Col xs={12} className='mt-24'>
                    <StyledTable>
                        <thead>
                            <tr>
                                <th>No</th>
                                {dataSourceTableHeadAdditional.map((item: any, index: number) => {
                                    return <th key={'printpreview_additional_head_item_' + index}>{item.value}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dataSourceTableBodyAdditional.map((row: any, rowIndex: number) => {
                                return <tr key={'printpreview_additional_body_row_' + rowIndex}>
                                    <td>{rowIndex+1}</td>
                                    {row.map((item: any, index: number) => {
                                        return <td key={'printpreview_additional_body_row'  + rowIndex + '_Item_' + index}>{item.value}</td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </StyledTable>
                </Col>
            </>}


            {getAccountDocSettingItem(
                accountBillingProfile,
                "ShowMessageToCustomer",
                AccountDocSettingVariables.Show,
                false
            ) && <Col xs={12} className='mt-24'>
                {stringToHtmlTrim(getAccountDocSettingItem(
                    accountBillingProfile,
                    "StringMessageToCustomer",
                    AccountDocSettingVariables.String,
                    ""
                ))}
            </Col>}


            {getAccountDocSettingItem(
                accountBillingProfile,
                "ShowSignature",
                AccountDocSettingVariables.Show,
                DefaultNoPhoto
            ) && <>
                <Col xs={true}></Col>
                <Col xs={5}>
                    <Row className='align-items-center'>
                        <Col xs={12} className='text-center'>
                            {(form && form.jobSignatureUrl && form.jobSignatureUrl.length > 0) && <>
                                {form.jobSignatureUrl.map((item: any, index: number) => {
                                    return <Image key={'printpreview_signature_photo_item_' + index}
                                        preview={false}
                                        width={200}
                                        alt={item?.name}
                                        src={item?.url}
                                    />
                                })}
                            </>}
                        </Col>
                        <Col xs={12}><Divider /></Col>
                        <Col xs={12} className='text-center'>
                            {(form?.jobSignedUserName ? form?.jobSignedUserName + ' ' : '') + (form?.jobSignedUserContact ? '(' + form?.jobSignedUserContact + ')': '')}
                        </Col>
                    </Row>
                </Col>
            </>}
        </Row>
    }
    const page2 = () => {
        return <Row className='g-64 align-items-center'>
            <Col xs={12}>
                <b>{getAccountDocSettingItem(
                    accountBillingProfile,
                    "LabelPROOF_OF_DELIVERY",
                    AccountDocSettingVariables.Label,
                    ""
                )}</b>
            </Col>

            {
                (form && form.photoAttachmentsUrl && form.photoAttachmentsUrl.length > 0)
                ?
                <>
                    {form.photoAttachmentsUrl.map((item: any, index: number) => {
                        return <Col xs={6} key={'printpreview_photo_item_' + index}>
                            <Image
                                preview={false}
                                alt={item?.name}
                                src={item?.url}
                            />
                        </Col>
                    })}
                </>
            :
                <>
                    <Col xs={6}>
                        <Image
                            preview={false}
                            alt={'No Image'}
                            src={DefaultNoPhoto}
                        />
                    </Col>
                </>    
            }
            
        </Row>
    }
    const page3Default = () => {
        let customFields = getFirstCustomFields(form);
        let customerSite = getFirstCustomerSite(form);
        let isExchange1 = isExchange1Type(form?.stepGroups);

        let bins = isExchange1 ? getBinIn(form) : getBinAll(form);

        let price: any = 0;
        if(values.pricings && values.pricings.length > 0){
            let index = values.pricings.findIndex((x: any) => x.useBinWeightQuantity == true);
            if(index > -1){
                price = values.pricings[index].price;
            }
        }

        let bin = null;
        let weightChitDateTime = '';
        let weightChitOutDateTime = '';
        if(bins && bins.length > 0){
            bin = bins[0];

            if(bin && bin.weightChitDateTime){
                let wcDate = moment(bin.weightChitDateTime).format(dateFormat());
                let wcTime = moment(bin.weightChitDateTime).format(dateTimeFormat());
                weightChitDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
            }

            if(bin && bin.weightChitOutDateTime){
                let wcDate = moment(bin.weightChitOutDateTime).format(dateFormat());
                let wcTime = moment(bin.weightChitOutDateTime).format(dateTimeFormat());
                weightChitOutDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
            }
        }

        let weighChitBillableWeight = parseFloat(bin?.billableBinWeight || 0);
        let rate = parseFloat(price);
        let amount = weighChitBillableWeight * rate;


        let dataSourceList: any = [
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerName'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerName'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowCustomerName", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelCustomer_Name", AccountDocSettingVariables.Label, "Customer Name"),
                value: (form?.customer && form?.customer.customerName) ? form?.customer.customerName : '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerAddress'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerAddress'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowCustomerAddress", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelCustomer_Address", AccountDocSettingVariables.Label, "Customer Address"),
                value: (form?.customer && form?.customer.billingAddress) ? form?.customer.billingAddress : '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowSiteAddress'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowSiteAddress'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowSiteAddress", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelSite_Address", AccountDocSettingVariables.Label, "Site Site"),
                value: (customerSite && customerSite?.address) ? customerSite?.address : '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDONumber'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDONumber'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowDONumber", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelDO_Number", AccountDocSettingVariables.Label, "DO Number"),
                value: form?.jobNumber,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobCompletion'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobCompletion'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowJobCompletion", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelJob_Completion", AccountDocSettingVariables.Label, "Job Completion"),
                value: (form?.jobAttemptCompletedDate) ? moment.utc(form?.jobAttemptCompletedDate).format("DD-MMM-YYYY hh:mm A") : '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobType'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobType'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowJobType", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelJob_Type", AccountDocSettingVariables.Label, "Job Type"),
                value: (form?.jobTemplate && form?.jobTemplate.jobTemplateName) ? form?.jobTemplate.jobTemplateName : '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighTimestamp'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighTimestamp'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWeighTimestamp", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWeigh_Timestamp", AccountDocSettingVariables.Label, "Weigh Timestamp"),
                value: weightChitDateTime,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighOutTimestamp'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighOutTimestamp'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWeighOutTimestamp", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWeigh_Out_Timestamp", AccountDocSettingVariables.Label, "Weigh Out Timestamp"),
                value: weightChitOutDateTime,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDriver'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDriver'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowDriver", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelDriver", AccountDocSettingVariables.Label, "Driver"),
                value: form?.driverName || '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowVehicle'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowVehicle'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowVehicle", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelVehicle", AccountDocSettingVariables.Label, "Vehicle"),
                value: form?.vehicleName || '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighBy'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighBy'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWeighBy", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWeigh_By", AccountDocSettingVariables.Label, "Weigh By"),
                value: bin?.binWeighBy || '',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRemarks'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRemarks'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowRemarks", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelRemarks", AccountDocSettingVariables.Label, "Remarks"),
                value: bin?.weightChitRemarks || '',
            },
        ];

        if(bins && bins.length > 0){
            dataSourceList.push({
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBinINType'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBinINType'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowBinINType", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelBin_IN_Type", AccountDocSettingVariables.Label, "Bin IN Type"),
                value: bins.map((item: any, i: number) => item?.binType?.binTypeName).join(', '),
                // value: <>
                //     {bins.map((item: any, i: number) => {
                //         return <div key={'printpreview_page3_default_bins_bintype_item_' + i}>{item?.binType?.binTypeName}</div>
                //     })}
                // </>,
            });
            dataSourceList.push({
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWasteINType'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWasteINType'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWasteINType", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWaste_IN_Type", AccountDocSettingVariables.Label, "Waste IN Type"),
                value: bins.map((item: any, i: number) => item?.wasteType?.wasteTypeName).join(', '),
                // value: <>
                //     {bins.map((item: any, i: number) => {
                //         return <div key={'printpreview_page3_default_bins_wastetype_item_' + i}>{item?.wasteType?.wasteTypeName}</div>
                //     })}
                // </>,
            });
        }
        
        
        // let binWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.binWeight, 0) : 0;
        // let tareBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.tareBinWeight, 0) : 0;
        // let netBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.netBinWeight, 0) : 0;
        // let nonBillableBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.nonBillableBinWeight, 0) : 0;
        // let billableBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.billableBinWeight, 0) : 0;

        if(customFields && customFields.length > 0){
            customFields.forEach((customItem: any) => {
                let val = (customItem.value) ? customItem.value : '';
                if(customItem.accountCustomField.label != ''){
                    dataSourceList.push({
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomField_' + customItem.accountCustomFieldId), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomField_' + customItem.accountCustomFieldId), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, 'ShowCustomField_' + customItem.accountCustomFieldId, AccountDocSettingVariables.Show, false),
                        label: customItem?.accountCustomField?.label,
                        value: val,
                    });
                }
            });
        }

        dataSourceList = dataSourceList.sort((a: any, b: any) => a.order - b.order);


        /* ITEMS */
        let dataSourceTableHead: any = [
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowGrossWeight'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowGrossWeight'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowGrossWeight", AccountDocSettingVariables.Show, false),
                value: 'Gross',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowTareWeight'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowTareWeight'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowTareWeight", AccountDocSettingVariables.Show, false),
                value: 'Tare',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNettWeight'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNettWeight'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowNettWeight", AccountDocSettingVariables.Show, false),
                value: 'Nett',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNonBillableWeight'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNonBillableWeight'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowNonBillableWeight", AccountDocSettingVariables.Show, false),
                value: 'Non-Billable',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBillableWeight'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBillableWeight'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowBillableWeight", AccountDocSettingVariables.Show, false),
                value: 'Billable',
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRate'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRate'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowRate", AccountDocSettingVariables.Show, false),
                value: 'Rate'
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowAmount'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowAmount'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowAmount", AccountDocSettingVariables.Show, false),
                value: 'Amount',
            },
        ];
        dataSourceTableHead = dataSourceTableHead.sort((a: any, b: any) => a.order - b.order);

        let dataSourceTableBody: any = [];
        if(bins && bins.length > 0){
            bins.forEach((item: any) => {
                let dataSourceTableBodyRow: any = [
                    {
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowGrossWeight'), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowGrossWeight'), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowGrossWeight", AccountDocSettingVariables.Show, false),
                        value: toFixedDefaultUom(item?.binWeight) + ' ' + ((getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'),
                    },
                    {
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowTareWeight'), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowTareWeight'), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowTareWeight", AccountDocSettingVariables.Show, false),
                        value: toFixedDefaultUom(item?.tareBinWeight) + ' ' + ((getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'),
                    },
                    {
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNettWeight'), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNettWeight'), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowNettWeight", AccountDocSettingVariables.Show, false),
                        value: toFixedDefaultUom(item?.netBinWeight) + ' ' + ((getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'),
                    },
                    {
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNonBillableWeight'), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNonBillableWeight'), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowNonBillableWeight", AccountDocSettingVariables.Show, false),
                        value: toFixedDefaultUom(item?.nonBillableBinWeight) + ' ' + ((getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'),
                    },
                    {
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBillableWeight'), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBillableWeight'), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowBillableWeight", AccountDocSettingVariables.Show, false),
                        value: toFixedDefaultUom(item?.billableBinWeight) + ' ' + ((getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'),
                    },
                    {
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRate'), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRate'), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowRate", AccountDocSettingVariables.Show, false),
                        value: getCurrency() + formatNumber(price),
                    },
                    {
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowAmount'), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowAmount'), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowAmount", AccountDocSettingVariables.Show, false),
                        value: getCurrency() + formatNumber((parseFloat(item?.nonBillableBinWeight || 0) * price)),
                    },
                ];
                dataSourceTableBodyRow = dataSourceTableBodyRow.sort((a: any, b: any) => a.order - b.order);
                dataSourceTableBody.push(dataSourceTableBodyRow);
            });
        }
        /* END ITEMS */


        return <Row className='g-12 align-items-center'>
            <Col xs={12} className='px-16 py-8' style={{ borderRadius: '6px', backgroundColor: 'var(--bs-blue-focus1)' }}>
                <Row className='g-12 align-items-center'>
                    <Col xs={'auto'} className='fs-12 medium gray-700'>
                        {getAccountDocSettingItemWeightChit(
                            accountBillingProfile,
                            "LabelWEIGH_TICKET",
                            AccountDocSettingVariables.Label,
                            ""
                        )}
                    </Col>
                    <Col xs={true} className='fs-12 medium gray-900'>{bin?.weightChitTicketNumber}</Col>
                </Row>
            </Col>


            <Col xs={12}>
                <StyledList
                    size="small"
                    bordered
                    dataSource={dataSourceList}
                    renderItem={(item: any) => {
                        if(item.show){
                            return <List.Item>
                                <Row className='g-16 w-100'>
                                    <Col xs={3} className='fs-11 medium gray-700'>{item?.label}</Col>
                                    <Col xs={'auto'} className='fs-11 medium gray-700'>:</Col>
                                    <Col xs={true} className='fs-12 regular gray-900'>{item?.value}</Col>
                                </Row>
                            </List.Item>
                        }
                    }}
                />
            </Col>


            {(dataSourceTableBody && dataSourceTableBody.length > 0) && <>
                <Col xs={12} className='mt-24'>
                    <StyledTable>
                        <thead>
                            <tr>
                                {dataSourceTableHead.map((item: any, index: number) => {
                                    if(item.show){
                                        return <th key={'printpreview_weightchit_head_item_' + index}>{item.value}</th>
                                    }
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {dataSourceTableBody.map((row: any, rowIndex: number) => {
                                return <tr key={'printpreview_weightchit_body_row_' + rowIndex}>
                                    {row.map((item: any, index: number) => {
                                        if(item.show){
                                            return <td key={'printpreview_weightchit_body_row'  + rowIndex + '_Item_' + index}>{item.value}</td>
                                        }
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </StyledTable>
                </Col>
            </>}


            {getAccountDocSettingItemWeightChit(
                accountBillingProfile,
                "ShowMessageToCustomer",
                AccountDocSettingVariables.Show,
                false
            ) && <Col xs={12} className='mt-24'>
                {stringToHtmlTrim(getAccountDocSettingItemWeightChit(
                    accountBillingProfile,
                    "StringMessageToCustomer",
                    AccountDocSettingVariables.String,
                    ""
                ))}
            </Col>}

        </Row>
    }
    const page3Receipt = () => {
        let customFields = getFirstCustomFields(form);
        let customerSite = getFirstCustomerSite(form);
        let isExchange1 = isExchange1Type(form?.stepGroups);

        let bins = isExchange1 ? getBinIn(form) : getBinAll(form);

        let price: any = 0;
        if(values.pricings && values.pricings.length > 0){
            let index = values.pricings.findIndex((x: any) => x.useBinWeightQuantity == true);
            if(index > -1){
                price = values.pricings[index].price;
            }
        }

        let bin = null;
        let weightChitDateTime = '';
        let weightChitOutDateTime = '';
        if(bins && bins.length > 0){
            bin = bins[0];

            if(bin && bin.weightChitDateTime){
                let wcDate = moment(bin.weightChitDateTime).format(dateFormat());
                let wcTime = moment(bin.weightChitDateTime).format(dateTimeFormat());
                weightChitDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
            }

            if(bin && bin.weightChitOutDateTime){
                let wcDate = moment(bin.weightChitOutDateTime).format(dateFormat());
                let wcTime = moment(bin.weightChitOutDateTime).format(dateTimeFormat());
                weightChitOutDateTime = moment(moment(wcDate + ' ' + wcTime)).format("DD-MMM-YYYY hh:mm A")
            }
        }

        let weighChitBillableWeight = parseFloat(bin?.billableBinWeight || 0);
        let rate = parseFloat(price);
        let amount = weighChitBillableWeight * rate;


        let dataSourceList: any = [
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerName'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerName'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowCustomerName", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelCustomer_Name", AccountDocSettingVariables.Label, "Customer Name"),
                value: (form?.customer && form?.customer.customerName) ? form?.customer.customerName : '',
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerAddress'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomerAddress'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowCustomerAddress", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelCustomer_Address", AccountDocSettingVariables.Label, "Customer Address"),
                value: (form?.customer && form?.customer.billingAddress) ? form?.customer.billingAddress : '',
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowSiteAddress'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowSiteAddress'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowSiteAddress", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelSite_Address", AccountDocSettingVariables.Label, "Site Site"),
                value: (customerSite && customerSite?.address) ? customerSite?.address : '',
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDONumber'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDONumber'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowDONumber", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelDO_Number", AccountDocSettingVariables.Label, "DO Number"),
                value: form?.jobNumber,
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobCompletion'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobCompletion'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowJobCompletion", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelJob_Completion", AccountDocSettingVariables.Label, "Job Completion"),
                value: (form?.jobAttemptCompletedDate) ? moment.utc(form?.jobAttemptCompletedDate).format("DD-MMM-YYYY hh:mm A") : '',
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobType'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowJobType'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowJobType", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelJob_Type", AccountDocSettingVariables.Label, "Job Type"),
                value: (form?.jobTemplate && form?.jobTemplate.jobTemplateName) ? form?.jobTemplate.jobTemplateName : '',
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighTimestamp'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighTimestamp'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWeighTimestamp", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWeigh_Timestamp", AccountDocSettingVariables.Label, "Weigh Timestamp"),
                value: weightChitDateTime,
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighOutTimestamp'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighOutTimestamp'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWeighOutTimestamp", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWeigh_Out_Timestamp", AccountDocSettingVariables.Label, "Weigh Out Timestamp"),
                value: weightChitOutDateTime,
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDriver'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowDriver'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowDriver", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelDriver", AccountDocSettingVariables.Label, "Driver"),
                value: form?.driverName || '',
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowVehicle'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowVehicle'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowVehicle", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelVehicle", AccountDocSettingVariables.Label, "Vehicle"),
                value: form?.vehicleName || '',
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighBy'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWeighBy'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWeighBy", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWeigh_By", AccountDocSettingVariables.Label, "Weigh By"),
                value: bin?.binWeighBy,
                showUnit: false,
                isBold: false,
            },
            {
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRemarks'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRemarks'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowRemarks", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelRemarks", AccountDocSettingVariables.Label, "Remarks"),
                value: bin?.weightChitRemarks,
                showUnit: false,
                isBold: false,
            },
        ];

        if(bins && bins.length > 0){
            dataSourceList.push({
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBinINType'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBinINType'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowBinINType", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelBin_IN_Type", AccountDocSettingVariables.Label, "Bin IN Type"),
                value: bins.map((item: any, i: number) => item?.binType?.binTypeName).join(', '),
                // value: <>
                //     {bins.map((item: any, i: number) => {
                //         return <div key={'printpreview_page3_receipt_bins_bintype_item_' + i}>{item?.binType?.binTypeName}</div>
                //     })}
                // </>,
                showUnit: false,
                isBold: false,
            });
            dataSourceList.push({
                group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWasteINType'), null, 'group'),
                order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowWasteINType'), null, 'sortOrder'),
                show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowWasteINType", AccountDocSettingVariables.Show, false),
                label: getAccountDocSettingItemWeightChit(accountBillingProfile, "LabelWaste_IN_Type", AccountDocSettingVariables.Label, "Waste IN Type"),
                value: bins.map((item: any, i: number) => item?.wasteType?.wasteTypeName).join(', '),
                // value: <>
                //     {bins.map((item: any, i: number) => {
                //         return <div key={'printpreview_page3_receipt_bins_wastetype_item_' + i}>{item?.wasteType?.wasteTypeName}</div>
                //     })}
                // </>,
                showUnit: false,
                isBold: false,
            });
        }
        
        let binWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.binWeight, 0) : 0;
        let tareBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.tareBinWeight, 0) : 0;
        let netBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.netBinWeight, 0) : 0;
        let nonBillableBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.nonBillableBinWeight, 0) : 0;
        let billableBinWeightTotal = (bins && bins.length > 0) ? bins.reduce((sum: any, bin: any) => sum + bin.billableBinWeight, 0) : 0;

        if(customFields && customFields.length > 0){
            customFields.forEach((customItem: any) => {
                let val = (customItem.value) ? customItem.value : '';
                if(customItem.accountCustomField.label != ''){
                    dataSourceList.push({
                        group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomField_' + customItem.accountCustomFieldId), null, 'group'),
                        order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowCustomField_' + customItem.accountCustomFieldId), null, 'sortOrder'),
                        show: getAccountDocSettingItemWeightChit(accountBillingProfile, 'ShowCustomField_' + customItem.accountCustomFieldId, AccountDocSettingVariables.Show, false),
                        label: customItem?.accountCustomField?.label,
                        value: val,
                        showUnit: true,
                    });
                }
            });
        }


        dataSourceList.push({
            group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowGrossWeight'), null, 'group'),
            order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowGrossWeight'), null, 'sortOrder'),
            show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowGrossWeight", AccountDocSettingVariables.Show, false),
            label: 'Gross',
            value: toFixedDefaultUom(binWeightTotal),
            showUnit: true,
            isBold: false,
        });
        dataSourceList.push({
            group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowTareWeight'), null, 'group'),
            order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowTareWeight'), null, 'sortOrder'),
            show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowTareWeight", AccountDocSettingVariables.Show, false),
            label: 'Tare',
            value: toFixedDefaultUom(tareBinWeightTotal),
            showUnit: true,
            isBold: false,
        });
        dataSourceList.push({
            group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNettWeight'), null, 'group'),
            order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNettWeight'), null, 'sortOrder'),
            show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowNettWeight", AccountDocSettingVariables.Show, false),
            label: 'Nett',
            value: toFixedDefaultUom(netBinWeightTotal),
            showUnit: true,
            isBold: true,
        });
        dataSourceList.push({
            group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNonBillableWeight'), null, 'group'),
            order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowNonBillableWeight'), null, 'sortOrder'),
            show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowNonBillableWeight", AccountDocSettingVariables.Show, false),
            label: 'Non-Billable',
            value: toFixedDefaultUom(nonBillableBinWeightTotal),
            showUnit: true,
            isBold: false,
        });
        dataSourceList.push({
            group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBillableWeight'), null, 'group'),
            order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowBillableWeight'), null, 'sortOrder'),
            show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowBillableWeight", AccountDocSettingVariables.Show, false),
            label: 'Billable',
            value: toFixedDefaultUom(billableBinWeightTotal),
            showUnit: true,
            isBold: false,
        });
        dataSourceList.push({
            group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRate'), null, 'group'),
            order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowRate'), null, 'sortOrder'),
            show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowRate", AccountDocSettingVariables.Show, false),
            label: 'Rate',
            value: getCurrency() + formatNumber(price),
            showUnit: true,
            isBold: false,
        });
        dataSourceList.push({
            group: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowAmount'), null, 'group'),
            order: findSettingsItem(findSettings(getAccountDocSettingVariablesWeightChit(accountBillingProfile), 'ShowAmount'), null, 'sortOrder'),
            show: getAccountDocSettingItemWeightChit(accountBillingProfile, "ShowAmount", AccountDocSettingVariables.Show, false),
            label: 'Amount',
            value: getCurrency() + formatNumber(amount),
            showUnit: true,
            isBold: false,
        });

        dataSourceList.sort((a: any, b: any) => {
            if (a.group !== b.group) {
                return a.group - b.group;
            }
            return a.order - b.order;
        });
        
        return <Row className='g-12 align-items-center'>
            <Col xs={12} className='px-8 py-8'>
                <Row className='g-16 align-items-center w-100'>
                    <Col xs={4} className='fs-11 bold gray-900'>
                        {getAccountDocSettingItemWeightChit(
                            accountBillingProfile,
                            "LabelWEIGH_TICKET",
                            AccountDocSettingVariables.Label,
                            ""
                        )}
                    </Col>
                    <Col xs={true} className='fs-12 bold gray-900'>{bin?.weightChitTicketNumber}</Col>
                </Row>
            </Col>


            <Col xs={12} className='mt-0'>
                <StyledListNoBorder
                    size="small"
                    dataSource={dataSourceList}
                    renderItem={(item: any) => {
                        if(item.show){
                            return <List.Item>
                                <Row className='g-16 w-100'>
                                    <Col xs={4} className={item?.isBold ? 'fs-11 bold gray-900' : 'fs-11 medium gray-700'}>{item?.label}</Col>
                                    <Col xs={item?.showUnit ? 4 : true} className={item?.isBold ? 'fs-12 bold gray-900' : 'fs-12 regular gray-900'}>{item?.value}</Col>
                                    {item?.showUnit && <Col xs={true} className={item?.isBold ? 'fs-12 bold gray-900' : 'fs-12 regular gray-900'}>{(getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'}</Col>}
                                </Row>
                            </List.Item>
                        }
                    }}
                />
            </Col>

            {/* <Col xs={12} className='mt-24'>
                <StyledListNoBorder
                    size="small"
                    dataSource={dataSourceListWeight}
                    renderItem={(item: any) => {
                        if(item.show){
                            return <List.Item>
                                <Row className='g-16 w-100'>
                                    <Col xs={4} className={item?.isBold ? 'fs-11 bold gray-900' : 'fs-11 medium gray-700'}>{item?.label}</Col>
                                    <Col xs={item?.showUnit ? 4 : true} className={item?.isBold ? 'fs-12 bold gray-900' : 'fs-12 regular gray-900'}>{item?.value}</Col>
                                    {item?.showUnit && <Col xs={true} className={item?.isBold ? 'fs-12 bold gray-900' : 'fs-12 regular gray-900'}>{(getDefaultUOMv4() === ReportUOM.kg) ? 'KG' : 'TONS'}</Col>}
                                </Row>
                            </List.Item>
                        }
                    }}
                />
            </Col> */}


            {getAccountDocSettingItemWeightChit(
                accountBillingProfile,
                "ShowMessageToCustomer",
                AccountDocSettingVariables.Show,
                false
            ) && <Col xs={12} className='mt-24'>
                {stringToHtmlTrim(getAccountDocSettingItemWeightChit(
                    accountBillingProfile,
                    "StringMessageToCustomer",
                    AccountDocSettingVariables.String,
                    ""
                ))}
            </Col>}

        </Row>
    }
    /* END PAGES */


    /* LAYOUT */
    const page1Elements = () => {
        if(values.includePdfAttachmentDeliveryOrder){
            return <div className='page'>
                <Row className='g-24 align-items-center'>
                    <Col xs={12}>{headerDefaultElements()}</Col>
                    <Col xs={12}>{page1()}</Col>
                    <Col xs={12}>{footerElements()}</Col>
                </Row>
            </div>
        }
    }
    const page2Elements = () => {
        if(values.includePdfAttachmentDeliveryOrder && values.includePdfAttachmentPhoto){
            return <div className='page'>
                <Row className='g-24 align-items-center'>
                    <Col xs={12}>{page2()}</Col>
                    <Col xs={12}>{footerElements()}</Col>
                </Row>
            </div>
        }
    }
    const page3DefaultElements = () => {
        if(values.includePdfAttachmentWeightChit){
            return <div className='page'>
                <Row className='g-24 align-items-center'>
                    <Col xs={12}>{headerDefaultElements()}</Col>
                    <Col xs={12}>{page3Default()}</Col>
                    <Col xs={12}>{footerWeightChitElements()}</Col>
                </Row>
            </div>
        }
    }
    const page3ReceiptElements = () => {
        if(values.includePdfAttachmentWeightChit){
            return <div className='page page-receipt'>
                <Row className='g-24 align-items-center'>
                    <Col xs={12}>{headerReceiptElements()}</Col>
                    <Col xs={12}>{page3Receipt()}</Col>
                    <Col xs={12}>{footerWeightChitElements()}</Col>
                </Row>
            </div>
        }
    }
    /* END LAYOUT */


    const previewElements = () => {
        return <div className='wrapper'>
            {page1Elements()}
            {page2Elements()}
            {(values?.layout === WeighChitLayout.Receipt) ? page3ReceiptElements() : page3DefaultElements()}
        </div>
    }


    return <>
        {previewElements()}
    </>
}

export default PreviewComponent;
