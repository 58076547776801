/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-loop-func */
import React from 'react';
// import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

// import {  } from '../../../manage/jobs/jobTypes/slice';

import { axios } from '../../../../../v5/utils/axios';
import { success, successAPI, errorAPI, getMaxPageSize, isNumeric, getDefaultUOMv4, getBillingUOMv4 } from '../../../../../v5/utils/utils';
// import Yup from '../../../../../v5/utils/yup';
// import {  } from '../../../../../v5/utils/enums';


interface InitState {
  show: boolean,
  details: any,
  
  isLoading: boolean,
  currentVersion: any,
  previousVersion: any,
}

function NewReducer() {
  const name = 'jobFormAuditTrailSlice';


  const initialState: InitState = {
    show: false,
    details: null,

    isLoading: false,
    currentVersion: null,
    previousVersion: null,
  };


  const reducers = {
    setShow: (state: InitState, action: PayloadAction<{ show: boolean, details: any }>) => {
      state.show = action.payload.show;
      state.details = action.payload.details;
    },

    startCompareHistory: (state: InitState) => {
      state.isLoading = true;
      state.currentVersion = null;
      state.previousVersion = null;
    },
    finishCompareHistory: (state: InitState, action: PayloadAction<any>) => {
      let currentVersion = (action.payload && action.payload.currentVersion) ? action.payload.currentVersion : null;
      let previousVersion = (action.payload && action.payload.previousVersion) ? action.payload.previousVersion : null;

      state.currentVersion = currentVersion;
      state.previousVersion = previousVersion;
      state.isLoading = false;
    },
  };


  const apis = {
    callCompareHistoryApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCompareHistory());

      await axios.post('job/compare-history', params).then(result => {
        let data = result.data;
        
        successAPI(data);

        callback(true, data);
        dispatch(actions.finishCompareHistory(data));
      }).catch(error => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishCompareHistory(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();