import _ from 'lodash';

import { info } from '../utils';

// DOM ----------------------------------------------------------------------

export const scrollTo = (selector: string = '', time: number = 100) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, time);
}
export const scrollInElement = (containerSelector: string, targetSelector: string, time: number = 100) => {
  setTimeout(() => {
    const container = document.querySelector(containerSelector) as HTMLElement;
    const target = document.querySelector(targetSelector) as HTMLElement;

    if (container && target) {
      container.scrollTo({
        top: target.offsetTop - container.offsetTop,
        behavior: 'smooth',
      });
    }
  }, time);
};
export const jumpTo = (selector: string = '', time: number = 100) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollIntoView({ behavior: 'instant' });
    }
  }, time);
}
export const scrollToTop = (selector: string = '', time: number = 100) => {
  setTimeout(() => {
    const element = document.querySelector(selector);
    if (element) {
      element.scrollTop = 0;
    }
  }, time);
}
export const autoFoucus = (selector: string = '', time: number = 100) => {
  setTimeout(() => {
    const element = document.querySelector(selector) as HTMLInputElement;
    if (element) {
      element.focus();
    }
  }, time);
}
export const copyToClipboard = (text: string) => {
  navigator.clipboard.writeText(text).then(() => info('Copied to clipboard', text));
};
export const clickOutside = (el = 'body') => {
  try {
    setTimeout(() => {
      const element: any = document.querySelector(el);
      element.click();
    }, 200);
  } catch (e) {}
};
