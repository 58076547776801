import React, { ReactNode } from 'react';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

// import {
//   Row,
//   Col,
//   Card,
// } from 'react-bootstrap';
// import styled from 'styled-components';
// import { up, down } from 'styled-breakpoints';

import { Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../../v5/auth/use-auth-context.js';
// import { useRouter } from '../../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../../../../v5/utils/store.tsx';
import slice, { showRoute } from './slice.tsx';
import jobFormSlice, { driverMarkerIcon, arrowMarkerIcon, defaultMarkerIcon } from '../../slice.tsx';

// enums
import { getDefLatLngZoomV4 } from '../../../../../v5/utils/utils.tsx';
// import {  } from '../../../../../v5/utils/enums.tsx';

import { LocationEnum, LocalJobType, getStepsFromStepGroups } from '../../../manage/jobs/jobTypes/slice.tsx';

// components
// import MainContainer from '../../../../../v5/components/container/MainContainer.jsx';
import GoogleMap, { addMarker, clearAllMarkers, addPolyline, clearAllPolyline } from '../../../../../v5/components/googleMap/GoogleMap.jsx';

// import { ReactComponent as XIcon } from "../../../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------


const Map = () => {
  const defaultLatLngZoom: any = getDefLatLngZoomV4();
  // const location = useLocation();
  // const router = useRouter();

  const { show, values } = useTypedSelector((state: RootState) => state.jobFormJobSummarySlice);
  const { latestLocationDrivers, defaultLocations } = useTypedSelector((state: RootState) => state.jobFormSlice);
  // const dispatch = useDispatch<AppDispatch>();

  const [map, setMap] = React.useState<any>(null);

  const latestLocationDriversMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const driversPathMarkersRef = React.useRef<Array<google.maps.Marker>>([]);
  const polylineRef = React.useRef<Array<google.maps.Polyline>>([]);
  const defaultLocationsMarkersRef = React.useRef<Array<google.maps.Marker>>([]);


  React.useEffect(() => {
    clearAllMarkers(latestLocationDriversMarkersRef);
    clearAllMarkers(driversPathMarkersRef);
    clearAllMarkers(defaultLocationsMarkersRef);
    clearAllPolyline(polylineRef);

    if(show){
      if(latestLocationDrivers && latestLocationDrivers.length > 0){
        latestLocationDrivers.forEach((item: any, i: number) => {
          addMarker(latestLocationDriversMarkersRef, {
            map: map,
            ...item,
            ...driverMarkerIcon(item.driverName, '#43936C'),
          })
          
          addMarker(driversPathMarkersRef, {
            map: map,
            ...item,
            ...arrowMarkerIcon(latestLocationDrivers, item, i),
          })
        });
        
        addPolyline(polylineRef, latestLocationDrivers.map((x: any) => x.position), {
          map: map,
          strokeColor: "#185CFF",
          strokeOpacity: 0.5,
          strokeWeight: 4,
        });
      }

      let coordinates: Array<any> = [];
      let arr = getStepsFromStepGroups(values);
      if(arr && arr.length > 0){
        arr.forEach((stepObj: any, stepIndex: number) => {
          if(stepObj.locationType == LocationEnum.CustomerSite){
            let pos = {
              lat: stepObj.customerSite?.latitude,
              lng: stepObj.customerSite?.longitude,
            }
            coordinates.push(pos);

            addMarker(defaultLocationsMarkersRef, {
              map: map,
              position: pos,
              title: stepObj.customerSite?.siteNameDisplay,
              ...defaultMarkerIcon((stepObj.stepCount+1).toString()),
            })
          } else {
            if(stepObj.hasDefaultSite){
              let locationItem = defaultLocations.find((x: any) => x.defaultLocationId === stepObj.defaultLocationId);
              if(locationItem){
                let pos = {
                  lat: locationItem.latitude,
                  lng: locationItem.longitude,
                }
                coordinates.push(pos);
  
                addMarker(defaultLocationsMarkersRef, {
                  map: map,
                  position: pos,
                  title: locationItem.address,
                  ...defaultMarkerIcon((stepObj.stepCount+1).toString()),
                })
              }
            }
          }
        })
      }

      showRoute(coordinates)
    }
  }, [show, values, map, window.google])


  return <Spin spinning={false}>
    <div style={{ height: 'calc(100vh - 133px)' }}>
      <GoogleMap
        canConextMenu={false}
        options={{
          center: defaultLatLngZoom.coord,
          zoom: defaultLatLngZoom.zoom,
          mapTypeControl: false,
          streetViewControl: false,
          zoomControl: true,
          cameraControl: false,
          fullscreenControl: false,
          gestureHandling: "cooperative",
        }}
        customElements={customElements}
        onLoad={(map: any, ref: any) => {
          setMap(map);
        }}
      />
    </div>
  </Spin>
}

export default Map;
