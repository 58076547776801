import React from 'react';
import { useFormik } from 'formik';
import _ from 'lodash';
// import { useLocation } from 'react-router-dom'

import {
  Row,
  Col,
  Form,
} from 'react-bootstrap';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { Spin } from "antd";

// Auth
// import { useAuthContext } from '../../../v5/auth/use-auth-context.js';
import { useRouter } from '../../../v5/auth/auth-guard.js';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector, Tables } from '../../../v5/utils/store.tsx';
import slice, { FormikContext, fields, initialValues, formSchema, prepareForm, prepareData, saveJobForm, saveAction, reloadAction, setPricingsData, margePricingsData, getId, isEdit, isCopy, setQtyAtMainPricings, getJobFormActionButtons } from './slice.tsx';
import confirmSlice from '../../../v5/components/modal/confirmSlice.tsx';

// enums
import { getIntProps, updateRow, createRow, getMaxPageSize, isNumeric } from '../../../v5/utils/utils.tsx';
import { MAIN_BREAKPOINT, Permissions } from '../../../v5/utils/enums.tsx';

// components
import MainContainer from '../../../v5/components/container/MainContainer.jsx';
import createGenericSlice, { BackOptions } from '../../../v5/components/table/withSlice/tableSlice.tsx';

import { smartConversionKgTons } from './form/weightChit/slice.tsx';
import { LocalJobType, ForWith, canDoPricings, getBinTypeForPricings } from '../manage/jobs/jobTypes/slice.tsx';

import Header from './form/Header.tsx';
import Footer from './form/Footer.tsx';
import PrintPreview from './form/printPreview/Page.tsx';
import RecurringJob from './form/recurringJob/Page.tsx';
import BillingJob from './form/billingJob/Page.tsx';
import WeightChit from './form/weightChit/Page.tsx';
import JobRoutes from './form/JobRoutes.tsx';
import JobSummary from './form/jobSummary/Page.tsx';
import DefaultLocation from './form/defaultLocation/Form.tsx';
import Alerts from './form/Alerts.tsx';
import CustomerDetails from './form/CustomerDetails.tsx';
import JobType from './form/JobType.tsx';
import JobTypeHistory from './form/jobTypeHistory/Page.tsx';
import Location from './form/Location.tsx';
import BinDetails from './form/BinDetails.tsx';
import Services from './form/services/Page.tsx';
import DispatchOrder from './form/DispatchOrder.tsx';
import DriverOptions from './form/driverOptions/Page.tsx';
import Payment from './form/Payment.tsx';
import Attachments from './form/Attachments.tsx';
import Signature from './form/Signature.tsx';
import AuditTrailList from './form/AuditTrail.tsx';
import AuditTrail from './form/auditTrail/Page.tsx';

import CustomerMiniForm from "./form/customer/Form.tsx";
// import customerMiniSlice from "./form/customer/slice";

import CustomerSiteMiniForm from "./form/customerSite/Form";
// import customerSiteMiniSlice from "./form/customerSite/slice";

// import { ReactComponent as XIcon } from "../../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledLayoutWrapper = styled.div`
  width: 100%;
  display: inline-block;

  ${up('lg')} {
    height: calc(100vh - var(--header-height, 64px) - 5px);
    overflow-x: hidden;
    overflow-y: auto;
  }

  ${down('lg')} {
    width: 100%;
  }

  .top-section {
    border-bottom: 1px solid var(--bs-gray-100);
    z-index: 1;

    ${up('lg')} {
      position: sticky;
      top: 0px;
      padding: 20px 32px;
    }
  
    ${down('lg')} {
      position: relative;
      padding: 20px 16px;
    }
  }

  .content-section {
    width: 100%;

    ${up('lg')} {
      display: inline-flex;
      height: calc(100vh - var(--header-height, 64px) - var(--job-form-top-height, 72px) - var(--job-form-footer-height, 72px) - 7px);
      padding: 24px 32px;
    }
  
    ${down('lg')} {
      display: block;
      height: auto;
      padding: 24px 16px;
    }

    .form-section {
      ${up('xl')} {
        overflow: auto;
      }

      ${up('lg')} {
        width: calc(100% - 470px);
      }
    
      ${down('lg')} {
        width: 100%;
        margin-bottom: 32px;
      }

      > div {
        width: 100%;

        > div {
          padding-right: 0px;
          justify-items: center;
          
          > div {
            width: 100%;

            > div {
              ${down('lg')} {
                padding-right: 0px;
              }
            }
          }
        }
      }

      .ant-divider {
        margin: 16px 0px 0px 0px;
      }
    }

    .side-section {
      overflow: auto;
      
      ${up('lg')} {
        width: 470px;
      }
    
      ${down('lg')} {
        width: 100%;
      }

      > div {
        width: 100%;

        > div {
          padding-right: 0px;
          justify-items: flex-end;

          ${down('lg')} {
            justify-items: center;
          }
          
          > div {
            width: 100%;

            > div {
              padding-right: 0px;
            }
          }
        }
      }
    }
  }

  .footer-section {
    border-top: 1px solid var(--bs-gray-100);
    position: sticky;
    bottom: 0px;
    background-color: white;
    z-index: 2;
    
    ${up('lg')} {
      padding: 20px 32px;
    }
  
    ${down('lg')} {
      padding: 20px 16px;
    }
  }
`;


// const table = createGenericSlice<any>(Tables.Jobs);

const FormComponent = () => {
  // const location = useLocation();
  const router = useRouter();

  const topHeight = document.getElementById('top_ID')?.clientHeight || 72;
  const footerHeight = document.getElementById('footer_ID')?.clientHeight || 72;
  document.documentElement.style.setProperty('--job-form-top-height', `${(topHeight)}px`);
  document.documentElement.style.setProperty('--job-form-footer-height', `${(footerHeight)}px`);

  // const { user }: any = useAuthContext();
  // const { userAccount } = user ?? {};
  // const someField = userAccount?.someField;

  // const formikRef = React.useRef<any>(null);

  const { isLoading, details, isLoadingCreateUpdateDelete, isLoadingCreditLimit, isCreditLimit, isLoadingJobTemplates, isLoadingDownloadInternalNote, isLoadingRefreshJobNote } = useTypedSelector((state: RootState) => state.jobFormSlice);
  const { isLoadingServicesItems } = useTypedSelector((state: RootState) => state.jobFormBinDetailsServicesSlice);
  // const { tableData, tableTotal } = useTypedSelector((state: RootState) => state.tableJobs);
  const dispatch = useDispatch<AppDispatch>();

  const [backLink, _setBackLink] = React.useState<any>('/jobs');

  const jobTypeRef = React.useRef<any>(null);
  const inputCanelledRef = React.useRef<any>(null);

  const [weightChitCounter, setWeightChitCounter] = React.useState<number>(1);


  React.useEffect(() => {
    onResetSlice();

    setTimeout(() => {
      let id = getId();
      if(id && id > 0){
        dispatch(slice.callDetailsApi(id, (state: boolean, data: any) => {}));
      }
      
      dispatch(slice.callLatestLocationDriversApi([], (state: Boolean, data: any) => {}));
  
      let params = {
        currentPage: 1,
        pageSize: getMaxPageSize(),
        sortColumn: 'locationName',
        sortDir: 'asc',
      };
      dispatch(slice.callReadDefaultLocationApi(params, (state: boolean, data: any) => {}));
    }, 0);

    return () => {
      onResetSlice();
    };
  }, []);

  React.useEffect(() => {
    dispatch(slice.setLoading(true));
    let form = prepareForm(details, initialValues);
    setValues(form);
    dispatch(slice.setLoading(false));
  }, [details]);

  React.useEffect(() => {
    if(isEdit() && !isCopy() && weightChitCounter > 1){
      let actions = getJobFormActionButtons(values);
      let main = actions ? actions.main : null;
      onUpdateWeightChit(main);
    }
  }, [weightChitCounter]);


  const formik = useFormik({
    initialValues: initialValues,
    validateOnMount: false,
    validateOnChange: false,
    validationSchema: formSchema(null),
    onSubmit: values => {},
  });
  const { values, errors, setValues, setFieldValue, validateForm, handleSubmit }: any = formik;
  // formikRef.current = formik;


  const onResetSlice = () => {
    let form = prepareForm(null, initialValues);
    setValues(form);
    dispatch(slice.setValues(form));
    dispatch(slice.resetSlice());
  }
  const onCancel = (backOptions: number = BackOptions.Read, data: any = null) => {
    onResetSlice();
    
    if(backOptions === BackOptions.Create){
      router.pushArgs(backLink, { backOptions: backOptions, backData: data });
    } else if(backOptions === BackOptions.Update){
      router.pushArgs(backLink, { backOptions: backOptions, backData: data });
    } else {
      router.push(backLink);
    }
  }


  const onCreate = (eventKey: any) => {
    let data = prepareData(values, eventKey, null);
    console.log('⚡ CREATE | ', { data: data, values: values, errors: errors });
    dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
      if(state){
        // let rows = createRow(tableData, 'jobId', { ...data, isNew: true });
        // dispatch(table.callSetData({ data: rows, total: (tableTotal+1)}));
        // onCancel(BackOptions.Create, data);
        saveAction(eventKey, getId(), router);
      }
    }));
  }
  const onUpdate = (eventKey: any, cancellationRemarks = null) => {
    let data = prepareData(values, eventKey, cancellationRemarks);
    console.log('⚡ UPDATE | ', { data: data, values: values, errors: errors });
    dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
      if(state){
        // let rows = updateRow(tableData, 'jobId', { ...data, isNew: false });
        // dispatch(table.callSetData({ data: rows, total: tableTotal}));
        // onCancel(BackOptions.Update, data);
        saveAction(eventKey, getId(), router);
      }
    }));
  }
  const onUpdateWeightChit = (eventKey: any) => {
    let data = prepareData(values, eventKey, null);
    console.log('⚡ UPDATE | ', { data: data, values: values, errors: errors });
    dispatch(slice.callUpdateApi(data, (state: boolean, data: any) => {
      if(state){
        // let rows = updateRow(tableData, 'jobId', { ...data, isNew: false });
        // dispatch(table.callSetData({ data: rows, total: tableTotal}));
        // onCancel(BackOptions.Update, data);
        reloadAction(getId(), router);
      }
    }));
  }
  const onCopy = (eventKey: any) => {
    let data = prepareData(values, eventKey, null);
    console.log('⚡ COPY | ', { data: data, values: values, errors: errors });
    dispatch(slice.callCreateApi(data, (state: boolean, data: any) => {
      if(state){
        // let rows = createRow(tableData, 'jobId', { ...data, isNew: true });
        // dispatch(table.callSetData({ data: rows, total: (tableTotal+1)}));
        // onCancel(BackOptions.Create, data);
        saveAction(eventKey, getId(), router);
      }
    }));
  }

  const onSelectBin = async (groupIndex: number, blockIndex: number, index: number, mainItem: any, additionalItems: Array<any>, isSelectedInPopup: boolean = true) => {
    if(isNumeric(groupIndex) && isNumeric(blockIndex) && isNumeric(index) && mainItem){
      let wasteTypes: Array<any> = [];
      if(additionalItems && additionalItems.length > 0){
        additionalItems.forEach((x: any, j: number) => {
          wasteTypes.push({
            ...x,
            order: (j+1),
          });
        });
      }
      

      let itm = _.cloneDeep(values.stepGroups[groupIndex].blocks[blockIndex].bins[index]);
      let obj = {
        ...itm,
        serviceItem: mainItem,
        serviceItemId: mainItem?.serviceItemId,
        binTypeId: mainItem?.binTypeId,
        binType: mainItem?.binType,
        isNoninventory: mainItem?.binType?.isNoninventory,
        wasteTypeId: mainItem?.wasteTypeId,
        wasteType: mainItem?.wasteType,
        wasteTypes: wasteTypes,
        binNumberId: isSelectedInPopup ? null : mainItem?.binNumberId,
        binNumber: isSelectedInPopup ? '' : mainItem?.binNumber,
        quantity: isSelectedInPopup ? 1 : mainItem?.quantity,
        binOutWeight: null,
        binWeight: null,
        tareBinWeight: null,
        netBinWeight: null,
        nonBillableBinWeight: null,
        billableBinWeight: null,
        weightChitTicketNumber: null,
        weightChitRemarks: null,
        binWeighBy: null,
        weightChitDateTime: null,
        weightChitOutDateTime: null,
      }
      await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.bins.' + index, obj);

      let shouldDoPricings = canDoPricings(values.stepGroups[groupIndex], values.stepGroups[groupIndex].blocks[blockIndex]);
      if(shouldDoPricings){
        let oldPricings = (values.pricings && values.pricings.length > 0) ? _.cloneDeep(values.pricings) : [];
        let newPricings = setPricingsData(mainItem, additionalItems, index);
        let pricings = margePricingsData(oldPricings, newPricings);
        await setFieldValue('pricings', pricings);

        let oldAmountToCollect = isNumeric(values.amountToCollect) ? _.cloneDeep(parseFloat(values.amountToCollect)) : 0;
        let newAmountToCollect = calcAmountToCollect(mainItem, additionalItems);
        let sumAmountToCollect = (oldAmountToCollect + newAmountToCollect)

        let amountToCollect = isNumeric(newAmountToCollect) ? sumAmountToCollect.toFixed(2) : oldAmountToCollect;
        await setFieldValue('amountToCollect', amountToCollect);
      }
    }
  }
  const onRemoveBin = async (groupIndex: number, blockIndex: number, index: number, item: any) => {
    if(isNumeric(groupIndex) && isNumeric(blockIndex) && isNumeric(index) && item){
      let oldPricings = (values.pricings && values.pricings.length > 0) ? _.cloneDeep(values.pricings) : [];
      let newPricings = oldPricings.filter((x: any) => x.bundleServiceTag != item?.serviceItem?.serviceTag);
      await setFieldValue('pricings', newPricings);

      let amountToCollect = calcAmountToCollectFromPricings(newPricings);
      await setFieldValue('amountToCollect', amountToCollect);
    }
  }
  const onClearBin = async (groupIndex: number, blockIndex: number, index: number) => {
    if(isNumeric(groupIndex) && isNumeric(blockIndex) && isNumeric(index)){
      let itm = _.cloneDeep(values.stepGroups[groupIndex].blocks[blockIndex].bins[index]);
      let obj = {
        ...itm,
        serviceItem: null,
        serviceItemId: null,
        binTypeId: null,
        binType: null,
        isNoninventory: false,
        wasteTypeId: null,
        wasteType: null,
        wasteTypes: [],
        binNumberId: null,
        binNumber: '',
        quantity: 1,
        binOutWeight: null,
        binWeight: null,
        tareBinWeight: null,
        netBinWeight: null,
        nonBillableBinWeight: null,
        billableBinWeight: null,
        weightChitTicketNumber: null,
        weightChitRemarks: null,
        binWeighBy: null,
        weightChitDateTime: null,
        weightChitOutDateTime: null,
      }

      await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.bins.' + index, obj);
      await setFieldValue('pricings', []);

      let amountToCollectValue = calcAmountToCollect(null, []);
      let amountToCollect = isNumeric(amountToCollectValue) ? amountToCollectValue.toFixed(2) : 0;
      await setFieldValue('amountToCollect', amountToCollect);
    }
  }

  const convertQtyinPricings = async (val: any) => {
    let binType: any = getBinTypeForPricings(val);
    if(binType){
      let newPricings: Array<any> = _.cloneDeep(values?.pricings);
      let qty = smartConversionKgTons(binType?.billableBinWeight);
      newPricings = setQtyAtMainPricings(newPricings, qty);
      await setFieldValue('pricings', newPricings);

      let amountToCollect = calcAmountToCollectFromPricings(val?.pricings);
      await setFieldValue('amountToCollect', amountToCollect);
    }

    if (isEdit() && !isCopy()) {
      setWeightChitCounter(weightChitCounter + 1);
    }
  }

  const calcAmountToCollect = (mainItem: any, additionalItems: any) => {
    let amountToCollect: any = 0;

    if(mainItem){
      let bundleServicesMain = (mainItem && mainItem.bundleServices && mainItem.bundleServices.length > 0) ? mainItem.bundleServices : [];
      let bundlePriceMain = bundleServicesMain.reduce((sum: any, itm: any) => {
        let quantityMainBundle = (itm && itm.quantity && isNumeric(itm.quantity)) ? itm.quantity : 1;
        let priceMainBundle = (itm && itm.price && isNumeric(itm.price)) ? itm.price : 0;
        let totalMainBundle = quantityMainBundle * priceMainBundle;
        
        return itm.useBinWeightQuantity === false ? sum + totalMainBundle : sum;
      }, 0);

      let quantityMain = (mainItem && mainItem.quantity && isNumeric(mainItem.quantity)) ? mainItem.quantity : 0;
      let priceMain = (mainItem && mainItem.price && isNumeric(mainItem.price)) ? mainItem.price : 0;
      let totalMain = quantityMain * priceMain;
    
      let totalAdditional = 0;
      let bundlePriceAdditional: any = 0;
      if(additionalItems && additionalItems.length > 0){
        additionalItems.forEach((itemObj: any) => {
          let bundleServices = (itemObj && itemObj.bundleServices && itemObj.bundleServices.length > 0) ? itemObj.bundleServices : [];
          bundlePriceAdditional = bundleServices.reduce((sum: any, itm: any) => {
            let quantityAdditionalBundle = (itm && itm.quantity && isNumeric(itm.quantity)) ? itm.quantity : 1;
            let priceAdditionalBundle = (itm && itm.price && isNumeric(itm.price)) ? itm.price : 0;
            let totalAdditionalBundle = quantityAdditionalBundle * priceAdditionalBundle;
            
            return itm.useBinWeightQuantity === false ? sum + totalAdditionalBundle : sum;
          }, 0);

          let quantityAdditional = (itemObj && itemObj.quantity && isNumeric(itemObj.quantity)) ? itemObj.quantity : 0;
          let priceAdditional = (itemObj && itemObj.price && isNumeric(itemObj.price)) ? itemObj.price : 0;
          totalAdditional = quantityAdditional * priceAdditional;
        });
      }

      amountToCollect = amountToCollect + totalMain + parseFloat(bundlePriceMain) + totalAdditional + parseFloat(bundlePriceAdditional);
    }

    return isNumeric(amountToCollect) ? amountToCollect : null;
  }
  const calcAmountToCollectFromPricings = (pricings: Array<any>) => {
    let amountToCollect: any = 0;
    
    if(pricings && pricings.length > 0){
      pricings.forEach((itemObj: any) => {
        let quantity = (itemObj && itemObj.quantity && isNumeric(itemObj.quantity)) ? itemObj.quantity : 0;
        let price = (itemObj && itemObj.price && isNumeric(itemObj.price)) ? itemObj.price : 0;
        amountToCollect = amountToCollect + parseFloat(quantity) * parseFloat(price);
      });
    }

    return isNumeric(amountToCollect) ? amountToCollect.toFixed(2) : null;
  }


  const setLayout = () => {
    return <StyledLayoutWrapper>
      <div id={'top_ID'} className='top-section'>
        <Header
          onCancel={() => {
            onCancel();
          }}
        />
      </div>

      <div id={'content_ID'} className='content-section'>
        <div className='form-section'>
          <Row className='gy-32 align-items-center'>
            <Alerts />
            <CustomerDetails />
            {values.customerId && <JobType ref={jobTypeRef} />}
            {values.jobTemplateId && <Location />}
            {!isCreditLimit && <>
              {values.jobTemplateId && <BinDetails
                onCopyToBelowSection={async (groupIndex: number, blockIndex: number, index: number, mainItem: any, additionalItems: any, item: any) => {
                  if(item){
                    onSelectBin(groupIndex, blockIndex, index, mainItem, additionalItems, false);
                  } else {
                    onClearBin(groupIndex, blockIndex, index);
                  }
                }}
                onRemoveBin={(groupIndex: number, blockIndex: number, index: number, item: any) => {
                  onRemoveBin(groupIndex, blockIndex, index, item);
                }}
              />}
              {values.jobTemplateId && <DispatchOrder />}
              {values.jobTemplateId && <Payment showDivider={(values.jobTemplateId && isEdit() && !isCopy())}/>}
              {(values.jobTemplateId && isEdit() && !isCopy()) && <Attachments />}
              {(values.jobTemplateId && isEdit() && !isCopy()) && <Signature />}
              {(values.jobTemplateId && isEdit() && !isCopy()) && <AuditTrailList />}
            </>}
          </Row>
        </div>

        <div className='side-section'>
          <Row className='gy-32 align-items-center'>
            <JobRoutes />
          </Row>
        </div>
      </div>

      <div id={'footer_ID'} className='footer-section'>
        <Footer
          onSave={async (eventKey: any) => {
            saveJobForm(eventKey, getId(), formik, values?.jobTemplate, 
              () => {
                onCreate(eventKey);
              },
              () => {
                onUpdate(eventKey);
              },
              () => {
                onCopy(eventKey)
              },
              () => {
                // onCachCollected
                dispatch(confirmSlice.show(() => {
                  onUpdate(eventKey);
                  dispatch(confirmSlice.setHide());
                }, {
                  title: 'Cash collected Info',
                  body: 'Cash collected by driver is less than amount to be collected. Do you want to continue?',
                  size: 'md',
                  cancelText: 'No',
                  cancelVariant: 'custom-outlined',
                  cancelCallback: () => {},
                  confirmText: 'Yes',
                  confirmVariant: 'custom-primary',
                }));
              },
              () => {
                // onCanceled
                dispatch(confirmSlice.show(null, {
                  title: 'Are you sure you want to cancel this job?',
                  body: <>
                    <Form.Group>
                      <Form.Control
                        as="textarea"
                        className='textarea-noresize'
                        ref={inputCanelledRef}
                        autoFocus
                        rows={5}
                        autoComplete='off'
                        placeholder={'Enter cancellation remarks'}
                        disabled={false}
                      />
                      <Form.Control.Feedback type="invalid">You must enter cancellation remarks</Form.Control.Feedback>
                    </Form.Group>
                  </>,
                  size: 'md',
                  cancelText: 'No',
                  cancelVariant: 'custom-outlined',
                  cancelCallback: () => {},
                  confirmText: 'Yes',
                  confirmVariant: 'custom-danger',
                  confirmCallback: async () => {
                    if(inputCanelledRef && inputCanelledRef.current){
                      if(inputCanelledRef.current.value !== ''){
                        inputCanelledRef.current.classList.remove("is-invalid");
                        let val = inputCanelledRef.current.value;

                        onUpdate(eventKey, val);
                        dispatch(confirmSlice.setHide());
                      } else {
                        inputCanelledRef.current.classList.add("is-invalid");
                      }
                    }
                  },
                }));
              }
            );
          }}
          onSaveCopyDraft={async (eventKey: any) => {
            onCopy(eventKey)
          }}
        />
      </div>
    </StyledLayoutWrapper>
  }


  return <MainContainer className={'p-0'} title={'Job form'} isLoading={false} roles={Permissions.Jobs}>
    <FormikContext.Provider value={formik}>
      <Spin spinning={(
        isLoading || 
        isLoadingCreateUpdateDelete || 
        isLoadingCreditLimit || 
        isLoadingJobTemplates ||
        isLoadingDownloadInternalNote || 
        isLoadingRefreshJobNote ||
        isLoadingServicesItems
      )} wrapperClassName={'no-height'}>{setLayout()}</Spin>
    </FormikContext.Provider>

    <JobTypeHistory
      onSave={(selectedId: number|null, selectedItem: any) => {
        if(jobTypeRef && jobTypeRef.current){
          jobTypeRef.current.onSelectTemplate(selectedItem);
        }
      }}
    />

    <DefaultLocation
      onSave={async (groupIndex: number, blockIndex: number, stepIndex: number, step: any) => {
        if(isNumeric(groupIndex) && isNumeric(blockIndex) && isNumeric(stepIndex) && step){
          await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.steps.' + stepIndex + '.defaultLocationId', step?.defaultLocationId);
          await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.steps.' + stepIndex + '.defaultLocation', step?.defaultLocation);
          await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.steps.' + stepIndex + '.siteName', step?.defaultLocation?.locationName);
          await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.steps.' + stepIndex + '.address', step?.defaultLocation?.address);
          await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.steps.' + stepIndex + '.fullAddress', step?.defaultLocation?.address);
          await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.steps.' + stepIndex + '.latitude', step?.defaultLocation?.latitude);
          await setFieldValue('stepGroups.' + groupIndex + '.blocks.' + blockIndex + '.steps.' + stepIndex + '.longitude', step?.defaultLocation?.longitude);
        }
      }}
    />

    <Services
      onSave={async (groupIndex: number, blockIndex: number, index: number, mainItem: any, additionalItems: Array<any>, tab: any) => {
        onSelectBin(groupIndex, blockIndex, index, mainItem, additionalItems, true);
      }}
    />
    
    <DriverOptions
      onSave={(steps: Array<any>) => {
        setFieldValue('steps', steps);
      }}
    />

    <BillingJob
      onSave={async (val: any) => {
        await setFieldValue('isBillable', val?.isBillable);
        await setFieldValue('invoiceDate', val?.invoiceDate);
        await setFieldValue('billingNote', val?.billingNote);
        await setFieldValue('pricings', val?.pricings);

        let amountToCollect = calcAmountToCollectFromPricings(val?.pricings);
        await setFieldValue('amountToCollect', amountToCollect);
      }}
    />

    <WeightChit
      onSave={async (val: any) => {
        await setFieldValue('stepGroups', val?.stepGroups);
        await convertQtyinPricings(val);
      }}
    />

    <JobSummary />
    <PrintPreview />
    <RecurringJob />
    <AuditTrail />

    <CustomerMiniForm
      onSave={async (res: any) => {
        if(res){
          // onClearSteps
          // onClearCustomerSite

          await setFieldValue('customerId', res.customerId);
          await setFieldValue('customerName', res.customerName);
          await setFieldValue('customer', res);

          // if (isCopy() || !isEdit()) {
          //   callReadCheckCreditLimitApi
          // }
        }
      }}
    />

    <CustomerSiteMiniForm
      onSave={async (res: any, type: any) => {
        if(res){
          // await setFieldValue('customerSiteId', res.customerSiteId);
          // await setFieldValue('customerSiteName', res.siteNameDisplay);
          // await setFieldValue('customerSite', res.res);

          // calcDistance
        }
      }}
    />
  </MainContainer>
}

export default FormComponent;
