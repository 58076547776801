import React from 'react';
import { createContext, useContext } from 'react';
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from 'lodash';

import { axios } from '../../../../../../../v5/utils/axios';
import { success, successAPI, errorAPI, getMaxPageSize } from '../../../../../v5/../../utils/utils';
import Yup from '../../../../../../../v5/utils/yup';
// import {  } from '../../../../../../../v5/utils/enums';


export const fields = {
  locationName: {
    id: 'locationName',
    label: 'Location Name',
    placeholder: 'Enter site name',
  },
  address: {
    id: 'address',
    label: 'Location Address',
    placeholder: 'Insert address or coordinate',
  },
};


export const formSchema = (id: number|null= null) => {
  return Yup.object().shape({
    locationName: Yup.string().required().label(fields.locationName.label),
    address: Yup.string().required().label(fields.address.label),
  })
}


let formikContext: any = null;
export const FormikContext = createContext<any>(null);
export const useFormikContext = () => {
    formikContext = useContext(FormikContext);
    if (!formikContext) {
      throw new Error('useFormikContext must be used within a FormikProvider');
    }
    return formikContext;
};


export const prepareForm = (values: any = null, defValues: any = null) => {
  let form = _.cloneDeep(values);
  let data = _.cloneDeep(defValues);
  
  if(data && form){
    data['locationName'] = form.locationName;
    data['address'] = form.address;
    data['addressField'] = form.address;
    data['latitude'] = form.latitude;
    data['longitude'] = form.longitude;
    data['isDefault'] = form.isDefault;
  }
  
  return data;
};
export const prepareData = (values: any = null, id: any = null) => {
  let data: any = {};

  let form = _.cloneDeep(values);

  if(form){
    data['locationName'] = form.locationName;
    data['address'] = form.address;
    data['addressField'] = form.address;
    data['latitude'] = form.latitude;
    data['longitude'] = form.longitude;
    data['isDefault'] = form.isDefault;

    if(id){
      data['defaultLocationId'] = id;
    }
  }

  return data;
};


export interface initialValuesStruct {
  locationName: string,
  address: string,
};
export const initialValues: initialValuesStruct = {
  locationName: '',
  address: '',
};


interface InitState {
  isLoading: boolean,
  show: boolean,
  index: number|null,
  id: number|null,
  details: any,
}


function NewReducer() {
  const name = 'defaultLocationFormSlice';


  const initialState: InitState = {
    isLoading: false,
    show: false,
    index: null,
    id: null,
    details: initialValues,
  };


  const reducers = {
    resetSlice: () => {
      return initialState;
    },
    setLoading: (state: InitState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setValues: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
    },
    

    setShow: (state: InitState, action: PayloadAction<{ show: boolean, index: number|null, id: number|null }>) => {
      state.isLoading = false;
      state.id = action.payload.id;
      state.index = action.payload.index;
      state.show = action.payload.show;
    },
    

    startDetails: (state: InitState) => {
      state.isLoading = true;
    },
    finishDetails: (state: InitState, action: PayloadAction<any>) => {
      state.details = action.payload;
      state.isLoading = false;
    },
    
    startCreate: (state: InitState) => {
      state.isLoading = true;
    },
    finishCreate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startUpdate: (state: InitState) => {
      state.isLoading = true;
    },
    finishUpdate: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },

    startDelete: (state: InitState) => {
      state.isLoading = true;
    },
    finishDelete: (state: InitState, action: PayloadAction<any>) => {
      state.isLoading = false;
    },
  };


  const apis = {
    callDetailsApi: (id: number|null, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDetails());

      let params = {
        currentPage: 1,
        pageSize: getMaxPageSize(),
        sortColumn: 'locationName',
        sortDir: 'asc',
      };

      await axios.get('default-location', { params: params }).then((result: any) => {
        let data = (result && result.data && result.data.data && result.data.data.length > 0) ? result.data.data : [];
        let details = data.find((x: any) => x.defaultLocationId == id);
        if(details){
          details['addressField'] = details.address;
        }

        // successAPI(data);

        callback(true, details);
        dispatch(actions.finishDetails(details));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDetails(null));
      });
    },

    callCreateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startCreate());

      await axios.post('default-location', params).then((result: any) => {
        let data = result.data;
        
        // successAPI(data);
        // success('Saved successfully', 'The data you entered has been successfully saved.');

        callback(true, data);
        dispatch(actions.finishCreate(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishCreate(null));
      });
    },

    callUpdateApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startUpdate());

      await axios.put('default-location', params).then((result: any) => {
        let data = result.data;
        
        // successAPI(data);
        // success('Updated successfully', 'Your data has been successfully updated.');

        callback(true, data);
        dispatch(actions.finishUpdate(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishUpdate(null));
      });
    },

    callDeleteApi: (params: any, callback: (state: boolean, data: any) => void) => async (dispatch: any) => {
      dispatch(actions.startDelete());

      await axios.delete('default-location', { data: params }).then((result: any) => {
        let data = result.data;
            
        // successAPI(data);
        success('Deleted successfully', 'Location has been successfully deleted.');

        callback(true, data);
        dispatch(actions.finishDelete(data));
      }).catch((error: any) => {
        errorAPI(error);
        
        callback(false, null);
        dispatch(actions.finishDelete(null));
      });
    },
  };


  const { reducer, actions } = createSlice({
    name,
    initialState,
    reducers,
  });


  return {
    reducer,
    ...actions,
    ...apis,
  };
}


export default NewReducer();