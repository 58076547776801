import React, { ReactNode } from 'react';
import {useLocation} from 'react-router-dom'
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { up, down } from 'styled-breakpoints';

import { 
    Container,
    Row,
    Col,
    Button,
    Offcanvas,
} from 'react-bootstrap';

// import PrivateHeader from '../sections/PrivateHeader';
import { HeaderWrapper } from '../../../_metronic/layout/components/header/HeaderWrapper'
import {MenuComponent} from '../../../_metronic/assets/ts/components'
import Menu from '../sections/Menu';

// Redux
import { useDispatch } from 'react-redux';
import { RootState, AppDispatch, useTypedSelector } from '../../v5/utils/store.tsx';
import slice from './menu/slice.tsx';

import { MAIN_BREAKPOINT } from '../utils/enums';

import ConfirmModal from '../components/modal/ConfirmModal';

import { ReactComponent as XIcon } from "../../v5/assets/icons/smallX.svg";

// ----------------------------------------------------------------------

const StyledContent = styled.div`
  display: flex;
  min-height: calc(100vh - var(--header-height, 64px));

  ${up(MAIN_BREAKPOINT)} {
    display: flex;
  }

  ${down(MAIN_BREAKPOINT)} {
    display: block;
  }
`;
const StyledChildren = styled.div`
  width: 100%;
  overflow: hidden;
  min-height: 300px;
`;

const StyledOffcanvas = styled(Offcanvas)`
  width: 80px !important;

  ${up(MAIN_BREAKPOINT)} {
    display: none;
  }

  ${down(MAIN_BREAKPOINT)} {
    display: block;
  }
`;

const StyledMenuWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: auto;
`;

const StyledHeader = styled.div`
  align-content: center;
  height: 64px !important;
  border-bottom: 1px solid var(--bs-gray-100);
`;

// ----------------------------------------------------------------------

export default function PrivateLayout({ children }: { children: ReactNode }) {
  const headerHeight = document.getElementById('header_ID')?.clientHeight || 64;
  document.documentElement.style.setProperty('--header-height', `${(headerHeight + 1)}px`);


  const { show } = useTypedSelector((state: RootState) => state.menuSlice);
  const dispatch = useDispatch<AppDispatch>();


  const location = useLocation()
  React.useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  React.useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])


  return <Container fluid={'xxxl'}>
    <Row>
      <Col xs={12} className={'g-0'}>
        {/* <PrivateHeader /> */}
        <div className='wrapper d-flex flex-column flex-row-fluid kt-wrapper-custom' id='kt_wrapper'>
          <HeaderWrapper />
        </div>

        <StyledContent>
          {/* <Menu isResponsive={true} /> */}
          <StyledChildren>{children}</StyledChildren>
        </StyledContent>
      </Col>
    </Row>
    
    <ConfirmModal />

    <StyledOffcanvas
      backdrop={true}
      scroll={false}
      placement={'start'}
      show={show}
      onHide={() => {
        dispatch(slice.setShow(false));
      }}
    >
      <>
        <StyledHeader id={'manage_offcanvas_header_ID'}>
          <Button
            className={'px-32'}
            size={'sm'}
            variant={'custom-text'}
            onClick={() => {
              dispatch(slice.setShow(false));
            }}
          >
            <XIcon />
          </Button>
        </StyledHeader>

        <StyledMenuWrapper>
          <Menu isResponsive={false} />
        </StyledMenuWrapper>
      </>
    </StyledOffcanvas>
  </Container>
}

PrivateLayout.propTypes = {
  children: PropTypes.node,
};
